import React, { useState, useEffect } from 'react';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { AdminGroupErrorHandleFields } from '../../containers/admin/adminGroups/AdminGroupErrorHandleFields';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../utils';
import { CheckStatus, SavedStatus } from '../../config/CustomEnums';
import BasePrompt from '../../components/base/prompt/BasePrompt';
import './AddNewAdminGroupPrompt.scss';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';

function AddNewAdminGroupPrompt(props) {
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const dispatch = useDispatch();
  const {
    errorFields,
    checked,
    saved,
    allPermissions,
    oneAdminGroup,
  } = useSelector((state) => ({
    errorFields: state.adminGroup.errorFields,
    checked: state.adminGroup.checked,
    saved: state.adminGroup.saved,
    allPermissions: state.admin.allPermissionList, //state.admin.cmsAllPermissions
    oneAdminGroup: state.adminGroup.oneAdminGroup,
  }));
  const [name, setName] = useState();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(
        createAction('adminGroup/updateOrCreate')({
          data: { name, permissions },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, checked, name, permissions]);

  useEffect(() => {
    if (saved === SavedStatus.savedWithSuccess) {
      console.log('@@45: ', oneAdminGroup);
      onConfirm(oneAdminGroup);
      setName('');
      dispatch({
        type: 'adminGroup/updateState',
        payload: {
          saved: SavedStatus.init,
          checked: CheckStatus.initOrNotChecked,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirm, saved]);

  const otherBody = () => {
    return (
      <>
        <div className="new-admin-other-body">
          <CustomTitleWithInput
            title={'Name'}
            defaultValue={name}
            setValue={setName}
            error={{
              id: 'name',
              error: errorFields?.fields?.includes('name'),
              message: errorFields?.messages?.map((item) => {
                if (item.field === 'name') {
                  return AdminGroupErrorHandleFields[item.field][
                    item.errorType
                  ];
                }
                return undefined
              }),
            }}
          />
          <BaseMultipleSelectorV2
            title={'Administrator group(s) permission(optional)'}
            size={["shorter"]}
            data={{
              sourceData: allPermissions,
              targetData: permissions,
              targetChange: setPermissions,
            }}
          />
        </div>
      </>
    );
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={() => {
        onClose();
        setPermissions([]);
      }}
      rightButton={{
        text: 'Save',
        action: () =>
          dispatch({
            type: 'adminGroup/checkValsValid',
            payload: { name, permissions },
          }),
      }}
      title={'Add administrator groups'}
      description={
        'Created here will be updated to administrator groups synchronously'
      }
      otherBody={otherBody}
    />
  );
}

export default AddNewAdminGroupPrompt;

import React from 'react';
import BaseActionModal from '../coupon/couponList/BaseActionModalView';
import { capitalizeFirstLetter } from '../../utils';
import { TRANSACTION_RECORD_TYPE } from '../../containers/record/records/TransactionList';

function TransactionActionPrompt(props) {
  const event = props.event; //approve, reject, recall
  const item = props.item;
  const show = props.show;
  const onHide = props.onHide || (() => {});
  const action = props.action || (() => {});

  const promptTitle = event
    ? `${capitalizeFirstLetter(event)} transaction`
    : '';
  const promptMessage = event
    ? `${capitalizeFirstLetter(event)} the transaction below?`
    : '';
  const promptDetailItems = [
    {
      title: 'Invoice',
      content:
        item?.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
          ? item?.posInvoiceId
          : item?.invoiceId,
    },
    { title: 'Total valus', content: item?.totalValue },
  ];
  const promptRightButton = event ? capitalizeFirstLetter(event) : '';

  const handleOnHide = () => {
    onHide();
  };

  const getActionPromptDetailItems = () => {
    return (
      <div>
        {promptDetailItems.map((item, index) => {
          return (
            <div className="deactive-content-row" key={index}>
              <label className="deactive-itme-name">{item.title}</label>
              <label className="deactive-item-value">{item.content}</label>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <BaseActionModal
      className={'transaction-action-prompt'}
      showModal={show}
      onHide={handleOnHide}
      modalTitle={promptTitle}
      modalGeneralContent={promptMessage}
      modalDetailContent={getActionPromptDetailItems()}
      actionName={promptRightButton}
      action={() => {
        action();
      }}
    />
  );
}

export default TransactionActionPrompt;

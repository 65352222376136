import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

export function SectionTopTitle({ title }) {
  return <label className="create-section-title">{title}</label>;
}

export function FieldControl({ name, rules = {}, render }) {
  const { control } = useFormContext();
  return (
    <Controller control={control} name={name} rules={rules} render={render} />
  );
}

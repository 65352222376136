import i18n from 'i18n-js';
const translations = {
  en: {
    ...require('./translations/en.json'),
    campaign: require('./translations/campaign/en.json'),
    coupon: require('./translations/coupon/en.json'),
  },
  'zh-Hant': {
    ...require('./translations/zh-Hant.json'),
    campaign: require('./translations/campaign/zh-Hant.json'),
    coupon: require('./translations/coupon/zh-Hant.json'),
  },
  'zh-Hans': {
    ...require('./translations/zh-Hans.json'),
    campaign: require('./translations/campaign/zh-Hans.json'),
    coupon: require('./translations/coupon/zh-Hans.json'),
  },
};
const languageTag = 'en';
i18n.locale = languageTag;
i18n.fallbacks = true;
i18n.translations = translations;

export default i18n;

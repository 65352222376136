import React from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CampaignsCard.scss';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import { ANIMATION_DURATION } from '../../config/DashboardConfig';
import { enLocaleNumberFormatter } from '../../utils';
import { URLFragments } from '../campaign/CampaignUrlConfig';
import { getValueChangeLabel } from './CustomersCard';

const CampaignsCard = () => {
  const { totalPublished, conversions, views, shareRate } = useSelector(
    (state) => state.dashboard.campaigns,
  );
  return (
    <div className="dashboard-campaign-card">
      <div className="dashboard-card-title">Campaigns</div>
      <div className="dashboard-campaigns-total-publish-title">
        Total published
      </div>
      <AnimatedNumber
        className="dashboard-campaigns-total-publish-value"
        duration={ANIMATION_DURATION}
        value={totalPublished?.value || 0}
        formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
      />
      <div className="dashboard-campaign-row dashboard-campaign-info-title">
        Conversion (Buy coupons/ earn points)
      </div>

      <div className="dashboard-campaign-row dashboard-campaign-info-value-row">
        <AnimatedNumber
          className="dashboard-campaign-info-value"
          duration={ANIMATION_DURATION}
          value={conversions?.value || 0}
          formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
        />
        {getValueChangeLabel(conversions?.change)}
      </div>
      <div className="dashboard-campaign-row dashboard-campaign-info-title">
        Share
      </div>

      <div className="dashboard-campaign-row dashboard-campaign-info-value-row">
        <AnimatedNumber
          className="dashboard-campaign-info-value"
          duration={ANIMATION_DURATION}
          value={shareRate?.value || 0}
          formatValue={(value) => `${(value * 100).toFixed(0)}%`}
        />
        {getValueChangeLabel(shareRate?.change)}
      </div>
      <div className="dashboard-campaign-row dashboard-campaign-info-title">
        View
      </div>
      <div className="dashboard-campaign-row dashboard-campaign-info-value-row">
        <AnimatedNumber
          className="dashboard-campaign-info-value"
          duration={ANIMATION_DURATION}
          value={views?.value || 0}
          formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
        />
        {getValueChangeLabel(views?.change)}
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/campaigns', hash: URLFragments.overview }}
      >
        Campaign Overview
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default CampaignsCard;

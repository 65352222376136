import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseActionModal from './BaseActionModalView';
import './DeactiveCouponSetView.scss'

@connect()
class DeactiveCouponSetView extends Component {
  getCouponSetContent = () => {
    return (
      <div>
        <div className="deactive-content-row">
          <label className="deactive-itme-name">Coupon Set</label>
          <label className="deactive-item-value deactive-coupon-set-name">
            {this.props.couponSet?.name}
          </label>
        </div>
        <div className="deactive-content-row">
          <label className="deactive-itme-name">Single coupon generated</label>
          <label className="deactive-item-value deactive-generated-coupon-number">
            {this.props.couponSet?.totalNumberOfGeneratedCoupons}
          </label>
        </div>
      </div>
    );
  };

  render() {
    return (
      <BaseActionModal
        customClass={'deactive-coupon-set-modal'}
        showModal={this.props.showDeactiveView}
        onHide={this.props.onHide}
        modalTitle={`Deactive the Coupon Set`}
        modalGeneralContent={`Deactive the coupon set and its generated single coupon?`}
        modalDetailContent={this.getCouponSetContent()}
        action={() => {
          this.props.deactiveAction()
        }}
        actionName={`Deactive`}
      />
    );
  }
}

export default DeactiveCouponSetView;

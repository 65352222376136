import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import i18n from 'I18n';

import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import { ReactHookFormErrorMessage } from 'components/base/ErrorFieldMessage';
import {
  AppCampaignDetailUrl,
  CampaignDetailUrl,
  LanguageConfig,
} from 'config/CustomEnums';

import './MessageGeneralSectionSMS.scss';
import CustomTips from 'components/base/CustomTips';

function MessageGeneralSectionSMS({ language }) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const messageContentField = `translations.${language}.smsContent`;

  const content = watch(messageContentField);
  const [contentStart, setContentStart] = useState(0);

  const getContentTips = () => {
    return (
      <ul className='content-tips'>
        <li>
          {("Insert campaign URL for web\n" +
            "When customer click on the link, will open the page in website and " +
            "show a banner for customer to open the same content in native " +
            "app, redirect to app store if the native app has not been installed.")}
        </li>
        <li>
          {"Insert campaign deep link for app\n" +
            "When customer click on the link, will redirect to native app if the" +
            "app has been installed;  if not installed, will show a page to app" +
            "store."}
        </li>
      </ul>
    )
  }

  return (
    <div className={'message-general-section-SMS'}>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>

      <CustomTitleLabelWithEditorBar
        title={
          <CustomTips detail={getContentTips()}>
            {i18n.t('content', { locale: language })}
          </CustomTips>
        }
        suggest={i18n.t('inbox_sms_content_suggest', { locale: language })}
        buttonNewLine={true}
        onCampaignSelected={(campaign, forApp) => {
          const url = forApp
            ? `${AppCampaignDetailUrl}${campaign.pk}`
            : `${CampaignDetailUrl}${campaign.pk}`;
          if (content) {
            const newValue =
              content.substring(0, contentStart) +
              ' ' +
              url +
              ' ' +
              content.substring(contentStart);
            setValue(messageContentField, newValue, {
              shouldDirty: true,
            });
          } else {
            setValue(messageContentField, url, { shouldDirty: true });
          }
        }}
        paramText={i18n.t('message_insert_param', { locale: language })}
        onParamSelected={(value) => {
          console.log('param', value);
          if (content) {
            const newValue =
              content.substring(0, contentStart) +
              value +
              content.substring(contentStart);
            setValue(messageContentField, newValue, {
              shouldDirty: true,
            });
          } else {
            setValue(messageContentField, value, { shouldDirty: true });
          }
        }}
      />
      <textarea
        onChange={({ target }) => {
          const value = target.value;
          setValue(messageContentField, value, { shouldDirty: true });
        }}
        className="custom-textarea-input-box custom-textarea-input-font"
        onFocus={() => {}}
        onBlur={({ target }) => {
          const start = target.selectionStart;
          console.log('textarea.onBlur selectionStart', start);
          setContentStart(start);
        }}
        value={content}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageContentField} />
    </div>
  );
}

export default MessageGeneralSectionSMS;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import AuthButton from './AuthButton';
import BasePrompt from './prompt/BasePrompt';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import './CustomFileUploader.scss'

function CustomFileUploader({
  title = 'Import .csv',
  accept = '.csv',
  action = () => {},
  afterUploadAction = () => { },
  removeUploadedFileAction = () => { },
  showFileName = false,
  buttonClass = '',
}) {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const { importedFile, uploading } = useSelector((state) => ({
    importedFile: state.uploadFiles.importedFile,
    uploading: state.uploadFiles.uploading,
  }));

  const [fileName, setFileName] = useState();
  const [showFile, setShowFile] = useState(false);

  useEffect(() => {
    if (importedFile) {
      // setFileName(event.target.value.replace(/^.*[\\\/]/, ''));
      // console.log('@@27: ', importedFile);
      setShowFile(showFileName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedFile]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const removeFile = () => {
    dispatch(
      createAction('uploadFiles/updateState')({
        importedFile: '',
        importedFileName: '',
      }),
    );
    removeUploadedFileAction();
    setShowFile(false);
    setFileName('')
  }

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // eslint-disable-next-line no-useless-escape
    const fileName = event.target.value.replace(/^.*[\\\/]/, '')
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
        actionAfterSuccess: (responseData) => {
          afterUploadAction(responseData)
          dispatch(
            createAction('uploadFiles/updateState')({
              importedFileName: fileName,
            }),
          );
        },
      }),
    );

    setShowFile(false);
    setFileName(fileName);

    event.target.value = null;

    action();
  };

  const [showError, setShowError] = useState(false);
  const { importError } = useSelector((state) => ({
    importError: state.downloadAndImport.error,
  }));

  useEffect(() => {
    setShowError(Object.keys(importError || {}).length > 0);
  }, [importError]);

  return (
    <>
      {uploading ? (
        <Image src={LoadingIcon} className="loading-icon"></Image>
      ) : (
          showFile && fileName ?
            <div className='uploaded-file-button link-button-text'>
              <div>
                <div>{fileName}</div>
              </div>
              <Image
                src={CloseIcon}
                className="remove-file-button"
                onClick={removeFile}
              />
            </div> :
            <AuthButton
              className={showFileName ? `link-button-text ${buttonClass || ''}` : buttonClass}
              title={title}
              action={handleClick}
              requires={PermissionCodes.addImportjob}
            />
      )}

      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
      />

      <BasePrompt
        show={showError}
        title={importError?.title}
        description={importError?.content}
        closeAction={() => {
          setShowError(false);
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowError(false);
          },
        }}
        rightButton={{
          text: 'Import again',
          action: () => {
            handleClick();
            setShowError(false);
          },
          requires: PermissionCodes.addImportjob,
        }}
      />
    </>
  );
}

export default CustomFileUploader;

import moment from 'moment';
import { getDashboardData } from '../services/AnalyticsApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { createAction } from '../utils';
import { COUPON_PIE_CHART_CONFIG } from '../config/DashboardConfig';

export const parseCustomerChartData = (chartData) => {
  return chartData.periods.map((period, index) => {
    const startDate = moment(period.split(' - ')[0]);
    const endDate = moment(period.split(' - ')[1]);
    return {
      period: period,
      point: chartData.points[index],
      startDate,
      startDateString: startDate.format('D MMM'),
      endDate,
    };
  });
};
const parseDashboardApiData = (resp) => {
  const { analyticsData } = resp;
  const coupons = {
    totalActivities: {
      ...analyticsData.totalNumberOfCouponActivities,
    },
    totalPoints: {
      ...analyticsData.totalNumberOfCouponsRedeemedByPoints,
    },
    totalCampaigns: {
      ...analyticsData.totalNumberOfCouponsAcquiredByCampaigns,
    },
    totalStampCampaigns: {
      ...analyticsData.couponAcquiredInStampCampaign,
    },
    totalUsed: {
      ...analyticsData.totalNumberOfCouponsUsed,
    }
  };
  coupons.chartData = COUPON_PIE_CHART_CONFIG.map((item) => ({
    key: item.key,
    value: coupons ? coupons[item.key]?.value : 0,
  }));
  return {
    customers: {
      totalCustomers: {
        ...analyticsData.totalNumberOfCustomers,
      },
      activeCustomers: {
        ...analyticsData.totalNumberOfActiveCustomers,
      },
      newCustomers: {
        ...analyticsData.totalNumberOfNewCustomers,
      },
      chartData: parseCustomerChartData(
        analyticsData.totalNumberOfNewCustomersChart,
      ),
      tempEarningCustomers: {
        ...analyticsData.totalNumberOfTemporaryEarningCustomers,
      },
      tempCustomers: {
        ...analyticsData.totalNumberOfTemporaryCustomers,
      },
    },
    coupons,
    campaigns: {
      totalPublished: {
        ...analyticsData.totalNumberOfPublishedCampaigns,
      },
      conversions: {
        ...analyticsData.totalNumberOfCampaignConversions,
      },
      views: {
        ...analyticsData.totalNumberOfCampaignViews,
      },
      shareRate: {
        ...analyticsData.campaignShareRate,
      },
    },
    stampCampaigns: {
      ...analyticsData.totalNumberOfPublishedStampCampaign,
      ...analyticsData.totalNumberOfStampCampaignConversion,
      ...analyticsData.stampCampaignConversionRate,
      ...analyticsData.stampCampaignTotalNumberOfView,
      ...analyticsData.stampCampaignConversionRate,
      ...analyticsData.stampCampaignTotalNumberOfView,
      ...analyticsData.stampCampaignShareRate,
      ...analyticsData.stampEarnedInStampCampaign,
      ...analyticsData.totalNumberOfTimesOfRewardsEarnedInStampCampaign,
      ...analyticsData.pointsEarnedInStampCampaign,
      ...analyticsData.couponAcquiredInStampCampaign,
    },
  };
};

export default {
  namespace: 'dashboard',
  state: {
    customers: {},
    coupons: {},
    campaigns: {},
    stampCampaigns: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return {
        customers: {},
        coupons: {},
        campaigns: {},
      };
    },
  },
  effects: {
    getDashboardData: [
      function* ({ payload }, { call, select, put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [getDashboardData, startDate, endDate];
        function* onSuccess(data) {
          const processedData = parseDashboardApiData(data);
          yield put(createAction('updateState')({ ...processedData }));
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@Get dashboard data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import queryString from 'query-string';

import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import BaseForm from 'components/base/v2/BaseForm';
import CreateMessageStepFour from 'components/message/CreateMessageStepFour';
import CreateMessageStepOne from 'components/message/CreateMessageStepOne';
import CreateMessageStepThree from 'components/message/CreateMessageStepThree';
import CreateMessageStepTwo from 'components/message/CreateMessageStepTwo';
import CreateMessageStepTwoEmail from 'components/message/CreateMessageStepTwoEmail';
import CreateMessageStepTwoSMS from 'components/message/CreateMessageStepTwoSMS';
import CreateMessageStepTwoWhatsapp from 'components/message/CreateMessageStepTwoWhatsapp';
import { ActionType, LanguageConfig } from 'config/CustomEnums';
import { MessageStepConfig } from 'config/CustomEnums';
import { sessionDataKey } from 'models/CreateMessageModel';
import CreateMessageSuccessPrompt from 'components/message/CreateMessageSuccessPrompt';

const mapPropsToState = (state) => ({
  selectedChannels: state.createMessage.selectedChannels,
  stepConfig: state.createMessage.stepConfig,
  currentStep: state.createMessage.currentStep,
  scheduledMessage: state.createMessage.scheduledMessage,
  message: state.createMessage.message,
  hasUpdatedDefaultValues: state.createMessage.hasUpdatedDefaultValues,
  formHasSubmitted: state.createMessage.formHasSubmitted,
});

export const afterSaveASDraftSuccess = ({ setValue, tempSavePk, translationPks }) => {
  setValue(
    'tempSavePk',
    tempSavePk,
  );
  Object.entries(translationPks).forEach(([lang, pk])=>{
    setValue(
      `translations.${lang}.pk`,
      pk,
    );
  })
}

function CreateMessage({
  selectedChannels,
  stepConfig,
  currentStep,
  scheduledMessage,
  message,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const search = queryString.parse(history.location.search);
  const [showSentPrompt, setShowSentPrompt] = useState(false);
  const [isTestSend, setIsTestSend] = useState(false);

  const [actionType, setActionType] = useState(ActionType.none);

  useEffect(() => {
    dispatch({
      type: 'messageList/getWhatsappMessageTemplateList',
    });
    if (search) {
      const campaignId = search.campaign;
      dispatch({
        type: 'createCampaign/getOneCampaign',
        payload: { id: campaignId },
      });
      dispatch({
        type: 'language/getList',
        payload: { isSelectorLoad: true },
      });
    }
    if (params.id) {
      dispatch({
        type: 'messageList/getMessage',
        payload: {
          messagePK: params.id,
          afterAction: (message) => {
            dispatch({
              type: 'createMessage/editMessage',
              payload: {
                message
              },
            });
          },
        },
      });
    } else {
      dispatch({
        type: 'createMessage/loadMessageFromCookie',
        payload: {},
      });
    }

    return () => {
      dispatch({
        type: 'createMessage/clearData',
        payload: {},
      });
      dispatch({
        type: 'campaignList/clearData',
        payload: {},
      });
      dispatch({
        type: 'createCampaign/clearData',
        payload: {
          deleteSession: search.campaign,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stepContent = () => {
    const pageList = [<CreateMessageStepOne />];
    if (MessageStepConfig[1].show(selectedChannels)) {
      pageList.push(
        <CreateMessageStepTwo
          setShowSentPrompt= {setShowSentPrompt}
        />
      );
    }
    if (MessageStepConfig[2].show(selectedChannels)) {
      pageList.push(
        <CreateMessageStepTwoEmail
          setShowSentPrompt={setShowSentPrompt}
        />);
    }
    if (MessageStepConfig[3].show(selectedChannels)) {
      pageList.push(
        <CreateMessageStepTwoSMS
          setShowSentPrompt={setShowSentPrompt}
        />);
    }
    if (MessageStepConfig[4].show(selectedChannels)) {
      pageList.push(
        <CreateMessageStepTwoWhatsapp
          setShowSentPrompt={setShowSentPrompt}
        />);
    }
    pageList.push(
      <CreateMessageStepThree
        setShowSentPrompt={setShowSentPrompt}
      />);
    pageList.push(
      <CreateMessageStepFour
        setShowSentPrompt={setShowSentPrompt}
        setActionType={setActionType}
        setIsTestSend={setIsTestSend}
      />);
    return pageList;
  };

  return (
    <>
      <BaseForm
        defaultValues={message}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: 'createMessage/saveOrRemoveMessageFromCookie',
            payload: {
              save: save,
              values: getValues(),
            },
          });
        }}
        showFinishPop={scheduledMessage}
        sessionDataKey={sessionDataKey}
        content={stepContent()}
        currentStep={currentStep}
        stepsConfig={stepConfig}
        breadcrumb={
          <CustomBreadcrumb
            name={
              params.id
                ? message.name && message.name !== ''
                  ? message.name
                  : 'Edit Message'
                : null
            }
          />
        }
        caution={{
          detail: '', //Create a message to get in touch with  your customers. You can send a message through different channels.
          title: `${params.id
              ? message.name && message.name !== ''
                ? message.name
                : 'Edit Message'
              : 'Create Message'
            }`,
        }}
      />
      <CreateMessageSuccessPrompt
        isTest={isTestSend}
        actionType={
          currentStep && (currentStep + 1 < stepConfig?.length)
          ? ActionType.tempForPulished : actionType}
        show={showSentPrompt}
        onClose={() => setShowSentPrompt(false)}
      />
    </>

  );
}

export default connect(mapPropsToState)(CreateMessage);

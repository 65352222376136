import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

function CouponBarcodeSelectDropdown(props) {
  const customClass = props.customClass || '';
  const title = props.title;
  const defaultValue = props.defaultValue;
  const onSelectChange = props.onSelectChange || (() => {});
  const onFocus = props.onFocus || (() => {});
  const dispatch = useDispatch();
  const { couponBarcodeList } = useSelector((state) => ({
    couponBarcodeList: state.createCoupon?.couponBarcodeList,
  }));

  useEffect(() => {
    dispatch(createAction('createCoupon/getBarcodeList')({}));
  }, [dispatch]);

  return (
    <CustomTitleWithDropDown
      customClass={customClass}
      title={title}
      source={couponBarcodeList}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      loadMoreAction={()=>{}}
      filterAction={'createCoupon/getBarcodeList'}
      onFocus={onFocus}
    />
  );
}

export default CouponBarcodeSelectDropdown;

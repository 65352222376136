import {
  getPointTransactionRecords,
  getOnePointTransactionRecord,
  getPointTransactionReports,
} from 'services/TransactionRecordsAPIHelper';
import {
  createAction,
  capitalizeFirstLetter,
  convertCursorToNumber,
  convertNumberToCursor,
} from 'utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from 'utils/TimeFormatUtil';
import { POINT_TRANSACTION_TYPE } from 'containers/record/pointRecords/PointTransactionList';
import { POINT_RECORD_TYPE } from 'components/pointRecord/CreatePointRecordStepOne';

function getInitState() {
  return {
    pointTransactionList: [],
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      {
        displayName: 'Name (preferred name)',
        fieldName: 'name',
        linked: false,
        orderField: 'customerFirstName',
      },
      {
        displayName: 'Membership\r\nID',
        fieldName: 'membershipId',
        orderField: 'membershipId',
      },
      { displayName: 'Point Type', fieldName: 'pointDisplayType' },
      { displayName: 'Gift card code', fieldName: 'giftCardCode' },
      { displayName: 'Points', fieldName: 'points', orderField: 'value' },
      { displayName: 'Loyalty Point', fieldName: 'loyaltyPoints', orderField: 'loyaltyPoints' },
      { displayName: 'Gift card Point', fieldName: 'giftCardPoints', orderField: 'giftCardPoints' },
      { displayName: 'Point source', fieldName: 'pointSource' },
      { displayName: 'Progression Points', fieldName:'tpe'},
      { displayName: 'Record Type', fieldName: 'transactionDisplayType' },
      { displayName: 'Remark', fieldName: 'remarks' },
      { displayName: 'Create at', fieldName: 'creationDate', orderField: "creationDate"},
    ],
    reportListDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Name', fieldName: 'name', linked: false },
      { displayName: 'Report type', fieldName: 'reportType' },
      { displayName: 'Time range', fieldName: 'displayTimeRange' },
      { displayName: 'Create at', fieldName: 'creationDate' },
    ],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    currentLastCursor: '',
    currentPage: 0,
    totalPage: 0,
    totalCount: 0,
    checkedList: [],
    currentPagePointTransactionList: [],
    selectedPointTransaction: {},
    maxPointValue: 0,
    currentPagePointTransactionReportList: [],
  };
}

function parsePointTransactionType(transactionType) {
  switch (transactionType) {
    case 'CUSTOMER_EARN':
      return POINT_TRANSACTION_TYPE.CUSTOMER_EARN;
    case 'CUSTOMER_REDEEM_GIFT_CARD':
      return POINT_TRANSACTION_TYPE.CUSTOMER_REDEEM_GIFT_CARD;
    case 'CUSTOMER_USED':
      return POINT_TRANSACTION_TYPE.CUSTOMER_USED;
    case 'CUSTOMER_USED_CASH_DOLLAR':
      return POINT_TRANSACTION_TYPE.CUSTOMER_USED_CASH_DOLLAR;
    case 'ADMIN_EDITED':
      return POINT_TRANSACTION_TYPE.ADMIN_EDITED;
    case 'EXPIRED':
      return POINT_TRANSACTION_TYPE.EXPIRED;
    case 'ADMIN_VOIDED':
      return POINT_TRANSACTION_TYPE.ADMIN_VOIDED;
    default:
      break;
  }
}

function parsePointType(pointType) {
  switch (pointType) {
    case 'TYPE_POINT_ADD':
      return POINT_RECORD_TYPE.TYPE_ADD;
    case 'TYPE_POINT_REMOVE':
      return POINT_RECORD_TYPE.TYPE_REMOVE;
    default:
      break;
  }
}

function parsePointSource(pointSource) {
  switch (pointSource) {
    case 'LOYALTY':
      return 'Loyalty';
    case 'GIFT_CARD':
      return 'Gift card';
    case 'LOYALTY_AND_GIFT_CARD':
      return 'Loyalty and Gift card';
    default:
      return pointSource;
  }
}

function parsePointReportType(reportType) {
  switch (reportType) {
    case 'WEEKLY':
      return 'Weekly';
    case 'MONTHLY':
      return 'Monthly';
    default:
      break;
  }
}

function parsePointTransactionReport(item) {
  return {
    pk: item.pk,
    id: item.id,
    name: item.reportName,
    reportType: parsePointReportType(item.reportType),
    displayTimeRange: `${formatDate(item.startTime, 'DD MMM yyyy')} - ${formatDate(item.endTime, 'DD MMM yyyy')}`,
    creationDate: formatDate(item.creationDate, 'DD MMM yyyy'),
    reportFileUrl: item.reportFileUrl,
  };
}

function parsePointTransactionRecord(item) {
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.customer?.membershipId,
    ssoUid: item.customer?.ssoUid,
    pointType: item.pointType,
    pointDisplayType: parsePointType(item.pointType),
    transactionType: item.transactionType,
    transactionDisplayType: parsePointTransactionType(item.transactionType),
    points: item.value,
    giftCardCode: item.giftCardCodes?.join(', '),
    pointSource: parsePointSource(item.pointSource),
    giftCardPoints: item.giftCardPoints,
    loyaltyPoints: item.loyaltyPoints,
    tpe:item.tpe,
    remarks: item.remarks,
    usedType: item.usedType,
    usedDisplayType: item.usedType
      ? capitalizeFirstLetter(item.usedType?.replace(/_/g, ' ').toLowerCase())
      : null,
    useDate: item.usedDate
      ? formatDate(item.usedDate, 'DD MMM yyyy (ddd), HH:mm a')
      : null,
    creationDate: getDisplayDate(item.creationDate),
    expiryDate: item.expireAtDate
      ? formatDate(item.expireAtDate, 'DD MMM yyyy (ddd), HH:mm a')
      : null,
    name: item.customer
      ? item.customer?.nickname
        ? `${item.customer?.firstName} ${item.customer?.lastName} (${item.customer?.nickname})`
        : `${item.customer?.firstName} ${item.customer?.lastName}`
      : null,
    administratorName: item.administrator?.username,
    customer: item.customer,
    createdDate: formatDate(item.creationDate),
    expiredTime: formatDate(item.expireAtDate),
    source: item.transactionType,
  };
}

export default {
  namespace: 'pointTransactionList',
  state: getInitState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    getCurrentPagePointRecords: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        const serviceArgs = [getPointTransactionRecords, afterCursor, payload];
        function* onSuccess(data) {
          const pointTransactionData = data.pointTransactions.edges;
          const pageInfo = data.pointTransactions.pageInfo;
          const totalCount = data.pointTransactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const pointTransactionList = pointTransactionData.map((item) =>
            parsePointTransactionRecord(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPagePointTransactionList: pointTransactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
              maxPointValue: data.pointTransactions.maxPointValue,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSinglePointRecord: [
      function* ({ payload }, { put }) {
        const { pointRecordPK } = payload;
        const pointRecordID = btoa(`PointTransactionNode:${pointRecordPK}`);
        const serviceArgs = [getOnePointTransactionRecord, pointRecordID];
        function* onSuccess(data) {
          const pointRecordData = data.pointTransaction;
          yield put(
            createAction('updateState')({
              selectedPointTransaction: parsePointTransactionRecord(
                pointRecordData,
              ),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPagePointReports: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getPointTransactionReports, afterCursor, payload];
        function* onSuccess(data) {
          const pointTransactionReportsData = data.pointReports.edges;
          const pageInfo = data.pointReports.pageInfo;
          const totalCount = data.pointReports.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const pointTransactionReportList = pointTransactionReportsData.map((item) =>
            parsePointTransactionReport(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPagePointTransactionReportList: pointTransactionReportList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};

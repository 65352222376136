import React from 'react';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { withResizeDetector } from 'react-resize-detector';
import { Controller, useFormContext } from 'react-hook-form';
import { hasReactHookFormError } from '../base/ErrorFieldMessage';

function TransactionTypeMutipleSelector({
  width, fieldBaseName,
}) {
  const { control, setValue, watch, formState: { errors } } = useFormContext();
  const selectedFieldName = `${fieldBaseName}.transactionTypes`;
  const mutipleSelecteOptions = [
		{ pk: 'ONLINE', name: 'Online', value: 'ONLINE' },
		{ pk: 'OFFLINE_POS', name: 'Offline Sales', value: 'OFFLINE_POS' },
		{ pk: 'OFFLINE_REWARD_CLAIM', name: 'Offline Reward Claim', value: 'OFFLINE_REWARD_CLAIM' },
	];;
  const defaultSelected = watch(selectedFieldName);

  return (
		<div style={{ width: '100%' }}>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				<Controller
					control={control}
					name={selectedFieldName}
					shouldUnregister
					render={({ field }) => (
						<div style={width < 972 ? { width: '100%' } : { flex: 1 }}>
							<BaseMultipleSelectorV2
								title="Transaction types (optional)"
								data={{
									sourceData: mutipleSelecteOptions,
									targetData: defaultSelected || [],
									targetChange: (options) => {
										setValue(field.name, options, { shouldDirty: true });
									},
								}}
								sourceReminderText={'Please select item(s)'}
								amidReminderText={'Please select item(s)'}
								error={{
									error: hasReactHookFormError(errors, field.name),
									message: 'Please select at least one item',
								}}
							// isReset={isReset}
							/>
						</div>
					)}
				/>
			</div>
		</div>
  ); 
}

export default withResizeDetector(TransactionTypeMutipleSelector);

import { getMessageCreditRecords, getMessageCreditRecord } from 'services/MessageRecordsApiHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  createAction,
  formatNumberWithCommas,
} from '../utils';
import { TimeFormater, formatDate } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { loading } from './LoadingUtil';

function getInitState() {
  return {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      {
        displayName: 'Message',
        fieldName: 'displayMessageName',
        orderField: 'name',
        linked: true,
        deeplink: (item) => {
          if (!item?.message) {
            return ''
          }
          return `/messages/${item.message.pk}/`
        }
      },
      {
        displayName: 'Admin',
        fieldName: 'displayMessageAdmin',
        orderField: 'messageAdmin',
      },
      {
        displayName: 'Message create date',
        fieldName: 'displayMessageCreationDate',
        orderField: 'messageCreationDate',
      },
      {
        displayName: 'Credit consumed',
        fieldName: 'displayCreditConsum',
        orderField: 'realCreditSpent',
      },
      {
        displayName: 'Create at',
        fieldName: 'displayCreationDate',
        orderField: 'creationDate',
      },
    ],
    messageCreditRecordList: [],
  };
}

function parseMessageCreditRecord(item) {
  return {
    pk: item?.pk || "-",
    message: item?.message,
    name: item?.message?.name || "-", 
    displayMessageName: item?.message?.pk ? `[${item?.message?.pk}]${item?.message?.name || "-"}` : "-", 
    admin: item?.message?.admin,
    displayMessageAdmin: item?.message?.admin ? `[${item?.message?.admin?.pk}]${item?.message?.admin?.username || ""}` : "-",
    messageCreateDate: item?.message?.creationDate,
    displayMessageCreationDate: formatDate(
      item?.message?.creationDate,
      TimeFormater.dayMonthWeek,
    ),
    creditConsum: item?.message?.realCreditSpent,
    displayCreditConsum: formatNumberWithCommas(item?.message?.realCreditSpent || "") || "0.00", 
    creationDate: item?.creationDate,
    displayCreationDate: formatDate(
      item?.creationDate,
      TimeFormater.dayMonthWeek,
    ),
  };
}

export default createModel({
  namespace: 'messageCreditRecord',
  states: getInitState(),
  params: {
    objectKey: 'messageCreditRecords',
    pkNode: 'MessageCreditRecordNode',
    detailAPI: getMessageCreditRecord,
    parseDetail: (data) => parseMessageCreditRecord(data?.messageCreditRecord),
    initState: getInitState(),
  },
  reducers: {},
  effects: {
    getCurrentPageMessageCreditRecords: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = "";
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getMessageCreditRecords, afterCursor, payload];
        function* onSuccess(data) {
          const messageCreditRecordData = data.messageCreditRecords?.edges;
          const pageInfo = data.messageCreditRecords?.pageInfo;
          const totalCount = data.messageCreditRecords.totalCount;
          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);
          const messageCreditRecordDataList = messageCreditRecordData.map((item) =>
            parseMessageCreditRecord(item.node),
          );
          yield put(
            createAction('updateState')({
              pagedList: messageCreditRecordDataList,
              pageInfo: {
                startCursor: isNaN(startCursor) ? 0 : startCursor + 1,
                endCursor: isNaN(endCursor) ? 0 : endCursor + 1,
              },
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});

import React from 'react';
import './CountComponent.scss';
function CountComponent(props) {
  const { start, end, total } = props;

  return (
    <label className="count-text">
      Showing {start} to {end} of {total} entries
    </label>
  );
}

export default CountComponent;

import { apiWithResponseHandle } from 'models/LoadingUtil';
import { getCountryCodes } from 'services/CountryCodeApiHelper';

const parseCountryCode = (item) => {
  return {
    id: item.id,
    pk: item.pk,
    name: `+${item.name}`,
    value: item.pk,
  };
};

const parseCountryCodeList = (data) => {
  return data.map((item) => {
    return parseCountryCode(item.node);
  });
};

const getInitialState = () => ({
  countryCodes: [],
});

export default {
  namespace: 'settings',
  state: getInitialState,

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    getCountryCodeList: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getCountryCodes];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              countryCodes: parseCountryCodeList(data?.countryCodes?.edges),
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};

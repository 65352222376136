import {
  getCampaignsOverviewData,
  getCustomersOverviewData,
  getCouponsOverviewData,
  getStampCampaignsOverviewData,
} from '../services/AnalyticsApiHelper';
import { apiWithResponseHandle } from './LoadingUtil';
import { createAction } from '../utils';
import { parseCustomerChartData } from './DashboardModel';

const parseCampaignsApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    totalPublishedCampaign: analyticsData.totalNumberOfPublishedCampaigns,
    totalCampaignConversion: analyticsData.totalNumberOfCampaignConversions,
    totalCampaignViews: analyticsData.totalNumberOfCampaignViews,
    couponCampaignConversionRate: analyticsData.couponCampaignConversionRate,
    earningCampaignConversionRate: analyticsData.earningCampaignConversionRate,
    campaignShareRate: analyticsData.campaignShareRate,

    totalPointsEarned: analyticsData.totalPointsEarned,
    totalPointsUsed: analyticsData.totalPointsUsed,

    totalCouponActivities: analyticsData.totalNumberOfCouponActivities,
    totalCouponRedeemByPoints:
      analyticsData.totalNumberOfCouponsRedeemedByPoints,
    totalCouponUsed: analyticsData.totalNumberOfCouponsUsed,
    totalCouponAcquiredByCampaigns:
      analyticsData.totalNumberOfCouponsAcquiredByCampaigns,
  };
};

const parseCustomersApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    totalCustomers: {
      ...analyticsData.totalNumberOfCustomers,
    },
    activeCustomers: {
      ...analyticsData.totalNumberOfActiveCustomers,
    },
    newCustomers: {
      ...analyticsData.totalNumberOfNewCustomers,
    },
    nonRegistedCustomers: {
      ...analyticsData.totalNumberOfGuestCustomers,
    },
    chartData: parseCustomerChartData(
      analyticsData.totalNumberOfNewCustomersChart,
    ),
    tempEarningCustomers: {
      ...analyticsData.totalNumberOfTemporaryEarningCustomers,
    },
    tempCustomers: {
      ...analyticsData.totalNumberOfTemporaryCustomers,
    },
  };
};

const parseCouponsApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    totalCouponActivities: analyticsData.totalNumberOfCouponActivities,
    totalCouponRedeemByPoints:
      analyticsData.totalNumberOfCouponsRedeemedByPoints,
    totalCouponUsed: analyticsData.totalNumberOfCouponsUsed,
    totalCouponAcquiredByCampaigns:
      analyticsData.totalNumberOfCouponsAcquiredByCampaigns,
  };
};

const parseStampCampaignsApiData = (resp) => {
  const { analyticsData } = resp;
  return {
    ...analyticsData,
  };
};

export default {
  namespace: 'overview',
  state: {
    customers: {},
    coupons: {},
    campaigns: {},
    stampCampaigns: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearState(state, { payload }) {
      return {
        customers: {},
        coupons: {},
        campaigns: {},
        stampCampaigns: {},
      };
    },
  },
  effects: {
    getCampaignsData: [
      function* ({ payload }, { put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [getCampaignsOverviewData, startDate, endDate];
        function* onSuccess(data) {
          const processedData = parseCampaignsApiData(data);
          yield put(createAction('updateState')({ campaigns: processedData }));
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@Get campaigns overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCustomersData: [
      function* ({ payload }, { put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [getCustomersOverviewData, startDate, endDate];
        function* onSuccess(data) {
          const processedData = parseCustomersApiData(data);
          yield put(createAction('updateState')({ customers: processedData }));
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@Get customers overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCouponsData: [
      function* ({ payload }, { put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [getCouponsOverviewData, startDate, endDate];
        function* onSuccess(data) {
          const processedData = parseCouponsApiData(data);
          yield put(createAction('updateState')({ coupons: processedData }));
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@Get coupons overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getStampCampaignsData: [
      function* ({ payload }, { put }) {
        const { startDate, endDate } = payload;
        const serviceArgs = [getStampCampaignsOverviewData, startDate, endDate];
        function* onSuccess(data) {
          const processedData = parseStampCampaignsApiData(data);
          yield put(
            createAction('updateState')({ stampCampaigns: processedData }),
          );
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@Get stamp campaigns overview data failed: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};

import {
  LanguageConfig,
} from '../../../config/CustomEnums';


export const CreateBrandError = {
  brandName: {
    message: 'Please provide a name.',
  },
  brandIcon: {
    message: 'Please provide a logo image.',
  },
};

function firstStepValidate({ getValues, setError }) {
  const nameField = `translations.${LanguageConfig.english}.name`;
  const coverPhotoField = 'coverPhoto';
  const [
    name,
    coverPhoto,
  ] = getValues([
    nameField,
    coverPhotoField,
  ]);
  const errorList = [];
  if (!name) {
    errorList.push({
      name: nameField,
      message: CreateBrandError.brandName.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: coverPhotoField,
      message: CreateBrandError.brandIcon.message,
    });
  }
  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      }
    );
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step }) {
  console.log('@71 brandValidate:', getValues());
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
  }
}

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import AuthButton from 'components/base/AuthButton';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import Loading from 'components/base/Loading';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import { APIStatus } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  giftCardRecordDetail: state.giftCardRecord.detail,
  status: state.loading.status,
});

function GiftCardDetail({ giftCardRecordDetail, status }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={
            status === APIStatus.calling
              ? [<Loading />]
              : [
                  <MembershipInfoCard
                    title="Gift card record"
                    data={giftCardRecordDetail}
                    fields={[
                      [{ title: 'ID', field: 'pk' }],
                      [{ title: 'Gift card code (String)', field: 'code' }],
                      [{ title: 'Type', field: 'displayType' }],
                      [{ title: 'Cash value', field: 'cashValue' }],
                      [{ title: 'Points expiry date', field: 'expiryAt' }],
                      [{ title: 'Creation at', field: 'creationAt' }],
                      [{ title: 'status', field: 'displayAction' }],
                    ]}
                  />,
                ]
          }
          hidePreview
          // sectionStyle="detail-info-section-item"
        />
      ),
    },
  ];

  const buttons = [];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('giftCardRecord/getOneDetail')({
          id: params.id,
          view: true,
        }),
      );
    }
    return () => {
      dispatch({ type: 'giftCardRecord/clearData' });
    };
  }, [dispatch, params.id]);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: 'Gift card record',
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={giftCardRecordDetail.sourceName} />}
        buttons={giftCardRecordDetail.canEdit ? buttons : []}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

export default connect(mapPropsToState)(GiftCardDetail);

import React, { useEffect } from 'react';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { createAction } from 'utils';

import { APIStatus } from 'config/CustomEnums';
import BaseForm from 'components/base/v2/BaseForm';

import CreateLevelDiscountContent from './CreateLevelDiscountContent';

function CreateLevelDiscount({
  formHasSubmitted,
  levelDiscount,
  hasUpdatedDefaultValues,
  createStatus,
  apiStatus,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const stepSet = [<CreateLevelDiscountContent />];

  useEffect(() => {
    dispatch(createAction('levelDiscount/getOneDetail')({ id: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'levelDiscount/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiStatus === APIStatus.success) history.push('/level_discounts');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus]);

  return (
    <>
      <BaseForm
        defaultValues={levelDiscount}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'levelDiscount/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'levelDiscount/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Level Discount is successfully updated.',
          steps: null,
          onHide:()=>{},
          buttons: [
            {
              text: 'Back to Level Discount list',
              action: () => {
                history.push({
                  pathname: '/languages',
                });
              },
            },
          ],
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={levelDiscount.sourceName}
          />
        }
        caution={{
          detail: 'coming soon',
          title: levelDiscount.sourceName,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  formHasSubmitted: state.levelDiscount?.formHasSubmitted,
  levelDiscount: state.levelDiscount?.detail,
  hasUpdatedDefaultValues: state.levelDiscount?.hasUpdatedDefaultValues,
  createStatus: state.levelDiscount?.createStatus,
  apiStatus: state.levelDiscount?.apiStatus,
})

export default connect(mapPropsToState)(CreateLevelDiscount)
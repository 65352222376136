import React from 'react';
import { Button, Image } from 'react-bootstrap';

import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';

import closeIcon from 'assets/images/close.svg';

import './BaseMultipleSelectorDropDown.scss';

function BaseMultipleSelectorDropDown({
  title = '',
  filterTitle = 'title',
  value = [],
  onChange = () => {},
  disabled = false,
  showLineBrank = false,
  source = [],
  onFocus = () => {},
  customFilter = null,
  hasMarginTop = true,
  loadMoreAction = () => {},
  filterAction = () => {},
  placeholder = null,
  defaultFilter = {},
  customItem = () => {},
}) {
  return (
    <div
      className="multiple-dropdown-container"
      style={{ marginTop: hasMarginTop ? '30px' : 0 }}
      onFocus={onFocus}
    >
      {!disabled && showLineBrank ? <div className="line"></div> : null}
      {title ? <label className="create-section-title">{title}</label> : null}
      {disabled ? null : (
        <CustomTitleWithDropDown
          customClass={'filter-title'}
          title={filterTitle}
          source={source}
          placeholder={placeholder || 'Search by ID, name'}
          labelContainPk
          defaultValue={value ? value : []}
          setValue={(v) => {
            onChange(v);
          }}
          loadMoreAction={loadMoreAction}
          customFilter={customFilter}
          filterAction={filterAction}
          defaultFilter={{
            isSelectorLoad: true,
            ...defaultFilter,
          }}
          multiple={true}
          toolTips={true}
          notShowLabel={true}
          disableAutoFocus={true}
          customItem={customItem}
        />
      )}
      <div className="title-container">
        <CustomTitleLabel
          className="custom-create-section-label"
          title={`Summary of selected ${filterTitle} (${value.length})`}
        />
        {disabled ? null : (
          <Button
            className="clear-filter reset-selected"
            onClick={() => {
              onChange();
            }}
          >
            Reset
          </Button>
        )}
      </div>
      {value && value?.length > 0
        ? value?.map((item, index) => {
            return (
              <div key={`${index}`} className="store-list-item-container">
                <label className="store-list-item">{item.label}</label>
                {disabled ? null : (
                  <Image
                    src={closeIcon}
                    className="list-item-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      // eslint-disable-next-line eqeqeq
                      const newValue = value?.filter(
                        (filterItem) => filterItem.value.pk != item.value.pk,
                      );
                      onChange(newValue);
                    }}
                  />
                )}
              </div>
            );
          })
        : '-'}
    </div>
  );
}

export default BaseMultipleSelectorDropDown;

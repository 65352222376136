import React from 'react';
import { connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import CustomTips from '../base/CustomTips';

function LimitSection({
  tips = {}
}) {
  const { watch, setValue } = useFormContext();
  const overallLimit = watch('overallLimit')
  const periodicLimit = watch('periodicLimit')
  const periodicLimitDays = watch('periodicLimitDays')
  const perHeadLimit = watch('perHeadLimit')
  const perHeadPeriodicLimit = watch('perHeadPeriodicLimit')
  const perHeadPeriodicLimitDays = watch('perHeadPeriodicLimitDays')
  return (
    <>
      <InputFieldControl
        name={'overallLimit'}
        title={'Overall limit (optional)'}
        value={overallLimit}
        setValue={(value) => {
          setValue('overallLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='times'
        className='coupon-campaign-section-input'
        tips={tips.overallLimit}
      />
      <CustomTitleLabel title="Periodic limit (optional)" />
      <label className="tips-message">
        {tips.periodicLimit}
      </label>
      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={(value) => {
          setValue("periodicLimit", value, { shouldDirty: true})
        }}
        firstDefaultValue={periodicLimit}
        setSecondValue={(value) => {
          setValue("periodicLimitDays", value, { shouldDirty: true })
        }}
        secondDefaultValue={periodicLimitDays}
      />

      <InputFieldControl
        name={'perHeadLimit'}
        title={'Per head limit (optional)'}
        value={perHeadLimit}
        setValue={(value) => {
          setValue('perHeadLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='times'
        className='coupon-campaign-section-input'
        tips={tips.perHeadLimit}
      />

      <CustomTitleLabel title="Per head periodic limit (optional)" />
      <label className="tips-message">
        {tips.perHeadPeriodicLimit}
      </label>
      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={(value) => {
          setValue("perHeadPeriodicLimit", value, { shouldDirty: true})
        }}
        firstDefaultValue={perHeadPeriodicLimit}
        setSecondValue={(value) => {
          setValue("perHeadPeriodicLimitDays", value, { shouldDirty: true })
        }}
        secondDefaultValue={perHeadPeriodicLimitDays}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
})

export default connect(mapPropsToState)(LimitSection);


export function LevelUpgradePerHeadPeriodicLimitSection({
}) {
  const { watch, setValue } = useFormContext();
  const perHeadPeriodicLimit = watch('perHeadPeriodicLimit')
  const perHeadPeriodicLimitDays = watch('perHeadPeriodicLimitDays')
  return (
    <>
      <label className="create-section-title">LIMIT</label>
      <CustomTitleLabel title="Per head periodic limit (optional)" />
      <div className="create-section-label">
        <CustomTips
          detail={"•In the case of item returns, the customer may experience a level upgrade with the first purchase, followed by a level downgrade upon returning the item. The second level upgrade occurs when making the second purchase. So a limit setting may be needed.\
          \n•For example, if it is set as 2 times per 30 days, the customer can receive a maximum of 2 instances of the same rewards within a 30-day period starting from the date of the first reward issuance."}
        >
          <label className="tips-message">
          Set limit to the number of times a customer can get the reward from this earning rules within a time period.
          </label>
        </CustomTips>
      </ div>

      <CustomNumberMutipleInputWithUnit
        per="times per"
        unit="days"
        setFirstValue={(value) => {
          setValue("perHeadPeriodicLimit", value, { shouldDirty: true})
        }}
        firstDefaultValue={perHeadPeriodicLimit}
        setSecondValue={(value) => {
          setValue("perHeadPeriodicLimitDays", value, { shouldDirty: true })
        }}
        secondDefaultValue={perHeadPeriodicLimitDays}
      />
      </>
  );
}
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import CreateBrandContent from '../../../components/brand/CreateBrandContent';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import { validate } from './CreateBrandValidate';


function CreateBrandStepOne({
  selectedBrand,
  languages,
  setShowCompleteProcess,
  setShowDeletePrompt = () => {},
}) {
  const queryString = require('query-string');
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { setError, clearErrors, getValues } = useFormContext();

  useEffect(() => {
    dispatch({
      type: 'language/getList',
      payload: {
        isSelectorLoad: true,
      }
    });
  }, [dispatch, params.id]);

  const getLanguageTab = (language) => {
    return [<CreateBrandContent language={language} />];
  };

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const gotoBrandList = () => {
    dispatch({
      type: 'brand/saveOrRemoveBrandFromCookie',
      payload: {
        save: false,
      }
    });
    history.push({
      pathname: '/brands',
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  const gotoBrandDetail = () => {
    dispatch({
      type: 'brand/saveOrRemoveBrandFromCookie',
      payload: {
        save: false,
      }
    });
    history.push({
      pathname: `/brands/${params.id}/`,
    });
  };

  const save = function() {
    console.log('@@53: ', getValues());
    let isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (!isValid) {
      return;
    }
    if (params.id) {
      dispatch({
        type: 'brand/updateBrand',
        payload: {
          id: params.id,
          values: getValues(),
          afterActions: gotoBrandList,
        }
      });
    } else {
      dispatch({
        type: 'brand/createBrand',
        payload: {
          values: getValues(),
          afterActions: () => setShowCompleteProcess(true),
        }
      });
    }
  };

  return (
    <>
      <ContentSections
        languageTabContent={languageTabConfig}
        hidePreview
      />

      <SaveAndBackButtons
        backAction={() => {
          if (params.id) {
            gotoBrandDetail();
          } else {
            gotoBrandList();
          }
        }}
        saveTempText={params.id ? 'Delete Brand' : null}
        saveTempAction={() => {
          dispatch({
            type: 'brand/updateState',
            payload: {
              checkedList: [selectedBrand],
            }
          });
          setShowDeletePrompt(true);
        }}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={() => {
          save();
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedBrand: state.brand.selectedBrand,
  languages: state.language.allList,
})

export default connect(mapPropsToState)(CreateBrandStepOne);

import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomRadios from 'components/base/CustomRadios';
import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import InputFieldControl from 'components/base/InputFieldControl';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import {
  EXPIRY_TYPE_CONFIG,
  POINTS_EXPIRY_DATE_VALUE,
} from 'config/CustomEnums';
import { getValidateVariantErrors } from 'services/GiftCardAPIHelper';

import DeleteIcon from 'assets/images/deleteIcon.png';

import './VirtualGiftCardVariant.scss';

const VirtualGiftCardVariant = ({
  index,
  canDelete = false,
  item,
  setItem = () => {},
  deleteItem = () => {},
}) => {
  const [cashValue, setCashValue] = useState(item.cashValue);
  const [price, setPrice] = useState(item.price);
  const [activePeriodType, setActivePeriodType] = useState(
    item.activePeriodType,
  );
  const [endDate, setEndDate] = useState(item.endDate);
  const [expireDays, setExpireDays] = useState(item.expireDays);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const variantData = {
      pk: item.pk,
      cashValue,
      price,
      activePeriodType,
      endDate,
      expireDays,
    };
    setItem(variantData);
    const errorsData = getValidateVariantErrors(variantData);
    setErrors(errorsData);
  }, [cashValue, price, activePeriodType, endDate, expireDays]);

  return (
    <>
      <div className="variant-title">{`CASH VALUE ${index + 1}`}</div>
      <div className="content-area gift-card-config-variant">
        <div className="display-flex-area">
          <CustomTitleWithInput
            title="Cash value"
            type="number"
            defaultValue={cashValue}
            setValue={(value) => {
              setCashValue(value);
            }}
            error={{
              error: errors?.includes('cashValue'),
              id: 'cashValue',
              message: 'Please input a valid cash value',
            }}
          />

          <CustomTitleWithInput
            title="Price"
            type="number"
            defaultValue={price}
            setValue={(value) => {
              setPrice(value);
            }}
            error={{
              error: errors?.includes('price'),
              id: 'price',
              message: 'Please input a valid price',
            }}
          />

          <CustomTitleLabelWithEditorBar title={'Points expiry date'} />
          <div style={{ display: 'flex', marginTop: 8 }}>
            <CustomRadios
              onChange={(value) => {
                setActivePeriodType(value);
              }}
              default={activePeriodType}
              options={EXPIRY_TYPE_CONFIG}
              disabled={false}
            />
          </div>

          {activePeriodType === POINTS_EXPIRY_DATE_VALUE.DAYS_SINCE_ACQUIRE && (
            <>
              <CustomTitleWithInput
                title={'Required days'}
                defaultValue={expireDays}
                setValue={(value) => {
                  setExpireDays(value);
                }}
                type="number"
                unit="Days"
                error={{
                  error: errors?.includes('expireDays'),
                  id: 'expireDays',
                  message: 'Please input a valid expire days',
                }}
              />
            </>
          )}

          {activePeriodType === POINTS_EXPIRY_DATE_VALUE.IN_PERIOD && (
            <>
              <CustomTitleLabel title="End date" />
              <CustomDateTimeSelect
                defaultTime={endDate ? new Date(endDate) : new Date()}
                onTimeChange={(datetime) => {
                  setEndDate(datetime);
                }}
              />
              <ReactHookFormErrorMessage errors={errors} id="endDate" />
            </>
          )}

          {canDelete && (
            <button
              className="reset-button content-delete-button-area"
              type="button"
              onClick={() => deleteItem()}
            >
              <Image src={DeleteIcon} className="content-delete-button" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default VirtualGiftCardVariant;

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown'
import { createAction } from '../../utils';

import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';

function LevelUpgradeSection({
  upgradeLevels
}) {
  const { watch, formState, setValue } = useFormContext();
  const watchLevelGoal = watch("levelGoal")
  const errors = formState.errors;

  const dispatch = useDispatch();

  useEffect(() => {
    !upgradeLevels && dispatch(createAction('levels/getLevelList')({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upgradeLevels]);
  return (
    <>
      <label className="create-section-title">LEVEL UPGRADE</label>
      <CustomTitleWithDropDown
        title={'Level upgrade (from, to)'}
        defaultValue={{
          value: watchLevelGoal,
          label: watchLevelGoal?.label || (watchLevelGoal && upgradeLevels?.find((level)=> level.pk === watchLevelGoal.pk)?.label),
        }}
        setValue={(item) => {
          const value = item?.value
          setValue('levelGoal', value, { shouldDirty: true });
        }}
        source={upgradeLevels}
        needFilter={false}
      />
      <ReactHookFormErrorMessage errors={errors} id='levelGoal' />
    </>
  );
}

const mapPropsToState = (state) => ({
  upgradeLevels: state.levels.upgradeLevels,
});

export default connect(mapPropsToState)(LevelUpgradeSection);
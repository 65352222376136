import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import {
  CREATE_EARNING_RULE,
  getCampaignCreateSuccessStepBar,
} from 'components/base/prompt/NextStepConfig';
import BaseForm from 'components/base/v2/BaseForm';
import { APIStatus, CampaignType, EarningRuleType } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { EARNING_RULES_SESSION_KEY } from 'models/CreateEarningRulesModel';

import CreateEarningRuleStepOne from './CreateEarningRuleStepOne';
import CreateEarningRuleStepThree from './CreateEarningRuleStepThree';
import CreateEarningRuleStepTwo from './CreateEarningRuleStepTwo';

import {
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from 'utils';

function CreateEarningRule({
  stepConfig,
  currentStep,
  earningRule,
  // savedSucess,
  createStatus,
  hasUpdatedDefaultValues,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const earningRuleId = params.id;
    if (earningRuleId) {
      dispatch(
        createAction('createEarningRules/getOneEarningRule')({
          id: earningRuleId,
        }),
      );
    } else {
      dispatch({
        type: 'createEarningRules/loadEarningRuleFromCookie',
      });
    }
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch({ type: 'createEarningRules/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const [stepSet, setStepSet] = useState([<Loading />]);

  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: earningRule.generalName,
        model: 'createEarningRules',
      };
    } else {
      return {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: 'Create Earning Rule ',
        model: 'createEarningRules',
      };
    }
  };

  const toEarningRuleList = () => {
    history.push('/earns');
  };

  const stepSet = [
    <CreateEarningRuleStepOne />,
    <CreateEarningRuleStepTwo />,
    <CreateEarningRuleStepThree />,
  ];
  const watchForStamp = useSelector((state) => state.earningRuleList.forStamp);
  const createEarningCampaign = [
    EarningRuleType.qrCodeScanning,
    EarningRuleType.gpsCheckIn,
    EarningRuleType.memberReferral,
  ].includes(earningRule.type);
  console.log('@84', createStatus, APIStatus.success, earningRule);
  return (
    <>
      <BaseForm
        defaultValues={earningRule}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={createStatus === APIStatus.success}
        hasleavePagePrompt={params.id}
        hasCancelButton={params.id}
        tempSave={(save, getValues) => {
          console.log('@92', save, getValues());
          if (save) {
            saveToSessionStorage(EARNING_RULES_SESSION_KEY, getValues());
          } else if (!params.id) {
            removeFromSessionStorage(EARNING_RULES_SESSION_KEY);
          }
        }}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={<CustomBreadcrumb name={earningRule.generalName} />}
        stepsConfig={stepConfig}
        caution={caution()}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: watchForStamp
            ? `The earning rule is successfully created.\n Let's create a stamp campaign to activate the earning rule.`
            : `The earning rule is successfully created.\n Let’s create ${createEarningCampaign ? "an earning": "a general message"} campaign to announce the new earning rule.`,
          steps: {
            stepNames: getCampaignCreateSuccessStepBar(
              watchForStamp
                ? CampaignType.stampCampaign
                : (
                  createEarningCampaign
                  ? CampaignType.earningRulesCampaign
                  : CampaignType.generalMessageCampaign
                ),
              true,
            ),
            current: CREATE_EARNING_RULE,
          },
          onHide: () => {},
          buttons: [
            watchForStamp
              ? {
                  text: 'Create Stamp campaign',
                  action: () => {
                    history.push({
                      pathname: '/stamp_campaigns/create',
                      search: ``,
                    });
                  },
                  requires: PermissionCodes.addCampaign,
                }
              : {
                  text: `Create ${createEarningCampaign ? "earning" : "general message"} campaign`,
                  action: () => {
                    history.push({
                      pathname: '/campaigns/create',
                      search: `type=${createEarningCampaign ? CampaignType.earningRulesCampaign : CampaignType.generalMessageCampaign}&id=${earningRule?.pk}`,
                    });
                  },
                  requires: PermissionCodes.addCampaign,
                },
            {
              text: 'Maybe Later',
              action: toEarningRuleList,
              requires: PermissionCodes.changeMessage,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: toEarningRuleList,
          onClose: toEarningRuleList,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createEarningRules.stepConfig,
  currentStep: state.createEarningRules.currentStep,
  earningRule: state.createEarningRules.earningRule,
  // savedSucess: state.createEarningRules.saved,
  createStatus: state.createEarningRules.createStatus,
  hasUpdatedDefaultValues: state.createEarningRules.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CreateEarningRule);

import React from 'react';
import { useFormContext } from 'react-hook-form';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import {
  LanguageConfig,
} from '../../config/CustomEnums';
import i18n from '../../I18n';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

const CreateStoreCategoryContent = ({ language }) => {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const nameField = `translations.${language}.name`;
  const orderField = `order`;
  const watchName = watch(nameField);
  const watchOrder = watch(orderField);

  console.log(
    '@@49: ',
    language,
    watchName,
    watchOrder,
  );
  const isEnglish = language === LanguageConfig.english;
  return [
    <>
      <div className="create-section-title">
        {i18n.t('category', { locale: language })}
      </div>
      <CustomTitleWithInput
        title={i18n.t('name', { locale: language })}
        type={'text'}
        setValue={(value) => {
          setValue(nameField, value, { shouldDirty: true, });
        }}
        extra={{
          maxLength: 50,
        }}
        defaultValue={watchName}
        customClass={`custom-markdown-area-title `}
        error={{ error: hasError(errors, nameField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={nameField} />

      {isEnglish ? (
        <>
          <CustomTitleWithInput
            title={'Display order'}
            type={'number'}
            setValue={(value) => {
              setValue(orderField, value, { shouldDirty: true, });
            }}
            defaultValue={watchOrder}
            customClass={`coupon-campaign-section-input `}
            error={{ error: hasError(errors, orderField) }}
          />
          <ReactHookFormErrorMessage errors={errors} id={orderField} />
          <label className="coupon-campaign-section-caution-description">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
        </>
      ) : null}
    </>
  ];
};

export default CreateStoreCategoryContent;

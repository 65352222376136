import { LanguageConfig } from '../../../config/CustomEnums';
import { anchorElementWithId } from '../../../utils';
export const StampCampaignErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a stamp campaign name and ensure the length not more than 200.',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  squarePhoto: {
    name: 'squarePhoto',
    message: 'Please provide an square photo.',
  },
  shortDescription: {
    name: 'shortDescription',
    message:
      'Please provide a short description and ensure the length not more than 200.',
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide a target customer group.',
  },
  segment: {
    name: 'customerGroup',
    message: 'Please provide a target segment.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a stamp campaign cannot be on or before the start date and time.',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message:
      'The end date & time of a stamp campaign cannot be on or before the start date and time.',
  },
  requiredPoints: {
    name: 'requiredPoints',
    message: 'Please provide a required/valid points.',
  },
  overallLimit: {
    name: 'overallLimit',
    message:
      'Coupon campaign overall limit should be smaller than the number of generated coupon of given coupon template.',
  },
  perHeadLimit: {
    name: 'perHeadLimit',
    message:
      'Coupon campaign overall limit must be larger than per head limit.',
  },
  linkedEarningRules: {
    name: 'linkedEarningRules',
    message: 'At least one task should be selected.',
  },
  stampReward: {
    name: 'stampRewards',
    message: 'Please select at least one stamp reward.',
  },
  linkedCoupon: {
    name: 'linkedCoupon',
    message: 'Please select at least one coupon set.',
  },
  numberLimit: {
    name: 'numberLimit',
    message: 'Please enter 1 to 9999.',
  },
  requiredStamps: {
    name: 'requiredStamps',
    message: 'Same no. of badges already exists. Please enter another number.',
  },
  quantityRequired: {
    name: 'quantityRequired',
    message: 'Please provide a quantity.',
  },
  couponQuantity: {
    name: 'couponQuantity',
    message:
      'Exceed current coupon stock. Please generate more coupons or input a smaller number.',
  },
  fileRequired: {
    name: 'fileRequired',
    message: 'Please upload a file.',
  },
  fileRequiredForAllLanguage:{
    name:'fileRequiredForAllLanguage',
    message: 'Please upload a file for each language.'
  },
  checkList: {
    name: 'checkList',
    message: 'Please review the item and tick the checkbox.'
  },
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};

export const COUPON_PIE_CHART_CONFIG = [
  { color: '#7375E2', name: 'Coupon acquired by points', key: 'totalPoints' },
  {
    color: '#00A9C8',
    name: 'Coupon acquired by earning campaigns',
    key: 'totalCampaigns',
  },
  {
    color:'#E6BB4B',
    name:'Coupon acquired in stamp campaign',
    key:'totalNumberOfCouponsAcquiredByCampaigns'
  },
  { color: '#E64A7B', name: 'Coupon used', key: 'totalUsed' },
];
export const ANIMATION_DURATION = 500;

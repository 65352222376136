import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search.slice(1));
  const showInCustomerProfileParsed = parsedSearch['show_in_customer_profile'] || '';

  const [showInCustomerProfile, setShowInCustomerProfile] = useState(showInCustomerProfileParsed);

  const content = [
    {
      title: 'Show in customer profile',
      data: [
        { name: 'Yes', pk: 'true' },
        { name: 'No', pk: 'false' },
      ],
      value: showInCustomerProfile,
      setValue: setShowInCustomerProfile,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import './CreateStoreCategory.scss';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import CreateStoreCategoryContent from '../../../components/storeCategory/CreateStoreCategoryContent';
import CreateStoreSubCategoryContent from '../../../components/storeCategory/CreateStoreSubCategoryContent';
import { validate } from '../../../containers/merchants/storeCategory/CreateStoreCategoryValidate';
import {
  LanguageConfig,
} from '../../../config/CustomEnums';


const CreateStoreCategoryStepOne = ({
  languages,
  setShowDeletePrompt = () => {},
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true }
    });
  }, [dispatch]);

  const { watch, setValue, getValues, setError, clearErrors } = useFormContext();

  const watchSubCategories = watch(`subCategories`);

  const getLanguageTab = (language) => {
    const getSubCategories = (language) => {
      return watchSubCategories.map((subCategory, index) => {
        return [
          <CreateStoreSubCategoryContent
            index={index}
            language={language}
          />
        ];
      });
    };
    const isEnglish = language === LanguageConfig.english;
    return [
      <CreateStoreCategoryContent
        language={language}
      />,
      ...getSubCategories(language),
      isEnglish
        ? (
          <Button
            onClick={() => {
              const tempArray = [].concat(watchSubCategories);
              const translations = {};
              (languages || []).forEach((item) => {
                translations[item.code] = {
                  name: null
                };
              });
              const value = {
                id: null,
                name: null,
                order: null,
                translations: translations
              };
              tempArray.push(value);
              setValue('subCategories', tempArray, {  shouldDirty: true, });
            }}
            className="btn-back-button-common add-sub-category-btn"
          >
            Add Subcategory
          </Button>
        )
        : null
    ];
  };

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const save = async () => {
    clearErrors();
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (!isValid) {
      return;
    }
    if (params.id) {
      const uploadData = {
        values: getValues(),
        categoryID: params.id,
      };
      dispatch({
        type: 'createStoreCategory/checkAndUpdate',
        payload: uploadData,
      });
    } else {
      const uploadData = {
        values: getValues(),
      }
      dispatch({
        type: 'createStoreCategory/checkAndSave',
        payload: uploadData,
      });
    }
  };

  return (
    <>
      <ContentSections
        languageTabContent={languageTabConfig}
        hidePreview
      />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => save()}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateStoreCategoryStepOne);

import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import './CouponTransaction.scss';
import { useDispatch, connect } from 'react-redux';
import { createAction, getHashKeyString } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import CustomRadios from '../../../components/base/CustomRadios';
import BasePrompt from '../../../components/base/prompt/BasePrompt';

const TAB_RECORDS = "Records"
const TAB_REPORTS = "Reports"

function CouponTransaction({
  tableFields,
  dataList,
  pageInfo,
  totalCount,
  totalPage,
  currentPage,
  reportListDisplayFields,
  reportDataList,
  checkedList,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDownloadReportPrompt, setShowDownloadReportPrompt] = useState(false);
  const [isExportAllReports, setIsExportAllReports] = useState(true);
  const activeTab = getHashKeyString(location.hash) || TAB_RECORDS;

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    // const couponTransactionType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const reportType = searchDict['report_type'];
    const timeRange = searchDict['time_range'];
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    if (activeTab === TAB_RECORDS) {
      dispatch(
        createAction('couponTransactions/getCurrentPageCouponTransactions')({
          ...searchDict,
          // couponTransactionType,
          searchKey,
          rank,
          page,
        }),
      );
      dispatch(
        createAction('campaignList/getCampaignList')({
          campaignIds: searchDict['campaigns'], 
          isSimpleList: true,
          isSelectorLoad: true,
          isAvailable: false,
          noLoading: true
        }),
      );
    }
    if (activeTab === TAB_REPORTS) {
      dispatch(
        createAction('couponTransactions/getCurrentPageCouponTransactionsReports')({
          ...searchDict,
          reportType,
          timeRange,
          searchKey,
          rank,
          page,
        }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, queryString]);

  const viewCouponSetAction = () => {
    history.push({
      pathname: '/coupons',
      search: queryString.stringify({
        page: 1,
        rank: true,
        searchKey: '',
      }),
    });
  };
  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    let expiredDateDict = {}
    if (parsedSearch?.expired_date){
      switch (parsedSearch.expired_date) {
        case 'absolute':
          if (parsedSearch?.absolute) {
            const expiredDateRange = parsedSearch?.absolute?.split(',');
            expiredDateDict = {
              absoluteGte: expiredDateRange?.[0],
              absoluteLte: expiredDateRange?.[1]
            }
          }
          break;
        case 'relativeDay':
          if (parsedSearch?.relativeDay) {
            expiredDateDict = {
              relativeDay: parsedSearch.relativeDay,
            }
          }
          break;
        default:
          expiredDateDict = { allTime:true } ;
          break;
      }
    }
    const couponTransactionListIn = (checkedList || []).map(item => item.pk);

    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.couponRecord,
        related: {
          ...expiredDateDict,
          typeIn: parsedSearch?.record_type?.split(','),
          creationDateGte: parsedSearch?.create_date?.split(',')?.[0],
          creationDateLte: parsedSearch?.create_date?.split(',')?.[1],
          targetCampaignIds: parsedSearch?.campaigns?.split(','),
          separateFiles: false,
          customerNameIcontains: parsedSearch?.search,
          couponTransactionListIn: couponTransactionListIn,
        },
      }),
    );
  };

  const exportReportAction = () => {
    const searchDict = queryString.parse(location.search);
    const reportType = searchDict['report_type']
    const timeRange = searchDict['time_range']?.split(',');
    const nameIcontains = searchDict['search'];
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.couponReport,
        ...(isExportAllReports || activeTab !== TAB_REPORTS ? null :
          {
            related: {
              nameIcontains,
              reportType,
              timeRangeGte: timeRange?.[0],
              timeRangeLte: timeRange?.[1],
            }
          })
      }),
    );
    setShowDownloadReportPrompt(false)
  }

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Export report"
          customClass="btn-back-button-common btn-download"
          action={() => { setShowDownloadReportPrompt(true) }}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title="Export .csv"
          customClass="btn-back-button-common btn-download"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => { setShowDownloadReportPrompt(true) },
          content: 'Export report',
        },
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: 'Export .csv',
        },
      ]}
      primaryButton={
        <AuthButton
          title="View Coupon Set"
          action={viewCouponSetAction}
          requires={PermissionCodes.viewCoupon}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.viewCoupon,
        action: viewCouponSetAction,
        content: 'View Coupon Set',
      }}
    />,
  ];

  const couponRecordTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'EARN', name: 'Customer earned' },
    { key: 'BUY', name: 'Customer Acquired' },
    { key: 'GRANT', name: 'Admin added' },
    { key: 'RECLAIM', name: 'Admin removed' },
  ];

  const reportTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'WEEKLY', name: 'Weekly' },
    { key: 'MONTHLY', name: 'Monthly' },
  ]

  const tableTabs = [
    {
      name: TAB_RECORDS,
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={couponRecordTypeTabs}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          filter={{ hasFilter: true, component: Filter }}
          listContent={
            <BaseListContainer
              fields={tableFields}
              model={'couponTransactions'}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View details',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
              customClassName="coupon-record-table"
              permissionGroup={PermissionCodes.coupontransaction}
            />
          }
        />
      ),
    },
    {
      name: TAB_REPORTS,
      content: (
        <BaseTabListContainer
          tabs={reportTypeTabs}
          tabSearchKey={'report_type'}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchPlaceholder={'Search by report name'}
          groupActions={[]}
          filter={{ hasFilter: true, component: Filter }}
          listContent={
            <BaseListContainer
              fields={reportListDisplayFields}
              model={'couponTransactions'}
              dataList={reportDataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Download',
                    requires: PermissionCodes.addExportjob,
                    href: item?.reportFileUrl,
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  const otherBody = () => {
    return (
      <div className='d-flex'>
        <CustomRadios
          onChange={(value) => {
            setIsExportAllReports(value);
          }}
          default={isExportAllReports}
          options={[
            {
              label: 'All items',
              value: true,
            },
            {
              label: 'All filtered items from the list',
              value: false,
            },
          ]}
        />
      </div>
    )
  }

  return (
    <>
    <CustomListComponent
      caution={{
        detail:
          'All coupon records are listed here, you can view the coupon transactions of each customer.',
        title: 'Coupon records',
      }}
      defaultActiveKey={activeTab}
      buttons={buttons}
      breadcrumb={<CustomBreadcrumb />}
      tabs={tableTabs}
      onTabChange={(key) => {
        history.push({ pathname: location.pathname, hash: key });
      }}
    />
    <BasePrompt
      customClass={'export-report-modal'}
      show={showDownloadReportPrompt}
      title={'Export report'}
      description={'Select the export range'}
      closeAction={() => {
        setShowDownloadReportPrompt(false);
      }}
      otherBody={otherBody}
      rightButton={{
        text: 'Export',
        action: exportReportAction,
        requires: PermissionCodes.addExportjob,
      }}
    />
    </>
  );
}

const mapPropsToState = (state) => ({
  tableFields: state.couponTransactions.listDisplayFields,
  dataList: state.couponTransactions.currentPageCouponTransactionList,
  reportDataList: state.couponTransactions.currentPageCouponTransactionReportList,
  pageInfo: state.couponTransactions.pageInfo,
  totalCount: state.couponTransactions.totalCount,
  totalPage: state.couponTransactions.totalPage,
  currentPage: state.couponTransactions.currentPage,
  reportListDisplayFields: state.couponTransactions.reportListDisplayFields,
  checkedList: state.couponTransactions.checkedList,
})

export default connect(mapPropsToState)(CouponTransaction);

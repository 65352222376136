import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './BrandList.scss';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

function BrandList({
  brandList,
  displayFields,
  totalPage,
  totalCount,
  pageInfo,
  checkedList,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const closeDeletePop = () => {
    setShowDeletePrompt(false);
  };

  const confirmDelete = () => {
    setShowDeletePrompt(false);
    dispatch({
      type: 'brand/delete',
      payload: {
        afterAction: () => {
          history.push({
            pathname: location.pathname,
            search: queryString.stringify({
              ...queryString.parse(location.search),
              page: 1,
            }),
          });
        },
      }
    });
  };

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const stringRank = searchDict['rank'] || 'true';
    const rank = stringRank === 'true';
    dispatch({
      type: 'brand/getCurrentPageBrands',
      payload: {
        searchKey: searchDict['search'],
        rank: rank,
        page: searchDict['page'] || 1,
        moreSearch: { ...searchDict },
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Create Brand"
      action={() => {
        history.push('/brands/create');
      }}
      requires={PermissionCodes.addBrand}
    />,
  ];
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteBrand,
    },
  ];
  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'brand'}
              permissionGroup={PermissionCodes.brand}
              dataList={brandList}
              totalPage={totalPage}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              deleteInfo={{
                data: [],
                title: 'brand',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.BRAND,
              }}
            />
          }
        />
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Create various brands and select them in a campaign.',
          title: 'Brands',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />

      <DeletePrompt
        data={checkedList}
        title={'Brand'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.BRAND}
        show={showDeletePrompt}
        onClose={closeDeletePop}
        onConfirm={confirmDelete}
      />
    </>
  );
}



const mapPropsToState = (state) => ({
  brandList: state.brand.currentPageBrandList,
  displayFields: state.brand.listDisplayFields,
  totalPage: state.brand.totalPage,
  totalCount: state.brand.totalCount,
  pageInfo: state.brand.pageInfo,
  checkedList: state.brand.checkedList,
});

export default connect(mapPropsToState)(BrandList);

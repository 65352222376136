import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

const NODE_CONTENT = `{
  id
  pk
  name
  parameter
  code
}`;

export const getLanguageList = (afterCursor, moreSearch) => {
  let filter = '';
  if (moreSearch.filterName || moreSearch.search) {
    filter = `, nameIcontains:"${moreSearch.filterName || moreSearch.search}"`;
  }
  
  let orderBy = moreSearch.rank ? '-pk' : 'pk';

  const query = `{
    languages(first: ${moreSearch?.isAll ? 100 : 20}, after:"${afterCursor}", orderBy:"${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${NODE_CONTENT}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getLanguage = (id) => {
  const query = `{
    language(id: "${id}") ${NODE_CONTENT}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteLanguages = (ids) => {
  const query = `mutation DeleteLanguages($input: DeleteLanguagesInput!) {
    deleteLanguages(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateLanguage = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}Language($input: ${action}LanguageInput!) {
    ${action.toLowerCase()}Language(input: $input) {
      success
      node ${NODE_CONTENT}
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

import {
  APIStatus,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_MEDIUM,
  COUPON_SET_TYPES,
  LanguageConfig,
  SESSION_KEYS,
} from 'config/CustomEnums';
// import { IMAGE_TYPES } from 'models/UploadFilesModel';
import { parseApplicationStore } from 'models/CouponUtil';
// import { CouponErrorFields } from 'components/coupon/couponCreation/CouponHandleError';
import { apiWithResponseHandle } from 'models/LoadingUtil';
import { ToastType } from 'models/NavBarModel';
// import { getCouponExpiryDate } from 'utils/TimeFormatUtil';
import { defaultStep, getNewStepConfig } from 'models/StepBarUtil';
import {
  createCouponTemplate,
  generateCoupon,
  getBarcodeFormats,
  updateCouponSet,
} from 'services/CouponAPIHelper';

import {
  createAction,
  getImageImage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from 'utils';

export const VALID_PERIOD_TYPE = {
  ALL_TIME: 'ALL_TIME',
  ABSOLUTE: 'ABSOLUTE',
  RELATIVE: 'RELATIVE',
};
export const DISCOUNT_CODE_FORMAT = {
  CODE: 'COUPON_CODE',
  URL: 'COUPON_URL',
};
const stepNameList = ['Type', 'Content', 'Properties'];

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_COUPON_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_COUPON_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_COUPON_ORIGIN_DATA_SESSION_KEY,
};

export const saveCouponToSessionStorage = (data) => {
  saveToSessionStorage(sessionDataKey.objectKey, data);
};

export const removeCouponSessionStroage = () => {
  removeFromSessionStorage(sessionDataKey.objectKey);
};

function createNewItemAutoAdd(originData, newItems) {
  const newBrand = newItems.newBrand;
  const newStore = newItems.newStore;
  if (newBrand) {
    originData.selectedBrand = newBrand;
  }
  if (newStore && newStore.brandPK === originData.selectedBrand.pk) {
    originData.selectedStores = [...originData.selectedStores, newStore];
  }
}

// function checkFields(data, coverPhoto) {
//   const generalName = data[LanguageConfig.english].name;
//   let errorFields = [];
//   if (!generalName) {
//     errorFields.push(CouponErrorFields.generalName.name);
//   }
//   if (
//     !coverPhoto ||
//     coverPhoto.length < 1 ||
//     coverPhoto.type !== IMAGE_TYPES.TYPE_URL
//   ) {
//     errorFields.push(CouponErrorFields.coverPhotoEmpty.name);
//   }
//   return {
//     invalid: errorFields.length > 0,
//     errorFields,
//     data: {
//       translations: { ...data },
//     },
//   };
// }

function parseOtherPhotoForAPI(otherPhotos) {
  let detailPhotos = {};
  if (otherPhotos && otherPhotos.length > 0) {
    otherPhotos.forEach((image, index) => {
      const detailPhotoKey = 'detailPhoto' + (index + 1);
      detailPhotos[detailPhotoKey] = getImageImage(image);
    });
  }
  let detailPhotosLength = 4;
  [...Array(detailPhotosLength)].forEach((e, i) => {
    const detailPhotoKey = 'detailPhoto' + (i + 1);
    detailPhotos[detailPhotoKey] = detailPhotos[detailPhotoKey] || null;
  });
  return detailPhotos;
}

const cleanTranslations = (translations, origionalData) => {
  const data = [];
  Object.keys(translations).forEach((language) => {
    if (language !== LanguageConfig.english) {
      const translation = {
        language,
        name: translations[language].name,
        shortDescription: translations[language].shortDescription,
        instructionSectionTitle: translations[language].instructionSectionTitle,
        instructionSectionContent:
          translations[language].instructionSectionContent,
        detailSectionTitle: translations[language].detailSectionTitle,
        detailSectionContent: translations[language].detailSectionContent,
        coverPhoto: getImageImage(translations[language].coverPhoto) || null,
        ...parseOtherPhotoForAPI(translations[language].otherPhoto),
      };
      console.log('@127', origionalData);
      if (origionalData?.translations?.[language]?.pk) {
        translation.id = origionalData.translations[language].pk;
      }
      data.push(translation);
    }
  });
  return data;
};

const cleanFormData = (data, origionalData) => {
  const {
    validPeriodType,
    mediumType,
    onlineCouponSetType,
    offlineCouponSetType,
    isDifferentCodeForOnlineOffline,
  } = data;
  const blackoutPeriod = data.validPeriodValues?.filter((item) => {
    return !!item?.startDate && !!item?.endDate;
  });
  const blackoutWeekday = data.validWeekday;
  const cleanedData = {
    applyCoverPhotoToAllLanguage: data.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: data.applyDetailPhotosToAllLanguage,
    name: data.translations[LanguageConfig.english].name,
    shortDescription:
      data.translations[LanguageConfig.english].shortDescription,
    instructionSectionTitle:
      data.translations[LanguageConfig.english].instructionSectionTitle,
    instructionSectionContent:
      data.translations[LanguageConfig.english].instructionSectionContent,
    detailSectionTitle:
      data.translations[LanguageConfig.english].detailSectionTitle,
    detailSectionContent:
      data.translations[LanguageConfig.english].detailSectionContent,
    coverPhoto: getImageImage(
      data.translations[LanguageConfig.english].coverPhoto,
    ),
    ...parseOtherPhotoForAPI(
      data.translations[LanguageConfig.english].otherPhoto,
    ),
    validPeriodType,
    blackoutPeriod,
    blackoutWeekday,
    mediumType,
  };

  if (mediumType === COUPON_SET_MEDIUM.OFFLINE) {
    cleanedData.offlineCouponTemplateType = offlineCouponSetType;
    cleanedData.offlineRedemptionMethod = data.offlineRedemptionMethod;

    if (offlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      cleanedData.offlineFormats = data.offlineFormats;
      cleanedData.offlineBarcodeFormat = data?.offlineBarcodeFormat?.value?.pk;
      cleanedData.offlineCouponCodeType = data.offlineCouponCodeType;
      cleanedData.offlineGenericCode = data.offlineGenericCode;
    }
    if (
      data.offlineRedemptionMethod === COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE
    ) {
      cleanedData.offlineShowCopiableString = !!data.offlineShowCopiableString;
    }
  }

  if (mediumType === COUPON_SET_MEDIUM.ONLINE) {
    cleanedData.onlineCouponTemplateType = onlineCouponSetType;
    cleanedData.onlineRedemptionMethod = data.onlineRedemptionMethod;

    if (onlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      cleanedData.onlineFormats = data.onlineFormats;
      cleanedData.onlineCouponCodeType = data.onlineCouponCodeType;
      cleanedData.onlineGenericCode = data.onlineGenericCode;
    }
  }

  if (mediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
    cleanedData.onlineCouponTemplateType = onlineCouponSetType;
    cleanedData.onlineRedemptionMethod = data.onlineRedemptionMethod;
    cleanedData.offlineCouponTemplateType = offlineCouponSetType;
    cleanedData.offlineRedemptionMethod = data.offlineRedemptionMethod;

    if (
      onlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
      offlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
    ) {
      cleanedData.isDifferentCodeForOnlineOffline =
        isDifferentCodeForOnlineOffline;
      if (isDifferentCodeForOnlineOffline) {
        cleanedData.onlineFormats = data.onlineFormats;
        cleanedData.onlineCouponCodeType = data.onlineCouponCodeType;
        cleanedData.onlineGenericCode = data.onlineGenericCode;

        cleanedData.offlineFormats = data.offlineFormats;
        cleanedData.offlineBarcodeFormat =
          data?.offlineBarcodeFormat?.value?.pk;
        cleanedData.offlineCouponCodeType = data.offlineCouponCodeType;
        cleanedData.offlineGenericCode = data.offlineGenericCode;
        cleanedData.offlineShowCopiableString =
          data.offlineRedemptionMethod ===
          COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE
            ? !!data.offlineShowCopiableString
            : null;
      } else {
        cleanedData.onlineFormats = data.onlineAndOfflineFormats;
        cleanedData.onlineCouponCodeType = data.onlineAndOfflineCouponCodeType;
        cleanedData.onlineGenericCode = data.onlineAndOfflineGenericCode;

        cleanedData.offlineFormats = data.onlineAndOfflineFormats;
        cleanedData.offlineCouponCodeType = data.onlineAndOfflineCouponCodeType;
        cleanedData.offlineGenericCode = data.onlineAndOfflineGenericCode;
      }
    } else if (
      onlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
    ) {
      cleanedData.onlineFormats = data.onlineFormats;
      cleanedData.onlineCouponCodeType = data.onlineCouponCodeType;
      cleanedData.onlineGenericCode = data.onlineGenericCode;
    } else if (
      offlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
    ) {
      cleanedData.offlineFormats = data.offlineFormats;
      cleanedData.offlineBarcodeFormat = data?.offlineBarcodeFormat?.value?.pk;
      cleanedData.offlineCouponCodeType = data.offlineCouponCodeType;
      cleanedData.offlineGenericCode = data.offlineGenericCode;
      cleanedData.offlineShowCopiableString =
        data.offlineRedemptionMethod ===
        COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE
          ? !!data.offlineShowCopiableString
          : null;
    }
  }

  if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE) {
    cleanedData.numberOfDaysToExpireAfterAcquisition =
      data.numberOfDaysToExpireAfterAcquisition;
  }
  if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE) {
    cleanedData.absoluteEffectiveDate = data.absoluteEffectiveDate;
    cleanedData.absoluteExpiryDate = data.absoluteExpiryDate;
  }
  if (data.selectedBrand?.pk) {
    cleanedData.brand = data.selectedBrand.pk;
    cleanedData.shouldUseInStore = data.shouldBeUsedInStores || false;
    if (data.selectedStores) {
      const applicableStores = data.selectedStores.map((store) => {
        return store.pk;
      });
      cleanedData.applicableStores = applicableStores;
    }
  }
  cleanedData.translations = cleanTranslations(
    data.translations,
    origionalData,
  );

  if (origionalData?.pk) {
    cleanedData.id = origionalData.pk;
  }
  return cleanedData;
};

// function getCouponValideType(state) {
//   if (state.isAllTime) {
//     return VALID_PERIOD_TYPE.ALL_TIME;
//   } else if (state.isAbsolute) {
//     return VALID_PERIOD_TYPE.ABSOLUTE;
//   } else if (state.isRelative) {
//     return VALID_PERIOD_TYPE.RELATIVE;
//   }
// }

// function paseConponSetInputBody(couponTemplate) {
//   const validPeriodType =
//     couponTemplate.validPeriodType || getCouponValideType(couponTemplate);
//   const otherPhotos = parseOtherPhotoForAPI(
//     couponTemplate.translations[LanguageConfig.english].otherPhoto,
//   );
//   let brandPK = null;
//   if (couponTemplate.selectedBrand) {
//     brandPK = couponTemplate.selectedBrand.pk;
//   }
//   let applicableStores = null;
//   if (couponTemplate.selectedStores) {
//     applicableStores = couponTemplate.selectedStores.map((store) => {
//       return store.storePK;
//     });
//   }
//   const inputBody = {
//     name: couponTemplate.translations[LanguageConfig.english].name,
//     instructionSectionTitle:
//       couponTemplate.translations[LanguageConfig.english]
//         .instructionSectionTitle,
//     instructionSectionContent:
//       couponTemplate.translations[LanguageConfig.english]
//         .instructionSectionContent,
//     detailSectionTitle:
//       couponTemplate.translations[LanguageConfig.english].detailSectionTitle,
//     detailSectionContent:
//       couponTemplate.translations[LanguageConfig.english].detailSectionContent,
//     validPeriodType: validPeriodType,
//     coverPhoto: getImageImage(
//       couponTemplate.translations[LanguageConfig.english].coverPhoto,
//     ),
//     offlineFormats: couponTemplate.offlineFormats,
//     ...otherPhotos,
//   };

//   if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE) {
//     inputBody.absoluteEffectiveDate =
//       couponTemplate.startDateTime || couponTemplate.absoluteEffectiveDate;
//     inputBody.absoluteExpiryDate = couponTemplate.endDateTime
//       ? getCouponExpiryDate(couponTemplate.endDateTime)
//       : couponTemplate.absoluteExpiryDate;
//   } else if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE) {
//     inputBody.numberOfDaysToExpireAfterAcquisition =
//       couponTemplate.numberOfDaysToExpireAfterAcquisition;
//   }
//   if (brandPK) {
//     inputBody.brand = brandPK;
//     inputBody.shouldUseInStore = couponTemplate.shouldBeUsedInStores;
//     if (applicableStores) {
//       inputBody.applicableStores = applicableStores;
//     }
//   }
//   return inputBody;
// }

// function parseCouponSetTranslation(
//   couponTemplatePK,
//   currentLanguageContent,
//   language,
// ) {
//   let otherPhotos = [];
//   if (currentLanguageContent.otherPhoto) {
//     otherPhotos = parseOtherPhotoForAPI(currentLanguageContent.otherPhoto);
//   }

//   const inputBody = {
//     // source: couponTemplatePK,
//     language: language,
//     name: currentLanguageContent.name ? currentLanguageContent.name : null,
//     coverPhoto: currentLanguageContent.coverPhoto
//       ? getImageImage(currentLanguageContent.coverPhoto)
//       : null,
//     instructionSectionTitle: currentLanguageContent.instructionSectionTitle,
//     instructionSectionContent: currentLanguageContent.instructionSectionContent,
//     detailSectionTitle: currentLanguageContent.detailSectionTitle,
//     detailSectionContent: currentLanguageContent.detailSectionContent,
//     ...otherPhotos,
//   };
//   if (currentLanguageContent.pk) {
//     inputBody['id'] = currentLanguageContent.pk;
//   }

//   return inputBody;
// }

function getInitState() {
  return {
    couponTemplate: {
      selectedBrand: null,
      shouldBeUsedInStores: false,
      selectedStores: null,
      applyCoverPhotoToAllLanguage: false,
      applyDetailPhotosToAllLanguage: false,
      // isAllTime: true,
      // isAbsolute: false,
      // startDateTime: null,
      // endDateTime: null,
      // isRelative: false,
      validPeriodType: VALID_PERIOD_TYPE.ALL_TIME,
      numberOfDaysToExpireAfterAcquisition: null,
      offlineFormats: null,
      generateCoupon: false,
      pk: null,
      id: null,
      name: null,
      shortDescription: '',
      instructionSectionTitle: '',
      instructionSectionContent: '',
      detailSectionTitle: '',
      detailSectionContent: '',
      coverPhoto: null,
      otherPhoto: null,
      translations: {},
    },
    // isSomeFieldError: false,
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    previewImageStatus: 0,
    fromBack: false,
    // errorFields: [],
    createCouponTemplateStatus: APIStatus.none,
    generateCoupon: {
      generateCouponStatus: APIStatus.none,
      couponTemplatePk: null,
      name: '',
      brandName: '',
      offlineFormats: null,
      offlineCouponTemplateType: null,
      offlineRedemptionMethod: null,
      offlineCouponCodeType: null,
      generateCoupon: false,
      currentSelectedStore: [],
    },
    couponQuantity: 0,
    couponGenerated: false,
    formChanged: false,
    hasUpdatedDefaultValues: false,
    formHasSubmitted: false,
  };
}

export const parseBarcode = (barcode) => {
  if (!barcode) {
    return {};
  }

  return {
    id: barcode.id,
    pk: barcode.pk,
    name: barcode.name,
  };
};

export default {
  namespace: 'createCoupon',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateGenerateCouponState(state, { payload }) {
      console.log('@23', state, payload);
      if (payload.cleanGenerateCoupon) {
        return {
          ...state,
          generateCoupon: {
            ...getInitState().generateCoupon,
            ...payload,
          },
        };
      }
      return {
        ...state,
        generateCoupon: {
          ...state.generateCoupon,
          ...payload,
        },
      };
    },

    updateCouponTemplate(state, { payload }) {
      if (payload.absoluteEffectiveDate) {
        payload.startDateTime = payload.absoluteEffectiveDate;
      }
      if (payload.absoluteExpiryDate) {
        payload.endDateTime = payload.absoluteExpiryDate;
      }
      const coupon = {
        ...state.couponTemplate,
        ...payload,
      };
      if (!payload.notSaveToSession) {
        delete coupon.notSaveToSession;
        saveToSessionStorage(sessionDataKey.objectKey, coupon);
      }
      return {
        ...state,
        couponTemplate: coupon,
      };
    },

    // updateCouponTemplateWithSetData(state, { payload }) {
    //   const { selectedStores } = payload;
    //   let shouldBeUsedInStores = state.couponTemplate.shouldBeUsedInStores;
    //   if (selectedStores && selectedStores.length > 0) {
    //     shouldBeUsedInStores = true;
    //   }
    //   const coupon = {
    //     ...state.couponTemplate,
    //     ...payload,
    //     shouldBeUsedInStores: shouldBeUsedInStores,
    //     selectedStores: selectedStores,
    //   };
    //   if (!payload.notSaveToSession) {
    //     delete coupon.notSaveToSession;
    //     saveToSessionStorage(sessionDataKey.origionalData, coupon);
    //     saveToSessionStorage(sessionDataKey.objectKey, coupon);
    //   }
    //   return {
    //     ...state,
    //     couponTemplate: coupon,
    //   };
    // },

    saveOrRemoveCouponFromCookie(state, { payload }) {
      if (!payload) {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    loadCouponFromAPI(state, { payload }) {
      const { data, newItems } = payload;
      createNewItemAutoAdd(data, newItems);
      console.log('@506', data);
      return {
        ...state,
        hasUpdatedDefaultValues: true,
        couponTemplate: {
          ...data,
        },
      };
    },

    loadCouponFromCookie(state, { payload }) {
      const coupon = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!coupon) {
        return {
          ...state,
          couponTemplate: {
            ...getInitState().couponTemplate,
          },
        };
      }
      createNewItemAutoAdd(coupon, payload);
      // delete coupon.notSaveToSession;
      // saveToSessionStorage(sessionDataKey.origionalData, coupon);
      // saveToSessionStorage(sessionDataKey.objectKey, coupon);
      return {
        ...state,
        hasUpdatedDefaultValues: true,
        couponTemplate: {
          ...coupon,
        },
      };
    },

    stepChange(state, { payload }) {
      let { isBack, step, isValid } = payload;

      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      };
    },

    clearState(state, { payload }) {
      return { ...getInitState() };
    },

    hideEditorSuccessPrompt(state, { payload }) {
      return {
        ...state,
        showEditSuccessPrompt: false,
      };
    },
  },

  effects: {
    *initCoupon({ payload }, { select }) {
      const oldCoupon = yield select(
        (state) => state.createCoupon.couponTemplate,
      );
      delete oldCoupon.notSaveToSession;
      saveToSessionStorage(sessionDataKey.origionalData, oldCoupon);
    },
    // *setFieldToSession({ payload }, { select }) {
    //   const oldCoupon = yield select(
    //     (state) => state.createCoupon.couponTemplate,
    //   );
    //   const language = payload.language;
    //   let coupon = {};
    //   // console.log('@@408: ', payload);
    //   if (language) {
    //     delete payload.language;
    //     coupon = {
    //       ...oldCoupon,
    //       translations: {
    //         ...oldCoupon.translations,
    //         [language]: {
    //           ...oldCoupon.translations[language],
    //           ...payload,
    //         },
    //       },
    //     };
    //   } else {
    //     coupon = { ...oldCoupon, ...payload };
    //   }
    //   // console.log('@@424: ', coupon);
    //   delete coupon.notSaveToSession;
    //   saveToSessionStorage(sessionDataKey.objectKey, coupon);
    // },
    createCouponTemplate: [
      function* ({ payload }, { all, select, put }) {
        const { data, isGenerater } = payload;
        yield put(
          createAction('updateState')({
            createCouponTemplateStatus: APIStatus.calling,
          }),
        );
        let inputBody = {};
        if (data) {
          console.log('@@498: ', data);
          inputBody = cleanFormData(data);
        }

        console.log('@715', inputBody);
        const serviceArgs = [createCouponTemplate, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onFailed() {
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.failed,
            }),
          );
          // yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });
        }

        function* onSuccess(data) {
          const couponTemplateInfo = data.createCouponTemplate.node;
          console.log('@733', couponTemplateInfo);
          if (couponTemplateInfo) {
            yield put(
              createAction('updateGenerateCouponState')({
                generateCoupon: isGenerater,
                couponTemplatePk: couponTemplateInfo.pk,
                name: couponTemplateInfo.name,
                offlineCouponTemplateType:
                  couponTemplateInfo.offlineCouponTemplateType,
                offlineRedemptionMethod:
                  couponTemplateInfo.offlineRedemptionMethod,
                offlineFormats: couponTemplateInfo.offlineFormats,
                brand: couponTemplateInfo.brand,
                brandName: couponTemplateInfo.brand?.name,
                applicableStores:
                  parseApplicationStore(couponTemplateInfo.applicableStores) ||
                  [],
                offlineCouponCodeType: couponTemplateInfo.offlineCouponCodeType,
                shouldBeUsedInStores: couponTemplateInfo.shouldUseInStore,
                mediumType: couponTemplateInfo.mediumType,
                isDifferentCodeForOnlineOffline:
                  couponTemplateInfo.isDifferentCodeForOnlineOffline,
                showOnlineOfflineInput:
                  couponTemplateInfo.showOnlineOfflineInput,
                offlineShouldImportByFile:
                  couponTemplateInfo.offlineShouldImportByFile,
                onlineShouldImportByFile:
                  couponTemplateInfo.onlineShouldImportByFile,
              }),
            );

            yield put(
              createAction('updateState')({
                createCouponTemplateStatus: isGenerater
                  ? APIStatus.none
                  : APIStatus.success,
                formHasSubmitted: true,
              }),
            );
          }
          yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });

          if (payload.afterAction) {
            yield payload.afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],

    generateCoupon: [
      function* ({ payload }, { call, select, put, all }) {
        const redirectAction = payload.redirectAction || (() => {});
        const { store, quantity } = payload;
        const { couponTemplate, couponQuantity } = yield select((state) => ({
          couponTemplate: state.createCoupon.couponTemplate.pk,
          couponQuantity: state.createCoupon.couponQuantity,
        }));
        yield put(
          createAction('updateState')({
            generateCouponStatus: APIStatus.calling,
            couponQuantity: (
              parseInt(quantity) + parseInt(couponQuantity)
            ).toLocaleString(),
          }),
        );

        const inputBody = {
          couponTemplate: couponTemplate,
          quantity: quantity,
          store: store,
        };
        const serviceArgs = [generateCoupon, inputBody];
        function* onFailed() {
          yield put(
            createAction('updateState')({
              generateCouponStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          if (data.generateCoupons.success) {
            const couponTemplatePK = yield select(
              (state) => state.createCoupon.couponTemplate.pk,
            );
            yield all([
              put(
                createAction('updateState')({
                  generateCouponStatus: APIStatus.success,
                  couponGenerated: true,
                }),
              ),
              put({ type: 'saveOrRemoveCouponFromCookie', payload: false }),
              put({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.couponGenerate,
                  },
                },
              }),
              redirectAction(couponTemplatePK),
            ]);
            saveToSessionStorage(sessionDataKey.stepEndKey, true);
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCouponTemplateInput: [
      function* ({ payload }, { call, put, select }) {
        const { data, isGenerater } = payload;
        yield put(
          createAction('updateState')({
            createCouponTemplateStatus: APIStatus.calling,
          }),
        );
        const {
          couponTemplate,
          // storeEditMode
        } = yield select((state) => ({
          couponTemplate: state.createCoupon.couponTemplate,
          // storeEditMode: state.storeModel.storeEditMode,
        }));
        yield put({ type: 'saveOrRemoveCouponFromCookie', payload: false });
        const inputBody = cleanFormData(data, couponTemplate);
        // if (!storeEditMode) {
        //   delete inputBody.selectedStores;
        //   delete inputBody.applicableStores;
        // }
        const serviceArgs = [updateCouponSet, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onFailed() {
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          yield put(
            createAction('couponList/getCurrentPageTemplateList')({
              rank: true,
              search: '',
              page: 1,
            }),
          );
          if (isGenerater) {
            const couponTemplateInfo = data.updateCouponTemplate.node;
            yield put(
              createAction('updateGenerateCouponState')({
                generateCoupon: true,
                couponTemplatePk: couponTemplateInfo.pk,
                name: couponTemplateInfo.name,
                offlineCouponTemplateType:
                  couponTemplateInfo.offlineCouponTemplateType,
                offlineRedemptionMethod:
                  couponTemplateInfo.offlineRedemptionMethod,
                offlineFormats: couponTemplateInfo.offlineFormats,
                brand: couponTemplateInfo.brand,
                brandName: couponTemplateInfo.brand?.name,
                offlineCouponCodeType: couponTemplateInfo.offlineCouponCodeType,
                applicableStores:
                  parseApplicationStore(couponTemplateInfo.applicableStores) ||
                  [],
                shouldBeUsedInStores: couponTemplateInfo.shouldUseInStore,
                mediumType: couponTemplateInfo.mediumType,
                isDifferentCodeForOnlineOffline:
                  couponTemplateInfo.isDifferentCodeForOnlineOffline,
                showOnlineOfflineInput:
                  couponTemplateInfo.showOnlineOfflineInput,
                offlineShouldImportByFile:
                  couponTemplateInfo.offlineShouldImportByFile,
                onlineShouldImportByFile:
                  couponTemplateInfo.onlineShouldImportByFile,
              }),
            );
          }
          yield put(
            createAction('updateState')({
              createCouponTemplateStatus: APIStatus.success,
              formHasSubmitted: true,
              showEditSuccessPrompt: !isGenerater,
            }),
          );
          removeFromSessionStorage(sessionDataKey.objectKey);
          if (payload.afterAction) {
            yield payload.afterAction(data?.updateCouponTemplate?.node?.pk);
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getBarcodeList: [
      function* ({ payload }, { put }) {
        const { searchKey } = payload;
        const serviceArgs = [getBarcodeFormats, searchKey];

        function* onSuccess(data) {
          const barcodeData = data.barcodeFormats.edges;
          const barcodeList = barcodeData.map((item) =>
            parseBarcode(item.node),
          );
          yield put(
            createAction('updateState')({
              couponBarcodeList: barcodeList,
            }),
          );
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};

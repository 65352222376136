import React, { useState } from 'react';
import './PublishCampaignPrompt.scss';
import { useHistory } from 'react-router-dom';
import { URLFilterKeys, URLFragments } from '../CampaignUrlConfig';
import NextStepPrompt from '../../base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import queryString from 'query-string';
import { ActionType } from '../../../config/CustomEnums';
import { NOTIFY_YOUR_CUSTOMER, getCampaignStepBarNameWithType } from 'components/base/prompt/NextStepConfig';

function PublishCampaignPrompt(props) {
  const defaultShow = props.show || false;
  const path = props.path || '/campaigns';
  const actionType = props.actionType;
  const history = useHistory();
  const [show, setShow] = useState(false);

  if (!show && defaultShow) {
    setShow(true);
  }

  const getTitle = () => {
    switch (actionType) {
      case ActionType.save:
        return 'Successfully Saved!'
      case ActionType.update:
      case ActionType.tempForPulished:
        return 'Successfully Updated!';
      case ActionType.submitForApproval:
        return 'Successfully Submitted!'
      case ActionType.approval:
        return 'Successfully Approved!'
      case ActionType.withdraw:
        return 'Successfully Withdrawn!'
      default:
        return 'Successfully Unpublished!'
    }

  };

  const getDescription = () => {
    switch (actionType) {
      case ActionType.save:
        return `You can update the content.`
      case ActionType.update:
      case ActionType.tempForPulished:
        return `The campaign is successfully updated.`;
      case ActionType.submitForApproval:
        return `Notify the checkers to approve the content.`
      case ActionType.approval:
        return `Let your customers know about the campaign.`
      case ActionType.withdraw:
        return `You can update the content.`
      default:
        return `Campaign is successfully unpublished.You can publish again by edit the status.`
    }
  };

  const getSteps = () => {
    if (actionType === ActionType.approval) {
      const currentStep = getCampaignStepBarNameWithType(props.campaignType)
      return {
        stepNames: [currentStep, NOTIFY_YOUR_CUSTOMER],
        current: currentStep,
      };
    };
    return null;
  };

  const getButtons = () => {
    const buttons = [
      {
        text: 'Back to list',
        action: () =>{
          const search = queryString.stringify({
            [URLFilterKeys.page]: 1,
            [URLFilterKeys.searchKey]: '',
            [URLFilterKeys.rank]: true,
          });
          history.push({
            pathname: path,
            hash: URLFragments.list,
            search: search,
          })
        },
        requires: PermissionCodes.changeCampaign,
      },
    ]
    if (actionType === ActionType.approval) {
      buttons.unshift({
        text: 'Create message',
        action: () => {
          history.push({
            pathname: '/messages/create',
            search: `campaign=${props.campaignId}`,
          });
        },
        requires: PermissionCodes.addMessage,
      })
    }
    return buttons;
  };

  return (
    <NextStepPrompt
      show={show}
      title={getTitle()}
      description={getDescription()}
      steps={getSteps()}
      buttons={getButtons()}
    />
  );
}

export default PublishCampaignPrompt;

import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { TitleWithButton } from './utils';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';


const fields = [
  [
    { title: "Total number of coupon acquired", field: 'totalNumberOfCouponsAcquired' },
    { title: "Total number of coupon used", field: 'totalNumberOfCouponsUsed' },
    { title: "Total number of coupon expired", field: 'totalNumberOfCouponsExpired' },
  ],
];

const CouponSection = ({
  customer,
  setActiveTab,
}) => {

  return (
    <>
      <TitleWithButton
        containerExtraClassName="customer-detail-section-title-container"
        title="COUPON"
        titleExtraClassName="customer-detail-section-title"
        firstButtonContent="View onwed coupons"
        firstButtonOnClick={() => setActiveTab('Rewards')}
      />


      <MembershipInfoCard
        title=""
        data={customer}
        fields={fields}
      />

    </>
  )
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,

});

export default connect(mapPropsToState)(CouponSection);
import React from 'react';

function BaseEditSection({ title, fields, style }) {
  return (
    <div style={style}>
      {title ? <label className="create-section-title">{title}</label> : null}

      {fields.map((field, index) => (
        <div key={`${title?.toLowerCase()}-${index}`}>{field}</div>
      ))}
    </div>
  );
}

export default BaseEditSection;

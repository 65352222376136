import React from 'react';
import { Row, Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import ImageUploader from '../../base/ImageUploader';
import '../../campaign/campaignCreation/KeyVisualSection.scss';
import CustomRadios from '../../base/CustomRadios';
import { LanguageConfig } from '../../../config/CustomEnums';
import CustomTips from '../../base/CustomTips';
import i18n from '../../../I18n';
import { StampCampaignErrorHandleField } from './CreateStampCampaignHandleError';
import { insertKeyToAllLanguages } from '../../../utils';
import { Controller, useFormContext } from 'react-hook-form';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  errorMessage,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import config from 'config';

const CampaignLabel = {
  none: 'none',
  isFeatured: 'isFeatured',
  isExclusive: 'isExclusive',
};

const getRadioOptions = (language) => [
  {
    label: i18n.t('campaign.none', { locale: language }),
    value: CampaignLabel.none,
  },
  {
    label: i18n.t('campaign.featured_campaign', { locale: language }),
    value: CampaignLabel.isFeatured,
  },
  {
    label: i18n.t('campaign.exclusive_campaign', { locale: language }),
    value: CampaignLabel.isExclusive,
  },
];

function KeyVisualSection({
  language,
  onFocus = () => { },
  languages,
  isStamp = true,
}) {
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;
  const isExclusive = watch('isExclusive');
  const isFeatured = watch('isFeatured');
  const useUploadStampIcons = watch('useUploadStampIcons');
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );
  const applySquareCoverPhotoToAllLanguage = watch(
    'applySquareCoverPhotoToAllLanguage',
  );

  const coverPhoto = watch(`translations.${language}.coverPhoto`) || '';
  const detailPhotos = watch(`translations.${language}.detailPhotos`) || [];
  const squareCoverPhoto = watch(`translations.${language}.squareCoverPhoto`);
  const campaignCoverPhoto = [coverPhoto]?.filter((item) => item);
  const campaignSquareCoverPhoto = [squareCoverPhoto].filter((item) => item);
  const campaignOtherPhotos = detailPhotos?.filter((item) => item);
  // stamp campaign
  // const emptyStampSlot = watch(`emptyStampSlot`) || '';
  const emptyStampSlot = watch(`stampCampaignTypeEmptySlotStampImage`) || '';
  const collectedStamp = watch(`stampCampaignTypeAcquiredStampImage`) || '';
  const emptyGiftStampSlot = watch(`stampCampaignTypeEmptyGiftSlotImage`) || '';
  const collectedGift = watch(`stampCampaignTypeCollectedGiftImage`) || '';
  const emptyStampSlotPhoto = [emptyStampSlot]?.filter((item) => item);
  const collectedStampPhoto = [collectedStamp]?.filter((item) => item);
  const emptyGiftStampSlotPhoto = [emptyGiftStampSlot]?.filter((item) => item);
  const collectedGiftPhoto = [collectedGift]?.filter((item) => item);

  const selectedCampaignLabel = isExclusive
    ? CampaignLabel.isExclusive
    : CampaignLabel.none;

  const radioOptions = getRadioOptions(language);

  const getDefaultLabel = () => {
    if (isExclusive) {
      return CampaignLabel.isExclusive;
    }
    if (isFeatured) {
      return CampaignLabel.isFeatured;
    }
    return CampaignLabel.none;
  };

  const getRadioText = () => {
    let label = CampaignLabel.none;
    if (isFeatured) {
      label = CampaignLabel.isFeatured;
    }
    if (isExclusive) {
      label = CampaignLabel.isExclusive;
    }
    return radioOptions.filter((item) => item.value === label)[0].label;
  };

  const imageUploaderConfig = [
    {
      i18nTitlePk: 'emptyStampSlot',
      i18nSuggestPk: 'emptyStampSlotSuggest',
      disabled: !useUploadStampIcons && language === LanguageConfig.english,
      allowJson: false,
      onImageStateChange: (newState) => {
        setValue('stampCampaignTypeEmptySlotStampImage', newState[0], {
          shouldDirty: true,
        });
      },
      errorMessage: errorMessage(
        errors,
        'stampCampaignTypeEmptySlotStampImage',
      ),
      show: language === LanguageConfig.english,
      images: emptyStampSlotPhoto,
      name: 'stampCampaignTypeEmptySlotStampImage',
    },
    {
      i18nTitlePk: 'collectedStamp',
      i18nSuggestPk: 'collectedStampSuggest',
      disabled: !useUploadStampIcons && language === LanguageConfig.english,
      allowJson: true,
      onImageStateChange: (newState) => {
        setValue('stampCampaignTypeAcquiredStampImage', newState[0], {
          shouldDirty: true,
        });
      },
      errorMessage: errorMessage(errors, 'stampCampaignTypeAcquiredStampImage'),
      show: language === LanguageConfig.english,
      images: collectedStampPhoto,
      name: 'stampCampaignTypeAcquiredStampImage',
    },
    {
      i18nTitlePk: 'emptyGiftStampSlot',
      i18nSuggestPk: 'emptyGiftStampSlotSuggest',
      disabled: !useUploadStampIcons && language === LanguageConfig.english,
      allowJson: false,
      onImageStateChange: (newState) => {
        setValue('stampCampaignTypeEmptyGiftSlotImage', newState[0], {
          shouldDirty: true,
        });
      },
      errorMessage: errorMessage(errors, 'stampCampaignTypeEmptyGiftSlotImage'),
      show: language === LanguageConfig.english,
      images: emptyGiftStampSlotPhoto,
      name: 'stampCampaignTypeEmptyGiftSlotImage',
    },
    {
      i18nTitlePk: 'collectedGift',
      i18nSuggestPk: 'collectedGiftSuggest',
      disabled: !useUploadStampIcons && language === LanguageConfig.english,
      allowJson: true,
      onImageStateChange: (newState) => {
        setValue(`stampCampaignTypeCollectedGiftImage`, newState[0], {
          shouldDirty: true,
        });
      },
      errorMessage: errorMessage(errors, 'stampCampaignTypeCollectedGiftImage'),
      show: language === LanguageConfig.english,
      images: collectedGiftPhoto,
      name: 'stampCampaignTypeCollectedGiftImage',
    },
  ];

  const imageUploaderWithLabelComponent = (config) => (
    <>
      {config.show && (
        <>
          <label className="create-section-label create-section-label-bottom-space">
            {i18n.t(config.i18nTitlePk, { locale: language })}
          </label>
          <label className="create-campaign-cover-photo-reminder">
            {i18n.t(config.i18nSuggestPk, { locale: language })}
          </label>
          <Controller
            control={control}
            name={config.name}
            render={() => (
              <ImageUploader
                minWidth={120}
                minHeight={120}
                images={config.images}
                disabled={config.disabled}
                allowJson={config.allowJson}
                onImageStateChange={config.onImageStateChange}
                aspect={1}
                maxImageNum={1}
                uploadImageClicked={() => {
                  onFocus('stampIcons');
                }}
                language={language}
                sizeLimit={2}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={config.name} />
        </>
      )}
    </>
  );

  const featuredAndExclusive = () => (
    <div className="create-section-label-bottom-space">
      <CustomTips
        detail={i18n.t('campaign.featured_or_exclusive_campaign_tips', {
          locale: language,
        })}
      >
        <label className="create-section-label featured-exclusive-row-tip-label">
          {i18n.t('campaign.featured_or_exclusive_campaign', {
            locale: language,
          })}
        </label>
      </CustomTips>
      <Row className="featured-exclusive-row">
        {language !== LanguageConfig.english ? (
          getRadioText()
        ) : (
          <Controller
            control={control}
            name="campaignLabelType"
            render={() => (
              <CustomRadios
                disabled={language !== LanguageConfig.english}
                onChange={(value) => {
                  setValue('campaignLabelType', value, { shouldDirty: true });
                  setValue('isFeatured', CampaignLabel.isFeatured === value, {
                    shouldDirty: true,
                  });
                  setValue('isExclusive', CampaignLabel.isExclusive === value, {
                    shouldDirty: true,
                  });
                }}
                default={getDefaultLabel()}
                options={radioOptions}
              />
            )}
          />
        )}
      </Row>
    </div>
  );

  return (
    <>
      <label className="create-section-title">
        {i18n.t('key_visual', { locale: language })}
      </label>
      {language === LanguageConfig.english ? null : (
        <label className="create-campaign-cover-photo-reminder">
          {i18n.t('campaign.cover_photo_reminder', { locale: language })}
        </label>
      )}

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest_with_variable', {
          locale: language,
          ...config.campaignRectangleSize
        })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={config.campaignRectangleSize.minHeight}
        disabled={
          language !== LanguageConfig.english && applyCoverPhotoToAllLanguage
        }
        images={campaignCoverPhoto}
        onImageStateChange={(newState) => {
          const applyLanguages = applyCoverPhotoToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'coverPhoto',
            value: newState[0],
            setValue,
            getValues,
          });
        }}
        aspect={900 / config.campaignRectangleSize.minHeight}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('coverPhoto')}
        language={language}
        photoSectionTitle={'cover_photo'}
        errorMessage={errorMessage(
          errors,
          `translations.${language}.coverPhoto`,
        )}
      />
      <span
        id={`${StampCampaignErrorHandleField.coverPhoto.name}-${language}`}
      />

      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
      {featuredAndExclusive()}
      <Collapse in={selectedCampaignLabel === CampaignLabel.isExclusive}>
        <div>
          <label className="create-section-label create-section-label-bottom-space">
            {i18n.t('campaign.square_cover_photo', { locale: language })}
          </label>
          <label className="create-campaign-cover-photo-reminder">
            {i18n.t('square_cover_photo_suggest_with_variable', {
              locale: language,
              ...config.campaignSquareSize
            })}
          </label>

          <ImageUploader
            images={campaignSquareCoverPhoto}
            disabled={
              language !== LanguageConfig.english &&
              applySquareCoverPhotoToAllLanguage
            }
            onImageStateChange={(newState) => {
              setValue(
                `translations.${language}.squareCoverPhoto`,
                newState ? (newState?.[0] ? newState?.[0] : null) : null,
                {
                  shouldDirty: true,
                },
              );
            }}
            aspect={config.campaignSquareSize.minWidth / config.campaignSquareSize.minHeight}
            maxImageNum={1}
            language={language}
            photoSectionTitle={'square_photo'}
            uploadImageClicked={() => onFocus('squareCoverPhoto')}
            errorMessage={errorMessage(
              errors,
              `translations.${language}.squareCoverPhoto`,
            )}
          />
          <span
            id={`${StampCampaignErrorHandleField.squarePhoto.name}-${language}`}
          />
          <ApplySwitch
            disabled={false}
            show={language === LanguageConfig.english}
            checked={applySquareCoverPhotoToAllLanguage}
            onChange={(value) => {
              setValue('applySquareCoverPhotoToAllLanguage', value, {
                shouldDirty: true,
              });
              if (value) {
                insertKeyToAllLanguages({
                  languages,
                  key: 'squareCoverPhoto',
                  value: squareCoverPhoto,
                  setValue,
                  getValues,
                });
              }
            }}
          />
        </div>
      </Collapse>

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('other_photos', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest_with_variable', {
          locale: language,
          ...config.campaignRectangleSize
        })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={config.campaignRectangleSize.minHeight}
        images={campaignOtherPhotos}
        disabled={
          language !== LanguageConfig.english && applyDetailPhotosToAllLanguage
        }
        onImageStateChange={(newState) => {
          const applyLanguages = applyDetailPhotosToAllLanguage
            ? languages
            : [language];
          insertKeyToAllLanguages({
            languages: applyLanguages,
            key: 'detailPhotos',
            value: newState,
            setValue,
            getValues,
          });
        }}
        aspect={900 / config.campaignRectangleSize.minHeight}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
        uploadImageClicked={() => onFocus('otherPhotos')}
      />
      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'detailPhotos',
              value: detailPhotos,
              setValue,
              getValues,
            });
          }
        }}
      />
      {isStamp && (
        <>
          <ApplySwitch
            disabled={false}
            labelText={'Upload stamp icons'}
            show={language === LanguageConfig.english}
            checked={useUploadStampIcons}
            onChange={(value) => {
              setValue('useUploadStampIcons', value, {
                shouldDirty: true,
              }
              );
              if (value) {
                insertKeyToAllLanguages({
                  languages,
                  key: 'collectedGiftPhoto',
                  value: collectedGiftPhoto,
                  setValue,
                  getValues,
                });
              }
            }}
          />
          {language === LanguageConfig.english && (
            <label className="create-campaign-cover-photo-reminder">
              If off, the default animation will be applied.
            </label>
          )}
          {useUploadStampIcons && (
            <>
              {imageUploaderConfig.map((config) =>
                imageUploaderWithLabelComponent(config),
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(KeyVisualSection);

import React from 'react';
import '../campaign/campaignList/DeleteCampaignPrompt.scss';
import BasePrompt from '../base/prompt/BasePrompt';

export const deleteMessage = (items, related, title = '') => {
  const sections = (
    <b>
      {related.length > 1
        ? related.slice(0, related.length - 1).join(', ') +
          ' and ' +
          related.slice(related.length - 1)
        : related.join(', ')}
    </b>
  );
  // const sections = `${
  //   related.length > 1
  //     ? related.slice(0, related.length - 1).join(', ') +
  //       ' and ' +
  //       related.slice(related.length - 1)
  //     : related.join(', ')
  // }`;

  // const message = (
  //   <div>
  //     {items.length > 1
  //       ? 'These ' +
  //         items.slice(0, items.length - 1).join(', ') +
  //         ' and ' +
  //         items.slice(items.length - 1) +
  //         ' are'
  //       : 'This ' + items.join(', ') + ' is'}{' '}
  //     associated with some {sections}, once deleted, it will affect other
  //     content. Are you sure to delete?
  //   </div>
  // );

  const message = (
    <div>
      {items.length > 1
        ? 'These ' + title.toLowerCase() + ' are'
        : 'This ' + title.toLowerCase() + ' is'}{' '}
      associated with some {sections}, once deleted, it will affect other
      content. Are you sure to delete?
    </div>
  );

  return message;
};

function DeletePrompt(props) {
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const data = props.data || [];
  const title = props.title;
  // const relatedName = props.relatedName;
  const relatedSections = props.relatedSections || [];
  const message = props.message;
  const description = props.description || null;
  // const displayName = props.displayName || null;

  // const getDisplay = () => {
  //   if (message) {
  //     return null;
  //   }
  //   let mapData = [];
  //   if (!Array.isArray(data) && data) {
  //     mapData = [data];
  //   } else {
  //     mapData = data;
  //   }

  //   return (
  //     <div className="delete-campaign-pop-content">
  //       {mapData.map((data, index) => {
  //         const item = data.node ? data.node : data;
  //         const name = item.name;
  //         // console.log('@@39: ', relatedName, item.node);
  //         const relatedItems = relatedName
  //           ? item[relatedName]['edges'] || item[relatedName]
  //           : [];
  //         return (
  //           <ul className="delete-campaign-pop-content-text" key={index}>
  //             <li className="delete-campaign-pop-content-text">
  //               {displayName
  //                 ? `${title}: ${item[displayName]}`
  //                 : `${title}: ${name}`}
  //               <ul className="delete-campaign-pop-content-text">
  //                 {relatedItems.length > 0 ? `${relatedName}:` : null}
  //                 {relatedItems.map((related, index) => {
  //                   return (
  //                     <li className="delete-campaign-pop-content-text">
  //                       {related.node?.name || related.name || related}
  //                     </li>
  //                   );
  //                 })}
  //               </ul>
  //             </li>
  //           </ul>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const getDescription = () => {
    if (relatedSections.length <= 0) {
      return `Do you confirm to delete ${
        !Array.isArray(data) && data ? 'this' : 'these'
      } ${title}?`;
    }
    let mapData = [];
    if (!Array.isArray(data) && data) {
      mapData = [data];
    } else {
      mapData = data;
    }

    return deleteMessage(
      mapData.map((item) => item.name),
      relatedSections,
      title,
    );
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={onClose}
      rightButton={{
        text: 'Delete',
        action: onConfirm,
      }}
      leftButton={{
        text: 'Cancel',
        action: onClose,
      }}
      title={message?.title ? message.title : `Delete the ${title}`}
      // description={
      //   message?.content
      //     ? message.content
      //     : `You will not be able to recover it. All of the following objects and
      // their related items will be deleted:`
      // }
      // otherBody={getDisplay}
      description={description ? description : getDescription()}
    />
  );
}

export default DeletePrompt;

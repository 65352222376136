import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { OnlyContinueButton } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import ItemTypeSet, {
  MIN_WIDTH,
  SPACE_BETWEEN,
} from 'components/base/ItemTypeSet';
import Loading from 'components/base/Loading';
import { transactionQueryKey } from 'components/pointRecord/RecordsUrlConfig';
import { APIStatus } from 'config/CustomEnums';
import { validate } from 'containers/record/records/CreateTransactionValidate';
import { TRANSACTION_RECORD_TYPE } from 'containers/record/records/TransactionList';

import RedirectToShopifyPrompt from './RedirectToShopifyPrompt';

import { getScrollbarWidth, useMainContentWidth } from 'utils/ScreenUtil';

import OfflinePOSImage from 'assets/images/transaction/offlinePOS.svg';
import offlineRewardClaimImage from 'assets/images/transaction/offlineRewardClaim.svg';
import OnlineImage from 'assets/images/transaction/online.svg';

function CreateTransactionStepOne({ status }) {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();

  const { watch, getValues, setValue, clearErrors, setError } =
    useFormContext();

  const selectedTypeField = 'transactionDisplayType';
  const selectedType = watch(selectedTypeField);
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    dispatch({
      type: 'createTransaction/stepChange',
      payload: {
        isBack: false,
        step: 0,
        isValid,
      },
    });
  };

  const transactionTypeList = [
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_ONLINE,
      title: 'Online',
      description: 'Online shopify transaction',
      image: OnlineImage,
    },
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS,
      title: 'Offline Sales',
      description: 'Physical store transaction',
      image: OfflinePOSImage,
    },
    {
      id: TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM,
      title: 'Offline Reward Claim',
      description: 'Physical store transaction',
      image: offlineRewardClaimImage,
    },
  ];
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  const sections = [
    status === APIStatus.calling ? (
      <Loading />
    ) : (
      <div className="create-point-step-one-title">
        <label className="step-one-title">Transaction Type</label>
        <div
          className="point-record-step-type-area transaction-step-type-area"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {transactionTypeList.map((item, index) => {
            return (
              <ItemTypeSet
                key={index}
                item={item}
                selected={selectedType}
                onClick={(id) => {
                  setValue(selectedTypeField, id, { shouldDirty: true });
                }}
              />
            );
          })}
        </div>
      </div>
    ),
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={() => {
          if (selectedType === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
            setShowRedirectPrompt(true);
          } else {
            stepChangeAction();
          }
        }}
        disabledContinue={!selectedType}
      />
      <RedirectToShopifyPrompt
        show={showRedirectPrompt}
        onHide={() => {
          setShowRedirectPrompt(false);
        }}
        onConfirm={() => {
          setShowRedirectPrompt(false);
          history.push({
            pathname: '/transactions',
            search: queryString.stringify({
              [transactionQueryKey.page]: 1,
              [transactionQueryKey.rank]: true,
              [transactionQueryKey.searchKey]: '',
              [transactionQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.loading.status,
});

export default connect(mapPropsToState)(CreateTransactionStepOne);

import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  errorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';

import CloseIcon from 'assets/images/prompt_close_icon.svg';

import './EditCustomerAdditionalDataModal.scss';

const mapPropsToState = (state) => ({
  userCustomData: state.customerList.customer?.userCustomData,
  customer: state.customerList.customer,
  errors: state.customerList.errorFields,
});

const EditCustomerAdditionalDataModal = ({
  userCustomData = {},
  customer,
  errors,
  sortedFieldNames = [],
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [newUserCustomData, setNewUserCustomData] = useState({});

  const onClose = () => {
    setShow(false);
  };

  const saveAction = () => {
    dispatch({
      type: 'customerList/updateCustomerAdditionalData',
      payload: {
        id: customer.pk,
        data: newUserCustomData,
        successCallback: () => {
          dispatch({
            type: 'customerList/getOneCustomer',
            payload: {
              id: customer.pk,
            },
          });
          setShow(false);
        },
        failureCallback: () => {},
      },
    });
  };

  useEffect(() => {
    setNewUserCustomData(userCustomData);
  }, [userCustomData]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body className={`base-prompt-container`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={onClose}
        />

        <label className="customer-detail-modal-title">
          Edit customer additional data
        </label>

        <div className="customer-additional-data">
          {sortedFieldNames.map((fieldName, index) => {
            const value = newUserCustomData[fieldName];
            return (
              <>
                <CustomTitleWithInput
                  title={`Fields #${index + 1}`}
                  per={'Title'}
                  type={'text'}
                  defaultValue={fieldName}
                  disabled
                  buttonNewLine
                />
                <CustomTitleWithInput
                  per={'Value'}
                  type={'text'}
                  setValue={(v) => {
                    const value = {
                      ...newUserCustomData,
                    };
                    value[fieldName] = v;
                    setNewUserCustomData(value);
                  }}
                  defaultValue={value}
                  buttonNewLine
                />
              </>
            );
          })}
        </div>

        <div className="base-prompt-buttons">
          <PromptButton
            title="Cancel"
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title="Confirm"
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapPropsToState)(EditCustomerAdditionalDataModal);

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';

function CampaignSelectDropdown(props) {
  const alwaysHint = props.alwaysHint || null;
  const customStyles = props.customStyles || null;
  const title = props.title || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const campaignList = useSelector((state) => state.campaignList.allList);
  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        isSelectorLoad: true,
        isExpired: false,
        isPublished: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTitleWithDropDown
      alwaysHint={alwaysHint}
      customStyles={customStyles}
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={campaignList}
      labelContainPk
      defaultValue={defaultValue}
      loadMoreAction={'campaignList/getCampaignList'}
      filterAction={'campaignList/getCampaignList'}
      allType
    />
  );
}

export default CampaignSelectDropdown;

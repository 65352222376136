import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { hasReactHookFormError } from '../base/ErrorFieldMessage';

export default function InputWithMutipleDropDown({
  title,
  fieldBaseName,
  hasNumber,
  rangeOptions,
  hasPeriodDropdown,
}) {
  const { setValue, control, watch, register, formState: { errors } } = useFormContext();
  const fixedRangeOptions = rangeOptions || ['All the time', 'Within'];
   
  const numberFieldName = `${fieldBaseName}.number`;
  const periodRangeFieldName = `${fieldBaseName}.periodRange`;
  const periodDaysFieldName = `${fieldBaseName}.periodDays`;
  const periodRange = watch(periodRangeFieldName) || 'Please select';
  const periodDays = watch(periodDaysFieldName) || 'Please select';
  const showPeriodDays = periodRange === 'Within';

  const rangePeriodOptions = ['1 day', '7 days', '1 month', '3 months', '6 months', '1 year'];

  const dropDownOptions = (values, fieldName) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setValue(fieldName, value, { shouldDirty: true, shouldValidate: true });
      },
    }));
  };

  return (
    <div>
      {title ? <CustomTitleLabel title={title} /> : null}
      <div style={{ display: 'flex' }}>
        {hasNumber ? (
          <input
            type="number"
            className={`custom-number-input-long ${hasReactHookFormError(errors, numberFieldName) ? 'error-field' : ''}`}
            {...register(numberFieldName, { required: hasNumber })}
          />
        ) : null}

    
        <Controller
          control={control}
          rules={{ required: fixedRangeOptions.length !== 1 }}
          name={periodRangeFieldName}
          render={({field}) => (
            <DropDownWithDefaultSelect
              actions={dropDownOptions(fixedRangeOptions, periodRangeFieldName)}
              defaultValue={periodRange}
              className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
            />)
          }
        />
          

        {showPeriodDays && hasPeriodDropdown ? (
          <Controller
            control={control}
            name={periodDaysFieldName}
            rules={{ required: showPeriodDays && hasPeriodDropdown }}
            render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={dropDownOptions(rangePeriodOptions, periodDaysFieldName)}
                defaultValue={periodDays}
                className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
              />)
            }
          />
        ) : null}
      </div>
    </div>
  );
}
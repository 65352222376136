import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function NoPermission(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("no permission -> logout")
    dispatch({ type: 'users/logout' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{ marginTop: '100px' }}>403 Forbidden</div>;
}
export default NoPermission;

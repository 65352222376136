import {
  getBulkRewardList,
  getBulkRewardRecord,
} from '../services/BulkRewardRecordApiHelp';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';

const getInitState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk', linked: true },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'pk',
    },
    { displayName: 'Reward Type', fieldName: 'rewardTypeString' },
    {
      displayName: 'Point Added',
      fieldName: 'addPoints',
      orderField: 'addPoints',
    },
    {
      displayName: 'Point Removed',
      fieldName: 'removePoints',
      orderField: 'removePoints',
    },
    {
      displayName: 'Progress Points Added',
      fieldName: 'addTpes',
      orderField: 'addTpes',
    },
    {
      displayName: 'Progress Points Removed',
      fieldName: 'removeTpes',
      orderField: 'removeTpes',
    },
    { displayName: 'Coupon Set Added', fieldName: 'addCouponSetIds' },
    { displayName: 'Coupon Added', fieldName: 'addCouponAmount' },
    { displayName: 'Coupon Set Removed', fieldName: 'removeCouponSetIds' },
    { displayName: 'Coupon Removed', fieldName: 'removeCouponAmount' },
  ],
});

// TODO: 修改parse中的type显示字段
const REWARD_TYPE = {
  ADD_POINTS: 'Add Points',
  REMOVE_POINTS: 'Remove Points',
  ADD_COUPONS: 'Add Coupons',
  REMOVE_COUPONS: 'Remove Coupons',
  ADD_TPES: 'Add Progress Points',
  REMOVE_TPES: 'Remove Progress Points',
};
const parseBulkReward = (data) => {
  data.addCouponSetIds = Array(data.addCouponSetIds).join(); //type of addCouponSetIds is object
  data.addCouponAmount = Array(data.addCouponAmount).join();
  data.removeCouponSetIds = Array(data.removeCouponSetIds).join();
  data.removeCouponAmount = Array(data.removeCouponAmount).join();
  const rewardTypeString = data.rewardType
    .map((type) => REWARD_TYPE[type], this)
    .join();
  console.log('sjsjsjsj', data);
  if (data?.creationDate) {
    data.creationDate = formatDate(
      data.creationDate,
      TimeFormater.dayMonthYearWeekTime,
    );
  }
  return {
    customerName: data.customer?.nickname
      ? `${data.customer?.firstName} ${data.customer?.lastName} (${data.customer?.nickname})`
      : `${data.customer?.firstName} ${data.customer?.lastName}`,
    ...data,
    rewardTypeString,
  };
};

export default createModel({
  namespace: 'bulkReward',
  states: getInitState(),
  params: {
    listAPI: getBulkRewardList,
    parse: (data) => {
      return data?.bulkRewardRecords.edges.map((item) =>
        parseBulkReward(item.node),
      );
    },
    pkNode: 'BulkRewardRecordNode',
    detailAPI: getBulkRewardRecord,
    parseDetail: (data) => parseBulkReward(data.bulkRewardRecord),
    objectKey: 'bulkRewardRecords',
    initState: getInitState(),
  },
  reducers: {},
  effects: {},
});

import React, { Component } from 'react';

import PreviewImage from 'components/base/prompt/PreviewImage';

class BaseOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
    };
  }

  render() {
    return (
      <div
        className={`coupon-overview-container ${this.props.className || ''}`}
      >
        {this.props.title ? (
          <div className="first-section-title overview-section-title">
            {this.props.title}
          </div>
        ) : null}
        {this.props.itemTitleList.map((title, index) => {
          const isArray = Array.isArray(this.props.itemValueList[index]);
          let contentItem = null;
          if (title === 'Image') {
            contentItem = (
              <img
                alt="img"
                src={this.props.itemValueList[index]}
                style={{ maxWidth: 191, maxHeight: 191 }}
                onClick={() => {
                  this.setState({
                    imageUrl: this.props.itemValueList[index],
                  });
                }}
              />
            );
          } else if (isArray) {
            contentItem = (
              <>
                {this.props.itemValueList[index].map((item, index) => (
                  <div className="second-section-content">{item}</div>
                ))}
              </>
            );
          } else {
            contentItem = (
              <div className="second-section-content">
                {this.props.itemValueList[index]}
              </div>
            );
          }
          return (
            <div className="second-section">
              <div className="second-title-section">
                <div className="second-section-title">{title}</div>
              </div>
              {contentItem}
            </div>
          );
        })}
        <PreviewImage
          show={this.state.imageUrl ? true : false}
          onClose={() => {
            this.setState({
              imageUrl: null,
            });
          }}
          imageUrl={this.state.imageUrl}
        />
      </div>
    );
  }
}

export default BaseOverView;

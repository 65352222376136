import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './RecordTableCard.scss';


function RecordTableCard({
  data,
  fields,
}) {
  const history = useHistory();

  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(800);
  const tableOffset = tableRef.current?.offsetWidth;
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableOffset]);

  return (
    <>
      {data.length <= 0 ? (
        null
      ) : (
        <>
          <PerfectScrollbar>
            <table
              ref={tableRef}
              className="customer-detail-table"
              style={{ width: '100%' }}
            >
              <thead>
                {fields.map((field, index) => {
                  return (
                    <th
                      className="customer-detail-table-blod-font"
                      style={{
                        width:
                          index === 0
                            ? '250px'
                            : `${(tableWidth - 250) / (fields.length - 1)
                            }px`,
                      }}
                    >
                      {field.displayName}
                    </th>
                  );
                })}
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr>
                      {fields.map((field) => {
                        return (
                          <td>
                            <label
                              className={field.name === 'pk' ? 'customer-detail-table-clickable' : ''}
                              onClick={() => {
                                if (field.name === 'pk') {
                                  history.push({
                                    pathname: `/transactions/${item.pk}/`,
                                  });
                                }
                              }}
                            >
                              {item[field.name] || '-'}
                            </label>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </PerfectScrollbar>
        </>
      )}
    </>
  );
}

export default RecordTableCard;

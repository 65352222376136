import { SIGN_UP_METHOD } from 'config/CustomEnums';
import { EditCustomerHandleError } from '../../../components/customer/EditCustomerHandleError';

export function getErrorList({
  firstName,
  lastName,
  pointTransaction,
  transactionPoint,
  tpeTransaction,
  transactionTpe,
  signUpMethod,
  mobilePhoneNumberCountryCode,
  mobilePhoneNumberSubscriberNumber,
}) {
  const errorList = [];

  if (firstName == null || firstName === '') {
    errorList.push({
      name: EditCustomerHandleError.firstName.name,
      message: EditCustomerHandleError.firstName.message,
    });
  }
  if (lastName == null || lastName === '') {
    errorList.push({
      name: EditCustomerHandleError.lastName.name,
      message: EditCustomerHandleError.lastName.message,
    });
  }
  if (
    (transactionPoint != null && transactionPoint !== '')
    || (pointTransaction != null && pointTransaction?.value != null && pointTransaction !== '')
  ) {
    const transactionPointInt = parseInt(transactionPoint);
    if (isNaN(transactionPointInt) || transactionPointInt <= 0) {
      errorList.push({
        name: EditCustomerHandleError.transactionPoint.name,
        message: EditCustomerHandleError.transactionPoint.message,
      });
    }
    if (pointTransaction == null || pointTransaction.value == null) {
      errorList.push({
        name: EditCustomerHandleError.pointTransaction.name,
        message: EditCustomerHandleError.pointTransaction.message,
      });
    }
  }

  if (
    (transactionTpe != null && transactionTpe !== '')
    || (tpeTransaction != null && tpeTransaction?.value != null && tpeTransaction !== '')
  ) {
    const transactionTpeInt = parseInt(transactionTpe);
    if (isNaN(transactionTpeInt) || transactionTpeInt <= 0) {
      errorList.push({
        name: EditCustomerHandleError.transactionTpe.name,
        message: EditCustomerHandleError.transactionTpe.message,
      });
    }
    if (tpeTransaction == null || tpeTransaction.value == null) {
      errorList.push({
        name: EditCustomerHandleError.tpeTransaction.name,
        message: EditCustomerHandleError.tpeTransaction.message,
      });
    }
  }

  if (signUpMethod !== SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
    if (!mobilePhoneNumberCountryCode && !mobilePhoneNumberSubscriberNumber) {
      // account phone number can be empty
    } else {
      if (!mobilePhoneNumberCountryCode) {
        errorList.push({
          name: EditCustomerHandleError.mobilePhoneNumberCountryCode.name,
          message: EditCustomerHandleError.mobilePhoneNumberCountryCode.message,
        });
      }
      if (!mobilePhoneNumberSubscriberNumber) {
        errorList.push({
          name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name,
          message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.message,
        });
      } else {
        const length = mobilePhoneNumberSubscriberNumber.length;
        if (mobilePhoneNumberCountryCode === '852') {
          if (length !== 8) {
            errorList.push({
              name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.name,
              message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.message,
            });
          }
        } else if (mobilePhoneNumberCountryCode === '86') {
          if (length !== 11) {
            errorList.push({
              name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.name,
              message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.message,
            });
          }
        }
      }
    }
  }

  return errorList;
}


function firstStepValidate({ getValues, setError }) {
  const [
    firstName,
    lastName,
    pointTransaction,
    transactionPoint,
    tpeTransaction,
    transactionTpe,
    signUpMethod,
    mobilePhoneNumberCountryCode,
    mobilePhoneNumberSubscriberNumber,
  ] = getValues([
    'firstName',
    'lastName',
    'pointTransaction',
    'transactionPoint',
    'tpeTransaction',
    'transactionTpe',
    'signUpMethod',
    'mobilePhoneNumberCountryCode',
    'mobilePhoneNumberSubscriberNumber',
  ]);
  const errorList = getErrorList({
    firstName,
    lastName,
    pointTransaction,
    transactionPoint,
    tpeTransaction,
    transactionTpe,
    signUpMethod,
    mobilePhoneNumberCountryCode,
    mobilePhoneNumberSubscriberNumber,
  });

  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      },
      { shouldFocus: true, },
    );
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step }) {
  console.log('@71 customerValidate:', getValues());
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
  }
}

import React from "react"
import { Image, OverlayTrigger, Popover } from "react-bootstrap"

import AuthButton from "./AuthButton"
import InfoIcon from 'assets/images/info.svg';

import "./CustomButtonWithTips.scss"

const CustomButtonWithTips = ({
  tipsContent,
  tipsPosition = "bottom-end",
  ...others
}) => {
  return (
    <div className='custom-button-with-tips btn-download'>
      <AuthButton
        {...others}
      />
      <OverlayTrigger
        rootClose
        placement={tipsPosition}
        overlay={
          <Popover className='caution-container'>
            <Popover.Content
              className={`caution-content`}
            >
              {tipsContent || ''}
            </Popover.Content>
          </Popover>}
      >
        <Image src={InfoIcon} />
      </OverlayTrigger>
    </div>)
}

export default CustomButtonWithTips;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dva from './Dva';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'cookies-js';

import NavBarsModel from 'models/NavBarModel';
import UsersModel from 'models/UsersModel';
import CreateCampaignModel from 'models/CreateCampaignModel';
import CreateCouponModel from 'models/CreateCouponModel';
import SettingModel from 'models/SettingModel';
import UploadFilesModel from 'models/UploadFilesModel';
import BrandModel from 'models/BrandModel';
import StoreModel from 'models/StoreModel';
import CouponListModel from 'models/CouponListModel';
import SingleCouponModel from 'models/SingleCouponModel';
import CustomerListModel from 'models/CustomerListModel';
import CampaignListModel from 'models/CampaignListModel';
import DashboardModel from 'models/DashboardModel';
import EarningRulesModel from 'models/EarningRulesModel';
import CreateEarningRulesModel from 'models/CreateEarningRulesModel';
import LoadingModel from 'models/LoadingModel';
import SegmentModel from 'models/SegmentModel';
import LevelModel from 'models/LevelModel';
import DownloadAndImport from 'models/DownloadImportModel';
import CouponTransactionModel from 'models/CouponTransactionModel';
import CreatePointRecordModel from 'models/CreatePointRecordModel';
import PointTransactionListModel from 'models/PointTransactionListModel';
import TransactionModel from 'models/TransactionModel';
import CreateTransactionModel from 'models/CreateTransactionModel';
import AdminModel from 'models/AdminModel';
import AdminGroupModel from 'models/AdminGroupModel';
import CustomerGroupModel from 'models/CustomerGroupModel';
import MessageListModel from 'models/MessageListModel';
import CampaignCategoryListModel from 'models/CampaignCategoryListModel';
import CreateCampaignCategoryModel from 'models/CreateCampaignCategoryModel';
import CreateMessageModel from 'models/CreateMessageModel';
import OverviewModel from 'models/OverviewModel';
import StoreCategoryModel from 'models/StoreCategoryModel';
import CreateStoreCategoryModel from 'models/CreateStoreCategoryModel';
import HomeBannersModel from 'models/HomeBannersModel';
import HomeFeaturedModel from 'models/HomeFeaturedModel';
import LanguageModel from 'models/LanguageModel';
import LevelDiscountModel from 'models/LevelDiscountModel';
import FillingFormModel from 'models/FillingFormModel';
import BulkRewardModel from 'models/BulkRewardModel';
import WebviewModel from 'models/WebviewModel';
import StampRecordModel from 'models/StampRecordModel';
import MessageCreditRecordModel from './models/MessageCreditRecordModel';
import RecencyFrequencyMonetaryScoreSettingModel from 'models/RecencyFrequencyMonetaryScoreSettingModel';
import PurchasedItemCategoryModel from 'models/PurchasedItemCategoryModel';
import GiftCardModel from 'models/GiftCardModel';
import GiftCardRecordModel from 'models/GiftCardRecordModel';
import GiftCardVirtualConfigModel from 'models/GiftCardVirtualConfigModel';
import ShopifyModel from 'models/ShopifyModel';

const midwares = [];
const usersFilter = createFilter('users', [
  'userId',
  'isLogin',
  'username',
  'isSuperuser',
  'firstLogin',
]);

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
  whitelist: ['users'],
  stateReconciler: autoMergeLevel2,
  transforms: [usersFilter],
};

const app = dva({
  initialState: {},
  models: [
    NavBarsModel,
    UsersModel,
    CampaignListModel,
    CreateCampaignModel,
    CouponListModel,
    SingleCouponModel,
    CreateCouponModel,
    EarningRulesModel,
    SettingModel,
    UploadFilesModel,
    BrandModel,
    StoreModel,
    CustomerListModel,
    LoadingModel,
    CreateEarningRulesModel,
    SegmentModel,
    LevelModel,
    DownloadAndImport,
    CouponTransactionModel,
    CreatePointRecordModel,
    PointTransactionListModel,
    DashboardModel,
    OverviewModel,
    TransactionModel,
    CreateTransactionModel,
    AdminModel,
    AdminGroupModel,
    CustomerGroupModel,
    MessageListModel,
    CampaignCategoryListModel,
    CreateCampaignCategoryModel,
    CreateMessageModel,
    StoreCategoryModel,
    CreateStoreCategoryModel,
    HomeBannersModel,
    HomeFeaturedModel,
    LanguageModel,
    LevelDiscountModel,
    FillingFormModel,
    BulkRewardModel,
    WebviewModel,
    StampRecordModel,
    MessageCreditRecordModel,
    RecencyFrequencyMonetaryScoreSettingModel,
    PurchasedItemCategoryModel,
    GiftCardModel,
    GiftCardRecordModel,
    GiftCardVirtualConfigModel,
    ShopifyModel,
  ],
  onReducer: (rootReducer) => persistReducer(persistConfig, rootReducer),
  onAction: midwares,
  onError(e) {
    console.log('onError', e);
  },
});

const persistor = persistStore(app.getStore(), null, () => {
  app.getStore().dispatch({ type: 'users/startUp' });
});

const DvaApp = app.start(<App />);
ReactDOM.render(
  <React.StrictMode>
    <PersistGate load={null} persistor={persistor}>
      <DvaApp />
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import './AddRemarkModal.scss';


const AddRemarkModal = ({
  customer,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [remark, setRemark] = useState(null);

  const onClose = () => {
    setShow(false);
  };

  const saveAction = () => {
    dispatch({
      type: 'customerList/createCustomerRemark',
      payload: {
        customer: customer.pk,
        remark,
      },
    })
  }

  useEffect(() => {
    if (show) {
      setRemark(null);
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>Add Remark</label>

        <CustomTitleLabel title="Remark" />

        <textarea
          onChange={({ target }) => {
            const value = target.value;
            setRemark(value);
          }}
          className="custom-textarea-input-box customer-detail-textarea-input"
          value={remark}
        />

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm'
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
});

export default connect(mapPropsToState)(AddRemarkModal);

import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Editor } from '@tinymce/tinymce-react';
// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

const HtmlEditor = forwardRef((props, ref) => {
  const { init, className, ...rest } = props;
  const editorRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      setContent: (data) => {
        editorRef.current.setContent(data);
      },
    };
  });

  return (
    <div className={`${className}`}>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins:
            'preview importcss searchreplace  directionality code visualblocks visualchars ' +
            'fullscreen image link media codesample table charmap   insertdatetime ' +
            'advlist lists wordcount help charmap emoticons',
          menubar: 'file edit view insert format tools help',
          toolbar:
            'undo redo | bold italic underline strikethrough | ' +
            'fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify |  ' +
            'outdent indent |  numlist bullist | forecolor backcolor removeformat | ' +
            'fullscreen  preview | image media link codesample | ' +
            'ltr rtl',

          ...init,
          skin: false,
          content_css: false,
          content_style: [
            contentCss,
            contentUiCss,
            init.content_style || '',
          ].join('\n'),
          font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt',
        }}
        {...rest}
      />
    </div>
  );
});

export default HtmlEditor;

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import { EarningRuleType } from 'config/CustomEnums';

const linkedCampaignTypeEnum = {
  stampCampaign: 'STAMP_CAMPAIGN',
  earningCampaign: 'EARNING_CAMPAIGN',
};

function EarningRulesSelectDropdown({
  title,
  description,
  defaultValue,
  defaultFilter,
  isPrompt = false,
  addButtonInfo = {},
  setValue = (() => {}),
  earningRuleList,
  errors,
  errorName,
}) {
  const dispatch = useDispatch();
  //TODO: remove save linked earning rule
  const onSelectChange = (value) => {
    setValue(value?.value);
  };

  useEffect(() => {
    dispatch({
      type: 'earningRuleList/getEarningRuleListWithTypes',
      payload: {
        reverse: true,
        linkedCampaignType: linkedCampaignTypeEnum.earningCampaign,
        ...defaultFilter
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <CustomTitleWithDropDown
      title={title}
      description={description}
      titleStyle={isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={earningRuleList}
      labelContainPk
      loadMoreAction={'earningRuleList/getEarningRuleListWithTypes'}
      filterAction={'earningRuleList/getEarningRuleListWithTypes'}
      errors={errors}
      errorName={errorName}
      addButtonInfo={addButtonInfo}
      defaultFilter={{ linkedCampaignType: linkedCampaignTypeEnum.earningCampaign, ...defaultFilter, }}
    />
  );
}

const mapPropsToState = (state) => ({
  // linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  earningRuleList: state.earningRuleList.earningRuleList,
});

export default connect(mapPropsToState)(EarningRulesSelectDropdown);

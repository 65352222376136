import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'I18n';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { ReactHookFormMarkDown } from 'components/base/CustomMarkDownArea';
import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import { errorMessage, hasError } from 'components/base/ErrorFieldMessage';
import { ReactHookFormImageUploader } from 'components/base/ImageUploader';
import InputFieldControl from 'components/base/InputFieldControl';
import BrandSelectDropdown from 'components/campaign/campaignCreation/BrandSelectDropdown';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { EMAIL_REG, LanguageConfig } from 'config/CustomEnums';
import QRCodeCollapse from 'containers/merchants/stores/QRCodeCollapse';
import { StoreErrorHandleFields } from 'containers/merchants/stores/StoreErrorHandleFields';

import './CreateStoreStepOneContentSection.scss';

function CreateStoreStepOneContentSection({
  codeDisplayImage,
  codeDownloadImage,
  storeName,
  districtList,
  subCategoryList,
  language,
  brands,
  countryCodes,
  onFocus = () => {},
  setShowAlertPrompt = () => {},
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    dispatch({
      type: 'settings/getCountryCodeList',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const watchName = watch(`storeTranslations.${language}.name`);
  const watchDisplayPriority = watch('displayPriority');
  const watchCoverPhoto = watch('coverPhoto');
  const watchDistrict = watch('district');
  const watchAddress = watch(`storeTranslations.${language}.address`);
  const watchPhoneNumberCountryCode = watch('phoneNumberCountryCode');
  const watchPhoneNumberSubscriberNumber = watch('phoneNumberSubscriberNumber');
  const watchEmailAddress = watch('emailAddress');
  const watchBrand = watch('brand');
  const watchSubcategories = watch('selectedSubcategories') || [];
  const watchLatitude = watch('latitude');
  const watchLongitude = watch('longitude');
  const watchStaffCode = watch('staffCode');
  const watchIsOnlineStore = watch('isOnlineStore');
  const watchThirdPartyStoreCode = watch('thirdPartyStoreCode');

  const isOnlineStoreTipsPopover = () => {
    return (
      <div className="online-store-tip-popover">
        If toggle ON, this store is identified as an online store.
        <p />
        <div>
          Online store
          <ul>
            <li>In frontend, it will not shown in the store directory list;</li>
            <li>
              In CMS, it will shown as
              <ul>
                <li>“Online” type store</li>
                <li>an label in transaction records and dashboard.</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginTop: '-30px' }} className="store-create-step-one">
      <InputFieldControl
        name={`storeTranslations.${language}.name`}
        title={i18n.t('name', { locale: language })}
        rules={
          language === LanguageConfig.english
            ? { required: StoreErrorHandleFields.name.required }
            : {}
        }
        value={watchName}
        setValue={(value) => {
          setValue(`storeTranslations.${language}.name`, value, {
            shouldDirty: true,
          });
        }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      {language === LanguageConfig.english && (
        <CustomTitleWithSwitch
          title={'Online store'}
          tips={'Toggle on to indicate this store is an online store.'}
          tipsPopoverDetail={isOnlineStoreTipsPopover()}
          defaultValue={watchIsOnlineStore}
          setValue={(isChecked) => {
            setValue('isOnlineStore', isChecked, { shouldDirty: true });
          }}
          error={''}
        />
      )}
      {language === LanguageConfig.english && !watchIsOnlineStore ? (
        <>
          <InputFieldControl
            name={'displayPriority'}
            title={'Display order'}
            rules={
              !watchIsOnlineStore
                ? { required: StoreErrorHandleFields.displayPriority.required }
                : {}
            }
            value={watchDisplayPriority}
            type={'number'}
            setValue={(value) => {
              setValue('displayPriority', value, { shouldDirty: true });
            }}
          />
          <label className="create-message-suggest">
            {`Minimum value is 1. The smaller value, the higher priority.`}
          </label>
          <div style={{ display: 'grid' }}>
            <ReactHookFormImageUploader
              uploadSuggest={i18n.t('cover_photo_suggest')}
              name={'coverPhoto'}
              rules={
                !watchIsOnlineStore
                  ? { required: StoreErrorHandleFields.coverPhoto.required }
                  : {}
              }
              minWidth={900}
              minHieght={600}
              aspect={3 / 2}
              value={watchCoverPhoto}
              language={language}
              uploadImageClicked={() => {
                onFocus('coverPhoto');
              }}
            />
          </div>
          <Controller
            control={control}
            name={'district'}
            render={() => (
              <>
                <CustomTitleWithDropDown
                  title="District(optional)"
                  source={districtList}
                  defaultValue={watchDistrict}
                  setValue={(value) => {
                    setValue('district', value, { shouldDirty: true });
                  }}
                  loadMoreAction={'storeModel/getDistrictList'}
                  filterAction={'storeModel/getDistrictList'}
                  onFocus={() => {
                    onFocus('district');
                  }}
                />
              </>
            )}
          />
        </>
      ) : null}
      {!watchIsOnlineStore && (
        <InputFieldControl
          name={`storeTranslations.${language}.address`}
          title={i18n.t('address', { locale: language })}
          rules={
            language === LanguageConfig.english && !watchIsOnlineStore
              ? { required: StoreErrorHandleFields.address.required }
              : {}
          }
          value={watchAddress}
          setValue={(value) => {
            setValue(`storeTranslations.${language}.address`, value, {
              shouldDirty: true,
            });
          }}
          onFocus={() => {
            onFocus('address');
          }}
        />
      )}

      {language === LanguageConfig.english && !watchIsOnlineStore ? (
        <>
          <Controller
            control={control}
            name={'phoneNumberSubscriberNumber'}
            render={() => (
              <CustomTitleWithDropDownAndInput
                title="Mobile number(optional)"
                source={countryCodes}
                defaultValue={watchPhoneNumberCountryCode}
                defaultInputValue={watchPhoneNumberSubscriberNumber}
                needFilter={false}
                customClass="dropdown-short"
                inputCustomClass="dropdown-input-long"
                setValue={(value) => {
                  if (value !== watchPhoneNumberCountryCode) {
                    setValue('phoneNumberCountryCode', value, {
                      shouldDirty: true,
                    });
                  }
                }}
                setInputValue={(value) => {
                  if (value !== watchPhoneNumberSubscriberNumber) {
                    setValue('phoneNumberSubscriberNumber', value, {
                      shouldDirty: true,
                    });
                  }
                }}
                onFocus={() => {
                  onFocus('mobileNumber');
                }}
                inputOnFocusChange={() => onFocus('mobileNumber')}
              />
            )}
          />
          <InputFieldControl
            name={'emailAddress'}
            title={'Email(optional)'}
            rules={{
              validate: {
                validEmail: (watchEmailAddress) => {
                  console.log('@330', watchEmailAddress);
                  if (!watchEmailAddress?.length) {
                    return true;
                  }
                  if (EMAIL_REG.test(watchEmailAddress)) {
                    return true;
                  }
                  return StoreErrorHandleFields.emailAddress.valid;
                },
              },
            }}
            value={watchEmailAddress}
            setValue={(value) => {
              setValue('emailAddress', value, { shouldDirty: true });
            }}
            onFocus={() => {
              onFocus('email');
            }}
          />
        </>
      ) : null}
      {!watchIsOnlineStore && (
        <div>
          <ReactHookFormMarkDown
            content={{
              label: i18n.t('description_optional', { locale: language }),
              key: `storeTranslations.${language}.description`,
              focus: () => {
                onFocus('description');
              },
            }}
          />
        </div>
      )}
      {language === LanguageConfig.english ? (
        <Controller
          control={control}
          name="brand"
          render={() => (
            <>
              <BrandSelectDropdown
                title={'Related brand (Optional)'}
                defaultValue={{
                  value: watchBrand,
                  label: watchBrand?.name,
                }}
                onSelectChange={(item) => {
                  onFocus('brand');
                  if (item.value !== watchBrand) {
                    setValue('brand', item.value, { shouldDirty: true });
                  }
                }}
                addButton={{
                  title: 'Create Now',
                  action: () => {
                    setShowAlertPrompt({
                      title: 'brand',
                      show: true,
                      pathname: '/brands/create/',
                    });
                  },
                  customClass:
                    'multiple-select-option-add btn-add-button-common',
                }}
                editable={
                  !history.location.pathname?.includes('edit') || !brands
                }
                onFocus={() => onFocus('brand')}
              />
              <label className="tips-message">
                Once set, cannot be changed.
              </label>
            </>
          )}
        />
      ) : null}

      {language === LanguageConfig.english && !watchIsOnlineStore ? (
        <>
          <Controller
            control={control}
            name="selectedSubcategories"
            rules={
              language === LanguageConfig.english && !watchIsOnlineStore
                ? { required: StoreErrorHandleFields.subcategories.required }
                : {}
            }
            render={() => (
              <BaseMultipleSelectorV2
                title={'Subcategories'}
                data={{
                  sourceData: subCategoryList,
                  targetData: watchSubcategories,
                  targetChange: (value) => {
                    console.log('subCategory @65', value);
                    setValue('selectedSubcategories', value, {
                      shouldDirty: true,
                    });
                  },
                }}
                addButton={{
                  title: 'Add Subcategory ',
                  action: () => {
                    setShowAlertPrompt({
                      title: 'subcategory',
                      show: true,
                      pathname: '/store_category/create',
                    });
                  },
                }}
                onFocusFunc={() => {
                  onFocus('subCategory');
                }}
                error={{
                  error: hasError(errors, 'selectedSubcategories'),
                  id: 'selectedSubcategories',
                  message: errorMessage(errors, 'selectedSubcategories'),
                }}
              />
            )}
          />
          {/* <ReactHookFormErrorMessage errors={errors} id='selectedSubcategories' /> */}
          <InputFieldControl
            name={'latitude'}
            title={'Latitude'}
            style={{ width: 'auto' }}
            type="number"
            rules={
              language === LanguageConfig.english && !watchIsOnlineStore
                ? { required: StoreErrorHandleFields.latitude.required }
                : {}
            }
            value={watchLatitude}
            setValue={(value) => {
              setValue('latitude', value, { shouldDirty: true });
            }}
          />

          <InputFieldControl
            name={'longitude'}
            title={'Longitude'}
            type="number"
            style={{ width: 'auto' }}
            rules={
              language === LanguageConfig.english && !watchIsOnlineStore
                ? { required: StoreErrorHandleFields.longitude.required }
                : {}
            }
            value={watchLongitude}
            setValue={(value) => {
              setValue('longitude', value, { shouldDirty: true });
            }}
          />
          <InputFieldControl
            name={'staffCode'}
            title={'Staff code'}
            rules={
              language === LanguageConfig.english && !watchIsOnlineStore
                ? {
                    validate: {
                      validStaffCode: (watchStaffCode) => {
                        console.log(
                          '@330',
                          watchStaffCode,
                          watchStaffCode?.length,
                        );
                        if (!watchStaffCode?.length) {
                          return StoreErrorHandleFields.staffCode.required;
                        }
                        if (watchStaffCode?.length !== 6) {
                          return StoreErrorHandleFields.staffCode.length;
                        }
                        return true;
                      },
                    },
                  }
                : {}
            }
            value={watchStaffCode}
            setValue={(value) => {
              setValue('staffCode', value, { shouldDirty: true });
            }}
          />
          {codeDisplayImage ? (
            <>
              <CustomTitleLabel title={'QR code preview'} />
              <label className="qrcode-tips">
                The store QR code is auto generate
              </label>
              <QRCodeCollapse
                codeDisplayImage={codeDisplayImage}
                codeDownloadImage={codeDownloadImage}
                storeName={storeName}
              />
            </>
          ) : null}
        </>
      ) : null}

      {language === LanguageConfig.english ? (
        <InputFieldControl
          name={'thirdPartyStoreCode'}
          title={'Store Code(optional)'}
          value={watchThirdPartyStoreCode}
          setValue={(value) => {
            setValue('thirdPartyStoreCode', value, { shouldDirty: true });
          }}
        />
      ) : null}
    </div>
  );
}

const mapPropsToState = (state) => ({
  checked: state.storeModel.checked,
  errorFields: state.storeModel.errorFields,
  saved: state.storeModel.saved,
  districtList: state.storeModel.districtList || [],
  subCategoryList: state.storeCategoryList.subCategoryList || [],
  formChanged: state.storeModel.formChanged,
  codeDisplayImage: state.storeModel.oneStore?.codeDisplayImage,
  codeDownloadImage: state.storeModel.oneStore?.codeDownloadImage,
  storeName: state.storeModel.oneStore?.storeName,
  brands: state.storeModel.oneStore?.brand,
  countryCodes:
    state.settings.countryCodes?.map((item) => ({
      pk: item.value,
      name: item.value,
    })) || [],
  test: state.language,
});

export default connect(mapPropsToState)(CreateStoreStepOneContentSection);

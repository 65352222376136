import React from 'react';
import { Image } from 'react-bootstrap';
import Slide from '../../assets/images/slide-2-image.svg';
import './OnboardingSlideTwo.scss';
import './OnboardingSlideTwoVertical.scss';
const slideTwoText = (title, description, eg) => {
  return (
    <div className={'onboarding-slide-two-text-container'}>
      <label className="onboarding-slide-two-text-title">{title}</label>
      <label className="onboarding-slide-two-text">{description}</label>
      <label className="onboarding-slide-two-text">{eg}</label>
    </div>
  );
};

function OnboardingSlideTwoVertical({ width, height }) {
  return (
    <div className="onboarding-slide-two-vertical">
      <label className="onboarding-slide-two-title">
        What types of campaign do you need to create？
      </label>
      <Image src={Slide} className="onboarding-slide-two-image" />
      {slideTwoText(
        'Coupon campaign',
        'Coupon campaign Enables customers to acquire coupons by using points;',
        '',
      )}
      {slideTwoText(
        'Earning campaign',
        'Enables customers to earn or to know the instruction of earning reward by completing specific activities;',
        'e.g. Making purchase, scanning QR codes, referring a friends.',
      )}
      {slideTwoText(
        'General message campaign',
        'It’s mainly for communication purpose. It enables customers to know about the latest updates or other specific information. ',
        'e.g. Special announcement from the brand, lates updates on the office hour form the store.',
      )}
    </div>
  );
}

export default OnboardingSlideTwoVertical;

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { DatePicker } from 'antd';
import './DateRangeSelection.scss';
const { RangePicker } = DatePicker;

export const RANGE_TYPES = {
  TODAY: '0',
  LAST_7_DAYS: '1',
  LAST_30_DAYS: '2',
  CUSTOM: '3',
  CURRENT_MONTH: '4'
};

export const getStartEndDate = (range, start, end) => {
  if (range === RANGE_TYPES.TODAY) {
    return { rangeType: range, startDate: moment(), endDate: moment() };
  }
  if (range === RANGE_TYPES.LAST_30_DAYS) {
    return {
      rangeType: range,
      startDate: moment().add(-30, 'days'),
      endDate: moment().add(-1, 'days'),
    };
  }
  if (range === RANGE_TYPES.CUSTOM) {
    return {
      rangeType: range,
      startDate: moment(start),
      endDate: moment(end),
    };
  }
  if (range === RANGE_TYPES.CURRENT_MONTH) {
    return {
      rangeType: range,
      startDate: moment().startOf("month"),
      endDate: moment(),
    };
  }
  return {
    rangeType: RANGE_TYPES.LAST_7_DAYS,
    startDate: moment().add(-7, 'days'),
    endDate: moment().add(-1, 'days'),
  };
};

const DateRangeSelection = ({
  isInCustomListComponent = true,
  applyDateRangeEvent = () => { },
  clearDataEvent = () => { },
  onTab,
  defaultDateRange,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { range, start, end } = queryString.parse(location.search);

  const [originDateState, setOriginDateState] = useState({
    range, start, end
  })

  const { rangeType, startDate, endDate } = originDateState.range || originDateState.start || originDateState.end ?
    getStartEndDate(originDateState.range, originDateState.start, originDateState.end)
    : getStartEndDate(defaultDateRange);

  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
    return () => {
      clearDataEvent && clearDataEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyDateRangeEvent && applyDateRangeEvent(startDate, endDate);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originDateState]);

  useEffect(() => {
    if (!datePickerOpen && initialized) {
      setDateRange({
        rangeType: RANGE_TYPES.CUSTOM,
        startDay: tempStartDate.format('YYYY-MM-DD'),
        endDay: tempEndDate.format('YYYY-MM-DD'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerOpen]);

  const rangeTypeTextMap = {
    [RANGE_TYPES.TODAY]: "Today",
    [RANGE_TYPES.LAST_7_DAYS]: "Last 7 days",
    [RANGE_TYPES.LAST_30_DAYS]: "Last 30 days",
  }

  const setDateRange = ({ rangeType = RANGE_TYPES.TODAY, startDay = null, endDay = null }) => {
    if (isInCustomListComponent) {
      let searchText = `?range=${rangeType}`
      if (startDay) {
        searchText += `&start=${startDay}`
      }
      if (endDay) {
        searchText += `&end=${endDay}`
      }
      history.push({
        pathname: location.pathname,
        search: searchText,
        hash: location.hash,
      });
    }
    setOriginDateState({
      range: rangeType,
      start: startDay,
      end: endDay,
    })
  }

  return (
    <div
      className={`list-section-container-header-buttons dashboard-header-right ${
        onTab ? 'dashboard-tab-range-selection' : ''
      }`}
    >
      {Object.entries(rangeTypeTextMap)?.map(([type, title]) =>
        <div
          className={`dashboard-date-range-btn ${rangeType === type ? 'active' : ''
            }`}
          onClick={() => {
            if (rangeType !== type) {
              setDateRange({ rangeType: type })
            }
          }}
        >
          {title}
        </div>
      )}
      <RangePicker
        disabledTime
        value={[tempStartDate, tempEndDate]}
        className="date-picker-extra"
        format="D MMM, YYYY"
        disabledDate={(current) => current > moment().endOf('day')}
        separator={'-'}
        onChange={(values) => {
          if (!values) {
            return;
          }
          setTempStartDate(values[0]);
          setTempEndDate(values[1]);
        }}
        onOpenChange={setDatePickerOpen}
      />
    </div>
  );
};

export default DateRangeSelection;

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import BaseListContainer from 'containers/base/BaseListContainer';
import Filter from 'containers/engagement/message/Filter';
import CustomListComponent from 'components/base/CustomListComponent';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import DeletePrompt from 'components/base/DeletePrompt';
import AuthButton from 'components/base/AuthButton';
import MessageCredit from 'components/message/MessageCredit';
import { PermissionCodes } from 'config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from 'config/CustomEnums';
import { getHashKeyString } from 'utils';

import './MessageList.scss';

export const MESSAGE_TAB = {
  MESSAGE_CREDIT: "Messages Credit",
  MESSAGE_LIST: "List of Messages"
}

const mapPropsToState = (state) => ({
  pageInfo: state.messageList.pageInfo,
  totalCount: state.messageList.totalCount,
  displayFields: state.messageList.listDisplayFields,
  messageList: state.messageList.currentPageMessageList,
  totalPage: state.messageList.totalPage,
  checkedList: state.messageList.checkedList,
});

function MessageList({
  pageInfo,
  totalCount,
  displayFields,
  messageList,
  totalPage,
  checkedList,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const activeTab = getHashKeyString(location.hash) || MESSAGE_TAB.MESSAGE_CREDIT;

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch({
      type: 'messageList/getCurrentPageMessages',
      payload: {
        ...searchDict,
        page,
        rank,
        searchKey,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const buttons = [
    <AuthButton
      title="Create Message"
      action={() => {
        history.push('/messages/create');
      }}
      requires={PermissionCodes.addMessage}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteMessage,
    },
  ];

  const tabs = [
    {
      name: MESSAGE_TAB.MESSAGE_CREDIT,
      content: (
        <MessageCredit />
      ),
    },
    {
      name: MESSAGE_TAB.MESSAGE_LIST,
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount ? totalCount : 0}
          filter={{ hasFilter: true, component: Filter }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'messageList'}
              permissionGroup={PermissionCodes.message}
              dataList={messageList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'message',
                relatedName: 'channels',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.MESSAGE,
              }}
              actions={['Edit', 'Detail', 'Duplicate', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create a message to get in touch with  your customers. You can send a message through different channels.',
          title: 'Messages',
        }}
        defaultActiveKey={activeTab}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={false}
        tabs={tabs}
        onTabChange={(key) => {
          history.push({
            pathname: location.pathname,
            hash: key,
             });
        }}
      />
      <DeletePrompt
        relatedSections={DELETE_RELATED_SECTIONS.MESSAGE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'messageList/delete',
            payload: {
              afterAction: () => history.push(location),
            },
          });
        }}
        data={checkedList}
        title={'Message'}
        relatedName={'channels'}
      />
    </>
  );
}

export default connect(mapPropsToState)(MessageList);

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { SaveAndBackButtons } from 'components/base/BottomStepComponent';
import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import Loading from 'components/base/Loading';
import CouponCodeSettingSection from 'components/coupon/couponCreation/CouponCodeSettingSection';
import CouponDateTimeSelector from 'components/coupon/couponCreation/CouponDateTimeSelector';
import {
  APIStatus,
  COUPON_FORMATS,
  COUPON_SET_MEDIUM,
  COUPON_SET_TYPES,
} from 'config/CustomEnums';
import { validate } from 'containers/engagement/coupon/CreateCouponValidate';

import { createAction } from 'utils';

import './CreateCouponStepThree.scss';
import './CreateCouponStepTwo.scss';

const CreateCouponStepThree = ({ createCouponTemplateStatus }) => {
  const { getValues, setError, clearErrors, watch, setValue } =
    useFormContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const fromEdit =
    history.location.pathname.endsWith('edit') ||
    history.location.pathname.endsWith('edit/');

  const saveAction = (isGenerater, isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    if (!isValid || isBack) {
      dispatch({
        type: 'createCoupon/stepChange',
        payload: { isBack: isBack, step: 2, isValid },
      });
    } else {
      if (fromEdit) {
        dispatch(
          createAction('createCoupon/updateCouponTemplateInput')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      } else {
        dispatch(
          createAction('createCoupon/createCouponTemplate')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      }
    }
  };

  const watchMediumType = watch('mediumType') || COUPON_SET_MEDIUM.OFFLINE;
  const watchshouldBeUsedInStores = watch('shouldBeUsedInStores');
  const watchIsDifferentCodeForOnlineOffline = watch(
    'isDifferentCodeForOnlineOffline',
  );
  const watchOnlineAndOfflineFormats = watch('onlineAndOfflineFormats');

  const watchOfflineCouponSetType = watch('offlineCouponSetType');

  const watchOnlineCouponSetType = watch('onlineCouponSetType');

  const showDifferentCodeSwitch =
    watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE &&
    watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
    watchOnlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON;

  useEffect(() => {
    if (
      showDifferentCodeSwitch &&
      watchIsDifferentCodeForOnlineOffline === undefined
    ) {
      setValue('isDifferentCodeForOnlineOffline', true, { shouldDirty: true });
    }
    if (
      showDifferentCodeSwitch &&
      watchIsDifferentCodeForOnlineOffline === false &&
      watchOnlineAndOfflineFormats !== COUPON_FORMATS.TEXT
    ) {
      setValue('onlineAndOfflineFormats', COUPON_FORMATS.TEXT, {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIsDifferentCodeForOnlineOffline, showDifferentCodeSwitch]);

  const displayCouponCodeSettingSection = () => {
    switch (watchMediumType) {
      case COUPON_SET_MEDIUM.OFFLINE:
        return (
          watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
        );
      case COUPON_SET_MEDIUM.ONLINE:
        return (
          watchOnlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
        );
      case COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE:
        return [watchOnlineCouponSetType, watchOfflineCouponSetType].includes(
          COUPON_SET_TYPES.IMPORT_EXISTING_COUPON,
        );
      default:
        return false;
    }
  };

  return createCouponTemplateStatus === APIStatus.calling ? (
    <Loading />
  ) : (
    <>
      <div className="create-coupon-step-three-container">
        <CouponDateTimeSelector />
        {showDifferentCodeSwitch ? (
          <>
            <div className="second-section no-margin">
              <div>
                <CustomTitleWithSwitch
                  title={'Set different codes for online & offline stores'}
                  defaultValue={watchIsDifferentCodeForOnlineOffline}
                  setValue={(value) => {
                    setValue('isDifferentCodeForOnlineOffline', value, {
                      shouldDirty: true,
                    });
                  }}
                  bottomDescription={
                    !watchshouldBeUsedInStores &&
                    watchIsDifferentCodeForOnlineOffline
                      ? 'Please note that even when the "applicable store" toggle is off, applying different coupon codes requires customers to select either the online or offline store when acquiring the coupon in the front end.'
                      : null
                  }
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      {displayCouponCodeSettingSection() && (
        <div className="create-coupon-step-three-container has-bottom-margin">
          <CouponCodeSettingSection />
        </div>
      )}

      <SaveAndBackButtons
        saveTempText="Save Only"
        saveText={`Save and ${
          watchOfflineCouponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED
            ? 'Generate'
            : 'Import'
        } Coupon`}
        saveTempAction={() => {
          saveAction(false, false);
        }}
        saveAction={() => {
          saveAction(true, false);
        }}
        backAction={() => {
          saveAction(false, true);
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  createCouponTemplateStatus: state.createCoupon.createCouponTemplateStatus,
});

export default connect(mapPropsToState)(CreateCouponStepThree);

export const URLFragments = {
  list: 'List of Campaigns',
  overview: 'Overview',
};

export const URLFilterKeys = {
  type: 'type',
  searchKey: 'search',
  rank: 'rank',
  page: 'page',
};

export const ContinueCreate = {
  title: 'Continue to Create Campaign',
  content: 'You can continue to create the campaign.',
};

import React from 'react';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton';

export default function FollowCouponSetSwitch({ checked, onChange, disabled }) {
  return (
    <div>
      <label
        style={{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '15px',
          lineHeight: '24px',
          color: '#404040',
          marginRight: '8px',
        }}
      >
        Follow Coupon Set：
      </label>
      <SpecificCustomSwitchButton
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { createAction } from 'utils';
import Loading from 'components/base/Loading';
function ShopifyLogin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const parsedSearch = queryString.parse(currentLocation.search);
  const shopifyAuth = JSON.parse(atob(parsedSearch?.auth || ''))
  const { isLogin, loginFailed } = useSelector((state) => ({
    isLogin: state.users.isLogin,
    loginFailed: state.users.loginFailed,
  }));

  useEffect(() => {
    if (shopifyAuth) {
      dispatch(
        createAction('users/login')({
          username: shopifyAuth.username,
          password: shopifyAuth.password,
          isLoginByShopify: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLogin) {
      history.push('/');
    }
    if (loginFailed) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, loginFailed]);

  return shopifyAuth?.username && shopifyAuth?.password ? <Loading /> : <Redirect to="login" />;
}

export default withRouter(ShopifyLogin);

import React from 'react';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import InputFieldControl from '../base/InputFieldControl';
import { useFormContext } from 'react-hook-form';
import LimitSection from './LimitSection';

function GPSCheckInSection() {
  const { watch, setValue } = useFormContext();

  const latitude = watch("latitude")
  const longitude = watch("longitude")
  const radius = watch("radius")
  return (
    <>
      <label className="create-section-title">GPS CHECK-IN</label>
      <InputFieldControl
        name='latitude'
        title='Latitude'
        value={latitude}
        setValue={(value) => {
          setValue('latitude', value, { shouldDirty: true });
        }}
        type='number'
      />
      <InputFieldControl
        name='longitude'
        title='Longitude'
        value={longitude}
        setValue={(value) => {
          setValue('longitude', value, { shouldDirty: true });
        }}
        type='number'
      />
      <InputFieldControl
        name='radius'
        title='Radius'
        value={radius}
        setValue={(value) => {
          setValue('radius', value, { shouldDirty: true });
        }}
        type='number'
        unit="meters"
        className='coupon-campaign-section-input'
      />
      <LimitSection
        tips={{
          'overallLimit': 'Maximum number of times to do GPS check-in per campaign.',
          'periodicLimit': 'Maximum number of times to do GPS check-in per period.',
          'perHeadLimit': 'Maximum number of times to do GPS check-in per customer.',
          'perHeadPeriodicLimit': 'Maximum number of times to do GPS check-in per customer per period.'
        }}
      />
    </>
  );
}

export default GPSCheckInSection;

import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import './CreatePointRecordStepOne.scss';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import ItemTypeSet from '../base/ItemTypeSet';
import AddPointIcon from '../../assets/images/pointRecord/addPoint.svg';
import RemovePointIcon from '../../assets/images/pointRecord/removePoint.svg';
import { validate } from '../../containers/record/pointRecords/CreatePorintRecordValidate';


export const POINT_RECORD_TYPE = {
  TYPE_ADD: 'Add point',
  TYPE_REMOVE: 'Remove point',
};
const POINT_TYPE_CONFIG = [
  {
    id: POINT_RECORD_TYPE.TYPE_ADD,
    title: 'Add Point',
    image: AddPointIcon,
    description: 'Points will be given to customers',
  },
  {
    id: POINT_RECORD_TYPE.TYPE_REMOVE,
    title: 'Remove Point',
    image: RemovePointIcon,
    description: 'Deduct points from customers',
  },
];


function CreatePointRecordStepOne() {
  const dispatch = useDispatch();
  const { watch, getValues, setValue, clearErrors, setError } = useFormContext();

  const pointTypeField = 'pointType';
  const selectedType = watch(pointTypeField);

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    dispatch({
      type: 'createPointRecord/stepChange',
      payload: {
        isBack: false,
        step: 0,
        isValid: isValid,
      },
    });
  };

  const sections = [
    <div className="create-point-step-one-title">
      <label className="step-one-title">Point Type</label>
      <div className="point-record-step-type-area">
        {POINT_TYPE_CONFIG.map((item) => {
          return (
            <ItemTypeSet
              key={item.id}
              moreThanThree={false}
              item={item}
              selected={selectedType}
              onClick={(id) => {
                setValue(pointTypeField, id, { shouldDirty: true });
              }}
              selectedType={selectedType}
            />
          );
        })}
      </div>
    </div>,
  ];
  return (
    <>
      <ContentSections
        sections={sections}
        hidePreview={true}
      />
      <OnlyContinueButton
        continueAction={() => {
          stepChangeAction();
        }}
        disabledContinue={!selectedType}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
});

export default connect(mapPropsToState)(CreatePointRecordStepOne);

import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getProducts = ({
  productFirst = 250,
  productAfter = '',
  variantFirst = 100,
}) => {
  const query = `{
    shopifyProducts(productFirst: ${productFirst}, productAfter: "${productAfter}", variantFirst: ${variantFirst}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          variants {
            edges {
              node {
                id
                sku
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

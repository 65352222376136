import React from 'react';
import { Image } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { listMultiItem } from 'components/banners/DisplayItems';
import { getCampaignTypeTitle } from 'components/campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { CampaignType, WEEKDAY } from 'config/CustomEnums';
import { GENERAL_TYPE } from 'models/CreateCampaignModel';

import { getReadablePeriod } from 'utils/TimeFormatUtil';

import CheckOnIcon from 'assets/images/check_on.svg';

import './ProfileSection.scss';

function ProfileSection({ data }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};

  const getListStrings = (list) => {
    if (!list || list?.length === 0) {
      return null;
    }
    return list?.map((item) => item.name).toString();
  };

  const profileField = (field, value, showShortDescription = false) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <label className="create-section-field-input profile-section-label-bottom">
          {value || '-'}
        </label>
        {showShortDescription ? (
          <div className="display-over-all-limit-container">
            <Image src={CheckOnIcon} className="display-over-all-limit-icon" />
            <label className="display-over-all-limit">
              Display the overall limit in the campaign.
            </label>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <label className="create-section-title">PROPERTIES</label>
      {profileField(
        'Campaign type',
        getCampaignTypeTitle(campaign.campaignType),
      )}
      {campaign.campaignType === CampaignType.couponsCampaign &&
        profileField(
          'Link to coupon set',
          campaign.linkedCoupon?.pk
            ? `[ID: ${campaign.linkedCoupon?.pk}] ${campaign.linkedCoupon?.name}`
            : campaign.linkedCoupon?.name,
        )}
      {campaign.campaignType === CampaignType.earningRulesCampaign &&
        profileField(
          'Link to earning rule set',
          campaign.linkedEarningRules?.pk
            ? `[ID: ${campaign.linkedEarningRules?.pk}] ${campaign.linkedEarningRules?.name}`
            : campaign.linkedEarningRules?.name,
        )}
      {campaign.hideInAnyChannel ? (
        profileField(
          'Active period',
          getReadablePeriod(
            campaign.activeStartDate,
            campaign.activeEndDate,
            campaign.isAlwaysActivePeriod,
          ),
        )
      ) : (
        <>
          {profileField(
            'Campaign category',
            getListStrings(campaign.categories),
          )}
          {profileField(
            'Target customer groups',
            getListStrings(
              campaign.generalType === GENERAL_TYPE.customerGroup
                ? campaign.customerGroup
                : [],
            ),
          )}
          {profileField(
            'Segments',
            getListStrings(
              campaign.generalType === GENERAL_TYPE.segment
                ? campaign.segments
                : [],
            ),
          )}
          {profileField(
            'Active period',
            getReadablePeriod(
              campaign.activeStartDate,
              campaign.activeEndDate,
              campaign.isAlwaysActivePeriod,
            ),
          )}
          {listMultiItem(
            'Blackout period (by period)',
            campaign.activePeriodValues?.map((item) =>
              getReadablePeriod(item.startDate, item.endDate, false),
            ),
          )}
          {listMultiItem(
            'Blackout period (by weekday)',
            campaign.activeWeekday?.map((item) => WEEKDAY[item]),
          )}
          {profileField(
            'Visible period',
            getReadablePeriod(
              campaign.visibleStartDate,
              campaign.visibleEndDate,
              campaign.isAlwaysVisiblePeriod,
            ),
          )}
          {profileField('Related brand', campaign.linkedBrand?.name)}
          {campaign.campaignType === CampaignType.couponsCampaign && (
            <>
              {profileField(
                'Per campaign coupon acquiring limit',
                campaign?.linkedCoupon?.totalNumberOfGeneratedCoupons === null
                  ? '-'
                  : `${campaign?.linkedCoupon?.totalNumberOfGeneratedCoupons} number of coupons`,
                campaign.displayOverLimit,
              )}
              {profileField(
                'Per head coupon acquiring limit during the campaign active period',
                campaign.perHeadLimit === null
                  ? '-'
                  : `${campaign.perHeadLimit} number of coupons`,
              )}
              {profileField(
                'Required points',
                `${campaign.requiredPoints} points`,
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ProfileSection;

import { EarningRuleErrorHandleField } from 'components/earning/EarningRuleHandleError';
import {
  EarningRuleRewardType,
  EarningRuleType,
  LanguageConfig,
} from 'config/CustomEnums';
import { isEarningRuleRequireToLinkCampaign } from 'models/EarningRulesModel';

// if (!isBack) {
//   const requiredFieldsConfig = [
//     [],
//     ['generalName'],
//     {
//       general_purchase: ['minSpending', 'maxSpending'],
//       gps_check_in: ['latitude', 'longitude', 'radius'],
//       birthday: ['birthStart', 'birthEnd'],
//       reward: ['rewardType', 'coupons', 'quantity', 'rewardTypeX'],
//       new_member: [],
//       member_referral: [],
//       qr_code_scanning: ['qrCodes'],
//     },
//   ];

//   const requiredFields =
//     currentStep === 2
//       ? [
//           ...requiredFieldsConfig[2][data.earningRuleType.toLowerCase()],
//           ...requiredFieldsConfig[currentStep]['reward'],
//         ]
//       : requiredFieldsConfig[currentStep];

//   requiredFields.forEach((field, index) => {
//     if (field === 'maxSpending') {
//       if (
//         data[field] &&
//         parseInt(data[field]) <= parseInt(data['minSpending'])
//       ) {
//         errorFields.push(EarningRuleErrorHandleField[field].name);
//         return;
//       }
//       return;
//     }

//     if (
//       field === 'coupons' &&
//       data['rewardType'] !== EarningRuleRewardType.coupons
//     ) {
//       return;
//     }

//     if (
//       field === 'rewardTypeX' &&
//       data['type'] !== EarningRuleType.generalPurchase
//     ) {
//       return;
//     }

//     if (
//       field === 'rewardTypeX' &&
//       data['rewardType'] !== EarningRuleRewardType.points &&
//       data['type'] === EarningRuleType.generalPurchase
//     ) {
//       return;
//     }

//     const value = currentStep === 1 ? data[LanguageConfig.english] : data;

//     if (!value[field] || value[field].length <= 0) {
//       errorFields.push(EarningRuleErrorHandleField[field].name);
//       return;
//     }

//     if (['latitude', 'longitude'].includes(field)) {
//       const dataField = data[field] + '';
//       if (dataField.split('.')[0].length > 3) {
//         errorFields.push(
//           EarningRuleErrorHandleField[`${field}Limit`].name,
//         );
//         return;
//       }
//     }
//   });

//   checked =
//     errorFields.length <= 0
//       ? CheckStatus.checkedWithSuccess
//       : CheckStatus.checkedWithFail;
// }
function firstStepValidate({ getValues, setError }) {
  const { linkedStampCampaign, type } = getValues();
  let errorMessage = '';
  if (linkedStampCampaign) {
    switch (type) {
      case EarningRuleType.newMember:
        errorMessage = 'New member does not support stamp campaign.';
        break;
      case EarningRuleType.birthday:
        errorMessage = 'Birthday does not support stamp campaign.';
        break;
      case EarningRuleType.levelUpgrade:
        errorMessage = 'Level upgrade does not support stamp campaign.';
        break;
      case EarningRuleType.fillingForm:
        errorMessage = EarningRuleErrorHandleField.linkStampCampaign.message;
        break;
      default:
        errorMessage = '';
        break;
    }
  }
  if (errorMessage) {
    setError('linkedStampCampaign', {
      type: 'manual',
      message: errorMessage,
    });

    return false;
  }
  return true;
}

function secondStepValidate({ getValues, setError }) {
  const [type, enGeneralName] = getValues([
    'type',
    `translations.${LanguageConfig.english}.generalName`,
  ]);
  const errorList = [];
  if (
    isEarningRuleRequireToLinkCampaign(type) &&
    (!enGeneralName || enGeneralName.length > 200)
  ) {
    errorList.push({
      name: 'generalName',
      message: EarningRuleErrorHandleField.generalName.message,
    });
  }
  if (
    !isEarningRuleRequireToLinkCampaign(type) &&
    (!enGeneralName || enGeneralName.length > 200)
  ) {
    errorList.push({
      name: 'generalName',
      message: EarningRuleErrorHandleField.generalName.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
};

const isZero = (value) => {
  return value === 0 || value === '0';
};

function pointRewardTypeValidate(
  campaignType,
  quantity,
  rewardTypeX,
  tpeQuantity,
  rewardTypeTpeX,
  errorList,
) {
  switch (campaignType) {
    case EarningRuleType.generalPurchase:
      if (isEmpty(quantity) || isEmpty(rewardTypeX)) {
        errorList.push({
          name: 'point',
          message: EarningRuleErrorHandleField['point'].message,
        });
        break;
      }
      if (
        isEmpty(tpeQuantity) ^ isEmpty(rewardTypeTpeX) ||
        (isZero(tpeQuantity) && isZero(quantity))
      ) {
        errorList.push({
          name: 'tpe',
          message: EarningRuleErrorHandleField['tpe'].message,
        });
        break;
      }
      if (
        isEmpty(tpeQuantity) &&
        isEmpty(rewardTypeTpeX) &&
        isEmpty(quantity) &&
        isEmpty(rewardTypeX)
      ) {
        errorList.push({
          name: 'pointReward',
          message: EarningRuleErrorHandleField['pointReward'].message,
        });
        break;
      }
      break;
    default:
      if (!quantity && !tpeQuantity) {
        errorList.push({
          name: 'pointReward',
          message: EarningRuleErrorHandleField['pointReward'].message,
        });
      }
  }
}
function couponRewardTypeValidate(quantity, coupon_template, errorList) {
  if (!quantity || quantity <= 0) {
    errorList.push({
      name: 'quantity',
      message: EarningRuleErrorHandleField['quantity'].message,
    });
  }
  if (!coupon_template) {
    errorList.push({
      name: 'coupons',
      message: EarningRuleErrorHandleField['coupons'].message,
    });
  }
}

function thirdStepValidate({ getValues, setError }) {
  const [
    type,
    rewardType,
    quantity,
    rewardTypeX,
    rewardTypeTpeX,
    tpeQuantity,
    couponTemplate,
    activeStartDate,
    activeEndDate,
    isAlwaysActivePeriod,
  ] = getValues([
    'type',
    'rewardType',
    'quantity',
    'rewardTypeX',
    'rewardTypeTpeX',
    'tpeQuantity',
    'coupons',
    'activeStartDate',
    'activeEndDate',
    'isAlwaysActivePeriod',
  ]);
  console.log(getValues());
  const requiredFieldsConfig = {
    general_purchase: ['minSpending', 'maxSpending'],
    gps_check_in: ['latitude', 'longitude', 'radius'],
    birthday: [],
    // reward: ['rewardType', 'coupons', 'quantity', 'rewardTypeX'],
    new_member: [],
    member_referral: [],
    qr_code_scanning: ['qrCodes'],
    filling_form: [],
    level_upgrade: ['levelGoal'],
  };
  const requiredFields = [
    ...requiredFieldsConfig[type.toLowerCase()],
    // ...requiredFieldsConfig['reward'],
  ];
  const errorList = [];
  requiredFields.forEach((field, index) => {
    if (field === 'maxSpending') {
      if (
        getValues(field) &&
        parseInt(getValues(field)) <= parseInt(getValues('minSpending'))
      ) {
        errorList.push({
          name: 'maxSpending',
          message: EarningRuleErrorHandleField[field].message,
        });
        return;
      }
      return;
    }

    // if (field === 'coupons' && rewardType !== EarningRuleRewardType.coupons) {
    //   return;
    // }

    if (
      !getValues(field) ||
      getValues(field).length <= 0
      // getValues(field).length <= 0 ||
      // (field === 'quantity' && getValues(field) <= 0) ||
      // (field === 'coupons' && !getValues(field).name)
    ) {
      errorList.push({
        name: field,
        message: EarningRuleErrorHandleField[field].message,
      });
      return;
    }

    if (['latitude', 'longitude'].includes(field)) {
      const dataField = getValues(field) + '';
      if (dataField.split('.')[0].length > 3) {
        errorList.push({
          name: field,
          message: EarningRuleErrorHandleField[field].message,
        });
        return;
      }
    }
  });

  if (rewardType === EarningRuleRewardType.points) {
    pointRewardTypeValidate(
      type,
      quantity,
      rewardTypeX,
      tpeQuantity,
      rewardTypeTpeX,
      errorList,
    );
  }
  if (rewardType === EarningRuleRewardType.coupons) {
    couponRewardTypeValidate(quantity, couponTemplate, errorList);
  }
  if (
    [
      EarningRuleType.newMember,
      EarningRuleType.birthday,
      EarningRuleType.generalPurchase,
      EarningRuleType.levelUpgrade,
      EarningRuleType.fillingForm,
    ].includes(type)
  ) {
    if (
      !isAlwaysActivePeriod &&
      activeStartDate?.getTime() >= activeEndDate?.getTime()
    ) {
      errorList.push({
        name: 'activeEndDate',
        message: EarningRuleErrorHandleField.activeEndDate.message,
      });
    }
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}

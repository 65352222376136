import { getPurchasedItemCategories } from 'services/PurchasedItemCategoryApiHelper';

import { createModel } from './BaseModel';

const parseCategories = (categories) => {
  let caterories = [];

  categories.forEach((category) => {
    const categoryPK = category.category.replace('-', '_');
    if (!category.subcategories || category.subcategories?.length === 0) {
      caterories.push({
        pk: categoryPK,
        name: category.category,
      });
    } else {
      const tempSubCategories = category.subcategories.map((subcategory) => {
        const subcategoryPK = subcategory.replace('-', '_');
        return {
          pk: `${categoryPK}_${subcategoryPK}`,
          name: subcategory,
          categoryKey: {
            pk: categoryPK,
            name: category.category,
          },
        };
      });
      caterories = caterories.concat(tempSubCategories);
    }
  });
  return caterories;
};

export default createModel({
  namespace: 'purchasedItemCategories',
  params: {
    initState: {},
    sessionKey: 'purchasedItemCategories',
    dataKey: 'purchasedItemCategories',
    listAPI: getPurchasedItemCategories,
    parse: (data) =>
      parseCategories(
        data?.purchasedItemCategories.edges.map((item) => item.node),
      ),
    objectKey: 'purchasedItemCategories',
  },
  states: {},
  reducers: {},
  effects: {},
});

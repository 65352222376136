import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';

import { ArrayStringData, getHashKeyString } from 'utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const activeTab = getHashKeyString(location.hash);

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchPointSource = parsedSearch['point_source'] || '';
  const searchValue = parsedSearch['value'] || '';
  const searchCode = parsedSearch['gift_card_code'] || '';
  const searchCreateDate = parsedSearch['create_date'] || '';
  const searchTimeRange = parsedSearch['time_range'] || '';
  const searchReportType = parsedSearch['report_type'] || '';

  const { maxPointValue } = useSelector((state) => ({
    maxPointValue: state.pointTransactionList.maxPointValue,
  }));

  const [type, setType] = useState(searchType);
  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [pointSource, setPointSource] = useState(
    ArrayStringData(searchPointSource),
  ); 
  const [value, setValue] = useState(() => {
    const values = ArrayStringData(searchValue);
    if (values.length > 0) return values;
    return [0, 0];
  });
  const [code, setCode] = useState(searchCode);
  const [createDate, setCreateDate] = useState(searchCreateDate);
  const [reportsTimeRange, setReportsTimeRange] = useState(searchTimeRange);
  const [reportType, setReportType] = useState(searchReportType);

  let content;
  if (activeTab === 'Reports') {
    content = [
      {
        title: 'Report type',
        data: [
          { pk: 'WEEKLY', name: 'Weekly report' },
          { pk: 'MONTHLY', name: 'Monthly report' },
        ],
        value: reportType,
        setValue: setReportType,
        component: FilterTypeEnum.singleChoice,
        className: 'fit-content-height-area',
      },
      {
        title: 'Time range',
        value: reportsTimeRange,
        setValue: setReportsTimeRange,
        component: FilterTypeEnum.dateRange,
      },
    ];
  } else {
    content = [
      {
        title: 'Type',
        data: [
          { pk: 'TYPE_POINT_ADD', name: 'Add Points' },
          { pk: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
        ],
        value: type,
        setValue: setType,
        component: FilterTypeEnum.singleChoice,
        className: 'fit-content-height-area',
      },
      {
        title: 'Point source',
        data: [
          { name: 'Loyalty', pk: 'LOYALTY' },
          { name: 'Gift card', pk: 'GIFT_CARD' },
          { name: 'Loyalty and Gift card', pk: 'LOYALTY_AND_GIFT_CARD' },
        ],
        value: pointSource,
        setValue: setPointSource,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Record type',
        data: [
          { name: 'Customer earn', pk: 'CUSTOMER_EARN' },
          {
            name: 'Customer redeem gift card',
            pk: 'CUSTOMER_REDEEM_GIFT_CARD',
          },
          { name: 'Customer use', pk: 'CUSTOMER_USED' },
          {
            name: 'Customer use(for cash dollar)',
            pk: 'CUSTOMER_USED_CASH_DOLLAR',
          },
          { name: 'Admin edit', pk: 'ADMIN_EDITED' },
          { name: 'Admin void', pk: 'ADMIN_VOIDED' },
          { name: 'Point expired', pk: 'EXPIRED' },
        ],
        value: recordType,
        setValue: setRecordType,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Gift card code',
        placeholder: 'Search by Gift card code',
        value: code,
        setValue: setCode,
        component: FilterTypeEnum.inputWithTitle,
      },
      {
        title: 'Create date',
        value: createDate,
        setValue: setCreateDate,
        component: FilterTypeEnum.dateRange,
      },
      {
        title: 'Value',
        data: [0, maxPointValue],
        value: value,
        setValue: setValue,
        component: FilterTypeEnum.slider,
      },
    ];
  }

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

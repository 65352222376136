import React, { useState, useEffect } from 'react';

function CustomListCheckbox(props) {
  const action = props.onChange;
  const [checked, setChecked] = useState();

  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked);
    }
  }, [props.checked]);
  return (
    <div
      className={`all-list-scrollable-area-checkbox-container all-list-scrollable-area-table-item-common ${props.className}`}
    >
      <input
        disabled={props.disabled}
        className="all-list-scrollable-area-checkbox"
        type="checkbox"
        checked={checked}
        onChange={() => action()}
      />
    </div>
  );
}
export default CustomListCheckbox;

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';

import {
  ContinueWithBackAndTempSaveButtons,
} from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import MessageGeneralSection from 'components/message/MessageGeneralSection';
import { ActionType, ApprovalStatus, LanguageConfig } from 'config/CustomEnums';
import { validate } from 'containers/engagement/message/CreateMessageValidate';
import { afterSaveASDraftSuccess } from 'containers/engagement/message/CreateMessage';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  campaign: state.createCampaign.campaign,
});

// for push and inbox
function CreateMessageStepTwo({
  languages,
  campaign,
  setShowSentPrompt,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const params = useParams();

  const { getValues, setValue, setError, clearErrors, watch } = useFormContext();
  const approvalStatus = watch('approvalStatus');
  const isPublished = approvalStatus === ApprovalStatus.published

  useEffect(() => {
    if (search && campaign?.id) {
      const relatedCampaign = campaign?.translations[LanguageConfig.english];
      if (!relatedCampaign) {
        return;
      }
      relatedCampaign.id = campaign?.id;
      relatedCampaign.pk = campaign?.pk;
      relatedCampaign.name = relatedCampaign?.generalName;
      setValue(
        'relatedCampaign',
        relatedCampaign,
        { shouldDirty: true },
      );
      dispatch({
        type: 'createCampaign/clearData',
        payload: {
          deleteSession: search.campaign,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
      languages: languages,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: 1,
        isValid,
      },
    });
  };

  const saveTempAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack: false,
      languages: languages,
    });
    if (isValid && !isPublished) {
      dispatch(
        createAction('createMessage/saveAsDraftMessage')({
          values: getValues(),
          afterActions: (tempSavePk, translationPks) => {
            afterSaveASDraftSuccess({ setValue, tempSavePk, translationPks })
          },
        }),
      );
    } else if (isValid && isPublished) {
      dispatch(
        createAction('createMessage/createOrUpdateMessage')({
          values: getValues(),
          actionType: ActionType.tempForPulished,
          afterActions: () => {
            setShowSentPrompt(true);
          },
        }),
      );
    } else {
      dispatch({
        type: 'createMessage/stepChange',
        payload: {
          isBack: false,
          step: 1,
          isValid,
        },
      });
    }
  };

  const renderTabForLanguage = (language) => {
    return [<MessageGeneralSection language={language} />];
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <ContinueWithBackAndTempSaveButtons
        saveTempText={params.id ? 'Update' : ''}
        backAction={() => stepChangeAction(true)}
        saveTempAction={() => saveTempAction()}
        continueAction={() => stepChangeAction(false)}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateMessageStepTwo);

export const CampaignType = {
  allTypes: 'all',
  generalMessageCampaign: 'GENERAL_MESSAGE_CAMPAIGN',
  earningRulesCampaign: 'EARNING_CAMPAIGN',
  couponsCampaign: 'COUPON_CAMPAIGN',
  stampCampaign: 'STAMP_CAMPAIGN',
};
export const COUPON_SET_MEDIUM = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  ONLIEN_OR_OFFLINE: 'ONLINE_OR_OFFLINE',
};
export const GIFTCARD_CONTACT_TYPE = {
  EMAIL: 'email',
  SMS: 'sms',
};

export const COUPON_SET_TYPES = {
  SYSTEM_GENERATED: 'SYSTEM_GENERATED',
  IMPORT_EXISTING_COUPON: 'IMPORT_EXISTING_COUPON',
};
export const COUPON_REDEMPTION_METHOD = {
  MERCHANT_APP_SCAN_COUPON_QR_CODE: 'MERCHANT_APP_SCAN_COUPON_QR_CODE',
  USERS_SCAN_STORE_QR_CODE: 'USERS_SCAN_STORE_QR_CODE',
  USERS_COPY_THE_DISCOUNT_CODE: 'USERS_COPY_THE_DISCOUNT_CODE',
  INPUT_STORE_STAFF_CODE: 'INPUT_STORE_STAFF_CODE',
  POS_UPDATED_CODE: 'POS_UPDATED_CODE',
};

export const COUPON_FORMATS = {
  COUPON_QR_CODE: 'COUPON_QR_CODE',
  COUPON_BAR_CODE: 'COUPON_BAR_CODE',
  TEXT: 'TEXT',
};

export const COUPON_CODE_TYPE = {
  GENERIC: 'GENERIC',
  UNIQUE: 'UNIQUE',
};

export const StepStatus = {
  error: 'error',
  processing: 'processing',
  finished: 'finished',
  waiting: 'waiting',
};

export const LanguageConfig = {
  english: 'en',
  simplifiedChinese: 'zh-Hans',
  traditionalChinese: 'zh-Hant',
};

export const LanguageUpperConfig = {
  english: 'EN',
  simplifiedChinese: 'ZH_HANS',
  traditionalChinese: 'ZH_HANT',
};

export const EarningRuleType = {
  allTypes: 'all',
  generalPurchase: 'GENERAL_PURCHASE',
  memberReferral: 'MEMBER_REFERRAL',
  birthday: 'BIRTHDAY',
  qrCodeScanning: 'QR_CODE_SCANNING',
  gpsCheckIn: 'GPS_CHECK_IN',
  newMember: 'NEW_MEMBER',
  fillingForm: 'FILLING_FORM',
  levelUpgrade: 'LEVEL_UPGRADE',
};

export const EarningRuleTypeKey = {
  All: 'allTypes',
  GENERAL_PURCHASE: 'generalPurchase',
  MEMBER_REFERRAL: 'memberReferral',
  BIRTHDAY: 'birthday',
  QR_CODE_SCANNING: 'qrCodeScanning',
  GPS_CHECK_IN: 'gpsCheckIn',
  NEW_MEMBER: 'newMember',
  FILLING_FORM: 'fillingForm',
  LEVEL_UPGRADE: 'levelUpgrade',
};

export const LanguageTabTransLation = {
  english: 'English',
  simplifiedChinese: '简体',
  traditionalChinese: '繁体',
};

export const LanguageTransLation = {
  en: 'English',
  'zh-Hans': '简体',
  'zh-Hant': '繁体',
};

export const AmOrPm = {
  am: 'am',
  pm: 'pm',
};

export const SavingStatus = {
  onSaving: 'onSaving',
  onFinished: 'onFinished',
};

export const APIStatus = {
  success: 'success',
  calling: 'calling',
  failed: 'failed',
  none: 'none',
};

export const MessageChannel = {
  all: 'All Possible Channels (SMS)',
  allWhatsapp: 'All Possible Channels (Whatsapp)',
  push: 'Push',
  email: 'Email',
  sms: 'SMS',
  whatsapp: 'Whatsapp',
  web: 'Web',
  inbox: 'Inbox',
};

export const MessageBackendChannel = {
  all: 'ALL_POSSIBLE_CHANNELS_SMS',
  allWhatsapp: 'ALL_POSSIBLE_CHANNELS_WHATSAPP',
  push: 'PUSH_NOTIFICATION',
  email: 'EMAIL',
  sms: 'SMS',
  whatsapp: 'WHATSAPP',
  web: 'WEB', // CMS do not have this choice
  inbox: 'INBOX',
};

export const MessageWhatsappTemplateType = {
  TEXT: 'Text',
  IMAGE: 'Image',
  DOCUMENT: 'Document',
  VIDEO: 'Video',
};

export const MessageDefaultSteps = ['Channel', 'Property', 'Preview & Send'];

export const MessageStepConfig = [
  {
    name: 'Channel',
    show: (channels) => true,
  },
  {
    name: 'Content for Push/Inbox',
    show: (channels) =>
      channels.includes(MessageChannel.all) ||
      channels.includes(MessageChannel.allWhatsapp) ||
      channels.includes(MessageChannel.push) ||
      channels.includes(MessageChannel.inbox),
  },
  {
    name: 'Content for email',
    show: (channels) =>
      channels.includes(MessageChannel.all) ||
      channels.includes(MessageChannel.allWhatsapp) ||
      channels.includes(MessageChannel.email),
  },
  {
    name: 'Content for SMS',
    show: (channels) =>
      channels.includes(MessageChannel.all) ||
      channels.includes(MessageChannel.sms),
  },
  {
    name: 'Content for Whatsapp',
    show: (channels) =>
      channels.includes(MessageChannel.allWhatsapp) ||
      channels.includes(MessageChannel.whatsapp),
  },
  {
    name: 'Property',
    show: (channels) => true,
  },
  {
    name: 'Preview & Send',
    show: (channels) => true,
  },
];

export const CheckStatus = {
  initOrNotChecked: -1,
  checkedWithFail: 0,
  checkedWithSuccess: 1,
};

export const SavedStatus = {
  init: -1,
  savedWithFail: 0,
  savedWithSuccess: 1,
};

export const EarningRuleRewardType = {
  coupons: 'COUPON',
  points: 'POINTS',
  stamps: 'STAMP',
};
export const EarningRuleLinkedCampaign = {
  stampCampaign: 'STAMP_CAMPAIGN',
  earningCampaign: 'EARNING_CAMPAIGN',
};

export const EarningRuleDefinitionType = {
  allFields: 'ALLFIELD',
  mandatoryFields: 'MANDATORYFIELD',
  oneField: 'ONEFIELD',
};

export const StatusTag = {
  active: 'Active',
  activeRequired: 'Active(Acquired)',
  deactive: 'Deactive',
  inactive: 'Inactive',
  deleteRequested: 'RequestedToDel',
  temporary: "Temporary",
  temporaryEarning: "Temporary Earning",
  preActive: 'Pre-Active',
};

export const PublishTagType = {
  published: 'Published',
  unPublished: 'Unpublished',
  expired: 'Expired',
  draft: 'Draft',
  pendingForApproval: 'PendingForApproval',
};

export const DownloadTag = {
  completed: 'Exported',
  cancelled: 'Cancelled',
  downloading: 'Exporting',
  importing: 'Importing',
  importCompleted: 'Imported',
  error: 'Error',
  generating: 'Generating',
  generated: 'Generated',
  pendingForImport: 'Pending',
  pendingForExport: 'Pending',
  pendingForGenerate: 'Pending',
};

export const MessageTag = {
  scheduled: 'Scheduled',
  draft: 'Draft',
  pendingForApproval: 'PendingForApproval',
  saved: 'Saved',
  sent: 'Sent',
  sending: 'Sending',
  error: 'Failed',
};

export const ConditionTag = {
  EQUAL_TO: 'Equal to',
  NOT_EQUAL_TO: 'Not equal to',
  IS_EMPTY: 'Is empty',
  IS_NOT_EMPTY: 'Is not empty',
  BETWEEN: 'Between',
  LESS_THAN: 'Less than',
  GREATER_THAN: 'Greater than',
  IN: 'In',
  CONTAINS_TEXT: "Contains text",
  DOES_NOT_CONTAIN_TEXT: "Does not contain text",
};

export const DaysTag = {
  '1_DAY': '1 day',
  '7_DAYS': '7 days',
  '1_MONTH': '1 month',
  '3_MONTHS': '3 months',
  '6_MONTHS': '6 months',
  '1_YEAR': '1 year',
};

const domain =
  process.env.REACT_APP_IMAGE || 'https://nginx.aillia.hkdev.motherapp.com';

export const ImageDomain = `${domain}/media/`;

export const StaticImageDomain = `${domain}/static/`;

const webAppDomain =
  process.env.REACT_APP_WEBAPP_DOMAIN ||
  'https://webapp.aillia.hkdev.motherapp.com';

// TODO: webapp domain
export const CampaignDetailUrl = `${webAppDomain}/campaign/`;

const openInAppHost =
  process.env.REACT_APP_OPEN_IN_APP_HOST || 'download.aillia.motherapp.cn';

export const AppCampaignDetailUrl = `https://${openInAppHost}/?page=campaignDetail&id=`;

export const GenderOptions = [
  { pk: 'MALE', name: 'Male', value: 'MALE' },
  { pk: 'FEMALE', name: 'Female', value: 'FEMALE' },
  { pk: 'NOT_DISCLOSED', name: 'Prefer not to say', value: 'NOT_DISCLOSED' },
];

export const MonthOptions = [
  { pk: '1', name: 'January', value: '1' },
  { pk: '2', name: 'February', value: '2' },
  { pk: '3', name: 'March', value: '3' },
  { pk: '4', name: 'April', value: '4' },
  { pk: '5', name: 'May', value: '5' },
  { pk: '6', name: 'June', value: '6' },
  { pk: '7', name: 'July', value: '7' },
  { pk: '8', name: 'August', value: '8' },
  { pk: '9', name: 'September', value: '9' },
  { pk: '10', name: 'October', value: '10' },
  { pk: '11', name: 'November', value: '11' },
  { pk: '12', name: 'December', value: '12' },
];

export const CountryCodeOptions = [
  { pk: 852, name: '+852', value: 852 },
  { pk: 853, name: '+853', value: 853 },
  { pk: 86, name: '+86', value: 86 },
];

export const BANNER_CONTENT_TYPE = {
  ALL_TYPE: 'all',
  CAMPAIGN: 'CAMPAIGN',
  // PRODUCT: 'PRODUCT',
};

export const POINT_RECORD_TYPES = [
  { key: 'TYPE_POINT_ADD', name: 'Add Points' },
  { key: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
];

export const COUPON_TEMPLATE_TYPE = {
  storeCode: 'STORE_CODE',
  couponCode: 'COUPON_CODE',
  couponQRCode: 'COUPON_QR_CODE',
  couponCodeImage: 'COUPON_CODE_IMAGE',
};

export const COUPON_TEMPLATE_TYPE_NAME = {
  STORE_CODE: 'Store code',
  COUPON_CODE: 'Coupon code',
  COUPON_QR_CODE: 'Coupon QR code',
  COUPON_CODE_IMAGE: 'Coupon code image',
};

export const DELETE_RELATED_SECTIONS = {
  CUSTOMER: [
    'AppInstallation',
    'CampaignParticipationRecord',
    'Coupon',
    'CouponTransaction',
    'PointTransaction',
    'Transaction',
  ],
  SEGMENT: ['Campaign'],
  CUSTOMER_GROUP: ['Campaign', 'ParticipationCustomer'],
  CAMPAIGN: [
    'Message',
    'CampaignParticipationRecord',
    'CouponTransaction',
    'PointsTransaction',
    'Segment Campaign Characteristic',
    'Banner',
  ],
  CAMPAIGN_CATEGORY: ['Campaign'],
  MESSAGE: ['InboxMessage'],
  EARNING_RULE: ['Campaign'],
  COUPON_TEMPLATE: ['Campaign', 'Coupon', 'EarningRule'],
  COUPON: ['CouponTransaction'],
  BRAND: ['Campaign', 'CouponTemplate', 'Store'],
  STORE: ['CampaignParticipationRecord', 'Coupon', 'Transaction'],
  STORE_CATEGORY: ['StoreSubcategory'],
  TRANSACTION: [
    'CampaignParticipationRecord',
    'PurchasedItem',
    'Coupon transaction',
    'Point transaction',
  ],
  POINT_RECORD: [],
  COUPON_RECORD: [],
  ADMIN_GROUP: ['ParticipationAdmin'],
  ADMIN: ['CouponTransaction', 'ImportJob', 'ExportJob'],
  BANNER: [],
  WEBVIEW: [],
  STAMP_RECORD: [],
};

export const EMAIL_REG =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SESSION_KEYS = {
  ADMIN_GROUP_SESSION_KEY: 'tempAdminGroup',
  ADMIN_SESSION_KEY: 'tempAdmin',
  STORE_SESSION_KEY: 'tempStore',
  LANGUAGE_SESSION_KEY: 'language',
  LANGUAGE_DATA_SESSION_KEY: 'languages',
  GIFTCARD_SESSION_KEY: 'giftCard',
  GIFTCARD_DATA_SESSION_KEY: 'giftCards',
  GIFTCARD_RECORD_SESSION_KEY: 'giftCardRecord',
  GIFTCARD_RECORD_DATA_SESSION_KEY: 'giftCardRecords',
  GIFTCARD_VIRTUAL_CONFIG_SESSION_KEY: 'virtualGiftCardVariant',
  GIFTCARD_VIRTUAL_CONFIG_DATA_SESSION_KEY: 'virtualGiftCardVariants',
  LEVEL_DISCOUNT_SESSION_KEY: 'levelDiscount',
  LEVEL_DISCOUNT_DATA_SESSION_KEY: 'levelDiscounts',
  CREATE_CAMPAIGN_SESSION_KEY: 'createCampaign',
  CREATE_CAMPAIGN_END_SESSION_KEY: 'createCampaignStepEnd',
  CREATE_CAMPAIGN_ORIGIN_DATA_SESSION_KEY: 'createCampaignOriginalData',
  CAMPAIGN_LIST_SESSION_KEY: 'campaigns',
  CAMPAIGN_CATEGORY_SESSION_KEY: 'campaignCategoryKey',
  CREATE_CAMPAIGN_CATEGORY_SUCCESS: 'createCampaignCategorySuccess',
  CREATE_CAMPAIGN_CATEGORY_FAILD: 'createCampaignCategoryFailed',
  CAMPAIGN_CATEGORY_ORDER_LAST: 'capaignCategoryLast',
  EARNING_RULES_SESSION_KEY: 'tempEarningRule',
  FILLING_FORM_SESSION_KEY: 'fillingForm',
  FILLING_FORM_DATA_SESSION_KEY: 'fillingForms',
  CREATE_MESSAGE_SESSION_KEY: 'createMessage',
  CREATE_MESSAGE_STEP_END_SESSION_KEY: 'createMessageStepEnd',
  CREATE_MESSAGE_ORIGINAL_DATA_SESSION_KEY: 'createMessageOriginalData',
  CREATE_COUPON_SESSION_KEY: 'createCoupon',
  CREATE_COUPON_END_SESSION_KEY: 'createCouponStepEnd',
  CREATE_COUPON_ORIGIN_DATA_SESSION_KEY: 'createCouponOriginalData',
  CREATE_BRAND_SESSION_KEY: 'createBrand',
  CREATE_BRAND_STEP_END_SESSION_KEY: 'createBrandStepEnd',
  CREATE_BRAND_ORIGINAL_DATA_SESSION_KEY: 'createBrandOriginalData',
  CREATE_POINT_RECORD_STEP_END_SESSION_KEY: 'createPointRecordStepEnd',
  CREATE_POINT_RECORD_ORIGINAL_DATA_SESSION_KEY:
    'createPointRecordOriginalData',
};

export const TokenErrors = {
  Expiry: 'Signature has expired',
  DecodingError: 'Error decoding signature',
  RefreshTokenInvalid: 'Invalid refresh token',
  RefreshTokenExpired: 'Refresh token is expired',
  NoPermission: 'You do not have permission to perform this action',
};

export const STORE_LOADING_TYPE = {
  BRAND: 'brand',
  CAMPAIGN: 'campaignIn',
  COUPON: 'couponTemplateIn',
};

export const SIGN_UP_METHOD_DISPLAY = {
  MOBILE_PHONE_NUMBER: 'Mobile phone number',
  'GOOGLE-OAUTH2': 'Google OAuth2',
  FACEBOOK: 'Facebook',
  'APPLE-ID': 'Apple ID',
  EMAIL_ADDRESS: 'Email address',
  MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS: "Both Mobile phone number and email address",
};

export const SIGN_UP_METHOD = {
  MOBILE_PHONE_NUMBER: "MOBILE_PHONE_NUMBER",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS: "MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS",
  'GOOGLE-OAUTH2': "GOOGLE-OAUTH2",
  FACEBOOK: "FACEBOOK",
  'APPLE-ID': "APPLE-ID",
}

export const ADMINISTRATOR_TYPES = {
  CMS_ADMINISTRATOR: 'AILLIA_ADMIN',
  MERCHANT_APP_ACCOUNT: 'MERCHANT_APP_ACCOUNT',
};

export const ActionType = {
  save: 'save',
  publish: 'publish',
  none: 'none',
  saveAsDraft: 'saveAsDraft',
  update: 'update',
  submitForApproval: 'submitForApproval',
  pendingForApproval: 'pendingForApproval',
  withdraw: 'withdraw',
  approval: 'approval',
  unpublish: 'unpublish',
  tempForPulished: 'tempForPublished',
  withdrawFromPendding: 'withdrawPendingforApproval',
  withdrawFromApproval: 'withdrawApproval',
  reclaim: 'reclaim',
};

export const ApprovalStatus = {
  draft: 'DRAFT',
  pendingForApproval: 'PENDING_FOR_APPROVAL',
  published: 'PUBLISHED',
  scheduled: 'SCHEDULED',
  pending: 'PENDING',
};

export const WEEKDAY = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export const POINTS_EXPIRY_DATE_VALUE = {
  ALL_TIME: 'ALL_TIME',
  IN_PERIOD: 'IN_PERIOD',
  DAYS_SINCE_ACQUIRE: 'DAYS_SINCE_ACQUIRE',
};

export const EXPIRY_TYPE_CONFIG = [
  {
    value: POINTS_EXPIRY_DATE_VALUE.ALL_TIME,
    label: 'All the time',
  },
  {
    value: POINTS_EXPIRY_DATE_VALUE.IN_PERIOD,
    label: 'In a period',
  },
  {
    value: POINTS_EXPIRY_DATE_VALUE.DAYS_SINCE_ACQUIRE,
    label: 'Number of days since acquiring',
  },
];

export const DISCOUNT_TYPE = {
  TRANSACTION: 'TRANSACTION',
  ITEM: 'ITEM'
}

export const DISCOUNT_VALUE_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  FIXED_VALUE: 'FIXED_VALUE',
};

export const REFUND_TYPE = {
  TOTAL_AMOUNT_OF_ORDER: 'TOTAL_AMOUNT_OF_ORDER',
  CUSTOM_QUANTITY: 'CUSTOM_QUANTITY',
};

export const CUSTOMER_DETAIL_COUPON_TYPE = {
  all: 'all',
  available: 'AVAILABLE',
  unavailable: 'UNAVAILABLE',
  used_or_expired: 'USED_OR_EXPIRED',
}

export const COUPON_VIRTUAL_REAL_TYPE = {
  normal: 'NORMAL',
  virtual: 'VIRTUAL',
  real: 'REAL',
}

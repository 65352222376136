import React from 'react';
import BasePrompt from '../base/prompt/BasePrompt';
import { gotoOutWeb } from '../../utils';

function RedirectToShopifyPrompt(props) {
  const show = props.show;
  const onHide = props.onHide || (() => {});
  const onConfirm = props.onConfirm || (() => {});

  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Send',
        action: () => {
          gotoOutWeb(
            'https://accounts.shopify.com/select?rid=875fc7ee-2606-4f42-b6d6-15b780f11753',
          ); //TODO: need to confirm this shopify link
          onConfirm();
        },
      }}
      leftButton={{
        text: 'Cancel',
        action: onHide,
      }}
      title={'Redirect to Shopify?'}
      description={
        'You have to go to Shopify to create online transaction records. Are you sure you to continue?'
      }
    />
  );
}

export default RedirectToShopifyPrompt;

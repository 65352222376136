import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'react-bootstrap';

import './PurchaseItemSection.scss';

import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import { DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE } from 'config/CustomEnums';
import { TRANSACTION_EVENT_TYPE } from 'containers/record/records/TransactionList';
import DiscountSection from './DiscountSection';
import TransactionAdditionalData from './TransactionAdditionalData';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';


function PurchaseItemSection({
  index = 0,
}) {
  const { watch, setValue, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const purchasedItemsField = 'purchasedItems';
  const productNameField = `purchasedItems.${index}.productName`;
  const skuField = `purchasedItems.${index}.sku`;
  const categoryField = `purchasedItems.${index}.category`;
  const subcategoryField = `purchasedItems.${index}.subcategory`;
  const brandField = `purchasedItems.${index}.brand`;
  const quantityField = `purchasedItems.${index}.quantity`;
  const originalPriceField = `purchasedItems.${index}.originalPrice`
  const valueField = `purchasedItems.${index}.value`;
  const itemDiscountListField = `purchasedItems.${index}.discounts`;
  const itemAdditionalDataField = `purchasedItems.${index}.additionalData`;
  const isRefundedField = `purchasedItems.${index}.isRefunded`;

  const purchasedItems = watch(purchasedItemsField);
  const productName = watch(productNameField);
  const sku = watch(skuField);
  const category = watch(categoryField);
  const subcategory = watch(subcategoryField);
  const brand = watch(brandField);
  const quantity = watch(quantityField);
  const originalPrice = watch(originalPriceField);
  const value = watch(valueField);
  const itemDiscountList = watch(itemDiscountListField);
  const isRefunded = watch(isRefundedField);
  const displayOfflineEventType = watch('displayOfflineEventType');

  useEffect(() => {
    if (!itemDiscountList || itemDiscountList?.length < 1) {
      setValue(itemDiscountListField, [{
        type: DISCOUNT_TYPE.ITEM,
        valueType: DISCOUNT_VALUE_TYPE.PERCENTAGE,
        value: null,
        code: null,
      }], { shouldDirty: true, });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="d-flex">
        <div className="first-section-title">{`PURCHASED ITEM #${index + 1}`}</div>
        {purchasedItems.length > 1
          ? (
            <button
              className="base-delete-button base-back-button"
              onClick={() => {
                let itemList = purchasedItems;
                clearErrors()
                itemList.splice(index, 1);
                setValue(purchasedItemsField, itemList, { shouldDirty: true });
              }}
            >
              Delete
            </button>
          )
          : null}
      </div>

      <CustomTitleWithInput
        title={'Product name'}
        error={{ error: hasError(errors, productNameField) }}
        defaultValue={productName}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(productNameField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={productNameField} />

      <CustomTitleWithInput
        title={'Sku (optional)'}
        error={{ error: hasError(errors, skuField) }}
        defaultValue={sku}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(skuField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={skuField} />

      <CustomTitleWithInput
        title={'Category (optional)'}
        error={{ error: hasError(errors, categoryField) }}
        defaultValue={category}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(categoryField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={categoryField} />

      <CustomTitleWithInput
        title={'Subcategory (optional)'}
        error={{ error: hasError(errors, subcategoryField) }}
        defaultValue={subcategory}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(subcategoryField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={subcategoryField} />

      <CustomTitleWithInput
        title={'Brand (optional)'}
        error={{ error: hasError(errors, brandField) }}
        defaultValue={brand}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(brandField, value, { shouldDirty: true, });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={brandField} />

      <CustomTitleLabel title="Quantity" />
      <div className="d-flex">
        <input
          className={`day-input-fields custom-number-input-short ${hasError(errors, quantityField)
            ? 'error-field'
            : ''
            }`}
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            setValue(quantityField, value, { shouldDirty: true });
          }}
          value={quantity || ''}
        />
        <label className="days-label">number of items</label>
      </div>
      <ReactHookFormErrorMessage errors={errors} id={quantityField} />
      
      <CustomTitleLabel title="Original price" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          className={`day-input-fields custom-number-input-short ${hasError(errors, originalPriceField)
            ? 'error-field'
            : ''
            }`}
          type="number"
          onChange={(e) => {
            // let data = e.target.value;
            // data = data.replace(/[^0-9.]/g, '');
            setValue(originalPriceField, e.target.value, { shouldDirty: true });
          }}
          value={originalPrice || ''}
        />
      </div>
      <ReactHookFormErrorMessage errors={errors} id={originalPriceField} />

      <CustomTitleLabel title="Final price" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          className={`day-input-fields custom-number-input-short ${hasError(errors, valueField)
            ? 'error-field'
            : ''
            }`}
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            setValue(valueField, value, { shouldDirty: true });
          }}
          value={value || ''}
        />
      </div>
      <ReactHookFormErrorMessage errors={errors} id={valueField} />

      <div className='multiple-discounts'>
        {itemDiscountList?.map((value, index) => <DiscountSection index={index} discountListField={itemDiscountListField} />)}
        <div>
          <Button
            onClick={() => {
              const tempArray = [].concat(itemDiscountList);
              const value = {
                type: DISCOUNT_TYPE.ITEM,
                valueType: DISCOUNT_VALUE_TYPE.PERCENTAGE,
                value: null,
                code: null,
              };
              tempArray.push(value);
              setValue(itemDiscountListField, tempArray, { shouldDirty: true, });
            }}
            className="btn-add-button-common"
          >
            Add discount
          </Button>
        </div>
      </div>
      <div className='item-addtional-data'>
        <TransactionAdditionalData additionalDataField={itemAdditionalDataField} />
      </div>
      {displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED &&
        <CustomTitleWithSwitch
          title={'Is it refunded?'}
          defaultValue={isRefunded || false}
          setValue={(value) => {
            setValue(isRefundedField, value, { shouldDirty: true });
          }}
        />
      }

      {index === purchasedItems.length - 1
        ? (
          <div className="content-section-table-container">
            <Button
              onClick={() => {
                const tempArray = [].concat(purchasedItems);
                const value = {
                  productName: null,
                  sku: null,
                  category: null,
                  brand: null,
                  quantity: null,
                  value: null,
                };
                tempArray.push(value);
                setValue(purchasedItemsField, tempArray, { shouldDirty: true, });
              }}
              className="btn-add-button-common"
            >
              Add Purchased Item
            </Button>
          </div>
        )
        : null}
    </>
  );
}

export default PurchaseItemSection;

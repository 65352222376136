import { APIStatus } from '../config/CustomEnums';

export default {
  namespace: 'loading',
  state: {
    status: APIStatus.none,
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import {
  SavedStatus,
} from '../../../config/CustomEnums';
import CreateAdminGroupStepOne from './CreateAdminGroupStepOne';
import BaseForm from '../../../components/base/v2/BaseForm';


function CreateAdminGroup({
  adminGroup,
  saved,
  hasUpdatedDefaultValues
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    return () => {
      dispatch(createAction('adminGroup/clearData')());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/administrator_groups');
    }
  }, [saved, history]);

  useEffect(() => {
    const id = params.id;
    if (id) {
      dispatch(createAction('adminGroup/getOneAdminGroup')({ id }));
    } else {
      dispatch({
        type: 'adminGroup/loadDataFromSessionAndSession',
      })
    }
  }, [dispatch, params]);

  const stepSet = [<CreateAdminGroupStepOne/>]

  return (
    <div className="customer">
      <BaseForm
        defaultValues={adminGroup}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={saved === SavedStatus.savedWithSuccess}
        tempSave={(save, getValues) => {
          if (save) {
            dispatch({
              type: 'adminGroup/saveAdminGroupToSessionStorage', 
              payload: { values: getValues() } 
            });
          } else {
            dispatch({
              type: 'adminGroup/removeAdminGroupFromSessionStorage'
            });
          }
        }}
        showFinishPop={saved === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Administrator group is successfully created.',
          steps: null,
          onHide:()=>{},
          buttons: [
            {
              text: 'Back to administrator group list',
              action: () =>
                history.push({
                  pathname: '/administrator_groups',
                }),
            },
          ],
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? adminGroup.name : 'Create administrator group'}
          />
        }
        caution={{
          detail: '',
          title: params.id
            ? `Edit ${adminGroup.name}`
            : 'Create administrator group',
          model: 'adminGroup',
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  adminGroup: state.adminGroup.oneAdminGroup || {},
  saved: state.adminGroup.saved,
  hasUpdatedDefaultValues: state.adminGroup.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CreateAdminGroup);


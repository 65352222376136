import React from 'react';
import i18n from '../../I18n';
import '../base/CustomMarkDownArea.scss';

import { isLongScreen } from '../../utils/ScreenUtil';
import { useFormContext } from 'react-hook-form';
import { EarningRuleErrorHandleField } from '../earning/EarningRuleHandleError';
import { CustomErrorMessage, hasError } from '../base/ErrorFieldMessage';

function OverviewSection(props) {
  const language = 'en';
  const { register, formState: { errors } } = useFormContext();
  return (
    <>
      <label
        className={
            'create-section-label'
        }
      >
        {i18n.t('name', { locale: language })}
      </label>
      <input
        type="text"
        {...register("name", { required: `${EarningRuleErrorHandleField.generalName.message}` })}
        className={`custom-markdown-area-title ${
          hasError(errors, 'name') ? 'error-field' : ''
        } ${isLongScreen ? '' : 'custom-markdown-area-title-short'}`}
      />
      <CustomErrorMessage name="name"/>
    </>
  );
}

export default OverviewSection;

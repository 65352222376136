import { ImageDomain } from 'config/CustomEnums';

export const PresetTemplate = `
<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
    <body>
      <br/>
      <table cellpadding="0" cellspacing="0" style="margin: 0px auto;max-width: 621px;border: 1px solid #DDDDDD;box-sizing: border-box;border-radius: 12px;border-collapse: separate;border-spacing: 0;overflow: hidden;">
        <tbody>
        <tr class="bg-white" style="vertical-align: baseline;">
          <td colspan="3"></td>
        </tr>
        <tr style="height: 24px;">
          <td style="width: 32px;"></td>
          <td colspan="1"></td>
          <td style="width: 32px;"></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <p><img src="${ImageDomain}message/motherapp_loyalty_logo.svg" alt="" width="110" height="56"></p>
            <p>&nbsp;</p>
            <p><img src="${ImageDomain}message/banner_image.png" alt="" width="556" height="372"></p>
            <p>&nbsp;</p>
            <p><span style="font-size: 20pt;">Christmas offer: 10% off for any purchase over $100 at any physical store</span></p>
            <p>&nbsp;</p>
            <p><div style="width: 100%;border: none;height: 1px;color: #DDDDDD;background-color: #DDDDDD"></div></p>
            <p>&nbsp;</p>
            <p style="font-size: 16pt;">Dear {{preferredName}},<br>This the season to save! We\'re excited to present our exclusive Christmas offer: a delightful 10% discount on any purchase over $100 at our physical stores. It\'s our way of spreading holiday cheer and expressing our gratitude to valued customers like you.<br>From the latest fashion trends to cutting-edge gadgets, our wide range of high-quality products awaits you. Treat yourself or find the perfect gifts for your loved ones, all while enjoying significant savings on your total purchase.</p>
            <p style="font-size: 16pt;">Hurry in to any of our physical stores before [end date of promotion] to take advantage of this limited-time offer. Our friendly staff will be there to assist you in finding the ideal items that suit your needs and preferences.<br>Make your holiday shopping merrier than ever with our exclusive 10% off discount! Don\'t miss out on this extraordinary opportunity to save while spreading joy this Christmas season.</p>
            <p style="font-size: 16pt;">Warmest regards,<br>[Your Name]<br>[Your Position]<br>[Your Company Name]</p>
          </td>
          <td></td>
        </tr>
        <tr style="height: 24px;"><td colspan="3"></td></tr>
        </tbody>
      </table>
      <br/>
    </body>
</html>`;

export const CampaignLinkA =
  '<p><a title="{{linkTitle}}" href="{{linkUrl}}">{{linkContent}}</a></p>';

export const InsertParameterOptions = [
  { name: 'First name - {{firstName}}', value: '{{firstName}}' },
  { name: 'Last name - {{lastName}}', value: '{{lastName}}' },
  { name: 'Current level - {{currentLevel}}', value: '{{currentLevel}}' },
  { name: 'Next level - {{nextLevel}}', value: '{{nextLevel}}' },
  { name: 'Current point - {{currentPoint}}', value: '{{currentPoint}}' },
  { name: 'Name - {{preferredName}}', value: '{{preferredName}}' },
  {
    name: 'Point to next level - {{pointToNextLevel}}',
    value: '{{pointToNextLevel}}',
  },
  {
    name: 'Web URL of homepage - {{webUrlOfHomepage}}',
    value: '{{webUrlOfHomepage}}'
  },
  {
    name: 'Web URL of sign up page (pre-fill contact) - {{webUrlOfSignUpPage}}',
    value: '{{webUrlOfSignUpPage}}'
  },
];

export const WhatsappInsertParameterOptions = [
  { name: 'First name - {{firstName}}', value: '{{firstName}}' },
  { name: 'Last name - {{lastName}}', value: '{{lastName}}' },
  { name: 'Current level - {{currentLevel}}', value: '{{currentLevel}}' },
  { name: 'Next level - {{nextLevel}}', value: '{{nextLevel}}' },
  { name: 'Current point - {{currentPoint}}', value: '{{currentPoint}}' },
  { name: 'Name - {{preferredName}}', value: '{{preferredName}}' },
  {
    name: 'Point to next level - {{pointToNextLevel}}',
    value: '{{pointToNextLevel}}',
  },
  {
    name: 'Campaign URL for web - {{CampaignUrlForWeb}}',
    value: '{{CampaignUrlForWeb}}',
  },
  {
    name: 'Campaign deep link for app - {{CampaignDeepLinkForApp}}',
    value: '{{CampaignDeepLinkForApp}}',
  },
  {
    name: 'Campaign active period start time - {{CampaignActiveStart}}',
    value: '{{CampaignActiveStart}}',
  },
  {
    name: 'Campaign active period end time - {{CampaignActiveEnd}}',
    value: '{{CampaignActiveEnd}}',
  },
];

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CampaignType, EarningRuleType } from '../../../config/CustomEnums';
import { Button } from 'react-bootstrap';
import './ApprovalCheckList.scss';

function ApprovalCheckList() {
  const { watch, setValue, formState, getValues } = useFormContext();
  const errors = formState.errors['checkList'];
  const campaignType = getValues('campaignType')
  const linkedCoupon = getValues('linkedCoupon')  
  const linkedEarningRules = getValues('linkedEarningRules')
  const stampRewards = getValues('stampRewards')
  
  useEffect(() => {
    if (errors) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [errors]);

  const linkedEarningRulesReward = (earningRule) => {
    switch (earningRule?.type) {
      case EarningRuleType.birthday:
        return earningRule.birthdayTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.fillingForm:
        return earningRule.fillingFormTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.generalPurchase:
        return earningRule.generalPurchaseTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.gpsCheckIn:
        return earningRule.gpsCheckInTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.memberReferral:
        return earningRule.memberReferralTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.newMember:
        return earningRule.newMemberTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.qrCodeScanning:
        return earningRule.qrCodeScanningTypeCouponRewardTypeCouponTemplate
      default:
        return earningRule?.couponTemplate || null
    } 
  }

  console.log("@23", campaignType)

  const checkList = watch("checkList") || {}
  console.log("@50", errors)
  const addNewCheckList = (item) => {
    if (!item || checkList?.[item?.id] !== undefined) {
      return
    }
    setValue("checkList", {...checkList, [item.id]: false})
  }
  const item = (item, pathRoot) => {
    addNewCheckList(item)
    return (
    <div className={`check-list-item-container`}>
      <label className={`checkbox-container check-list-label`}>
        {`[ID: ${item?.pk}] ${item?.name}`}
        <input  type="checkbox" checked={checkList?.[item?.id]} onChange={() => {
          console.log("@67", checkList)
          const newCheckList = {
            ...checkList,
            [item.id]: !checkList?.[item?.id]
          }
          setValue("checkList", newCheckList, {shouldDirty: true})
        }} />
        <span className={errors && !checkList?.[item?.id] ? 'checkmark error-field' : 'checkmark'}></span>
      </label>
      <Button
        className={'btn-back-button-common btn-download btn btn-primary'}
        onClick={() => {
          window.open(`${pathRoot}/${item?.pk}/edit`, "_blank")
        }}
      >
        {'Go to review'}
      </Button>
      {errors && !checkList?.[item?.id] ? 
        <label className='error-field-message-style'>{errors.message}</label>
        : null
      }
    </div>)
  }

  const stampCampaignRelatedCoupon = () => {
    let stampRewardsCouponList = []
    stampRewards.forEach((value) => {
      value?.linkedCoupon && stampRewardsCouponList.push(value?.linkedCoupon)
    })
    if (stampRewardsCouponList.length > 0) {
      return (
        <>
          <label className="create-section-label check-list-section">{'Related coupon set'}</label>
          {stampRewardsCouponList.map((coupon) => item(coupon, '/coupons'))}
        </>
      )
    }
    return null
  }

  return (
    <>
      <label className="create-section-title">{'Checklist'}</label>
      { campaignType === CampaignType.couponsCampaign ? <>
        <label className="create-section-label check-list-section">{'Related coupon set'}</label>
        {item(linkedCoupon, '/coupons')}
      </> : null}

      { campaignType === CampaignType.earningRulesCampaign ?
       <>
        {linkedEarningRulesReward(linkedEarningRules) ? <>
          <label className="create-section-label check-list-section">{'Related coupon set'}</label>
          {item(linkedEarningRulesReward(linkedEarningRules), '/coupons')}
        </> : null}
        <label className="create-section-label check-list-section">{'Related earning rule'}</label>
        {item(linkedEarningRules, '/earns')}
      </>
      : null}

      { campaignType === CampaignType.stampCampaign ? <>
        {stampCampaignRelatedCoupon()}
        <label className="create-section-label check-list-section">{'Related earning rule'}</label>
        {Array.isArray(linkedEarningRules) ?
          linkedEarningRules.map((value)=>item(value, '/earns'))
          :
          item(linkedEarningRules, '/earns')
        }
      </> : null}

    </>
  );
}

export default ApprovalCheckList;

import { BaseHelper } from './BaseGraphQLAPIHelper';

const MESSAGE_CREDIT_RECORDS_NODE = `{
  id
  pk
  message {
    pk
    name
    admin {
      id
      pk
      username
    }
    creationDate
    realCreditSpent
  }
  creationDate
}`;

export const getMessageCreditRecords = (afterCursor, search) => {
  let searchString = `, orderBy: "${search.reverse ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search.sort}"`;
  }
  if (search.search) {
    searchString += `, nameIcontains: "${search.search}"`;
  }
  if (search?.create_at) {
    const createDate = search.create_at.split(',');
    searchString += `, creationDateGte: "${createDate[0]}", creationDateLte: "${createDate[1]}"`;
  }
  const creditConsumedRange = search?.credit_consumed?.split(',');

  if (creditConsumedRange?.[0]) {
    searchString += `, creditConsumedGte: ${creditConsumedRange[0]}`;
  }
  if (creditConsumedRange?.[1]) {
    searchString += `,creditConsumedLte: ${creditConsumedRange[1]}`;
  }

  const query = `{
    messageCreditRecords(first: 20, after:"${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node ${MESSAGE_CREDIT_RECORDS_NODE}
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const getMessageCreditRecord = (id) => {
  const query = `{
    messageCreditRecord(id: "${id}") ${MESSAGE_CREDIT_RECORDS_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import BasePrompt from '../base/prompt/BasePrompt';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import {
  CampaignType,
  EarningRuleType,
  EarningRuleRewardType,
} from '../../config/CustomEnums';
import { getCampaignTypeSelectPromptConfig } from '../campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { useHistory } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import InputFieldControl from '../base/InputFieldControl';
import { useFormContext } from 'react-hook-form';

function RewardSection({ earningRule }) {
  const { getValues, watch, formState, setValue } = useFormContext();
  const errors = formState.errors;
  const [earningRuleType] = getValues(['type']);

  const watchLinkedStampCampaign = watch('linkedStampCampaign');
  const watchRewardType = watch('rewardType');
  const watchQuantity = watch('quantity');
  const watchX = watch('rewardTypeX');
  const watchTPEQuantity = watch('tpeQuantity');
  const watchTPEPoints = watch('rewardTypeTpeX');
  const watchCoupon = watch('coupons');

  useEffect(() => {
    if (!watchRewardType
      || (watchLinkedStampCampaign && watchRewardType !== EarningRuleRewardType.stamps)
      || (!watchLinkedStampCampaign && watchRewardType === EarningRuleRewardType.stamps)
    ) {
      setValue(
        'rewardType',
        watchLinkedStampCampaign ? EarningRuleRewardType.stamps : EarningRuleRewardType.coupons,
        { shouldDirty: true, }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  const radioOptions = [
    { label: 'Coupons', value: EarningRuleRewardType.coupons },
    { label: 'Points', value: EarningRuleRewardType.points },
  ];
  const promptconfig = getCampaignTypeSelectPromptConfig(
    CampaignType.couponsCampaign,
  );

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const history = useHistory();

  const rewardQuality = () => {
    if (watchLinkedStampCampaign) {
      return 'stamp per action';
    }
    if (watchRewardType === EarningRuleRewardType.points) {
      return 'number of points';
    }
    return 'coupons per number';
  };

  return (
    <>
      <label className="create-section-title">REWARD</label>
      {watchLinkedStampCampaign && earningRuleType !== EarningRuleType.fillingForm ? (
        <>
          {earningRuleType === EarningRuleType.generalPurchase ? (
            <>
              <CustomTitleLabel title="Reward stamps" />
              <label className="tips-message">
                Eg. Customer earns 3 stamps for every 5 dollars spent. HK$ 5 = 3
                stamps
              </label>
              <CustomNumberMutipleInputWithUnit
                numberTitle={'HK$'}
                firstDefaultValue={watchX}
                setFirstValue={(value) => {
                  setValue('rewardTypeX', value, { shouldDirty: true });
                }}
                secondDefaultValue={watchQuantity}
                setSecondValue={(value) => {
                  setValue('quantity', value, { shouldDirty: true });
                }}
                per="="
                unit={"stamps"}
              />
            </>
          ) : (
            <InputFieldControl
              name={'quantity'}
              title={'Stamp quantity'}
              value={watchQuantity}
              setValue={(value) => {
                setValue('quantity', value, { shouldDirty: true });
              }}
              type="number"
              unit={rewardQuality()}
              className="coupon-campaign-section-input"
            />
          )}
        </>
      ) : (
        <>
          <CustomTitleLabel title="Reward type" />
          <div style={{ display: 'flex' }}>
            <CustomRadios
              onChange={(value) => {
                // setRewardType(value);
                setValue('rewardType', value, { shouldDirty: true });
              }}
              default={watchRewardType}
              options={radioOptions}
            />
          </div>

          {watchRewardType === EarningRuleRewardType.coupons ? (
            <>
              <CouponSelectDropdown
                title={'Coupon set'}
                defaultValue={{
                  value: watchCoupon,
                  label: watchCoupon?.pk
                    ? `[ID: ${watchCoupon?.pk}] ${watchCoupon?.name}`
                    : watchCoupon?.name,
                }}
                setValue={(item) => {
                  console.log('couponChange:', item);
                  const coupon = item || {};
                  setValue('coupons', coupon, { shouldDirty: true });
                }}
                // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
                isPrompt
                addButtonInfo={{
                  requires: PermissionCodes.addCouponTemplate,
                  children: <Image src={addImage} />,
                  action: () => setShowCreateNewLinkPrompt(true),
                  customClass: 'earning-rule-add-new-link-button',
                }}
              />
              <ReactHookFormErrorMessage errors={errors} id="coupons" />
            </>
          ) : null}
          {earningRuleType === EarningRuleType.generalPurchase &&
          watchRewardType === EarningRuleRewardType.points ? (
            <>
              <CustomTitleLabel title="Reward points" />
              <label className="tips-message">
                Eg. Customer earns 3 points for every 5 dollars spent. HK$ 5 = 3
                points
              </label>
              <CustomNumberMutipleInputWithUnit
                numberTitle={'HK$'}
                firstDefaultValue={watchX}
                setFirstValue={(value) => {
                  setValue('rewardTypeX', value, { shouldDirty: true });
                }}
                secondDefaultValue={watchQuantity}
                setSecondValue={(value) => {
                  setValue('quantity', value, { shouldDirty: true });
                }}
                per="="
                unit="points"
              />
              <CustomNumberMutipleInputWithUnit
                numberTitle={'HK$'}
                firstDefaultValue={watchTPEPoints}
                setFirstValue={(value) => {
                  setValue('rewardTypeTpeX', value, { shouldDirty: true });
                }}
                secondDefaultValue={watchTPEQuantity}
                setSecondValue={(value) => {
                  setValue('tpeQuantity', value, { shouldDirty: true });
                }}
                per="="
                unit="progression points"
              />
              {/* <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.quantity.name}`}
            error={isError(['rewardTypeX', 'quantity'])}
            message={`${EarningRuleErrorHandleField.quantity.message}`}
          /> */}
              <ReactHookFormErrorMessage errors={errors} id="pointReward" />
              <ReactHookFormErrorMessage errors={errors} id="point" />
              <ReactHookFormErrorMessage errors={errors} id="tpe" />
            </>
          ) : (
            <>
              <InputFieldControl
                name={'quantity'}
                title={'Quantity'}
                value={watchQuantity}
                setValue={(value) => {
                  setValue('quantity', value, { shouldDirty: true });
                }}
                type="number"
                unit={rewardQuality()}
                className="coupon-campaign-section-input"
              />
              {watchRewardType === EarningRuleRewardType.points && (
                <InputFieldControl
                  name={'tpeQuantity'}
                  title={'Progression Points'}
                  value={watchTPEQuantity}
                  setValue={(value) => {
                    setValue('tpeQuantity', value, { shouldDirty: true });
                  }}
                  type="number"
                  unit={'number of progression points'}
                  className="coupon-campaign-section-input"
                />
              )}
              <ReactHookFormErrorMessage errors={errors} id="pointReward" />
            </>
          )}
        </>
      )}
      <BasePrompt
        show={showCreateNewLinkPrompt}
        closeAction={() => setShowCreateNewLinkPrompt(false)}
        rightButton={{
          text: promptconfig.button,
          action: () => {
            history.push({
              pathname: '/coupons/create',
              state: {
                from: earningRule?.id
                  ? `/earns/${earningRule?.id}/edit/`
                  : '/earns/create',
                title: 'Continue to Create Earning Rule',
                content: 'You can continue to create the earning rule.',
              },
            });
          },
        }}
        title={promptconfig.title}
        description={
          'You will leave the campaign creation process. After you create a coupon set, you can come back to create a earning rule.'
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule,
});

export default connect(mapPropsToState)(RewardSection);

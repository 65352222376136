import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import ErrorFieldMessage, { hasErrorFromList } from 'components/base/ErrorFieldMessage';
import { CustomTitleWithDropDownAndInput } from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import CustomEditor from 'components/base/CustomEditor';
import { EditCustomerHandleError } from 'components/customer/EditCustomerHandleError';


const UpdatePointsModal = ({
  user,
  customer,
  errors,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [pointTransaction, setPointTransaction] = useState(null);
  const [transactionPoint, setTransactionPoint] = useState(null);
  const [tpeTransaction, setTpeTransaction] = useState(null);
  const [transactionTpe, setTransactionTpe] = useState(null);
  const [pointTransactionRemark, setPointTransactionRemark] = useState(null);


  const getPointTransactionSource = () => {
    let source = [];
    const permissions = user.userPermissions;
    if (permissions.includes(PermissionCodes.addCustomerPoints)) {
      source.push({ name: 'Add transaction', pk: 'ADD_POINTS' });
    }
    if (permissions.includes(PermissionCodes.removeCustomerPoints)) {
      source.push({ name: 'Remove transaction', pk: 'REMOVE_POINTS' });
    }
    return source;
  };


  const onClose = () => {
    setShow(false);
  }


  const saveAction = () => {
    dispatch({
      type: 'customerList/checkCustomerProfileValid',
      payload: {
        data: {
          ...customer,
          pointTransaction,
          transactionPoint,
          tpeTransaction,
          transactionTpe,
          pointTransactionRemark,
        },
        customer: customer,
        refreshCustomer: true,
      },
    });
  };

  useEffect(() => {
    if (show) {
      dispatch({
        type: 'customerList/updateState',
        payload: {
          errorsList: [],
        }
      })
      setPointTransaction(null);
      setTransactionPoint(null);
      setTpeTransaction(null);
      setTransactionTpe(null);
      setPointTransactionRemark(null);
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>Update points & progression points</label>

        <CustomTitleWithDropDownAndInput
          title="Update points"
          source={getPointTransactionSource()}
          defaultValue={''}
          needFilter={false}
          unit="Points"
          customClass="edit-custom-transaction-selector customer-detail-prompt-selector"
          setValue={(value) => {
            setPointTransaction(value);
          }}
          setInputValue={(value) => {
            setTransactionPoint(value);
          }}
        />
        <ErrorFieldMessage
          id={EditCustomerHandleError.pointTransaction.name}
          error={hasErrorFromList(errors, EditCustomerHandleError.pointTransaction.name)}
          message={EditCustomerHandleError.pointTransaction.message}
        />
        <ErrorFieldMessage
          id={EditCustomerHandleError.transactionPoint.name}
          error={hasErrorFromList(errors, EditCustomerHandleError.transactionPoint.name)}
          message={EditCustomerHandleError.transactionPoint.message}
        />

        <CustomTitleWithDropDownAndInput
          title="Update progression points"
          source={getPointTransactionSource()}
          defaultValue={''}
          needFilter={false}
          unit="Progression Points"
          customClass="edit-custom-transaction-selector customer-detail-prompt-selector"
          setValue={(value) => {
            setTpeTransaction(value)
          }}
          setInputValue={(value) => {
            setTransactionTpe(value)
          }}
        />
        <ErrorFieldMessage
          id={EditCustomerHandleError.tpeTransaction.name}
          error={hasErrorFromList(errors, EditCustomerHandleError.tpeTransaction.name)}
          message={EditCustomerHandleError.tpeTransaction.message}
        />
        <ErrorFieldMessage
          id={EditCustomerHandleError.transactionTpe.name}
          error={hasErrorFromList(errors, EditCustomerHandleError.transactionTpe.name)}
          message={EditCustomerHandleError.transactionTpe.message}
        />

        <CustomTitleLabel title="Points transaction remark(Optional)" />
        <CustomEditor
          initialValue={''}
          onValueChange={(value) => {
            setPointTransactionRemark(value);
          }}
          onFocus={() => { }}
          errorMessage={''}
          error={''}
        />

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm'
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  user: state.users,
  customer: state.customerList.customer,
  errors: state.customerList.errorsList,
});

export default connect(mapPropsToState)(UpdatePointsModal);

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import './TransactionAmountSection.scss';

import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import DiscountSection from './DiscountSection';
import { Button } from 'react-bootstrap';
import { DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE } from 'config/CustomEnums';


function TransactionAmountSection() {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const paidAmountField = 'paidAmount';
  const totalValueField = 'totalValue';
  const totalValueFormatField = 'totalValueFormat';
  const cashDollarField = 'pointToCash';
  const shippingFeeField = 'shippingFee';
  const otherChargeField = 'otherCharge';
  const discountsField = 'discounts';
  const discountAmountField = 'discountAmount';

  const paidAmount = watch(paidAmountField);
  const totalValue = watch(totalValueField);
  const cashDollar = watch(cashDollarField);
  const shippingFee = watch(shippingFeeField);
  const otherCharge = watch(otherChargeField);
  const discounts = watch(discountsField);
  const discountAmount = watch(discountAmountField);

  useEffect(() => {
    if (!discounts || discounts?.length < 1) {
      setValue(
        discountsField,
        [{
          type: DISCOUNT_TYPE.TRANSACTION,
          valueType: DISCOUNT_VALUE_TYPE.PERCENTAGE,
          value: null,
          code: null,
        }],
        { shouldDirty: true, });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="first-section-title">AMOUNT</div>

      <CustomTitleLabel title="Cash dollar (optional)" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="text"
          className={`day-input-fields ${hasError(errors, cashDollarField)
            ? 'error-field'
            : ''
            }`}
          value={cashDollar}
          onInput={(e) => {
            const value = e.target.value;
            const data = value.replace(/[^0-9.]/g, '');
            setValue(cashDollarField, data, { shouldDirty: true });
          }}
        />
      </div>
      <ReactHookFormErrorMessage errors={errors} id={cashDollarField} />

      <CustomTitleLabel title="Shipping fee (optional)" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="text"
          className={`day-input-fields ${hasError(errors, shippingFeeField)
            ? 'error-field'
            : ''
            }`}
          value={shippingFee}
          onInput={(e) => {
            const value = e.target.value;
            const data = value.replace(/[^0-9.]/g, '');
            setValue(shippingFeeField, data, { shouldDirty: true });
          }}
        />
      </div>

      <CustomTitleLabel title="Other charge (optional)" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="text"
          className={`day-input-fields ${hasError(errors, otherChargeField)
            ? 'error-field'
            : ''
            }`}
          value={otherCharge}
          onInput={(e) => {
            const value = e.target.value;
            const data = value.replace(/[^0-9.]/g, '');
            setValue(otherChargeField, data, { shouldDirty: true });
          }}
        />
      </div>
      <CustomTitleLabel title="Total discount (optional)" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="text"
          className={`day-input-fields ${hasError(errors, discountAmountField)
            ? 'error-field'
            : ''
            }`}
          value={discountAmount}
          onInput={(e) => {
            const value = e.target.value;
            const data = value.replace(/[^0-9.]/g, '');
            setValue(discountAmountField, data, { shouldDirty: true });
          }}
        />
      </div>

      <CustomTitleLabel title="Eligible Total" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="number"
          className={(
              hasError(errors, totalValueField) ||
              hasError(errors, totalValueFormatField)
            )
            ? 'day-input-fields error-field'
            : 'day-input-fields'
          }
          value={totalValue}
          onInput={(e) => {
            // const value = e.target.value;
            // const data = value.replace(/[^0-9.]/g, '');
            setValue(totalValueField, e.target.value, { shouldDirty: true });
          }}
        />
      </div>
      <ReactHookFormErrorMessage errors={errors} id={totalValueField} />
      <ReactHookFormErrorMessage errors={errors} id={totalValueFormatField} />

      <CustomTitleLabel title="Paid amount (optional)" />
      <div className="d-flex">
        <label className="transaction-amount-total-value-label">HK$</label>
        <input
          type="number"
          className={hasError(errors, paidAmountField)
            ? 'day-input-fields error-field'
            : 'day-input-fields'
          }
          value={paidAmount}
          onInput={(e) => {
            setValue(paidAmountField, e.target.value, { shouldDirty: true });
          }}
        />
      </div>
      <ReactHookFormErrorMessage errors={errors} id={paidAmountField} />

      <div className='multiple-discounts'>
        {discounts?.map((value, index) => <DiscountSection index={index} />)}
        <div>
          <Button
            onClick={() => {
              const tempArray = [].concat(discounts);
              const value = {
                type: DISCOUNT_TYPE.TRANSACTION,
                valueType: DISCOUNT_VALUE_TYPE.PERCENTAGE,
                value: null,
                code: null,
              };
              tempArray.push(value);
              setValue(discountsField, tempArray, { shouldDirty: true, });
            }}
            className="btn-add-button-common"
          >
            Add discount
          </Button>
        </div>
      </div>
    </>
  );
}

export default TransactionAmountSection;

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Cropper from 'react-cropper';
import Dropzone from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';

import 'cropperjs/dist/cropper.css';
import { v4 as uuidv4 } from 'uuid';

import { ReactHookFormErrorMessage } from 'components/base/ErrorFieldMessage';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { LanguageConfig } from 'config/CustomEnums';
import { ERROR_TYPE, IMAGE_TYPES } from 'models/UploadFilesModel';

import { createAction, isJsonFile, getNumberWithOrdinal } from 'utils';

import LoadingIcon from 'assets/images/loading';
import XButton from 'assets/images/x-button.svg';

import './ImageUploader.scss';

function getBase64ImgSize(base64Image) {
  return atob(base64Image?.split(',')[1])?.length ||
    Math.round(base64Image.length * 3 / 4) || 0
}

function compressImage(image, targetSize, imageType) {
  let quality = 0.9;
  let currentBase64Image;
  let currentSize;
  do {
    currentBase64Image = image.toDataURL(imageType, quality)
    currentSize = getBase64ImgSize(currentBase64Image) / 1024 / 1024
    quality -= 0.1
  } while (quality > 0.1 && currentSize > targetSize)
  return currentBase64Image
}

@connect(({ uploadFiles }) => ({
  ...uploadFiles,
}))
class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null,
      modalShow: false,
      imageType: null,
      imageWidth: null,
      imageHeight: null,
      errorMessage: props.errorMessage,
      cropDetail: {},
      showDoubleConfirm: false,
      invalidTitleText: null,
      invalidConfirmText: null,
      invalidAgainText: null,
    };
    this.overSizeImagesIndex= [];
    this.lowResolutionImagesIndex= [];
    this.uploadingImagesMap = {};
    this.cropper = React.createRef();
    this.imageUploadRef = React.createRef();
    this.sizeLimit = this.props.sizeLimit >= 0 ? this.props.sizeLimit : 2;
    // format init images
    if (props.images && props.images.length > 0) {
      if (typeof props.images[0] === 'string') {
        props.onImageStateChange(this.getDefaultImages());
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.uploadedImages !== prevProps.uploadedImages) {
      const uploadedImageUuids = [];
      this.getDefaultImages().forEach((item, index) => {
        if (
          item.type === IMAGE_TYPES.TYPE_UPLOADING &&
          this.props.failedImage[item.value]
        ) {
          this.deleteImageBtnClicked(index);
        } else if (
          item.type === IMAGE_TYPES.TYPE_UPLOADING &&
          item.value in this.props.uploadedImages
        ) {
          uploadedImageUuids.push(item.value);
        }
      });
      if (uploadedImageUuids.length > 0 && this.props.onImageStateChange) {
        const newImageState = [];
        this.getDefaultImages().forEach((item, index) => {
          if (uploadedImageUuids.includes(item.value)) {
            const imageData = {
              type: IMAGE_TYPES.TYPE_URL,
              value: this.props.uploadedImages[item.value],
              oldValue: item.value,
              name: item.name,
            };
            newImageState.push(imageData);
          } else {
            newImageState.push(item);
          }
        });
        console.log(newImageState);
        this.props.onImageStateChange(newImageState);
      }
    }
  }

  getDefaultImages() {
    const images = this.props.images || [];
    // console.log("this.getDefaultImages():", images)
    const newImages = images.map((item) => {
      return {
        oldValue: item?.oldValue || '',
        type: item?.type || IMAGE_TYPES.TYPE_URL,
        value: item?.value || item,
        linkUrl: item?.linkUrl || '',
        name: item?.name || '',
      };
    });
    return newImages;
  }

  turnLeftButtonClicked = () => {
    this.cropper.current.cropper.rotate(-90);
  };

  turnRightButtonClicked = () => {
    this.cropper.current.cropper.rotate(90);
  };

  showResult = async () => {
    const newImageIndex = this.getDefaultImages().length + 1
    // confirm upload image and preview
    this.setState({
      modalShow: false,
      errorMessage: '',
    });
    if (
      typeof this.cropper.current.cropper.getCroppedCanvas() === 'undefined'
    ) {
      return;
    }

    if (!this.props.skipSize) {
      const croppedCanvas = this.cropper.current.cropper.getCroppedCanvas();
      const minWidth = this.props.minWidth || 600;
      const minHeight = this.props.minHeight || 600;

      const maxWidth = this.props.maxWidth;
      const maxHeight = this.props.maxHeight;

      if (croppedCanvas.height < minHeight || croppedCanvas.width < minWidth) {
        this.lowResolutionImagesIndex.push(newImageIndex)
      }

      if (
        maxWidth &&
        maxHeight &&
        (croppedCanvas.height > maxHeight || croppedCanvas.width > maxWidth)
      ) {
        this.overSizeImagesIndex.push(newImageIndex)
      }
    }

    const cropper = this.cropper.current.cropper;
    this.croppedImage = this.state.imageSrc;

    if (
      cropper.getCroppedCanvas().width !== this.state.imageWidth ||
      cropper.getCroppedCanvas().height !== this.state.imageHeight
    ) {
      // console.log('kevin@162', this.state.imageType);
      this.croppedImage = cropper
        .getCroppedCanvas()
        .toDataURL(this.state.imageType, 0.96);
    } else {
      // console.log('kevin@167', this.state.imageType);
      // console.log('kevin@167', this.originImageDataUrl);
      this.croppedImage = this.originImageDataUrl;
    }

    const croppedImageSize = getBase64ImgSize(this.croppedImage)
    const isCroppedImageOverSize = croppedImageSize / 1024 / 1024 > this.sizeLimit
    if (isCroppedImageOverSize) {
      if (this.overSizeImagesIndex?.indexOf(newImageIndex) === -1) {
        this.overSizeImagesIndex.push(newImageIndex)
      }
      this.croppedImage =compressImage(cropper.getCroppedCanvas(), this.sizeLimit, this.state.imageType )
    }

    if (!!this.props?.extraCheckForDoubleConfirm) {
      const checkFunc = this.props.extraCheckForDoubleConfirm.rules;
      const invalidTitleText = this.props.extraCheckForDoubleConfirm.invalidTitleText;
      const invalidConfirmText = this.props.extraCheckForDoubleConfirm.invalidConfirmText;
      const invalidAgainText = this.props.extraCheckForDoubleConfirm.invalidAgainText;
      const croppedCanvas = this.cropper.current.cropper.getCroppedCanvas();
      const checkValid = checkFunc(croppedCanvas.width, croppedCanvas.height);
      if (!checkValid) {
        this.setState({
          invalidTitleText,
          invalidConfirmText,
          invalidAgainText,
          showDoubleConfirm: true,
        });
        return;
      }
    }
    this.uploadImageToServer();
  };

  uploadImageToServer = () => {
    const uuid = uuidv4();
    this.props.dispatch(
      createAction('uploadFiles/uploadCroppedImage')({
        croppedImage: {
          imageDataUrl: this.croppedImage,
          imageType: this.state.imageType,
          imageName: uuid,
        },
      }),
    );
    this.uploadingImagesMap[uuid] = this.croppedImage;
    if (this.props.onImageStateChange) {
      this.props.onImageStateChange([
        ...(this.getDefaultImages() || []),
        { type: IMAGE_TYPES.TYPE_UPLOADING, value: uuid },
      ]);
    }
    this.setState({
      imageSrc: null,
    });
  };

  onDropFileChange = async (files) => {
    this.props.uploadImageClicked && this.props.uploadImageClicked();
    if (this.getDefaultImages().length === this.props.maxImageNum) {
      return;
    }
    if (files.length > 0) {
      const file = files[0];
      if (
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/gif' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'application/json'
      ) {
        this.setState({
          errorMessage: ERROR_TYPE.wrongPhotoType,
        });
        return;
      }
      let imageDataUrl = await readFile(file);
      this.originImageDataUrl = imageDataUrl;
      // console.log("imageDataUrl", imageDataUrl, "imageDataUrl size", imageDataUrl.length)
      let img = new Image();
      img.src = imageDataUrl;
      img.onload = function () {
        let widthRate = img.width / 2700;
        let heightRate = img.height / 1800;
        if (widthRate > 1) {
          img.width = 2700;
          img.height = img.height / widthRate;
        } else if (heightRate > 1) {
          img.width = img.width / heightRate;
          img.height = 1800;
        }

        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var resizedImageDataUrl = canvas.toDataURL();
        // console.log("aaa", resizedImageDataUrl, 'a.length', resizedImageDataUrl.length)

        this.setState({
          imageSrc: resizedImageDataUrl,
          imageHeight: img.height,
          imageWidth: img.width,
        });
      }.bind(this);

      this.setState({
        modalShow: true,
        imageType: file.type,
      });
      if (file.type === 'application/json') {
        const uuid = uuidv4();
        this.props.dispatch(
          createAction('uploadFiles/uploadFile')({
            file: file,
            id: uuid,
            jsonAnimation: true,
          }),
        );
        this.uploadingImagesMap[uuid] = imageDataUrl;
        if (this.props.onImageStateChange) {
          this.props.onImageStateChange([
            ...(this.getDefaultImages() || []),
            { type: IMAGE_TYPES.TYPE_UPLOADING, value: uuid, name: file.name },
          ]);
        }
        this.setState({
          imageSrc: null,
          modalShow: false,
          errorMessage: '',
        });
      }
      if (file.type === 'image/gif') {
        const uuid = uuidv4();
        this.props.dispatch(
          createAction('uploadFiles/uploadCroppedImage')({
            croppedImage: {
              imageDataUrl: imageDataUrl,
              imageType: file.type,
              imageName: uuid,
            },
          }),
        );
        this.uploadingImagesMap[uuid] = imageDataUrl;
        if (this.props.onImageStateChange) {
          this.props.onImageStateChange([
            ...(this.getDefaultImages() || []),
            { type: IMAGE_TYPES.TYPE_UPLOADING, value: uuid },
          ]);
        }
        this.setState({
          imageSrc: null,
          modalShow: false,
          errorMessage: '',
        });
      }
    }
  };

  deleteImageBtnClicked = (index) => {
    if (index > this.getDefaultImages().length) {
      return;
    }
    const image = this.getDefaultImages()[index];

    if (this.overSizeImagesIndex?.length > 0) {
      const newOverSizeImages = [];
      // eslint-disable-next-line no-unused-expressions
      this.overSizeImagesIndex?.forEach((oldIndex) => {
        if (oldIndex > index + 1) {
          newOverSizeImages.push(oldIndex - 1)
        }
        if (oldIndex < index + 1) {
          newOverSizeImages.push(oldIndex)
        }
      })
      this.overSizeImagesIndex = newOverSizeImages
    }

    if (this.lowResolutionImagesIndex?.length > 0) {
      const newLowResolutionImages = [];
      // eslint-disable-next-line no-unused-expressions
      this.lowResolutionImagesIndex?.forEach((oldIndex) => {
        if (oldIndex > index + 1) {
          newLowResolutionImages.push(oldIndex - 1)
        }
        if (oldIndex < index + 1) {
          newLowResolutionImages.push(oldIndex)
        }
      })
      this.lowResolutionImagesIndex = newLowResolutionImages
    }
    if (this.props.failedImage[image.value]) {
      this.setState({
        errorMessage: ERROR_TYPE.photoUploadFailed,
      });
    }
    if (image.value in this.uploadingImagesMap) {
      delete this.uploadingImagesMap[image.value];
    }
    if (this.props.onImageStateChange) {
      const newImages = [];
      this.getDefaultImages().forEach((oldImage, oldIndex) => {
        if (index !== oldIndex) {
          newImages.push(oldImage);
        }
      });
      this.props.onImageStateChange(newImages);
    }
  };

  uploadImage = {
    [LanguageConfig.english]: 'Upload Image',
    [LanguageConfig.traditionalChinese]: '上傳圖片',
    [LanguageConfig.simplifiedChinese]: '上传图片',
  };

  uploadJsonAnimation = {
    [LanguageConfig.english]: 'Upload Image/Json',
    [LanguageConfig.traditionalChinese]: '上傳圖片/JSON',
    [LanguageConfig.simplifiedChinese]: '上传图片/JSON',
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingIcon,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const isSquare = this.props.aspect === 1 / 1;

    const getTips = () => {
      const  res = [];

      if (this.overSizeImagesIndex?.length === 1 && this.getDefaultImages().length === 1) {
        res.push(
          <div>
            {`The file size of the uploaded image(s) exceeds the suggested ${this.sizeLimit}MB limit. \
            The image(s) will be automatically compressed to a smaller file size and optimized for front-end display. \
            If the resulting image quality is not satisfactory, please compress the file before uploading it again.`}
          </div>
        )
      } else if (this.overSizeImagesIndex?.length > 0) {
        res.push(
          <>
            <div>
              {`${this.overSizeImagesIndex.reduce(
                (previousValue, currentValue) => `${previousValue}${getNumberWithOrdinal(currentValue)}, `, ""
              )?.slice(0, -2)} image:`}
            </div>
            <div>
              {`The file size of the uploaded image(s) exceeds the suggested ${this.sizeLimit}MB limit. \
                The image(s) will be automatically compressed to a smaller file size and optimized for front-end display. \
                If the resulting image quality is not satisfactory, please compress the file before uploading it again.`
              }
            </div>
          </>)
      }


      if (this.lowResolutionImagesIndex?.length === 1 && this.getDefaultImages().length === 1) {
        res.push(
          <div>
            {`The uploaded image(s) has a lower resolution than the suggested one, \
              which may result in a blurry and pixelated display. \
              You may either upload a higher resolution image or continue to proceed.`}
          </div>
        )

      } else if (this.lowResolutionImagesIndex?.length > 0) {
        res.push(
          <>
            <div>
              {`${this.lowResolutionImagesIndex.reduce(
                (previousValue, currentValue) => `${previousValue}${getNumberWithOrdinal(currentValue)}, `, ""
              )?.slice(0, -2)} image:`}
            </div>
            <div>
              {`The uploaded image(s) has a lower resolution than the suggested one, \
              which may result in a blurry and pixelated display. \
              You may either upload a higher resolution image or continue to proceed.`
              }
            </div>
          </>)
      }
      return res.map((item)=> item)
    }
    return (
      <div className="upload-image-container">
        <div className="error-field-message-style">
          {getTips()}
        </div>
        <div className="demo-image-section">
          {this.getDefaultImages()?.map((item, index) => {
            const { type, value, name } = item;
            // console.log("getDefaultImages:", item)
            let src = '';
            if (type === IMAGE_TYPES.TYPE_URL) {
              src = value;
            } else if (type === IMAGE_TYPES.TYPE_UPLOADING) {
              if (value in this.uploadingImagesMap) {
                src = this.uploadingImagesMap[value];
              }
            }
            return (
              <div key={index}>
                <div className="demo-image-div">
                  {isJsonFile(name) || isJsonFile(src) ? (
                    <Lottie
                      options={{
                        loop: false,
                        path: src,
                      }}
                      style={{ marginLeft: 0, width: 120, height: 120 }}
                      className={`demo-image ${
                        isSquare ? 'demo-image-square' : 'demo-image-rectangle'
                      }`}
                      onLoad={() => {
                        const uuid = item.oldValue;
                        if (uuid && uuid in this.uploadingImagesMap) {
                          delete this.uploadingImagesMap[uuid];
                          this.props.dispatch({
                            type: 'uploadFiles/finishUploadImages',
                            payload: {
                              uuid,
                            },
                          });
                        }
                      }}
                    />
                  ) : (
                    <img
                      alt="img"
                      src={src}
                      className={`demo-image ${
                        isSquare ? 'demo-image-square' : 'demo-image-rectangle'
                      }`}
                      onLoad={() => {
                        const uuid = item.oldValue;
                        if (uuid && uuid in this.uploadingImagesMap) {
                          delete this.uploadingImagesMap[uuid];
                          this.props.dispatch({
                            type: 'uploadFiles/finishUploadImages',
                            payload: {
                              uuid,
                            },
                          });
                        }
                      }}
                    />
                  )}
                  <div
                    className="uploading-background"
                    hidden={type === IMAGE_TYPES.TYPE_UPLOADING ? false : true}
                  ></div>
                  {type === IMAGE_TYPES.TYPE_UPLOADING ||
                  type === IMAGE_TYPES.TYPE_UPLOADED ? (
                    <Lottie
                      options={defaultOptions}
                      style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      height={80}
                      width={80}
                    />
                  ) : null}
                  {this.props.disabled ? null : (
                    <img
                      alt="x-button"
                      src={XButton}
                      className="x-button"
                      onClick={() => {
                        if (this.props.disabled) {
                          return;
                        }
                        this.deleteImageBtnClicked(index);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <Dropzone
          accept={
            this.props.allowJson
              ? ['image/jpeg', 'image/png', 'image/gif', 'application/json']
              : ['image/jpeg', 'image/png', 'image/gif']
          }
          onDrop={(acceptedFiles) => this.onDropFileChange(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="drop-upload-image-section">
              <div {...getRootProps()} ref={this.imageUploadRef}>
                <input
                  {...getInputProps({
                    disabled:
                      this.getDefaultImages().length === this.props.maxImageNum
                        ? true
                        : false,
                  })}
                />
                <button
                  type="button"
                  className="custom-upload-btn"
                  disabled={
                    this.getDefaultImages().length === this.props.maxImageNum ||
                    this.props.disabled
                  }
                  onClick={() =>
                    this.props.uploadImageClicked &&
                    this.props.uploadImageClicked()
                  }
                >
                  {this.props.allowJson
                    ? this.uploadJsonAnimation[this.props.language]
                    : this.uploadImage[this.props.language]}
                </button>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="error-field-message-style">
          {this.state.errorMessage || this.props.errorMessage}
        </div>
        {this.getDefaultImages()?.map((item, index) => {
          const { linkUrl } = item;
          return (
            <>
              {this.props.imageLinkUrl?.display ? (
                <div className="d-flex flex-column">
                  {index === 0 ? (
                    <>
                      <label
                        className={`create-section-label create-section-label-bottom-space ${this.props.imageLinkUrl?.className}`}
                      >
                        {this.props.imageLinkUrl?.title}
                      </label>
                      {this.props.imageLinkUrl?.tips ? (
                        <label className="tips-message">
                          {this.props.imageLinkUrl?.tips}
                        </label>
                      ) : null}
                    </>
                  ) : null}
                  <div style={{ display: 'contents', marginTop: '8px' }}>
                    <input
                      type={'url'}
                      onChange={({ target }) => {
                        let newImages = this.getDefaultImages();
                        newImages[index] = { ...item, linkUrl: target.value };
                        this.props.onImageStateChange(newImages);
                      }}
                      value={linkUrl}
                      className={`custom-markdown-area-title custom-markdown-area-title-short ${this.props.imageLinkUrl?.className}`}
                      style={{ marginTop: '8px' }}
                      onFocus={this.props.imageLinkUrl?.onFocus}
                      disabled={this.props.disabled}
                    />
                  </div>
                </div>
              ) : null}
            </>
          );
        })}
        <Modal centered show={this.state.modalShow} onHide={() => {
          this.setState({
            imageSrc: null,
            modalShow: false
          });
        }}>
          <Modal.Header
            closeButton
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <label>
              {Math.floor(this.state.cropDetail.width)} px *{' '}
              {Math.floor(this.state.cropDetail.height)} px
            </label>
          </Modal.Header>
          <Modal.Body>
            <Cropper
              ref={this.cropper}
              src={this.state.imageSrc}
              style={{ height: 'auto', width: '100%', maxHeight: 500 }}
              aspectRatio={this.props.aspect}
              guides={false}
              rotatable={true}
              viewMode={1}
              crop={(event) => {
                this.setState({ cropDetail: event.detail });
              }}
              autoCropArea={1}
              dragMode="move"
              cropBoxMovable={false}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.turnLeftButtonClicked}
              className="turn_left_btn crop_image_btn"
            >
              rotate anticlockwise
            </button>
            <button
              onClick={this.turnRightButtonClicked}
              className="turn_right_btn crop_image_btn"
            >
              rotate clockwise
            </button>
            <button
              onClick={() => this.setState({ 
                imageSrc: null,
                modalShow: false
              })}
              className="crop_image_btn cancel"
            >
              Cancel
            </button>
            <button
              onClick={this.showResult}
              className="crop_image_btn confirm"
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>

        <BasePrompt
          show={this.state.showDoubleConfirm}
          closeAction={() => this.setState({ showDoubleConfirm: false })}
          title={''}
          description={this.state.invalidTitleText}
          rightButton={{
            text: this.state.invalidConfirmText,
            action: () => {
              this.setState({ showDoubleConfirm: false });
              this.uploadImageToServer();
            },
          }}
          leftButton={{
            text: this.state.invalidAgainText,
            action: () => {
              this.setState({ showDoubleConfirm: false });
              this.imageUploadRef.current.click();
            },
          }}
        />
      </div>
    );
  }
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default ImageUploader;

export function ReactHookFormImageUploader({
  title = 'Cover photo',
  uploadSuggest,
  name,
  rules = {},
  minWidth = 100,
  minHeight = 75,
  skipSize = false,
  value,
  aspect = 1,
  maxImageNum = 1,
  language = LanguageConfig.english,
  uploadImageClicked = () => {},
  sizeLimit = 2,
  extraCheckForDoubleConfirm,
}) {
  const { setValue, control, formState } = useFormContext();
  const { errors } = formState;
  return (
    <>
      <CustomTitleLabel title={title} />
      <div>
        <label className="create-message-suggest">{uploadSuggest}</label>
      </div>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={() => (
          <ImageUploader
            minWidth={minWidth}
            minHeight={minHeight}
            skipSize={skipSize}
            images={value ? [value] : []}
            aspect={aspect}
            maxImageNum={maxImageNum}
            language={language}
            onImageStateChange={(newState) => {
              setValue(
                name,
                newState ? (newState?.[0] ? newState?.[0] : null) : null,
                {
                  shouldDirty: true,
                },
              );
            }}
            uploadImageClicked={uploadImageClicked}
            sizeLimit={sizeLimit}
            extraCheckForDoubleConfirm={extraCheckForDoubleConfirm}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id={name} />
    </>
  );
}

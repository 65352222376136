import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { listItem, listImage } from '../../../components/banners/DisplayItems';
import ContentSections from '../../../components/base/ContentSections';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  APIStatus,
  LanguageConfig,
  BANNER_CONTENT_TYPE,
} from '../../../config/CustomEnums';
import { getReadablePeriod } from '../../../utils/TimeFormatUtil';

function BannerDetail({
  banner,
  status,
  languages
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch({
      type: 'bannerList/clearData',
    });
    dispatch({
      type: 'language/getList',
      payload: {
        isSelectorLoad: true
      }
    });
    const bannerId = params.id;
      if (bannerId) {
        dispatch({
          type: 'bannerList/getOneBanner',
          payload: {
            id: bannerId
          }
        });
      } else {
        dispatch({
          type: 'bannerList/loadFromSessionStorage',
        });
      }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'bannerList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getValueFromTranslations = (language, translations) => {
    // console.log("110", language, valueKeys);
    const name = translations?.find(
      (translation) => translation.language === language,
    )?.name || '-';
    return name;
  }

  const campaignSection = (language) => {
    const isEnglish = language === LanguageConfig.english;
    let campaignName = banner.campaign?.name;
    if (!isEnglish) {
      campaignName = getValueFromTranslations(language, banner.campaign?.translations);
    }
    return (
      <>
        {listItem(
          'Related campaign',
          `[ID: ${banner?.campaign?.pk}] ${banner?.campaign?.name}`
        )}
        {listItem(
          'Campaign name',
          campaignName
        )}
        {listItem(
          'Campaign ID',
          banner.campaign?.pk
        )}
        {listItem(
          'Campaign’s active period',
          getReadablePeriod(
            banner.campaign?.startDate,
            banner.campaign?.endDate,
          )
        )}
        {listItem(
          'Campaign’s visible period',
          getReadablePeriod(
            banner.campaign?.displayStartDate,
            banner.campaign?.displayEndDate,
          )
        )}
      </>
    );
  };

  const productSection = (language) => {
    return (
      <>
        {listItem(
          'Related product',
          banner.product?.name
        )}
        {listItem(
          'Product name',
          banner.product?.name
        )}
        {listItem(
          'Product ID',
          banner.product?.pk
        )}
        {listItem(
          'Quantity',
          banner.product?.quantity
        )}
      </>
    );
  };

  const bannerImage = (language) => {
    let coverPhoto = banner.translations?.[language]?.coverPhotos?.[0]?.value;
    if (coverPhoto != null) {
      return (
        <>
          {listImage('Banner image', coverPhoto)}
        </>
      );
    }
    return (
      <>
        {listItem('Banner image', "")}
      </>
    );
  };

  const renderTabForLanguage = (language) => {
    return [
      <>
        {banner.contentType === BANNER_CONTENT_TYPE.CAMPAIGN
          ? campaignSection(language)
          : productSection(language)}
        {bannerImage(language)}
        {listItem('Display order', banner.order)}
      </>
    ]
  };

  const tabs = [
    {
      name: 'Detail',
      content: status === APIStatus.calling
      ? <Loading />
      : (
        <>
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
          />
        </>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'bannerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeBanner}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: banner?.name || '',
        }}
        defaultActiveKey={activeTab}
        breadcrumb={
          <CustomBreadcrumb name={banner?.name ? `${banner.name}` : ''} />
        }
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  banner: state.bannerList.banner,
  status: state.bannerList.loadingStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(BannerDetail);

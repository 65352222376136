import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';

import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  customers: state.customerList.customerList,
  levelList: state.levels.levelList,
});

function CustomerFilterableDropdown({
  selectCustomer = () => {},
  defaultSelectedCustomer = null,
  filterTestAccount = false,
  needLevelFilter = false,
  title,
  description = '',
  errorName = '',
  errorFields = [],
  moreSearch = {},
  customers,
  levelList,
}) {
  const dispatch = useDispatch();

  let search = filterTestAccount ? { isAssignedAsTestingCustomer: true} : {};

  useEffect(() => {
    dispatch(createAction('levels/getLevelList')({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   firstError(errorName, errorFields, LanguageConfig.english);
  // }, [errorFields, errorName]);

  return (
    <>
      <CustomTitleWithDropDown
        title={title}
        description={description}
        source={customers}
        defaultValue={defaultSelectedCustomer}
        setValue={selectCustomer}
        loadMoreAction={'customerList/getPagedCustomers'}
        filterAction={'customerList/getPagedCustomers'}
        filterButtons={
          needLevelFilter ? levelList?.map((item) => item.name) : null
        }
        errors={errorFields}
        errorName={errorName}
        defaultFilter={{
          ...moreSearch,
          ...search,
          isDropdownNode: true,
        }}
        customItem={(item) => `[${item?.membershipId}] ${item?.owner}`}
      />
    </>
  );
}

export default connect(mapPropsToState)(CustomerFilterableDropdown);

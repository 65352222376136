import React from 'react';
import { useHistory } from 'react-router-dom';
import BasePrompt from '../base/prompt/BasePrompt';

function RedirectToStorePrompt(props) {
  const show = props.show;
  const onHide = props.onHide || (() => {});
  const history = useHistory();
  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Go to Stores',
        action: () => {
          history.push({
            pathname: '/stores/create',
            state: {
              from: history.location,
              title: 'Continue to Create Transation',
              content: 'You can continue to create the Transation.',
            },
          });
        },
      }}
      leftButton={{
        text: 'Cancel',
        action: onHide,
      }}
      title={'Go to update Store?'}
      description={
        'You will leave this page. After you update the store, you can come back to conitnue.'
      }
    />
  );
}

export default RedirectToStorePrompt;

import { BaseHelper } from './BaseGraphQLAPIHelper';
import { ImageDomain } from '../config/CustomEnums';

const SimpleListFieldNode = `
node {
  id
  pk
  name
  description
  creationDate
  showInCustomerProfile
}
`
const ListFieldNode = `
node {
  id
  pk
  name
  description
  creationDate
  showInCustomerProfile
  customers {
    edges {
      node {
        pk
        id
        firstName
        lastName
        ssoUid
      }
    }
  }
}
`

export const getGroups = (
  afterCursor = '',
  moreSearch,
) => {
  let orderBy = '-pk';
  if (!moreSearch?.rank) {
    orderBy = 'pk';
  }
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const search = moreSearch?.search?moreSearch.search:'';
  // const node = moreSearch?.isSimpleList ? SimpleListFieldNode : ListFieldNode
  const node = SimpleListFieldNode;

  let searchString = "";
  if (moreSearch?.show_in_customer_profile) {
    searchString += ` , showInCustomerProfile: ${moreSearch?.show_in_customer_profile}`;
  };

  const query = `{
    customerGroups(${
      moreSearch.isAll ? '' : 'first: 20'
    }, after:"${afterCursor}", orderBy:"${orderBy}", name: "${search}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        ${node}
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerGroup = (id) => {
  const query = `
  {
    customerGroup(id: "${id}") {
      id
      pk
      name
      description
      members
      showInCustomerProfile
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomerGroups = (ids) => {
  const query = `
  mutation DeleteCustomerGroups($input: DeleteCustomerGroupsInput!) {
    deleteCustomerGroups(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateCustomerGroup = (id) => {
  const query = `
  mutation CopyCustomerGroup($input: CopyCustomerGroupInput!) {
    copyCustomerGroup(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCustomerGroup = (inputBody) => {
  const query = `
  mutation CreateCustomerGroup($input: CreateCustomerGroupInput!) {
    createCustomerGroup(input: $input) {
      node {
        id
        pk
        name
        description
        customers {
          edges {
            node {
              id
              pk
              firstName
              lastName
              nickname
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomerGroup = (inputBody) => {
  const query = `
  mutation UpdateCustomerGroup($input: UpdateCustomerGroupInput!) {
    updateCustomerGroup(input: $input) {
      node {
        id
        pk
        name
        description
        customers {
          edges {
            node {
              id
              pk
              firstName
              lastName
              nickname
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const CUSTOMER_GROUP_TEMPLATE_FILE_PATH = `${ImageDomain}import_customer_group_template.csv`;

import {
  getPagedAdminGroups,
  deleteAdminGroup,
  updateOrCreateAdminGroup,
  getOneAdminGroup,
} from '../services/AdminGroupApiHelper';
import {
  CheckStatus,
  SavedStatus,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  delay,
  convertPKToId,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { AdminGroupErrorHandleFields } from '../containers/admin/adminGroups/AdminGroupErrorHandleFields';
import { createModel } from './BaseModel';

const adminGroupSessionKey = SESSION_KEYS.ADMIN_GROUP_SESSION_KEY;

const parseAdminGroup = (item) => {
  return {
    ...item,
    permissions: item?.permissions?.edges.map((item) => item.node) || [],
    permissionsString: item?.permissions?.edges
      .map((item) => item.node.name)
      .join(', '),
  };
};

const parseAdminGroupList = (data) => {
  return data.map((item) => parseAdminGroup(item.node));
};

const getInitialState = () => ({
  oneAdminGroup: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Permission', fieldName: 'permissionsString' },
  ],
  adminGroupList: [],
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  saved: SavedStatus.init,
  formChanged: false,
  hasUpdatedDefaultValues: false,
});

export default createModel({
  namespace: 'adminGroup',
  state: getInitialState(),
  params: {
    sessionKey: SESSION_KEYS.ADMIN_GROUP_SESSION_KEY,
    listAPI: getPagedAdminGroups,
    parse: (data) => parseAdminGroupList(data?.administratorGroups?.edges || []),
    objectKey: 'administratorGroups'
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    loadDataFromSessionAndSession(state, { payload }) {
      const tempAdminGroup = getObjectFromSessionStorage(adminGroupSessionKey);
      if(!tempAdminGroup) {
        return {
          ...state,  
        }
      }
      // const serverAdminGroup = payload.data ? payload.data : {};

      // const oneAdminGroup = { ...serverAdminGroup, ...tempAdminGroup };
      // if (payload?.isDuplicate) {
      //   delete oneAdminGroup.pk;
      //   delete oneAdminGroup.id;
      // }
      // saveToSessionStorage(adminGroupSessionKey, oneAdminGroup);

      return {
        ...state,
        oneAdminGroup: tempAdminGroup,
        hasUpdatedDefaultValues: true,
      };
    },

    saveAdminGroupToSessionStorage(state, { payload }) {
      saveToSessionStorage(adminGroupSessionKey, payload.values);
      return {
        ...state,
      }
    },

    removeAdminGroupFromSessionStorage(state, { payload }) {
      removeFromSessionStorage(adminGroupSessionKey);
      return {
        ...state,
      }
    },

    changeVals(state, { payload }) {
      let tempAdminGroup = getObjectFromSessionStorage(adminGroupSessionKey);

      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }

      tempAdminGroup = { ...tempAdminGroup, ...data };
      saveToSessionStorage(adminGroupSessionKey, tempAdminGroup);

      return {
        ...state,
        formChanged: true,
      };
    },

    checkValsValid(state, { payload }) {
      let tempAdminGroup = payload
        ? payload
        : {
            ...state.oneAdminGroup,
            ...getObjectFromSessionStorage(adminGroupSessionKey),
          };

      let errorFields = { fields: [], messages: [] };
      let checked = CheckStatus.initOrNotChecked;

      Object.keys(AdminGroupErrorHandleFields).forEach((field) => {
        if (!tempAdminGroup[field]) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'required' });
        }
      });

      if (errorFields.fields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }
      console.log('@@219: ', errorFields);
      return {
        ...state,
        checked,
        errorFields,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getPagedAdminGroups: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getPagedAdminGroups,
          pageCursor,
          // payload.reverse,
          // payload.search,
          { ...payload.moreSearch, ...payload },
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.administratorGroups?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.administratorGroups?.totalCount;

          yield put({
            type: 'updateState',
            payload: {
              adminGroupList: parseAdminGroupList(
                data?.administratorGroups?.edges,
              ),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { all, put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.adminGroup.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteAdminGroup, pks];
        const afterAction = payload.afterAction || (() => {});
        console.log('@@231: ', pks);
        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield all([
            put({
              type: 'updateState',
              payload: {
                checkedList: [],
                formChanged: false,
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { all, put, select }) {
        const copyAdminGroup = payload.data;
        console.log('@@324: ', copyAdminGroup);
        const data = {
          name: `Copy of ${copyAdminGroup.name}`,
          permissions: copyAdminGroup.permissions?.map((item) => item.pk),
        };

        const serviceArgs = [updateOrCreateAdminGroup, data];
        const afterAction = payload.afterAction || (() => {});

        // eslint-disable-next-line require-yield
        function* onSuccess(data) {
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getOneAdminGroup: [
      function* ({ payload }, { all, put, select }) {
        const serviceArgs = [
          getOneAdminGroup,
          convertPKToId('AdministratorGroupNode', payload.id),
        ];

        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                oneAdminGroup: parseAdminGroup(data?.administratorGroup),
                hasUpdatedDefaultValues: true,
              },
            }),
          ]);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateOrCreate: [
      function* ({ payload }, { all, put, select }) {
        const afterAction = payload.afterAction || (() => {});
        const tempAdminGroup = { ...payload.values, ...payload.data};
        console.log("@314", payload)

        let data = {
          name: tempAdminGroup.name,
          permissions: tempAdminGroup.permissions?.map((item) => item.pk),
        };

        if (tempAdminGroup.pk) {
          data.id = tempAdminGroup.pk;
        }
        const serviceArgs = [updateOrCreateAdminGroup, data];

        function* onSuccess(data) {
          if (
            ('createAdminGroup' in data &&
              data.createAdministratorGroup.errors) ||
            ('updateAdminGroup' in data && data.updateAdministratorGroup.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                saved: SavedStatus.savedWithFail,
                oneAdminGroup: {},
              },
            });
          } else {
            const oneAdminGroup = payload.data
              ? parseAdminGroup(data.createAdministratorGroup.node)
              : {};
            yield put({
              type: 'updateState',
              payload: {
                formChanged: false,
                saved: SavedStatus.savedWithSuccess,
                oneAdminGroup,
              },
            });
            yield afterAction();
          }

          removeFromSessionStorage(adminGroupSessionKey);
        }

        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithFail,
            },
          });
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
});

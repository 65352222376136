import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  BackAndTempSaveButtons,
  ContinueWithBackAndTempSaveButtons,
} from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import MessageGeneralSectionWhatsapp from 'components/message/MessageGeneralSectionWhatsapp';
import {
  ActionType,
  ApprovalStatus,
  MessageStepConfig,
} from 'config/CustomEnums';
import { afterSaveASDraftSuccess } from 'containers/engagement/message/CreateMessage';
import { validate } from 'containers/engagement/message/CreateMessageValidate';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  selectedChannels: state.createMessage.selectedChannels,
});

function CreateMessageStepTwoWhatsapp({
  languages,
  selectedChannels,
  setShowSentPrompt,
}) {
  const dispatch = useDispatch();
  const params = useParams();

  const { getValues, setValue, setError, clearErrors, watch } =
    useFormContext();
  const approvalStatus = watch('approvalStatus');
  const isPublished = approvalStatus === ApprovalStatus.published;

  let whatsappStep = 1;
  if (MessageStepConfig[1].show(selectedChannels)) {
    whatsappStep = whatsappStep + 1;
  }
  if (MessageStepConfig[2].show(selectedChannels)) {
    whatsappStep = whatsappStep + 1;
  }
  if (MessageStepConfig[3].show(selectedChannels)) {
    whatsappStep = whatsappStep + 1;
  }

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 4,
      isBack,
      languages: languages,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: whatsappStep,
        isValid,
      },
    });
  };

  const saveTempAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 4,
      isBack: false,
      languages: languages,
    });
    if (isValid && !isPublished) {
      dispatch(
        createAction('createMessage/saveAsDraftMessage')({
          values: getValues(),
          afterActions: (tempSavePk, translationPks) => {
            afterSaveASDraftSuccess({ setValue, tempSavePk, translationPks })
          },
        }),
      );
    } else if (isValid && isPublished) {
      dispatch(
        createAction('createMessage/createOrUpdateMessage')({
          values: getValues(),
          actionType: ActionType.tempForPulished,
          afterActions: () => {
            setShowSentPrompt(true);
          },
        }),
      );
    } else {
      dispatch({
        type: 'createMessage/stepChange',
        payload: {
          isBack: false,
          step: whatsappStep,
          isValid,
        },
      });
    }
  };

  const renderTabForLanguage = (language) => {
    return [
      <MessageGeneralSectionWhatsapp key={language} language={language} />,
    ];
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <ContinueWithBackAndTempSaveButtons
        backAction={() => stepChangeAction(true)}
        saveTempText={params.id ? 'Update' : null}
        saveTempAction={() => saveTempAction()}
        continueAction={() => stepChangeAction(false)}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateMessageStepTwoWhatsapp);

import React from 'react';
import './CustomEditableRank.scss';
import { Image } from 'react-bootstrap';
import RankIcon from '../../assets/images/rank_icon.svg';

function CustomEditableRank(props) {
  const disabled = props.disabled || false;
  const showRank = props.show;
  const action = props.onClick || (() => {});
  const title = props.title;
  return (
    <div
      onClick={() => {
        if (disabled) return;
        action(!props.rank);
      }}
      className={`all-list-scrollable-area-table-item-common ${props.extraAreaStyle}`}
    >
      <label
        className={`all-list-scrollable-area-title ${
          disabled ? '' : 'custom-editable-rank'
        }`}
      >
        {title}
      </label>
      {disabled || !showRank ? null : (
        <Image
          className={`custom-editable-rank-icon custom-editable-rank ${
            props.rank ? '' : 'custom-editable-rank-icon-rtoate180'
          } ${props.extraRankStyle}`}
          src={RankIcon}
        />
      )}
    </div>
  );
}
export default CustomEditableRank;

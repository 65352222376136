import { CampaignErrorHandleField } from 'components/campaign/campaignCreation/CreateCampaignHandleError';
import { StampCampaignErrorHandleField } from 'components/stampCampaign/stampCampaignCreation/CreateStampCampaignHandleError';
import { CampaignType, EarningRuleRewardType, LanguageConfig } from 'config/CustomEnums';
import { GENERAL_TYPE } from 'models/CreateCampaignModel';

function firstStepValidate({ getValues, setError }) {
  return true;
}

function checkRequiredStampRepeat(reward, rewardList) {
  let repeat = 0;
  rewardList.forEach((item, index) => {
    if (Number(item.requiredStamps) === Number(reward.requiredStamps)) {
      repeat += 1;
    }
  });
  return repeat !== 1;
}

function stampCampaignSecondStepValidate({ getValues }) {
  const [
    coverPhoto,
    isExclusive,
    square,
    shortDescription,
    linkedEarningRules,
    stampRewards,
    useUploadStampIcons,
    emptyStampSlot,
    collectedStamp,
    emptyGiftStampSlot,
    collectedGift,
  ] = getValues([
    'translations.en.coverPhoto',
    'isExclusive',
    'translations.en.squareCoverPhoto',
    'translations.en.shortDescription',
    'linkedEarningRules',
    'stampRewards',
    'useUploadStampIcons',
    'stampCampaignTypeEmptySlotStampImage',
    'stampCampaignTypeAcquiredStampImage',
    'stampCampaignTypeEmptyGiftSlotImage',
    'stampCampaignTypeCollectedGiftImage',
  ]);
  const errorList = [];
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: StampCampaignErrorHandleField.coverPhoto.message,
    });
  }
  if (isExclusive && !square) {
    errorList.push({
      name: 'translations.en.squareCoverPhoto',
      message: StampCampaignErrorHandleField.squarePhoto.message,
    });
  }
  if (!linkedEarningRules || linkedEarningRules?.length === 0) {
    errorList.push({
      name: 'linkedEarningRules',
      message: StampCampaignErrorHandleField.linkedEarningRules.message,
    });
  }
  if (!stampRewards || stampRewards?.length === 0) {
    errorList.push({
      name: 'stampReward',
      message: StampCampaignErrorHandleField.stampReward.message,
    });
  } else {
    let quiredStampList = [];
    stampRewards.forEach((item, index) => {
      if (
        item.rewardType === EarningRuleRewardType.coupons &&
        !item?.linkedCoupon?.name
      ) {
        errorList.push({
          name: `stampRewards[${index}].linkedCoupon`,
          message: StampCampaignErrorHandleField.linkedCoupon.message,
        });
      }
      if (
        isEmpty(item?.requiredStamps) ||
        Number(item?.requiredStamps) < 0 ||
        Number(item?.requiredStamps) > 9999
      ) {
        errorList.push({
          name: `stampRewards[${index}].requiredStamps`,
          message: StampCampaignErrorHandleField.numberLimit.message,
        });
      } else if (checkRequiredStampRepeat(item, stampRewards)) {
        errorList.push({
          name: `stampRewards[${index}].requiredStamps`,
          message: StampCampaignErrorHandleField.requiredStamps.message,
        });
      } else {
        quiredStampList.push(item.requiredStamps);
      }
      if (isEmpty(item?.quantity) || Number(item?.quantity) < 0) {
        errorList.push({
          name: `stampRewards[${index}].quantity`,
          message: StampCampaignErrorHandleField.quantityRequired.message,
        });
      }
      if (
        item.rewardType === EarningRuleRewardType.coupons &&
        Number(item.quantity) > Number(item?.linkedCoupon?.stock)
      ) {
        errorList.push({
          name: `stampRewards[${index}].quantity`,
          message: StampCampaignErrorHandleField.couponQuantity.message,
        });
      }
      if (useUploadStampIcons) {
        if (!emptyStampSlot) {
          errorList.push({
            name: 'stampCampaignTypeEmptySlotStampImage',
            message: StampCampaignErrorHandleField.fileRequired.message,
          });
        }
        if (!collectedStamp) {
          errorList.push({
            name: 'stampCampaignTypeAcquiredStampImage',
            message: StampCampaignErrorHandleField.fileRequired.message,
          });
        }
        if (!emptyGiftStampSlot) {
          errorList.push({
            name: 'stampCampaignTypeEmptyGiftSlotImage',
            message: StampCampaignErrorHandleField.fileRequired.message,
          });
        }
        if (!collectedGift) {
          errorList.push({
            name: 'stampCampaignTypeCollectedGiftImage',
            message: StampCampaignErrorHandleField.fileRequired.message,
          });
        }
      }
    });
  }
  return errorList;
}

function secondStepAllLanguagesValidate({ getValues, languages, isStampCampaign=false }) {
  let errorList = [];
  languages.forEach((language) => {
    const genenralNameField = `translations.${language}.generalName`;
    const [generalName] = getValues([genenralNameField]);
    if (
      (language === LanguageConfig.english && !generalName) ||
      (!!generalName && generalName.length > 200)
    ) {
      errorList.push({
        name: genenralNameField,
        message: isStampCampaign
          ? StampCampaignErrorHandleField.generalName.message
          : CampaignErrorHandleField.generalName.message,
      });
    }
  });
  return errorList;
}

function secondStepValidate({ getValues, setError, languages }) {
  const [
    coverPhoto,
    isExclusive,
    square,
    shortDescription,
    hideInAnyChannel,
    campaignType,
  ] = getValues([
    'translations.en.coverPhoto',
    'isExclusive',
    'translations.en.squareCoverPhoto',
    'translations.en.shortDescription',
    'hideInAnyChannel',
    'campaignType',
  ]);
  let errorList = [];
  const isStampCampaign = campaignType === CampaignType.stampCampaign;

  const allLanguagesErrorList = secondStepAllLanguagesValidate({ getValues, languages, isStampCampaign });
  errorList = [...errorList, ...allLanguagesErrorList];

  if (!hideInAnyChannel && !isStampCampaign) {
    if (!coverPhoto) {
      errorList.push({
        name: 'translations.en.coverPhoto',
        message: CampaignErrorHandleField.coverPhoto.message,
      });
    }
    if (isExclusive && !square) {
      errorList.push({
        name: 'translations.en.squareCoverPhoto',
        message: CampaignErrorHandleField.squarePhoto.message,
      });
    }
  } else if (isStampCampaign) {
    const stampCampaignErrorList = stampCampaignSecondStepValidate({
      getValues,
    });
    errorList = [...errorList, ...stampCampaignErrorList];
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
};

function thirdStepValidate({ getValues, setError }) {
  const [
    isExclusive,
    activeStartDate,
    activeEndDate,
    visibleStartDate,
    visibleEndDate,
    campaignType,
    requiredPoints,
    isAlwaysActivePeriod,
    isAlwaysVisiblePeriod,
    customerGroup,
    segments,
    generalType,
    perHeadLimit,
    linkedCoupon,
    hideInAnyChannel,
  ] = getValues([
    'isExclusive',
    'activeStartDate',
    'activeEndDate',
    'visibleStartDate',
    'visibleEndDate',
    'campaignType',
    'requiredPoints',
    'isAlwaysActivePeriod',
    'isAlwaysVisiblePeriod',
    'customerGroup',
    'segments',
    'generalType',
    'perHeadLimit',
    'linkedCoupon',
    'hideInAnyChannel',
  ]);
  const errorList = [];
  const isStampCampaign = campaignType === CampaignType.stampCampaign;
  if (
    !isAlwaysActivePeriod &&
    activeStartDate.getTime() >= activeEndDate.getTime()
  ) {
    errorList.push({
      name: 'activeEndDate',
      message: CampaignErrorHandleField.activeEndDate.message,
    });
    // errorFields.push(CampaignErrorHandleField.activeEndDate.name);
  }
  if (!hideInAnyChannel && !isStampCampaign) {
    if (isExclusive) {
      if (
        generalType === GENERAL_TYPE.customerGroup &&
        customerGroup?.length === 0
      ) {
        errorList.push({
          name: 'customerGroup',
          message: CampaignErrorHandleField.customerGroup.message,
        });
      }
      if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
        errorList.push({
          name: 'segments',
          message: CampaignErrorHandleField.segment.message,
        });
      }
    }
    if (
      !isAlwaysVisiblePeriod &&
      visibleStartDate.getTime() >= visibleEndDate.getTime()
    ) {
      errorList.push({
        name: 'visibleEndDate',
        message: CampaignErrorHandleField.visibleEndDate.message,
      });
      // errorFields.push(CampaignErrorHandleField.visibleEndDate.name);
    }
    if (
      campaignType === CampaignType.couponsCampaign &&
      ((requiredPoints !== 0 && !requiredPoints) || requiredPoints < 0)
    ) {
      errorList.push({
        name: 'requiredPoints',
        message: CampaignErrorHandleField.requiredPoints.message,
      });
      // errorFields.push(CampaignErrorHandleField.requiredPoints.name);
    }

    if (campaignType === CampaignType.couponsCampaign) {
      if (
        Number(perHeadLimit) >
        Number(linkedCoupon?.totalNumberOfGeneratedCoupons)
      ) {
        errorList.push({
          name: 'perHeadLimit',
          message: CampaignErrorHandleField.perHeadLimit.message?.replace(
            '%{totalNumberOfGeneratedCoupons}',
            `${linkedCoupon?.totalNumberOfGeneratedCoupons}`,
          ),
        });
      }
    }
  } else if (isStampCampaign) {
    if (isExclusive) {
      if (
        generalType === GENERAL_TYPE.customerGroup &&
        customerGroup?.length === 0
      ) {
        errorList.push({
          name: 'customerGroup',
          message: StampCampaignErrorHandleField.customerGroup.message,
        });
      }
      if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
        errorList.push({
          name: 'segments',
          message: StampCampaignErrorHandleField.segment.message,
        });
      }
    }
    if (
      !isAlwaysVisiblePeriod &&
      visibleStartDate.getTime() >= visibleEndDate.getTime()
    ) {
      errorList.push({
        name: 'visibleEndDate',
        message: StampCampaignErrorHandleField.visibleEndDate.message,
      });
    }
  }
  // return {
  //   invalid: isBack ? false : errorFields.length > 0,
  //   errorFields: isBack ? [] : errorFields,
  //   data: { ...data },
  // };
  // return true;
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const fourStepValidate = ({ getValues, setError, checkCheckList }) => {
  const [checkList, campaignType] = getValues(['checkList', 'campaignType']);
  if (Object.keys(checkList || {}).length === 0 || !checkCheckList) {
    return true;
  }
  const errorList = [];
  (Object.entries(checkList) || []).forEach(([key, value]) => {
    if (!value) {
      errorList.push({
        name: 'checkList',
        message:
          campaignType === CampaignType.stampCampaign
            ? StampCampaignErrorHandleField.checkList.message
            : CampaignErrorHandleField.checkList.message,
      });
      return;
    }
  });
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
};

export function validate({
  getValues,
  setError,
  clearErrors,
  step,
  isBack,
  checkCheckList = false,
  languages=[LanguageConfig.english],
}) {
  console.log('@71 campaignValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  const campaignType = getValues('campaignType');
  switch (campaignType === CampaignType.stampCampaign ? step + 1 : step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError, languages });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    case 3:
      const validStepFour = fourStepValidate({
        getValues,
        setError,
        checkCheckList,
      });
      return validStepFour;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      const validFour = fourStepValidate({
        getValues,
        setError,
        checkCheckList,
      });
      return validOne && validTwo && validThree && validFour;
  }
}

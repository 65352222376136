import {
  CampaignType,
  EarningRuleRewardType,
  EarningRuleType,
} from 'config/CustomEnums';
import { createModel } from 'models/BaseModel';
import { assembleCouponTemplates } from 'models/CouponUtil';
import { apiWithResponseHandle, loading } from 'models/LoadingUtil';
import {
  deleteEarningRules,
  getEarningRuleList,
  getEarningRulesWithType,
} from 'services/EarningRuleHelper';

import { convertCursorToNumber, convertNumberToCursor, delay } from 'utils';
import { getDisplayDate } from 'utils/TimeFormatUtil';

const linkedCampaignTypeEnum = {
  stampCampaign: 'STAMP_CAMPAIGN',
  earningCampaign: 'EARNING_CAMPAIGN',
};
const campaignTypeEnum = {
  [linkedCampaignTypeEnum.stampCampaign]: 'Stamp campaign',
  [linkedCampaignTypeEnum.earningCampaign]: 'Earning campaign',
};

const getInitialState = () => ({
  earningRuleList: [],
  earningRuleTypeList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedEarningRules: [],
  checkedList: [],
  forStamp: false,
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Linked campaign type', fieldName: 'campaignType' },
    { displayName: 'Target customers', fieldName: 'numberOfCustomersVisible' },
    { displayName: 'Linked Campaign', fieldName: 'linkedCampaign' },
    { displayName: 'Reward Type', fieldName: 'rewardType' },
    {
      displayName: 'Create at',
      fieldName: 'displayCreated',
      orderField: 'creationDate',
    },
    {
      displayName: 'Last Modified',
      fieldName: 'displayModified',
      orderField: 'lastModifiedDate',
    },
  ],
});

const assembleEarningRules = (earningRules) =>
  earningRules.map((earningRule) => {
    const ruleNode = earningRule.node;
    const type = ruleNode.type;
    const couponTemplateData = {};
    switch (type) {
      case EarningRuleType.generalPurchase:
        couponTemplateData.node =
          ruleNode.generalPurchaseTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.memberReferral:
        couponTemplateData.node =
          ruleNode.memberReferralTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.newMember:
        couponTemplateData.node =
          ruleNode.newMemberTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.birthday:
        couponTemplateData.node =
          ruleNode.birthdayTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.qrCodeScanning:
        couponTemplateData.node =
          ruleNode.qrCodeScanningTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.gpsCheckIn:
        couponTemplateData.node =
          ruleNode.gpsCheckInTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.fillingForm:
        couponTemplateData.node =
          ruleNode.fillingFormTypeCouponRewardTypeCouponTemplate;
        break;
      case EarningRuleType.levelUpgrade:
        couponTemplateData.node =
          ruleNode.generalPurchaseTypeCouponRewardTypeCouponTemplate;
        break;
      default:
        break;
    }

    const templates = [];
    if (couponTemplateData.node) {
      templates.push(couponTemplateData);
    }
    return {
      id: ruleNode.id,
      name: ruleNode.name,
      pk: ruleNode.pk,
      couponTemplate: assembleCouponTemplates(templates)[0] || {},
      ...ruleNode,
    };
  });

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const getRewardType = (earnRule) => {
  const key = getKeyByValue(EarningRuleType, earnRule.type);
  const rewardType = earnRule[`${key}TypeRewardType`];

  if (rewardType === EarningRuleRewardType.points) {
    if (earnRule.type === EarningRuleType.generalPurchase) {
      const point =
        earnRule.generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent;
      const x = earnRule.generalPurchaseTypePointsRewardTypeX;
      let result = `${rewardType} $${x} = ${point}pts`;
      if (earnRule.generalPurchaseTypePointsRewardTypeTpePerXDollarsSpent) {
        const tpeX = earnRule.generalPurchaseTypePointsRewardTypeTpeX;
        const tpe =
          earnRule.generalPurchaseTypePointsRewardTypeTpePerXDollarsSpent;
        result += `\r\nPROGRESSION POINTS $${tpeX} = ${tpe}pts`;
      }
      return result;
    }
    const rewardPointValue = earnRule[`${key}TypePointsRewardTypePoints`];
    let result = `${rewardType} ${rewardPointValue}pts`;
    const rewardTpe = earnRule[`${key}TypePointsRewardTypeTpe`];
    if (!!rewardTpe) {
      result += `\r\nPROGRESSION POINTS ${rewardTpe}pts`;
    }
    return result;
  } else if (rewardType === EarningRuleRewardType.coupons) {
    const couponTemplate = earnRule[`${key}TypeCouponRewardTypeCouponTemplate`];
    return `${rewardType}: [ID: ${couponTemplate.pk}] ${couponTemplate.name}`;
  } else if (rewardType === EarningRuleRewardType.stamps) {
    if (earnRule.type === EarningRuleType.generalPurchase) {
      const stamp =
        earnRule.generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent;
      const x = earnRule.generalPurchaseTypeStampRewardTypeX;
      const result = `${rewardType} $${x} = ${stamp}stamps`;
      return result;
    }
    const rewardStampValue = earnRule[`${key}TypeStampRewardTypeQuantity`];
    let result = `${rewardType} ${rewardStampValue}stamps`;
    return result;
  }

  return `${rewardType}`;
};

export const isEarningRuleRequireToLinkCampaign = (earnRuleType) => {
  if (
    [
      EarningRuleType.qrCodeScanning,
      EarningRuleType.gpsCheckIn,
      EarningRuleType.memberReferral,
    ].includes(earnRuleType)
  ) {
    return true;
  }
  return false;
};

const getLinkedCampaignDisplay = (earnRule) => {
  if (!isEarningRuleRequireToLinkCampaign(earnRule?.type)) {
    return 'Not applicable';
  }
  const campaigns = earnRule.campaigns.edges;
  const linkedCampaign = earnRule.campaigns.edges.map(
    (campaign) => `[ID: ${campaign.node?.pk}] ${campaign.node?.name}`,
  );
  return linkedCampaign.join(', ');
};

const getLinkedCampaignType = (earnRule) => {
  return earnRule.linkedCampaignType === linkedCampaignTypeEnum.stampCampaign
    ? campaignTypeEnum[linkedCampaignTypeEnum.stampCampaign]
    : campaignTypeEnum[linkedCampaignTypeEnum.earningCampaign];
};

const getLinkedStampCampaignDisplay = (item) => {
  const stampCampaignList = item.stampTaskRelation.edges.map(
    (task) =>
      `[ID: ${task.node.stampCampaign?.pk}] ${task.node.stampCampaign?.name}`,
  );
  return stampCampaignList.join(', ');
};

const parseEarningRule = (item) => {
  let linkedCampaign = [];
  if (item.linkedCampaignType === CampaignType.stampCampaign) {
    linkedCampaign = getLinkedStampCampaignDisplay(item);
  } else {
    linkedCampaign = getLinkedCampaignDisplay(item);
  }
  return {
    ...item,
    campaignType: getLinkedCampaignType(item),
    linkedCampaign,
    rewardType: getRewardType(item),
    displayCreated: getDisplayDate(item.creationDate),
    displayModified: getDisplayDate(item.lastModifiedDate),
    linkedStampCampaign:
      item.linkedStampCampaign === 'STAMP_CAMPAIGN' ? true : false,
  };
};

export default createModel({
  namespace: 'earningRuleList',
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleEarningRuleList(state, { payload }) {
      const { templateList, pageInfo, page, totalCount } = payload;
      const earningRuleTypeList = templateList.map((item) =>
        parseEarningRule(item.node),
      );
      const earningRuleList = assembleEarningRules(templateList);
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        earningRuleTypeList: earningRuleTypeList,
        earningRuleList:
          payload.isSelectorLoad && page > 1
            ? [...state.earningRuleList, ...earningRuleList]
            : earningRuleList,
        totalCount,
        totalPage: Math.ceil(totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  params: {
    listAPI: getEarningRuleList,
    objectKey: 'earningRules',
    parse: (data) => {
      return data.earningRules.edges.map((item) => parseEarningRule(item.node));
    },
  },
  effects: {
    getEarningRuleListWithTypes: [
      function* ({ payload }, { call, all, put }) {
        const page = payload.page !== undefined ? payload.page : 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const linkedCampaignType = payload.linkedCampaignType
          ? payload.linkedCampaignType
          : payload.linked_campaign_type;
        const serviceArgs = [
          getEarningRulesWithType,
          pageCursor,
          payload.reverse,
          payload.type || 'all',
          payload.search || '',
          payload.otherSearch || '',
          linkedCampaignType || '',
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const earningRules = data.earningRules;
          yield put({
            type: 'assembleEarningRuleList',
            payload: {
              templateList: earningRules.edges,
              totalCount: earningRules.totalCount,
              pageInfo: earningRules.pageInfo,
              ...payload,
            },
          });
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedEarningRules } = yield select((state) => ({
          checkedEarningRules: state.earningRuleList.checkedList,
        }));

        let pks = [];
        let deleteCampaignIds = [];
        checkedEarningRules.forEach((item) => {
          pks.push(item.pk);

          item.campaigns.edges.forEach((campaign) => {
            deleteCampaignIds.push(campaign.node.pk);
          });
        });

        const serviceArgs = [deleteEarningRules, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield all([
            // yield put({
            //   type: 'createCampaign/deleteCampaigns',
            //   payload: { campaignPks: deleteCampaignIds },
            // }),
            // yield put({
            //   type: 'deleteCampaignTranslations',
            //   payload: { campaignPks: deleteCampaignIds },
            // }),
            put({
              type: 'earningRuleList/updateState',
              payload: { checkedList: [] },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});

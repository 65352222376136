import React from 'react';
import { Image } from 'react-bootstrap';

import './DisplayItems.scss';

export const titleForItem = (title, moreStyle) => {
  return (
    <label
      className={`create-section-label create-section-label-bottom-space ${moreStyle}`}
    >
      {title}
    </label>
  );
};

export const listItem = (title, value, moreStyle) => {
  return (
    <>
      {titleForItem(title, moreStyle)}
      <label className="banners-detail-text">{value || '-'}</label>
    </>
  );
};

export const listMultiItem = (title, values, moreStyle) => {
  let items = <label className="banners-detail-text">{'-'}</label>;
  if (values && values.length) {
    items = values.map((value) => (
      <label className="banners-detail-text">{value || '-'}</label>
    ));
  }
  return (
    <>
      {titleForItem(title, moreStyle)}
      {items}
    </>
  );
};

export const listImage = (title, src) => {
  return (
    <>
      {titleForItem(title)}
      <Image className="feature-campaign-detail-image" src={src} />
    </>
  );
};

import React, { useEffect } from 'react';
import './CreateCouponStepOne.scss';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import ItemTypeSet from '../../../components/base/ItemTypeSet';
import {
  COUPON_SET_TYPES,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_MEDIUM,
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import CustomRadios from '../../../components/base/CustomRadios';
import SystemGenerated from '../../../assets/images/coupon/systemGenerated.svg';
import ImportExistingCoupon from '../../../assets/images/coupon/importExistingCoupon.svg';

export const COUPON_MEDIUM_CONFIG = [
  {
    label: 'Online only',
    value: COUPON_SET_MEDIUM.ONLINE,
  },
  {
    label: 'Offline only',
    value: COUPON_SET_MEDIUM.OFFLINE,
  },
  {
    label: 'Online or offline',
    value: COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE,
  },
]

export const OFFLINE_COUPON_TYPES_CONFIG = [
  {
    id: COUPON_SET_TYPES.SYSTEM_GENERATED,
    title: 'Generated by CMS',
    image: SystemGenerated,
    description: 'Coupon generated by the system',
  },
  {
    id: COUPON_SET_TYPES.IMPORT_EXISTING_COUPON,
    title: 'External',
    image: ImportExistingCoupon,
    description: 'Import existing coupon to the system',
  },
];

export const ONLINE_COUPON_TYPES_CONFIG = [
  {
    id: COUPON_SET_TYPES.IMPORT_EXISTING_COUPON,
    title: 'External',
    image: ImportExistingCoupon,
    description: 'Import existing coupon to the system',
  },
];

export const OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG = [
  {
    label: 'Merchant App to scan coupon QR code',
    value: COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE,
  },
  {
    label: 'Customer App to scan store QR code',
    value: COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE,
  },
  {
    label: 'Input store PIN code only',
    value: COUPON_REDEMPTION_METHOD.INPUT_STORE_STAFF_CODE,
  },
  {
    label: 'Sales system updated',
    value: COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE,
  },
  // {
  //   label: 'Users copy the discount code',
  //   value: COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE,
  // },
];

export const ONLINE_COUPON_REDEMPTION_METHOD_CONFIG = [
  {
    label: 'Sales system updated',
    value: COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE,
  },
]

const CreateCouponStepOne = () => {
  const dispatch = useDispatch();
  const { watch, setValue } = useFormContext();
  const watchMediumType = watch('mediumType');
  const watchOfflineCouponSetType = watch('offlineCouponSetType');
  const watchOnlineCouponSetType = watch('onlineCouponSetType');
  const watchOfflineRedemptionMethod = watch('offlineRedemptionMethod');
  const watchOnlineRedemptionMethod = watch('onlineRedemptionMethod');
  const watchIsLinkedCustomer = watch('isLinkedCustomer');

  const [methodOptions, setMethodOptions] = React.useState(
    watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
      ? OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG
      : OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 3),
  );

  useEffect(() => {
    if (!watchMediumType) {
      setValue('mediumType', COUPON_SET_MEDIUM.OFFLINE, { shouldDirty: true });
    }
    if (watchMediumType === COUPON_SET_MEDIUM.ONLINE || watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
      !watchOnlineCouponSetType && setValue('onlineCouponSetType', COUPON_SET_TYPES.IMPORT_EXISTING_COUPON, { shouldDirty: true });
      !watchOnlineRedemptionMethod && setValue('onlineRedemptionMethod', COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMediumType])

  useEffect(() => {
    setMethodOptions(
      watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
        ? OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG
        : OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 3),
    );
  }, [watchOfflineCouponSetType]);

  const offlineCouponSection = () => {
    return (<>
      <div className={
        watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE ?
          'second-section' : 'create-section-label-bottom-space'
      }>
        <label className="step-one-title">Offline coupon code source</label>
        <div className={OFFLINE_COUPON_TYPES_CONFIG?.length > 1 ? "step-type-area coupon-type-area" : "step-type-area"}>
          {OFFLINE_COUPON_TYPES_CONFIG.map((typeItem) => (
            <ItemTypeSet
              moreThanThree={false}
              extraClassName="couponset-card"
              key={`${typeItem.id}-${typeItem.description}`}
              item={typeItem}
              selected={watchOfflineCouponSetType}
              onClick={(id) => {
                setValue('offlineCouponSetType', id, { shouldDirty: true });
                if (id === COUPON_SET_TYPES.SYSTEM_GENERATED) {
                  setMethodOptions(OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 3));
                  if (
                    watchOfflineRedemptionMethod ===
                    COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE
                  ) {
                    setValue('offlineRedemptionMethod', null, { shouldDirty: true });
                  }
                } else {
                  setMethodOptions(OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG);
                }
              }}
              disabled={watchIsLinkedCustomer}
            />
          ))}
        </div>
      </div>
      <label className="step-one-title">Offline redemption method</label>
      <div className='redemption-method-area'>
        <CustomRadios
          onChange={(value) => {
            setValue('offlineRedemptionMethod', value, { shouldDirty: true });
          }}
          default={watchOfflineRedemptionMethod}
          disabled={watchIsLinkedCustomer}
          options={methodOptions}
        />
      </div>
    </>)
  }

  const onlineCouponSection = () => {
    return (<>
      <div className='create-section-label-bottom-space'>
        <label className="step-one-title">Online coupon code source</label>
        <div className={ONLINE_COUPON_TYPES_CONFIG?.length > 1 ? "step-type-area coupon-type-area" : "step-type-area"}>
          {ONLINE_COUPON_TYPES_CONFIG.map((typeItem) => (
            <ItemTypeSet
              moreThanThree={false}
              extraClassName="couponset-card"
              key={`${typeItem.id}-${typeItem.description}`}
              item={typeItem}
              selected={watchOnlineCouponSetType}
              onClick={(id) => {
                setValue('onlineCouponSetType', id, { shouldDirty: true });
              }}
              disabled={watchIsLinkedCustomer}
            />
          ))}
        </div>
        <label className="step-one-title">Online redemption method</label>
        <div className="redemption-method-area">
          <CustomRadios
            onChange={(value) => {
              setValue('onlineRedemptionMethod', value, { shouldDirty: true });
            }}
            default={watchOnlineRedemptionMethod}
            disabled={watchIsLinkedCustomer}
            options={ONLINE_COUPON_REDEMPTION_METHOD_CONFIG}
          />
        </div>
      </div>
    </>)
  }

  const sections = [
    <div>
      <label className="step-one-title">Coupon medium</label>
      <div style={{ display: 'flex', marginTop: 8 }}>
        <CustomRadios
          onChange={(value) => {
            setValue('mediumType', value, { shouldDirty: true });
          }}
          default={watchMediumType}
          options={COUPON_MEDIUM_CONFIG}
          disabled={watchIsLinkedCustomer}
        />
      </div>
      {[COUPON_SET_MEDIUM.ONLINE, COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE].includes(watchMediumType) && onlineCouponSection()}
      {watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE && <div className='create-section-split-line' />}
      {[COUPON_SET_MEDIUM.OFFLINE, COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE].includes(watchMediumType) && offlineCouponSection()}
    </div>,
  ];

  const isContinueButtonDisabled = () => {
    if (watchMediumType === COUPON_SET_MEDIUM.ONLINE && (
      !watchOnlineCouponSetType || !watchOnlineRedemptionMethod
    )) {
      return true
    }
    if (watchMediumType === COUPON_SET_MEDIUM.OFFLINE && (
      !watchOfflineCouponSetType || !watchOfflineRedemptionMethod
    )) {
      return true
    }
    if (watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE && (
      !watchOfflineCouponSetType || !watchOfflineRedemptionMethod ||
      !watchOnlineCouponSetType || !watchOnlineRedemptionMethod
    )) {
      return true
    }
    return false
  }
  return (
    <>
      <div className="create-coupon-step-one-container">
        <ContentSections sections={sections} hidePreview={true} />
      </div>
      <OnlyContinueButton
        disabledContinue={isContinueButtonDisabled()}
        continueAction={() => {
          dispatch(
            createAction('createCoupon/stepChange')({
              step: 0,
              isValid: true,
            }),
          );
          // saveCouponToSessionStorage(getValues());
        }}
      />
    </>
  );
};
export default CreateCouponStepOne;

import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CouponGeneral from '../../../components/coupon/couponCreation/CouponGeneral';
import './CreateCouponStepOne.scss';
import CouponKeyVisual from '../../../components/coupon/couponCreation/CouponKeyVisual';
import CouponInstructionSection from '../../../components/coupon/couponCreation/CouponInstructionSection';
import CouponDefaultPreview from '../../../assets/images/coupon/preview/SingleCoupon_default.png';
import CouponGeneralName from '../../../assets/images/coupon/preview/SingleCoupon_title.png';
import CouponGeneralBrand from '../../../assets/images/coupon/preview/SingleCoupon_brand.png';
import CouponGeneralStore from '../../../assets/images/coupon/preview/SingleCoupon_store.png';
import CouponKeyVisualImage from '../../../assets/images/coupon/preview/SingleCoupon_image.png';
import CouponInstructionTitle from '../../../assets/images/coupon/preview/SingleCoupon_instructionTitle.png';
import CouponInstructionContent from '../../../assets/images/coupon/preview/SingleCoupon_instructionContent.png';
import CouponDetailTitle from '../../../assets/images/coupon/preview/SingleCoupon_detailTitle.png';
import CouponDetailContent from '../../../assets/images/coupon/preview/SingleCoupon_detailContent.png';
import ContentSections from '../../../components/base/ContentSections';
import CouponDetailSection from '../../../components/coupon/couponCreation/CouponDetailSection';
import { ContinueWithBackButtons } from '../../../components/base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { validate } from './CreateCouponValidate';

const inputWithImageConfig = {
  name: CouponGeneralName,
  relatedBrand: CouponGeneralBrand,
  selectedStores: CouponGeneralStore,
  coverPhoto: CouponKeyVisualImage,
  otherPhotos: CouponKeyVisualImage,
  instructionTitle: CouponInstructionTitle,
  instructionContent: CouponInstructionContent,
  detailsTitle: CouponDetailTitle,
  detailsContent: CouponDetailContent,
};
const defaultImage = CouponDefaultPreview;

const CreateCouponStepTwo = ({
  languages,
  storeLoading,
  storeEditMode,
})  => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(null);
  const { getValues, setError, clearErrors } =
    useFormContext();

  // const parseDataArray = () => {
  //   let formatData = {};
  //   this.dataArray.forEach((item) => {
  //     const language = item.language;
  //     const data = item.data;
  //     const translations = formatData.translations;
  //     const formDataWithLanguage = translations?.[language] || {};
  //     formatData = {
  //       ...formatData,
  //       translations: {
  //         ...translations,
  //         [language]: {
  //           ...formDataWithLanguage,
  //           ...data,
  //         },
  //       },
  //     };
  //   });
  //   return formatData;
  // };


  const getTabs = (language) => {
    return [
      <CouponGeneral 
        language={language} 
        onFocus={setActiveSection}
      />,
      <CouponKeyVisual
        onFocus={setActiveSection}
        language={language}
      />,
      <CouponInstructionSection
        language={language}
        onFocus={setActiveSection}
      />,
      <CouponDetailSection
        language={language}
        onFocus={setActiveSection}
      />,
    ];
  };
  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    
    dispatch({
      type: 'createCoupon/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };
  return (
    <div>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: getTabs(item.code),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultImage}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons 
        disabledContinue={storeLoading}
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </div>
  );
}


const mapPropsToState = (state) => ({
  languages: state.language.allList,
  storeLoading: state.storeModel.allListLoading,
  storeEditMode: state.storeModel.storeEditMode,
})

export default connect(mapPropsToState)(CreateCouponStepTwo);

import React, { useState, useEffect } from 'react';
import './NextStepPrompt.scss';
import { Modal } from 'react-bootstrap';
import PromptButton from './BaseButtons';
import { BUTTON_TYPES } from './BaseButtons';
import NextStepItem from './NextStepItem';

/**
 * buttons: array of button, {
 *  *  {
 *      text: "close",
 *      action: () => {},
 *      class: "button-class",
 *      requires: PermissionCodes
 * }
 * steps={{
          stepNames: [
            'Create store category',
            'Create store',
            'Create coupon campaign',
            'Notify your Customer',
          ],
          current: 'Create store',
        }}
 * }
 * @param {*} props
 */

function NextStepPrompt(props) {
  const steps = props.steps || {};
  const buttons = props.buttons || [];
  const parentShow = props.show;
  const title = props.title;
  const description = props.description;

  const stepNames = steps.stepNames;
  const current = steps.current;

  const [show, setShow] = useState(false);
  const onHide = () => {
    if (props?.onHide) {
      props.onHide();
    } else {
      setShow(false);
    }
  };
  const buttonAction = (button) => {
    setShow(false);
    if (button?.action) {
      button.action();
    }
  };

  const getButton = (button, defaultType, extraClass) => {
    if (!button) {
      return null;
    }
    button.class = `${extraClass}`;
    return (
      <PromptButton
        title={button.text}
        action={() => buttonAction(button)}
        requires={button.requires}
        className={button.class}
        type={button.type || defaultType}
      />
    );
  };

  useEffect(() => {
    setShow(parentShow);
  }, [parentShow]);

  const body = () => {
    return (
      <>
        {title ? <div className="next-step-header">{title}</div> : null}
        {description ? (
          <div className="next-step-description">{description}</div>
        ) : null}
        <div className="next-step-buttons">
          {buttons.map((button, index) =>
            getButton(
              button,
              index === 0 ? BUTTON_TYPES.main : BUTTON_TYPES.link,
              index === 0 ? 'next-step-first-button' : 'next-step-link-button',
            ),
          )}
        </div>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="next-step-prompt-container">
        <NextStepItem stepNames={stepNames} current={current} />
        {body()}
      </Modal.Body>
    </Modal>
  );
}

export default NextStepPrompt;

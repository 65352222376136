import { saveAs } from 'file-saver';

const saveFile = (url, data, downloadFileName) => {
  const fileSuffix = url?.split('.')?.slice(-1);
  const fileNmme = downloadFileName
    ? `${downloadFileName}${fileSuffix ? '.' + fileSuffix : ''}`
    : url.substr(url.lastIndexOf('/') + 1);
  saveAs(data, fileNmme);
};

export const downloadZipFiles = (url, downloadFileName = '') => {
  if (url?.includes('.zip')) {
    saveFile(url, url, downloadFileName);
    return;
  }
  fetch(url, { cache: 'no-cache' })
    .then((response) => response.blob())
    .then((blob) => {
      console.log('response blob:', blob);
      saveFile(url, blob, downloadFileName);
    })
    .catch((error) => console.log('save error:', error));
};

import { BaseHelper } from './BaseGraphQLAPIHelper';

const NODE_CONTENT = `{
  id
  pk
  name
}`;

export const getFillingFormList = (afterCursor, moreSearch) => {
  console.log("@10getFillingFormList", )
  let filter = '';
  if (moreSearch.filterName || moreSearch.search) {
    filter = `, nameIcontains:"${moreSearch.filterName || moreSearch.search}"`;
  }
  
  let orderBy = moreSearch.rank ? '-pk' : 'pk';

  const query = `{
    fillingForms(first: ${moreSearch?.isAll ? 100 : 20}, after:"${afterCursor}", orderBy:"${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${NODE_CONTENT}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getFillingForm = (id) => {
  const query = `{
    fillingForm(id: "${id}") ${NODE_CONTENT}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

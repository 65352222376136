import React, { useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { withResizeDetector } from 'react-resize-detector';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomErrorMessage, hasReactHookFormError } from '../base/ErrorFieldMessage';

function DropdownToMutipleSelector({
  width, title, mutipleSelector, fieldBaseName,
}) {
  const { control, setValue, watch, formState: { errors } } = useFormContext();
  const lookupFieldName = `${fieldBaseName}.lookup`;
  const selectedFieldName = `${fieldBaseName}.selected`;
  const lookup = watch(lookupFieldName) || 'Please select';
  const [showSelector, setShowSelector] = useState(
    lookup.toLowerCase() === 'in',
  );
  const lookups =
    title === 'Level' ? ['In'] : ['In', 'Is empty', 'Is not empty'];
  const dropDownOptions = lookups.map((lookupItem) => ({
    name: lookupItem,
    action: () => {
      setShowSelector(lookupItem.toLowerCase() === 'in');
      setValue(lookupFieldName, lookupItem, { shouldDirty: true, shouldValidate: true });
    },
  }));
  const mutipleSelecteOptions = mutipleSelector.options;
  const defaultSelected = watch(selectedFieldName);

  return (
    <div style={{ width: '100%' }}>
      <CustomTitleLabel title={title} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginBottom: 15 }}>
          <Controller
            control={control}
            name={lookupFieldName}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <>
                <DropDownWithDefaultSelect
                  actions={dropDownOptions}
                  defaultValue={lookup}
                  className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
                />
                <CustomErrorMessage name={field.name} />
              </>
            )}
          />
        </div>
        {showSelector ? 
          <Controller
            control={control}
            name={selectedFieldName}
            shouldUnregister
            rules={{ required: 'Please select at least one item' }}
            render={({field}) => (
              <div style={width < 972 ? { width: '100%' } : { flex: 1 }}>
                <BaseMultipleSelectorV2
                  data={{
                    sourceData: mutipleSelecteOptions,
                    targetData: defaultSelected || [],
                    targetChange: (options) => {
                      setValue(field.name, options, { shouldDirty: true, shouldValidate: true });
                    },
                  }}
                  sourceReminderText={'Please select item(s)'}
                  amidReminderText={'Please select item(s)'}
                  error={{
                    error: hasReactHookFormError(errors, field.name),
                    message: 'Please select at least one item',
                  }}
                // isReset={isReset}
                />
              </div>
            )}
          /> : null}
      </div>
    </div>
  ); 
}

export default withResizeDetector(DropdownToMutipleSelector);

import React, {useState } from "react";
import { Image } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";

import BranchLine from 'assets/images/branchLinex3.png';
import { DropDownWithDefaultSelect } from "components/base/ActionsDropdown";
import { CustomTitleLabel } from "components/earning/CustomBaseComponments";
import { contentEnum } from "models/SegmentModel";
import { hasReactHookFormError } from '../base/ErrorFieldMessage';
import { CustomTitleWithDropDown } from "components/customer/CustomTitleWithDropDown";


export const additionDataLookupConfig = {
  emptyOption: [
    'Is empty',
    'Is not empty',
  ],
  textValueOption: [
    'Contains text',
    'Does not contain text',
  ],
  numericValueOption: [
    'Equal to',
    'Not equal to',
    'Between',
    'Less than',
    'Greater than',
  ]
}

const mapPropsToState = (state) => ({
  customerAdditionalDataFields: state.customerList.customerAdditionalDataFields,
  transactionAdditionalDataFields: state.transactions.transactionAdditionalDataFields,
  purchasedItemAdditionalDataFields: state.transactions.purchasedItemAdditionalDataFields,
})

const AdditionalDataSection = ({
  title,
  fieldBaseName,
  customerAdditionalDataFields,
  transactionAdditionalDataFields,
  purchasedItemAdditionalDataFields,
}) => {
  const {
    watch,
    setValue,
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const additionTitleFieldName = `${fieldBaseName}.title`;
  const lookupFieldName = `${fieldBaseName}.lookup`;
  const containTextFieldName = `${fieldBaseName}.containText`;
  const minFieldName = `${fieldBaseName}.minNum`;
  const maxFieldName = `${fieldBaseName}.maxNum`;

  const lookup = watch(lookupFieldName);

  const lookups = [
    ...additionDataLookupConfig.emptyOption,
    ...additionDataLookupConfig.textValueOption,
    ...additionDataLookupConfig.numericValueOption,
  ];

  const additionalDataTypeConfig = {
    [contentEnum.additionalDataFromCustomers]: {
      source: customerAdditionalDataFields,
      action: "customerList/getPagedCustomerAdditionalDataFields"
    },
    [contentEnum.additionalDataFromTransactionRecords]: {
      source: transactionAdditionalDataFields,
      action: "transactions/getPagedTransactionAdditionDataFields"
    },
    [contentEnum.additionalDataFromPurchasedItems]: {
      source: purchasedItemAdditionalDataFields,
      action: "transactions/getPagedPurchasedItemAdditionDataFields"
    },
  }

  const getInputNumberCount = (lookup) => {
    let textInputNum = 0;
    let numInputNum = 0;
    if (!lookup) {
      return {textInputNum, numInputNum};
    }
    if (additionDataLookupConfig.textValueOption.includes(lookup)) {
      textInputNum = 1;
    } else if (additionDataLookupConfig.numericValueOption.includes(lookup)) {
      numInputNum = lookup.toLowerCase() === 'between' ? 2 :1;
    }

    return { textInputNum, numInputNum };;
  };


  const [showInputNumber, setShowInputNumber] = useState(
    getInputNumberCount(lookup),
  );


  const lookupDropDownOptions = lookups.map((lookupItem) => ({
    name: lookupItem,
    action: () => {
      setValue(lookupFieldName, lookupItem, { shouldDirty: true, shouldValidate: true });
      setShowInputNumber(getInputNumberCount(lookupItem));
    },
  }));


  return (
    <div>
      <CustomTitleLabel title={title} />
      <div className="d-flex flex-column">
        <div style={{ display: 'flex' }}>
          <Controller
            name={additionTitleFieldName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <CustomTitleWithDropDown
                  setValue={(value) => {
                    const { value: choiceValue } = value;
                    setValue(
                      field.name,
                      choiceValue || "",
                      { shouldDirty: true },
                    );
                  }}
                  source={additionalDataTypeConfig?.[title]?.source}
                  customItem={(item) => item}
                  defaultValue={{
                    value: field.value,
                    label: field.value,
                  }}
                  loadMoreAction={additionalDataTypeConfig?.[title]?.action}
                  filterAction={additionalDataTypeConfig?.[title]?.action}
                  customClass={
                    hasReactHookFormError(errors, field.name)
                      ? 'error-field'
                      : ''
                  }
                />
              </>
            )}
          />
        </div>
        <div className="display-flex-area">
          <div className="d-flex" style={{ alignItems: "flex-end" }}>
            <Image src={BranchLine} className="content-campaign-branchLine" />
            <Controller
              control={control}
              name={lookupFieldName}
              rules={{ required: true }}
              render={({ field }) => (
                <DropDownWithDefaultSelect
                  actions={lookupDropDownOptions}
                  defaultValue={lookup}
                  className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
                  splitAfterIndex={[1,3]}
                />
              )}
            />
            <input
              className={`custom-number-input-short ${hasReactHookFormError(errors, containTextFieldName) ? 'error-field' : ''
                }`}
              {...register(containTextFieldName, { required: showInputNumber?.textInputNum >= 1 })}
              hidden={showInputNumber?.textInputNum < 1}
            />
            <input
              type="number"
              className={`custom-number-input-short ${hasReactHookFormError(errors, minFieldName) ? 'error-field' : ''
                }`}
              {...register(minFieldName, { required: showInputNumber?.numInputNum >= 1 })}
              hidden={showInputNumber?.numInputNum < 1}
            />
            <label
              className="help-message margin-left-10-percent margin-right-10-percent"
              hidden={showInputNumber?.numInputNum < 2}
            >
              and
            </label>
            <input
              type="number"
              className={`custom-number-input-short ${hasReactHookFormError(errors, maxFieldName) ? 'error-field' : ''
                }`}
              {...register(maxFieldName, { required: showInputNumber?.numInputNum >= 2 })}
              hidden={showInputNumber?.numInputNum < 2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapPropsToState)(AdditionalDataSection);

import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import EditCustomerAdditionalDataModal from 'components/customerDetail/EditCustomerAdditionalDataModal';
import {
  TitleWithButton,
  sortAdditionalFieldName,
} from 'components/customerDetail/utils';

import CustomerEdit from 'assets/images/customer_edit.svg';
import { PermissionCodes } from 'config/PermissionCodes';

const mapPropsToState = (state) => ({
  userCustomData: state.customerList.customer?.userCustomData,
  customerAdditionalDataFields: state.customerList.customerAdditionalDataFields,
});

const CustomerAdditionalData = ({
  userCustomData = {},
  customerAdditionalDataFields = [],
}) => {
  const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false);

  const fields = [];
  const sortedFieldNames = sortAdditionalFieldName(
    userCustomData,
    customerAdditionalDataFields,
  );
  for (const fieldName of sortedFieldNames) {
    fields.push([{ title: fieldName, field: fieldName }]);
  }

  return (
    <>
      <TitleWithButton
        containerExtraClassName="customer-detail-section-title-container"
        title="CUSTOMER ADDITIONAL DATA"
        titleExtraClassName="customer-detail-section-title"
        firstButtonImage={CustomerEdit}
        firstButtonContent="Edit"
        firstButtonOnClick={() => setShowAdditionalDataModal(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
      />
      <MembershipInfoCard data={userCustomData} fields={fields} />
      <EditCustomerAdditionalDataModal
        sortedFieldNames={sortedFieldNames}
        show={showAdditionalDataModal}
        setShow={setShowAdditionalDataModal}
      />
    </>
  );
};

export default connect(mapPropsToState)(CustomerAdditionalData);

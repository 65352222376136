import { ActionType, CampaignType } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

const SimpleListFieldNode = `
node {
  pk
  id
  type
  name
}
`;

const PageListNode = `
node {
  pk
  id
  type
  name
  messageChannels
  brand {
    pk
    name
  }
  numberOfCustomersVisible
  startDate
  endDate
  displayStartDate
  displayEndDate
  approvalStatus
  earningCampaignTypeEarningRule {
    pk
    name
    type
  }
  translations {
    edges {
      node {
        language
      }
    }
  }
}
`;

const ListFieldNode = `
node {
  pk
  id
  type
  name
  approvalStatus
  startDate
  endDate
  displayStartDate
  displayEndDate
  lastModifiedDate
  publicationDate
  displayPriority
  status
  coverPhoto
  brand {
    pk
    name
  }
  stores {
    edges {
      node {
        pk
        name
      }
    }
  }
  numberOfCustomersVisible
  messageChannels
  translations {
    edges {
      node {
        language
        name
      }
    }
  }
}
`;

export const getCampaignList = (afterCursor, filter) => {
  let {
    type,
    reverse,
    filterName,
    ssoUid,
    isAvailable,
    isSimpleList,
    isPageList,
    others,
  } = filter;

  if (!filterName) {
    filterName = '';
  }
  if (!type) {
    type = 'AllTypes';
  }

  let userFilter = '';
  if (ssoUid) {
    userFilter = `, isAvailable: true, customerSsoUid: "${ssoUid}"`;
  }

  if (!ssoUid && isAvailable) {
    userFilter = `, isAvailable: true`;
  }
  // if (others.moreSearch) {
  //   userFilter += ', ' + others.moreSearch;
  // }


  if (others?.searchNameIdKey) {
    userFilter += `, searchNameOrId: "${others?.searchNameIdKey}"`;
  }
  if (others?.status) {
    userFilter += `, approvalStatusIn: ${getInFilterValue(others.status)}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others.expired}`;
  }
  if (others?.message_channel) {
    userFilter += `, messageChannelsIn: ${getInFilterValue(
      others.message_channel,
    )}`;
  }
  if (others?.brand) {
    userFilter += `, brandIn: ${getInFilterValue(others.brand)}`;
  }
  if (others?.active_period) {
    const activePeriod = others.active_period.split(',');
    userFilter += `, startDateGte: "${activePeriod[0]}", endDateLte: "${activePeriod[1]}"`;
  }
  if (others?.visable_period) {
    const visablePeriod = others.visable_period.split(',');
    userFilter += `, displayStartDateGte: "${visablePeriod[0]}", displayEndDateLte: "${visablePeriod[1]}"`;
  }
  if (others?.target_customer) {
    if (others[others.target_customer])
      userFilter += `, ${others.target_customer}: ${getInFilterValue(
        others[others.target_customer],
      )}`;
  }
  let orderBy = reverse ? '-pk' : 'pk';
  if (others?.is_featured) {
    orderBy = 'displayPriority';
    userFilter += `, isFeatured: true`;
  }
  if (others?.is_origin) {
    userFilter += `, isOriginCampaign: true`;
  }
  if (others?.sort) {
    orderBy = others.sort;
  }

  if (others?.campaignIds) {
    userFilter += `, idIn: ${getInFilterValue(others.campaignIds)}`;
  } else if (others?.all || others?.isAll || filter?.isAll) {
    userFilter += `, first: ${filter?.pageSize || 100}`;
  } else {
    userFilter += `, first: 20`;
  }

  const allType =
    type === 'AllTypes'
      ? [
        CampaignType.couponsCampaign,
        CampaignType.earningRulesCampaign,
        CampaignType.generalMessageCampaign,
        CampaignType.stampCampaign,
      ]
      : [
        CampaignType.couponsCampaign,
        CampaignType.earningRulesCampaign,
        CampaignType.generalMessageCampaign,
      ];
  let node = ListFieldNode;
  if (isPageList) {
    node = PageListNode;
  } else if (isSimpleList) {
    node = SimpleListFieldNode;
  }
  const query = `{
      campaigns(
        after:"${afterCursor}",
        orderBy: "${orderBy}",
        nameIcontains:"${filterName}",
        type: ${type === 'AllTypes'
      ? getInFilterValue(allType)
      : getInFilterValue(type)
    }
        ${userFilter}
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          ${node}
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaign = (id) => {
  const query = `{
    campaign(id: "${id}") {
      id
      pk
      type
      name
      creationDate
      lastModifiedDate
      shortDescription
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      startDate
      endDate
      blackoutPeriod {
        edges{
          node {
            pk
            startDate
            endDate
          }
        }
      }
      blackoutWeekday
      displayStartDate
      displayEndDate
      isFeatured
      isExclusive
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      applySquareCoverPhotoToAllLanguage
      approvalStatus
      publicationDate
      coverPhoto
      squareCoverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      language
      displayPriority
      hideInAnyChannel
      publishChannel
      categories {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      targetedCustomerGroups {
        edges {
          node {
            pk
            name
            id
          }
        }
      }
      targetedSegments {
        edges {
          node{
            pk
            name
            id
          }
        }
      }
      translations {
        edges {
          node {
            id
            pk
            name
            shortDescription
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            coverPhoto
            squareCoverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            language
          }
        }
      }
      couponCampaignTypeCouponTemplate {
          id
          pk
          name
          totalNumberOfGeneratedCoupons
      }
      couponCampaignTypeShouldShowCouponStock
      couponCampaignTypeOverallLimit
      couponCampaignTypePerHeadLimit
      couponCampaignTypeRequiredPoints
      stampCampaignTypeEmptySlotStampImage
      stampCampaignTypeAcquiredStampImage
      stampCampaignTypeEmptyGiftSlotImage
      stampCampaignTypeCollectedGiftImage
      stampRewards{
        edges{
            node{
              id
              pk
              rewardType
              requiredStamps
              quantity
              couponTemplate{
                  id
                  pk
                  name
              }
            }
        }
      }
      stampTaskRelation{
        edges{
          node{
            id
            pk
            displayOrder
            earningRule{
              id
              pk
              type
              name
            }
          }
        }
      }
      earningCampaignTypeEarningRule {
          id
          pk
          name
          type
          generalPurchaseTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
          memberReferralTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
          newMemberTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
          birthdayTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
          qrCodeScanningTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
          gpsCheckInTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
          fillingFormTypeCouponRewardTypeCouponTemplate {
            id
            pk
            name
          }
      }
      brand {
          id
          pk
          name
      }
      stores {
        edges {
          node {
            pk
            name
          }
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignCategoryList = ({ search }) => {
  let searchString = '';
  // console.log('@@225: ', search);
  // if (search.isActive) {
  //   searchString += `, is_active: ${search.isActive}`;
  // }
  const query = `{
    campaignCategories(first: 20 ${searchString}) {
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaignCategory = (input) => {
  const query = `mutation CreateCampaignCategory($input: CreateCampaignCategoryInput!) {
    createCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaign = (campaign) => {
  const query = `mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      node {
        id
        pk
        type
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const publishCampaign = (input) => {
  const query = `mutation PublishCampaign($input: PublishCampaignInput!) {
    publishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const unPublishCampaign = (input) => {
  const query = `mutation UnpublishCampaign($input: UnpublishCampaignInput!) {
    unpublishCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCampaign = (ids) => {
  const query = `mutation DeleteCampaigns($input: DeleteCampaignsInput!) {
    deleteCampaigns(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

// export const deleteCampaignTranslation = (ids) => {
//   const query = `mutation DeleteCampaignTranslation($input: DeleteCampaignTranslationInput!) {
//     deleteCampaignTranslation(input: $input) {
//       success
//     }
//   }
//   `;
//   const variables = ids.map((item) => {
//     return {
//       query: query,
//       variables: {
//         input: {
//           id: item,
//         },
//       },
//     };
//   });
//   return BaseHelper.callGraphQLAPIMultiple(variables);
// };

// export const deleteCampaignBrand = (ids) => {
//   const query = `mutation DeleteCampaignBrandSection($input: DeleteCampaignBrandSectionInput!) {
//     deleteCampaignBrandSection(input: $input) {
//       success
//     }
//   }
//   `;
//   const variables = ids.map((item) => {
//     return {
//       query: query,
//       variables: {
//         input: {
//           id: item,
//         },
//       },
//     };
//   });
//   return BaseHelper.callGraphQLAPIMultiple(variables);
// };

export const duplicateCampaign = (id) => {
  const query = `
  mutation CopyCampaign($input: CopyCampaignInput!) {
    copyCampaign(input: $input) {
      success
      node {
        pk
        name
        isFeatured
        displayPriority
      }
    }
  }
  `;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCampaign = (input) => {
  const query = `mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      success
      node {
        id
        pk
        name
        type
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateFeaturedCampaignOrder = (input) => {
  const query = `
    mutation UpdateFeaturedCampaignOrder($input: UpdateFeaturedCampaignOrderInput!) {
      updateFeaturedCampaignOrder(input: $input) {
        success
        node {
          id
          pk
          name
          type
          displayPriority
        }
      }
    }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      id
      pk
      name
      type
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      generalPurchaseTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      memberReferralTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      newMemberTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      birthdayTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      qrCodeScanningTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      gpsCheckInTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      fillingFormTypeCouponRewardTypeCouponTemplate {
        id
        pk
        name
      }
      startDate
      endDate
      isExclusive
      targetedCustomerGroups {
        edges {
          node {
            pk
            name
          }
        }
      }
      targetedSegments {
        edges {
          node {
            pk
            name
          }
        }
      }
      translations {
        edges {
          node {
            name
            language
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCoupon = (id) => {
  const query = `
  {
    couponTemplate(id: "${id}") {
      id
      pk
      name
      totalNumberOfGeneratedCoupons
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      shortDescription
      translations {
        edges {
          node {
            name
            language
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            shortDescription
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStampCampaignList = (afterCursor, filter) => {
  let { type, reverse, filterName, ssoUid, isAvailable, others } = filter;
  if (!filterName) {
    filterName = '';
  }
  if (!type) {
    type = '';
  }
  let userFilter = '';
  if (ssoUid) {
    userFilter = `, isAvailable: true, customerSsoUid: "${ssoUid}"`;
  }

  if (!ssoUid && isAvailable) {
    userFilter = `, isAvailable: true`;
  }

  // if (others.moreSearch) {
  //   userFilter += ', ' + others.moreSearch;
  // }

  if (others?.status) {
    userFilter += `, approvalStatusIn: ${getInFilterValue(others.status)}`;
  }
  if (others?.expired) {
    userFilter += `, isExpired: ${others.expired}`;
  }
  if (others?.message_channel) {
    userFilter += `, messageChannelsIn: ${getInFilterValue(
      others.message_channel,
    )}`;
  }
  if (others?.brand) {
    userFilter += `, brandIn: ${getInFilterValue(others.brand)}`;
  }
  if (others?.active_period) {
    const activePeriod = others.active_period.split(',');
    userFilter += `, startDateGte: "${activePeriod[0]}", endDateLte: "${activePeriod[1]}"`;
  }
  if (others?.visable_period) {
    const visablePeriod = others.visable_period.split(',');
    userFilter += `, displayStartDateGte: "${visablePeriod[0]}", displayEndDateLte: "${visablePeriod[1]}"`;
  }
  if (others?.target_customer) {
    if (others[others.target_customer])
      userFilter += `, ${others.target_customer}: ${getInFilterValue(
        others[others.target_customer],
      )}`;
  }
  let orderBy = reverse ? '-pk' : 'pk';
  if (others?.is_featured) {
    orderBy = 'displayPriority';
    userFilter += `, isFeatured: true`;
  }
  if (others?.sort) {
    orderBy = others.sort;
  }

  if (others?.all || others?.isAll || filter?.isAll) {
    userFilter += `, first: ${filter?.pageSize || 100}`;
  } else {
    userFilter += `, first: 20`;
  }
  const query = `{
    campaigns(after:"${afterCursor}", orderBy: "${orderBy}", nameIcontains:"${filterName}", type: ["${CampaignType.stampCampaign}"] ${userFilter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          pk
          id
          type
          name
          startDate
          endDate
          displayStartDate
          displayEndDate
          approvalStatus
          lastModifiedDate
          publicationDate
          displayPriority
          status
          coverPhoto
          brand {
            pk
            name
          }
          stores {
            edges {
              node {
                pk
                name
              }
            }
          }
          numberOfCustomersVisible
          messageChannels
          translations {
            edges {
              node {
                language
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const updateApprovalStatus = (input, approvalStatus, actionType) => {
  let mutation = null;
  switch (actionType) {
    case ActionType.publish:
      mutation = 'PublishCampaign';
      break;
    case ActionType.unpublish:
      mutation = 'UnpublishCampaign';
      break;
    case ActionType.approval:
      mutation = 'PublishCampaign';
      break;
    case ActionType.submitForApproval:
      mutation = 'PendingCampaign';
      break;
    case ActionType.withdraw:
      mutation = 'WithdrawPendingCampaign';
      break;
    default:
      mutation = null;
      break;
  }
  console.log('@663 mutation', mutation);
  if (!mutation) {
    return;
  }

  const query = `mutation ${mutation}($input: ${mutation}Input!) {
    ${mutation.charAt(0).toLowerCase()}${mutation.slice(1)}(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const CreateTempCampaign = (campaign) => {
  const query = `mutation CreateTempCampaign($input: CreateTempCampaignInput!) {
    createTempCampaign(input: $input) {
      node {
        id
        pk
        type
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectPendingForApprovalCampaign = (input) => {
  const query = `
  mutation rejectPendingCampaign($input: RejectPendingCampaignInput!) {
    rejectPendingCampaign(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerDetailCampaignList = ({
  isStamp,
  afterCursor,
  customerId,
  filterName,
  categoryList,
}) => {
  const type = isStamp
    ? '["STAMP_CAMPAIGN"]'
    : '["COUPON_CAMPAIGN","EARNING_CAMPAIGN","GENERAL_MESSAGE_CAMPAIGN"]';

  const orderBy = isStamp
    ? 'display_end_date'
    : '-display_start_date,-start_date,end_date,name,-pk'

  let filter = 'isAvailable: true';

  if (isStamp) {
    filter += `, stampRewardStatusCustomerId: ${customerId}`;
  } else {
    filter += `, isOnlyForYouCustomerId: ${customerId}`;
  }

  if (filterName) {
    filter += `, nameIcontains:"${filterName}"`;
  };

  if (categoryList?.length > 0) {
    filter += `, campaignCategories: ${getInFilterValue(categoryList)}`;
  };

  const query = `{
      campaigns(
        first: 20,
        after:"${afterCursor}",
        orderBy: "${orderBy}",
        type: ${type},
        ${filter}
      ) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            pk
            name
            coverPhoto
            brand {
              pk
              name
            }
            totalStampsEarned
            maxRequiredStamps
          }
        }
      }
    }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}


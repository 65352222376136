import React, { useState, forwardRef, useEffect } from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import './FilterableDropdown.scss';
import Search from '../../assets/images/drop_down_filter.svg';
import { CheckboxItem } from './BaseFilter';
import { removeElementFromArray } from '../../utils';
export const CustomToggle = forwardRef(
  ({ children, onClick, disableDropDown }, ref) => (
    <div
      className="filterable-toogle-area"
      style={{
        opacity: disableDropDown ? '60%' : '100%',
        padding: 0,
        display: 'flex',
        border: 'none',
      }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ),
);

export const CustomMenu = forwardRef(
  (
    {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      isMultipleSelect,
      filteredList,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="filterable-dropdown-menu-select-area">
          {React.Children.toArray(children).filter((child) => {
            return isMultipleSelect
              ? filteredList.includes(child.props.children.props.title)
              : filteredList.includes(child.props.children);
          })}
        </ul>
      </div>
    );
  },
);

function FilterableDropdownWithoutLabel(props) {
  const filterButtons = props.filterButtons || [];
  const placeholder = props.placeholder || 'Search for...';
  const customClass = props.customClass;
  const onSelectChange = props.selectChange || (() => {});
  const onToggle = props.onToggle || (() => {});

  const muitipleSelect = props.muitipleSelect || false;
  const toolTips = props.toolTips || false;

  const [options, setOptions] = useState(props.options);
  const [value, setValue] = useState(
    muitipleSelect
      ? props.defaultValue?.map((item) => item?.value?.pk) || []
      : props.defaultValue?.label,
  );
  const [isOpen, setIsOpen] = useState();
  const isPromptDropdown = props.isPromptDropdown || false;
  const disableAutoFocus = props.disableAutoFocus || false;
  const disableDropDown = props.disableDropDown || false;

  const filterAction = props.filterAction || (() => {});
  const loadMoreAction = props.loadMoreAction || (() => {});
  // const [isLoadMore, setIsLoadMore] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [filterBtn, setFilterBtn] = useState('');
  const [filteredList, setfilteredList] = useState(
    options.map((item) => item.label),
  );
  const getFilteredList = (filter1, filter2) => {
    filterAction(filter1);
    // setIsLoadMore(false);
  };

  useEffect(() => {
    setfilteredList(options.map((item) => item.label));
  }, [options]);

  useEffect(() => {
    if (muitipleSelect) {
      setValue(props.defaultValue?.map((item) => item?.value?.pk));
    } else {
      setValue(props.defaultValue?.label || props.defaultValue?.value?.label);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);
  
  useEffect(() => {
    const filter = setTimeout(() => {
      getFilteredList(searchKey);
    }, 1000);
    return () => {
      clearTimeout(filter);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  return (
    <Dropdown
      show={isOpen}
      onToggle={(isOpen, e, metadata) => {
        if (disableDropDown) {
          setIsOpen(false);
        } else if (metadata.source !== 'select' || !muitipleSelect) {
          setIsOpen(isOpen);
        }
        onToggle();
      }}
      className={`${
        isPromptDropdown
          ? 'filterable-dropdown-area'
          : 'filterable-dropdown-area-prompt'
      } ${customClass}`}
      onScroll={(event) => {
        if (
          event.nativeEvent.target.scrollTop +
            event.nativeEvent.target.clientHeight ===
          event.nativeEvent.target.scrollHeight
        ) {
          loadMoreAction();
          // setIsLoadMore(true);
        }
      }}
    >
      <Dropdown.Toggle as={CustomToggle} disableDropDown={disableDropDown}>
        <>
          <Image
            src={Search}
            className="filterable-search-bar-icon"
            style={{ left: '10px' }}
          />
          <input
            autoFocus={!disableAutoFocus}
            className="filterable-search-bar-input"
            placeholder={placeholder}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
            value={searchKey}
          />
          {filterButtons && filterButtons.length > 0 ? (
            <div className="filter-buttons-bar">
              {filterButtons.map((button) => {
                return (
                  <button
                    className={`filter-btn ${
                      filterBtn === button ? 'filter-btn-selected' : ''
                    }`}
                    onClick={() => {
                      setFilterBtn(button);
                      getFilteredList(value, button);
                    }}
                  >
                    {button}
                  </button>
                );
              })}
            </div>
          ) : null}
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="filterable-dropdown-menu"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          margin: 0,
          right: 'auto',
          bottom: 'auto',
        }}
        isMultipleSelect={muitipleSelect}
        filteredList={filteredList}
      >
        {options.map((option, index) => (
          <Dropdown.Item
            key={`${index}-${option.label}`}
            className="filterable-item"
            onSelect={(eventKey) => {
              if (!muitipleSelect) {
                onSelectChange(option);
                setValue(option.label);
              } else {
                let newValue = [];
                let selectValue = [];
                console.log('@@225-1: ', value);
                if (value?.indexOf(option.value?.pk) >= 0) {
                  newValue = removeElementFromArray(value, option.value?.pk);
                  selectValue = props.defaultValue.filter(
                    (item) => item.value?.pk !== option.value?.pk,
                  );
                } else {
                  newValue = [...value, option.value?.pk];
                  selectValue = [...props.defaultValue, option];
                }
                console.log('@@225: ', newValue, value, selectValue);
                setValue(newValue);
                onSelectChange(selectValue);
              }
            }}
            eventKey={index}
          >
            {muitipleSelect ? (
              <CheckboxItem
                index={index}
                title={option.label}
                defaultValue={value?.indexOf(option.value?.pk) >= 0}
                onChange={() => {}}
                needNoMargin={false}
                toolTips={toolTips}
              />
            ) : (
              option.label
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterableDropdownWithoutLabel;

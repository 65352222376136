import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { InsertParamDropdown } from 'components/base/ActionsDropdown';
import { hasError } from 'components/base/ErrorFieldMessage';

import './WhatsappContentInlineEditor.scss';

const DisplayItemWithNewLine = ({ itemText }) => {
  const itemList = itemText?.split('\n');
  if (itemList?.length > 1) {
    return (
      <>
        {itemList?.map((item, index) => {
          if (index === 0) {
            return <>{item}</>;
          }
          return (
            <>
              <div className="next-line" />
              {item}
            </>
          );
        })}
      </>
    );
  }
  return <>{itemText}</>;
};

export const WhatsappContentInlineDisplay = ({
  customStyle,
  contentText,
  textArgs,
}) => {
  if (!contentText) {
    return null;
  }
  const elementList = [];
  const itemList = contentText?.split(/{{\d+}}/);
  if (itemList?.length > 1) {
    for (let i = 0; i < itemList.length; i++) {
      const item = itemList[i];
      if (i > 0 && textArgs?.[i - 1]) {
        elementList.push(textArgs[i - 1]);
      }
      elementList.push(item);
    }
    return (
      <div className={`create-section-inline-editor ${customStyle}`}>
        <DisplayItemWithNewLine itemText={elementList.join(' ')} />
      </div>
    );
  }
  return (
    <div className={`create-section-inline-editor ${customStyle}`}>
      <DisplayItemWithNewLine itemText={contentText} />
    </div>
  );
};

const WhatsappContentInlineEditor = ({
  customStyle,
  language,
  baseFieldName,
  contentText,
}) => {
  const { clearErrors, getValues, watch, setValue, formState } =
    useFormContext();
  const { errors } = formState;

  if (!contentText) {
    return null;
  }
  const itemList = contentText?.split(/{{\d+}}/);
  if (!!baseFieldName && itemList?.length > 1) {
    return (
      <div className={`create-section-inline-editor ${customStyle}`}>
        {itemList.map((item, index) => {
          if (index === 0) {
            return <DisplayItemWithNewLine itemText={item} />;
          }
          const fieldName = `${baseFieldName}.${index - 1}`;
          const filedValue = getValues(fieldName);
          if (filedValue === null) {
            // console.log('kevin@214 filedValue empty', fieldName, filedValue);
            setValue(fieldName, '', { shouldDirty: true });
          }
          return (
            <>
              <InsertParamDropdown
                name={fieldName}
                value={filedValue}
                language={language}
                dropdownClass={
                  hasError(errors, fieldName) ? 'field-has-error' : ''
                }
                setValue={(value) => {
                  setValue(fieldName, value, { shouldDirty: true });
                  clearErrors(fieldName);
                }}
              />
              <DisplayItemWithNewLine itemText={item} />
            </>
          );
        })}
      </div>
    );
  }
  return (
    <div className={`create-section-inline-editor ${customStyle}`}>
      <DisplayItemWithNewLine itemText={contentText} />
    </div>
  );
};

export default WhatsappContentInlineEditor;

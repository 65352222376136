import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import queryString from 'query-string';

import AuthButton from 'components/base/AuthButton';
import { OnlyBackButton } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import Loading from 'components/base/Loading';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import MessageOverviewTableSection from 'components/message/MessageOverviewTableSection';
import {
  APIStatus,
  MessageChannel,
  MessageTag,
} from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction } from 'utils';
import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';

const mapPropsToState = (state) => ({
  selectedMessage: state.messageList.message,
  status: state.loading.status,
  languages: state.language.allList,
});

function MessageOverview({ selectedMessage, status, languages }) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const message = {
    ...selectedMessage,
    displayChannels: selectedMessage.channels?.toString(),
    relatedCampaignName: selectedMessage.relatedCampaign?.pk
      ? `[ID: ${selectedMessage.relatedCampaign?.pk}] ${selectedMessage.relatedCampaign?.name}`
      : '-',
    displayTargetCustomer: (selectedMessage.targetCustomerGroup || [])
      .map((item) => item.name)
      .toString(),
    displayTargetedSegments: (selectedMessage.targetedSegments || [])
      .map((item) => item.name)
      .toString(),
    deliveryDate: formatDate(
      selectedMessage.scheduledDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    displaySendToAll: selectedMessage?.sendToAll ? 'Yes' : 'No',
  };

  useEffect(() => {
    dispatch({
      type: 'messageList/getMessage',
      payload: {
        messagePK: params.id,
      },
    });
    dispatch({
      type: 'language/getList',
      payload: {
        isSelectorLoad: true,
      },
    });
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'createMessage/clearData',
        payload: {},
      });
      dispatch({
        type: 'messageList/clearData',
        payload: {},
      });
    };
  }, [dispatch]);

  const getDisplayPerformanceFields = () => {
    const messageChannelsMap = {
      Inbox: 'inbox',
      SMS: 'sms',
      Whatsapp: 'whatsapp',
      Email: 'email',
      Push: 'push',
    };
    let displayFields = [
      [
        {
          title: `Credit spent`,
          field: `displayCreditSpent`,
        },
      ],
    ];
    let channels = message?.channels;
    if (message?.channels?.indexOf(MessageChannel.all) !== -1) {
      channels = [
        MessageChannel.inbox,
        MessageChannel.push,
        MessageChannel.sms,
        MessageChannel.email,
      ];
    } else if (message?.channels?.indexOf(MessageChannel.allWhatsapp) !== -1) {
      channels = [
        MessageChannel.inbox,
        MessageChannel.push,
        MessageChannel.whatsapp,
        MessageChannel.email,
      ];
    }

    // eslint-disable-next-line no-unused-expressions
    channels?.forEach((channel) => {
      if (channel !== 'SMS' && messageChannelsMap?.[channel]) {
        displayFields.push([
          {
            title: `${channel} click rate (click/opend)`,
            field: `display${messageChannelsMap?.[channel]}ClickRate`,
          },
        ]);
        displayFields.push([
          {
            title: `${channel} open rate (opened/sent)`,
            field: `display${messageChannelsMap?.[channel]}OpenRate`,
          },
        ]);
        return;
      }
      if (channel === 'SMS' && messageChannelsMap?.[channel]) {
        displayFields.push([
          {
            title: `${channel} click rate (click/sent)`,
            field: `display${messageChannelsMap?.[channel]}ClickRate`,
          },
        ]);
      }
    });
    return displayFields;
  };

  const renderTabForLanguage = (language) => {
    return [
      <MessageOverviewTableSection message={message} language={language} />,
      <MembershipInfoCard
        title={'PROPERTIES'}
        data={message}
        fields={[
          [
            {
              title: 'Start with a template',
              field: 'displayIsStartWithTemplate',
            },
          ],
          [
            {
              title: 'Template',
              field: 'displayTemplateName',
            },
          ],
          [
            {
              title: 'Name',
              field: 'name',
            },
          ],
          [
            {
              title: 'Message channel',
              field: 'displayChannels',
              isRichText: true,
            },
          ],
          [
            {
              title: 'Related campaign',
              field: 'relatedCampaignName',
            },
          ],
          [
            {
              title: 'Is it ‘Offer’ type message?',
              field: 'isDirectMarketing',
              isBoolean: true,
            },
          ],
          [
            {
              title: 'Send to every customer (including non-member)',
              field: 'displaySendToAll',
            },
          ],
          [
            {
              title: 'Target customers',
              field: 'displayTargetCustomer',
            },
          ],
          [
            {
              title: 'Target segments',
              field: 'displayTargetedSegments',
            },
          ],
          [{ title: 'Delivery Time', field: 'deliveryDate' }],
        ]}
      />,
      message?.channels?.length > 0 ? (
        <MembershipInfoCard
          title={'PERFORMANCE'}
          data={message}
          fields={getDisplayPerformanceFields()}
        />
      ) : null,
    ];
  };

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: renderTabForLanguage(item.code),
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    selectedMessage.status === MessageTag.sent ? null : (
      <AuthButton
        title="Edit"
        action={() => history.push('edit/')}
        requires={PermissionCodes.changeMessage}
      />
    ),
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: 'View Message',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
      <OnlyBackButton
        backAction={() => {
          dispatch(createAction('messageList/clearMessageState')());
          history.push({
            pathname: '/messages',
            search: queryString.stringify({
              page: 1,
              rank: true,
              search: '',
            }),
          });
        }}
      />
    </>
  );
}

export default connect(mapPropsToState)(MessageOverview);

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreatePointRecordStepOne from '../../../components/pointRecord/CreatePointRecordStepOne';
import CreatePointRecordStepTwo from '../../../components/pointRecord/CreatePointRecordStepTwo';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';


function CreatePointRecord({
  pointRecord,
  stepConfig,
  currentStep,
  pointRecordCreated,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch({
      type: 'createPointRecord/loadRecordFromCookie',
      payload: {}
    });
    return () => {
      dispatch({
        type: 'createPointRecord/clearData',
        payload: {}
      });
    };
  }, [dispatch]);
  const stepSet = [
    <CreatePointRecordStepOne />,
    <CreatePointRecordStepTwo />
  ];
  const caution = {
    detail: '', //Create point record
    title: 'Update Point record',
  };
  return (
    <div class="customer">
      <BaseForm
        defaultValues={pointRecord}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: 'createPointRecord/saveOrRemoveRecordFromCookie',
            payload: {
              save,
              values: getValues(),
            },
          });
        }}
        showFinishPop={pointRecordCreated}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Point records is successfully created.',
          steps: null,
          onHide:()=>{},
          buttons: [
            {
              text: 'Back to point records list',
              action: () =>
                history.push({
                  pathname: '/point_records',
                }),
            },
          ],
        }}
        stepsConfig={stepConfig}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={<CustomBreadcrumb />}
        caution={caution}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  pointRecord: state.createPointRecord.pointRecord,
  stepConfig: state.createPointRecord.stepConfig,
  currentStep: state.createPointRecord.currentStep,
  pointRecordCreated: state.createPointRecord.pointRecordCreated,
  hasUpdatedDefaultValues: state.createPointRecord.hasUpdatedDefaultValues,
  formHasSubmitted: state.createPointRecord.formHasSubmitted,
});

export default connect(mapPropsToState)(CreatePointRecord);

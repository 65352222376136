import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { OnlyBackButton } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import { couponRecordQueryKey } from 'components/pointRecord/RecordsUrlConfig';

import { createAction } from 'utils';

import './CouponTransactionOverview.scss';

function CouponTransactionOverview() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const couponTransactionPK = useParams().id;

  useEffect(() => {
    dispatch(
      createAction('couponTransactions/getSingleCouponTransactionRecord')({
        couponTransactionPK,
      }),
    );
  }, [couponTransactionPK, dispatch]);

  const { record } = useSelector((state) => ({
    record: state.couponTransactions.selectedCouponTransaction,
  }));

  const dataWithType = (couponTransactionType, isStampCampaign) => {
    let differentItems = [];
    switch (couponTransactionType) {
      case 'Customer earned':
        if (isStampCampaign) {
          differentItems = [
            [{ title: 'Campaign ID and name', field: 'displayCampaign' }],
          ];
        } else {
          differentItems = [
            [
              {
                title: 'Earning rule ID and name',
                field: 'displayEarningRule',
              },
            ],
          ];
        }
        break;
      case 'Customer acquired':
        differentItems = [
          [{ title: 'Points spent', field: 'pointsSpent' }],
          [{ title: 'Campaign ID and name', field: 'displayCampaign' }],
        ];
        break;
      case 'Admin added':
      case 'Admin removed':
        break;
      case 'Customer use':
        differentItems = [[{ title: 'Used date', field: 'usedDate' }]];
        break;
      case 'Coupon expired':
        if (record.earningRuleName) {
          differentItems = [
            [{ title: 'Earning rule ID and name', field: 'displayEarningRule' }],
          ];
        } else if (record.pointsSpent) {
          differentItems = [
            [{ title: 'Points spent', field: 'pointsSpent' }],
            [{ title: 'Campaign ID and name', field: 'displayCampaign' }],
          ];
        } else if (record.usedDate) {
          differentItems = [[{ title: 'Used date', field: 'usedDate' }]];
        }
        break;
      default:
        break;
    }
    let data = [
      [{ title: 'Name (preferred name)', field: 'name' }],
      [{ title: 'Membership ID', field: 'membershipId' }],
      [{ title: 'Transaction type', field: 'displayType' }],
      ...differentItems,
      [{ title: 'Coupon ID and name', field: 'displayCoupon' }],
      [{ title: 'Reference ID', field: 'couponReferenceId'}],
      [{ title: 'Expiry date', field: 'couponExpiryDate' }],
      [{ title: 'Record create date', field: 'creationDate' }],
    ];
    if (record.administratorName) {
      data = [
        ...data,
        [({ title: 'Administrator', field: 'administratorName'})]
      ]
    }
    return data;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={record}
                fields={dataWithType(
                  record.displayType,
                  record.isStampCampaign,
                )}
              />,
            ]}
            hidePreview={true}
            sectionStyle="coupon-transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view coupon records
          title: 'View Coupon records',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/coupon_records',
            search: queryString.stringify({
              [couponRecordQueryKey.searchKey]: '',
              [couponRecordQueryKey.page]: 1,
              [couponRecordQueryKey.rank]: true,
              [couponRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default CouponTransactionOverview;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';

function BannerList() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    listFields,
    totalCount,
    checkedList,
    dataList,
    pageInfo,
    totalPage,
  } = useSelector((state) => ({
    listFields: state.bannerList.listDisplayFields,
    dataList: state.bannerList.bannerList,
    pageInfo: state.bannerList.pageInfo,
    totalPage: state.bannerList.totalPage,
    totalCount: state.bannerList.totalCount,
    checkedList: state.bannerList.checkedList,
  }));

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    return function () {
      dispatch({ type: 'bannerList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const querySearch = queryString.parse(history.location.search);
    dispatch(
      createAction('bannerList/getPageBannerList')({
        ...querySearch,
      }),
    );
  }, [dispatch, history.location]);

  const updateBannerList = (pk, order, active = null) => {
    const data = {};
    if (!pk) {
      return;
    }
    data.pk = pk;
    if (active !== null && active !== undefined) {
      data.active = active;
    }
    if (order) {
      data.order = order;
    }
    dispatch(
      createAction('bannerList/createOrUpdateBanner')({
        data,
        afterAction: () => history.push(history.location),
      }),
    );
  };

  const buttons = [
    <AuthButton
      title="Create Banner"
      action={() => {
        history.push('banners/create');
      }}
      requires={PermissionCodes.changeBanner}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeBanner,
    },
  ];

  const tabs = [
    {
      name: 'List of Banners',
      content: (
        <BaseTabListContainer
          hideTab={true}
          maxTabWidth={260}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{ hasFilter: false }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              sortable
              sortDisabled={false}
              rankDisabled
              model={'bannerList'}
              permissionGroup={PermissionCodes.banners}
              deleteInfo={{
                data: [],
                title: 'banner',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.BANNER,
              }}
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) {
                  return;
                }
                const startOrder = dataList[0].order;
                const movedItem = dataList[oldIndex];
                updateBannerList(movedItem.pk, startOrder + newIndex);
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                updateBannerList(pk, newOrder);
              }}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              hasOtherActions
              customActions={{
                moveToFirst: (item) => {
                  updateBannerList(item.pk, 1);
                },
                moveToLast: (item) => {
                  updateBannerList(item.pk, totalCount);
                },
                custom: (item) => {
                  return {
                    name: item.isPublished ? 'UnPublish' : 'Publish',
                    action: (value) => {
                      updateBannerList(value.pk, value.displayPriority, !value.active);
                    },
                    requires: PermissionCodes.changeBanner
                  }
                }
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <div className="banner-list-container">
      <CustomListComponent
        caution={{
          detail:
            'Create a banner to promote your campaign or product. You can drag and drop, or edit the order value to reorder the items.',
          title: NavBarNames.banners,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'banner'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.BANNER}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('bannerList/delete')({
              afterAction: () => {
                history.push(history.location);
              },
            }),
          );
        }}
      />
    </div>
  );
}
export default BannerList;

import React, { useState, useEffect } from 'react';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import './Onboarding.scss';
import CustomSwiper from './CustomSwiper';
import { withResizeDetector } from 'react-resize-detector';
import { useSelector, useDispatch } from 'react-redux';
function Onboarding({ width, height }) {
  const dispatch = useDispatch();
  const firstLogin = useSelector((state) => state.users.firstLogin);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    setShowOnboarding(firstLogin);
  }, [firstLogin]);

  const handleClose = () => {
    setShowOnboarding(false);
    dispatch({ type: 'users/updateState', payload: { firstLogin: false } });
  };


  console.log('@onboarding:', width, height)
  return (
    <Modal
      className="onbarding-slide"
      show={showOnboarding}
      centered
    >
      <Modal.Body
        className={`base-prompt-container prompt-container ${
          width < 950 ? 'horizontal-height' : 'vertical-height'
        }`}
      >
        <Image
          src={CloseIcon}
          className="base-prompt-close-button close-image-index"
          onClick={handleClose}
        />
        {<CustomSwiper width={width || window.screen.width} height={height || window.screen.height} />}
      </Modal.Body>
    </Modal>
  );
}

export default withResizeDetector(Onboarding);

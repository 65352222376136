import {
  LanguageConfig,
  MessageStepConfig,
  MessageWhatsappTemplateType,
} from 'config/CustomEnums';

export const CreateMessageError = {
  content: {
    name: `translations.${LanguageConfig.english}.content`,
    message: 'Please provide a content.',
  },
  titleLengthLimit: {
    name: `translations.${LanguageConfig.english}.name`,
    message: 'Title limit 200 characters.',
  },
  emailTitle: {
    name: `translations.${LanguageConfig.english}.emailTitle`,
    message: 'Please provide a title.',
  },
  emailTitleLengthLimit: {
    name: `translations.${LanguageConfig.english}.emailTitle`,
    message: 'Email title limit 200 characters.',
  },
  emailContent: {
    name: `translations.${LanguageConfig.english}.emailContent`,
    message: 'Please provide a content.',
  },
  smsContent: {
    name: `translations.${LanguageConfig.english}.smsContent`,
    message: 'Please provide a content.',
  },
  scheduledDate: {
    name: 'scheduledDate',
    message: 'Please provide a valid schedule time.',
  },
  channels: {
    name: 'channels',
    message: 'Please provide channels.',
  },
  messageTemplate: {
    name: 'messageTemplate',
    message: 'Please provide a message template.',
  },
  name: {
    name: `name`,
    message: 'Please provide a name.',
  },
};

function firstStepValidate({ getValues, setError }) {
  const [channels, isStartWithTemplate, messageTemplate, name] = getValues([
    'channels',
    'isStartWithTemplate',
    'messageTemplate',
    'name',
  ]);
  const errorList = [];
  if (channels == null || channels.length === 0) {
    errorList.push({
      name: CreateMessageError.channels.name,
      message: CreateMessageError.channels.message,
    });
  }
  if (isStartWithTemplate && !messageTemplate?.value?.pk) {
    errorList.push({
      name: CreateMessageError.messageTemplate.name,
      message: CreateMessageError.messageTemplate.message,
    });
  }

  if (!name) {
    errorList.push({
      name: CreateMessageError.name.name,
      message: CreateMessageError.name.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondPushStepValidate({ getValues, setError }) {
  const errorList = [];
  const [title, content, tcTitle, scTitle] = getValues([
    `translations.${LanguageConfig.english}.name`,
    `translations.${LanguageConfig.english}.content`,
    `translations.${LanguageConfig.traditionalChinese}.name`,
    `translations.${LanguageConfig.simplifiedChinese}.name`,
  ]);
  if (title?.length > 200) {
    errorList.push({
      name: CreateMessageError.titleLengthLimit.name,
      message: CreateMessageError.titleLengthLimit.message,
    });
  }
  if (tcTitle?.length > 200) {
    errorList.push({
      name: `translations.${LanguageConfig.traditionalChinese}.name`,
      message: CreateMessageError.titleLengthLimit.message,
    });
  }
  if (scTitle?.length > 200) {
    errorList.push({
      name: `translations.${LanguageConfig.simplifiedChinese}.name`,
      message: CreateMessageError.titleLengthLimit.message,
    });
  }
  if (!content) {
    errorList.push({
      name: CreateMessageError.content.name,
      message: CreateMessageError.content.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondEmailStepValidate({ getValues, setError }) {
  const errorList = [];
  const [title, content, tcTitle, scTitle] = getValues([
    `translations.${LanguageConfig.english}.emailTitle`,
    `translations.${LanguageConfig.english}.emailContent`,
    `translations.${LanguageConfig.traditionalChinese}.emailTitle`,
    `translations.${LanguageConfig.simplifiedChinese}.emailTitle`,
  ]);
  if (!title) {
    errorList.push({
      name: CreateMessageError.emailTitle.name,
      message: CreateMessageError.emailTitle.message,
    });
  }
  if (!content) {
    errorList.push({
      name: CreateMessageError.emailContent.name,
      message: CreateMessageError.emailContent.message,
    });
  }
  if (title?.length > 200) {
    errorList.push({
      name: CreateMessageError.emailTitleLengthLimit.name,
      message: CreateMessageError.emailTitleLengthLimit.message,
    });
  }
  if (tcTitle?.length > 200) {
    errorList.push({
      name: `translations.${LanguageConfig.traditionalChinese}.emailTitle`,
      message: CreateMessageError.emailTitleLengthLimit.message,
    });
  }
  if (scTitle?.length > 200) {
    errorList.push({
      name: `translations.${LanguageConfig.simplifiedChinese}.emailTitle`,
      message: CreateMessageError.emailTitleLengthLimit.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondSmsStepValidate({ getValues, setError }) {
  const errorList = [];
  const [content] = getValues([
    `translations.${LanguageConfig.english}.smsContent`,
  ]);
  if (!content) {
    errorList.push({
      name: CreateMessageError.smsContent.name,
      message: CreateMessageError.smsContent.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondWhatsappStepValidate({ getValues, setError }) {
  for (const language of [
    LanguageConfig.english,
    LanguageConfig.simplifiedChinese,
    LanguageConfig.traditionalChinese,
  ]) {
    const [template, photo, headerArgs, bodyArgs] = getValues([
      `translations.${language}.whatsappTemplate`,
      `translations.${language}.whatsappImage`,
      `translations.${language}.whatsappHeader`,
      `translations.${language}.whatsappBody`,
    ]);
    if (language === LanguageConfig.english) {
      if (!template) {
        setError(`translations.${language}.whatsappTemplate`, {
          type: 'require',
          message: 'Please select template',
        });
        return false;
      }
    }
    if (
      template?.headerFormat === MessageWhatsappTemplateType.IMAGE &&
      !photo
    ) {
      setError(`translations.${language}.whatsappImage`, {
        type: 'require',
        message: 'Please upload Image',
      });
      return false;
    }
    if (template?.headerFormat === MessageWhatsappTemplateType.TEXT) {
      const fieldCount = template?.headerText?.split(/{{\d+}}/)?.length - 1;
      if (fieldCount > 0) {
        for (let i = 0; i < fieldCount; i++) {
          const value = headerArgs?.[i];
          if (!value) {
            setError(`translations.${language}.whatsappHeader.${i}`, {
              type: 'require',
              message: 'mandatory field',
            });
            return false;
          }
        }
      }
    }
    const fieldCount = template?.bodyText?.split(/{{\d+}}/)?.length - 1;
    if (fieldCount > 0) {
      for (let i = 0; i < fieldCount; i++) {
        const value = bodyArgs?.[i];
        if (!value) {
          setError(`translations.${language}.whatsappBody.${i}`, {
            type: 'require',
            message: 'mandatory field',
          });
          return false;
        }
      }
    }
  }
  return true;
}

function thirdStepValidate({ getValues, setError }) {
  const [scheduledDate] = getValues(['scheduledDate']);
  const errorList = [];
  if (!scheduledDate || new Date(scheduledDate) < new Date()) {
    errorList.push({
      name: CreateMessageError.scheduledDate.name,
      message: CreateMessageError.scheduledDate.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({
  getValues,
  setError,
  clearErrors,
  step,
  isBack,
  languages,
}) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwoPush = secondPushStepValidate({ getValues, setError });
      return validStepTwoPush;
    case 2:
      const validStepTwoEmail = secondEmailStepValidate({
        getValues,
        setError,
      });
      return validStepTwoEmail;
    case 3:
      const validStepTwoSMS = secondSmsStepValidate({ getValues, setError });
      return validStepTwoSMS;
    case 4:
      const validStepTwoWhatsapp = secondWhatsappStepValidate({
        getValues,
        setError,
      });
      return validStepTwoWhatsapp;
    case 5:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      let validResult = true;
      const channels = getValues(['channels']);
      const validOne = firstStepValidate({ getValues, setError });
      validResult = validResult && validOne;
      if (MessageStepConfig[1].show(channels)) {
        const validTwoPush = secondPushStepValidate({ getValues, setError });
        validResult = validResult && validTwoPush;
      }
      if (MessageStepConfig[2].show(channels)) {
        const validTwoEmail = secondEmailStepValidate({ getValues, setError });
        validResult = validResult && validTwoEmail;
      }
      if (MessageStepConfig[3].show(channels)) {
        const validTwoSms = secondSmsStepValidate({ getValues, setError });
        validResult = validResult && validTwoSms;
      } else if (MessageStepConfig[4].show(channels)) {
        const validTwoWhatsapp = secondWhatsappStepValidate({
          getValues,
          setError,
        });
        validResult = validResult && validTwoWhatsapp;
      }
      const validThree = thirdStepValidate({ getValues, setError });
      validResult = validResult && validThree;
      return validResult;
  }
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';

import { ArrayStringData } from 'utils';

function CustomerCampaignListFilter({ backAction = () => { } }) {
  const location = useLocation();

  const { campaignCategoryList } = useSelector((state) => ({
    campaignCategoryList: state.campaignCategoryList.categoryList,
  }));

  const parsedSearch = queryString.parse(location.search.slice(1));
  const searchCategories = parsedSearch['categories'] || '';

  const [categories, setCategories] = useState(ArrayStringData(searchCategories));

  const content = [
    {
      title: 'Categories',
      data: campaignCategoryList,
      value: categories,
      setValue: setCategories,
      component: FilterTypeEnum.choice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default CustomerCampaignListFilter;

import React from 'react';
import './RFMDashboard.scss';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomTips from '../../components/base/CustomTips';
import RFMDefinition from '../../components/rfmDashboard/RFMDefinition';
import RFMPieDash from 'components/rfmDashboard/RFMPieDash';

const RFMDashboard = () => {
  const tip =
    'The dashboard shows an overview of the No. of members by RFM.';
  return (
    <>
      <div className="list-section-container-header list-section-container-header-bottom dashboard-header">
        <CustomBreadcrumb />
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left dashboard-header-left">
            <div className="dashboard-title">
              <CustomTips detail={tip}>
                <label className="list-section-container-header-title">
                  {NavBarNames.rfm}
                </label>
              </CustomTips>
            </div>
          </div>

        </div>
      </div>
      <div className="dashboard-campaigns-coupons">
        <RFMPieDash />
        <RFMDefinition />
      </div>
    </>
  );
};

export default RFMDashboard;

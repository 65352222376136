import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import i18n from 'I18n';

import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import { ReactHookFormImageUploader } from 'components/base/ImageUploader';
import CampaignSelectDropdown from 'components/message/CampaignSelectDropdown';
import { LanguageConfig } from 'config/CustomEnums';

import './MessageGeneralSection.scss';

function MessageGeneralSection({ language }) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const messageTitleField = `translations.${language}.name`;
  const messageContentField = `translations.${language}.content`;
  const messagePhotoField = `translations.${language}.photo`;
  const relatedCampaignField = 'relatedCampaign';

  const name = watch(messageTitleField);
  const content = watch(messageContentField);
  const photo = watch(messagePhotoField);
  const relatedCampaign = watch(relatedCampaignField);
  const [titleStart, setTitleStart] = useState(0);
  const [contentStart, setContentStart] = useState(0);

  const uploadImageMinWidth = 560;
  const uploadImageMinHeight = 420;

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>

      <CustomTitleWithInput
        title={i18n.t('title(optional)', { locale: language })}
        tips={i18n.t('inbox_push_title_suggest', { locale: language })}
        type={'text'}
        customClass={'text-input-field'}
        defaultValue={name}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(messageTitleField, value, { shouldDirty: true });
        }}
        paramText={i18n.t('message_insert_param', { locale: language })}
        onParamSelected={(value) => {
          if (name) {
            const newValue =
              name.substring(0, titleStart) +
              value +
              name.substring(titleStart);
            setValue(messageTitleField, newValue, { shouldDirty: true });
          } else {
            setValue(messageTitleField, value, { shouldDirty: true });
          }
        }}
        blurAction={(target) => {
          const start = target.selectionStart;
          setTitleStart(start);
        }}
        error={{ error: hasError(errors, messageTitleField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageTitleField} />

      <CustomTitleLabelWithEditorBar
        title={i18n.t('content', { locale: language })}
        suggest={i18n.t('inbox_push_content_suggest', { locale: language })}
        paramText={i18n.t('message_insert_param', { locale: language })}
        onParamSelected={(value) => {
          console.log('param', content, value);
          if (content) {
            const newValue =
              content.substring(0, contentStart) +
              value +
              content.substring(contentStart);
            setValue(messageContentField, newValue, {
              shouldDirty: true,
            });
          } else {
            setValue(messageContentField, value, { shouldDirty: true });
          }
        }}
      />
      <textarea
        onChange={({ target }) => {
          const value = target.value;
          setValue(messageContentField, value, { shouldDirty: true });
        }}
        className="custom-textarea-input-box custom-textarea-input-font"
        onFocus={() => {}}
        onBlur={({ target }) => {
          const start = target.selectionStart;
          console.log('textarea.onBlur selectionStart', start);
          setContentStart(start);
        }}
        value={content}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageContentField} />

      <ReactHookFormImageUploader
        title={i18n.t('image(optional)', { locale: language })}
        uploadSuggest={i18n.t('message_photo_suggest', { locale: language })}
        name={messagePhotoField}
        sizeLimit={1}
        skipSize={false}
        minWidth={uploadImageMinWidth}
        minHeight={uploadImageMinHeight}
        value={photo}
        aspect={null}
        maxImageNum={1}
        language={language}
        uploadImageClicked={() => {}}
      />
      {language == LanguageConfig.english && (
        <>
          <CampaignSelectDropdown
            title={'Related campaign (optional)'}
            isPrompt
            defaultValue={{
              value: relatedCampaign,
              label: relatedCampaign?.pk
                ? `[ID: ${relatedCampaign?.pk}] ${relatedCampaign?.name}`
                : relatedCampaign?.name,
            }}
            setValue={(value) => {
              setValue(relatedCampaignField, value, { shouldDirty: true });
            }}
          />
          <ReactHookFormErrorMessage
            errors={errors}
            id={relatedCampaignField}
          />
        </>
      )}
    </>
  );
}

export default MessageGeneralSection;

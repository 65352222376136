import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import './SingleCouponList.scss';
import '../../base/CustomListComponent.scss';
import { withRouter } from 'react-router-dom';
import DeactiveSingleCouponView from './DeactiveSingleCouponView';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../../containers/base/BaseTabListContainer';
import BaseListContainer from '../../../containers/base/BaseListContainer';
import DeletePrompt from '../../base/DeletePrompt';
import { COUPON_STATUS } from '../../../models/CouponListModel';
import {
  COUPON_TEMPLATE_TYPE,
  DELETE_RELATED_SECTIONS,
  StatusTag,
} from '../../../config/CustomEnums';
import { ActionsDropdownForItem } from 'components/base/ActionsDropdown';
const queryString = require('query-string');

@connect(({ couponList, singleCoupon }) => ({
  getSingleCouponListStatus: couponList.getSingleCouponListStatus,
  pageInfo: singleCoupon.pageInfo,
  totalCount: singleCoupon.totalCount,
  totalPage: singleCoupon.totalPage,
  displayFields: singleCoupon.listDisplayFields,
  imageDisplayFields: singleCoupon.listImageDisplayFields,
  singleCouponList: singleCoupon.currentPageSingleCouponList,
  checkedList: singleCoupon.checkedList,
}))
class SingleCouponList extends Component {
  constructor(props) {
    super(props);
    this.couponSetPK = props.match.params.id;
    this.state = {
      selectedSingleCoupon: null,
      showDeleteView: false,
      showDeactiveView: false,
      clickedOneCoupon: false,
    };
  }
  componentDidMount() {
    const urlParams = this.getUrlParams();
    this.props.dispatch(
      createAction('singleCoupon/getCurrentPageSingleCouponList')({
        templatePK: this.couponSetPK,
        rank: urlParams.rank,
        currentPage: urlParams.currentPage,
        filterKey: urlParams.searchKey,
      }),
    );
  }

  getUrlParams = () => {
    const searchDict = queryString.parse(this.props.location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const currentPage = parseInt(stringPage);
    return {
      rank,
      searchKey,
      currentPage,
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const urlParams = this.getUrlParams();
      this.setState({
        ...urlParams,
      });
      this.props.dispatch(
        createAction('singleCoupon/getCurrentPageSingleCouponList')({
          templatePK: this.couponSetPK,
          rank: urlParams.rank,
          currentPage: urlParams.currentPage,
          filterKey: urlParams.searchKey,
        }),
      );
    }
  }

  multipleActionList = [
    {
      name: 'Delete',
      action: () => {
        this.setState({
          showDeleteView: true,
          clickedOneCoupon: false,
        });
      },
      requires: PermissionCodes.deleteCoupon,
    },
  ];

  render() {
    return (
      <>
        <BaseTabListContainer
          hideTab={true}
          groupActions={this.multipleActionList}
          pageInfo={this.props.pageInfo}
          totalCount={this.props.totalCount ? this.props.totalCount : 0}
          listContent={
            <BaseListContainer
              fields={
                this.props.couponSet.offlineFormats ===
                COUPON_TEMPLATE_TYPE.couponCodeImage
                  ? this.props.imageDisplayFields
                  : this.props.displayFields
              }
              model={'singleCoupon'}
              permissionGroup={PermissionCodes.coupon}
              dataList={this.props.singleCouponList}
              totalPage={this.props.totalPage}
              deleteInfo={{
                data: [],
                title: 'Single Coupon',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.COUPON,
              }}
              actions={[]}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Edit',
                    action: (item) => {
                      this.props.dispatch({ type: `singleCoupon/clearData` });
                      return this.props.history.push(
                        `${this.props.location.pathname}/${item.pk}/edit/`,
                      );
                    },
                    requires: `change_${PermissionCodes.coupon}`,
                  },
                ];
                if (!item.isUsed) {
                  actions.push({
                    name: 'Delete',
                    action: () => {
                      this.props.dispatch({
                        type: `singleCoupon/updateState`,
                        payload: { checkedList: [item] },
                      });
                      this.setState({
                        showDeleteView: true,
                      });
                    },
                    requires: PermissionCodes.deleteCoupon,
                  });
                }
                const otherActions = [];
                if (!item.isUsed && !item.isExpired) {
                  otherActions.push({
                    name: `${
                      item.status === StatusTag.active ||
                      item.status === StatusTag.activeRequired
                        ? 'Deactive'
                        : 'Active'
                    }`,
                    action: (value) => {
                      if (
                        value.status === COUPON_STATUS.ACTIVE ||
                        value.status === COUPON_STATUS.ACTIVE_ACQUIRED
                      ) {
                        this.setState({
                          showDeactiveView: true,
                          selectedSingleCoupon: value,
                        });
                      } else if (value.status === COUPON_STATUS.INACTIVE) {
                        this.props.dispatch(
                          createAction('singleCoupon/activeSingleCoupon')({
                            couponPK: value.pk,
                            activeAction: this.props.history.push(
                              this.props.location,
                            ),
                          }),
                        );
                      }
                    },
                    requires: PermissionCodes.changeCoupon,
                  });
                }
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={otherActions}
                  />
                );
              }}
            />
          }
        />
        <DeletePrompt
          data={this.props.checkedList}
          show={this.state.showDeleteView}
          title={'Coupon'}
          relatedName=""
          relatedSections={DELETE_RELATED_SECTIONS.COUPON}
          onClose={() => this.setState({ showDeleteView: false })}
          onConfirm={() => {
            this.props.dispatch(
              createAction('singleCoupon/delete')({
                afterAction: () => this.props.history.push(this.props.location),
              }),
            );
            this.setState({ showDeleteView: false });
          }}
        />
        <DeactiveSingleCouponView
          showDeactiveView={this.state.showDeactiveView}
          onHide={() => this.setState({ showDeactiveView: false })}
          singleCoupon={this.state.selectedSingleCoupon}
          couponSet={this.props.couponSet}
          activeBtnClicked={() => {
            this.props.dispatch(
              createAction('singleCoupon/deactiveSingleCoupon')({
                couponPK: this.state.selectedSingleCoupon.pk,
                afterAction: () => {
                  this.props.history.push(this.props.location);
                },
              }),
            );
          }}
        />
      </>
    );
  }
}

export default withRouter(SingleCouponList);

import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';

import './WhatsappTemplateSelectDropdown.scss';

const mapPropsToState = (state) => ({
  dataList: state.messageList.whatsappTemplates || [],
});

function WhatsappTemplateSelectDropdown({
  title,
  description,
  alwaysHint,
  customStyles,
  customClass = '',
  defaultValue,
  isPrompt,
  setValue = () => {},
  dataList,
}) {
  const refreshAction = 'messageList/getWhatsappMessageTemplateList';
  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: refreshAction,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTitleWithDropDown
      customClass={`whatsapp-template-dropdown ${customClass}`}
      alwaysHint={alwaysHint}
      customStyles={customStyles}
      title={title}
      description={description}
      titleStyle={isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={dataList}
      defaultValue={defaultValue}
      loadMoreAction={refreshAction}
      filterAction={refreshAction}
      allType
    />
  );
}

export default connect(mapPropsToState)(WhatsappTemplateSelectDropdown);

import React from 'react';
import './DisplayOrderTableData.scss';
const DisplayOrderTableData = ({ item, onDone, editable }) => {
  // console.log(item);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [value, setValue] = React.useState(item.order);
  React.useEffect(() => {
    setValue(item.order);
  }, [item]);
  return (
    <td>
      <div
        className={`all-list-scrollable-area-table-item-common sort-disabled`}
      >
        <div className={`all-list-scrollable-area-text-common list-new-line`}>
          {isEditMode ? (
            <div style={{ lineHeight: '35px' }}>
              <input
                type="number"
                min={"1"}
                value={value}
                className="order-input"
                onKeyDown={(event) => {
                  console.log('@@24: ', event.keyCode);
                  if (
                    [69, 107, 109, 187, 189].includes(event.keyCode) || // + - e
                    ([48, 96].includes(event.keyCode) && event.target.value < 1) // 0
                  ) {
                    event.preventDefault();
                    return false;
                  }
                }}
                onChange={({ target }) => {
                  setValue(target.value);
                }}
              />
              <div
                className="order-edit-done-btn sort-disabled"
                onClick={() => {
                  setIsEditMode(false);
                  onDone && onDone(item.pk, parseInt(value));
                }}
              >
                Done
              </div>
            </div>
          ) : (
            <>
              <div>{value || '-'}</div>
              {editable ? (
                <div
                  className="order-edit-btn sort-disabled"
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </td>
  );
};
export default DisplayOrderTableData;

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';

function MessageCreditRecordDetail({
  messageRecord
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const getSections = () => {
    let fields = [
      [{
        title: "Message",
        field: "displayMessageName",
        deeplink: messageRecord?.message?.pk ? `/messages/${messageRecord.message.pk}/` : ""
      }],
      [{
        title: "Admin",
        field: "displayMessageAdmin",
      }],
      [{
        title: "Message create date",
        field: "displayMessageCreationDate",
      }],
      [{
        title: "Credit consumed",
        field: "displayCreditConsum",
      }],
      [{
        title: "Create at",
        field: "displayCreationDate",
      }],
    ]
    let sections = [
      <MembershipInfoCard
        title={''}
        data={messageRecord}
        fields={fields}
      />,
    ];
    return sections;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={getSections()}
            hidePreview={true}
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch({ type: 'messageCreditRecord/clearData' });
    return () => {
      dispatch({ type: 'messageCreditRecord/clearData' });

    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(createAction('messageCreditRecord/getOneDetail')({ id }));
  }, [dispatch, id]);

  return (
    <div>
      <CustomListComponent
        caution={{
          detail: "",
          title: messageRecord?.title | "",
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={messageRecord?.title} />}
        tabs={tabs}
      />
    </div>
  );
}
const mapPropsToState = (state) => ({
  messageRecord: state.messageCreditRecord.detail,
});

export default connect(mapPropsToState)(MessageCreditRecordDetail);

import React from 'react';

import {
  listImage,
  listItem,
  titleForItem,
} from 'components/banners/DisplayItems';
import CustomRichTextViewer from 'components/base/CustomRichTextViewer';
import { WhatsappContentInlineDisplay } from 'components/message/WhatsappContentInlineEditor';
import {
  LanguageConfig,
  MessageChannel,
  MessageWhatsappTemplateType,
} from 'config/CustomEnums';

import './MessageGeneralSectionWhatsapp.scss';

function MessageOverviewTableSection({
  message = {},
  language = LanguageConfig.english,
}) {
  const channels = message?.channels;
  console.log('channels', channels);

  const defaultChannels = [];
  if (
    channels?.includes(MessageChannel.all) ||
    channels?.includes(MessageChannel.allWhatsapp) ||
    channels?.includes(MessageChannel.push)
  ) {
    defaultChannels.push(MessageChannel.push);
  }
  if (
    channels?.includes(MessageChannel.all) ||
    channels?.includes(MessageChannel.allWhatsapp) ||
    channels?.includes(MessageChannel.inbox)
  ) {
    defaultChannels.push(MessageChannel.inbox);
  }

  const name = message.translations?.[language]?.name;
  const content = message.translations?.[language]?.content;
  const photo = message.translations?.[language]?.photo?.value;

  const emailTitle = message.translations?.[language]?.emailTitle;
  const emailContent = message.translations?.[language]?.emailContent;

  const smsContent = message.translations?.[language]?.smsContent;

  const whatsappTemplate = message.translations?.[language]?.whatsappTemplate;
  const whatsappType =
    message.translations?.[language]?.whatsappTemplate?.headerFormat ||
    MessageWhatsappTemplateType.TEXT;
  const whatsappImage = message.translations?.[language]?.whatsappImage?.value;
  const whatsappHeader = message.translations?.[language]?.whatsappHeader;
  const whatsappBody = message.translations?.[language]?.whatsappBody;

  return (
    <>
      {channels?.includes(MessageChannel.all) ||
      channels?.includes(MessageChannel.allWhatsapp) ||
      channels?.includes(MessageChannel.inbox) ||
      channels?.includes(MessageChannel.push) ? (
        <>
          <label className="create-section-title">
            {defaultChannels?.toString()?.toUpperCase()}
          </label>
          {listItem('Title (optional)', name)}
          {titleForItem('Content')}
          <CustomRichTextViewer initialValue={content || '-'} />
          {photo == null || photo === ''
            ? listItem('Image (optional)', '-')
            : listImage('Image (optional)', photo)}
        </>
      ) : null}
      {channels?.includes(MessageChannel.all) ||
      channels?.includes(MessageChannel.allWhatsapp) ||
      channels?.includes(MessageChannel.email) ? (
        <>
          <label className="create-section-title">{'EMAIL'}</label>
          {listItem('Title', emailTitle)}
          {titleForItem('Content')}
          <CustomRichTextViewer initialValue={emailContent || '-'} />
        </>
      ) : null}
      {channels?.includes(MessageChannel.all) ||
      channels?.includes(MessageChannel.sms) ? (
        <>
          <label className="create-section-title">{'SMS'}</label>
          {titleForItem('Content')}
          <CustomRichTextViewer initialValue={smsContent || '-'} />
        </>
      ) : null}
      {channels?.includes(MessageChannel.allWhatsapp) ||
      channels?.includes(MessageChannel.whatsapp) ? (
        <>
          <label className="create-section-title">{'Whatsapp'}</label>
          {listItem('Whatsapp template', whatsappTemplate?.name)}
          {whatsappImage == null || whatsappImage === ''
            ? listItem('Image', '-')
            : listImage('Image', whatsappImage)}
          {titleForItem('Content')}
          <WhatsappContentInlineDisplay
            customStyle={'header'}
            contentText={whatsappTemplate?.headerText}
            textArgs={whatsappHeader}
          />
          <WhatsappContentInlineDisplay
            customStyle={'body'}
            contentText={whatsappTemplate?.bodyText}
            textArgs={whatsappBody}
          />
          <WhatsappContentInlineDisplay
            customStyle={'footer'}
            contentText={whatsappTemplate?.footerText}
            textArgs={[]}
          />
        </>
      ) : null}
    </>
  );
}

export default MessageOverviewTableSection;

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';


const EditCustomerGroupModal = ({
  customerGroup,
  customer,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [selectCustomerGroup, setSelectCustomerGroup] = useState(customer.displayInGroups || []);


  const onClose = () => {
    setShow(false);
  }


  const saveAction = () => {
    const originalGroups = customer.displayInGroups || [];

    const joinGroups = selectCustomerGroup.filter(group => !originalGroups.find(item => item.pk === group.pk));
    const leaveGroups = originalGroups.filter(group => !selectCustomerGroup.find(item => item.pk === group.pk));

    dispatch({
      type: 'customerList/updateCustomer',
      payload: {
        data: {
          ...customer,
          joinGroups,
          leaveGroups,
        },
        customer: customer,
        refreshCustomer: true,
      },
    });
  };

  useEffect(() => {
    if (show) {
      setSelectCustomerGroup(customer.displayInGroups || []);
    }
  }, [show])


  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt-customer-group`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>Edit customer tag (customer group)</label>

        <BaseMultipleSelectorV2
          title={'Customer group'}
          data={{
            sourceData: customerGroup,
            targetData: selectCustomerGroup,
            targetChange: (value) => {
              setSelectCustomerGroup(value);
            },
          }}
        />

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm'
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  customerGroup: state.customerList.customerGroup,
  customer: state.customerList.customer,
});

export default connect(mapPropsToState)(EditCustomerGroupModal);

import React from 'react';
import './NextStepItem.scss';
import { Image } from 'react-bootstrap';
import Right from '../../../assets/images/step_right_icon.svg';
import OneStepSuccess from '../../../assets/images/create_success_icon.svg';
/**
 * stepNames: string array of steps name,
 * [
 * "name of step"
 * ]
 *
 * current: int, current step index in stepConfigs
 * @param {*} props
 */
function NextStepItem(props) {
  const steps = props.stepNames || [];
  const current = steps.indexOf(props.current);
  const onlyOneStep = () => (
    <Image src={OneStepSuccess} className="next-step-only-one-step" />
  );

  const finishIcon = () => (
    <Image src={Right} className="next-step-finished-icon" />
  );

  const waitingStepIcon = (index) => (
    <label className="next-step-waiting-icon">{index + 1}</label>
  );

  const Icon = ({ item, index }) => {
    let iconStatusStyle = 'next-step-finished-background';
    let icon = finishIcon();
    if (index > current) {
      iconStatusStyle = 'next-step-waiting-background';
      icon = waitingStepIcon(index);
    }
    return (
      <>
        <div className={`next-step-background ${iconStatusStyle}`}>{icon}</div>
      </>
    );
  };

  if (steps.length === 0) {
    return onlyOneStep();
  }

  const getTail = (index) => {
    if (index === steps.length - 1) {
      return null;
    }
    return (
      <div
        className={`next-step-tail-line ${
          current <= index
            ? 'next-step-tail-line-inactive'
            : 'next-step-tail-line-active'
        }`}
      />
    );
  };

  return (
    <div className="next-step-content">
      {steps.map((item, index) => {
        return (
          <div key={`${index}-${item}`} className="next-step-one-item">
            {getTail(index)}
            <div
              className={`${
                index === steps.length - 1
                  ? 'next-step-icon-text-container-last'
                  : 'next-step-icon-text-container-before'
              } next-step-icon-text-container 
                `}
            >
              <Icon item={item} index={index} />
              <label
                className={`next-step-description-custom ${
                  index <= current
                    ? 'next-step-description-custom-actived'
                    : 'next-step-description-custom-inactive'
                }`}
              >
                {item}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default NextStepItem;

import React from 'react';
import { useDispatch } from 'react-redux';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';

const lookups = [
  'Equal to',
  'Between',
];

const isError = (errors = [], error = '') => {
  return errors.indexOf(error) > -1;
};

const ScoreSection = ({
  score,
  fieldBaseName,
  dropDown,
  minNumName,
  maxNumName,
  start,
  between,
  end,
  scoreSetting,
  errorFields,
}) => {
  const dispatch = useDispatch();

  const lookup = scoreSetting?.[fieldBaseName]?.lookup;
  const minNumber = scoreSetting?.[fieldBaseName]?.minNumber;
  const maxNumber = scoreSetting?.[fieldBaseName]?.maxNumber;

  const updateScoreSetting = (subName, value) => {
    console.log("@@updateScoreSetting", fieldBaseName, subName, value)
    dispatch({
      type: 'recencyFrequencyMonetaryScoreSetting/updateScoreSettingField',
      payload: {
        fieldName: fieldBaseName,
        subName,
        value,
      }
    })
  }

  const dropDownOptions = lookups.map((lookupItem) => ({
    name: lookupItem,
    action: () => {
      updateScoreSetting('lookup', lookupItem)
    },
  }));

  return (
    <div>
      <CustomTitleLabel title={`${score} Score`} className="recency-frequency-monetary-score-setting-title" />

      <div style={{ display: 'flex' }}>
        <label
          className="help-message margin-right-10-percent"
          hidden={!start}
        >
          {start}
        </label>
        {
          dropDown
            ? <DropDownWithDefaultSelect
              actions={dropDownOptions}
              defaultValue={lookup}
              className={`recency-frequency-monetary-score-setting-drop-down ${isError(errorFields, fieldBaseName) ? 'error-field' : ''}`}
              imageClass={'recency-frequency-monetary-score-setting-drop-down-image'}
            />
            : null
        }
        <input
          type="number"
          className={`custom-number-input-short ${isError(errorFields, fieldBaseName) ? 'error-field' : ''}`}
          value={minNumber}
          onChange={({target}) => {
            updateScoreSetting("minNumber", target.value);
          }}
          hidden={!minNumName}
        />
        <label
          className="help-message margin-left-10-percent margin-right-10-percent"
          hidden={!between || (dropDown && lookup === 'Equal to')}
        >
          {between}
        </label>
        <input
          type="number"
          className={`custom-number-input-short ${isError(errorFields, fieldBaseName) ? 'error-field' : ''}`}
          value={maxNumber}
          onChange={({target}) => {
            updateScoreSetting("maxNumber", target.value);
          }}
          hidden={!maxNumName || (dropDown && lookup === 'Equal to')}
        />
        <label className="help-message margin-left-10-percent">
          {end}
        </label>
      </div>
    </div>
  );
}

export default ScoreSection;

import React from 'react';
import './CouponInstructionSection.scss';
import { connect } from 'react-redux';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import i18n from '../../../I18n';
import { useFormContext } from 'react-hook-form';

const CouponInstructionSection = ({
  language,
  onFocus = () => {},
}) =>  {
  const { watch, setValue } = useFormContext();

  const instructionSectionTitle = watch(`translations.${language}.instructionSectionTitle`) || '';
  const instructionSectionContent = watch(`translations.${language}.instructionSectionContent`) || '';

  return (
    <CustomMarkDownArea
      areaTitle={i18n.t('instruction', { locale: language })}
      title={{
        label: i18n.t('instruction_section_title', { locale: language }),
        value: instructionSectionTitle,
        valueChange: (value) => {
          setValue(`translations.${language}.instructionSectionTitle`, value, {shouldDirty: true})
        },
        focus: () => onFocus('instructionTitle'),
      }}
      content={{
        label: i18n.t('instruction_section_content', { locale: language }),
        value: instructionSectionContent,
        valueChange: (value) => {
          setValue(`translations.${language}.instructionSectionContent`, value, {shouldDirty: true})
        },
        focus: () => onFocus('instructionContent'),
      }}
    />
  );
  
}

const mapPropsToState = (state) => ({
})

export default connect(mapPropsToState)(CouponInstructionSection);

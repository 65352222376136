import React from 'react';
import { connect } from 'react-redux';
import './ContentSection.scss'
import ScoreSection from './ScoreSection';
import { APIStatus } from 'config/CustomEnums';
import Loading from 'components/base/Loading';

const recencyScoreSection = [
  {
    score: 5,
    fieldBaseName: 'recencyScoreFive',
    maxNumName: 'recencyFiveScoreLessThanOrEqualDays',
    start: 'Within the last',
    end: 'days',
  },
  {
    score: 4,
    fieldBaseName: 'recencyScoreFour',
    minNumName: 'recencyFourScoreGreaterThanOrEqualDays',
    maxNumName: 'recencyFourScoreLessThanOrEqualDays',
    start: 'Within the',
    between: '-',
    end: 'days',
  },
  {
    score: 3,
    fieldBaseName: 'recencyScoreThree',
    minNumName: 'recencyThreeScoreGreaterThanOrEqualDays',
    maxNumName: 'recencyThreeScoreLessThanOrEqualDays',
    start: 'Within the',
    between: '-',
    end: 'days',
  },
  {
    score: 2,
    fieldBaseName: 'recencyScoreTwo',
    minNumName: 'recencyTwoScoreGreaterThanOrEqualDays',
    maxNumName: 'recencyTwoScoreLessThanOrEqualDays',
    start: 'Within the',
    between: '-',
    end: 'days',
  },
  {
    score: 1,
    fieldBaseName: 'recencyScoreOne',
    minNumName: 'recencyOneScoreGreaterThanDays',
    start: 'No purchase over',
    end: 'days',
  },
];

const frequencyScoreSection = [
  {
    score: 5,
    fieldBaseName: 'frequencyScoreFive',
    minNumName: 'frequencyFiveScoreGreaterThanOrEqualTimes',
    start: 'Greater than',
    end: 'purchases within the last 365 days',
  },
  {
    score: 4,
    fieldBaseName: 'frequencyScoreFour',
    dropDown: true,
    minNumName: 'frequencyFourScoreGreaterThanOrEqualTimes',
    maxNumName: 'frequencyFourScoreLessThanOrEqualTimes',
    between: '-',
    end: 'purchases within the last 365 days',
  },
  {
    score: 3,
    fieldBaseName: 'frequencyScoreThree',
    dropDown: true,
    minNumName: 'frequencyThreeScoreGreaterThanOrEqualTimes',
    maxNumName: 'frequencyThreeScoreLessThanOrEqualTimes',
    between: '-',
    end: 'purchases within the last 365 days',
  },
  {
    score: 2,
    fieldBaseName: 'frequencyScoreTwo',
    dropDown: true,
    minNumName: 'frequencyTwoScoreGreaterThanOrEqualTimes',
    maxNumName: 'frequencyTwoScoreLessThanOrEqualTimes',
    between: '-',
    end: 'purchases within the last 365 days',
  },
  {
    score: 1,
    fieldBaseName: 'frequencyScoreOne',
    maxNumName: 'frequencyOneScoreLessThanTimes',
    start: 'Less than',
    end: 'purchases within the last 365 days',
  },
];

const monetaryScoreSection = [
  {
    score: 5,
    fieldBaseName: 'monetaryScoreFive',
    minNumName: 'monetaryFiveScoreGreaterThanOrEqualNumber',
    start: 'Spent greater than HK$',
    end: 'from the last 365 days',
  },
  {
    score: 4,
    fieldBaseName: 'monetaryScoreFour',
    minNumName: 'monetaryFourScoreGreaterThanOrEqualNumber',
    maxNumName: 'monetaryFourScoreLessThanOrEqualNumber',
    start: 'Spent HK$',
    between: '-',
    end: 'from the last 365 days',
  },
  {
    score: 3,
    fieldBaseName: 'monetaryScoreThree',
    minNumName: 'monetaryThreeScoreGreaterThanOrEqualNumber',
    maxNumName: 'monetaryThreeScoreLessThanOrEqualNumber',
    start: 'Spent HK$',
    between: '-',
    end: 'from the last 365 days',
  },
  {
    score: 2,
    fieldBaseName: 'monetaryScoreTwo',
    minNumName: 'monetaryTwoScoreGreaterThanOrEqualNumber',
    maxNumName: 'monetaryTwoScoreLessThanOrEqualNumber',
    start: 'Spent HK$',
    between: '-',
    end: 'from the last 365 days',
  },
  {
    score: 1,
    fieldBaseName: 'monetaryScoreOne',
    maxNumName: 'monetaryOneScoreLessThanNumber',
    start: 'Spent less than HK$',
    end: 'from the last 365 days',
  },
];

const scoreSection = [
  {
    title: 'Recency (R) Score',
    description: 'For each score, set the time range of the last purchase day of a customer.',
    content: recencyScoreSection,
  },
  {
    title: 'Frequency (F) Score',
    description: 'For each score, set the number of purchases within the last 365 days of a customer.',
    content: frequencyScoreSection,
  },
  {
    title: 'Monetary (M) Score',
    description: 'For each score, set the total purchase value within the last 365 days of a customer.',
    content: monetaryScoreSection,
  },
];

const SingleContentSection = ({
  section,
  scoreSetting,
  errorFields,
}) => {
  return (
    <div>
      <div className='recency-frequency-monetary-score-setting-separate' />
      <div className='recency-frequency-monetary-score-setting-heading'>{section.title}</div>
      <div className='recency-frequency-monetary-score-setting-description'>{section.description}</div>
      {
        section.content.map((item, index) => (
          <ScoreSection
            key={index}
            score={item.score}
            fieldBaseName={item.fieldBaseName}
            dropDown={item.dropDown}
            minNumName={item.minNumName}
            maxNumName={item.maxNumName}
            start={item.start}
            between={item.between}
            end={item.end}
            scoreSetting={scoreSetting}
            errorFields={errorFields}
          />
        ))
      }
    </div>
  )
}


const ContentSection = ({
  scoreSetting,
  apiStatus,
  errorFields,
}) => {

  return (
    <div>
      <div className="base-prompt-header">Edit RFM score setting</div>
      <div className='score-setting-container'>
        {
          apiStatus === APIStatus.calling
            ? <Loading />
            : <>
              {
                scoreSection.map((item, index) => (
                  <SingleContentSection
                    key={index}
                    section={item}
                    scoreSetting={scoreSetting}
                    errorFields={errorFields}
                  />
                ))
              }
            </>
        }
      </div>
    </div>
  );
}

const mapPropsToState = (state) => ({
  scoreSetting: state.recencyFrequencyMonetaryScoreSetting.scoreSetting,
  apiStatus: state.recencyFrequencyMonetaryScoreSetting.apiStatus,
  errorFields: state.recencyFrequencyMonetaryScoreSetting.errorFields,
});

export default connect(mapPropsToState)(ContentSection);

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Collapse } from 'react-bootstrap';

import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomSwitchButton from 'components/base/CustomSwitchButton';
import ErrorFieldMessage from 'components/base/ErrorFieldMessage';

const PeriodSection = forwardRef((props, ref) => {
  const title = props.title;
  const titleDescription = props.titleDescription;
  const switchButtonTitle = props.switchButtonTitle;
  const isAlwaysPeriod = props.isAlwaysPeriod;
  const switchOnChange = props.switchOnChange;
  const startDate = props.startDate;
  const startDateChange = props.startDateChange || (() => {});
  const endDate = props.endDate;
  const endDateChange = props.endDateChange || (() => {});
  const maxDate = props.maxDate;
  const minDate = props.minDate;

  const reminder = props.reminder;
  const disabled = props.disabled;
  const startErrorObject = props.startErrorObject || {};
  const endErrorObject = props.endErrorObject || {};

  const startDatePicker = useRef(null);
  const endDatePicker = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      setStartDate: (date) => {
        startDatePicker.current.handleChange(date);
      },
      setEndDate: (date) => {
        endDatePicker.current.handleChange(date);
      },
    };
  });

  const dataPicker = ({
    title,
    defaultTime,
    errorObject = {},
    onTimeChange = () => {},
    hasMaxDate = false,
    hasMinDate = false,
    ref,
  }) => {
    return (
      <div>
        <label className="create-section-label create-section-label-bottom-space">
          {title}
        </label>
        <CustomDateTimeSelect
          ref={ref}
          disabled={isAlwaysPeriod}
          defaultTime={defaultTime}
          maxDate={hasMaxDate ? maxDate : null}
          minDate={hasMinDate ? minDate : null}
          error={!!errorObject.message}
          onTimeChange={onTimeChange}
        />
        {errorObject.message ? null : (
          <label className="tips-message">{reminder}</label>
        )}
        <ErrorFieldMessage
          id={errorObject.id}
          error={errorObject.message}
          message={errorObject.message}
        />
      </div>
    );
  };

  return (
    <>
      <label className="create-section-title">{title}</label>
      <label className="section-short-description">{titleDescription}</label>
      {switchButtonTitle && (
        <label className="create-section-label create-section-label-bottom-space">
          {switchButtonTitle}
        </label>
      )}
      {switchOnChange && (
        <CustomSwitchButton
          disabled={disabled}
          defaultChecked={isAlwaysPeriod}
          onChange={switchOnChange}
        />
      )}
      <Collapse in={!isAlwaysPeriod}>
        <div>
          {dataPicker({
            title: 'Start date',
            defaultTime: startDate,
            onTimeChange: startDateChange,
            errorObject: startErrorObject,
            hasMaxDate: true,
            ref: startDatePicker,
          })}
          {dataPicker({
            title: 'End date',
            defaultTime: endDate,
            onTimeChange: endDateChange,
            errorObject: endErrorObject,
            hasMinDate: true,
            ref: endDatePicker,
          })}
        </div>
      </Collapse>
    </>
  );
});

export default PeriodSection;

import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import './CouponsPieChart.scss';
import {
  COUPON_PIE_CHART_CONFIG,
  ANIMATION_DURATION,
} from '../../config/DashboardConfig';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  if (percent === 0) {
    return null;
  }
  const displayString = `${(percent * 100).toFixed(0)}%`;
  const radius =
    innerRadius +
    (outerRadius - innerRadius) * (displayString.length === 4 ? 0.05 : 0.3);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="coupon-chart-percentage"
    >
      {displayString}
    </text>
  );
};
const CouponsPieChart = ({ data }) => {
  return (
    <PieChart width={250} height={250} className="dashboard-coupon-chart">
      <Pie
        data={data}
        dataKey="value"
        innerRadius={75}
        outerRadius={125}
        labelLine={false}
        label={renderCustomizedLabel}
        animationDuration={ANIMATION_DURATION}
      >
        {data.map((entry, index) => (
          <Cell fill={COUPON_PIE_CHART_CONFIG[index].color} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CouponsPieChart;

import React from 'react';
import i18n from '../../../I18n.js';
import { connect } from 'react-redux';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import { useFormContext } from 'react-hook-form';

function InstructionSection({
  language,
  focusChange,
  titleTips,
  contentTips
}) {
  // const language = props.language;
  // const focusChange = props.focusChange;
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});

  const { watch, setValue } = useFormContext();

  const instructionSectionTitle = watch(`translations.${language}.instructionSectionTitle`) || '';
  const instructionSectionContent = watch(`translations.${language}.instructionSectionContent`) || '';

  // useEffect(() => {
  //   if (hasValChanged) {
  //     // console.log(
  //     //   '@@36: ',
  //     //   instructionSectionContent,
  //     //   vals.instructionSectionContent,
  //     // );
  //     dispatch({
  //       type: valueChangedAction,
  //       payload: { vals, language },
  //     });
  //   }
  // });

  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       instructionSectionTitle,
  //       instructionSectionContent,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  return (
    <CustomMarkDownArea
      areaTitle={i18n.t('instruction', { locale: language })}
      title={{
        label: i18n.t('instruction_section_title', { locale: language }),
        value: instructionSectionTitle,
        valueChange: (value) => {
          setValue(`translations.${language}.instructionSectionTitle`, value, {shouldDirty: true})
        },
        tips: { ...titleTips },
        focus: () => focusChange('instructionTitle'),
      }}
      content={{
        label: i18n.t('instruction_section_content', { locale: language }),
        value: instructionSectionContent,
        valueChange: (value) => {
          setValue(`translations.${language}.instructionSectionContent`, value, {shouldDirty: true})
        },
        tips: { ...contentTips },
        focus: () => focusChange('instructionContent'),
      }}
    />
  );
}

const mapPropsToState = () => ({

})

export default connect(mapPropsToState)(InstructionSection);

import React from 'react';
import './CouponInstructionSection.scss';
import { connect } from 'react-redux';
import CustomMarkDownArea from '../../base/CustomMarkDownArea';
import i18n from '../../../I18n';
import { useFormContext } from 'react-hook-form';

const CouponDetailSection = ({
  language,
  onFocus = () => {}
}) => {
  const { watch, setValue } = useFormContext();

  const detailSectionTitle = watch(`translations.${language}.detailSectionTitle`) || '';
  const detailSectionContent = watch(`translations.${language}.detailSectionContent`) || '';

  return (
    <CustomMarkDownArea
      areaTitle={i18n.t('detail', { locale: language })}
      title={{
        label: i18n.t('detail_section_title', { locale: language }),
        value: detailSectionTitle,
        valueChange: (value) => {
          setValue(`translations.${language}.detailSectionTitle`, value, {shouldDirty: true});
        },
        focus: () => onFocus('detailsTitle'),
      }}
      content={{
        label: i18n.t('detail_section_content', { locale: language }),

        value: detailSectionContent,
        valueChange: (value) => {
          setValue(`translations.${language}.detailSectionContent`, value, {shouldDirty: true});
        },
        focus: () => onFocus('detailsContent'),
      }}
    />
  );
  
}

const mapPropsToState = () => ({

})

export default connect(mapPropsToState)(CouponDetailSection);

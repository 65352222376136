import React from 'react';
import './Loading.scss';
import { Image } from 'react-bootstrap';
import LoadingIcon from '../../assets/images/loading.gif';

function Loading() {
  return (
    <div className="loading-area">
      <Image src={LoadingIcon} className="loading-icon"></Image>
    </div>
  );
}

export default Loading;

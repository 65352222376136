import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { ActionsDropdownForItem } from 'components/base/ActionsDropdown';
import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import DeletePrompt from 'components/base/DeletePrompt';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import BasePrompt from 'components/base/prompt/BasePrompt';
import TransactionActionPrompt from 'components/transactions/TransactionActionPrompt';
import { DELETE_RELATED_SECTIONS } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import { ImportResource } from 'models/DownloadImportModel';

import Filter from './Filter';

import { createAction } from 'utils';

import './TransactionList.scss';

export const TRANSACTION_RECORD_TYPE = {
  TYPE_ONLINE: 'Online',
  TYPE_OFFLINE_POS: 'Offline Sales',
  TYPE_OFFLINE_REWARD_CLAIM: 'Offline (Reward Claims)',
};

export const TRANSACTION_EVENT_TYPE = {
  TYPE_WAITING: 'Waiting for approval',
  TYPE_APPROVED: 'Approved',
  TYPE_REJECTED: 'Rejected',
  TYPE_RECALLED: 'Recalled',
  TYPE_PENDING: 'Pending',
  TYPE_AUTHORIZED: 'Authorized',
  TYPE_PAID: 'Paid',
  TYPE_PARTIALLY_PAID: 'Partially paid',
  TYPE_REFUNDED: 'Refunded',
  TYPE_PARTIALLY_REFUNDED: 'Partially refunded',
  TYPE_VOIDED: 'Voided',
  TYPE_EXCHANGE: 'Exchange',
};

function TransactionList() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [promptActionTitle, setPromptActionTitle] = useState();
  const [singleItemAction, setSingleItemAction] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const {
    tableFields,
    dataList,
    pageInfo,
    totalCount,
    totalPage,
    checkedList,
    importedFile,
  } = useSelector((state) => ({
    tableFields: state.transactions.listDisplayFields,
    dataList: state.transactions.currentPageTransactionList,
    pageInfo: state.transactions.pageInfo,
    totalCount: state.transactions.totalCount,
    totalPage: state.transactions.totalPage,
    currentPage: state.transactions.currentPage,
    checkedList: state.transactions.checkedList,
    importedFile: state.uploadFiles.importedFile,
  }));

  useEffect(() => {
    if (importedFile) {
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.transaction,
          file: importedFile,
        }),
      );
    }
  }, [dispatch, importedFile]);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    // const transactionType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('transactions/getCurrentPageTransactions')({
        ...searchDict,
        // transactionType,
        searchKey,
        rank,
        page,
      }),
    );
  }, [dispatch, location, queryString]);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };
  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const customerName = parsedSearch['search'] || null;
    const createDatePeriod = parsedSearch?.create_date?.split(',');
    const transactionDatePeriod = parsedSearch?.transaction_date?.split(',');
    const transactionListIn = (checkedList || []).map((item) => item.pk);

    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.transaction,
        related: {
          transactionTypeIn: parsedSearch?.transaction_type?.split(','),
          onlineEventTypeIn: parsedSearch?.online_event_type?.split(','),
          offlineEventTypeIn: parsedSearch?.offline_event_type?.split(','),
          dateGte: transactionDatePeriod?.[0],
          dateLte: transactionDatePeriod?.[1],
          creationDateGte: createDatePeriod?.[0],
          creationDateLte: createDatePeriod?.[1],
          customerNameIcontains: customerName,
          transactionListIn: transactionListIn,
        },
      }),
    );
  };
  const createTransactionAction = () => {
    dispatch(createAction('createTransaction/clearData')());
    history.push('/transactions/create');
  };
  const buttons = [
    <ListButtonsGroup
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Export .csv"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title={'Import .csv'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: 'Export .csv',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import .csv',
        },
      ]}
      primaryButton={
        <AuthButton
          title="Create Transactions"
          action={createTransactionAction}
          requires={PermissionCodes.addTransaction}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.addTransaction,
        action: createTransactionAction,
        content: 'Create Transactions',
      }}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  // const typeTabs = [
  //   { key: 'all', name: 'All Types' },
  //   { key: 'WAITING_FOR_APPROVAL', name: 'Waiting for approval' },
  //   { key: 'APPROVED', name: 'Approved' },
  //   { key: 'RECALLED', name: 'Recalled' },
  //   { key: 'REJECTED', name: 'Rejected' },
  // ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteTransaction,
    },
  ];

  const setActionPrompt = (event, item) => {
    setPromptActionTitle(event);
    setSingleItemAction(true);
    setSelectedItem(item);
    setShowActionPrompt(true);
  };

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{ hasFilter: true, component: Filter }}
          listContent={
            <BaseListContainer
              fields={tableFields}
              model={'transactions'}
              permissionGroup={PermissionCodes.transaction}
              dataList={dataList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: 'transaction',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.TRANSACTION,
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                let actions = [
                  {
                    name: 'View details',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewTransaction,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setShowDeletePrompt(true);
                      setSingleItemAction(true);
                      setSelectedItem(item);
                    },
                    requires: PermissionCodes.deleteTransaction,
                  },
                ];
                if (
                  item.transactionDisplayType ===
                  TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
                ) {
                  actions.splice(1, 0, {
                    name: 'Edit',
                    action: () => {
                      history.push(`/transactions/${item.pk}/edit`);
                    },
                    requires: PermissionCodes.changeTransaction,
                  });
                }
                let otherAction = [];
                if (
                  item.transactionDisplayType ===
                  TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
                ) {
                  if (
                    item.displayOfflineEventType ===
                    TRANSACTION_EVENT_TYPE.TYPE_WAITING
                  ) {
                    otherAction = [
                      {
                        name: 'Approve',
                        action: () => {
                          setActionPrompt('approve', item);
                        },
                        requires: PermissionCodes.changeTransaction,
                      },
                      {
                        name: 'Reject',
                        action: () => {
                          setActionPrompt('reject', item);
                        },
                        requires: PermissionCodes.changeTransaction,
                      },
                    ];
                  } else if (
                    item.displayOfflineEventType ===
                    TRANSACTION_EVENT_TYPE.TYPE_APPROVED
                  ) {
                    otherAction = [
                      {
                        name: 'Recall',
                        action: () => {
                          setActionPrompt('recall', item);
                        },
                        requires: PermissionCodes.changeTransaction,
                      },
                    ];
                  }
                }
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={otherAction}
                  />
                );
              }}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
  ];

  const [showError, setShowError] = useState(false);
  const { importError } = useSelector((state) => ({
    importError: state.downloadAndImport.error,
  }));

  useEffect(() => {
    setShowError(importError && Object.keys(importError).length > 0);
  }, [importError]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All transactions are listed here, you can view both  online and offline transactions of each customer.',
          title: 'Transaction',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        title="Transactions"
        data={singleItemAction ? [selectedItem] : checkedList}
        displayName={'deleteDisplayName'}
        relatedSections={DELETE_RELATED_SECTIONS.TRANSACTION}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          let ids = [];
          if (singleItemAction) {
            ids = [selectedItem.pk];
          } else {
            ids = checkedList.map((item) => {
              return item.pk;
            });
          }
          dispatch(
            createAction('transactions/deleteTransaction')({
              ids,
              afterActions: () => {
                history.push(location);
              },
            }),
          );
          setShowDeletePrompt(false);
        }}
      />
      <TransactionActionPrompt
        event={promptActionTitle}
        item={selectedItem}
        show={showActionPrompt}
        onHide={() => {
          setShowActionPrompt(false);
          setSingleItemAction(false);
        }}
        action={() => {
          dispatch(
            createAction(`transactions/${promptActionTitle}Transaction`)({
              transactionPK: selectedItem.pk,
              afterActions: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={showError}
        title={importError?.title}
        description={importError?.content}
        closeAction={() => {
          setShowError(false);
          dispatch({
            type: 'downloadAndImport/updateState',
            payload: { error: {} },
          });
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
        }}
        rightButton={{
          text: 'Import again',
          action: () => {
            handleImportClick();
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
          requires: PermissionCodes.addImportjob,
        }}
      />
    </>
  );
}

export default TransactionList;

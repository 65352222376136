
import React from "react";
import { Image } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import deleteButton from '../../assets/images/drop_down_delete_selected.png'
import CustomRadios from "components/base/CustomRadios";
import { ReactHookFormErrorMessage, hasError } from "components/base/ErrorFieldMessage";
import { DISCOUNT_TYPE, DISCOUNT_VALUE_TYPE } from "config/CustomEnums";

function DiscountSection({
  index,
  discountListField = `discounts`,
}) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const discountValueTypeField = `${discountListField}.${index}.valueType`;
  const discountValueField = `${discountListField}.${index}.value`;
  const discountCodeField = `${discountListField}.${index}.code`;
  const discountTypeField = `${discountListField}.${index}.type`;

  const discountValueType = watch(discountValueTypeField)
  const discountValue = watch(discountValueField)
  const discountCode = watch(discountCodeField)
  const discountType = watch(discountTypeField)

  const discountList = watch(discountListField)
  const showDeleteButton = discountList?.length > 1

  const renderDiscountValueInput = () => {
    if (discountValueType === DISCOUNT_VALUE_TYPE.PERCENTAGE) {
      return (
        <>
          {"x"}
          <input
            className='day-input-fields'
            style={{
              marginLeft: "9px"
            }}
            value={discountValue || ""}
            onInput={(e) => {
              const value = e.target.value
              const data = value.replace(/[^0-9.]/g, '');
              setValue(discountValueField, data, { shouldDirty: true });
            }}
          />
          {"%"}
        </>
      )
    }
    if (discountValueType === DISCOUNT_VALUE_TYPE.FIXED_VALUE) {
      return (
        <>
          {"- HK$"}
          <input
            className='day-input-fields'
            style={{
              marginLeft: "9px"
            }}
            value={discountValue || ""}
            onInput={(e) => {
              const value = e.target.value
              const data = value.replace(/[^0-9.]/g, '');
              setValue(discountValueField, data, { shouldDirty: true });
            }}
          />
        </>
      )
    }

    return <></>
  }

  return (
    <div className='single-discounts'>
      <div className="title-and-delete">
        <label className="create-section-label">
          {`Discount #${index + 1} (optional)`}
        </label>
        {showDeleteButton &&
          <Image
            src={deleteButton}
            className="delete-img"
            onClick={(e) => {
              let itemList = discountList;
              itemList.splice(index, 1);
              setValue(discountListField, itemList, { shouldDirty: true });
            }}
          />
        }
      </div>
      <div className="discount-value">
        <label className="create-section-label">
          {`Type`}
        </label>
        <div className="tips-message" style={{ marginBottom: 0 }}>
          {'Suggested NOT to change this item.'}
        </div>
        <div className="d-flex">
          <CustomRadios
            onChange={(value) => {
              setValue(discountTypeField, value, { shouldDirty: true });
            }}
            default={discountType}
            options={[
              {
                label: 'Transaction-level',
                value: DISCOUNT_TYPE.TRANSACTION
              },
              {
                label: 'Purchase item-level',
                value: DISCOUNT_TYPE.ITEM
              },
            ]}
          />
        </div>
        <ReactHookFormErrorMessage errors={errors} id={discountTypeField} />
      </div>
      <div className="discount-value">
        <label className="create-section-label">
          {`Value`}
        </label>
        <div className="d-flex">
          <CustomRadios
            onChange={(value) => {
              setValue(discountValueField, null, { shouldDirty: true })
              setValue(discountValueTypeField, value, { shouldDirty: true });
            }}
            default={discountValueType}
            options={[
              {
                label: 'Percentage',
                value: DISCOUNT_VALUE_TYPE.PERCENTAGE
              },
              {
                label: 'Fixed amount',
                value: DISCOUNT_VALUE_TYPE.FIXED_VALUE
              },
            ]}
          />
        </div>
        <div>
          {renderDiscountValueInput()}
        </div>
        <ReactHookFormErrorMessage errors={errors} id={discountValueField} />

      </div>
      <div>
        <label className="create-section-label">
          {`Code`}
        </label>
        <input
          type="text"
          className={`text-input-field ${hasError(errors, discountCodeField)
            ? 'error-field'
            : ''
            }`}
          value={discountCode || ""}
          onInput={(e) => {
            setValue(discountCodeField, e.target.value, { shouldDirty: true });
          }}
        />
      </div>
    </div>
  )
}

export default DiscountSection;
import { NavBarNames } from '../../config/NavBarNameList';

export function getCurrentRouteName(location) {
  const isBelongsToTab = (tab) => location?.toLowerCase()?.indexOf(tab) > -1;
  if (location === '/') {
    return NavBarNames.dashboard;
  }
  if (isBelongsToTab('/campaigns')) {
    return NavBarNames.campaigns;
  }
  if(isBelongsToTab('stamp') && isBelongsToTab('records')){
    return NavBarNames.stampRecords
  }
  if (isBelongsToTab('stamp')) {
    return NavBarNames.stampCampaigns;
  }
  if (isBelongsToTab('campaign') && isBelongsToTab('category')) {
    return NavBarNames.campaignCategory;
  }
  if (isBelongsToTab('coupon') && isBelongsToTab('records')) {
    return NavBarNames.couponRecords;
  }
  if (isBelongsToTab('coupon')) {
    return NavBarNames.coupons;
  }
  if (isBelongsToTab('earn')) {
    return NavBarNames.earnRules;
  }
  if (isBelongsToTab('customer') && isBelongsToTab('group')) {
    return NavBarNames.customerGroup;
  }
  if (isBelongsToTab('custom')) {
    return NavBarNames.customer;
  }
  if (isBelongsToTab('segments')) {
    return NavBarNames.segments;
  }
  if (isBelongsToTab('levels')) {
    return NavBarNames.levels;
  }
  if (isBelongsToTab('message_credit_records')) {
    return NavBarNames.messageCreditRecords;
  }
  if (isBelongsToTab('message')) {
    return NavBarNames.message;
  }
  if(isBelongsToTab('bulk')){
    return NavBarNames.bulkRewards
  }
  if (isBelongsToTab('rewards')) {
    return NavBarNames.rewards;
  }
  if (isBelongsToTab('brands')) {
    return NavBarNames.brands;
  }
  if (isBelongsToTab('stores')) {
    return NavBarNames.stores;
  }
  if (isBelongsToTab('store') && isBelongsToTab('category')) {
    return NavBarNames.storeCategory;
  }
  if (isBelongsToTab('translations')) {
    return NavBarNames.translations;
  }
  if (isBelongsToTab('transactions')) {
    return NavBarNames.transactions;
  }
  if (isBelongsToTab('point') && isBelongsToTab('records')) {
    return NavBarNames.pointRecords;
  }
  if (isBelongsToTab('administrator_groups')) {
    return NavBarNames.adminGroup;
  }
  if (isBelongsToTab('administrators')) {
    return NavBarNames.admin;
  }
  if (isBelongsToTab('banners')) {
    return NavBarNames.banners;
  }
  if (isBelongsToTab('product') && isBelongsToTab('categories')) {
    return NavBarNames.productCategory;
  }
  if (isBelongsToTab('home_campaigns')) {
    return NavBarNames.featuredCampaign;
  }
  if (isBelongsToTab('featured') && isBelongsToTab('products')) {
    return NavBarNames.featuredProduct;
  }
  if (isBelongsToTab('store_codes')) {
    return NavBarNames.storeCodes;
  }
  if (isBelongsToTab('languages')) {
    return NavBarNames.langauge;
  }
  if (isBelongsToTab('webview')) {
    return NavBarNames.webview;
  }
  if (isBelongsToTab('rfm')) {
    return NavBarNames.rfm;
  }
  if (isBelongsToTab('gift_cards')) {
    return NavBarNames.giftCards;
  }
  if (isBelongsToTab('gift_card_records')) {
    return NavBarNames.giftCardRecords;
  }
}

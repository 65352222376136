import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import BackgroundShort from '../../assets/images/slide-3-background-short.svg';
import OrShort from '../../assets/images/slide-or-short.svg';
import './OnboardingSlideThreeVertical.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonConfig } from './OnboardingConfig';

function OnboardingSlideThreeVertical() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentHover, setCurrentHover] = useState();

  const onHover = (type) => {
    setCurrentHover(type);
  };

  const getButtonItems = (config) => {
    return (
      <>
        <Image src={config.imageOff} height={0} width={0} />
        <div className={`button-item-container`}>
          <div
            className={`button-item `}
            style={{
              backgroundColor:
                config.type === currentHover
                  ? config.backgroundColorOff
                  : config.backgroundColorOn,
            }}
            onMouseOver={() => onHover(config.type)}
            onMouseLeave={() => onHover(null)}
            onClick={() => {
              history.push(config.location);
              dispatch({
                type: 'users/updateState',
                payload: { firstLogin: false },
              });
            }}
          >
            <Image
              className={`button-icon `}
              src={
                config.type === currentHover ? config.imageOff : config.imageOn
              }
            />
            <label
              style={{
                color:
                  config.type === currentHover
                    ? config.textColorOff
                    : config.textColorOn,
              }}
              className={`button-text `}
            >
              {config.des}
            </label>
          </div>
          <label className="button-title">{config.title}</label>
        </div>
      </>
    );
  };

  return (
    <div className="onboarding-slide-3-vertical">
      <div className={`text-container`}>
        <label className="content-title">
          Before you create campaign maybe you need...
        </label>
        <label className="content-text">
          If you want to create a coupon campaign or earning campaign, you need
          create an earning rule or coupon set to support your campaign. And if
          you want to create a general message campaign, you can select “Create
          general message campaign” directly!
        </label>
      </div>
      <div className={`button-area`}>
        {getButtonItems(ButtonConfig.coupon)}
        {getButtonItems(ButtonConfig.earning)}
      </div>
      <Image className={'item-or-image'} src={OrShort} />
      {getButtonItems(ButtonConfig.general)}
      <Image src={BackgroundShort} className="slide-background-image" />
    </div>
  );
}

export default OnboardingSlideThreeVertical;

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import structuredClone from '@ungap/structured-clone';

import Loading from 'components/base/Loading';
import {
  APIStatus,
  EXPIRY_TYPE_CONFIG,
  POINTS_EXPIRY_DATE_VALUE,
} from 'config/CustomEnums';

import VirtualGiftCardVariant from './VirtualGiftCardVariant';

import { createAction } from 'utils';

import './VirtualGiftCardConfigContentSection.scss';

const emptyVariant = {
  pk: -1,
  cashValue: null,
  price: null,
  activePeriodType: POINTS_EXPIRY_DATE_VALUE.ALL_TIME,
  endDate: null,
  expireDays: null,
};

function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
}

const mapPropsToState = (state) => ({
  pagedList: state.virtualGiftCardConfig?.pagedList,
  apiStatus: state.virtualGiftCardConfig?.apiStatus,
  errorFields: state.virtualGiftCardConfig?.errorFields,
});

const VirtualGiftCardConfigContentSection = ({
  pagedList,
  apiStatus,
  errorFields,
}) => {
  const dispatch = useDispatch();
  const [variants, setVariants] = useState([]);

  const getNewVariant = () => {
    const variant = structuredClone(emptyVariant);
    variant.pk = getRandomIntInclusive(-100000, 0);
    return variant;
  };

  useEffect(() => {
    if (pagedList && pagedList.length > 0) {
      setVariants(pagedList);
      return;
    }
    const variant = getNewVariant();
    setVariants([variant]);
  }, [pagedList]);

  useEffect(() => {
    dispatch(
      createAction('virtualGiftCardConfig/updateState')({
        variants: variants,
      }),
    );
    console.log('variants@53', variants);
  }, [variants]);

  const removeVariant = (index) => {
    if (index > variants.length) {
      return;
    }
    if (index < 0) {
      return;
    }
    const data = variants.concat([]);
    data.splice(index, 1);
    setVariants(data);
  };

  const addNewVariant = () => {
    const variant = getNewVariant();
    const data = variants.concat([variant]);
    setVariants(data);
  };

  const setVariantWithIndex = (index, variant) => {
    if (index > variants.length) {
      return;
    }
    if (index < 0) {
      return;
    }
    const data = variants.concat([]);
    data.splice(index, 1, variant);
    setVariants(data);
  };

  return (
    <div>
      <div className="base-prompt-header">
        Virtual gift card options configuration
      </div>
      <div className="score-setting-container">
        {apiStatus === APIStatus.calling ? (
          <Loading />
        ) : (
          <>
            {variants.map((item, index) => {
              const canDelete = variants?.length > 1;
              return (
                <VirtualGiftCardVariant
                  key={`config-variant-pk-${item.pk}`}
                  index={index}
                  canDelete={canDelete}
                  item={item}
                  setItem={(value) => setVariantWithIndex(index, value)}
                  deleteItem={() => removeVariant(index)}
                />
              );
            })}

            <Button
              onClick={() => addNewVariant()}
              className="btn-back-button-common add-sub-category-btn"
            >
              Add Cash value option
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(mapPropsToState)(VirtualGiftCardConfigContentSection);

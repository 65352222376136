import React from 'react';
import { Image, OverlayTrigger, Popover } from 'react-bootstrap';

import CustomTips from 'components/base/CustomTips';

import { getScrollbarWidth, useMainContentWidth } from 'utils/ScreenUtil';

import Info from 'assets/images/info.svg';

import './MessageChannelCard.scss';

export const MIN_WIDTH = 200;
export const MAX_WIDTH = 300;
export const SPACE_BETWEEN = 30;

function MessageChannelCard({
  channelConfig = {},
  selected,
  select = () => {},
  unSelect = () => {},
  alwaysShowTip = null,
  toolTip = null,
  moreThanThree = true,
  disabled = false,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();
  let width = MIN_WIDTH;
  if (moreThanThree) {
    if (fullContainerWith >= (MAX_WIDTH + SPACE_BETWEEN) * 3) {
      width = MAX_WIDTH;
    } else if (fullContainerWith >= (MIN_WIDTH + SPACE_BETWEEN) * 3) {
      width = (fullContainerWith - SPACE_BETWEEN * 3) / 3;
    } else if (fullContainerWith >= (MIN_WIDTH + SPACE_BETWEEN) * 2) {
      width = (fullContainerWith - SPACE_BETWEEN * 2) / 2;
    } else {
      width = fullContainerWith;
    }
  }

  const getChannelTitle = () => {
    if (!!alwaysShowTip) {
      return (
        <div className="channel-title-div">
          <OverlayTrigger
            key="right"
            placement="right"
            trigger="click"
            defaultShow={true}
            overlay={
              <Popover
                id={`popover-positioned-right`}
                className="caution-container"
              >
                <Popover.Content className="caution-content">
                  {alwaysShowTip}
                </Popover.Content>
              </Popover>
            }
          >
            <div className="tips-pointer-container">
              <label className="campaign-type-title">
                {channelConfig.channel}
              </label>
              <Image src={Info} className={'caution-icon'} />
            </div>
          </OverlayTrigger>
        </div>
      );
    }
    if (!!toolTip) {
      return (
        <div className="channel-title-div">
          <CustomTips detail={toolTip}>
            <label className="campaign-type-title">
              {channelConfig.channel}
            </label>
          </CustomTips>
        </div>
      );
    }
    return (
      <label className="campaign-type-title">{channelConfig.channel}</label>
    );
  };

  return (
    <div
      className={`message-channel-container ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
      style={moreThanThree ? { width } : { flex: 1 }}
      onClick={() => {
        if (disabled) {
          return ;
        }
        if (selected) {
          unSelect(channelConfig.channel);
        } else {
          select(channelConfig.channel);
        }
      }}
    >
      {getChannelTitle()}
      <Image className="campaign-type-image" src={channelConfig.image} />
      <label className="message-channel-description">
        {channelConfig.description}
      </label>
      <label className="message-channel-suggest">{channelConfig.suggest}</label>
    </div>
  );
}

export default MessageChannelCard;

import React, { useEffect } from 'react';
import '../campaign/CreateCampaign.scss';
import { useDispatch, connect } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateStampCampaignStepOne from '../../../components/stampCampaign/stampCampaignCreation/CreateStampCampaignStepOne'
import CreateStampCampaignStepTwo from '../../../components/stampCampaign/stampCampaignCreation/CreateStampCampaignStepTwo';
import CreateStampCampaignStepThree from '../../../components/stampCampaign/stampCampaignCreation/CreateStampCampaignStepThree';
import {
  createStampCampaignBreads,
} from '../../../config/CustomBreadConfig';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { createAction } from '../../../utils';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
  sessionDataKey,
} from '../../../models/CreateCampaignModel';
import {
  getCampaignCreateSuccessStepBar,
  getCampaignStepBarNameWithType,
} from '../../../components/base/prompt/NextStepConfig';
import { URLFilterKeys, URLFragments } from '../../../components/campaign/CampaignUrlConfig';
import queryString from 'query-string';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';

function CreateStampCampaign({
  stepConfig,
  currentStep,
  campaignPublish,
  linkedEarningRules,
  linkedCoupon,
  campaign = {},
  formHasSubmitted,
  hasUpdatedDefaultValues,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(history.location.search);

  const campaignType = CampaignType.stampCampaign;
  const campaignId = campaign.pk;
  const campaignGeneralName = campaign.translations?.en?.generalName;

  useEffect(() => {
    dispatch({ type: 'createCampaign/clearData' });
    if (!history.location.search) {
      const campaignId = params.id;
      if (campaignId) {
        dispatch(
          createAction('createCampaign/getOneCampaign')({ id: campaignId }),
        );
      } else {
        dispatch(
          createAction('createCampaign/loadCampaignFromCookie')({ resume: location.state?.resume }),
        );
      }
      dispatch(createAction('brand/getCurrentPageBrands')());
    }
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch({ type: 'createCampaign/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const stepSet = [
    <CreateStampCampaignStepOne />,
    <CreateStampCampaignStepTwo />,
    <CreateStampCampaignStepThree />,
  ];

  const toCampaignList = () => {
    const search = queryString.stringify({
      [URLFilterKeys.page]: 1,
      [URLFilterKeys.searchKey]: '',
      [URLFilterKeys.rank]: true,
    });
    history.push({
      pathname: '/stamp_campaigns',
      hash: URLFragments.list,
      search: search,
    });
  };

  const getCompleteSteps = () => {
    const urlId = search.id;
    const linkedId =
      CampaignType.couponsCampaign === campaignType
        ? linkedCoupon?.pk
        : linkedEarningRules?.pk;
    let showMoreSteps = location.search;
    if (urlId + '' !== linkedId + '') {
      showMoreSteps = false;
    }
    return getCampaignCreateSuccessStepBar(campaignType, showMoreSteps);
  };

  return (
    <BaseForm
      defaultValues={campaign}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      showFinishPop={campaignPublish === APIStatus.success}
      nextStepConfig={{
        title: 'Successfully Published!',
        description:
          'The campaign is successfully published.\n Let your customers know about the campaign.',
        steps: {
          stepNames: getCompleteSteps(),
          current: getCampaignStepBarNameWithType(campaignType),
        },
        onHide:()=>{},
        buttons: [
          {
            text: 'Create message',
            action: () => {
              history.push({
                pathname: '/messages/create',
                search: `${location.search}&campaign=${campaignId}${
                  location.search ? '' : '&type=' + campaignType
                  }`,
              });
            },
            requires: PermissionCodes.addMessage,
          },
          {
            text: 'Maybe Later',
            action: toCampaignList,
            requires: PermissionCodes.changeEarningRule,
          },
        ],
      }}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: toCampaignList,
        onClose: toCampaignList,
      }}
      sessionDataKey={sessionDataKey}
      content={stepSet}
      currentStep={currentStep}
      breadcrumb={<CustomBreadcrumb breadcrumbs={createStampCampaignBreads(campaignId ? campaignGeneralName : 'Create Stamp campaign')} />}
      stepsConfig={stepConfig}
      caution={{
        detail: '',
        title: campaignId ? campaignGeneralName : 'Create Stamp campaign',
      }}
      hasleavePagePrompt={params.id}
    />
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createCampaign.stampStepConfig,
  currentStep: state.createCampaign.currentStep,
  campaignType: state.createCampaign.campaign?.campaignType,
  campaignId: state.createCampaign.campaign?.pk,
  linkedEarningRules: state.createCampaign.campaign?.linkedEarningRules,
  linkedCoupon: state.createCampaign.campaign?.linkedCoupon,
  campaignPublish: state.createCampaign.campaignPublish,

  campaign: state.createCampaign.campaign,
  formHasSubmitted: state.createCampaign.formHasSubmitted,
  hasUpdatedDefaultValues: state.createCampaign.hasUpdatedDefaultValues,
})

export default connect(mapPropsToState)(CreateStampCampaign);

import moment from 'moment';

import { AmOrPm, LanguageConfig } from 'config/CustomEnums';

export const TimeFormater = {
  dayMonthWeek: 'DD MMM (ddd)',
  dayMonthYearWeek: 'D MMM YYYY (ddd)',
  dayMonthYearWeekTime: 'DD MMM yyyy (ddd),HH:mm:ss',
  dayMonthYearWeekTimeWithComma: 'D/M/YYYY(ddd),HH:mm:ss',
  dayMonthYearWeekTimeA: 'DD MMM yyyy (ddd),HH:mm a',
  dayMonthYear: 'DD MMM yyyy',
  yearMonthDay: 'YYYY-MM-DD',
  week: 'ddd',
  time: 'HH:mm:ss',
  dayMonthYearWeekTimePeriod: 'DD MMM yyyy (ddd), HH:mm a',
  yearMonthDayTimePeriod: 'YYYY-MM-DD HH:mm a',
  yearMonthDayTime: 'YYYY-MM-DD HH:mm',
  timePeriod: 'HH:mm a',
  blackoutPeriod: 'DD MMM YYYY (ddd) HH:mm',
};

export const getRemarkCreationDateFormatted = (creationDate) => {
  const timeFormat = 'MMM D, YYYY HH:mm:ss';
  return moment(creationDate).format(timeFormat);
};

export const getCampaignPeriodDate = (date) => {
  const momentFormat = moment(date);
  const formatted = momentFormat.format('D MMM YYYY (ddd)');
  return formatted;
};

export const getCampaignPeriodDateTime = (date) => {
  const momentFormat = moment(date);
  const formatted = momentFormat.format('DD MMM YYYY (ddd) HH:mm');
  return formatted;
};

export const getCampaignActivePeriodDateTime = (
  date,
  language = LanguageConfig.english,
) => {
  const momentDate = moment(date);
  if (language === LanguageConfig.traditionalChinese) {
    const dateFormat = 'D-M-YYYY (ddd) HH:mm';
    require('moment/locale/zh-hk');
    return momentDate.locale('zh-hk').format(dateFormat);
  } else if (language === LanguageConfig.simplifiedChinese) {
    const dateFormat = 'YYYY年M月D日 (ddd) HH:mm';
    require('moment/locale/zh-cn');
    return momentDate.locale('zh-cn').format(dateFormat);
  }
  const dateFormat = 'D MMM, YYYY (ddd) HH:mm';
  return momentDate.locale('en').format(dateFormat);
};

export const getCampaignPeriodFormatedTime = (date) => {
  const momentFormat = moment(date);
  const resultHour = momentFormat.format('hh');
  const resultMinute = momentFormat.format('mm');
  const resultSecond = momentFormat.format('ss');
  const twentyHourFormat = momentFormat.format('HH');
  const isPm = parseFloat(twentyHourFormat) >= 12;
  return {
    defaultHour: resultHour,
    defaultMinute: resultMinute,
    defaultSecond: resultSecond,
    defaultAmOrPm: isPm ? AmOrPm.pm : AmOrPm.am,
  };
};

export const getCurrentDay = () => {
  const date = new Date();
  date.setHours(23, 59, 59);
  return date;
};

export const getCampaignListDisplayTime = (startDate, endDate) => {
  const momentNow = moment();
  const startTime = moment(startDate);
  const currentYear = momentNow.year();
  const currentTime = momentNow.valueOf();
  if (!endDate) {
    if (currentYear === startTime.year()) {
      return `From ${startTime.format('DD MMM (ddd)')}`;
    }
    return `From ${startTime.format('DD MMM yyyy')}`;
  }
  const endTime = moment(endDate);
  if (currentTime < startTime) {
    if (currentYear === startTime.year()) {
      return `From ${startTime.format('DD MMM (ddd)')}`;
    }
    return `From ${startTime.format('DD MMM yyyy')}`;
  }
  if (currentYear === endTime.year()) {
    return `Until ${endTime.format('DD MMM (ddd)')}`;
  }
  return `Until ${endTime.format('DD MMM yyyy')}`;
};

export const checkCampaignIsExpired = (endDate) => {
  const momentNow = moment();
  const endTime = moment(endDate);
  return momentNow.valueOf() > endTime.valueOf();
};

export const getCouponExpiryDate = (datetime) => {
  const momentFormat = moment(datetime);
  const formatted = momentFormat.format('YYYY-MM-DD');
  return formatted;
};

export const getDisplayDate = (inputDate) => {
  const momentNow = moment();
  const inputTime = moment(inputDate);
  const currentYear = momentNow.year();
  // const currentTime = momentNow.valueOf();
  if (currentYear === inputTime.year()) {
    return `${inputTime.format('DD MMM (ddd)')}`;
  }
  return `${inputTime.format('DD MMM yyyy')}`;
};

export const formatDate = (inputDate, dateFormat = 'DD MMM yyyy (ddd)') => {
  if (!inputDate) {
    return '';
  }
  const inputTime = moment(inputDate);
  const weekIndex = dateFormat.indexOf(TimeFormater.week);
  if (weekIndex >= 0 && dateFormat.length > weekIndex + 4) {
    const time = inputTime.format(
      dateFormat.split(`(${TimeFormater.week}),`)[1],
    );
    return `${inputTime.format(TimeFormater.dayMonthYearWeek)}, ${time}`;
  }

  if (dateFormat === TimeFormater.dayMonthYearWeekTimeA) {
    const time = inputTime.format('HH:mm');
    const amOrPm = inputTime.format('a');
    return `${inputTime.format(
      TimeFormater.dayMonthYearWeek,
    )}, ${time} ${amOrPm}`;
  }

  return `${inputTime.format(dateFormat)}`;
};

export const compareDate = (date1, date2, isAfter) => {
  // console.log('compareDate:', date1, date2, isAfter);
  if (!date1) {
    return date2;
  }
  if (!date2) {
    return date1;
  }
  const firstDate = moment(date1);
  const secondDate = moment(date2);
  if (isAfter) {
    return firstDate.isAfter(secondDate) ? date1 : date2;
  }
  return firstDate.isBefore(secondDate) ? date1 : date2;
};

export const getReadablePeriod = (startDate, endDate, isAlways) => {
  if (!startDate) {
    return '-';
  }
  if (!endDate || isAlways) {
    return 'Always';
  }
  const startDateFormat = getCampaignPeriodDateTime(startDate);
  const endDateFormat = getCampaignPeriodDateTime(endDate);
  return `${startDateFormat} - ${endDateFormat}`;
};

export const getNearbyUpdateTime = () => {
  const now = moment();
  const minutes = now.minutes();
  now.set({ minutes: minutes >= 0 && minutes < 30 ? 0 : 30 });

  return now.format(TimeFormater.dayMonthYearWeekTimeA);
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ContinueWithBackAndTempSaveButtons,
} from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import MessageGeneralSectionSMS from 'components/message/MessageGeneralSectionSMS';
import { ActionType, ApprovalStatus, MessageStepConfig } from 'config/CustomEnums';
import { afterSaveASDraftSuccess } from 'containers/engagement/message/CreateMessage';
import { validate } from 'containers/engagement/message/CreateMessageValidate';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  selectedChannels: state.createMessage.selectedChannels,
});

function CreateMessageStepTwoSMS({ languages, selectedChannels, setShowSentPrompt }) {
  const dispatch = useDispatch();
  const params = useParams();

  const { getValues, setValue, setError, clearErrors, watch } = useFormContext();
  const approvalStatus = watch('approvalStatus');
  const isPublished = approvalStatus === ApprovalStatus.published

  let smsStep = 1;
  if (MessageStepConfig[1].show(selectedChannels)) {
    smsStep = smsStep + 1;
  }
  if (MessageStepConfig[2].show(selectedChannels)) {
    smsStep = smsStep + 1;
  }

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
      languages: languages,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: smsStep,
        isValid,
      },
    });
  };

  const saveTempAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack: false,
      languages: languages,
    });
    if (isValid && !isPublished) {
      dispatch(
        createAction('createMessage/saveAsDraftMessage')({
          values: getValues(),
          afterActions: (tempSavePk, translationPks) => {
            afterSaveASDraftSuccess({ setValue, tempSavePk, translationPks })
          },
        }),
      );
    } else if (isValid && isPublished) {
      dispatch(
        createAction('createMessage/createOrUpdateMessage')({
          values: getValues(),
          actionType: ActionType.tempForPulished,
          afterActions: () => {
            setShowSentPrompt(true);
          },
        }),
      );
    } else {
      dispatch({
        type: 'createMessage/stepChange',
        payload: {
          isBack: false,
          step: smsStep,
          isValid,
        },
      });
    }
  };

  const renderTabForLanguage = (language) => {
    return [<MessageGeneralSectionSMS language={language} />];
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: [renderTabForLanguage(item.code)],
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <ContinueWithBackAndTempSaveButtons
        backAction={() => stepChangeAction(true)}
        saveTempText={params.id ? 'Update' : null}
        saveTempAction={() => saveTempAction()}
        continueAction={() => stepChangeAction(false)}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateMessageStepTwoSMS);

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import { SavedStatus } from '../../../config/CustomEnums';
import CloseIcon from '../../../assets/images/prompt_close_icon.svg';
import ContentSection from '../../../components/recencyFrequencyMonetaryScoreSetting/ContentSection'
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import { errorMessagesList } from 'models/RecencyFrequencyMonetaryScoreSettingModel';

const UpdateRecencyFrequencyMonetaryScoreSetting = ({
  saved,
  errorMessages,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      dispatch({
        type: 'recencyFrequencyMonetaryScoreSetting/getList',
        payload: {},
      });
    }
  }, [dispatch, show]);

  const onClose = () => {
    setShow(false);
    dispatch({
      type: 'recencyFrequencyMonetaryScoreSetting/clearData',
      payload: {},
    })
  }

  useEffect(() => {
    if (saved === SavedStatus.savedWithSuccess) {
      onClose();
    };
  }, [saved]);

  const saveAction = () => {
    dispatch({
      type: 'recencyFrequencyMonetaryScoreSetting/update',
      payload: {},
    })
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={onClose}
        />
        <ContentSection />

        <div className="base-prompt-buttons">
          <div className='recency-frequency-monetary-score-setting-error-container'>
            {
              [errorMessagesList.overlapped, errorMessagesList.notConsecutive, errorMessagesList.required].map((item, index) => (
                <label
                  key={index}
                  className='recency-frequency-monetary-score-setting-error-message'
                  hidden={errorMessages.indexOf(item.name) < 0}
                >
                  {item.message}
                </label>
              ))
            }
          </div>
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Save'
            action={saveAction}
            requires={PermissionCodes.changeSegment}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  saved: state.recencyFrequencyMonetaryScoreSetting.saved,
  errorMessages: state.recencyFrequencyMonetaryScoreSetting.errorMessages,
});

export default connect(mapPropsToState)(UpdateRecencyFrequencyMonetaryScoreSetting);

import React from 'react';
import { useSelector } from 'react-redux';
import '../../campaign/campaignList/CampaignOverviewTab.scss';
import OverviewCard from '../../dashboard/OverviewCard';

const CAMPAINGNS_DATA_LIST_CONFIG = [
  {
    title: 'Total number of published stamp campaign',
    key: 'totalNumberOfPublishedStampCampaign',
  },
  {
    title: 'Total number of stamp campaign conversion',
    key: 'totalNumberOfStampCampaignConversion',
  },
  {
    title: 'Stamp Coupon campaign conversion rate',
    key: 'stampCampaignConversionRate',
    showPercentage: true,
  },
  {
    title: 'Stamp campaign conversion rate',
    key: 'stampCampaignConversionRate',
    showPercentage: true,
  },
  {
    title: 'Total number of view',
    key: 'stampCampaignTotalNumberOfView',
  },
  {
    title: 'Campaign share rate',
    key: 'stampCampaignShareRate',
    showPercentage: true,
  },
];

const STAMPS_DATA_LIST_CONFIG = [
    {
        title:'Stamp earned in stamp campaign',
        key:'stampEarnedInStampCampaign'
    },
    {
        title:'Total number of times of rewards earned in stamp campaign',
        key:'totalNumberOfTimesOfRewardsEarnedInStampCampaign'
    },
]

const POINTS_DATA_LIST_CONFIG = [
  {
    title: 'Points earned in stamp',
    key: 'pointsEarnedInStampCampaign',
  },
];

const COUPOINS_DATA_LIST_CONFIG = [
  {
    title: 'Coupon acquired in stamp campaign',
    key: 'couponAcquiredInStampCampaign',
  },
];

const StampCampaignOverviewTab = () => {
  const campaignOverviewData = useSelector((state) => {
    return state.overview.stampCampaigns});
  return (
    <div>
      <div className="overview-row-title overview-campaigns-title">
        Stamp campaigns
      </div>
      <div className="overview-row">
        {CAMPAINGNS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
            showPercentage={config.showPercentage}
          />
        ))}
      </div>

      <div className="overview-row-title">stamps</div>
      <div className="overview-row">
        {STAMPS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
          />
        ))}
      </div>

      <div className="overview-row-title">points</div>
      <div className="overview-row">
        {POINTS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
          />
        ))}
      </div>

      <div className="overview-row-title">coupons</div>
      <div className="overview-row">
        {COUPOINS_DATA_LIST_CONFIG.map((config, index) => (
          <OverviewCard
            title={config.title}
            value={campaignOverviewData[config.key]?.value}
            change={campaignOverviewData[config.key]?.change}
          />
        ))}
      </div>
    </div>
  );
};

export default StampCampaignOverviewTab;

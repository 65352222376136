import React from 'react';
import { useFormContext } from 'react-hook-form';

import { errorMessage, hasError } from 'components/base/ErrorFieldMessage';
import {
  campaignAvailableClue,
  campaignLimitDate,
  disableSwitchToAllPeriod,
  earningRuleAvailableClue,
} from 'components/campaign/campaignCreation/CreateCampaignUtil';
import PeriodAndWeekdaySection from 'components/base/period/PeriodAndWeekdaySection';
import PeriodSection from 'components/base/period/PeriodSection';

import { compareDate } from 'utils/TimeFormatUtil';

function ActivePeriodSection(props) {
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isAlwaysActivePeriod = watch('isAlwaysActivePeriod');
  const activeStartDate = watch('activeStartDate');
  const activeEndDate = watch('activeEndDate');
  const watchEarningRules = watch('linkedEarningRules');
  const periodValues = watch('activePeriodValues');
  const weekday = watch('activeWeekday');
  const birthdayStartDate = watchEarningRules?.birthdayTypePeriodStartDate;
  const birthdayEndDate = watchEarningRules?.birthdayTypePeriodEndDate;
  const earningRuleType = watchEarningRules?.type;
  const {
    forEarningRule: forEarningRule = false,
    showBottomTips: showBottomTips = false,
    bottomTips: bottomTips = null,
    disableBlackout: disableBlackout = false,
  } = props;

  return (
    <>
      <PeriodSection
        disabled={disableSwitchToAllPeriod(
          earningRuleType,
          birthdayStartDate,
          birthdayEndDate,
        )}
        title="Active Period"
        startErrorObject={{
          id: 'activeStartDate',
          error: hasError(errors, 'activeStartDate'),
          message: errorMessage(errors, 'activeStartDate'),
        }}
        endErrorObject={{
          id: 'activeEndDate',
          error: hasError(errors, 'activeEndDate'),
          message: errorMessage(errors, 'activeEndDate'),
        }}
        endDateChange={(date) => {
          setValue('activeEndDate', date, { shouldDirty: true });
        }}
        maxDate={campaignLimitDate(earningRuleType, birthdayStartDate)}
        minDate={campaignLimitDate(earningRuleType, birthdayEndDate)}
        titleDescription={
          forEarningRule
            ? earningRuleAvailableClue()
            : campaignAvailableClue(earningRuleType)
        }
        switchButtonTitle="It is an all-time active campaign"
        isAlwaysPeriod={isAlwaysActivePeriod}
        switchOnChange={(value) => {
          setValue('isAlwaysActivePeriod', value, { shouldDirty: true });
        }}
        startDate={compareDate(activeStartDate, birthdayStartDate, false)}
        startDateChange={(date) => {
          setValue('activeStartDate', date, { shouldDirty: true });
        }}
        endDate={compareDate(activeEndDate, birthdayEndDate, true)}
      />
      {!disableBlackout && (
        <PeriodAndWeekdaySection
          switchButtonTitle="Blackout period"
          periodValuesField="activePeriodValues"
          periodValues={periodValues}
          periodValuesChange={(data) => {
            setValue('activePeriodValues', data, { shouldDirty: true });
          }}
          weekday={weekday}
          weekdayChange={(data) => {
            setValue('activeWeekday', data, { shouldDirty: true });
          }}
          maxDate={activeEndDate}
          minDate={activeStartDate}
          disabledDate={!isAlwaysActivePeriod}
        />
      )}
      {showBottomTips && bottomTips && (
        <div className="tips-message" style={{ lineHeight: '18.2px' }}>
          {bottomTips}
        </div>
      )}
    </>
  );
}

export default ActivePeriodSection;

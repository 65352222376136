import React from 'react';
import './OverviewCard.scss';
import AnimatedNumber from 'animated-number-react';
import { getValueChangeLabel } from './CustomersCard';
import { ANIMATION_DURATION } from '../../config/DashboardConfig';
import { enLocaleNumberFormatter } from '../../utils';

const OverviewCard = ({ title, value, change, showPercentage }) => {
  return (
    <div className="overview-card-container">
      <div className="overview-card-title">{title}</div>
      <AnimatedNumber
        className="overview-card-value"
        duration={ANIMATION_DURATION}
        value={value}
        formatValue={(input) => {
          if (showPercentage) {
            return `${((input || 0) * 100).toFixed(0)}%`;
          }
          return enLocaleNumberFormatter(input.toFixed(0));
        }}
      />
      {getValueChangeLabel(change)}
    </div>
  );
};
export default OverviewCard;

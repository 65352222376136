import React from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SaveAndBackWithOutTempButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import ActivePeriodSection from 'components/base/period/ActivePeriodSection';
import BirthdaySection from 'components/earning/BirthdaySection';
import FillingFormSection from 'components/earning/FillingFormSection';
import GPSCheckInSection from 'components/earning/GPSCheckInSection';
import GeneralPurchaseSection from 'components/earning/GeneralPurchaseSection';
import LevelUpgradeSection from 'components/earning/LevelUpgradeSection';
import LimitSection, {
  LevelUpgradePerHeadPeriodicLimitSection,
} from 'components/earning/LimitSection';
import QRCodeScanningSection from 'components/earning/QRCodeScanningSection';
import ReferralSection from 'components/earning/ReferralSection';
import RewardSection from 'components/earning/RewardSection';
import TargetCustomersSection from 'components/earning/TargetCustomersSection';
import { EarningRuleType } from 'config/CustomEnums';

import { validate } from './CreateEarningRuleValidate';

import { createAction } from 'utils';

function CreateEarningruleStepThree({ earningRule }) {
  const { getValues, clearErrors, setError, watch } = useFormContext();
  const [earningRuleType] = getValues(['type']);
  const linkedStampCampaign = watch('linkedStampCampaign');

  const history = useHistory();

  const dispatch = useDispatch();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    if (isBack) {
      dispatch({
        type: 'createEarningRules/stepChange',
        payload: { isBack: isBack, step: 2, isValid },
      });
    } else if (isValid) {
      dispatch(
        createAction('createEarningRules/createOrUpdateEarningRule')({
          isValid: isValid,
          data: getValues(),
          afterAction: earningRule.id
            ? () => history.push({ pathname: '/earns' })
            : null,
        }),
      );
    }
  };

  const earningRuleProperties = () => {
    let properties;
    switch (earningRuleType) {
      case EarningRuleType.newMember:
        properties = [
          <TargetCustomersSection />,
          <ActivePeriodSection forEarningRule />,
        ];
        break;
      case EarningRuleType.generalPurchase:
        properties = [
          <GeneralPurchaseSection />,
          <RewardSection />,
          <LimitSection
            tips={{
              overallLimit:
                'Maximum number of times to issue rewards from this campaign.',
              periodicLimit:
                'Maximum number of times to issue rewards from this campaign, within a time period. ',
              perHeadLimit:
                'Maximum number of times to issue rewards from this campaign, per customer.',
              perHeadPeriodicLimit:
                'Maximum number of times to issue rewards from this campaign, per customer, within a time period. ',
            }}
          />,
        ];
        if (!linkedStampCampaign) {
          properties.splice(1, 0, <TargetCustomersSection />);
          properties.splice(2, 0, <ActivePeriodSection forEarningRule />);
        }
        break;
      case EarningRuleType.gpsCheckIn:
        properties = [<GPSCheckInSection />];
        break;
      case EarningRuleType.memberReferral:
        properties = [<ReferralSection />];
        break;
      case EarningRuleType.birthday:
        const showBottomTips = watch('isAlwaysActivePeriod');
        properties = [
          <BirthdaySection />,
          <TargetCustomersSection />,
          <ActivePeriodSection
            showBottomTips={showBottomTips}
            forEarningRule
            bottomTips={
              'The campaign will be active every year; Every day,\
            the system will check and the rewards will be auto distributed (add to wallet)\
            to the eligible customers,i.e. On the 1st day of every month or the rest of the\
            days in that month for those newly signed-up members who have the birthday in that month.'
            }
          />,
        ];
        break;
      case EarningRuleType.qrCodeScanning:
        properties = [<QRCodeScanningSection />];
        break;
      case EarningRuleType.fillingForm:
        properties = [
          <TargetCustomersSection />,
          <ActivePeriodSection forEarningRule />,
          <RewardSection />,
          <FillingFormSection />,
        ];
        break;
      case EarningRuleType.levelUpgrade:
        properties = [
          <LevelUpgradeSection />,
          <RewardSection />,
          <LevelUpgradePerHeadPeriodicLimitSection />,
        ];
        if (!linkedStampCampaign) {
          properties.splice(1, 0, <TargetCustomersSection />);
          properties.splice(2, 0, <ActivePeriodSection forEarningRule />);
        }
        break;
      default:
        properties = [];
        break;
    }
    return properties;
  };

  // const earningRulePropertie = earningRuleProperties();
  let sections = [...earningRuleProperties()];
  if (
    earningRuleType !== EarningRuleType.generalPurchase &&
    earningRuleType !== EarningRuleType.fillingForm &&
    earningRuleType !== EarningRuleType.levelUpgrade
  ) {
    sections.push(<RewardSection />);
  }

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule,
});

export default connect(mapPropsToState)(CreateEarningruleStepThree);

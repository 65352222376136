import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import queryString from 'query-string';

import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';

import { getHashKeyString } from 'utils';
import CustomerCampaignListFilter from './CustomerCampaignListFilter';

function CustomerExclusiveCampaignList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = getHashKeyString(location.hash);
  const params = useParams();
  const customerId = params.id;

  useEffect(() => {
    if (hash !== "Exclusive campaign") {
      return;
    };
    dispatch({
      type: 'campaignCategoryList/getCampaignCategoryList',
      payload: { isAll: true },
    })
  }, [dispatch, hash])

  useEffect(() => {
    if (hash !== "Exclusive campaign") {
      return;
    }
    const parsedSearch = queryString.parse(location.search);
    const searchKey = parsedSearch['search'] || '';
    const stringPage = parsedSearch['page'] || 1;
    const page = parseInt(stringPage);
    const categoryList = parsedSearch['categories'] || '';

    dispatch({
      type: 'campaignList/getCustomerDetailCampaignList',
      payload: {
        page,
        customerId,
        searchKey,
        categoryList,
        isStamp: false,
      },
    });
  }, [dispatch, location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={true}
        maxTabWidth={670}
        groupActions={[]}
        pageInfo={pageInfo}
        totalCount={totalCount}
        filter={{ hasFilter: true, component: CustomerCampaignListFilter }}
        listContent={
          <BaseListContainer
            fields={listFields}
            dataList={dataList}
            totalPage={totalPage}
            model={'campaignList'}
            permissionGroup={PermissionCodes.campaign}
            deleteInfo={{
              data: [],
              title: 'Campaigns',
              relatedName: '',
              onComfirm: {},
              relatedSections: null,
            }}
            hideActions
            openNewTab
            customPathname="/campaigns"
          />
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.campaignList.customerDetailExclusiveListFields,
  dataList: state.campaignList.pagedList,
  pageInfo: state.campaignList.pageInfo,
  totalPage: state.campaignList.totalPage,
  totalCount: state.campaignList.totalCount,
});

export default connect(mapPropsToState)(CustomerExclusiveCampaignList);

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import CustomerFilterableDropdown from 'components/transactions/CustomerFilterableDropdown';


const EditMembershipModal = ({
  customer,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [referral, setReferral] = useState(customer.referrerUser);

  const onClose = () => {
    setShow(false);
  };

  const saveAction = () => {
    dispatch({
      type: 'customerList/updateCustomer',
      payload: {
        data: {
          ...customer,
          referrerUser: referral,
        },
        customer: customer,
        refreshCustomer: true,
      },
    });
  };

  useEffect(() => {
    if (show) {
      setReferral(customer.referrerUser);
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>Edit membership</label>

        <CustomerFilterableDropdown
          title={'Referrer(Optional)'}
          description='Changes will not trigger any referral earning logic, it is for record purpose only.'
          defaultSelectedCustomer={referral}
          selectCustomer={(value) => {
            setReferral(value);
          }}
        />

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm'
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
});

export default connect(mapPropsToState)(EditMembershipModal);

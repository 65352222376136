import React from 'react';
import dompurify from 'dompurify';
const showdown = require('showdown');
const converter = new showdown.Converter();

dompurify.addHook('uponSanitizeElement', function(currentNode, data, config) {
  data.allowedTags.iframe = true;
  if (currentNode.tagName === 'IMG') {
    let img = new Image();
    img.src = currentNode.getAttribute('src');
    const imageWidth = currentNode.getAttribute('width') || img.width;
    if (imageWidth > 1080) {
      currentNode.setAttribute('width', '100%');
      currentNode.setAttribute('height', 'auto');
    }
  }
  if (currentNode.tagName === 'IFRAME') {
    const width = currentNode.getAttribute('width');
    const height = currentNode.getAttribute('height');
    const scale = width / height;
    if (width > 1080) {
      currentNode.setAttribute('width', '1080px');
      currentNode.setAttribute('height', `${1080 / scale}px`);
    }
  }
});

function CustomViewer({ initValue, className, onClick = () => {} }) {
  let clearHtml = dompurify.sanitize(initValue);
  clearHtml = clearHtml.replaceAll('&gt;', '>');
  return (
    <label
      className={className}
      onClick={onClick}
      // dangerouslySetInnerHTML={{
      //   __html: initValue || '-'
      // }}
      // style={{maxWidth: '100%'}}
      dangerouslySetInnerHTML={{
        __html: initValue ? converter.makeHtml(clearHtml) : '-',
      }}
    ></label>
  );
}

export default CustomViewer;

import React from 'react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './CustomSwiper.scss';
import OnboardingSlideOne from './OnboardingSlideOne';
import OnboardingSlideTwo from './OnboardingSlideTwo';
import OnboardingSlideTwoVertical from './OnboardingSlideTwoVertical';
import OnboardingSlideThree from './OnboardingSlideThree';
import OnboardingSlideThreeVertical from './OnboardingSlideThreeVertical';
SwiperCore.use([Pagination, Autoplay]);

function CustomSwiper({ width, height }) {
  return (
    <Swiper
      init={true}
      autoplay={{
        stopOnLastSlide: true,
        disableOnInteraction: false,
        delay: 3000,
      }}
      className={`custom-swiper-container`}
      pagination={{ clickable: true }}
      activeDotColor="#000"
      observer={true}
    >
      <SwiperSlide key={`slide-1`} className="swiper-slide">
        <OnboardingSlideOne width={width} height={height} />
      </SwiperSlide>
      <SwiperSlide key={`slide-2`} className="swiper-slide">
        {width >= 950 ? (
          <OnboardingSlideTwo width={width} height={height} />
        ) : (
          <OnboardingSlideTwoVertical width={width} height={height} />
        )}
      </SwiperSlide>
      <SwiperSlide key={`slide-3`} className="swiper-slide">
        {width >= 950 ? (
          <OnboardingSlideThree width={width} height={height} />
        ) : (
          <OnboardingSlideThreeVertical />
        )}
      </SwiperSlide>
    </Swiper>
  );
}

export default CustomSwiper;

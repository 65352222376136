import { CampaignType } from '../../../config/CustomEnums';

export const CRATE_COUPON_SET = 'Create coupon set';
export const GENERATE_COUPON = 'Generate coupon';
export const CREATE_COUPON_CAMPAIGN = 'Create coupon campaign';
export const NOTIFY_YOUR_CUSTOMER = 'Notify your Customer';
export const CREATE_EARNING_RULE = 'Create earning rule';
export const CREATE_EARNING_CAMPAIGN = 'Create earning campaign';
export const CREATE_GENERAL_MESSAGE_CAMPAIGN = 'Create general message campaign';
export const CREATE_STORE_CATEGORY = 'Create store category';
export const CREATE_STORE = 'Create store';
export const CREATE_STAMP_CAMPAIGN = 'Create stamp campaign';

export const getCampaignStepBarNameWithType = (type) => {
  if (type === CampaignType.generalMessageCampaign) {
    return CREATE_GENERAL_MESSAGE_CAMPAIGN;
  }
  if (type === CampaignType.couponsCampaign) {
    return CREATE_COUPON_CAMPAIGN;
  }
  if (type === CampaignType.stampCampaign) {
    return CREATE_STAMP_CAMPAIGN;
  }
  return CREATE_EARNING_CAMPAIGN;
};

export const getCampaignCreateSuccessStepBar = (type, previousStepExist) => {
  console.log("CampaignCreateSuccess",type,"2rd",previousStepExist)
  const stepList = [NOTIFY_YOUR_CUSTOMER];
  if (type === CampaignType.generalMessageCampaign) {
    stepList.unshift(CREATE_GENERAL_MESSAGE_CAMPAIGN);
    if (previousStepExist) {
      stepList.unshift(CREATE_EARNING_RULE);
    }
    return stepList;
  }
  if (type === CampaignType.couponsCampaign) {
    stepList.unshift(CREATE_COUPON_CAMPAIGN);
    if (previousStepExist) {
      stepList.unshift(GENERATE_COUPON);
      stepList.unshift(CRATE_COUPON_SET);
    }
    return stepList;
  }
  if (type === CampaignType.stampCampaign) {
    if (previousStepExist) {
      stepList.unshift(CREATE_EARNING_RULE);  
    }
    stepList.unshift(CREATE_STAMP_CAMPAIGN);
    return stepList;
  }
  stepList.unshift(CREATE_EARNING_CAMPAIGN);
  if (previousStepExist) {
    stepList.unshift(CREATE_EARNING_RULE);
  }
  return stepList;
};

export const getStoreCreateSuccessStepBar = (previousStepExist) => {
  const stepList = [CREATE_STORE];
  if (previousStepExist) {
    stepList.unshift(CREATE_STORE_CATEGORY);
  }
  return stepList;
};

import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

export const getCountryCodes = (values) => {
  const query = `
  query CountryCodes {
    countryCodes(first: 100, orderBy: "pk") {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'I18n';

import { SectionTopTitle } from 'components/base/CommonComponent';
import { ReactHookFormMarkDown } from 'components/base/CustomMarkDownArea';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import InputFieldControl from 'components/base/InputFieldControl';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { ContinueCreate } from 'components/campaign/CampaignUrlConfig';
import BrandSelectDropdown from 'components/campaign/campaignCreation/BrandSelectDropdown';
import { LanguageConfig } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import './GeneralSection.scss';

function GeneralSection({ storeList, language, onFocus = () => {} }) {

  const history = useHistory();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;

  const nameField = `translations.${language}.generalName`;
  const watchBrand = watch('linkedBrand') || {};
  const translations = watch('translations') || {};
  const watchName = watch(nameField);
  // const watchStores = watch('linkedStores') || [];
  const watchHideInAnyChannel = watch('hideInAnyChannel');

  const [showAddBrand, setShowAddBrand] = useState(false);

  return (
    <>
      <SectionTopTitle title={i18n.t('general', { locale: language })} />
      <InputFieldControl
        name={nameField}
        title={i18n.t('name', { locale: language })}
        value={watchName}
        setValue={(value) => {
          setValue(nameField, value, { shouldDirty: true });
        }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      {!watchHideInAnyChannel && (
        <>
          <ReactHookFormMarkDown
            content={{
              label: i18n.t('short_description_optional', { locale: language }),
              error:
                language === LanguageConfig.english
                  ? hasError(
                      errors,
                      `translations.${language}.shortDescription`,
                    )
                  : false,
              key: `translations.${language}.shortDescription`,
              focus: () => {
                onFocus('shortDescription');
              },
            }}
          />
          <ReactHookFormErrorMessage
            errors={errors}
            id={`translations.${language}.shortDescription`}
          />
          <div
            className="create-campaign-brand-select"
            onFocus={() => onFocus('relatedBrand')}
          >
            <BrandSelectDropdown
              title={i18n.t('related_brand_optional', { locale: language })}
              defaultValue={{ label: watchBrand?.name, value: watchBrand }}
              onSelectChange={(value) => {
                setValue('linkedBrand', value?.value || {}, {
                  shouldDirty: true,
                });
              }}
              addButton={{
                action: () => setShowAddBrand(true),
                title: 'Create Now',
                requires: PermissionCodes.addBrand,
                customClass:
                  'general-section-add-new-brand btn-add-button-common',
              }}
              editable={language === LanguageConfig.english}
            />
          </div>
        </>
      )}

      <BasePrompt
        show={showAddBrand}
        closeAction={() => setShowAddBrand(false)}
        rightButton={{
          text: 'Create Brand',
          action: () =>
            history.push({
              pathname: '/brands/create',
              state: {
                from: {
                  ...history.location,
                  state: {
                    ...history.location?.state,
                    resume: true,
                  },
                },
                ...ContinueCreate,
              },
            }),
        }}
        title={'Go to create brand?'}
        description={`You will leave the campaign creation process. After you create a new brand, you can back to this page.`}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  storeList: state.storeModel.storeList,
});

export default connect(mapPropsToState)(GeneralSection);

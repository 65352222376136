import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getError, createAction, isNumber } from '../../utils';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';
import { SavedStatus } from '../../config/CustomEnums';
import BasePrompt from '../../components/base/prompt/BasePrompt';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import './ChangePasswordPrompt.scss';

const TIMER_LENGTH = 59;

function ChangePasswordPrompt(props) {
  const defaultShow = props.show;
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const admin = props.admin;
  const includeOTP = props.includeOTP || false
  const hideCloseIcon = props.hideCloseIcon || false
  const hideDescription = props.hideDescription || false
  const username = props.admin?.username || ''

  const { changePasswordStatus, changePasswordError } = useSelector((state) => ({
    changePasswordStatus: state.admin.changePasswordStatus,
    changePasswordError: state.admin.changePasswordError,
  }));

  const [errorFields, setErrorFields] = useState({ fields: [], messages: [] });
  const [show, setShow] = useState(defaultShow);

  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [code, setCode] = useState('')
  const [sendLoading, setSendLoading] = useState(false);
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  const [loadingSeconds, setLoadingSeconds] = useState(TIMER_LENGTH);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (!password) {
      setErrorFields({
        fields: ['password'],
        messages: [
          {
            field: 'password',
            errorType: 'required',
          },
        ],
      });
    } else if (password.length < 8) {
      setErrorFields({
        fields: ['password'],
        messages: [
          {
            field: 'password',
            errorType: 'length',
          },
        ],
      });
    } else if (isNumber(password)) {
      setErrorFields({
        fields: ['password'],
        messages: [
          {
            field: 'password',
            errorType: 'numeric',
          },
        ],
      });
    } else if (password !== passwordConfirmation) {
      setErrorFields({
        fields: ['passwordConfirmation'],
        messages: [
          {
            field: 'passwordConfirmation',
            errorType: 'valid',
          },
        ],
      });
    } else {
      setErrorFields({ fields: [], messages: [] })
      dispatch(
        createAction('admin/changePassword')({
          data: {
            id: admin?.pk,
            username: username,
            password1: password,
            password2: passwordConfirmation,
            method: 'RESET_PASSWORD',
            code
          },
        }),
      );
    }
  };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleSendCode = () => {
    setSendButtonLoading(true);
    dispatch(createAction('users/sendCode')({
      data: {
        username,
        method: 'RESET_PASSWORD',
      },
      handleSuccess: () => {
        setSendLoading(true);
      },
      afterAction: () => {
        setSendButtonLoading(false);
      },
    }));
  }

  useEffect(() => {
    setShow(props.show);
    dispatch(createAction('admin/updateState')({
      changePasswordStatus: SavedStatus.init,
      changePasswordError: {}
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    if (changePasswordStatus === SavedStatus.savedWithSuccess) {
      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: 'Password reset complete.',
          },
        },
      });
      setShow(false);
      onConfirm();
      dispatch(createAction('admin/updateState')({
        changePasswordStatus: SavedStatus.init,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordStatus]);

  useEffect(() => {
    const loadingInterval =
      sendLoading &&
      loadingSeconds > 0 &&
      setInterval(() => {
        if (loadingSeconds > 1) {
          setLoadingSeconds(loadingSeconds - 1);
        } else {
          setSendLoading(false);
          setLoadingSeconds(TIMER_LENGTH);
        }
      }, 1000);
    return () => {
      clearInterval(loadingInterval);
    };
  }, [sendLoading, loadingSeconds]);

  const otherBody = () => {
    return (
      <>
        {includeOTP ?
          <div className="d-flex flex-column">
            <CustomTitleLabel title={'Account'} />
            <label className='change-password-prompt-text'>{username}</label>
          </div>
          : null
        }
        <CustomTitleWithInput
          title={includeOTP ? 'New Password' : 'Password'}
          defaultValue={''}
          setValue={setPassword}
          error={getError('password', errorFields, AdminErrorHandleFields)}
          type="password"
          tips="Password at least 8 characters, can't be entirely numeric."
        />

        <CustomTitleWithInput
          title={'Password confirmation'}
          defaultValue={''}
          setValue={setPasswordConfirmation}
          error={getError(
            'passwordConfirmation',
            errorFields,
            AdminErrorHandleFields,
          )}
          type="password"
          tips="Enter the same password as before, for verification"
        />
        {includeOTP ? <>
          <div className="otp-code-container">
            <CustomTitleLabel
              title={'Verification code'}
            />
            <div className="d-flex">
              <input
                defaultValue={''}
                type={'number'}
                name={'code'}
                className={`custom-markdown-area-title opt-code-input`}
                onChange={(e) => {
                  setCode(e.target.value)
                }}
              />
              <Button
                className="btn-further otp-code-send-button"
                onClick={() => {
                  handleSendCode();
                }}
                disabled={
                  sendLoading ||
                  !username ||
                  !password ||
                  !passwordConfirmation ||
                  sendButtonLoading
                }
              >
                {sendLoading ? `${loadingSeconds}s` : 'Send code'}
              </Button>
            </div>
            {changePasswordError?.code ? 
              <ErrorFieldMessage 
                id={changePasswordError?.code?.id}
                error={changePasswordError?.code?.error} 
                message={changePasswordError?.code?.message} 
              /> : null
            }
          </div>
          <div className="d-flex flex-column">
            <Button
              type="submit"
              className="btn-further change-password-comfirm-btn"
              disabled={!password || !passwordConfirmation || !code}
              onClick={handleConfirm}
            >
              {'Confirm'}
            </Button>
            <Button
              type="button"
              variant={'link'}
              className="change-password-comfirm-btn change-password-cancel-btn"
              onClick={handleClose}
            >
              {'Cancel'}
            </Button>
          </div>
        </>
          : null}
      </>
    );
  };

  return (
    <BasePrompt
      show={show}
      closeAction={handleClose}
      title={props.title || `Change password: ${admin.username}`}
      description={hideDescription ? '' : `Enter a new password for the user ${admin.username}`}
      otherBody={otherBody}
      hideCloseIcon={hideCloseIcon}
      disableClickBackground={true}
      {...(!includeOTP ? {
        rightButton: {
          text: 'Change password',
          action: handleConfirm,
        }
      } : null
      )}
    />
  );
}

export default ChangePasswordPrompt;

import {
  CheckStatus,
  LanguageTransLation,
  SIGN_UP_METHOD,
  SIGN_UP_METHOD_DISPLAY,
  SavedStatus,
  StatusTag,
} from 'config/CustomEnums';
import { getErrorList } from 'containers/customers/customer/CustomerEditValidate';
import { apiWithResponseHandle, loading } from 'models/LoadingUtil';
import {
  activeCustomer,
  createCustomerRemark,
  deactiveCustomer,
  deleteCustomerRemark,
  deleteCustomers,
  getAllCustomers,
  getCustomerActivityLog,
  getPagedCustomerAdditionalDataFields,
  getCustomerGroups,
  getCustomerReportsByPage,
  getCustomersByPage,
  getOneCustomer,
  updateCustomer,
} from 'services/CustomerAPIHelper';

import {
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  delay,
  formatNumberWithCommas,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from 'utils';
import {
  TimeFormater,
  formatDate,
  getRemarkCreationDateFormatted,
} from 'utils/TimeFormatUtil';

const customerSessionKey = 'tempCustomer';
const getInitialState = () => ({
  customerList: [],
  tempCustomerList: [],
  customerGroup: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name (preferred name)',
      fieldName: 'name',
      orderField: 'firstName',
    },
    { displayName: 'Test Customer', fieldName: 'testCustomer' },
    { displayName: 'Mobile number', fieldName: 'mobileNumber' },
    { displayName: 'Email', fieldName: 'email' },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    { displayName: 'Current Level', fieldName: 'level', orderField: 'level' },
    { displayName: 'Segment', fieldName: 'segment' },
    { displayName: 'Acquisition channel', fieldName: 'acquisitionChannel' },
    { displayName: 'Additional data', fieldName: 'displayExtraData' },
    { displayName: 'Status', fieldName: 'status' },
  ],
  reportListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', linked: false },
    { displayName: 'Report type', fieldName: 'reportType' },
    { displayName: 'Time range', fieldName: 'displayTimeRange' },
    { displayName: 'Create at', fieldName: 'creationDate' },
  ],
  pagedCustomerList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  listTotalCount: 0,
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  customer: {},
  checked: CheckStatus.initOrNotChecked,
  errorsList: [],
  test: false,
  saved: SavedStatus.init,
  activityLogs: [],
  formChanged: false,
  filters: {
    groups: [],
    segments: [],
    levels: [],
    genders: [],
    age: [0, 0],
    startDate: '',
    endDate: '',
    count: 0,
  },
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  pagedCustomerReportList: [],
  customerAdditionalDataFields: [],
  customerProfileLoading: false,
});

const getLevelPrivilege = (level) => {
  let levelPrivileges = [];
  switch (level) {
    case 'Level 1':
      levelPrivileges = ['Discount for the transaction'];
      break;
    case 'Level 2':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
      ];
      break;
    case 'Level 3':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
        '7 days insured',
      ];
      break;
    case 'Level 4':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
        '7 days insured',
        '24h Customer service',
      ];
      break;
    default:
      levelPrivileges = [];
      break;
  }

  return levelPrivileges;
};

const getReferralSource = (item) => {
  let referralSource = {};
  const referrer = item.referrer
    ? `[${item.referrer.membershipId}] ${item.referrer.firstName} ${item.referrer.lastName}`
    : '';
  let referredCampaigns = item.referredByCampaigns?.map(campaign => `[${campaign.pk}] ${campaign.name}`)

  if (referrer) {
    referralSource["referrer"] = referrer;
  }

  if (referredCampaigns?.length <= 0) {
    referredCampaigns = item.referredByCampaign?.pk
      ? [`[${item.referredByCampaign.pk}] ${item.referredByCampaign.name}`]
      : []
  }
  referralSource["referredCampaigns"] = referredCampaigns;

  return referralSource;
};

export const CustomerStatus = {
  active: 'active',
  deactive: 'deactive',
  preActive: 'pre_active',
  temporaryEarning: 'temporary_earning',
  temporary: 'temporary',
  deleteRequested: 'delete_requested',
};

const getDisplayStatus = (status) => {
  switch (status) {
    case CustomerStatus.active:
      return StatusTag.active;
    case CustomerStatus.deactive:
      return StatusTag.deactive;
    // case CustomerStatus.preActive:
    //   return StatusTag.preActive;
    case CustomerStatus.temporaryEarning:
      return StatusTag.temporaryEarning;
    case CustomerStatus.temporary:
      return StatusTag.temporary;
    case CustomerStatus.deleteRequested:
      return StatusTag.deleteRequested;
    default:
      return '-';
  }
};

export const CustomerAcquisitionChannel = {
  IMPORTED_BY_CSV: 'IMPORTED_BY_CSV',
  ONLINE_STORE: 'ONLINE_STORE',
  OFFLINE_STORE: 'OFFLINE_STORE',
};
export const CustomerAcquisitionChannelDisplay = {
  IMPORTED_BY_CSV: 'Imported by csv',
  ONLINE_STORE: 'Online store',
  OFFLINE_STORE: 'Offline store',
};

const getDisplayAcquisitionChannel = (channel) => {
  switch (channel) {
    case CustomerAcquisitionChannel.IMPORTED_BY_CSV:
      return CustomerAcquisitionChannelDisplay.IMPORTED_BY_CSV;
    case CustomerAcquisitionChannel.ONLINE_STORE:
      return CustomerAcquisitionChannelDisplay.ONLINE_STORE;
    case CustomerAcquisitionChannel.OFFLINE_STORE:
      return CustomerAcquisitionChannelDisplay.OFFLINE_STORE;
    default:
      return '-';
  }
};

const isPlural = (data) => {
  return data >= 2;
};

const getDisplayJoinedTime = (creationDate) => {
  const now = new Date();
  const joinedDate = new Date(creationDate);
  const deltaMonths = (now.getFullYear() - joinedDate.getFullYear()) * 12 + now.getMonth() - joinedDate.getMonth();
  const year = parseInt(deltaMonths / 12);
  const month = deltaMonths % 12;

  const yearComment = year ? ` ${year} year${isPlural(year) ? 's' : ''}` : '';
  const monthComment = year && !month ? '' : ` ${month} month${isPlural(month) ? 's' : ''}`;
  const andComment = yearComment && monthComment ? ' and' : '';

  return `Joined${yearComment}${andComment}${monthComment} (${formatDate(joinedDate)})`
}

const getDisplayLevelRenewalDate = (levelRenewalDate) => {
  const now = new Date();
  const renewalDate = new Date(levelRenewalDate);

  const seconds = renewalDate.getTime() - now.getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const deltaDays = parseInt(seconds / oneDay);

  return `Level renewal date: ${deltaDays} days from today (${formatDate(renewalDate)})`
}

const getDisplaySignUpChannel = (signUpChannel) => {
  switch(signUpChannel) {
    case "APP":
      return 'Native-app'
    case "WEBSITE":
      return 'Web-app'
    default:
      return '-'
  }
}

const getDisplayExtraData = (userCustomData, customerAdditionalDataFields) => {
  if (!userCustomData) {
    return '-';
  }
  const displayExtraData = [];
  const dataJson = JSON.parse(userCustomData);
  for (const fieldName of customerAdditionalDataFields) {
    // field display_order
    for (const [key, value] of Object.entries(dataJson)) {
      if (fieldName === key) {
        const keyValueString = `${key}:${value}`;
        displayExtraData.push(keyValueString);
        break;
      }
    }
  }
  for (const [key, value] of Object.entries(dataJson)) {
    if (customerAdditionalDataFields.includes(key)) {
      continue;
    }
    const keyValueString = `${key}:${value}`;
    displayExtraData.push(keyValueString);
  }
  console.log(
    'customerAdditionalDataFields@displayExtraData',
    displayExtraData,
  );
  return displayExtraData.join(', ');
};

const parseListCustomer = (item, customerAdditionalDataFields) => {
  const displayExtraData = getDisplayExtraData(
    item?.userCustomData,
    customerAdditionalDataFields,
  );
  const data = {
    pk: item.pk,
    owner: `${item.firstName} ${item.lastName}`,
    name: item.nickname
      ? `${item.firstName} ${item.lastName} (${item.nickname})`
      : `${item.firstName} ${item.lastName}`,
    membershipId: item.membershipId,
    mobileNumber:
      item.mobilePhoneNumberCountryCode &&
        item.mobilePhoneNumberSubscriberNumber
        ? `+${item.mobilePhoneNumberCountryCode}\r\n${item.mobilePhoneNumberSubscriberNumber}`
        : '-',
    email: item.emailAddress,
    pointAccount: item.pointAccount,
    level: item.pointAccount?.currentLevel?.levelName,
    segment: item.segments?.edges.map((seg) => seg.node.name).join(', '),
    status: getDisplayStatus(item?.status),
    testCustomer: item.isAssignedAsTestingCustomer ? 'Test\r\ncustomer' : '-',
    acquisitionChannel: getDisplayAcquisitionChannel(item.acquisitionChannel),
    userCustomData: item?.userCustomData,
    displayExtraData: displayExtraData,
  };
  return data;
};

const parseDetailCustomer = (item) => {
  const allDisplayRemarks = item.remarks?.edges.map((seg) => {
    const remark = seg.node.remark;
    const creationDate = seg.node.creationDate;
    const displayCreationDate = getRemarkCreationDateFormatted(creationDate);
    const admin = seg.node.creator;
    const adminName = admin?.firstName
      ? `${admin.firstName} ${admin.lastName}`
      : admin?.username;
    return {
      pk: seg.node.pk,
      isCreator: seg.node.isCreator,
      adminName,
      displayCreationDate,
      remark,
    };
  });
  const displayRemarks = allDisplayRemarks.length > 5 ? allDisplayRemarks.slice(0, 5) : allDisplayRemarks;

  const groups = item.groups?.edges.map(group => group.node) || [];
  const displayGroups = groups.filter(group => !!group.showInCustomerProfile);
  const mobileNumber = (
    item.mobilePhoneNumberCountryCode && item.mobilePhoneNumberSubscriberNumber
      ? `+${item.mobilePhoneNumberCountryCode}\r\n${item.mobilePhoneNumberSubscriberNumber}`
      : null
  )
  let displayLoginMethod = [];
  if (item.signUpMethod === SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS) {
    displayLoginMethod = [item.emailAddress, mobileNumber]
  } else if (item.signUpMethod === SIGN_UP_METHOD.MOBILE_PHONE_NUMBER) {
    displayLoginMethod = [mobileNumber]
  } else if (item.signUpMethod === SIGN_UP_METHOD.EMAIL_ADDRESS) {
    displayLoginMethod = [item.emailAddress]
  }
  displayLoginMethod = displayLoginMethod.filter(item => Boolean(item))

  return {
    ...item,
    owner: `${item.firstName} ${item.lastName}`,
    name: item.nickname
      ? `${item.firstName} ${item.lastName} (${item.nickname})`
      : `${item.firstName} ${item.lastName}`,
    membershipId: item.membershipId,
    signUpMethod: item.signUpMethod,
    signUpMethodDisplay: SIGN_UP_METHOD_DISPLAY?.[item.signUpMethod] || '-',
    chineseFirstName: '-',
    chineseLastName: '-',
    chineseName: '-',
    mobileNumber: mobileNumber || '-',
    email: item.emailAddress,
    displayLoginMethod: displayLoginMethod.join(", ") ||  "-",
    level: item.pointAccount?.currentLevel?.levelName,
    displayJoinedTime: getDisplayJoinedTime(item.creationDate),
    displayLevelRenewalDate: getDisplayLevelRenewalDate(item.pointAccount?.currentLevelRenewDatetime),
    levelRenewalDate: formatDate(item.pointAccount?.currentLevelRenewDatetime),
    levelExpiredIndays: item.pointAccount?.currentLevelExpiredInXDays,
    displayRemarks,
    allDisplayRemarks,
    displaySegmentList: item.segments?.edges.map((seg) => seg.node.name),
    segment: item.segments?.edges.map((seg) => seg.node.name).join(', '),
    segmentsIn: item.segments?.edges.map(segment => segment.node.pk).join(','),
    status: getDisplayStatus(item?.status),
    displayGroupList: displayGroups.map(group => group.name),
    group: groups.map(group => group.name).join(', '),
    groupsIn: groups.map(group => group.pk).join(','),
    inGroups: groups.map(group => ({
      pk: group.pk,
      name: group.name,
      value: group,
    })),
    displayInGroups: displayGroups.map(group => ({
      pk: group.pk,
      name: group.name,
      value: group,
    })),
    referrerUser: item.referrer
      ? {
        label: `[${item.referrer.membershipId}] ${item.referrer.firstName} ${item.referrer.lastName}`,
        value: item.referrer,
      }
      : null,
    referrerCampaign: item.referredByCampaign
      ? {
        label: item.referredByCampaign?.name,
        value: item.referredByCampaign,
      }
      : null,
    referralSource: getReferralSource(item),
    formateCreated: formatDate(item.creationDate),
    lifetime: item.totalSpending,
    averageOrder: item.averageOrderValue,
    totalOrders: item.totalNumberOfOrders,
    lastOrderDays: item.daysFromLastOrder,
    acceptDirectMarketing: item.hasAgreedDirectMarketing ? 'Yes' : 'No',
    acceptDirectMarketingViaEmail: item.directMarketingViaEmail ? 'Yes' : 'No',
    legalAgreement: 'Yes',
    dataProcessingAgreement: 'Yes',
    pointsBalance: item.pointAccount?.balance,
    pointsBalanceWithComma: formatNumberWithCommas(item.pointAccount?.balance || ''),
    totalLifetimePointsUsed: item.pointAccount?.totalPointsUsed,
    totalLifetimePointsExpired: item.pointAccount?.totalPointsExpired,
    tpe: item.pointAccount?.totalPointsEarned,
    levelPrivilege: getLevelPrivilege(
      item.pointAccount?.currentLevel?.levelName,
    ).join(', '),
    pointsToUpgrade: item.pointAccount?.pointsToUpgrade,
    pointsToUpgradeWithComma: formatNumberWithCommas(item.pointAccount?.pointsToUpgrade || ''),
    pointsToRetain: item.pointAccount?.pointsToRetain,
    pointsToRetainWithComma: formatNumberWithCommas(item.pointAccount?.pointsToRetain || ''),
    availableCampaign: '-',
    coupons: item.coupons?.edges.map((item, index) => item.node.template.name),
    ownedCoupons: item.coupons?.edges.map((item, index) => ({
      pk: item.node.pk,
      name: item.node.template.name,
      value: item.node,
    })),
    preferredMessageLanguageDisplay: item.preferredMessageLanguage
      ? LanguageTransLation[item.preferredMessageLanguage]
      : '-',
    socialMedia: '-',
    gender: item.gender?.value,
    displayGender: item.gender?.label,
    testCustomer: item.isAssignedAsTestingCustomer ? 'Test\r\ncustomer' : '-',
    assignToTest: item.isAssignedAsTestingCustomer ? 'Yes' : 'No',
    displayDateOfBirth: formatDate(item.dateOfBirth, 'MMM yyyy'),
    userCustomData: item?.userCustomData ? JSON.parse(item.userCustomData) : {},
    acquisitionChannel: getDisplayAcquisitionChannel(item.acquisitionChannel),
    signUpByMerchant: item.signUpByMerchant?.pk
      ? `[${item.signUpByMerchant?.pk}] ${item.signUpByMerchant?.username}`
      : '-',
    displaySignUpChannel: getDisplaySignUpChannel(item.signUpChannel),
  };
};

const parseCustomerList = (data, fieldsData) => {
  const customerAdditionalDataFields =
    fieldsData?.map((edge) => edge.node.fieldName).filter((item) => !!item) ||
    [];
  return data.map((item) => {
    if (!item.node.isReset) {
      return parseListCustomer(item.node, customerAdditionalDataFields);
    }
    return undefined;
  });
};
function parseCustomerReportType(reportType) {
  switch (reportType) {
    case 'WEEKLY':
      return 'Weekly';
    case 'MONTHLY':
      return 'Monthly';
    default:
      break;
  }
}

function parseCustomerReport(item) {
  return {
    pk: item.pk,
    id: item.id,
    name: item.reportName,
    reportType: parseCustomerReportType(item.reportType),
    displayTimeRange: `${formatDate(
      item.startTime,
      'DD MMM yyyy',
    )} - ${formatDate(item.endTime, 'DD MMM yyyy')}`,
    creationDate: formatDate(item.creationDate, 'DD MMM yyyy'),
    reportFileUrl: item.reportFileUrl,
  };
}

const parseActivityLogs = (data) => {
  console.log('@@152: ', data);
  return data?.map((item) => {
    return {
      ...item.node,
      text: item.node.customerPointAndLevelLogTitle,
      updateTime: formatDate(
        item.node.creationDate,
        TimeFormater.dayMonthYearWeekTime,
      ),
    };
  });
};

const parseCustomerGroup = (data) => {
  return {
    pk: data?.pk,
    name: data?.name,
    value: data,
  }
};

const parseCustomerGroups = (data) => {
  return data?.map(item => parseCustomerGroup(item.node))
}

export default {
  namespace: 'customerList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateAllCustomerTempList(state, { payload }) {
      const { tempCustomerList, page } = payload;
      return {
        ...state,
        tempCustomerList:
          page > 1
            ? [...state.tempCustomerList, ...tempCustomerList]
            : tempCustomerList,
        totalCount:
          page > 1
            ? state.tempCustomerList.length + tempCustomerList.length
            : tempCustomerList.length,
      };
    },

    updateCustomerList(state, { payload }) {
      const { customerList, page } = payload;
      return {
        ...state,
        customerList:
          page > 1 ? [...state.customerList, ...customerList] : customerList,
        totalCount:
          page > 1
            ? state.customerList.length + customerList.length
            : customerList.length,
      };
    },

    updateCustomerGroup(state, { payload }) {
      const { customerGroups, page } = payload;
      return {
        ...state,
        customerGroup:
          page > 1 ? [...state.customerGroup, ...customerGroups] : customerGroups,
      };
    },

    assembleCustomerAdditionalDataFields(state, { payload }) {
      const customerAdditionalDataFields =
        payload?.list
          ?.map((edge) => edge.node.fieldName)
          .filter((item) => !!item) || [];
      return {
        ...state,
        customerAdditionalDataFields,
      };
    },

    changeVals(state, { payload }) {
      // deprecated
      console.log('@@customer edit: vals changed', payload);
      let tempCustomer = getObjectFromSessionStorage(customerSessionKey);

      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }

      tempCustomer = { ...tempCustomer, ...data };
      saveToSessionStorage(customerSessionKey, tempCustomer);

      return {
        ...state,
        formChanged: true,
      };
    },

    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },

    updateAllCustomerAdditionalDataFields(state, { payload }) {
      const {page, additionalDataFields } = payload;
      return {
        ...state,
        customerAdditionalDataFields:
          page > 1
            ? [...state.customerAdditionalDataFields, ...additionalDataFields]
            : additionalDataFields,
      };
    }
  },

  effects: {
    getAllCustomerGroups: [
      function* ({ payload }, { call, select, put }) {
        const afterCursor = payload?.afterCursor;
        const pageItemCount = 100;
        const serviceArgs = [
          getCustomerGroups, 
          { 
            pageItemCount,
            afterCursor,
            moreSearch: {
              showInCustomerProfile: true
            }
          }
        ];
        function* onSuccess(data) {
          const customerGroups = data.customerGroups;
          const groups = parseCustomerGroups(customerGroups?.edges);

          const hasNextPage = groups?.length === pageItemCount;
          const endCursor = customerGroups?.pageInfo?.endCursor;

          const page = payload.page ? payload.page + 1 : 1;

          yield put({
            type: 'updateCustomerGroup',
            payload: {
              customerGroups: groups,
              page,
            }
          });

          if (hasNextPage) {
            yield put({
              type: 'getAllCustomerGroups',
              payload: {
                afterCursor: endCursor,
                page,
              }
            })
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllCustomers: [
      function* ({ payload }, { call, put, select }) {
        const { afterCursor, search } = payload;
        const page = afterCursor ? payload.page : 1;
        const response = yield call(getAllCustomers, afterCursor, search);
        if (!response || response.status >= 300) {
          return;
        }
        if (response.data.data?.customers) {
          const customerInfo = response.data.data.customers;
          const tempCustomerList = parseCustomerList(customerInfo.edges);
          yield put({
            type: 'updateAllCustomerTempList',
            payload: {
              tempCustomerList: tempCustomerList,
              page: page,
            },
          });
          if (customerInfo.pageInfo.hasNextPage) {
            yield put({
              type: 'getAllCustomers',
              payload: {
                afterCursor: customerInfo.pageInfo.endCursor,
                page: page + 1,
                search: search,
              },
            });
          } else {
            const tempCustomerList = yield select(
              (state) => state.customerList.tempCustomerList,
            );
            yield put({
              type: 'updateState',
              payload: {
                customerList: tempCustomerList,
              },
            });
          }
        }
      },
      { type: 'takeLatest' },
    ],
    getPagedCustomers: [
      function* ({ payload }, { call, put, all }) {
        const page = payload.page;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getCustomersByPage,
          pageCursor,
          payload.reverse,
          payload.search,
          payload.customer_groups,
          payload.segments,
          payload.levels,
          payload.age,
          payload.gender,
          payload.status,
          payload.birthday_start_date,
          payload.birthday_end_date,
          payload.birth_month,
          payload.sort,
          payload,
        ];
        console.log('@@363');
        function* onSuccess(data) {
          const pageInfo = data?.customers?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.customers?.totalCount;
          console.log('@@115: ', data);
          yield all([
            put({
              type: 'updateState',
              payload: {
                pagedCustomerList: parseCustomerList(
                  data?.customers?.edges,
                  data?.customerAdditionalDataFields?.edges,
                ),
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                currentLastCursor: currentLastCursor,
                listTotalCount: totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'assembleCustomerAdditionalDataFields',
              payload: { list: data.customerAdditionalDataFields.edges },
            }),
            put({
              type: 'updateCustomerList',
              payload: {
                customerList: parseCustomerList(
                  data?.customers?.edges,
                  data?.customerAdditionalDataFields?.edges,
                ),
                page,
              },
            }),
          ]);
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getPagedCustomersReports: [
      function* ({ payload }, { call, put, all }) {
        const page = payload.page;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getCustomerReportsByPage, pageCursor, payload];
        function* onSuccess(data) {
          const customerReportData = data.customerReports.edges;
          const pageInfo = data?.customerReports?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.customerReports?.totalCount;
          const customerReportList = customerReportData.map((item) =>
            parseCustomerReport(item.node),
          );
          yield all([
            put({
              type: 'updateState',
              payload: {
                pagedCustomerReportList: customerReportList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                currentLastCursor: currentLastCursor,
                listTotalCount: totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
          ]);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { all, put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.customerList.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteCustomers, pks];
        const afterAction = payload.afterAction || (() => { });
        console.log('@@231: ', pks);
        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield all([
            put({
              type: 'updateState',
              payload: {
                checkedList: [],
                formChanged: false,
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
              formChanged: false,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    checkCustomerProfileValid: [
      function* ({ payload }, { all, put, select }) {
        const tempCustomer = payload.data;
        const originCustomer = payload.customer;

        const errorsList = getErrorList({
          firstName: tempCustomer.firstName,
          lastName: tempCustomer.lastName,
          pointTransaction: tempCustomer.pointTransaction,
          transactionPoint: tempCustomer.transactionPoint,
          tpeTransaction: tempCustomer.tpeTransaction,
          transactionTpe: tempCustomer.transactionTpe,
          signUpMethod: tempCustomer.signUpMethod,
          mobilePhoneNumberCountryCode: tempCustomer.mobilePhoneNumberCountryCode,
          mobilePhoneNumberSubscriberNumber: tempCustomer.mobilePhoneNumberSubscriberNumber,
        })

        yield put({
          type: 'updateState',
          payload: {
            errorsList,
          }
        })

        if (errorsList.length <= 0) {
          yield put({
            type: 'updateCustomer',
            payload,
          })
        }

      },
      { type: 'takeLatest' },
    ],
    updateCustomer: [
      function* ({ payload }, { all, put, select }) {
        const tempCustomer = payload.data;
        const originCustomer = payload.customer;
        const data = {
          id: tempCustomer.pk,
          firstName: tempCustomer.firstName,
          lastName: tempCustomer.lastName,
          nickname: tempCustomer.nickname,
          gender: tempCustomer.gender,
          dateOfBirth: tempCustomer.dateOfBirth,
          address: tempCustomer.address,
          company: tempCustomer.company,
          signUpChannel: tempCustomer.signUpChannel,
          referrer: tempCustomer.referrerUser?.value?.pk || null,
          couponsToReclaim: tempCustomer.removedCoupons?.map(
            (item) => item.value.pk,
          ),
          groupsToJoin: tempCustomer.joinGroups?.map((item) => item.value.pk),
          groupsToLeave: tempCustomer.leaveGroups?.map((item) => item.value.pk),
          pointsChange: {
            type: tempCustomer.pointTransaction?.value || null,
            value: tempCustomer.transactionPoint || null,
            remarks: tempCustomer.pointTransactionRemark || null,
          },
          tpeChange: {
            type: tempCustomer.tpeTransaction?.value || null,
            value: tempCustomer.transactionTpe || null,
          },
          isAssignedAsTestingCustomer: tempCustomer.isAssignedAsTestingCustomer,
          isForcedInactive: tempCustomer.isForcedInactive,
        };
        if ([
          SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
          SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
        ].includes(originCustomer.signUpMethod)) {
          data.mobilePhoneNumberCountryCode =
            originCustomer.mobilePhoneNumberCountryCode;
          data.mobilePhoneNumberSubscriberNumber =
            originCustomer.mobilePhoneNumberSubscriberNumber;
        } else {
          data.mobilePhoneNumberCountryCode =
            tempCustomer.mobilePhoneNumberCountryCode;
          data.mobilePhoneNumberSubscriberNumber =
            tempCustomer.mobilePhoneNumberSubscriberNumber;
        }
        if ([
          SIGN_UP_METHOD.EMAIL_ADDRESS,
          SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
        ].includes(originCustomer.signUpMethod)) {
          data.emailAddress = originCustomer?.emailAddress
            ? originCustomer.emailAddress
            : null;
        } else {
          data.emailAddress = tempCustomer?.emailAddress
            ? tempCustomer.emailAddress
            : null;
        }

        const serviceArgs = [updateCustomer, data];

        console.log('@@382: ', data);

        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              formHasSubmitted: true,
              saved: SavedStatus.savedWithSuccess,
            },
          });
          if (payload?.refreshCustomer) {
            yield put({
              type: 'getOneCustomer',
              payload: {
                id: tempCustomer.pk
              }
            })
          };
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              formHasSubmitted: true,
              saved: SavedStatus.savedWithFail,
            },
          });

          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: data.data.errors[0].message,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCustomerActive: [
      function* ({ payload }, { all, put, select }) {
        let activeAPI = deactiveCustomer;
        if (payload.data.isForcedInactive) {
          activeAPI = activeCustomer;
        }
        const afterAction = payload.afterAction || (() => { });
        const serviceArgs = [activeAPI, { id: payload.data.id }];

        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield afterAction();
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCustomerAdditionalData: [
      function* ({ payload }, { all, put, select }) {
        const { data, id, successCallback, failureCallback } = payload;
        const userCustomData = JSON.stringify(data);
        console.log('userCustomData', userCustomData);
        const input = {
          id,
          userCustomData,
        };

        const serviceArgs = [updateCustomer, input];

        function* onSuccess(data) {
          console.log('updateCustomer@onSuccess: ', data);
          if (successCallback) {
            yield successCallback();
          }
        }
        function* onFailed(data) {
          console.log('updateCustomer@onFailed: ', data);
          if (failureCallback) {
            yield failureCallback();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getOneCustomer: [
      function* ({ payload }, { call, put, all }) {
        const serviceArgs = [
          getOneCustomer,
          convertPKToId('CustomerNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log('getOneCustomer@@115: ', data);
          const customer = parseDetailCustomer(data?.customer);
          yield all([
            put({
              type: 'updateState',
              payload: {
                customer: customer,
                hasUpdatedDefaultValues: true,
              },
            }),
            put({
              type: 'assembleCustomerAdditionalDataFields',
              payload: { list: data?.customerAdditionalDataFields?.edges },
            }),
          ]);
          saveToSessionStorage(customerSessionKey, customer);
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield put({
          type: 'updateState',
          payload: {
            customerProfileLoading: true,
          }
        })

        yield loading(serviceArgs, onSuccess, onFailed);

        yield put({
          type: 'updateState',
          payload: {
            customerProfileLoading: false,
          }
        })
      },
      { type: 'takeLatest' },
    ],
    getCustomerActivityLog: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getCustomerActivityLog, {
          ssoUid: payload.ssoUid,
          customerId: payload.customerId,
        }];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const activityLogs = parseActivityLogs(
            data?.customerActivityLogs.edges,
          );
          yield put({
            type: 'updateState',
            payload: {
              activityLogs,
            },
          });
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    createCustomerRemark: [
      function* ({ payload }, { call, put, all }) {
        const serviceArgs = [
          createCustomerRemark,
          {
            customer: payload.customer,
            remark: payload.remark,
          },
        ];
        function* onSuccess(data) {
          yield put({
            type: 'getOneCustomer',
            payload: {
              id: payload.customer,
            }
          })
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@create customer remark failed: ', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    deleteCustomerRemark: [
      function* ({ payload }, { select, put }) {
        const serviceArgs = [deleteCustomerRemark, payload.id];
        function* onSuccess() {
          yield put({
            type: 'getOneCustomer',
            payload: {
              id: payload.customer,
            }
          })
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPagedCustomerAdditionalDataFields: [
      function* ({ payload }, { select, put }) {
        const { actionAfterSuccess, search, page } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedCustomerAdditionalDataFields, afterCursor, search];
        function* onSuccess(data) {
          const additionalDataFieldKeys = data?.customerAdditionalDataFields?.edges?.map(item => item?.node?.fieldName)
          yield put({
            type: 'updateAllCustomerAdditionalDataFields',
            payload: {
              additionalDataFields: additionalDataFieldKeys,
              page: page,
            },
          });
          actionAfterSuccess && actionAfterSuccess(additionalDataFieldKeys)
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ]
  },
};

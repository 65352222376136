import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import BaseForm from '../../components/base/v2/BaseForm';
import { APIStatus, SESSION_KEYS } from '../../config/CustomEnums';
import { removeFromSessionStorage, saveToSessionStorage } from '../../utils';
import CreateWebviewStepOne from './CreateWebviewStepOne';

export default function CreateWebview() {
  const {
    webview,
    hasUpdatedDefaultValues,
    formHasSubmitted,
    createStatus,
    apiStatus,
  } = useSelector((state) => ({
    webview: state.webview.detail,
    hasUpdatedDefaultValues: state.webview.hasUpdatedDefaultValues,
    formHasSubmitted: state.webview.formHasSubmitted,
    createStatus: state.webview.createStatus,
    apiStatus: state.webview.apiStatus,
  }));
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const caution = {
    detail: '',
    title: params.id ? webview.name : 'Create Webview Content',
    model: 'webview',
  };

  useEffect(() => {
    const id = params.id;
    if (id) {
      dispatch({
        type: 'webview/getOneDetail',
        payload: { id: id },
      });
    } else {
      dispatch({ type: 'webview/loadFromCookie' });
    }
    return () => {
      dispatch({ type: 'webview/clearData' });
    };
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      apiStatus === APIStatus.success &&
      formHasSubmitted &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/webview');
    }
  }, [formHasSubmitted, apiStatus, webview, history]);

  const stepSet = [<CreateWebviewStepOne />];

  return (
    <>
      <BaseForm
        defaultValues={webview}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        breadcrumb={<CustomBreadcrumb name={webview?.name} />}
        caution={caution}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.WEBVIEW_CONTENT_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.WEBVIEW_CONTENT_SESSION_KEY);
          }
        }}
        hasleavePagePrompt={false}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Webview Content is successfully created!',
          step: null,
          buttons: [
            {
              text: 'Back to Webview Content list.',
              action: () => {
                history.push({
                  pathname: '/webview',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/webview'),
          onClose: () => history.push('/webview'),
        }}
        content={stepSet}
        currentStep={0}
      />
    </>
  );
}

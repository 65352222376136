import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';
import {
  CUSTOMER_DETAIL_COUPON_TYPE
} from 'config/CustomEnums';

function CustomerRewardListFilter({ backAction = () => { } }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search.slice(1));
  const searchType = parsedSearch['type'] || '';

  const [type, setType] = useState(searchType);

  const content = [
    {
      title: 'Type',
      data: [
        { pk: CUSTOMER_DETAIL_COUPON_TYPE.available, name: 'Available' },
        { pk: CUSTOMER_DETAIL_COUPON_TYPE.unavailable, name: 'Unavailable' },
        { pk: CUSTOMER_DETAIL_COUPON_TYPE.used_or_expired, name: 'Used or expired' },
      ],
      value: type,
      setValue: setType,
      component: FilterTypeEnum.singleChoice,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default CustomerRewardListFilter;

import moment from 'moment';

import { SESSION_KEYS } from 'config/CustomEnums';
import { createModel } from 'models/BaseModel';
import {
  getGiftCard,
  getGiftCardVirtualConfigVariants,
  updateVirtualGiftCardVariants,
} from 'services/GiftCardAPIHelper';

import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';


const parseListItem = (data) => {
  console.log(data);
  return {
    ...data,
    name: data.pk,
    sourceName: data.cashValue,
  };
};

export default createModel({
  namespace: 'virtualGiftCardConfig',
  states: {
    listDisplayFields: [],
    detail: {
      sourceName: '',
      parameter: '',
    },
  },
  params: {
    sessionKey: SESSION_KEYS.GIFTCARD_VIRTUAL_CONFIG_SESSION_KEY,
    dataKey: SESSION_KEYS.GIFTCARD_VIRTUAL_CONFIG_DATA_SESSION_KEY,
    listAPI: getGiftCardVirtualConfigVariants,
    parse: (data) =>
      data?.virtualGiftCardVariants.edges.map((item) => parseListItem(item.node)),
    pkNode: 'VirtualGiftCardVariantNode',
    detailAPI: getGiftCard, // TODO: remove
    parseDetail: (data) => parseListItem(data.virtualGiftCardVariant),
    objectKey: 'virtualGiftCardVariants',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    updateVirtualGiftCardVariants: [
      function* ({ payload }, { call, select, put }) {
        const variants = yield select(
          (state) => state.virtualGiftCardConfig.variants,
        ) || [];
        console.log('variants@56', variants);
        const filterData = variants.filter(
          (variant) => !!variant?.cashValue || !!variant?.price,
        ).map(
          (data) => {
            return {
              cashValue: data.cashValue,
              price: data.price,
              activePeriodType: data.activePeriodType,
              endDate: data.endDate,
              expireDays: data.expireDays,
            };
          }
        );
        console.log('variants@58', filterData);
        const data = {
          variants: filterData,
        };
        const serviceArgs = [updateVirtualGiftCardVariants, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});

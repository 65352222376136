import React from 'react';
import { useSelector } from 'react-redux';

import NewCustomerCard from 'components/customer/NewCustomerCard';
import OverviewCard from 'components/dashboard/OverviewCard';

import './CustomerOverview.scss';

const CUSTOMER_CARD_CONFIG = [
  {
    title: 'Total Customers',
    key: 'totalCustomers',
  },
  {
    title: 'New Customers',
    key: 'newCustomers',
  },
  // AP-2022: Hide Active Customers & Non-registered customers
  // {
  //   title: 'Active Customers',
  //   key: 'activeCustomers',
  // },
  // {
  //   title: 'Non-registered customers in app',
  //   key: 'nonRegistedCustomers',
  // },
  {
    title: 'Total Temporary Earning Customers',
    key: 'tempEarningCustomers',
  },
  {
    title: 'Total Temporary Customers',
    key: 'tempCustomers',
  },
];

const CustomerOverview = () => {
  const customerOverviewData = useSelector((state) => state.overview.customers);
  return (
    <div>
      <div className="overview-row-title overview-campaigns-title">
        Customers
      </div>
      <div className="overview-row customer-overview-row">
        {CUSTOMER_CARD_CONFIG.map((config) => {
          return (
            <OverviewCard
              key={config.key}
              title={config.title}
              value={customerOverviewData[config.key]?.value}
              change={customerOverviewData[config.key]?.change}
            />
          );
        })}
      </div>
      <NewCustomerCard />
    </div>
  );
};
export default CustomerOverview;

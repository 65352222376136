import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import i18n from 'I18n';

import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import ImageUploader from 'components/base/ImageUploader';
import PeriodSection from 'components/base/period/PeriodSection';
import CustomRadioWithTitleAndRelated from 'components/customer/CustomRadioWithTitleAndRelated';
import WhatsappContentInlineEditor from 'components/message/WhatsappContentInlineEditor';
import WhatsappTemplateSelectDropdown from 'components/message/WhatsappTemplateSelectDropdown';
import {
  AppCampaignDetailUrl,
  CampaignDetailUrl,
  LanguageConfig,
  MessageWhatsappTemplateType,
} from 'config/CustomEnums';

import './MessageGeneralSectionWhatsapp.scss';

function MessageGeneralSectionWhatsapp({ language }) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const templateField = `translations.${language}.whatsappTemplate`;
  const template = watch(templateField);

  const whatsappPhotoField = `translations.${language}.whatsappImage`;
  const whatsappPhoto = watch(whatsappPhotoField);

  return (
    <div className={'message-general-section-whatsapp'}>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>

      <WhatsappTemplateSelectDropdown
        title={'Template'}
        description={
          'Please select the respective language of the Whatsapp template'
        }
        customClass={hasError(errors, templateField) ? 'field-has-error' : ''}
        isPrompt
        defaultValue={
          template
            ? {
                value: template,
                label: template?.name,
              }
            : null
        }
        setValue={(value) => {
          console.log(value);
          setValue(templateField, value, { shouldDirty: true });
          setValue(whatsappPhotoField, null, { shouldDirty: true });
          setValue(`translations.${language}.whatsappHeader`, null, {
            shouldDirty: true,
          });
          setValue(`translations.${language}.whatsappBody`, null, {
            shouldDirty: true,
          });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={templateField} />

      {template?.headerFormat === MessageWhatsappTemplateType.IMAGE && (
        <div className="d-flex flex-column">
          <div className="create-section-label create-section-label-bottom-space">
            {'Image'}
          </div>
          <div className="second-section_description">
            {
              'Suggested image size: 1024px by 512px, file size under 1MB, support JPG, PNG and GIF only.'
            }
          </div>
          <ImageUploader
            images={whatsappPhoto ? [whatsappPhoto] : []}
            aspect={2 / 1}
            maxImageNum={1}
            minWidth={1024}
            minHeight={512}
            language={LanguageConfig.english}
            onImageStateChange={(data) => {
              setValue(whatsappPhotoField, data?.[0], { shouldDirty: true });
            }}
            uploadImageClicked={() => {}}
          />
          <ReactHookFormErrorMessage errors={errors} id={whatsappPhotoField} />
        </div>
      )}
      {template?.name && (
        <div className="d-flex flex-column">
          <div className="create-section-label create-section-label-bottom-space">
            {'Content'}
          </div>
          <WhatsappContentInlineEditor
            key={'header'}
            customStyle={'header'}
            language={language}
            baseFieldName={`translations.${language}.whatsappHeader`}
            contentText={template?.headerText}
          />
          <WhatsappContentInlineEditor
            key={'body'}
            customStyle={'body'}
            language={language}
            baseFieldName={`translations.${language}.whatsappBody`}
            contentText={template?.bodyText}
          />
          <WhatsappContentInlineEditor
            key={'footer'}
            customStyle={'footer'}
            language={language}
            contentText={template?.footerText}
          />
        </div>
      )}
    </div>
  );
}

export default MessageGeneralSectionWhatsapp;

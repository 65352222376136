import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import CustomRadios from 'components/base/CustomRadios';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';

import InputWithMutipleDropDown from './InputWithMutipleDropDown';

import { createAction } from 'utils';

import BranchLine from 'assets/images/branchLinex3.png';

const CouponBehavioralTypeEnum = {
  acquiredCoupon: 'ACQUIRED_COUPON',
  usedCoupon: 'USED_COUPON',
};

function CouponBehavioralSection({ title, fieldBaseName, couponTemplateList }) {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const behavioralTypeFieldName = `${fieldBaseName}.behavioralType`;
  const behavioralType = watch(behavioralTypeFieldName);

  const couponSetFieldName = `${fieldBaseName}.couponSet`;

  const behaviorTimesFieldName = `${fieldBaseName}.behaviorTimes`;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction('couponList/getCurrentPageTemplateList')())
    if (!behavioralType) {
      setValue(behavioralTypeFieldName, CouponBehavioralTypeEnum.acquiredCoupon, { shouldDirty: true });
    }
  }, [])


  return (
    <div>
      <CustomTitleLabel title={title} />
      <div className="d-flex flex-column">
        <div style={{ display: 'flex' }}>
          <Controller
            name={behavioralTypeFieldName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomRadios
                onChange={(value) => {
                  setValue(field.name, value, { shouldDirty: true });
                }}
                options={[
                  {
                    label: 'Acquired the coupon',
                    value: CouponBehavioralTypeEnum.acquiredCoupon,
                  },
                  {
                    label: 'Used the coupon',
                    value: CouponBehavioralTypeEnum.usedCoupon,
                  },
                ]}
                default={field.value}
              />
            )}
          />
        </div>
        <div className="display-flex-area">
          <Image src={BranchLine} className="content-campaign-branchLine" />
          <div className="d-flex flex-column">
            <CustomTitleLabel
              title={'Select coupon set'}
            />
            <CustomRadios
              options={[
                {
                  label: 'By searching coupon set',
                  value: 'searchCouponSet',
                },
              ]}
              default='searchCouponSet'
            />
            <Controller
              name={couponSetFieldName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTitleWithDropDown
                  setValue={(value) => {
                    const { label, value: choiceValue } = value;
                    if (choiceValue) {
                      const { id, pk, name } = choiceValue;
                      setValue(
                        field.name,
                        { value: { id, pk, name }, label },
                        { shouldDirty: true, shouldValidate: true },
                      );
                    } else {
                      setValue(field.name, null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  source={couponTemplateList}
                  labelContainPk
                  defaultValue={{
                    value: field.value?.value,
                    label: field.value?.value?.pk
                      ? `[ID: ${field.value?.value?.pk}] ${field.value?.value?.name}`
                      : field.value?.value?.name,
                  }}
                  loadMoreAction={'couponList/getCurrentPageTemplateList'}
                  filterAction={'couponList/getCurrentPageTemplateList'}
                  // defaultFilter={}
                  customClass={
                    hasReactHookFormError(errors, field.name)
                      ? 'error-field width-400px'
                      : 'width-400px'
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="display-flex-area">
          <div className="d-flex flex-column">
            <CustomTitleLabel
              title={'Number of times acquired or used the coupon'}
            />
            <InputWithMutipleDropDown
              fieldBaseName={behaviorTimesFieldName}
              hasNumber
              rangeOptions={['All the time', 'Within']}
              hasPeriodDropdown={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
const mapPropsToState = (state) => ({
  couponTemplateList: state.couponList.couponTemplateList,
});

export default connect(mapPropsToState)(CouponBehavioralSection)


import React, { useEffect, useState } from 'react';

import { COUPON_SET_MEDIUM, WEEKDAY } from 'config/CustomEnums';
import { parseCouponSetOverviewDatetime } from 'models/CouponUtil';

import BaseOverView from './BaseOverview';

import { getReadablePeriod } from 'utils/TimeFormatUtil';

import './CouponTemplateOverview.scss';

function CouponTemplateOverview({ couponSet }) {
  const [mediumTypeDisplay, setMediumTypeDisplay] = useState('Offline only');

  const mediumType = couponSet?.mediumType;

  useEffect(() => {
    switch (mediumType) {
      case COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE:
        setMediumTypeDisplay('Online or offline');
        break;
      case COUPON_SET_MEDIUM.ONLINE:
        setMediumTypeDisplay('Online only');
        break;
      case COUPON_SET_MEDIUM.OFFLINE:
      default:
        setMediumTypeDisplay('Offline only');
        break;
    }
  }, [mediumType]);

  const titleListA = ['Coupon medium'];
  const valueListA = [mediumTypeDisplay];
  let titleListB = ['Total Stock', 'Available coupon'];
  let valueListB = [couponSet?.totalNumberOfGeneratedCoupons, couponSet?.stock];
  if (mediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
    titleListB = [
      'Total Stock',
      'Total online coupon Stock',
      'Total offline coupon Stock',
      'Available coupon',
      'Available online coupon',
      'Available offline coupon',
    ];
    valueListB = [
      couponSet?.totalNumberOfGeneratedCoupons,
      couponSet?.totalNumberOfGeneratedOnlineCoupons,
      couponSet?.totalNumberOfGeneratedOfflineCoupons,
      couponSet?.stock,
      couponSet?.onlineStock,
      couponSet?.offlineStock,
    ];
  }
  const titleListC = [
    'Linked Campaign',
    'Create at',
    'Last Modified',
    'Blackout period (by period)',
    'Blackout period (by weekday)',
    'Status',
  ];
  const valueListC = [
    couponSet?.linkedCampaignsName || '-',
    couponSet?.creationDate
      ? parseCouponSetOverviewDatetime(couponSet?.creationDate)
      : '-',
    couponSet?.lastModifiedDate
      ? parseCouponSetOverviewDatetime(couponSet?.lastModifiedDate)
      : '-',
    couponSet?.validPeriodValues?.length > 0
      ? couponSet?.validPeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        )
      : '-',
    couponSet?.validWeekday?.length > 0
      ? couponSet?.validWeekday?.map((item) => WEEKDAY[item])
      : '-',
    couponSet?.status,
  ];
  const titleList = titleListA.concat(titleListB).concat(titleListC);
  const valueList = valueListA.concat(valueListB).concat(valueListC);
  return <BaseOverView itemTitleList={titleList} itemValueList={valueList} />;
}

export default CouponTemplateOverview;

import { BaseHelper } from './BaseGraphQLAPIHelper';

const SCORE_SETTING_CONTENT = `
  node {
    pk
    recencyOneScoreGreaterThanDays
    recencyTwoScoreGreaterThanOrEqualDays
    recencyTwoScoreLessThanOrEqualDays
    recencyThreeScoreGreaterThanOrEqualDays
    recencyThreeScoreLessThanOrEqualDays
    recencyFourScoreGreaterThanOrEqualDays
    recencyFourScoreLessThanOrEqualDays
    recencyFiveScoreLessThanOrEqualDays
    frequencyOneScoreLessThanTimes
    frequencyTwoScoreLessThanOrEqualTimes
    frequencyTwoScoreGreaterThanOrEqualTimes
    frequencyThreeScoreLessThanOrEqualTimes
    frequencyThreeScoreGreaterThanOrEqualTimes
    frequencyFourScoreLessThanOrEqualTimes
    frequencyFourScoreGreaterThanOrEqualTimes
    frequencyFiveScoreGreaterThanOrEqualTimes
    monetaryOneScoreLessThanNumber
    monetaryTwoScoreLessThanOrEqualNumber
    monetaryTwoScoreGreaterThanOrEqualNumber
    monetaryThreeScoreLessThanOrEqualNumber
    monetaryThreeScoreGreaterThanOrEqualNumber
    monetaryFourScoreLessThanOrEqualNumber
    monetaryFourScoreGreaterThanOrEqualNumber
    monetaryFiveScoreGreaterThanOrEqualNumber
}
`;

export const getScoreSettingList = () => {
  const query = `{
  recencyFrequencyMonetaryScoreSettings(first: 1) {
    edges {
      ${SCORE_SETTING_CONTENT}
    }
  }
}
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateScoreSetting = (value) => {
  const query = `
  mutation UpdateRecencyFrequencyMonetaryScoreSetting($input: UpdateRecencyFrequencyMonetaryScoreSettingInput!) {
    updateRecencyFrequencyMonetaryScoreSetting(input: $input) {
      ${SCORE_SETTING_CONTENT}
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: value,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

import React from 'react';
import { useSelector } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';
import { Link } from 'react-router-dom';

import AnimatedNumber from 'animated-number-react';

import CustomTips from 'components/base/CustomTips';
import NewCustomersChart from 'components/dashboard/NewCustomersChart';
import { ANIMATION_DURATION } from 'config/DashboardConfig';

import { enLocaleNumberFormatter } from 'utils';

import icon from 'assets/images/customer_overview_vector.svg';
import downIcon from 'assets/images/dashboard_down.svg';
import upIcon from 'assets/images/dashboard_up.svg';

import './CustomersCard.scss';

export const getValueChangeLabel = (change) => {
  if (change) {
    let icon = upIcon;
    if (change < 0) {
      icon = downIcon;
    }
    return (
      <div className="dashboard-change-container">
        <img className="dashboard-change-icon" src={icon} alt="" />
        <div
          className="dashboard-change-number"
          style={{ color: change > 0 ? '#008a60' : '#CE0045' }}
        >{`${(Math.abs(change) * 100).toFixed(1)}%`}</div>
      </div>
    );
  }
  return null;
};

const CustomersCard = ({ width, height }) => {
  const {
    chartData,
    totalCustomers,
    newCustomers,
    activeCustomers,
    tempEarningCustomers,
    tempCustomers,
  } = useSelector((state) => state.dashboard.customers);
  const newCustomersGrow = (newCustomers?.change || 0) >= 0;
  return (
    <div className="dashboard-customer-card">
      <div className="dashboard-card-title">CUSTOMERS</div>
      <div className="dashboard-customer-card-data">
        <div className="dashboard-customer-card-single-data">
          <div className="dashboard-customer-card-info-title">Total</div>
          <AnimatedNumber
            className="dashboard-customer-card-info-number"
            duration={ANIMATION_DURATION}
            value={totalCustomers?.value}
            formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
          />
          {getValueChangeLabel(totalCustomers?.change)}
        </div>
        <div className="dashboard-customer-card-single-data">
          <div className="dashboard-customer-card-info-title">
            New Customers
          </div>
          <AnimatedNumber
            className="dashboard-customer-card-info-number"
            duration={ANIMATION_DURATION}
            value={newCustomers?.value}
            formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
          />
          {getValueChangeLabel(newCustomers?.change)}
        </div>
        {false && ( // AP-2022: Hide Active Customers
        <div className="dashboard-customer-card-single-data">
          <CustomTips
            detail="At least one time at this period"
            iconClassName="dashboard-customer-card-hint-icon"
          >
            <label className="dashboard-customer-card-info-title">
              Active Customers
            </label>
          </CustomTips>
          <AnimatedNumber
            className="dashboard-customer-card-info-number"
            duration={ANIMATION_DURATION}
            value={activeCustomers?.value}
            formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
          />
          {getValueChangeLabel(activeCustomers?.change)}
        </div>
        )}
        <div className="dashboard-customer-card-single-data">
          <div className="dashboard-customer-card-info-title">
            Total Temporary Earning Customers
          </div>
          <AnimatedNumber
            className="dashboard-customer-card-info-number"
            duration={ANIMATION_DURATION}
            value={tempEarningCustomers?.value}
            formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
          />
          {getValueChangeLabel(tempEarningCustomers?.change)}
        </div>
        <div className="dashboard-customer-card-single-data">
          <div className="dashboard-customer-card-info-title">
            Total Temporary Customers
          </div>
          <AnimatedNumber
            className="dashboard-customer-card-info-number"
            duration={ANIMATION_DURATION}
            value={tempCustomers?.value}
            formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
          />
          {getValueChangeLabel(tempCustomers?.change)}
        </div>
      </div>
      <div className="dashboard-customer-card-chart-title">New Customers</div>
      <div style={{ marginLeft: 25, marginRight: 50 }}>
        <NewCustomersChart
          data={chartData}
          width={width ? width - 75 : null}
          height={250}
          dataGrow={newCustomersGrow}
        />
      </div>
      <Link
        className="dashboard-overview-link customers-overview-link"
        to={{ pathname: '/customers' }}
      >
        Customer Overview
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default withResizeDetector(CustomersCard);

import React from 'react';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import {
  CustomTitleLabel,
} from './CustomBaseComponments';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';

function ReferralSection() {
  const radioOptions = [
    { label: 'Inviter', value: 'INVITER' },
    { label: 'Invitee', value: 'INVITEE' },
    { label: 'Both', value: 'BOTH_INVITER_AND_INVITEE' },
  ];

  const { watch, setValue } = useFormContext();

  const referralType = watch('referralType') || setValue('referralType', 'INVITER', { shouldDirty: true })
  const maxInvitees = watch('maxInvitees')

  return (
    <>
      <label className="create-section-title">REFERRAL</label>
      <CustomTitleLabel title="Beneficiary" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => setValue('referralType', value, { shouldDirty: true })}
          default={referralType}
          options={radioOptions}
        />
      </div>

      {referralType !== 'INVITEE' ? (
        <>
          <InputFieldControl
            name='maxInvitees'
            title='Maximum number of invitees (optional, for inviters only)'
            value={maxInvitees}
            setValue={(value) => {
              setValue('maxInvitees', value, { shouldDirty: true });
            }}
            type='number'
          />
        </>
      ) : null}
    </>
  );
}

export default ReferralSection;

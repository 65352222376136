import { createModel } from 'models/BaseModel';
import {
  getLanguageList,
  getLanguage,
  createOrUpdateLanguage,
  deleteLanguages,
} from 'services/LanguageAPIHelper';
import { SESSION_KEYS } from 'config/CustomEnums';

const parseDetail = (data) => {
  return {
    ...data,
    sourceName: data.name,
    name: `${data.name}${data.parameter ? '(' + data.parameter + ')' : ''}`,
  };
};

export default createModel({
  namespace: 'language',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      {
        displayName: 'Language name(Parameter)',
        fieldName: 'name',
      },
    ],
    detail: {
      sourceName: '',
      parameter: '',
    },
  },
  reducers: {},
  params: {
    sessionKey: SESSION_KEYS.LANGUAGE_SESSION_KEY,
    dataKey: SESSION_KEYS.LANGUAGE_DATA_SESSION_KEY,
    listAPI: getLanguageList,
    parse: (data) =>
      data?.languages.edges.map((item) => parseDetail(item.node)),
    deleteAPI: deleteLanguages,
    pkNode: 'LanguageNode',
    detailAPI: getLanguage,
    parseDetail: (data) => parseDetail(data.language),
    objectKey: 'languages'
  },
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        console.log("@56", payload)
        const data = {
          name: payload.sourceName,
          parameter: payload.parameter,
        };
        if (payload.id) {
          data.id = payload.pk;
        }
        const serviceArgs = [createOrUpdateLanguage, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !payload.id },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});

import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import {
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { useFormContext } from 'react-hook-form';
import CreateAdminGroupStepOneGeneralSection from '../../../components/admin/CreateAdminGroupStepOneGeneralSection';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CreateAdminGroupStepOne({
  adminGroup,
}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    const { getValues, trigger } = useFormContext();
  
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  
    const goToAdminGroupList = () => {
      history.push('/administrator_groups');
    }

    const sections = [<CreateAdminGroupStepOneGeneralSection/>]
  
    return (
      <>
        <ContentSections sections={sections} hidePreview={true} />
        <SaveAndBackButtons
          saveTempText={params.id ? 'Delete' : null}
          saveText={params.id ? 'Update' : 'Save'}
          backAction={goToAdminGroupList}
          saveAction={async () => {
            let isValid = await trigger(
              [
                'name',
              ],
              { shouldFocus: true },
            );
            console.log("@83", isValid, getValues())
            if (isValid) {
              dispatch(
                createAction('adminGroup/updateOrCreate')({
                  values: getValues(),
                  afterActions: goToAdminGroupList,
                }),
              );
            }
          }}
          saveTempAction={() => setShowDeletePrompt(true)}
          tempRequires={PermissionCodes.deleteAdministratorGroup}
        />
        <DeletePrompt
          message={{
            title: 'Delete this Admin Group',
            content: 'Did you confirm to delete this Admin Group?',
          }}
          title={'Admin Group'}
          data={adminGroup}
          relatedSections={DELETE_RELATED_SECTIONS.ADMIN_GROUP}
          show={showDeletePrompt}
          onClose={() => {
            setShowDeletePrompt(false);
          }}
          onConfirm={() => {
            setShowDeletePrompt(false);
            dispatch({
              type: `adminGroup/updateState`,
              payload: { checkedList: [adminGroup] },
            });
            dispatch(
              createAction('adminGroup/delete')({
                afterAction: goToAdminGroupList,
              }),
            );
          }}
        />
      </>
    )
}

const mapPropsToState = (state) => ({
  adminGroup: state.adminGroup.oneAdminGroup || {},
});

export default connect(mapPropsToState)(CreateAdminGroupStepOne);
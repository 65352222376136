import React, { useEffect, useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import CustomSwitchButton from 'components/base/CustomSwitchButton';
import ErrorFieldMessage, {
  CustomErrorMessage,
  hasReactHookFormError,
} from 'components/base/ErrorFieldMessage';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { GenderOptions, MonthOptions } from 'config/CustomEnums';
import {
  contentEnum,
  contentGroupEnum,
  scoreDefinitionList,
  scoreDefinitionNameList,
} from 'models/SegmentModel';

import CampaignBehavioralSection from './CampaignBehavioralSection';
import DropdownToMutipleSelector from './DropdownToMutipleSelector';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import LookupWithMutipleNumber from './LookupWithMutipleNumber';
import ProductCategoryMultipleSelector from './ProductCategoryMultipleSelector';
import TransactionTypeMutipleSelector from './TransactionTypeMutipleSelector';

import 'utils/BtnCommonTheme.scss';

import DeleteIcon from 'assets/images/deleteIcon.png';
import Search from 'assets/images/drop_down_filter.svg';
import NarrowUp from 'assets/images/drop_down_not_show_narrow.svg';

import './Segment.scss';
import AdditionalDataSection from './AdditionalDataSection';
import CouponBehavioralSection from './CouponBehavioralSection';

const contentSections = {
  Demographic: [
    contentEnum.gender,
    contentEnum.birthday,
    contentEnum.age,
    contentEnum.countryCode,
    contentEnum.signupAnniversary,
  ],
  Behavioral: [
    contentEnum.level,
    contentEnum.lastPurchase,
    contentEnum.numberOfTransaction,
    contentEnum.totalSpending,
    contentEnum.averageTransaction,
    contentEnum.discountLover,
    contentEnum.newMember,
    contentEnum.newSignupWithReferral,
    contentEnum.numberOfLogin,
    contentEnum.lastLogin,
    contentEnum.campaignViewed,
    contentEnum.acquireOrUsedCoupon,
    contentEnum.earnReward,
    contentEnum.products,
    contentEnum.eComBindStatus,
    contentEnum.productCategory,
  ],
  RFM: scoreDefinitionNameList,
  "Additional data": [
    contentEnum.additionalDataFromCustomers,
    contentEnum.additionalDataFromTransactionRecords,
    contentEnum.additionalDataFromPurchasedItems,
  ]
};

function ContentFilterDropDown(props) {
  const sectionSelectAction = props.addContent || (() => {});
  const settedParams = props.settedParams || [];

  const [isOpen, setIsOpen] = useState({
    Demographic: false,
    Behavioral: false,
  });
  const openNarrow = (section) => {
    setIsOpen({ ...isOpen, [section]: !isOpen[section] });
  };
  const [contentSelections, setContentSelections] = useState(contentSections);
  const [filterKey, setFilterKey] = useState('');
  const [selectedSections, setSelectedSections] = useState(settedParams);

  useEffect(() => {
    setSelectedSections(props.settedParams);
  }, [props.settedParams]);

  useEffect(() => {
    setFilterKey('');
    setContentSelections(contentSections);
  }, [props.show]);

  return (
    <div className="content-add-button-selection-area">
      {/* <input className="content-add-button-selection-filter" /> */}
      <div className="filterable-search-bar content-add-button-selection-filter">
        <Image
          src={Search}
          className="filterable-search-bar-icon search_bar_icon"
        />
        <input
          autoFocus
          className="filterable-search-bar-input"
          placeholder="Search for..."
          onChange={(e) => {
            const value = e.target.value;
            setFilterKey(value);

            let filteredResult = {};
            Object.keys(contentSections).map(
              (key) =>
                (filteredResult[key] = contentSections[key].filter(
                  (val) => val.toLowerCase().indexOf(value.toLowerCase()) >= 0,
                )),
            );
            setContentSelections(filteredResult);
          }}
          value={filterKey}
        />
      </div>
      <div className="content-add-button-selection-section-area">
        {Object.keys(contentSelections).map((section) => {
          return (
            <>
              <ul className="content-add-button-selection-section">
                <label>{section}</label>
                <button
                  className="reset-button margin-left-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    openNarrow(section);
                  }}
                >
                  <Image
                    src={NarrowUp}
                    className={`filterable-toogle-area-indicator ${
                      isOpen[section] ? 'rtoate180' : ''
                    }`}
                  />
                </button>
              </ul>

              {isOpen[section]
                ? contentSelections[section].map((val, index) => {
                    return selectedSections.includes(val) ? null : (
                      <Dropdown.Item
                        onClick={() => {
                          sectionSelectAction(val);
                          setSelectedSections([...selectedSections, val]);
                        }}
                      >
                        <div className="display-flex-area">
                          <div className="li-icon" />
                          <label className="li-label">{val}</label>
                        </div>
                      </Dropdown.Item>
                      // <div
                      //   className="display-flex-area drop-item"
                      //   onClick={() => {
                      //     sectionSelectAction(val);
                      //     setSelectedSections([...selectedSections, val]);
                      //   }}
                      //   role="button"
                      // >
                      //   <div className="li-icon" />
                      //   <label className="li-label">{val}</label>
                      // </div>
                    );
                  })
                : null}
            </>
          );
        })}
      </div>
    </div>
  );
}

const getDropdownToMutipleSelector = (
  subIndex,
  groupName,
  title,
  options,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <DropdownToMutipleSelector
      fieldBaseName={fieldBaseName}
      title={title}
      mutipleSelector={{
        options,
      }}
    />
  );
};

const getLookupWithMutipleNumber = (
  subIndex,
  groupName,
  title,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <LookupWithMutipleNumber
      fieldBaseName={fieldBaseName}
      groupName={groupName}
      title={title}
    />
  );
};

const getTimePeriodInput = (
  subIndex,
  groupName,
  title,
  hasNumber,
  rangeOptions,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <InputWithMutipleDropDown
      fieldBaseName={fieldBaseName}
      title={title}
      hasNumber={hasNumber}
      rangeOptions={rangeOptions}
      hasPeriodDropdown
    />
  );
};

const FormSwitch = ({ fieldName }) => {
  const { setValue, control } = useFormContext();
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <CustomSwitchButton
          defaultChecked={field.value}
          onChange={(isChecked) => {
            setValue(field.name, isChecked, { shouldDirty: true });
          }}
        />
      )}
    />
  );
};

const getCustomTitleWithSwitch = (
  subIndex,
  groupName,
  title,
  tips,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      <FormSwitch fieldName={`${fieldBaseName}.enable`} />
      {tips ? <label className="tips-message">{tips}</label> : null}
    </div>
  );
};

const getCampaignView = (subIndex, groupName, title, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <CampaignBehavioralSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getAcquireOrUsedCouponView = (subIndex, groupName, title, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <CouponBehavioralSection
      title={title}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getViewWithTransactionTypeFilter = (
  subIndex,
  subRenderFunc,
  subRenderArgs,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <div>
      {subRenderFunc(subIndex, ...subRenderArgs)}
      <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
    </div>
  );
};

const getProductCategory = (subIndex, groupName, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <div className="d-flex flex-column">
      <ProductCategoryMultipleSelector fieldBaseName={fieldBaseName} />
      <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
    </div>
  );
};

const getAdditionalDataSection = (subIndex, title, groupName, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return <AdditionalDataSection
    title={title}
    groupName={groupName}
    fieldBaseName={fieldBaseName}
  />
}

const AddScoreDefinition = ({ fieldName, scoreDefinition }) => {
  const { setValue, control } = useFormContext();

  useEffect(() => {
    setValue(fieldName, scoreDefinition, { shouldDirty: false });
  }, []);

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => <></>}
    />
  );
};

const getCustomRecencyFrequencyMonetaryTitle = (
  subIndex,
  conditionName,
  scoreDefinition,
  name,
) => {
  const fieldName = `${conditionName}.${subIndex}.${
    contentGroupEnum[contentEnum.champions]
  }.scoreDefinition`;

  return (
    <div>
      <CustomTitleLabel title={`RFM - ${name}`} />
      <AddScoreDefinition
        fieldName={fieldName}
        scoreDefinition={scoreDefinition}
      />
    </div>
  );
};

const getRecencyFrequencyMonetaryFieldNumber = (fields = []) => {
  let number = 0;
  fields.forEach((item) => {
    if (scoreDefinitionNameList.indexOf(item.title) > -1) {
      number += 1;
    }
  });
  return number;
};

function ContentItemSection({ index, levels, countryCodes }) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const conditionIndex = index + 1;
  const conditionName = `conditionGroups.${index}.conditions`;
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: conditionName,
  });
  const [showResetPrompt, setShowResetPrompt] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const contentSectionComponment = {
    [contentEnum.gender]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.gender],
        contentEnum.gender,
        GenderOptions,
        conditionName,
      ],
    },
    [contentEnum.birthday]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.birthday],
        contentEnum.birthday,
        MonthOptions,
        conditionName,
      ],
    },
    [contentEnum.age]: {
      renderer: getLookupWithMutipleNumber,
      rendererArgs: [
        contentGroupEnum[contentEnum.age],
        contentEnum.age,
        conditionName,
      ],
    },
    [contentEnum.countryCode]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.countryCode],
        contentEnum.countryCode,
        countryCodes,
        conditionName,
      ],
    },
    [contentEnum.signupAnniversary]: {
      renderer: getLookupWithMutipleNumber,
      rendererArgs: [
        contentGroupEnum[contentEnum.signupAnniversary],
        contentEnum.signupAnniversary,
        conditionName,
      ],
    },
    [contentEnum.level]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.level],
        contentEnum.level,
        levels,
        conditionName,
      ],
    },

    [contentEnum.lastPurchase]: {
      renderer: (subIndex) => {
        const fieldBaseName = `${conditionName}.${subIndex}.${
          contentGroupEnum[contentEnum.lastPurchase]
        }`;
        const fieldName = `${fieldBaseName}.lastPurchase`;
        return (
          <div className="d-flex flex-column">
            <CustomTitleLabel title="Last purchase within X number of days" />
            <input
              type="number"
              min="0"
              className={`custom-number-input-long ${
                hasReactHookFormError(errors, fieldName) ? 'error-field' : ''
              }`}
              {...register(fieldName, { required: 'This field is required.' })}
            />
            <CustomErrorMessage name={fieldName} />
            <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
          </div>
        );
      },
      rendererArgs: [],
    },
    [contentEnum.numberOfTransaction]: {
      renderer: getViewWithTransactionTypeFilter,
      rendererArgs: [
        getTimePeriodInput,
        [
          contentGroupEnum[contentEnum.numberOfTransaction],
          contentEnum.numberOfTransaction,
          true,
          null,
          conditionName,
        ],
        contentGroupEnum[contentEnum.numberOfTransaction],
        conditionName,
      ],
    },
    [contentEnum.totalSpending]: {
      renderer: getViewWithTransactionTypeFilter,
      rendererArgs: [
        getLookupWithMutipleNumber,
        [
          contentGroupEnum[contentEnum.totalSpending],
          contentEnum.totalSpending,
          conditionName,
        ],
        contentGroupEnum[contentEnum.totalSpending],
        conditionName,
      ],
    },
    [contentEnum.averageTransaction]: {
      renderer: getViewWithTransactionTypeFilter,
      rendererArgs: [
        getLookupWithMutipleNumber,
        [
          contentGroupEnum[contentEnum.averageTransaction],
          contentEnum.averageTransaction,
          conditionName,
        ],
        contentGroupEnum[contentEnum.averageTransaction],
        conditionName,
      ],
    },
    [contentEnum.newMember]: {
      renderer: getTimePeriodInput,
      rendererArgs: [
        contentGroupEnum[contentEnum.newMember],
        contentEnum.newMember,
        false,
        ['Within'],
        conditionName,
      ],
    },
    [contentEnum.newSignupWithReferral]: {
      renderer: getCustomTitleWithSwitch,
      rendererArgs: [
        contentGroupEnum[contentEnum.newSignupWithReferral],
        contentEnum.newSignupWithReferral,
        null,
        conditionName,
      ],
    },
    [contentEnum.numberOfLogin]: {
      renderer: (subIndex) => {
        const fieldBaseName = `${conditionName}.${subIndex}.${
          contentGroupEnum[contentEnum.numberOfLogin]
        }`;
        const fieldName = `${fieldBaseName}.numberOfLogin`;
        return (
          <div className="d-flex flex-column">
            <CustomTitleLabel title={contentEnum.numberOfLogin} />
            <div style={{ display: 'flex' }}>
              <input
                type="number"
                min={'0'}
                className={`custom-number-input-short ${
                  hasReactHookFormError(errors, fieldName) ? 'error-field' : ''
                }`}
                {...register(fieldName, {
                  required: 'This field is required.',
                })}
              />
              <label className="help-message margin-left-10-percent margin-top-8-percent">
                times
              </label>
            </div>
            <CustomErrorMessage name={fieldName} />
          </div>
        );
      },
      rendererArgs: [],
    },
    [contentEnum.lastLogin]: {
      renderer: getTimePeriodInput,
      rendererArgs: [
        contentGroupEnum[contentEnum.lastLogin],
        contentEnum.lastLogin,
        false,
        null,
        conditionName,
      ],
    },
    [contentEnum.campaignViewed]: {
      renderer: getCampaignView,
      rendererArgs: [
        contentGroupEnum[contentEnum.campaignViewed],
        contentEnum.campaignViewed,
        conditionName,
      ],
    },
    [contentEnum.acquireOrUsedCoupon]: {
      renderer: getAcquireOrUsedCouponView,
      rendererArgs: [
        contentGroupEnum[contentEnum.acquireOrUsedCoupon],
        contentEnum.acquireOrUsedCoupon,
        conditionName,
      ],
    },
    [contentEnum.earnReward]: {
      renderer: getCampaignView,
      rendererArgs: [
        contentGroupEnum[contentEnum.earnReward],
        contentEnum.earnReward,
        conditionName,
      ],
    },
    [contentEnum.products]: {
      renderer: (subIndex) => {
        const fieldBaseName = `${conditionName}.${subIndex}.${
          contentGroupEnum[contentEnum.products]
        }`;
        const fieldName = `${fieldBaseName}.productSkus`;
        return (
          <div className="d-flex flex-column">
            <CustomTitleLabel title={contentEnum.products} />
            <textarea
              className={`custom-textarea-input-box custom-textarea-input-font ${
                hasReactHookFormError(errors, fieldName) ? 'error-field' : ''
              }`}
              {...register(fieldName, { required: 'This field is required.' })}
            />
            <CustomErrorMessage name={fieldName} />
            <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
          </div>
        );
      },
      rendererArgs: [],
    },
    [contentEnum.eComBindStatus]: {
      renderer: getCustomTitleWithSwitch,
      rendererArgs: [
        contentGroupEnum[contentEnum.eComBindStatus],
        contentEnum.eComBindStatus,
        null,
        conditionName,
      ],
    },
    [contentEnum.productCategory]: {
      renderer: getProductCategory,
      rendererArgs: [
        contentGroupEnum[contentEnum.productCategory],
        conditionName,
      ],
    },
    [contentEnum.discountLover]: {
      renderer: (subIndex) => {
        const fieldBaseName = `${conditionName}.${subIndex}.${
          contentGroupEnum[contentEnum.discountLover]
        }`;
        const timesFieldName = `${fieldBaseName}.times`;
        return (
          <div className="d-flex flex-column">
            <InputWithMutipleDropDown
              fieldBaseName={fieldBaseName}
              title={contentEnum.discountLover}
              hasPeriodDropdown
            />
            <CustomTitleLabel title="Number of transaction with discount (optional)" />
            <div style={{ display: 'flex' }}>
              <input
                type="number"
                min={'1'}
                className="custom-number-input-short"
                {...register(timesFieldName)}
              />
            </div>
          </div>
        );
      },
      rendererArgs: [],
    },
    [contentEnum.additionalDataFromCustomers]: {
      renderer: getAdditionalDataSection,
      rendererArgs: [
        contentEnum.additionalDataFromCustomers,
        contentGroupEnum[contentEnum.additionalDataFromCustomers],
        conditionName,
      ],
    },
    [contentEnum.additionalDataFromTransactionRecords]: {
      renderer: getAdditionalDataSection,
      rendererArgs: [
        contentEnum.additionalDataFromTransactionRecords,
        contentGroupEnum[contentEnum.additionalDataFromTransactionRecords],
        conditionName,
      ],
    },
    [contentEnum.additionalDataFromPurchasedItems]: {
      renderer: getAdditionalDataSection,
      rendererArgs: [
        contentEnum.additionalDataFromPurchasedItems,
        contentGroupEnum[contentEnum.additionalDataFromPurchasedItems],
        conditionName,
      ],
    },
  };

  scoreDefinitionList.forEach((item) => {
    contentSectionComponment[item.name] = {
      renderer: getCustomRecencyFrequencyMonetaryTitle,
      rendererArgs: [conditionName, item.value, item.name],
    };
  });
  const recencyFrequencyMonetaryFieldNumber =
    getRecencyFrequencyMonetaryFieldNumber(fields);

  return (
    <>
      <div>
        <label className="create-section-title">
          Condition group {conditionIndex}
        </label>
        {fields.length > 0 ? (
          <button
            type="button"
            className="btn-link reset-button"
            onClick={() => setShowResetPrompt(true)}
          >
            Reset
          </button>
        ) : null}
        <ErrorFieldMessage
          id={`condition-group-${conditionIndex}-rfm-error`}
          error={recencyFrequencyMonetaryFieldNumber > 1}
          message='There will be no result if multiple RFM segments are selected with "AND" relationships. Try using "OR" relationships instead or selecting a single RFM segment only.'
        />
      </div>

      <div className="content-area">
        {fields.map((item, subIndex) => {
          const componmentConfig = contentSectionComponment[item.title];
          return (
            <>
              {subIndex > 0 ? (
                <div className="content-condition-area">
                  <div className="content-condition-dash"></div>
                  <label>And</label>
                </div>
              ) : null}
              <div className="display-flex-area">
                {componmentConfig && componmentConfig.renderer(
                  subIndex,
                  ...componmentConfig.rendererArgs,
                )}
                <button
                  className="reset-button content-delete-button-area"
                  type="button"
                  onClick={() => {
                    remove(subIndex);
                  }}
                >
                  <Image src={DeleteIcon} className="content-delete-button" />
                </button>
              </div>
            </>
          );
        })}
        <Dropdown
          onToggle={(isOpen) => {
            setIsShowDropdown(isOpen);
          }}
        >
          <Dropdown.Toggle
            className="btn-back-button-common content-add-button"
            variant="none"
            id={`dropdown-${conditionIndex}`}
          >
            + Add condition
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ zIndex: 2 }}>
            <ContentFilterDropDown
              show={isShowDropdown}
              addContent={(section) => {
                append({ title: section });
              }}
              settedParams={fields?.map((item) => item.title)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <BasePrompt
        show={showResetPrompt}
        title={`Reset condition group ${conditionIndex}`}
        description={'Did you confirm to reset this content section?'}
        closeAction={() => setShowResetPrompt(false)}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowResetPrompt(false);
          },
        }}
        rightButton={{
          text: 'Delete',
          action: () => {
            replace([]);
            setShowResetPrompt(false);
          },
        }}
      />
    </>
  );
}
const mapPropsToState = (state) => ({
  levels: state.levels.levelList,
  countryCodes: state.settings.countryCodes,
  // segment: state.segments.tempSegment,
  // createErrorDict: state.segments.createErrorDict,
  // tempSegmentConditionGroup: state.segments.tempSegment?.conditionGroup,
});

export default connect(mapPropsToState)(ContentItemSection);

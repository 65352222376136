import React from 'react';
import './CampaignItemOverview.scss';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
function CampaignItemOverview(props) {
  const history = useHistory();
  const params = useParams();
  const buttons = [
    <Button
      className="btn-further"
      onClick={() =>
        history.push({
          pathname: `/campaigns/${params.id}/edit`,
        })
      }
    >
      Edit
    </Button>,
  ];

  const tabs = [
    {
      name: 'Overview',
      content: <h1> CampaignItemOverview {params.id}</h1>,
    },
  ];

  return (
    <CustomListComponent
      breadcrumb={<CustomBreadcrumb />}
      buttons={buttons}
      tabs={tabs}
    />
  );
}

export default CampaignItemOverview;

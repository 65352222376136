import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import i18n from '../../I18n';
import {
    LanguageConfig,
} from '../../config/CustomEnums';
import {
    hasError,
    ReactHookFormErrorMessage,
} from '../base/ErrorFieldMessage';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';

const CampaignCategoryContentSection = ({
  language,
}) => {
  const isEnglish = language === LanguageConfig.english;
  const { watch, setValue, control, formState } =
    useFormContext();
  const { errors } = formState;
  const watchName = isEnglish ? watch('name') : watch(`translations.${language}.name`);
  const watchOrder = watch('order');
  const watchIsForcedInactive= watch('isForcedInactive');

  return (
    <>
      <div className="create-section-title">
        {i18n.t('content', { locale: 'en' })}
      </div>
      <Controller
        control={control}
        name={isEnglish ? "name" : `translations.${language}.name`}
        rules={
          isEnglish
            ? {
                required: 'Please provide a name',
                maxLength: {
                  value: 50,
                  message: 'Name is too long.'
                }
              }
            : {
              maxLength: {
                value: 50,
                message: 'Name is too long.'
              }
            }
        }
        render={() => (
          <CustomTitleWithInput
            title={i18n.t('name', { locale: 'en' })}
            defaultValue={watchName}
            error={{ error: hasError(errors, isEnglish ? 'name' : `translations.${language}.name`) }}
            setValue={(value) => {
              setValue(isEnglish ? 'name' : `translations.${language}.name`, value, {
                shouldDirty: true,
              });
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id={isEnglish ? "name" : `translations.${language}.name`} />

      {isEnglish ? (
        <>
          <Controller
            control={control}
            name={'order'}
            rules={{ 
                min: { value: 1, message: "Minimum value is 1" },
                required: "Please provide order",
             }}
            render={() => (
              <CustomTitleWithInput
                title={'Display order'}
                error={{ error: hasError(errors, 'order' )}}
                defaultValue={watchOrder}
                setValue={(value) => {
                  setValue('order', value, {
                    shouldDirty: true,
                  });
                }}
                type={'number'}
                // extra={{
                //   min: 1,
                // }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'order'} />
          <label className="coupon-campaign-section-caution-description">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
          <label className="create-section-label create-section-label-bottom-space">
            Is forced inactive
          </label>
          <Controller
            control={control}
            name="isForcedInactive"
            render={() => (
              <CustomSwitchButton
                defaultChecked={watchIsForcedInactive}
                onChange={(value) => {
                    setValue('isForcedInactive', value, {
                      shouldDirty: true,
                    });
                  }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id="isForcedInactive" />
        </>
      ) : null}
    </>
  )
};

export default CampaignCategoryContentSection;

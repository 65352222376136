import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import { ContinueWithBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import {
  defaultPreviewImage,
  earningRulesCampaignPreview,
} from 'components/campaign/campaignCreation/CampaignStepTwoPreviewConfig';
import 'components/campaign/campaignCreation/CreateCampaignStepTwo.scss';
import DetailSection from 'components/campaign/campaignCreation/DetailSection';
import InstructionSection from 'components/campaign/campaignCreation/InstructionSection';
import OverviewSection from 'components/earning/OverviewSection';
import { CampaignType, EarningRuleType } from 'config/CustomEnums';
import { isEarningRuleRequireToLinkCampaign } from 'models/EarningRulesModel';

import { validate } from './CreateEarningRuleValidate';

function CreateEarningRuleStepTwo({ languages }) {
  const dispatch = useDispatch();
  const { getValues, watch, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const linkedStampCampaign = watch('linkedStampCampaign');
  const type = watch('type');
  const inputWithImageConfig = earningRulesCampaignPreview;
  const defaultImage = defaultPreviewImage(CampaignType.earningRulesCampaign);

  const onlyShowOverview =
    linkedStampCampaign ||
    type === EarningRuleType.newMember ||
    type === EarningRuleType.birthday ||
    type === EarningRuleType.generalPurchase ||
    type === EarningRuleType.fillingForm ||
    type === EarningRuleType.levelUpgrade;

  const [activeSection, setActiveSection] = useState();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'createEarningRules/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  const getTabs = (language) => {
    return onlyShowOverview
      ? [
          <OverviewSection
            onFocus={setActiveSection}
            language={language}
            areaTitle={'overview'}
            tips={
              !isEarningRuleRequireToLinkCampaign(type) &&
              i18n.t('earningRuleForStampCampaignTips', { locale: language })
            }
            requireAllLanguage={!isEarningRuleRequireToLinkCampaign(type)}
          />,
        ]
      : [
          <OverviewSection
            onFocus={setActiveSection}
            language={language}
            areaTitle={'overview'}
            requireAllLanguage={!isEarningRuleRequireToLinkCampaign(type)}
          />,
          <InstructionSection
            focusChange={setActiveSection}
            language={language}
          />,
          <DetailSection focusChange={setActiveSection} language={language} />,
        ];
  };

  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        languageTabContent={{
          containers: languages?.map((language) => {
            return {
              container: getTabs(language.code, setActiveSection),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        inputWithImageConfig={onlyShowOverview ? null : inputWithImageConfig}
        defaultImage={onlyShowOverview ? null : defaultImage}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateEarningRuleStepTwo);

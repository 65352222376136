import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './StoreList.scss';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import { createAction, ArrayStringData } from '../../../utils';
import queryString from 'query-string';


function StoreList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
  brandList,
  checkedBrandList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  //   const [activeTab, setActiveTab] = useState(tabs[0].name);
  // const [sortDisabled, setSortDisabled] = useState(false);
  const sortDisabled = false
  const parsedSearch = queryString.parse(location.search);
  const searchBrand = parsedSearch['brand'] || '';

  const buttons = [
    <AuthButton
      title="Create Store"
      action={() => {
        history.push('/stores/create');
      }}
      requires={PermissionCodes.addStore}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteStore,
    },
  ];

  const tabs = [
    {
      name: 'List of Stores',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeModel'}
              permissionGroup={PermissionCodes.store}
              deleteInfo={{
                data: [],
                title: 'store',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.STORE,
              }}
              sortable
              sortDisabled={sortDisabled}
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const startOrder = dataList[0].order;
                // const newDataList = arrayMove(dataList, oldIndex, newIndex);

                const movedItem = dataList[oldIndex];
                dispatch({
                  type: 'storeModel/createOrUpdate',
                  payload: {
                    data: {
                      id: movedItem.pk,
                      displayPriority: startOrder + newIndex,
                    },
                    afterAction: () => history.push(location),
                  }
                });
                // setDataList(
                //   newDataList.map((data, index) => ({
                //     ...data,
                //     order: startOrder + index,
                //   })),
                // );
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                dispatch({
                  type: 'storeModel/createOrUpdate',
                  payload: {
                    data: {
                      id: pk,
                      displayPriority: newOrder,
                    },
                    afterAction: () => history.push(location),
                  }
                });
              }}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              hasOtherActions
              customActions={{
                moveToFirst: (item) => {
                  dispatch({
                    type: 'storeModel/createOrUpdate',
                    payload: {
                      data: {
                        id: item.pk,
                        displayPriority: 1,
                      },
                      afterAction: () => history.push(location),
                    }
                  });
                },
                moveToLast: (item) => {
                  dispatch({
                    type: 'storeModel/createOrUpdate',
                    payload: {
                      data: {
                        id: item.pk,
                        displayPriority: totalCount,
                      },
                      afterAction: () => history.push(location),
                    }
                  });
                },
              }}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: 'storeModel/clearData' });
    dispatch(createAction('brand/updateState')({
      checkedList: []
    }))

    const queryString = require('query-string');
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      dispatch({
        type: 'storeModel/getPagedStoreList',
        payload: {
          ...querySearch,
          page,
          reverse: rank,
          search: searchKey,
          isListNode: true,
        }
      });
    }
    dispatch({
      type: 'storeCategoryList/getAllStoreSubcategoryList',
      payload: { isSimpleFilter: true }
    });
    dispatch({
      type: 'brand/getCurrentPageBrands',
      payload: {
        moreSearch: {
          brandIds: searchBrand
        },        
        isSimpleNode: true,
        noLoading: true,
      }
    });
  }, [dispatch, location]);

  const getSearchBrand = () => {
    let checkedBrands = []
    const searchBrandList = ArrayStringData(searchBrand)
    searchBrandList.forEach((item, index) => {
      const checkedBrand = brandList.find(value => value?.pk === item)
      if (checkedBrand) {
        checkedBrands.push({
          label: `[ID: ${checkedBrand?.pk}] ${checkedBrand?.name}`,
          value: checkedBrand
        })
      }
    })
    dispatch(createAction('brand/updateState')({
      checkedList: checkedBrands
    }))
  }

  useEffect(()=>{
    if ( searchBrand && brandList?.length > 0 && checkedBrandList?.length === 0 ) {
      getSearchBrand()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandList])

  useEffect(()=>{
    getSearchBrand()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBrand])


  const detail = 'After you create stores, you can specify which store the coupon can be used in.   A store may belongs to a brand.';

  return (
    <>
      <CustomListComponent
        caution={{
          detail: detail,
          title: NavBarNames.stores,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <DeletePrompt
        data={checkedList}
        title={'stores'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.STORE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'storeModel/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            }
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.storeModel.listDisplayFields,
  dataList: state.storeModel.pagedStoreList,
  pageInfo: state.storeModel.pageInfo,
  totalPage: state.storeModel.totalPage,
  totalCount: state.storeModel.totalCount,
  checkedList: state.storeModel.checkedList,
  brandList: state.brand.brandList,
  checkedBrandList: state.brand.checkedList,
});

export default connect(mapPropsToState)(StoreList);

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SaveAndBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomRadios from 'components/base/CustomRadios';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import { hasError } from 'components/base/ErrorFieldMessage';
import ItemTypeSet from 'components/base/ItemTypeSet';
import { GIFTCARD_CONTACT_TYPE } from 'config/CustomEnums';

import { createAction } from 'utils';

export const CONTACT_TYPE_CONFIG = [
  {
    label: 'Email',
    value: GIFTCARD_CONTACT_TYPE.EMAIL,
  },
  {
    label: 'SMS',
    value: GIFTCARD_CONTACT_TYPE.SMS,
  },
];

const sendMethodKey = 'sendMethod';
const recipientContactKey = 'recipientContact';

function GiftCardContent() {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const watchSendMethod = watch(sendMethodKey);
  const watchRecipientContact = watch(recipientContactKey);

  return (
    <>
      <label className="step-one-title">Recipient contact type</label>
      <div style={{ display: 'flex', marginTop: 8 }}>
        <CustomRadios
          onChange={(value) => {
            setValue(sendMethodKey, value, { shouldDirty: true });
          }}
          default={watchSendMethod}
          options={CONTACT_TYPE_CONFIG}
          disabled={false}
        />
      </div>

      <CustomTitleWithInput
        title="Recipient contact"
        defaultValue={watchRecipientContact}
        setValue={(value) => {
          setValue(recipientContactKey, value, {
            shouldDirty: true,
          });
        }}
        error={
          hasError(errors, recipientContactKey)
            ? {
                id: recipientContactKey,
                error: hasError(errors, recipientContactKey),
                message: 'please input a valid contact',
              }
            : {}
        }
      />
    </>
  );
}

export default function EditVirtualGiftCardContent() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, setError, clearErrors } = useFormContext();
  const validate = async () => {
    clearErrors([recipientContactKey]);
    const [recipientContact] = getValues([recipientContactKey]);
    let isError = false;
    if (!recipientContact) {
      isError = true;
      setError(
        recipientContactKey,
        {
          type: 'require',
          message: 'please input contact',
        },
        { shouldFocus: true },
      );
    }
    return !isError;
  };

  return (
    <>
      <ContentSections sections={[<GiftCardContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            const values = getValues()
            console.log('@120', values);
            dispatch(createAction('giftCard/updateVirtualGiftCard')(values));
          }
        }}
      />
    </>
  );
}

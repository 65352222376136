import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import AuthButton from "components/base/AuthButton";
import CustomBreadcrumb from "components/base/CustomBreadcrumb";
import CustomListComponent from "components/base/CustomListComponent";
import CustomRadios from "components/base/CustomRadios";
import BasePrompt from "components/base/prompt/BasePrompt";
import { NavBarNames } from "config/NavBarNameList";
import { PermissionCodes } from "config/PermissionCodes";
import BaseTabListContainer from "containers/base/BaseTabListContainer";
import BaseListContainer from "containers/base/BaseListContainer";
import { createAction } from "utils";
import MessageCreditRecordFilter from "./MessageCreditRecordFilter";
import { ImportResource } from "models/DownloadImportModel";

const MessageCreditRecords = ({
  listFields,
  dataList,
  pageInfo,
  totalCount,
  totalPage,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isExportAll, setIsExportAll] =  useState(true);
  const [showDownloadReportPrompt, setShowDownloadReportPrompt ] = useState(false);

  const querySearch = queryString.parse(location.search);

  useEffect(() => {
    dispatch({ type: 'messageCreditRecord/clearData' });

    const searchKey = querySearch['search'];
    const stringRank = querySearch['rank'] || 'true';
    const stringPage = querySearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('messageCreditRecord/getCurrentPageMessageCreditRecords')({
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
      }),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  const buttons = [
    <AuthButton
      title="Export report"
      action={() => {
        setShowDownloadReportPrompt(true)
      }}
      requires={PermissionCodes.addExportjob}
    />,
  ];

  const exportReportAction = () => {
    const searchDict = queryString.parse(location.search);
    const creattionDatePeriod = searchDict?.['create_at']?.split(',');
    const creditConsumedRange = searchDict?.['credit_consumed']?.split(',');
    const nameIcontains = searchDict['search'];

    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.messageCreditRecord,
        ...(isExportAll ? null :
          {
            related: {
              nameIcontains,
              creationDateGte: creattionDatePeriod?.[0],
              creationDateLte: creattionDatePeriod?.[1],
              creditConsumedGte: creditConsumedRange?.[0],
              creditConsumedLte: creditConsumedRange?.[1],
            }
          })
      }),
    );
    setShowDownloadReportPrompt(false)
  }

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          // groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            component: MessageCreditRecordFilter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'messageCreditRecord'}
              permissionGroup={PermissionCodes.message}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              hasOtherActions={true}
              actions={['Detail']}
              customActions={{
              }}
            />
          }
        />
      )
    },
  ]
  const [activeTab, setActiveTab] = useState(tabs?.[0]?.name);

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.messageCreditRecords,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <BasePrompt
        customClass={'export-report-modal'}
        show={showDownloadReportPrompt}
        title={'Export report'}
        description={'Select the export range'}
        closeAction={() => {
          setShowDownloadReportPrompt(false);
        }}
        otherBody={() => (
          <div className='d-flex'>
            <CustomRadios
              onChange={(value) => {
                setIsExportAll(value);
              }}
              default={isExportAll}
              options={[
                {
                  label: 'All items',
                  value: true,
                },
                {
                  label: 'All filtered items from the list',
                  value: false,
                },
              ]}
            />
          </div>
        )}
        rightButton={{
          text: 'Export',
          action: exportReportAction,
          requires: PermissionCodes.addExportjob,
        }}
      />
    </>
  )
}
const mapPropsToState = (state) => ({
  listFields: state.messageCreditRecord.listDisplayFields,
  dataList: state.messageCreditRecord.pagedList,
  pageInfo: state.messageCreditRecord.pageInfo,
  totalPage: state.messageCreditRecord.totalPage,
  totalCount: state.messageCreditRecord.totalCount,
  checkedList: state.messageCreditRecord.checkedList,
});

export default connect(mapPropsToState)(MessageCreditRecords);
import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomSwitchButton from './CustomSwitchButton';

import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomTips from './CustomTips';
function CustomTitleWithSwitch({
  title,
  customTitleClass,
  defaultValue,
  setValue,
  error = {},
  tips,
  tipsPopoverDetail,
  bottomDescription,
}) {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} className={customTitleClass} />
      {tips ?
        <div className="tips-with-popover">
          <label className="tips-message">
            {tips}
          </label>
          {tipsPopoverDetail &&
            <CustomTips
              popoverClassName="tips-popover"
              detail={tipsPopoverDetail}
            />}
        </div>
        : null}
      <CustomSwitchButton
        defaultChecked={defaultValue}
        onChange={(isChecked) => {
          setValue(isChecked);
        }}
      />
      {
        error.error
        ? <ErrorFieldMessage id={error.id} error={error} message={error.message} />
        : null
      }
      {bottomDescription ?
        <div className='section-switch-button-description' style={{ margin: 0, lineHeight: "24px" }}>
          {bottomDescription}
        </div> : null}
    </div>
  );
}

export default CustomTitleWithSwitch;

import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getWebviews = (afterCursor = '', search = {}) => {
  let searchString = '';
  let orderBy = '-pk';
  if (search.search) {
    searchString += `, name: "${search.search}"`;
  }
  if (search?.sort) {
    orderBy = search.sort;
  }
  const query = `{
    webviews(first:100, after:"${afterCursor}", orderBy:"${orderBy}" ${searchString}){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        edges {
            node {
                id
                pk
                name
                content
                creationDate
                url
                translations{
                  edges{
                    node{
                      pk
                      language
                      content
                    }
                  }
                }
            }
        }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneWebview = (id) => {
  const query = `{
        webview(id: "${id}"){
            pk
            id
            name
            content
            translations{
              edges{
                node{
                  pk
                  language
                  content
                }
              }
            }
        }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteWebviews = (ids) => {
  const query = `
    mutation DeleteWebviews($input: DeleteWebviewsInput!) {
        deleteWebviews(input: $input) {
            success
            errors{
                field
                messages
            }
        }
    }
    `;
  const variables = { input: { pks: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createWebview = (values) => {
  const query = `mutation CreateWebview($input: CreateWebviewInput!) {
        createWebview(input: $input) {
            success
            node{
                id
                pk
                name
                content
                translations{
                  edges{
                    node{
                      pk
                      language
                      content
                    }
                  }
                }
            }
            errors{
                field
                messages
            }
        }
    }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateWebview = (values) => {
  console.log(values);
  const query = `mutation UpdateWebview($input: UpdateWebviewInput!) {
    updateWebview(input: $input) {
      success
      node{
          id
          pk
          name
          content
          translations{
            edges{
              node{
                pk
                language
                content
              }
            }
          }
      }
      errors{
          field
          messages
      }
    }
  }`;
  const variables = { input: values };
  console.log('@@API  ', variables);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from 'moment';

import DateRangeSelection, { RANGE_TYPES } from "components/dashboard/DateRangeSelection";
import { createAction, formatNumberWithCommas } from "utils";

import "./MessageCredit.scss"

const mapPropsToState = (state) => ({
  realCreditTotalSpent: state.messageList.realCreditTotalSpent
});

export const MessageCreditCardContent = ({ realCreditTotalSpent }) => {
  const dispatch = useDispatch();

  const [lastUpdateTime, setLastUpdateTime] = useState(moment().format("DD MMM yyyy(ddd), HH:mm a"))

  return (
    <div className="message-credit-card-content">
      <div className="message-credit-card-header">
        <div className="message-credit-card-title">{"Messages Credit"}</div>
        <div>{`Last update time: ${lastUpdateTime}`}</div>
      </div>

      <div className="message-credit-spent-section">
        <div className="message-credit-date-range" >
          <div>
            {"Credit spent"}
          </div>
          <DateRangeSelection
            applyDateRangeEvent={(startDate, endDate) => {
              dispatch(createAction('messageList/getMessageCredit')({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD'),
                successAction: () => {
                  setLastUpdateTime(moment().format("DD MMM yyyy(ddd), HH:mm a"))
                }
              }))
            }}
            clearDataEvent={() => {

            }}
            defaultDateRange={RANGE_TYPES.CURRENT_MONTH}
            isInCustomListComponent={false}
          />
        </div>
        <div>
          {formatNumberWithCommas(realCreditTotalSpent || "0.00")}
        </div>
      </div>
    </div>
  )
}

const MessageCredit = ({
  realCreditTotalSpent
}) => {

  return (
    <div className="message-credit-card">
      <MessageCreditCardContent
        realCreditTotalSpent={realCreditTotalSpent}
      />
    </div>
  )
}

export default connect(mapPropsToState)(MessageCredit);
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import { ContinueWithBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import ActivePeriodSection from 'components/base/period/ActivePeriodSection';
import CouponCampaignSection from 'components/campaign/campaignCreation/CouponCampaignSection';
import PropertiesGeneralSection from 'components/campaign/campaignCreation/PropertiesGeneralSection';
import VisiblePeriodSection from 'components/campaign/campaignCreation/VisiblePeriodSection';
import { CampaignType } from 'config/CustomEnums';
import { validate } from 'containers/engagement/campaign/CreateCampaignValidate';

import './CreateCampaignStepTwo.scss';

function CreateCampaignStepThree() {
  // const { campaignType } = useSelector((state) => ({
  //   campaignType: state.createCampaign.campaign.campaignType,
  // }));
  const dispatch = useDispatch();
  const { getValues, watch, setError, clearErrors } = useFormContext();
  const campaignType = watch('campaignType');
  console.log('kevin@1', campaignType);
  const watchHideInAnyChannel = watch('hideInAnyChannel');

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 2, isValid },
    });
  };

  // const stopCheck = () => {
  //   setOnCheck({
  //     value: false,
  //     isBack: false,
  //   });
  // };

  // useEffect(() => {
  //   if (onCheck.value) {
  //     let formatedData = {};
  //     dataArray.forEach((item) => {
  //       formatedData = {
  //         ...formatedData,
  //         ...item,
  //       };
  //     });
  //     dispatch({
  //       type: 'createCampaign/stepChange',
  //       payload: {
  //         data: formatedData,
  //         isBack: onCheck.isBack,
  //         step: 2,
  //       },
  //     });
  //   }
  //   stopCheck();
  // }, [dispatch, onCheck.isBack, onCheck.value]);

  // const submitAction = (data) => {
  //   dataArray.push(data);
  // };

  const sections = [
    watchHideInAnyChannel ? null : (
      <PropertiesGeneralSection
      // onSubmit={onCheck.value}
      // onSubmitAction={(data) => {
      //   submitAction(data);
      // }}
      />
    ),
    <ActivePeriodSection
      disableBlackout={campaignType === 'GENERAL_MESSAGE_CAMPAIGN'}
    // onSubmit={onCheck.value}
    // onSubmitAction={(data) => {
    //   submitAction(data);
    // }}
    />,
    ...(watchHideInAnyChannel
      ? []
      : [
          <VisiblePeriodSection
          // onSubmit={onCheck.value}
          // onSubmitAction={(data) => {
          //   submitAction(data);
          // }}
          />,
          campaignType === CampaignType.couponsCampaign ? (
            <CouponCampaignSection
            // onSubmit={onCheck.value}
            // onSubmitAction={(data) => {
            //   submitAction(data);
            // }}
            />
          ) : null,
        ]),
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState)(CreateCampaignStepThree);

import React, { useState, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import i18n from 'I18n';

import CustomTips from 'components/base/CustomTips';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import HtmlEditor from 'components/base/HtmlEditor';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import {
  AppCampaignDetailUrl,
  ApprovalStatus,
  CampaignDetailUrl,
  LanguageConfig,
} from 'config/CustomEnums';
import {
  CampaignLinkA,
  InsertParameterOptions,
  PresetTemplate,
} from 'config/MessageEmail';
import { getCampaignList } from 'services/CampaignAPIHelper';

import { addDomainToImage, debounce } from 'utils';

import './MessageGeneralSectionEmail.scss';

function MessageGeneralSectionEmail({ language }) {
  const dispatch = useDispatch();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const { errors } = formState;

  const messageTitleField = `translations.${language}.emailTitle`;
  const messageContentField = `translations.${language}.emailContent`;

  const name = watch(messageTitleField);
  const initHtmlContent = getValues(messageContentField);

  const [titleStart, setTitleStart] = useState(0);
  const [showApplyPrompt, setShowApplyPrompt] = useState(false);

  const htmlEditorRef = useRef(null);
  const setInitContent = (v) => {
    htmlEditorRef.current.setContent(v);
  };

  const updateDebounceText = debounce((text, callback, editor, forApp) => {
    if (text?.length > 0) {
      getCampaignList(btoa('arrayconnection:-1'), {
        filterName: text,
        isPageList: false,
        isSimpleList: false,
        others: {
          is_origin: true,
          status: ApprovalStatus.published,
        },
      }).then((resp) => {
        console.log('@123', resp, text);
        const listData = resp.data.data.campaigns.edges.map((campaign) => {
          const node = campaign.node;
          return {
            type: 'menuitem',
            text: `[ID: ${node.pk}] ${node.name}`,
            onAction: () => {
              let campaignName = node.name;
              // console.log('campaign ', node);
              for (const translationNode of node.translations.edges) {
                if (
                  translationNode.node?.name &&
                  translationNode.node?.language == language
                ) {
                  campaignName = translationNode.node.name;
                  break;
                }
              }
              const url = forApp
                ? `${AppCampaignDetailUrl}${node.pk}`
                : `${CampaignDetailUrl}${node.pk}`;
              const aElement = CampaignLinkA.replace(
                '{{linkTitle}}',
                campaignName,
              )
                .replace('{{linkContent}}', campaignName)
                .replace('{{linkUrl}}', url);
              editor.insertContent(aElement);
            },
          };
        });
        if (listData?.length > 0) {
          console.log('@124', listData);
          callback(listData);
        } else {
          const items = [
            {
              type: 'menuitem',
              text: 'Not found campaigns',
              onAction: () => {},
            },
          ];
          callback(items);
        }
      });
    } else {
      const items = [
        {
          type: 'menuitem',
          text: 'Please input keyword search campaign',
          onAction: () => {},
        },
      ];
      callback(items);
    }
  }, 500);

  const getContentTips = () => {
    return (
      <ul className='content-tips'>
        <li>
          {("Insert campaign URL for web\n" +
            "When customer click on the link, will open the page in website and " +
            "show a banner for customer to open the same content in native " +
            "app, redirect to app store if the native app has not been installed.")}
        </li>
        <li>
          {"Insert campaign deep link for app\n" +
            "When customer click on the link, will redirect to native app if the" +
            "app has been installed;  if not installed, will show a page to app" +
            "store."}
        </li>
      </ul>
    )
  }

  return (
    <div className="create-message-step-email-container">
      <BasePrompt
        show={showApplyPrompt}
        closeAction={() => setShowApplyPrompt(false)}
        rightButton={{
          text: 'Continue',
          action: () => {
            setShowApplyPrompt(false);
            setInitContent(PresetTemplate);
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowApplyPrompt(false),
        }}
        title={'Apply preset template'}
        description={
          'Are you sure to clear all the content and apply the preset template?'
        }
      />

      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>

      <CustomTitleWithInput
        title={i18n.t('email_title', { locale: language })}
        tips={i18n.t('inbox_email_title_suggest', { locale: language })}
        type={'text'}
        customClass={'text-input-field'}
        defaultValue={name}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(messageTitleField, value, { shouldDirty: true });
        }}
        paramText={i18n.t('message_insert_param', { locale: language })}
        onParamSelected={(value) => {
          if (name) {
            const newValue =
              name.substring(0, titleStart) +
              value +
              name.substring(titleStart);
            setValue(messageTitleField, newValue, { shouldDirty: true });
          } else {
            setValue(messageTitleField, value, { shouldDirty: true });
          }
        }}
        blurAction={(target) => {
          const start = target.selectionStart;
          setTitleStart(start);
        }}
        error={{ error: hasError(errors, messageTitleField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageTitleField} />
      <CustomTitleLabel title={
        <CustomTips detail={getContentTips()}>
          {i18n.t('content', {locale: language })}
        </CustomTips>
      } />
      <Controller
        control={control}
        name={messageContentField}
        render={({ field, fieldState: { error } }) => {
          console.log('@68', field, typeof field.value);

          return (
            <>
              <HtmlEditor
                ref={htmlEditorRef}
                initialValue={initHtmlContent}
                init={{
                  selector: 'textarea',
                  height: 1000,
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
                  images_upload_handler: async (blobInfo) => {
                    return new Promise((resolve, reject) => {
                      dispatch({
                        type: 'uploadFiles/uploadBlob',
                        payload: {
                          blobInfo,
                          afterAction: (data) => {
                            if (data?.status < 300) {
                              resolve(addDomainToImage(data.blob_image));
                            } else {
                              reject('');
                            }
                          },
                        },
                      });
                    });
                  },
                  toolbar: [
                    'applyPresetTemplate | insertCampaignUrl | insertCampaignDeeplink | insertParameters',
                    'undo redo | bold italic underline strikethrough | ' +
                      'fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify |  ' +
                      'outdent indent |  numlist bullist | forecolor backcolor removeformat | ' +
                      'fullscreen  preview | image media link codesample | ' +
                      'ltr rtl',
                  ],
                  setup: function(editor) {
                    /* Apply preset template */
                    editor.ui.registry.addButton('applyPresetTemplate', {
                      text: i18n.t('message_apply_template', {
                        locale: language,
                      }),
                      onAction: (_) => {
                        const content = editor.getContent({ format: 'text' });
                        if (content && content.length > 0) {
                          setShowApplyPrompt(true);
                        } else {
                          editor.setContent(PresetTemplate);
                        }
                      },
                    });

                    /* insert Campaign Deeplink */
                    editor.ui.registry.addMenuButton('insertCampaignUrl', {
                      text: i18n.t('message_insert_campaign_web', {
                        locale: language,
                      }),
                      search: {
                        placeholder: 'Type...',
                      },
                      fetch: (callback, fetchContext) => {
                        const value = fetchContext.pattern;
                        console.log('call api get search result: ', value);
                        updateDebounceText(value, callback, editor, false);
                      },
                    });
                    editor.ui.registry.addMenuButton('insertCampaignDeeplink', {
                      text: i18n.t('message_insert_campaign_app', {
                        locale: language,
                      }),
                      search: {
                        placeholder: 'Type...',
                      },
                      fetch: (callback, fetchContext) => {
                        const value = fetchContext.pattern;
                        console.log('call api get search result: ', value);
                        updateDebounceText(value, callback, editor, true);
                      },
                    });

                    /* Insert parameters */
                    editor.ui.registry.addMenuButton('insertParameters', {
                      text: i18n.t('message_insert_param', {
                        locale: language,
                      }),
                      fetch: (callback, fetchContext) => {
                        const items = [];
                        for (const option of InsertParameterOptions) {
                          items.push({
                            type: 'menuitem',
                            text: option.name,
                            onAction: () => {
                              editor.insertContent(option.value);
                            },
                          });
                        }
                        console.log('items', items);
                        callback(items);
                      },
                    });
                  },
                }}
                onEditorChange={(value, editor) => {
                  // console.log("@79", editor);
                  // console.log("@71", value);
                  field.onChange(value);
                }}
                className={error ? 'error-field' : ''}
              />
            </>
          );
        }}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={messageContentField}
      />
    </div>
  );
}

export default MessageGeneralSectionEmail;

import React, { useState } from 'react';
import { Collapse, Image } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';

import AuthButton from 'components/base/AuthButton';
import CustomRangePicker from 'components/base/CustomRangePicker';
import CustomSwitchButton from 'components/base/CustomSwitchButton';
import ErrorFieldMessage from 'components/base/ErrorFieldMessage';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';

import { getCampaignPeriodDateTime, TimeFormater } from 'utils/TimeFormatUtil';

import deleteImage from 'assets/images/close.svg';

import './PeriodAndWeekdaySection.scss';

const { RangePicker } = DatePicker;

function PeriodAndWeekdaySection({
  title,
  titleDescription,
  switchButtonTitle,
  periodValuesField,
  periodValues = [],
  periodValuesChange = (() => {}),
  weekday = [],
  weekdayChange = (() => {}),
  disabled,
  switchButtonError = {},
  maxDate,
  minDate,
  disabledDate,
}) {
  const { watch, setValue } = useFormContext();

  const dateFormat = TimeFormater.blackoutPeriod;

  const [show, setShow] = useState(
    periodValues.length || weekday.length ? true : false,
  );

  const getRangePicker = () => {
    return periodValues.map((item, index) => {
      const watchStartDate = watch(`${periodValuesField}.${index}.startDate`);
      const watchEndDate = watch(`${periodValuesField}.${index}.endDate`);
      const onChange = (dates, dateStrings) => {
        let [start, end] = dates || [];
        if (dates && dates.length) {
          start = new Date(start);
          end = new Date(end);
          start.setSeconds(0);
          end.setSeconds(0);
        }
        console.log('start', start);
        console.log('end', end);
        setValue(`${periodValuesField}.${index}.startDate`, start, {
          shouldDirty: true,
        });
        setValue(`${periodValuesField}.${index}.endDate`, end, {
          shouldDirty: true,
        });
      };
      return (
        <div className="d-flex flex-row" style={{ marginBottom: '8px' }}>
          <CustomRangePicker
            defaultValue={[
              watchStartDate
                ? moment(getCampaignPeriodDateTime(watchStartDate), dateFormat)
                : null,
              watchEndDate
                ? moment(getCampaignPeriodDateTime(watchEndDate), dateFormat)
                : null,
            ]}
            onChange={onChange}
            dateFormat={dateFormat}
            disabledDate={disabledDate}
            minDate={minDate}
            maxDate={maxDate}
          />
          <AuthButton
            children={<Image src={deleteImage} className="custom-delete-btn" />}
            customClass="custom-delete-btn"
            action={() => {
              const tempArray = [].concat(periodValues);
              tempArray.splice(index, 1);
              periodValuesChange(tempArray);
            }}
          />
        </div>
      );
    });
  };

  return (
    <div className="d-flex flex-column">
      {title && <label className="create-section-title">{title}</label>}
      {titleDescription && (
        <label className="section-short-description">{titleDescription}</label>
      )}
      {switchButtonTitle && (
        <label className="create-section-label create-section-label-bottom-space">
          {switchButtonTitle}
        </label>
      )}
      <CustomSwitchButton
        disabled={disabled}
        defaultChecked={show}
        onChange={() => {
          const newFlag = !show;
          setShow(newFlag);
          if (!newFlag) {
            periodValuesChange([]);
            weekdayChange([]);
          }
        }}
      />
      <ErrorFieldMessage
        id={switchButtonError.id}
        error={switchButtonError.message}
        message={switchButtonError.message}
      />
      <Collapse in={show}>
        <div>
          <div className="d-flex flex-column">
            <CustomTitleLabel title="By period" />
            {getRangePicker()}
            <AuthButton
              title="Add period"
              customClass={'btn-back-button-common'}
              action={() => {
                const tempArray = [].concat(periodValues);
                tempArray.push({
                  startDate: '',
                  endDate: '',
                });
                periodValuesChange(tempArray);
              }}
            />

            <CustomTitleLabel title="By weekday" />
            <Checkbox.Group
              value={weekday}
              options={[
                { label: 'Monday', value: 'MONDAY' },
                { label: 'Tuesday', value: 'TUESDAY' },
                { label: 'Wednesday', value: 'WEDNESDAY' },
                { label: 'Thursday', value: 'THURSDAY' },
                { label: 'Friday', value: 'FRIDAY' },
                { label: 'Saturday', value: 'SATURDAY' },
                { label: 'Sunday', value: 'SUNDAY' },
              ]}
              onChange={weekdayChange}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default PeriodAndWeekdaySection;

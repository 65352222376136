import axios from 'axios';

const CMS_BASE_URL =
  process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com:443';
const AUTH_PATH = '/graphql';
const userAuthAPIinstance = axios.create({
  baseURL: CMS_BASE_URL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

class AuthAPIHelper {
  createAccessToken = (payload) => {
    const { username, password, method, code } = payload;
    console.log(username, password);
    const graphQL = {
      query: `mutation TokenAuth($input: ObtainJSONWebTokenInput!) {
          tokenAuth(input: $input) {
            token
            payload
            refreshToken
            refreshExpiresIn
            administrator {
              pk
              username
              isSuperuser
              allPermissions
            }
          }
        }`,
      variables: { input: { username: username, password: password, method, code } },
    };
    return userAuthAPIinstance
      .post(AUTH_PATH, JSON.stringify(graphQL))
      .catch((error) => error);
  };
  refreshAccessToken = (refreshToken) => {
    const query = `mutation RefreshToken($input: RefreshInput!) {
        refreshToken(input: $input) {
          token
          payload
          refreshToken
          refreshExpiresIn
          administrator {
            username
            isSuperuser
            allPermissions
        }
        }
      }`;
    const variables = {
      input: {
        refreshToken: refreshToken,
      },
    };
    const graphQL = { query, variables };
    return userAuthAPIinstance
      .post(AUTH_PATH, JSON.stringify(graphQL))
      .catch((error) => error);
  };

  shopifyCreateAccessToken = (payload) => {
    const { username, password } = payload;
    const graphQL = {
      query: `
        mutation ShopifyTokenAuth ($input:ObtainShopifyJSONWebTokenInput!) {
          shopifyTokenAuth(input: $input) {
          token
          payload
          refreshToken
          refreshExpiresIn
          administrator {
              username
              isSuperuser
              allPermissions
          }
          }
        }`,
      variables: { input: { username: username, password: password } },
    };
    return userAuthAPIinstance
      .post(AUTH_PATH, JSON.stringify(graphQL))
      .catch((error) => error);
  };

  logout = (refreshToken) => {
    const query = `mutation RevokeToken($input: RevokeInput!) {
      revokeToken(input: $input) {
        revoked
      }
    }`;
    const variables = {
      input: {
        refreshToken: refreshToken,
      },
    };
    const graphQL = { query, variables };
    return userAuthAPIinstance
      .post(AUTH_PATH, JSON.stringify(graphQL))
      .catch((error) => error);
  };
}
export const authAPIHelper = new AuthAPIHelper();

import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
} from '../../../models/StoreModel';
import { SavedStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateStoreStepOne from './CreateStoreStepOne';
import Loading from '../../../components/base/Loading';
// import './CreateStore.scss';

function CreateStore({
  store,
  formHasSubmitted,
  saved,
  hasUpdatedDefaultValues,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const stepSet = [<CreateStoreStepOne />];
  const goToStoreList = () => history.push({ pathname: '/stores' });

  useEffect(() => {
    if (id) {
      dispatch(createAction('storeModel/getOneStore')({ id }));
    } else {
      dispatch({ type: 'storeModel/loadDataFromCookie' });
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id && saved === SavedStatus.savedWithSuccess) {
      goToStoreList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  if (!store?.id && id) {
    return <Loading customClass={'common-full-height '} />;
  }

  const getButtons = () => {
    if (history.location.search) {
      return [
        {
          text: 'Back to store category list',
          action: () => {
            history.push({
              pathname: '/store_category',
            });
          },
        },
        ...(store?.isOnlineStore ? [] : [{
          text: 'Preview QR code',
          action: () =>
            history.push({
              pathname: `/stores/${store.storePK}/`,
            }),
        }]),
        {
          text: 'Maybe Later',
          action: () => history.push('/stores'),
        },
      ];
    }
    return [
      ...(store?.isOnlineStore ? [] : [{
        text: 'Preview QR code',
        action: () =>
          history.push({
            pathname: `/stores/${store.storePK}/`,
          }),
      }]),
      {
        text: 'Back to store list',
        action: () => {
          history.push({
            pathname: '/stores',
          });
        },
      },
    ];
  };

  return (
    <BaseForm
      defaultValues={store}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      showFinishPop={saved === SavedStatus.savedWithSuccess}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: goToStoreList,
        onClose: goToStoreList,
      }}
      nextStepConfig={{
        title: `Successfully ${id ? 'Updated' : 'Created'}!`,
        description: `Store is successfully ${
          id ? 'updated' : 'created'
          }.${store?.isOnlineStore ? '' : `\n You can preview and download the store QR code`}`,
        steps: null,
        onHide:()=>{},
        buttons: getButtons(),
      }}
      content={stepSet}
      currentStep={0}
      breadcrumb={<CustomBreadcrumb name={id ? store.storeName : ''} />}
      caution={{
        detail: '',
        title: id ? store.storeName : 'Create Store',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  store: state.storeModel.oneStore,
  formHasSubmitted: state.storeModel.formHasSubmitted,
  saved: state.storeModel.saved,
  hasUpdatedDefaultValues: state.storeModel.hasUpdatedDefaultValues,
})

export default connect(mapPropsToState)(CreateStore);

import React from 'react';
import { Button } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SaveAndBackWithOutTempButtons } from 'components/base/BottomStepComponent';

import ContentItemSection from './ContentItemSection';

export default function ContentSection() {
  const { control, getValues, trigger } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: 'conditionGroups',
  });
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      {fields &&
        fields?.map((field, index) => {
          return (
            <>
              {index > 0 && (
                <div className="content-condition-area content-condition-or-area">
                  <div className="content-condition-solid"></div>
                  <label>Or</label>
                </div>
              )}
              <ContentItemSection index={index} />
            </>
          );
        })}
      <Button
        onClick={() => {
          append({ conditions: [] });
        }}
        className="btn-back-button-common add-sub-category-btn"
      >
        Add Condition Group
      </Button>

      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={async (event) => {
          const result = await trigger(undefined, { shouldFocus: true });
          if (result) {
            dispatch({
              type: 'segments/createOrUpdateSegment',
              payload: getValues(),
            });
          }
          console.log('@eric', result, getValues());
          // setOnCheck(true);
          // handleSave(event);
        }}
        backAction={() => history.goBack()}
      />
    </>
  );
}

import React from 'react';
import { Image } from 'react-bootstrap';
import Slide from '../../assets/images/slide-2-image.svg';
import Dot from '../../assets/images/under-line-dot.svg';
import LeftUnderLine from '../../assets/images/left-underline.svg';
import RightUnderLine1 from '../../assets/images/right-underline-1.svg';
import RightUnderLine2 from '../../assets/images/right-underline-2.svg';
import RightUnderLine2Short from '../../assets/images/right-underline-2-short.svg';
import 'swiper/swiper-bundle.css';
import './OnboardingSlideTwo.scss';

const TEXT_LEFT_BASE_STYLES = {
  1: [74, 52],
  2: [552, 750],
  3: [591, 770],
};

const slideTwoText = (title, description, eg, position, isSmall, leftFix) => {
  const left = isSmall
    ? TEXT_LEFT_BASE_STYLES[position][0] + leftFix
    : TEXT_LEFT_BASE_STYLES[position][1];
  return (
    <div
      className={`slide-two-text-common ${
        isSmall
          ? `slide-two-text-${position}-1000`
          : `slide-two-text-${position}-1280`
      }`}
      style={{ left }}
    >
      <label className="slide-two-text-section-title">{title}</label>
      <label className="slide-two-text-section-text">{description}</label>
      <label className="slide-two-text-section-text">{eg}</label>
    </div>
  );
};
const DOTS_LEFT_BASE_STYLES = {
  1: [272, 318],
  2: [444, 625],
  3: [428, 597],
};
const UNDERLINE_LEFT_BASE_STYLES = {
  1: [62, 35],
  2: [447, 628],
  3: [429, 600],
};

const dots = (isSmall, index, src, leftFix) => {
  const dotLeft = isSmall
    ? DOTS_LEFT_BASE_STYLES[index][0] + leftFix
    : DOTS_LEFT_BASE_STYLES[index][1];

  const underLineLeft = isSmall
    ? UNDERLINE_LEFT_BASE_STYLES[index][0] + leftFix
    : UNDERLINE_LEFT_BASE_STYLES[index][1];
  return (
    <>
      <Image
        src={Dot}
        className={`${
          isSmall
            ? `slide-two-dot-${index}-1000`
            : `slide-two-dot-${index}-1280`
        }`}
        style={{
          left: dotLeft,
        }}
      />

      <Image
        src={src}
        className={`${
          isSmall
            ? `slide-two-underline-${index}-1000`
            : `slide-two-underline-${index}-1280`
        }`}
        style={{ left: underLineLeft }}
      />
    </>
  );
};

function OnboardingSlideTwo({ width, height }) {
  const isSmall = width < 1200;
  let leftFix = 0;
  if (isSmall) {
    leftFix = (width - 880 - 114) / 2;
    console.log(leftFix);
  }
  return (
    <div className="onboarding-slide-two-container">
      <label className="onboarding-slide-two-title">
        What types of campaign do you need to create？
      </label>
      <Image
        src={Slide}
        className={`${
          isSmall
            ? 'slide-two-image-background-1000'
            : 'slide-two-image-background-1280'
        }`}
        style={{ left: isSmall ? 159 + leftFix : 170 }}
      />

      {dots(isSmall, 1, LeftUnderLine, leftFix)}
      {dots(isSmall, 2, RightUnderLine1, leftFix)}
      {dots(
        isSmall,
        3,
        isSmall ? RightUnderLine2Short : RightUnderLine2,
        leftFix,
      )}
      {slideTwoText(
        'Coupon campaign',
        'Coupon campaign Enables customers to acquire coupons by using points;',
        null,
        1,
        isSmall,
        leftFix,
      )}
      {slideTwoText(
        'Earning campaign',
        'Enables customers to earn or to know the instruction of earning reward by completing specific activities;',
        'e.g. Making purchase, scanning QR codes, referring a friends.',
        2,
        isSmall,
        leftFix,
      )}
      {slideTwoText(
        'General message campaign',
        'It’s mainly for communication purpose. It enables customers to know about the latest updates or other specific information. ',
        'e.g. Special announcement from the brand, lates updates on the office hour form the store.',
        3,
        isSmall,
        leftFix,
      )}
    </div>
  );
}

export default OnboardingSlideTwo;

import {
  ApprovalStatus,
  MessageChannel,
  PublishTagType,
} from 'config/CustomEnums';
import { CampaignType, SESSION_KEYS } from 'config/CustomEnums';
import { createModel } from 'models/BaseModel';
import { sessionDataKey } from 'models/CreateCampaignModel';
import { isEarningRuleRequireToLinkCampaign } from 'models/EarningRulesModel';
import { apiWithResponseHandle, loading } from 'models/LoadingUtil';
import {
  deleteCampaign,
  getCampaignCategoryList,
  getCampaignList,
  getCustomerDetailCampaignList,
  getStampCampaignList,
} from 'services/CampaignAPIHelper';

import {
  convertNumberToCursor,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from 'utils';
import {
  checkCampaignIsExpired,
  getCampaignListDisplayTime,
} from 'utils/TimeFormatUtil';
import { parseChannel } from './MessageListModel';

export const FIRST_TIME_ENTER_CAMPAIGN = 'FIRST_TIME_ENTER_CAMPAIGN';

const SHOW_RESUME_POP = 'SHOW_RESUME_POP';

const getCampaignMessageChannel = (channels) => {
  if (!channels || channels.length === 0) {
    return '-';
  }
  const newChannels = channels.map((channel) => {
    return parseChannel(channel)
  });
  const channelString = newChannels.toString();
  return channelString || '-';
};

const getFormatedStatus = (campaign) => {
  if (campaign.approvalStatus !== ApprovalStatus.published) {
    switch (campaign.approvalStatus) {
      case ApprovalStatus.draft:
        return PublishTagType.draft;
      case ApprovalStatus.pending:
        return PublishTagType.pendingForApproval;
      default:
        return PublishTagType.draft;
    }
  }

  const isExpired = checkCampaignIsExpired(campaign.endDate);
  if (!isExpired) {
    return PublishTagType.published;
  }
  return PublishTagType.expired;
};

const getInitialState = () => ({
  listFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Message Channel', fieldName: 'displayMessageChannels' },
    { displayName: 'Brand', fieldName: 'brand', orderField: 'brand' },
    {
      displayName: 'Target customers',
      fieldName: 'numberOfCustomersVisible',
      orderField: 'targetCustomers',
    },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
  campaignList: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
  categories: [],
  segments: [],
  avaliableCampaignNamesList: [],
  allCampaignList: [],
  resume: {
    showResumeButton: false,
    showResumePop: false,
  },
  customerDetailExclusiveListFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', linked: true },
    { displayName: 'Cover Photo', fieldName: 'coverPhoto' },
    { displayName: 'Brand', fieldName: 'brand' },
  ],
  customerDetailStampListFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', linked: true },
    { displayName: 'Cover Photo', fieldName: 'coverPhoto' },
    { displayName: 'Brand', fieldName: 'brand' },
    { displayName: 'Progress', fieldName: 'stampProgress' },
  ],
});

export const getTypeDisplay = (type) => {
  switch (type) {
    case CampaignType.couponsCampaign:
      return 'Coupon';
    case CampaignType.earningRulesCampaign:
      return 'Earning Rule';
    case CampaignType.stampCampaign:
      return 'Stamp';
    default:
      return 'General';
  }
};

const getCampaignDisplayActivePeriod = (node) => {
  if (
    node?.type === CampaignType.earningRulesCampaign &&
    !isEarningRuleRequireToLinkCampaign(
      node?.earningCampaignTypeEarningRule?.type,
    )
  ) {
    return 'Not applicable';
  }
  return getCampaignListDisplayTime(node.startDate, node.endDate);
};

const parseCampaignList = (list) => {
  return list.map((campaign) => {
    const node = campaign.node;
    const translationsEdges = node.translations?.edges;
    let translations = {};
    if (translationsEdges?.length > 0) {
      translationsEdges.forEach((item) => {
        const node = item.node;
        translations[node.language] = node;
      });
    }

    const totalStampsEarned = node.totalStampsEarned;
    const maxRequiredStamps = node.maxRequiredStamps;
    let stampProgress = 0;
    if (totalStampsEarned && maxRequiredStamps) {
      if (totalStampsEarned >= maxRequiredStamps) {
        stampProgress = 100;
      } else {
        stampProgress = Math.round(totalStampsEarned * 100 / maxRequiredStamps);
      }
    }

    return {
      id: node.id,
      pk: node.pk,
      name: node.name,
      displayEndDate: node.displayEndDate,
      displayStartDate: node.displayStartDate,
      endDate: node.endDate,
      isPublished: node.isPublished,
      startDate: node.startDate,
      status: node.status,
      type: node.type,
      typeDisplay: getTypeDisplay(node.type),
      brand: node.brand?.name,
      numberOfCustomersVisible: node.numberOfCustomersVisible,
      lastModifiedDate: node.lastModifiedDate,
      publicationDate: node.publicationDate,
      messageChannels: node.messageChannels,
      translations,
      displayMessageChannels: getCampaignMessageChannel(node.messageChannels),
      displayActivePeriod: getCampaignDisplayActivePeriod(node),
      order: node.displayPriority,
      coverPhoto: node.coverPhoto,
      displayVisiblePeriod: getCampaignListDisplayTime(
        node.displayStartDate,
        node.displayEndDate,
      ),
      displayStatus: getFormatedStatus(node),
      stampProgress,
    };
  });
};

export default createModel({
  namespace: 'campaignList',
  params: {
    parse: (data) => parseCampaignList(data.campaigns.edges),
    dataKey: SESSION_KEYS.CAMPAIGN_LIST_SESSION_KEY,
    deleteAPI: deleteCampaign,
    listAPI: getCampaignList,
    parseDetail: (data) => { },
    objectKey: 'campaigns',
  },
  states: getInitialState(),
  reducers: {},

  effects: {
    *campaignResume({ payload }, { select, put }) {
      const campaign = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!campaign?.campaignType) {
        yield put({
          type: 'updateState',
          payload: {
            resume: { showResumeButton: false, showResumePop: false },
          },
        });
        saveToSessionStorage(SHOW_RESUME_POP, null);
        return;
      }
      const showResumePop = getObjectFromSessionStorage(SHOW_RESUME_POP);
      const firstTimeEnterCampaign = getObjectFromSessionStorage(
        FIRST_TIME_ENTER_CAMPAIGN,
      );
      if (!firstTimeEnterCampaign) {
        saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN, true);
      }
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: true,
            showResumePop:
              showResumePop !== false && firstTimeEnterCampaign === false,
          },
        },
      });
    },

    *resumeAction({ payload }, { select, put }) {
      const hideResumePop = payload.hideResumePop;
      saveToSessionStorage(SHOW_RESUME_POP, !hideResumePop);
      const showResumeButton = yield select(
        (state) => state.campaignList.resume.showResumeButton,
      );
      yield put({
        type: 'updateState',
        payload: {
          resume: {
            showResumeButton: showResumeButton,
            showResumePop: false,
          },
        },
      });
    },

    getDeeplinkCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const type = payload.type || '';
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const ssoUid = payload.ssoUid || '';
        const serviceArgs = [
          getCampaignList,
          pageCursor,
          {
            type: type === CampaignType.allTypes ? '' : type,
            reverse: payload.reverse,
            filterName: payload.searchKey || '',
            isSimpleList: payload.isSimpleList,
            isPageList: payload.isPageList,
            others: {
              is_origin: true,
              status: ApprovalStatus.published,
            },
          },
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          console.log('campaigns', campaigns.edges);
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseCampaignList(campaigns.edges),
              totalCount: campaigns.totalCount,
              pageInfo: campaigns.pageInfo,
            },
          });
          yield put({
            type: 'featuredCampaignList/clearData',
            payload: {},
          });
        }
        if (payload.noLoading) {
          yield apiWithResponseHandle(serviceArgs, onSuccess);
        } else {
          yield loading(serviceArgs, onSuccess);
        }
      },
      { type: 'takeLatest' },
    ],

    getCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const reverse = payload.reverse;
        const type = payload.type || '';
        const searchKey = payload.searchKey;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const ssoUid = payload.ssoUid || '';
        const isStamp = payload.isStamp || false;

        let serviceArgs;
        if (isStamp) {
          serviceArgs = [
            getStampCampaignList,
            pageCursor,
            {
              reverse,
              filterName: searchKey || '',
              ssoUid,
              isAvailable: payload.isSelectorLoad,
              others: payload,
            },
          ];
        } else {
          serviceArgs = [
            getCampaignList,
            pageCursor,
            {
              type: type === CampaignType.allTypes ? '' : type,
              reverse,
              filterName: searchKey || '',
              ssoUid,
              isAvailable:
                payload.isAvailable === false ? false : payload.isSelectorLoad,
              isSimpleList: payload.isSimpleList,
              isPageList: payload.isPageList,
              others: payload,
            },
          ];
        }

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          console.log('campaigns', campaigns.edges);
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseCampaignList(campaigns.edges),
              totalCount: campaigns.totalCount,
              pageInfo: campaigns.pageInfo,
            },
          });
          yield put({
            type: 'featuredCampaignList/clearData',
            payload: {},
          });
          // if (payload.isSelectorLoad) {
          //   yield put({
          //     type: 'assembleAllCampaignList',
          //     payload: {
          //       list: campaigns.edges,
          //       page,
          //     },
          //   });
          // } else {
          //   yield put({
          //     type: 'assembleCampaignList',
          //     payload: {
          //       list: campaigns.edges,
          //       totalCount: campaigns.totalCount,
          //       pageInfo: campaigns.pageInfo,
          //     },
          //   });
          // }
        }
        if (payload.noLoading) {
          yield apiWithResponseHandle(serviceArgs, onSuccess);
        } else {
          yield loading(serviceArgs, onSuccess);
        }
      },
      { type: 'takeLatest' },
    ],

    getCustomerDetailCampaignList: [
      function* ({ payload }, { put }) {
        const page = payload.page || 1;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const { customerId, searchKey, categoryList, isStamp } = payload;

        const serviceArgs = [
          getCustomerDetailCampaignList,
          {
            afterCursor: pageCursor,
            customerId: customerId,
            filterName: searchKey,
            categoryList: categoryList,
            isStamp: isStamp,
          }
        ];

        function* onSuccess(data) {
          const campaigns = data.campaigns;
          console.log('@@getCustomerDetailCampaignList, campaigns', campaigns);
          yield put({
            type: 'updateListData',
            payload: {
              ...payload,
              data: parseCampaignList(campaigns.edges),
              totalCount: campaigns.totalCount,
              pageInfo: campaigns.pageInfo,
            },
          });
          yield put({
            type: 'featuredCampaignList/clearData',
            payload: {},
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCamapigncategories: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getCampaignCategoryList, payload];
        function* onSuccess(data) {
          yield put({
            type: 'assembleCategory',
            payload: { list: data.campaignCategories.edges },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        console.log('duplicate campaign', payload);
        yield put({
          type: 'createCampaign/duplicateCampaign',
          payload: {
            campaignId: payload.data.pk,
            afterAction: payload.afterAction,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put }) {
        yield put({ type: 'createCampaign/deleteCampaigns', payload });
      },
      { type: 'takeLatest' },
    ],
  },
});

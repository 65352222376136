import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CloseButton from '../../../assets/images/coupon/close.svg';

class BaseActionModal extends Component {
  render() {
    return (
      <Modal
        centered
        show={this.props.showModal}
        onHide={this.props.onHide}
        className={`generate-coupon-modal ${this.props.customClass || ''}`}
      >
        <Modal.Header>
          <button onClick={this.props.onHide} className="modal-close-btn">
            <img alt="close" src={CloseButton} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            className={`generate-coupon-title-section ${this.props.className}`}
          >
            <div className="prompt-title">{this.props.modalTitle}</div>
            <div className="second-section-content delete-single-coupon-info">
              <label className="delete-modal-content-label">
                {this.props.modalGeneralContent}
              </label>
              {this.props.modalDetailContent}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-back-button-common delete-campaign-pop-content-button delete-campaign-pop-content-cancel"
            onClick={this.props.onHide}
          >
            Cancel
          </Button>
          <Button
            className="btn-further unsave-create-prompt-continue button-save-padding"
            onClick={() => {
              this.props.onHide();
              this.props.action();
            }}
          >
            {this.props.actionName}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BaseActionModal;

import { createModel } from 'models/BaseModel';
import {
  getLevelDiscountList,
  getLevelDiscount,
  updateLevelDiscount,
} from 'services/LevelAPIHelper';
import { SESSION_KEYS } from 'config/CustomEnums';

const parseDetail = (data) => {
  const parsedData = {
    ...data,
    sourceName: data.level.levelName,
    name: data.level.levelName,
    displayDiscount: `${Number(data.discount) * 100}%`,
    discountNumber: Number(data.discount) * 100,
  };
  return parsedData;
};

export default createModel({
  namespace: 'levelDiscount',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      {
        displayName: 'Level',
        fieldName: 'name',
      },
      {
        displayName: 'Discount',
        fieldName: 'displayDiscount',
      },
    ],
    detail: {
      sourceName: '',
      discount: '',
    },
  },
  reducers: {},
  params: {
    sessionKey: SESSION_KEYS.LEVEL_DISCOUNT_SESSION_KEY,
    dataKey: SESSION_KEYS.LEVEL_DISCOUNT_DATA_SESSION_KEY,
    listAPI: getLevelDiscountList,
    parse: (data) => data?.levelDiscounts.edges.map((item) => parseDetail(item.node)),
    pkNode: 'LevelDiscountNode',
    detailAPI: getLevelDiscount,
    parseDetail: (data) => parseDetail(data.levelDiscount),
    objectKey: 'levelDiscount'
  },
  effects: {
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        console.log("@56", payload)
        const data = {
          id: payload.pk,
          discount: Number(payload.discountNumber) / 100.0,
        };
        const serviceArgs = [updateLevelDiscount, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: false },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});

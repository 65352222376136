import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AuthButton from 'components/base/AuthButton';
import { SaveAndBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomButtonWithTips from 'components/base/CustomButtonWithTips';
import CustomListComponent from 'components/base/CustomListComponent';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import Loading from 'components/base/Loading';
import ContentSection from 'components/stampCampaign/stampCampaignCreation/ContentSection';
import ProfileSection from 'components/stampCampaign/stampCampaignCreation/ProfileSection';
import {
  APIStatus,
  AppCampaignDetailUrl,
  CampaignDetailUrl,
  LanguageConfig,
  SESSION_KEYS,
} from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { ToastType } from 'models/NavBarModel';

import { createAction, removeFromSessionStorage } from 'utils';

function StampCampaignDetail({ createStatus, campaign }) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    console.log('@32');
    removeFromSessionStorage(SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY);
    dispatch(
      createAction('createCampaign/getOneCampaign')({ id, isDetail: true }),
    );
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => dispatch({ type: 'createCampaign/clearData' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copiedDeeplinkAction = () => {
    dispatch({
      type: 'navBars/updateState',
      payload: {
        saveDiscardToastShowing: {
          value: true,
          type: ToastType.copySuccess,
        },
      },
    });
  };

  const copyDeeplinkToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };

  if (createStatus === APIStatus.calling) {
    return <Loading customClass={'common-full-height '} />;
  }

  const sections = [<ProfileSection data={campaign} />];

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSection data={campaign} />
          <ContentSections sections={sections} hidePreview={true} />
          <SaveAndBackButtons
            backAction={() => history.push('/stamp_campaigns/')}
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={1000}
      hideAllButtonWidth={400}
      extraButtons={[
        <CustomButtonWithTips
          tipsContent={(
            'When customer click on the link, will open ' +
            'the page in website and show a banner for customer ' +
            'to open the same content in native app, ' +
            'redirect to app store if the native app has not been installed.'
          )}
          className="btn-back-button-common"
          title={'Copy URL for web'}
          action={() => {
            const url = `${CampaignDetailUrl}${id}`;
            copyDeeplinkToClipboard(url);
            copiedDeeplinkAction();
          }}
        />,
        <CustomButtonWithTips
          tipsContent={(
            'When customer click on the link, ' +
            'will redirect to native app if the app has been installed; ' +
            'if not installed, will show a page to app store.'
          )}
          className="btn-back-button-common"
          title={'Copy deep link for app'}
          action={() => {
            const url = `${AppCampaignDetailUrl}${id}`;
            copyDeeplinkToClipboard(url);
            copiedDeeplinkAction();
          }}
        />,
      ]}
      primaryButton={
        <AuthButton
          title="Edit"
          action={() => {
            dispatch({ type: 'admin/clearData' });
            history.push({
              pathname: 'edit/',
            });
          }}
          requires={PermissionCodes.changeCampaign}
        />
      }
    />,
  ];

  return (
    <CustomListComponent
      caution={{
        detail: '',
        title: campaign.translations?.[LanguageConfig.english]?.generalName,
      }}
      hideTab
      breadcrumb={
        <CustomBreadcrumb
          name={campaign.translations?.[LanguageConfig.english]?.generalName}
        />
      }
      buttons={buttons}
      tabs={tabs}
    />
  );
}

const mapPropsToState = (state) => ({
  createStatus: state.createCampaign.createStatus,
  campaign: state.createCampaign.campaign || {},
});

export default connect(mapPropsToState)(StampCampaignDetail);

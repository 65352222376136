import React from 'react';
import { Link } from 'react-router-dom';
function CustomLinkableName(props) {
  const to = props.to;
  const text = props.text;
  const openNewTab = props.openNewTab;

  return (
    <div
      className={`all-list-scrollable-area-table-item-common all-list-scrollable-area-name`}
    >
      <Link
        to={to}
        className="all-list-scrollable-area-name-text all-list-scrollable-area-text-common"
        target={openNewTab ? "_blank" : ""}
        rel={openNewTab ? "noopener noreferrer" : ""}
      >
        {text || '-'}
      </Link>
    </div>
  );
}
export default CustomLinkableName;

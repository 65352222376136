import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'I18n';

import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';

function CampaignDeeplinkDropdown({
  alwaysHint,
  customStyles,
  title,
  defaultValue,
  isPrompt,
  setValue = () => {},
}) {
  const dispatch = useDispatch();

  const campaignList = useSelector((state) => state.campaignList.allList);
  const onSelectChange = (value, forApp) => {
    setValue(value?.value, forApp);
  };
  useEffect(() => {
    dispatch({
      type: 'campaignList/getDeeplinkCampaignList',
      payload: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomTitleWithDropDown
        alwaysHint={i18n.t('message_insert_campaign_web')}
        customStyles={customStyles}
        title={title}
        titleStyle={'create-section-label-no-top-space'}
        setValue={(v) => onSelectChange(v, false)}
        source={campaignList}
        labelContainPk
        defaultValue={defaultValue}
        loadMoreAction={'campaignList/getDeeplinkCampaignList'}
        filterAction={'campaignList/getDeeplinkCampaignList'}
        allType
      />
      <CustomTitleWithDropDown
        alwaysHint={i18n.t('message_insert_campaign_app')}
        customStyles={customStyles}
        title={title}
        titleStyle={'create-section-label-no-top-space'}
        setValue={(v) => onSelectChange(v, true)}
        source={campaignList}
        labelContainPk
        defaultValue={defaultValue}
        loadMoreAction={'campaignList/getDeeplinkCampaignList'}
        filterAction={'campaignList/getDeeplinkCampaignList'}
        allType
      />
    </>
  );
}

export default CampaignDeeplinkDropdown;

import React, { useState } from 'react';

import { DatePicker } from 'antd';

function CustomRangePicker({
  defaultValue = [],
  onChange,
  dateFormat,
  disabledDate = false,
  minDate,
  maxDate,
  className = 'date-picker',
  separator = '-',
  locale,
  onOpenChanged = () => {},
}) {
  const [dates, setDates] = useState(null);

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
    onOpenChanged(open);
  };

  return (
    <DatePicker.RangePicker
      locale={locale}
      value={dates || defaultValue}
      className={className}
      format={dateFormat}
      showTime={{ format: 'HH:mm' }}
      separator={separator}
      onChange={onChange}
      onCalendarChange={(value) => setDates(value)}
      onOpenChange={onOpenChange}
      disabledDate={(current) => {
        if (!dates) {
          return false;
        }
        const [start, end] = dates;
        // the end date cannot be the same as the start date
        const equalStart = start && current.diff(start, 'days') === 0;
        const equalEnd = end && current.diff(end, 'days') === 0;
        if (disabledDate) {
          current.set({
            second: 0,
            millisecond: 0,
          });
          const tooEarly = minDate && current < minDate;
          const tooLate = maxDate && current > maxDate;
          return !!tooEarly || !!tooLate || !!equalStart || !!equalEnd;
        }
        return !!equalStart || !!equalEnd;
      }}
    />
  );
}

export default CustomRangePicker;

import React from 'react';
import AnimatedNumber from 'animated-number-react';
import { useSelector } from 'react-redux';
import './CouponsCard.scss';
import { enLocaleNumberFormatter } from '../../utils';
import {
  COUPON_PIE_CHART_CONFIG,
  ANIMATION_DURATION,
} from '../../config/DashboardConfig';
import { Link } from 'react-router-dom';
import icon from '../../assets/images/customer_overview_vector.svg';
import CouponsPieChart from './CouponsPieChart';

const CouponsCard = () => {
  const couponsData = useSelector((state) => state.dashboard.coupons);
  const data = couponsData?.chartData || [];
  return (
    <div className="dashbard-coupon-card">
      <div className="dashboard-card-title">coupons</div>
      <div className="dashboard-coupon-chart-container">
        <CouponsPieChart data={data} />
        <div className="dashboard-coupon-chart-info-container">
          <div className="dashboard-coupon-chart-info-text-title">
            Total Activities
          </div>
          <AnimatedNumber
            className="dashboard-coupon-chart-info-text-value"
            duration={ANIMATION_DURATION}
            value={couponsData?.totalActivities?.value}
            formatValue={(value) => enLocaleNumberFormatter(value.toFixed(0))}
          />
        </div>
        <div className="dashboard-coupon-info-container">
          {COUPON_PIE_CHART_CONFIG.map((item, index) => (
            <div className="dashboard-coupon-info-row">
              <div
                className="dashboard-coupon-info-color"
                style={{ backgroundColor: item.color }}
              />
              <label className="dashboard-coupon-info-title">{item.name}</label>
              <AnimatedNumber
                className="dahsboard-coupon-info-value"
                duration={ANIMATION_DURATION}
                value={couponsData ? couponsData[item.key]?.value : 0}
                formatValue={(value) =>
                  enLocaleNumberFormatter(value.toFixed(0))
                }
              />
            </div>
          ))}
        </div>
      </div>
      <Link
        className="dashboard-overview-link coupons-overview-link"
        to={{ pathname: '/coupons' }}
      >
        Coupon Activities Overview
        <img className="dashboard-overview-link-icon" src={icon} alt="" />
      </Link>
    </div>
  );
};

export default CouponsCard;

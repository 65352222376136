import { CampaignType } from '../../config/CustomEnums';
import CouponWhite from '../../assets/images/slide-coupon-white.svg';
import CouponOn from '../../assets/images/slide-coupon-on.svg';

import EarningWhite from '../../assets/images/slide-earning-white.png';
import EarningOn from '../../assets/images/slide-earning-on.png';

import GeneralWhite from '../../assets/images/slide-general-white.svg';
import GeneralOn from '../../assets/images/slide-general-on.svg';

export const ButtonConfig = {
  coupon: {
    imageOn: CouponOn,
    imageOff: CouponWhite,
    textColorOn: 'black',
    textColorOff: 'white',
    backgroundColorOn: 'white',
    backgroundColorOff: '#4E8DD7',
    des: 'Start by create coupon set',
    title: 'Coupon campaign',
    type: CampaignType.couponsCampaign,
    location: {
      pathname: '/coupons/create',
    },
  },

  earning: {
    imageOn: EarningOn,
    imageOff: EarningWhite,
    textColorOn: 'black',
    textColorOff: 'white',
    backgroundColorOn: 'white',
    backgroundColorOff: '#4E8DD7',
    des: 'Start by create earning rule',
    title: 'Earning campaign',
    type: CampaignType.earningRulesCampaign,
    location: {
      pathname: '/earns/create',
    },
  },
  general: {
    imageOn: GeneralOn,
    imageOff: GeneralWhite,
    textColorOn: 'black',
    textColorOff: 'white',
    backgroundColorOn: 'white',
    backgroundColorOff: '#4E8DD7',
    des: 'Create Directly',
    title: 'General message campaign',
    type: CampaignType.generalMessageCampaign,
    location: {
      pathname: '/campaigns/create',
      search: `type=${CampaignType.generalMessageCampaign}`,
    },
  },
};

import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import debounce from 'lodash.debounce';

import './BottomStepComponent.scss';

function Base(props) {
  const { isSuperuser, userPermissions } = useSelector((state) => ({
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const hasPermission = (requires) => {
    if (isSuperuser) return true;
    return userPermissions.includes(requires) || !requires;
  };

  const backButton = props.backButton || null;
  const continueButton = props.continueButton || null;
  const saveTempButton = props.saveTempButton || null;
  const saveButton = props.saveButton || null;
  const hasMoreThanTwoButtons = props.hasMoreThanTwoButtons || false;
  const multipleButtons = props.multipleButtons || [];
  return (
    <div className="base-save-area">
      {hasPermission(props.backRequires) ? backButton : null}
      {continueButton}
      {hasMoreThanTwoButtons ? (
        <div className="base-temp-save-area">
          {multipleButtons.map((item, index) => {
            // console.log('@@33: ', item.text);
            return item; //TODO: add permission
          })}
        </div>
      ) : (
        <div className="base-temp-save-area">
          {hasPermission(props.tempRequires) ? saveTempButton : null}
          {hasPermission(props.saveRequires) ? saveButton : null}
        </div>
      )}
    </div>
  );
}

function ContinueButton(props) {
  const continueAction = props.action || (() => {});
  return (
    <Button
      disabled={props.disabledContinue}
      onClick={() => {
        continueAction();
      }}
      className="base-continue-button base-save-and-back-component-button btn-further"
    >
      {props.text || 'Continue'}
    </Button>
  );
}

function BackButton(props) {
  const backAction = props.action || (() => {});
  return (
    <Button
      onClick={() => {
        backAction();
      }}
      className="base-back-button base-save-and-back-component-button btn-back-button-common"
    >
      Back
    </Button>
  );
}

export function SaveTempButton(props) {
  const saveTempAction = props.action || (() => {});
  return (
    <Button
      onClick={() => {
        saveTempAction();
      }}
      className="base-save-button-temp-button base-save-and-back-component-button btn-back-button-common"
    >
      {props.text || 'Save as a draft'}
    </Button>
  );
}

export function SaveButton(props) {
  const saveAction = props.action || (() => {});
  const debounceSave = useCallback(
    debounce((event) => saveAction(event), 1000),
    [saveAction],
  );
  const handleSave = (event) => {
    debounceSave(event);
  };
  return (
    <Button
      onClick={(event) => {
        handleSave(event);
      }}
      className="base-save-button base-save-and-back-component-button btn-further"
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
}

function DeleteButton(props) {
  const deleteAction = props.action || (() => {});
  return (
    <button
      onClick={() => {
        deleteAction();
      }}
      className="base-delete-button base-back-button"
    >
      Delete
    </button>
  );
}

function ActionButtonWithoutBorder(props) {
  const action = props.action || (() => {});
  return (
    <button
      className="base-delete-button base-back-button"
      onClick={() => {
        action();
      }}
    >
      {props.text}
    </button>
  );
}

export function OnlyContinueButton(props) {
  return (
    <Base
      continueButton={
        <ContinueButton
          text={props.text}
          disabledContinue={props.disabledContinue}
          action={props.continueAction}
        />
      }
    />
  );
}

export function OnlyBackButton(props) {
  return <Base backButton={<BackButton action={props.backAction} />} />;
}

export function ContinueWithBackButtons(props) {
  return (
    <Base
      continueButton={<ContinueButton action={props.continueAction} />}
      backButton={<BackButton action={props.backAction} />}
    />
  );
}

export function ContinueWithBackAndTempSaveButtons(props) {
  return (
    <Base
      backButton={<BackButton action={props.backAction} />}
      hasMoreThanTwoButtons={true}
      multipleButtons={[
        <SaveTempButton
          text={props.saveTempText}
          action={props.saveTempAction}
        />,
        <ContinueButton action={props.continueAction} />,
      ]}
    />
  );
}

export function BackAndTempSaveButtons(props) {
  return (
    <Base
      backButton={
        <div className="base-back-button base-save-and-back-component-button"></div>
      }
      hasMoreThanTwoButtons={true}
      multipleButtons={[
        <SaveTempButton
          text={props.saveTempText}
          action={props.saveTempAction}
        />,
        <ContinueButton action={props.continueAction} />,
      ]}
    />
  );
}

export function SaveAndBackButtons(props) {
  if (props.hideSave) {
    return (
      <Base
        {...props}
        backButton={
          props.backAction ? <BackButton action={props.backAction} /> : null
        }
        saveButton={
          <SaveButton
            action={props.saveTempAction}
            text={props.saveTempText}
            {...props.save}
          />
        }
      />
    );
  }
  return (
    <Base
      {...props}
      backButton={
        props.backAction ? <BackButton action={props.backAction} /> : null
      }
      saveButton={
        props.saveText ? (
          <SaveButton
            action={props.saveAction}
            text={props.saveText}
            {...props.save}
          />
        ) : null
      }
      saveTempButton={
        props.saveTempText ? (
          <SaveTempButton
            action={props.saveTempAction}
            text={props.saveTempText}
            {...props.saveTemp}
          />
        ) : null
      }
    />
  );
}

export function SaveAndBackWithOutTempButtons(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      saveButton={
        <SaveButton action={props.saveAction} text={props.saveText} />
      }
    />
  );
}

export function DeleteAndMultipleButtons(props) {
  console.log('@@260',props.multipleButtons);

  return (
    <Base
      {...props}
      backButton={<DeleteButton action={props.deleteAction} />}
      hasMoreThanTwoButtons={true}
      multipleButtons={props.multipleButtons.map((item, index) => {
        if (index < props.multipleButtons.length - 1) {
          return <SaveTempButton text={item.text} action={item.action} />;
        }
        return <SaveButton text={item.text} action={item.action} />;
      })}
    />
  );
}

export function OnlySaveButton(props) {
  return (
    <Base
      saveButton={
        <SaveButton action={props.saveAction} text={props.saveText} />
      }
    />
  );
}

export function SaveAndBackAndWithoutBorderButtons(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      saveButton={
        <SaveButton action={props.saveAction} text={props.saveText} />
      }
      saveTempButton={
        <ActionButtonWithoutBorder
          action={props.saveTempAction}
          text={props.saveTempText}
        />
      }
    />
  );
}

export function BackAndWithoutBorderButton(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      saveButton={
        <ActionButtonWithoutBorder
          action={props.action}
          text={props.actionText}
        />
      }
    />
  );
}

export function BackAndMultipleButtons(props) {
  return (
    <Base
      {...props}
      backButton={<BackButton action={props.backAction} />}
      hasMoreThanTwoButtons={true}
      multipleButtons={props.multipleButtons.map((item) => item)}
    />
  );
}

import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from 'components/base/BaseFilter';
import { ArrayStringData } from 'utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchStatus = parsedSearch['status'] || '';
  const searchExpiryDate = parsedSearch['points_expiry_date'] || '';
  const searchCreationPeriod = parsedSearch['create_date'] || '';

  const [type, setType] = useState(searchType);
  const [status, setStatus] = useState(
    ArrayStringData(searchStatus),
  );
  const [expiryDate, setExpiryDate] = useState(searchExpiryDate);
  const [creationPeriod, setCreationPeriod] = useState(searchCreationPeriod);

  const content = [
    {
      title: 'Type',
      data: [
        { name: 'Virtual', pk: 'VIRTUAL' },
        { name: 'Physical', pk: 'PHYSICAL' },
      ],
      value: type,
      setValue: setType,
      component: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Status',
      data: [
        { name: 'Generated', pk: 'GENERATED' },
        { name: 'Customer online purchased', pk: 'PURCHASED' },
        { name: 'Customer used', pk: 'USED' },
        { name: 'Admin edited', pk: 'ADMIN_EDITED' },
        { name: 'Refunded', pk: 'REFUNDED' },
      ],
      value: status,
      setValue: setStatus,
      component: FilterTypeEnum.choice,
    },
    {
      title: 'Create date',
      value: creationPeriod,
      setValue: setCreationPeriod,
      component: FilterTypeEnum.dateRange,
    },
    {
      title: 'Points expiry date',
      value: expiryDate,
      setValue: setExpiryDate,
      component: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

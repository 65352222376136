import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPurchasedItemCategories = () => {
  const query = `
    {
        purchasedItemCategories(
          first: 20,
          after: "",
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              category
              subcategories
            }
          }
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction } from 'utils';

import Search from 'assets/images/drop_down_filter.svg';
import CloseIcon from 'assets/images/prompt_close_icon.svg';

import './CampaignFilterableSelectModal.scss';

const mapPropsToState = (state) => ({
  campaignList: state.campaignList.allList || [],
});

const CampaignFilterableSelectModal = ({
  show = true,
  onSelect = () => {},
  onClose = () => {},
  campaignList,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  useEffect(() => {
    dispatch(createAction('campaignList/clearState')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        isSelectorLoad: true,
        isExpired: false,
        isPublished: true,
        searchNameIdKey: value,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body
        className={`base-prompt-container customer-detail-prompt campaign-select`}
      >
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className="customer-detail-modal-title">Select campaign</label>

        <div className="filterable-search-bar">
          <Image src={Search} className="filterable-search-bar-icon" />
          <input
            autoFocus
            className="filterable-search-bar-input"
            placeholder={'Search by campaign ID and name'}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
          />
        </div>

        <div className="campaign-list-area">
          {campaignList?.map((data, index) => (
            <div
              key={`modal-select-campaign-${data.pk}`}
              className={'filterable-item'}
              onClick={(e) => onSelect(data)}
            >
              {`[ID: ${data.pk}] ${data.name}`}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapPropsToState)(CampaignFilterableSelectModal);

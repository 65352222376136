import {
  createPointAddTypeRecord,
  createPointRemoveTypeRecord,
} from '../services/TransactionRecordsAPIHelper';
import { SESSION_KEYS } from '../config/CustomEnums';
import { POINT_RECORD_TYPE } from '../components/pointRecord/CreatePointRecordStepOne';
import { loading } from './LoadingUtil';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';

export const sessionDataKey = {
  stepEndKey: SESSION_KEYS.CREATE_POINT_RECORD_STEP_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_POINT_RECORD_ORIGINAL_DATA_SESSION_KEY,
};

export const CreatePointRecordError = {
  selectCustomer: {
    message: 'Please provide a customer.',
  },
  operatedPoints: {
    message: 'Please provide a value.',
  },
};

function pointRecordInit() {
  return {
    pointType: null,
    customer: {},
    points: null,
    remarks: null,
  };
}

function getInitState() {
  const stepNameList = ['Type', 'Properties'];
  return {
    pointRecord: pointRecordInit(),
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    pointRecordCreated: false,
    hasUpdatedDefaultValues: false,
  };
}

function parsePointRecordInputBody(pointRecord) {
  return {
    customer: pointRecord.customer.pk,
    points: pointRecord.points,
    tpe: !!pointRecord.tpe ? pointRecord.tpe : null,
    remarks: pointRecord.remarks,
  };
}

export default {
  namespace: 'createPointRecord',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    loadRecordFromCookie(state, { payload }) {
      const record = getObjectFromSessionStorage(sessionDataKey.origionalData);
      if (!record) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, record);
      return {
        ...state,
        pointRecord: record,
        hasUpdatedDefaultValues: true,
      };
    },
    saveOrRemoveRecordFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.origionalData, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.origionalData);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        currentStep: step,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },

  effects: {
    createPointRecord: [
      function* ({ payload }, { select, put }) {
        const { values } = payload;
        const inputBody = parsePointRecordInputBody(values);
        let serviceArgs = values.pointType === POINT_RECORD_TYPE.TYPE_ADD
          ? [createPointAddTypeRecord, inputBody]
          : [createPointRemoveTypeRecord, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.origionalData);
          yield put({
            type: 'updateState',
            payload: {
              pointRecordCreated: true,
              formHasSubmitted: true,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@208: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};

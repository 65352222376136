import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TitleWithButton } from './utils';
import CustomerEdit from '../../assets/images/customer_edit.svg';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import './MembershipSection.scss';
import EditMembershipModal from './EditMembershipModal';
import { PermissionCodes } from 'config/PermissionCodes';


const MembershipSection = ({
  customer,
}) => {
  const [showEditMembership, setShowEditMembership] = useState(false);

  const fields = [
    [
      { title: "Sign up from", field: 'displaySignUpChannel' },
    ],
    [
      { title: "Acquisition channel", field: 'acquisitionChannel' },
    ],
    [
      {
        title: "Referral source",
        customRender: <>
          <label className="customer-detail-membership-section-label">
            {customer.referralSource?.referrer || '-'}
          </label>
          {
            customer.referralSource?.referredCampaigns?.length > 0
              ? <ul className='customer-detail-membership-section-ul'>
                {
                  customer.referralSource?.referredCampaigns.map(campaign => (
                    <li>{campaign}</li>
                  ))
                }
              </ul>
              : null
          }
        </>
      },
    ],
    [
      { title: "Merchant admin for quick sign up", field: 'signUpByMerchant' },
    ],
  ];


  return (
    <>
      <TitleWithButton
        containerExtraClassName="customer-detail-section-title-container"
        title="MEMBERSHIP"
        titleExtraClassName="customer-detail-section-title"
        firstButtonImage={CustomerEdit}
        firstButtonContent="Edit"
        firstButtonOnClick={() => setShowEditMembership(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
      />

      <MembershipInfoCard
        title=""
        data={customer}
        fields={fields}
      />

      <EditMembershipModal
        show={showEditMembership}
        setShow={setShowEditMembership}
      />

    </>
  )
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,

});

export default connect(mapPropsToState)(MembershipSection);
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AuthButton from '../../components/base/AuthButton';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../components/base/CustomListComponent';
import DeletePrompt from '../../components/base/DeletePrompt';
import { DELETE_RELATED_SECTIONS } from '../../config/CustomEnums';
import { NavBarNames } from '../../config/NavBarNameList';
import { PermissionCodes } from '../../config/PermissionCodes';
import BaseListContainer from '../base/BaseListContainer';
import BaseTabListContainer from '../base/BaseTabListContainer';
import queryString from 'querystring';

function WebviewList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [sortDisabled, setSortDisabled] = useState(false);
  const [deleteItems, setDeletedItems] = useState([]);

  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page;
  const searchKey = searchDict?.search;
  const hasSearchKey = searchKey && searchKey.length > 0;
  const { listFields, dataList, pageInfo, totalPage, totalCount, checkedList } =
    useSelector((state) => ({
      listFields: state.webview.listDisplayFields,
      dataList: state.webview.pagedList,
      pageInfo: state.webview.pageInfo,
      totalPage: state.webview.totalPage,
      totalCount: state.webview.totalCount,
      checkedList: state.webview.checkedList,
    }));

  const fetchData = () => {
    setSortDisabled(hasSearchKey);
    dispatch({
      type: 'webview/getList',
      payload: {
        page: currentPage,
        searchKey,
        ...searchDict,
      },
    });
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
        setDeletedItems(checkedList);
      },
      requires: PermissionCodes.deleteWebview,
    },
  ];

  const tabs = [
    {
      name: 'List of Webviews',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: false,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'webview'}
              permissionGroup={PermissionCodes.webview}
              deleteInfo={{
                data: [],
                title: 'webview',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.WEBVIEW,
              }}
              sortDisabled={sortDisabled}
              rankDisabled
              actions={['Edit', 'Delete']}
              hasOtherActions={true}
              customActions={{
                copyUrl: (item, language) => {
                  const input = document.createElement('input');
                  input.style.opacity = 0;
                  input.style.position = 'fixed';
                  input.style.left = '-100%';
                  input.style.top = '-100%';
                  input.value = `${item.url}?lang=${language}`;
                  document.body.appendChild(input);
                  input.select();
                  // eslint-disable-next-line no-unused-vars
                  const success = document.execCommand('copy');
                  document.body.removeChild(input);
                },
              }}
            />
          }
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Create Webview"
      className="btn-further"
      action={() => {
        history.push({ pathname: `${location.pathname}/create/` });
      }}
      requires={PermissionCodes.addWebview}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'All webview contents are listed here.',
          title: NavBarNames.webview,
        }}
        defaultActiveKey={tabs[0].name}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <DeletePrompt
        show={showDeletePrompt}
        title={'Webviews Content'}
        data={deleteItems}
        relatedSections={[]}
        onClose={() => {
          setShowDeletePrompt(false);
          setDeletedItems([]);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'webview/updateState',
            payload: { checkedList: deleteItems },
          });
          dispatch({
            type: 'webview/delete',
            payload: { afterAction: fetchData },
          });
          setDeletedItems([]);
        }}
      />
    </>
  );
}

export default WebviewList;

import React from 'react';
import './SortableTable.scss';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

export const SortableRow = SortableElement(({ children }) => children);

export const SortableTableBodyContainer = SortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

export const SortableTableBody = ({
  dataList,
  renderRowData,
  onSortEnd,
  disabled,
}) => {
  const handleSortStart = ({ node, helper }) => {
    document.body.style.cursor = 'move';
    const color = window
      .getComputedStyle(node, null)
      .getPropertyValue('background-color');
    helper.style.backgroundColor = color;
    const tds = helper.childNodes;
    node.childNodes.forEach(
      (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`),
    );
    node.classList.add('drag-item-ghost');
  };
  return (
    <SortableTableBodyContainer
      shouldCancelStart={(e) => {
        if (disabled) {
          return true;
        }
        console.log(e.target.tagName);
        if (e.target.tagName === 'A' || e.target.tagName === 'INPUT') {
          return true;
        }
        if (e.target.className.indexOf('sort-disabled') !== -1) {
          return true;
        }
      }}
      hideSortableGhost={false}
      helperClass="sortable-helper"
      onSortStart={handleSortStart}
      onSortEnd={({ oldIndex, newIndex, nodes }) => {
        nodes.forEach((node, idx) => {
          node.node.classList.remove('drag-item-ghost');
        });
        document.body.style.cursor = '';
        onSortEnd && onSortEnd(oldIndex, newIndex);
      }}
    >
      {dataList.map((item, index) => (
        <SortableRow index={index}>{renderRowData(item)}</SortableRow>
      ))}
    </SortableTableBodyContainer>
  );
};

import React from 'react';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import arrayMove from 'array-move';
import { createAction } from '../../../utils';
import { useLocation, useHistory } from 'react-router-dom';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import {
  DELETE_RELATED_SECTIONS,
  StatusTag,
} from '../../../config/CustomEnums';
import { CAMPAIGN_CATEGORY_ORDER_LAST } from '../../../models/CreateCampaignCategoryModel';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import Filter from './Filter';

export default function CampaignCategory() {
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [sortDisabled, setSortDisabled] = React.useState(false);
  const [deleteItems, setDeleteItems] = React.useState([]);
  const deletePks = deleteItems.map((item) => item.pk);
  const dispatch = useDispatch();
  const {
    apiDataList,
    totalCount,
    totalPage,
    pageInfo,
    listDisplayFields,
    checkedList,
  } = useSelector((state) => {
    const { campaignCategoryList } = state;
    return {
      apiDataList: campaignCategoryList.categoryList,
      totalCount: campaignCategoryList.totalCount,
      totalPage: campaignCategoryList.totalPage,
      pageInfo: campaignCategoryList.pageInfo,
      listDisplayFields: campaignCategoryList.listDisplayFields,
      checkedList: campaignCategoryList.checkedList,
    };
  });
  const location = useLocation();
  const history = useHistory();
  console.log('dataList:', dataList, listDisplayFields);
  const searchQuery = queryString.parse(location.search);
  let currentPage = parseInt(searchQuery?.page);
  if (isNaN(currentPage)) {
    currentPage = 1;
  }

  const searchKey = searchQuery?.search;
  const hasSearchKey = searchKey && searchKey.length > 0;
  const fetchData = () => {
    setSortDisabled(hasSearchKey);
    dispatch(
      createAction('campaignCategoryList/getCampaignCategoryList')({
        ...searchQuery,
        page: currentPage,
        searchKey,
      }),
    );
  };
  React.useEffect(() => {
    dispatch({ type: 'getCampaignCategoryList/clearData' });
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  React.useEffect(() => {
    setDataList(apiDataList);
  }, [apiDataList]);
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCampaignCategory,
    },
  ];
  const tabs = [
    {
      name: 'List Of Campaign Categories',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={dataList}
              totalPage={totalPage}
              currentPage={currentPage}
              customClassName="campaign-category-list"
              model={'campaignCategoryList'}
              permissionGroup={PermissionCodes.campaigncategory}
              deleteInfo={{
                data: [],
                title: 'campaign category',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY,
              }}
              sortable
              sortDisabled={sortDisabled}
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const startOrder = dataList[0].order;
                const newDataList = arrayMove(dataList, oldIndex, newIndex);

                const movedItem = dataList[oldIndex];
                dispatch(
                  createAction('createCampaignCategory/updateCategoryOrder')({
                    id: movedItem.pk,
                    order: startOrder + newIndex,
                  }),
                );
                setDataList(
                  newDataList.map((data, index) => ({
                    ...data,
                    order: startOrder + index,
                  })),
                );
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                dispatch(
                  createAction('createCampaignCategory/updateCategoryOrder')({
                    id: pk,
                    order: newOrder,
                    afterAction: fetchData,
                  }),
                );
              }}
              hasOtherActions
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Detail',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewCampaignCategory,
                  },
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeCampaignCategory,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.deleteCampaignCategory,
                  },
                  {
                    name: 'Duplicate',
                    action: () => {
                      dispatch(
                        createAction(
                          'createCampaignCategory/duplicateCategory',
                        )({
                          pk: item.pk,
                          afterAction: fetchData,
                        }),
                      );
                    },
                    requires: PermissionCodes.addCampaignCategory,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    onToggle={(open) => {
                      if (!hasSearchKey) {
                        setSortDisabled(open);
                      }
                    }}
                    otherActions={[
                      {
                        name:
                          item.status === StatusTag.active
                            ? 'Deactivate'
                            : 'Activate',
                        action: () => {
                          dispatch(
                            createAction(
                              'createCampaignCategory/updateCategoryActiveStatus',
                            )({
                              id: item.pk,
                              isForcedInactive:
                                item.status === StatusTag.active,
                              afterAction: fetchData,
                            }),
                          );
                        },
                        requires: PermissionCodes.changeCampaignCategory,
                      },
                      {
                        name: 'Move to first',
                        action: () => {
                          dispatch(
                            createAction(
                              'createCampaignCategory/updateCategoryOrder',
                            )({
                              id: item.pk,
                              order: 1,
                              afterAction: fetchData,
                            }),
                          );
                        },
                        requires: PermissionCodes.changeCampaignCategory,
                      },
                      {
                        name: 'Move to last',
                        action: () => {
                          dispatch(
                            createAction(
                              'createCampaignCategory/updateCategoryOrder',
                            )({
                              id: item.pk,
                              order: CAMPAIGN_CATEGORY_ORDER_LAST,
                              afterAction: fetchData,
                            }),
                          );
                        },
                        requires: PermissionCodes.changeCampaignCategory,
                      },
                    ]}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title="Create Campaign Category"
      action={() => {
        history.push('campaign_category/create');
      }}
      requires={PermissionCodes.addCampaignCategory}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'You can create categories of campaigns，and the campaigns will be displayed as categories',
          title: NavBarNames.campaignCategory,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Campaign Category"
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch(
            createAction('createCampaignCategory/deleteCategories')({
              ids: deletePks,
              afterAction: fetchData,
            }),
          );
        }}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Transfer, Tree } from 'antd';

import AuthButton from 'components/base/AuthButton';
import ErrorFieldMessage from 'components/base/ErrorFieldMessage';
import FollowCouponSetSwitch from 'components/campaign/campaignCreation/FollowCouponSetSwitch';

import SearchIcon from 'assets/images/drop_down_filter.svg';
import nodata from 'assets/images/no_data_transfer.svg';
import loading from 'assets/images/selector_loading.gif';

import './BaseMultipleSelectorV2.scss';

export const SELECTOR_SIZE = {
  HIGHER: 'higher',
  WIDER: 'wider',
  SHORTER: 'shorter',
  NARROWER: 'narrower',
};

const getUnRepeatData = (data = []) => {
  // console.log('getUnRepeatData1:', data);
  const unRepeatData = [];
  data.forEach((item) => {
    if (!item?.pk) {
      return;
    }
    const itemAlreadyExist = unRepeatData.some(
      (unRepeatItem) => unRepeatItem?.pk === item.pk,
    );
    if (!itemAlreadyExist) {
      unRepeatData.push({ ...item, key: item.pk });
    }
  });
  // console.log('unRepeatData1:', unRepeatData);
  return unRepeatData;
};

const generateTree = (treeNodes = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    children: generateTree(children),
  }));

const groupTree = (data = [], key, customTree, canNoChildren) => {
  const treeData = [];
  data.forEach((item) => {
    const parentPK = item[key]?.pk;
    if (!parentPK) {
      if (canNoChildren) {
        treeData.push({
          ...item,
          title: item.title || item.name,
          key: item.pk,
          children: [],
        });
      }
      return;
    }
    // eslint-disable-next-line eqeqeq
    const exsitGroup = treeData.find((group) => group.pk == parentPK);
    if (exsitGroup) {
      exsitGroup.children.push({
        ...item,
        title: customTree.renderChildrenTitle
          ? customTree.renderChildrenTitle(item)
          : item.title || item.name,
        key: item.pk,
      });
      return;
    }
    treeData.push({
      ...item[key],
      key: `group-${parentPK}`,
      title: customTree.renderGroupTitle
        ? customTree.renderGroupTitle(item[key])
        : item[key].title || item[key].name,
      children: [
        {
          ...item,
          title: customTree.renderChildrenTitle
            ? customTree.renderChildrenTitle(item)
            : item.title || item.name,
          key: item.pk,
        },
      ],
    });
  });
  return treeData;
};

const removeUnExsitTarget = (source = [], target = []) => {
  const cleanedTarget = target.filter((item) =>
    source.some((sourceItem) => sourceItem.pk === item.pk),
  );
  return cleanedTarget;
};

export default function BaseMultipleSelectorV2({
  title,
  requires,
  disabled,
  namespace = '',
  groupKey,
  tips,
  hideTopSpace,
  searchPlaceholder = 'Search by Name',
  onFocusFunc,
  custom = {
    customItem: null,
    customTitle: null,
    customContainerStyle: null,
    customFilter: null,
  },
  size = [],
  data = {
    sourceData: [],
    targetData: [],
    targetChange: () => {},
  },
  switchInfo = {
    showSwitch: false,
    checked: false,
    checkChange: () => {},
  },
  addButton = {
    title: '',
    action: () => {},
    link: '',
    customClass: '',
  },
  error = {
    id: '',
    message: '',
    error: false,
  },
  customTree = {
    renderGroupTitle: null,
    renderChildrenTitle: null,
  },
  sortable = true,
  loadMoreAction = () => {},
  canNoChildren = false,
}) {
  const { allListLoading, selectedAllListLoading } = useSelector((state) => ({
    allListLoading: state[namespace]?.allListLoading,
    selectedAllListLoading: state[namespace]?.selectedAllListLoading,
  }));
  // console.log('errorSelec:', error);
  const history = useHistory();
  const isLoading = allListLoading || selectedAllListLoading;
  const sourceData = data.sourceData;
  const targetData = data.targetData;
  const sourceUnRepeat = getUnRepeatData(sourceData);
  const targetUnRepeat = getUnRepeatData(targetData);
  const cleanedTarget = removeUnExsitTarget(sourceUnRepeat, targetUnRepeat);
  const groupSourceData = groupKey
    ? groupTree(sourceData, groupKey, customTree, canNoChildren)
    : [];
  const targetKeys = cleanedTarget.map((item) => item.key);
  const targetParentKeys = cleanedTarget.map(
    (item) => item?.[groupKey]?.pk || item?.pk
  );
  
  const sourceParentKeys = [];
  sourceUnRepeat.forEach((item) => {
    const parentKey = item?.[groupKey]?.pk || item?.pk;
    const itemAlreadyExist = sourceParentKeys.some(
      (unRepeatItem) => unRepeatItem === parentKey,
    );
    if (!targetKeys.includes(item?.key) && !itemAlreadyExist) {
      sourceParentKeys.push(parentKey);
    }
  })

  const [sourceSelectedKeys, setSourceSelectedKeys] = useState([]);
  const [targetSelectedKeys, setTargetSelectedKeys] = useState([]);
  const [searchInfo, setSearchInfo] = useState({ left: '', right: '' });
  const [searchedSelectedKeys, setSearchedSelectedKeys] = useState([]);

  const targetChange = (changeKeys, direction, moveKeys) => {
    let keys = changeKeys;
    if (direction === 'right') {
      moveKeys.map((key) => keys.push(key));
      keys = keys.slice(moveKeys.length);
    }

    console.log(direction);
    if (sortable) {
      keys.sort((a, b) => a - b);
    }
    const newTargetData = keys.map((key) =>
      // eslint-disable-next-line eqeqeq
      sourceUnRepeat.find((item) => item.pk == key),
    );
    data.targetChange(newTargetData);
    console.log(targetKeys, keys, data.targetData, targetSelectedKeys);
  };

  const filterOption = (inputValue, option) => {
    if (custom?.customFilter) {
      return custom.customFilter(inputValue, option);
    }
    const optionName = option?.name?.toLowerCase() || '';
    return optionName.indexOf(inputValue?.toLowerCase()) > -1;
  };

  const getSearchedSelectedKeys = () => {
    const searchedKeys = [];
    const allSelectedKeys = [...targetSelectedKeys, ...sourceSelectedKeys];

    sourceUnRepeat.forEach((source) => {
      const isInTarget = targetKeys.includes(source.pk);
      const searchKey = isInTarget ? searchInfo.right : searchInfo.left;
      const isSearchResultItem = custom?.customFilter
        ? custom.customFilter(searchKey, source)
        : source.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1;
      if (!isSearchResultItem) {
        return;
      }
      const isSelected = allSelectedKeys.indexOf(source.pk) > -1;

      if (isSelected) {
        searchedKeys.push(source.pk);
      }
    });

    return searchedKeys;
  };

  const getTreeFilterData = (direction) => {
    const treeData = [];
    const searchValue = searchInfo[direction];
    groupSourceData.forEach((sourceTree) => {
      // if (!sourceTree?.children?.length) {
      //   // if (
      //   //   canNoChildren &&
      //   //   filterOption(searchValue, sourceTree) &&
      //   //   (direction === 'left'
      //   //     ? !targetKeys.includes(sourceTree.key)
      //   //     : targetKeys.includes(sourceTree.key))
      //   // ) {
      //   //   treeData.push({
      //   //     key: sourceTree.key,
      //   //     title: customTree.renderGroupTitle
      //   //       ? customTree.renderGroupTitle(sourceTree)
      //   //       : sourceTree.title || sourceTree.name,
      //   //     pk: sourceTree.pk,
      //   //     name: sourceTree.name,
      //   //   });
      //   // }
      //   return;
      // }
      const parentFilter = (filterOption(searchValue,sourceTree) &&
      (direction === 'left'
        ? sourceParentKeys.includes(sourceTree.pk)
        : targetParentKeys.includes(sourceTree.pk)))
      const searchedResult = sourceTree.children.filter((child) => {
        return (
          (filterOption(searchValue, child) || parentFilter) &&
          (direction === 'left'
            ? !targetKeys.includes(child.key)
            : targetKeys.includes(child.key))
        );
      });

      if (!!searchedResult?.length || (canNoChildren && parentFilter && !sourceTree?.children?.length)) {
        const data ={
          key: sourceTree.key,
          title: customTree.renderGroupTitle
            ? customTree.renderGroupTitle(sourceTree)
            : sourceTree.title || sourceTree.name,
          pk: sourceTree.pk,
          name: sourceTree.name,
          
        }
        if(!!searchedResult?.length){
          data.children= searchedResult;
        }
        treeData.push(data);
      }
    });
    return treeData;
  };

  const treeCheckChange = (key, direction, treeData) => {
    onFocusFunc && onFocusFunc();
    const allKeysList =
      direction === 'left' ? sourceSelectedKeys : targetSelectedKeys;
    const groupCheckSeperatorIndex = `${key}`.indexOf('-');
    if (groupCheckSeperatorIndex > -1) {
      const groupCheckedKey = key.substring(groupCheckSeperatorIndex + 1);
      const filteredTreeObject = treeData.find(
        // eslint-disable-next-line eqeqeq
        (item) => item.pk == groupCheckedKey,
      );
      if (!filteredTreeObject || !filteredTreeObject.children?.length) {
        return;
      }
      const sourceGroupTreeObject = groupSourceData.find(
        // eslint-disable-next-line eqeqeq
        (item) => item.pk == groupCheckedKey,
      );
      // console.log('sourceGroupTreeObject:', sourceGroupTreeObject);
      if (!sourceGroupTreeObject || !sourceGroupTreeObject.children?.length) {
        return;
      }
      const sourceChildrenKeys = sourceGroupTreeObject.children.map(
        (item) => item.key,
      );
      const treeChildrenKeys = filteredTreeObject.children.map(
        (item) => item.key,
      );
      const notAllSelected = filteredTreeObject.children.some(
        (item) => !searchedSelectedKeys.includes(item.key),
      );
      const removedOldKeyList = allKeysList.filter(
        (item) => !sourceChildrenKeys.includes(item),
      );
      // console.log('removedOldKeyList:', removedOldKeyList, sourceChildrenKeys);
      if (notAllSelected) {
        removedOldKeyList.push(...treeChildrenKeys);
      }
      direction === 'left'
        ? setSourceSelectedKeys(removedOldKeyList)
        : setTargetSelectedKeys(removedOldKeyList);
      return;
    }
    const isSelected = allKeysList.includes(key);
    const removedOldKeyList = allKeysList.filter((item) => item !== key);
    if (!isSelected) {
      removedOldKeyList.push(key);
    }
    direction === 'left'
      ? setSourceSelectedKeys(removedOldKeyList)
      : setTargetSelectedKeys(removedOldKeyList);
  };

  useEffect(() => {
    const newSearchKeys = getSearchedSelectedKeys();
    setSearchedSelectedKeys(newSearchKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    targetKeys.length,
    searchInfo.left,
    searchInfo.right,
    sourceSelectedKeys.length,
    targetSelectedKeys.length,
  ]);
  // console.log('@@284: size: ', size);

  // const onChange = (nextTargetKeys) => {
  //   targetKeys = Array.from(new Set(nextTargetKeys));
  // };

  // const moveRow = async (dragIndex, hoverIndex) => {
  //   const clonedList = targetKeys;
  //   const el = clonedList.splice(dragIndex, 1)[0];
  //   clonedList.splice(hoverIndex, 0, el);
  //   onChange(clonedList);
  // };

  return (
    <>
      {!hideTopSpace ? <div style={{ marginTop: '35px' }} /> : null}
      <div
        className={`v2-selector-multiple-container ${
          size.includes(SELECTOR_SIZE.SHORTER) ? 'is-shorter' : ''
        } ${size.includes(SELECTOR_SIZE.HIGHER) ? 'is-higher' : ''} ${
          size.includes(SELECTOR_SIZE.WIDER) ? 'is-wider' : ''
        } ${size.includes(SELECTOR_SIZE.NARROWER) ? 'is-narrower' : ''}`}
        style={custom.customContainerStyle || {}}
      >
        {custom.customTitle ? (
          custom.customTitle
        ) : (
          <div className={'v2-selector-multiple-container-title'}>
            <label>{title}</label>
            {switchInfo?.showSwitch ? (
              <div style={{ marginLeft: 'auto', marginRight: '40px' }}>
                <FollowCouponSetSwitch
                  checked={switchInfo?.checked}
                  onChange={switchInfo?.checkChange || (() => {})}
                />
              </div>
            ) : null}
          </div>
        )}
        {tips ? (
          <label
            className="second-section_description"
            style={{ marginTop: '0px' }}
          >
            {tips}
          </label>
        ) : null}
        <div
          style={{
            position: 'relative',
            paddingTop: '25px',
            marginTop: '10px',
          }}
        >
          <div className="multiple-selector-v2-search-left">
            <img alt="" src={SearchIcon} />
          </div>
          <div className="multiple-selector-v2-search-right">
            <img alt="" src={SearchIcon} />
          </div>
          <Transfer
            dataSource={sourceUnRepeat}
            className={`v2-selector-multiple-transfer ${
              error?.error ? 'v2-selector-multiple-transfer-error' : null
            }`}
            disabled={disabled}
            filterOption={filterOption}
            showSearch
            targetKeys={targetKeys}
            onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
              onFocusFunc && onFocusFunc();

              setSourceSelectedKeys(sourceSelectedKeys);
              setTargetSelectedKeys(targetSelectedKeys);
            }}
            onSearch={(direction, value) => {
              setSearchInfo({ ...searchInfo, [direction]: value });
            }}
            onScroll={(direction, event) => {
              if (direction === 'right') {
                return;
              }
              if (
                event.nativeEvent.target.scrollTop +
                  event.nativeEvent.target.clientHeight ===
                event.nativeEvent.target.scrollHeight
              ) {
                loadMoreAction();
              }
            }}
            selectedKeys={disabled ? [] : searchedSelectedKeys}
            onChange={targetChange}
            locale={{
              searchPlaceholder: searchPlaceholder,
              selectAll: 'Select all',
              selectInvert: 'Invert selection',
              notFoundContent: (
                <img
                  alt=""
                  style={isLoading ? { width: '80px', height: '80px' } : {}}
                  src={isLoading ? loading : nodata}
                />
              ),
            }}
            render={(item) =>
              custom.customItem ? custom.customItem(item) : item?.name
            }
            titles={['Available item(s)', 'Selected item(s)']}
          >
            {groupKey
              ? ({ direction }) => {
                  const treeData = getTreeFilterData(direction);
                  const groupTreeKeys = [];
                  treeData.forEach((item) => {
                    if (!item.children) {
                      if (canNoChildren) {
                        groupTreeKeys.push(item.key);
                      }
                      return;
                    }
                    const keys = item.children.map((child) => child.key) || [];
                    groupTreeKeys.push(...keys);
                  });
                  const checkedKeys =
                    groupTreeKeys.filter((groupTreeKey) =>
                      [...sourceSelectedKeys, ...targetSelectedKeys].includes(
                        groupTreeKey,
                      ),
                    ) || [];
                  // console.log("@@358: ", treeData)
                  if (!treeData?.length) {
                    return null;
                  }
                  return (
                    <Tree
                      checkable
                      disabled={disabled}
                      defaultExpandAll={true}
                      className={disabled ? 'tree-disabled' : ''}
                      checkedKeys={checkedKeys}
                      treeData={generateTree(treeData)}
                      onCheck={(_, { node: { key } }) =>
                        treeCheckChange(key, direction, treeData)
                      }
                      onSelect={(_, { node: { key } }) =>
                        treeCheckChange(key, direction, treeData)
                      }
                    />
                  );
                }
              : null}
          </Transfer>
        </div>
        {addButton?.title ? (
          <div
            style={{ marginTop: '10px' }}
            className={`custom-add-remove-mutiple-selector-add-btn-container `}
          >
            <AuthButton
              title={addButton.title}
              requires={requires}
              disabled={disabled}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                if (addButton.link) {
                  history.push(addButton.link);
                }
                if (addButton.action) {
                  addButton.action();
                }
              }}
            />
          </div>
        ) : null}

        <ErrorFieldMessage
          id={error.id}
          error={error.error}
          message={error.message}
        />
      </div>
    </>
  );
}
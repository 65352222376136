export const NavBarNames = {
  customer: 'Customer',
  segments: 'Segment',
  customerGroup: 'Customer group',
  levels: 'Levels',
  records: 'Records',
  campaigns: 'Campaigns',
  stampCampaigns: 'Stamp campaigns',
  campaignCategory: 'Campaign categories',
  message: 'Messages',
  earnRules: 'Earning rules',
  coupons: 'Coupons',
  giftCards: 'Gift cards',
  giftCardRecords: 'Gift card records',
  rewards: 'Rewards',
  brands: 'Brands',
  stores: 'Stores',
  storeCodes: 'Store code List',
  storeCategory: 'Store Categories',
  translations: 'Translations',
  transactions: 'Transactions',
  bulkRewards: 'Bulk rewards update',
  stampRecords: 'Stamp records',
  pointRecords: 'Point records',
  couponRecords: 'Coupon records',
  others: 'Webview content',
  admin: 'Administrators',
  adminGroup: 'Administrators group',
  dashboard: 'Dashboard',
  dashboardCampaignAndRedemption: 'Campaigns/Redeem',
  dashboardDemographic: 'Demographics',
  dashboardSales: 'Sales',
  banners: 'Banners',
  productCategory: 'Product categories',
  featuredCampaign: 'Featured campaigns',
  featuredProduct: 'Featured products',
  langauge: 'Languages',
  levelDiscount: 'Level discount',
  webview: 'Webview content',
  rfm: "RFM",
  messageCreditRecords: "Message credit records",
};

import { CampaignType } from 'config/CustomEnums';
import { parseExpiredDate } from 'models/CouponListModel';
import { loading } from 'models/LoadingUtil';
import {
  getCouponTransaction,
  getCouponTransactionReports,
  getCouponTransactions,
} from 'services/TransactionRecordsAPIHelper';

import {
  capitalizeFirstLetter,
  convertCursorToNumber,
  convertNumberToCursor,
  createAction,
} from 'utils';
import { formatDate, getDisplayDate } from 'utils/TimeFormatUtil';

const getInitialState = () => ({
  couponTransactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    {
      displayName: 'Name (preferred name)',
      fieldName: 'name',
      linked: false,
      orderField: 'customerFirstName',
    },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    { displayName: 'Record Type', fieldName: 'displayType' },
    { displayName: 'Reference ID', fieldName: 'couponReferenceId'},
    { displayName: 'Coupon ID', fieldName: 'couponID', orderField: 'coupon' },
    {
      displayName: 'Coupon Set Name',
      fieldName: 'couponTemplateName',
      orderField: 'couponTemplateName',
    },
    {
      displayName: 'Expiry Date',
      fieldName: 'couponExpiryDate',
    },
    {
      displayName: 'Create at',
      fieldName: 'creationDate',
      orderField: 'creationDate',
    },
  ],
  reportListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', linked: false },
    { displayName: 'Report type', fieldName: 'reportType' },
    { displayName: 'Time range', fieldName: 'displayTimeRange' },
    { displayName: 'Create at', fieldName: 'creationDate' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageCouponTransactionList: [],
  selectedCouponTransaction: {},
  currentPageCouponTransactionReportList: [],
});

const parseCouponTransactionType = (type) => {
  let displayType = '';
  switch (type) {
    case 'EARN':
      displayType = 'Customer earned';
      break;
    case 'BUY':
      displayType = 'Customer acquired';
      break;
    case 'GRANT':
      displayType = 'Admin added';
      break;
    case 'RECLAIM':
      displayType = 'Admin removed';
      break;
    case 'USE':
      displayType = 'Customer use';
      break;
    case 'EXPIRED':
      displayType = 'Coupon expired';
      break;
    case 'DEACTIVATE':
      displayType = 'Admin deactivated';
      break;
    case 'REACTIVATE':
      displayType = 'Admin reactivated';
      break;
    default:
      break;
  }
  return displayType;
};

const parseCouponReportType = (reportType) => {
  switch (reportType) {
    case 'WEEKLY':
      return 'Weekly';
    case 'MONTHLY':
      return 'Monthly';
    default:
      break;
  }
}

const getEventType = (transactionType) => {
  let eventType = '';
  switch (transactionType) {
    case 'EARN':
    case 'BUY':
    case 'GRANT':
      eventType = 'Coupon in';
      break;
    case 'RECLAIM':
    case 'USE':
    case 'EXPIRED':
      eventType = 'Coupon out';
      break;
    default:
      break;
  }
  return eventType;
};

const parseCouponTransaction = (item) => {
  const earningRule = item.earningRule || item.campaign?.earningCampaignTypeEarningRule
  const isStampCampaign = item.campaign?.type === CampaignType.stampCampaign;
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.customer?.membershipId,
    ssoUid: item.customer?.ssoUid,
    originType: item.type,
    displayType: parseCouponTransactionType(item.type),
    pointsSpent: item.pointsSpent,
    creationDate: getDisplayDate(item.creationDate),
    usedDate: formatDate(item.usedAt, 'DD MMM yyyy (ddd), HH:mm a'),
    name: item.customer
      ? item.customer?.nickname
        ? `${item.customer?.firstName} ${item.customer?.lastName} (${item.customer?.nickname})`
        : `${item.customer?.firstName} ${item.customer?.lastName}`
      : null,
    isStampCampaign,
    campaignName: item.campaign?.name,
    earningRuleName: earningRule?.name,
    couponID: item.coupon?.pk,
    couponTemplateName: item.coupon?.template?.name,
    couponTemplateNameWithSerialNumber: `${capitalizeFirstLetter(
      item.type.toString().toLowerCase(),
    )} ${item.coupon?.template?.name}(${item.coupon?.serialNumber})`,
    couponReferenceId:item.coupon?.referenceId,
    couponExpiryDate: item.coupon?.template
      ? parseExpiredDate(item.coupon?.template)
      : null,
    administratorName: item?.administrator?.username,
    createdDate: formatDate(item.creationDate),
    eventType: getEventType(item.type),
    displayCampaign: item.campaign
      ? `[ID: ${item.campaign?.pk}] ${item.campaign?.name}`
      : '-',
    displayCoupon: item.coupon
      ? `[ID: ${item.coupon?.pk}] ${item.coupon?.template?.name}`
      : '-',
    displayEarningRule: earningRule
      ? `[ID: ${earningRule?.pk}] ${earningRule?.name}`
      : '-',
  };
};

const parseCouponTransactionReports = (item) => {
  return {
    pk: item.pk,
    id: item.id,
    name: item.reportName,
    reportType: parseCouponReportType(item.reportType),
    displayTimeRange: `${formatDate(item.startTime, 'DD MMM yyyy')} - ${formatDate(item.endTime, 'DD MMM yyyy')}`,
    creationDate: formatDate(item.creationDate, 'DD MMM yyyy'),
    reportFileUrl: item.reportFileUrl,
  };
};

export default {
  namespace: 'couponTransactions',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
  },
  effects: {
    getCouponTransactions: [
      function* ({ payload }, { call, put }) {
        // const { afterCursor } = payload;
        const serviceArgs = [
          getCouponTransactions,
          null,
          { ...payload, rank: true },
        ];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              couponTransactionList: couponTransactionList,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageCouponTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        console.log('@@158: ', afterCursor);
        const serviceArgs = [getCouponTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const pageInfo = data.couponTransactions.pageInfo;
          const totalCount = data.couponTransactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPageCouponTransactionList: couponTransactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSingleCouponTransactionRecord: [
      function* ({ payload }, { put }) {
        const { couponTransactionPK } = payload;
        const transactionID = btoa(
          `CouponTransactionNode:${couponTransactionPK}`,
        );
        const serviceArgs = [getCouponTransaction, transactionID];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              selectedCouponTransaction: parseCouponTransaction(
                data.couponTransaction,
              ),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageCouponTransactionsReports: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getCouponTransactionReports, afterCursor, payload];
        function* onSuccess(data) {
          const conponTransactionReportsData = data.couponReports.edges;
          const pageInfo = data.couponReports.pageInfo;
          const totalCount = data.couponReports.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const couponTransactionReportList = conponTransactionReportsData.map((item) =>
            parseCouponTransactionReports(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPageCouponTransactionReportList: couponTransactionReportList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};

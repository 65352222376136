import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { singleCouponBreads } from '../../../config/CustomBreadConfig';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import SingleCouponOverview from '../../../components/coupon/couponList/SingleCouponOverview';
import { DeleteAndMultipleButtons } from '../../../components/base/BottomStepComponent';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import DeactiveSingleCouponView from '../../../components/coupon/couponList/DeactiveSingleCouponView';
import {
  CouponURLQueryKeys,
  CouponURLFragments,
} from '../../../components/coupon/CouponUrlConfig';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
const queryString = require('query-string');

@connect(({ couponList, singleCoupon }) => ({
  couponSet: couponList.couponSet,
  singleCoupon: singleCoupon.singleCoupon,
}))
class SingleCouponDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteView: false,
      showDeactiveView: false,
      selectedOwner: props.singleCoupon?.owner,
    };
  }

  componentDidMount() {
    const couponSetId = this.props.match.params.id;
    const singleCouponId = this.props.match.params.singleCouponId;
    this.props.dispatch(
      createAction('couponList/getCouponSet')({
        couponSetPK: couponSetId,
        notSaveToSession: true,
      }),
    );
    this.props.dispatch(
      createAction('singleCoupon/getSingleCoupon')({
        pk: singleCouponId,
      }),
    );
  }

  componentWillUnmount() {
    this.props.dispatch(createAction('singleCoupon/clearSingleCoupon')());
  }

  goBacktoCouponList = () => {
    return this.props.history.push({
      pathname: `/coupons/${this.props.couponSet.pk}`,
      search: queryString.stringify({
        [CouponURLQueryKeys.rank]: true,
        [CouponURLQueryKeys.page]: 1,
        [CouponURLQueryKeys.searchKey]: '',
      }),
      hash: CouponURLFragments.list,
      state: this.props.couponSet,
    });
  };

  saveButtonClicked = () => {
    if (this.state.selectedOwner && this.state.selectedOwner !== '-') {
      this.props.dispatch(
        createAction('singleCoupon/grantSingleCoupon')({
          couponPK: this.props.singleCoupon.pk,
          customerPK: this.state.selectedOwner?.value?.pk,
          afterAction: this.goBacktoCouponList,
        }),
      );
    }
    this.goBacktoCouponList();
  };

  getBottomActions = () => {
    let actions = [];
    if (!this.props.singleCoupon?.isExpired){
      if (this.props.singleCoupon?.isForcedInactive) {
        actions = [
          {
            text: 'Active',
            action: () => {
              this.props.dispatch(
                createAction('singleCoupon/activeSingleCoupon')({
                  couponPK: this.props.singleCoupon.pk,
                  activeAction: this.goBacktoCouponList,
                }),
              );
            },
          },
          ...actions,
        ];
      } else {
        actions = [
          {
            text: 'Deactive',
            action: () => this.setState({ showDeactiveView: true }),
          },
          ...actions,
        ];
      }
    }
    if (
      this.props.singleCoupon.owner &&
      this.props.singleCoupon.owner.owner !== ''
    ) {
      actions = [
        ...actions,
        {
          text: 'Reclaim',
          action: () => {
            this.props.dispatch(
              createAction('singleCoupon/reclaimCoupon')({
                couponPK: this.props.singleCoupon.pk,
                afterAction: () => this.goBacktoCouponList(),
              }),
            );
          },
        },
      ];
    } else {
      actions = [
        ...actions,
        {
          text: 'Save',
          action: this.saveButtonClicked,
        },
      ];
    }
    return actions;
  };

  render() {
    return (
      <div>
        <CustomListComponent
          breadcrumb={
            <CustomBreadcrumb
              breadcrumbs={singleCouponBreads(
                this.props.couponSet.name,
                this.props.singleCoupon?.serialNumber,
              )}
            />
          }
          title={`${this.props.couponSet.name}(${this.props.singleCoupon?.serialNumber})`}
        />
        <SingleCouponOverview
          singleCoupon={this.props.singleCoupon}
          couponSet={this.props.couponSet}
          defaultOwner={
            this.state.selectedOwner && this.state.selectedOwner.owner !== '-'
              ? this.state.selectedOwner
              : ''
          }
          selectedOwner={(owner) => {
            this.setState({ selectedOwner: owner });
          }}
        />
        {this.props.singleCoupon?.isUsed ? (
          null
        ) : (
          <DeleteAndMultipleButtons
            deleteAction={() => {
              this.setState({ showDeleteView: true });
            }}
            multipleButtons={this.getBottomActions()}
          />
        )}
        <DeletePrompt
          data={this.props.singleCoupon}
          show={this.state.showDeleteView}
          title={'Single Coupon'}
          relatedName=""
          relatedSections={DELETE_RELATED_SECTIONS.COUPON}
          onClose={() => {
            this.setState({ showDeleteView: false });
          }}
          onConfirm={() => {
            this.props.dispatch(
              createAction('singleCoupon/updateState')({
                checkedList: [this.props.singleCoupon],
              }),
            );
            this.props.dispatch(
              createAction('singleCoupon/delete')({
                couponPK: this.props.singleCoupon.pk,
                afterAction: this.goBacktoCouponList,
              }),
            );
          }}
        />

        <DeactiveSingleCouponView
          showDeactiveView={this.state.showDeactiveView}
          onHide={() => this.setState({ showDeactiveView: false })}
          singleCoupon={this.props.singleCoupon}
          couponSet={this.props.couponSet}
          activeBtnClicked={() => {
            this.props.dispatch(
              createAction('singleCoupon/deactiveSingleCoupon')({
                couponPK: this.props.singleCoupon.pk,
                afterAction: this.goBacktoCouponList,
              }),
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(SingleCouponDetail);

import React from 'react';
import { useLocation } from 'react-router-dom';
import BasePrompt from '../base/prompt/BasePrompt';

function ContinueCreatePrompt(props) {
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const location = useLocation();
  return (
    <BasePrompt
      show={props.show}
      closeAction={onClose}
      rightButton={{
        text: 'Continue',
        action: onConfirm,
      }}
      leftButton={{
        text: 'Maybe Later',
        action: onClose,
      }}
      title={location?.state?.title}
      description={location?.state?.content}
    />
  );
}

export default ContinueCreatePrompt;

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import arrayMove from 'array-move';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { createAction } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import { STORE_CATEGORY_ORDER_LAST } from '../../../models/CreateStoreCategoryModel';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';


function StoreCategory({
  apiDataList,
  totalCount,
  totalPage,
  pageInfo,
  listDisplayFields,
  checkedList,
}) {
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [sortDisabled, setSortDisabled] = React.useState(false);
  const [deleteItems, setDeleteItems] = React.useState([]);
  const deletePks = deleteItems.map((item) => item.pk);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1;
  const searchKey = searchDict?.search;
  const hasSearchKey = searchKey && searchKey.length > 0;
  const fetchData = () => {
    setSortDisabled(hasSearchKey);
    dispatch(
      createAction('storeCategoryList/getStoreCategoryList')({
        page: currentPage - 1,
        searchKey,
        moreSearch: { ...searchDict },
      }),
    );
  };
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  React.useEffect(() => {
    setDataList(apiDataList);
  }, [apiDataList]);
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCampaignCategory,
    },
  ];
  const tabs = [
    {
      name: 'List Of Store Categories',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeCategoryList'}
              permissionGroup={PermissionCodes.campaigncategory}
              deleteInfo={{
                data: [],
                title: 'store category',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.STORE_CATEGORY,
              }}
              sortable
              sortDisabled={sortDisabled}
              rankDisabled
              onSortEnd={(oldIndex, newIndex) => {
                if (oldIndex === newIndex) return;
                const startOrder = dataList[0].order;
                const newDataList = arrayMove(dataList, oldIndex, newIndex);

                const movedItem = dataList[oldIndex];
                dispatch(
                  createAction('createStoreCategory/updateCategoryOrder')({
                    id: movedItem.pk,
                    order: startOrder + newIndex,
                  }),
                );
                setDataList(
                  newDataList.map((data, index) => ({
                    ...data,
                    order: startOrder + index,
                  })),
                );
              }}
              onOrderChangedByEdit={(pk, newOrder) => {
                dispatch(
                  createAction('createStoreCategory/updateCategoryOrder')({
                    id: pk,
                    order: newOrder,
                    afterAction: fetchData,
                  }),
                );
              }}
              hasOtherActions
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Detail',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewSorecategory,
                  },
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeStorecategory,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.deleteStorecategory,
                  },
                  {
                    name: 'Duplicate',
                    action: () => {
                      dispatch(
                        createAction('createStoreCategory/duplicateCategory')({
                          pk: item.pk,
                          afterAction: fetchData,
                        }),
                      );
                    },
                    requires: PermissionCodes.addStorecategory,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    onToggle={(open) => {
                      if (!hasSearchKey) {
                        setSortDisabled(open);
                      }
                    }}
                    otherActions={[
                      {
                        name: 'Move to first',
                        action: () => {
                          dispatch(
                            createAction(
                              'createStoreCategory/updateCategoryOrder',
                            )({
                              id: item.pk,
                              order: 1,
                              afterAction: fetchData,
                            }),
                          );
                        },
                        requires: PermissionCodes.changeStorecategory,
                      },
                      {
                        name: 'Move to last',
                        action: () => {
                          dispatch(
                            createAction(
                              'createStoreCategory/updateCategoryOrder',
                            )({
                              id: item.pk,
                              order: STORE_CATEGORY_ORDER_LAST,
                              afterAction: fetchData,
                            }),
                          );
                        },
                        requires: PermissionCodes.changeStorecategory,
                      },
                    ]}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title="Create store category"
      action={() => {
        history.push('store_category/create');
      }}
      requires={PermissionCodes.addStorecategory}
    />,
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'You can create different categories to group your stores',
          title: NavBarNames.storeCategory,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Store Category"
        relatedSections={DELETE_RELATED_SECTIONS.STORE_CATEGORY}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
          dispatch(
            createAction('createStoreCategory/deleteCategories')({
              ids: deletePks,
              afterAction: fetchData,
            }),
          );
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  apiDataList: state.storeCategoryList.categoryList,
  totalCount: state.storeCategoryList.totalCount,
  totalPage: state.storeCategoryList.totalPage,
  pageInfo: state.storeCategoryList.pageInfo,
  listDisplayFields: state.storeCategoryList.listDisplayFields,
  checkedList: state.storeCategoryList.checkedList,
});

export default connect(mapPropsToState)(StoreCategory);

import { MessageChannel } from 'config/CustomEnums';

import ImageAll from 'assets/images/message/img_all.svg';
import ImageEmail from 'assets/images/message/img_email.svg';
import ImageInbox from 'assets/images/message/img_inbox.svg';
import ImagePush from 'assets/images/message/img_push.svg';
import ImageSMS from 'assets/images/message/img_sms.svg';

export const MessageChannelConfig = [
  {
    channel: MessageChannel.all,
    image: ImageAll,
    description:
      'Message sent to all possible channels, e.g. push, email, SMS and inbox.',
    suggest: null,
  },
  {
    channel: MessageChannel.allWhatsapp,
    image: ImageAll,
    description:
      'Message sent to all possible channels, e.g. push, email, Whatsapp and inbox. The content are limited by the available templates in Whatsapp.',
    suggest: null,
  },
  {
    channel: MessageChannel.push,
    image: ImagePush,
    description: 'Encourage customers to open the app.',
    suggest: 'Suggest length: 235 characters',
  },
  {
    channel: MessageChannel.email,
    image: ImageEmail,
    description: 'Send to the customer’s provided email address.',
    suggest: 'Suggest length: 50 ~ 125 words',
  },
  {
    channel: MessageChannel.sms,
    image: ImageSMS,
    description: 'Send to the customer’s provided mobile number.',
    suggest: 'Suggest length: 160 characters',
  },
  {
    channel: MessageChannel.whatsapp,
    image: ImageSMS,
    description: 'Send templated messages to customer’s mobile number.',
    suggest: null,
  },
  {
    channel: MessageChannel.inbox,
    image: ImageInbox,
    description:
      'Save a copy in the Inbox (front end) for easy reference in the future.',
    suggest: 'Suggest length: 1,000 character',
  },
];

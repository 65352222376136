import React, { useState, useEffect } from 'react';
import './CreateCampaignStepFour.scss';
import { useDispatch, connect } from 'react-redux';
import ContentSections from '../../base/ContentSections';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import PublishCampaignPrompt from './PublishCampaignPrompt';
import { BackAndMultipleButtons, SaveButton, SaveTempButton } from '../../base/BottomStepComponent';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { createAction } from '../../../utils';
import { ActionType, APIStatus, ApprovalStatus, CampaignType } from '../../../config/CustomEnums';
import Loading from '../../base/Loading';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { useFormContext } from 'react-hook-form';
import ApprovalCheckList from './ApprovalCheckList';
import ListButtonsGroup from '../../base/ListButtonsGroup';
import RejectPrompt from './RejectPrompt';

function CreateCampaignStepFour({
  createStatus,
  formHasSubmitted,
  listpath,
  isSuperuser,
  userPermissions,
  campaignId,
}) {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();
  const location = useLocation();

  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [showRejectPrompt, setShowRejectPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const approvalStatus = getValues('approvalStatus');
  let saveActionType = null;
  let tempActionType = null;
  const campaignType = getValues('campaignType')

  const dispatch = useDispatch();
  const history = useHistory();
  const sections = () => {
    let section = [<ProfileSection />];
    if (campaignType !== CampaignType.generalMessageCampaign) {
      section.push(<ApprovalCheckList />)
    }
    return section
  } 
  // const { createStatus, isPublished } = useSelector((state) => ({
  //   createStatus: state.createCampaign.createStatus,
  //   isPublished: state.createCampaign.campaign.isPublished,
  // }));
  const hasPermission = (requires) => {
    if (isSuperuser) return true;
    if (userPermissions.includes(requires) || !requires) return true;

    return false;
  };

  useEffect(() => {
    if (createStatus === APIStatus.success && formHasSubmitted) {
      setShowPublishPrompt(
        (actionType !== ActionType.approval)||
          history.location.pathname.includes('edit'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus, formHasSubmitted]);

  const stepChangeAction = (checkCheckList, isBack, buttonAction = null) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
      checkCheckList,
    });
    if (!isValid || isBack) {
      dispatch({
        type: 'createCampaign/stepChange',
        payload: { isBack: isBack, step: 3, isValid },
      });
    } else {
      setActionType(buttonAction)
      if (buttonAction === ActionType.withdraw || buttonAction === ActionType.unpublish) {
        dispatch(
          createAction('createCampaign/updateApprovalStatus')({
            data: getValues(),
            actionType: buttonAction,
          }),
        )
      } else {
        dispatch(
          createAction('createCampaign/createOrUpdateCampaign')({
            data: getValues(),
            actionType: buttonAction,
            resume: location.state?.resume
          }),
        );
      }
    }
  };

  const getTempText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        tempActionType = ActionType.update
        return 'Update'
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          tempActionType = ActionType.update
          return 'Update'
        }
        tempActionType = ActionType.withdraw
        return 'Withdraw'
      case ApprovalStatus.published:
        tempActionType = ActionType.tempForPulished
        return 'Update'
      default:
        tempActionType = ActionType.save
        return 'Save as a draft'
    }
  }

  const tempRequires = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return PermissionCodes.editDraftCampaign
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          return PermissionCodes.publishPendingCampaign
        }
        return PermissionCodes.withdrawPendingCampaignToDraft
      case ApprovalStatus.published:
        return PermissionCodes.tempForPublishCampaign
      default:
        return PermissionCodes.createDraftCampaign
    }
  }

  const getSaveText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.published:
        saveActionType = ActionType.unpublish
        return 'Unpublish'
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          saveActionType = ActionType.approval
          return 'Approve'
        }
        saveActionType = ActionType.pendingForApproval
        return 'Pending for approval'
      default:
        saveActionType = ActionType.submitForApproval
        return 'Submit for approval'
    }
  }

  const saveRequires = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return PermissionCodes.pendingCampaign
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          return PermissionCodes.publishPendingCampaign
        }
        return PermissionCodes.withdrawPendingCampaignToDraft
      case ApprovalStatus.published:
        if (hasPermission(PermissionCodes.withdrawPublishCampaignToPending)) {
          return PermissionCodes.withdrawPublishCampaignToPending
        }
        return PermissionCodes.withdrawPublishCampaignToDraft
      default:
        return PermissionCodes.pendingCampaign
    }
  }

  const getExtraPopContent = () => {
    const extraPopContent = [
      {
        content: getTempText(),
        action: () => stepChangeAction(false, false, tempActionType),
      },
    ]

    if (approvalStatus === ApprovalStatus.pending) {
      extraPopContent.push({
        content: 'Reject',
        action: () => {
          setShowRejectPrompt(true);
        },
        requires: PermissionCodes.publishPendingCampaign,
      },)
    }
    return extraPopContent
  }

  const primaryText = getSaveText();

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <ContentSection />
          <ContentSections sections={sections()} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <BackAndMultipleButtons
        backAction={() => stepChangeAction(false, true)}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              hasPermission(tempRequires()) ?
                <SaveTempButton
                  text={getTempText()}
                  action={() => {
                    stepChangeAction(false, false, tempActionType);
                  }}
                /> : null,
              approvalStatus === ApprovalStatus.pending && hasPermission(PermissionCodes.publishPendingCampaign) ?
                <SaveTempButton
                  text="Reject"
                  action={() => {
                    setShowRejectPrompt(true);
                  }}
                /> : null,
            ]}
            extraPopContent={getExtraPopContent()}
            primaryButton={
              hasPermission(saveRequires()) ?
                <SaveButton
                  text={primaryText}
                  action={() =>
                    stepChangeAction(approvalStatus === ApprovalStatus.published ? false : true, false, saveActionType)
                  }
                  disabled={saveActionType === ActionType.pendingForApproval ? true : false}
                /> : null
            }
            primaryPopContent={{
              requires: saveRequires(),
              action: () => stepChangeAction(approvalStatus === ApprovalStatus.published ? false : true, false, saveActionType),
              content: primaryText,
            }}
          />
        ]}
      />

      <RejectPrompt
        show={showRejectPrompt}
        onHide={() => setShowRejectPrompt(false)}
        onConfirm={(comment) => {
          setShowRejectPrompt(false);
          dispatch(
            createAction('createCampaign/rejectCampaign')({
              id: params.id,
              message: comment,
              afterAction: () => {
                const pathname = location.pathname.split('/')[1];
                history.push({
                  pathname: `/${pathname}`
                })
              },
            }),
          )
        }}
      />

      <PublishCampaignPrompt
        campaignId={campaignId}
        campaignType={campaignType}
        actionType={actionType}
        show={showPublishPrompt}
        path={listpath}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  createStatus: state.createCampaign.createStatus,
  formHasSubmitted: state.createCampaign.formHasSubmitted,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
  campaignId: state.createCampaign.campaign?.pk,
})

export default connect(mapPropsToState)(CreateCampaignStepFour);

import { loading } from './LoadingUtil';
import { getProducts } from '../services/ShopifyAPIHelper';

const getInitialState = () => ({
  productSkus: [],
  pageInfo: {
    startCursor: "",
    endCursor: "",
    hasPreviousPage: false,
    hasNextPage: false,
  },
});

const assembleProduct = (node) => {
  if (!node) {
    return {};
  }
  return {
    pk: node.id,
    name: node.title,
  };
};

export default {
  namespace: 'shopify',
  state: { ...getInitialState() },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },

    assembleProductList(state, { payload }) {
      const pageInfo = payload.pageInfo;
      let productSkus = [];
      const productList = payload.productList.map(({ node }) => {
        if (!node) {
          return {}
        }
        const variants = node.variants?.edges?.map((variantNode) => {
          const variant = variantNode.node;
          if (variant?.sku) {
            productSkus.push({
              pk: variant.id,
              name: variant.sku,
            });
          }
          return variant
        })
        return assembleProduct(node);
      });
      console.log("@@67", payload)
      console.log("@@68", productList, productSkus)

      return {
        ...state,
        productSkus: payload?.loadMore 
        ? [
          ...state.productSkus,
          ...productSkus,
        ]
        : productSkus,
        pageInfo: {
          startCursor: pageInfo.startCursor,
          endCursor: pageInfo.endCursor,
          hasPreviousPage: pageInfo.hasPreviousPage,
          hasNextPage: pageInfo.hasNextPage,
        }
      };
    },
  },
  effects: {
    getProductList: [
      function* ({ payload }, { put, call }) {
        const serviceArgs = [getProducts, {}];
        function* onSuccess(data) {
          const products = data?.shopifyProducts;
          yield put({
            type: 'assembleProductList',
            payload: {
              productList: products?.edges || [],
              pageInfo: products?.pageInfo || {},
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],

    // get product list more not use, just for further updates
    getProductListMore: [
      function* ({ payload }, { put, select, call }) {
        const {
          endCursor,
          hasNextPage,
        } = yield select((state) => ({
          endCursor: state.shopify.pageInfo.endCursor,
          hasNextPage: state.shopify.pageInfo.hasNextPage,
        }));

        console.log("@@getProductListMore", endCursor, hasNextPage)
        if (!hasNextPage) {
          return;
        }

        const serviceArgs = [getProducts, { productAfter: endCursor }];
        function* onSuccess(data) {
          const products = data?.shopifyProducts;
          yield put({
            type: 'assembleProductList',
            payload: {
              productList: products?.edges || [],
              pageInfo: products?.pageInfo || {},
              loadMore: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],
  },
};

import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import BackgroundLong from '../../assets/images/slide-3-background-long.svg';
import BackgroundMiddle from '../../assets/images/slide-3-background-middle-mask.svg';
import OrLong from '../../assets/images/slide-or-long.svg';
import OrMiddle from '../../assets/images/slide-or-middle.svg';
import './OnboardingSlideThree.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonConfig } from './OnboardingConfig';

function OnboardingSlideThree({ width, height }) {
  const isSmall = width < 1100;
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentHover, setCurrentHover] = useState(null);

  const onHover = (type) => {
    setCurrentHover(type);
  };

  const getButtonItems = (config) => {
    return (
      <>
        <Image src={config.imageOff} height={0} width={0} />
        <div className={`button-item-container`}>
          <div
            className={`button-item`}
            style={{
              backgroundColor:
                config.type === currentHover
                  ? config.backgroundColorOff
                  : config.backgroundColorOn,
            }}
            onMouseOver={() => onHover(config.type)}
            onMouseLeave={() => onHover(null)}
            onClick={() => {
              history.push(config.location);
              dispatch({
                type: 'users/updateState',
                payload: { firstLogin: false },
              });
            }}
          >
            <Image
              className={`button-icon ${
                isSmall ? 'button-icon-short' : 'button-icon-short'
              }`}
              src={
                config.type === currentHover ? config.imageOff : config.imageOn
              }
            />
            <label
              style={{
                color:
                  config.type === currentHover
                    ? config.textColorOff
                    : config.textColorOn,
              }}
              className={`button-text`}
            >
              {config.des}
            </label>
          </div>
          <label className="button-title">{config.title}</label>
        </div>
      </>
    );
  };

  return (
    <div className="onboarding-slide-3">
      <div className={`text-container`}>
        <label className="content-title">
          Before you create campaign maybe you need...
        </label>
        <label className="content-text">
          If you want to create a coupon campaign or earning campaign, you need
          create an earning rule or coupon set to support your campaign. And if
          you want to create a general message campaign, you can select “Create
          general message campaign” directly!
        </label>
      </div>
      <div
        className={`${
          isSmall ? 'button-area-middle' : 'button-area-long'
        } button-area`}
      >
        {getButtonItems(ButtonConfig.coupon)}
        {getButtonItems(ButtonConfig.earning)}
        <Image
          className={isSmall ? 'item-or-image-middle' : 'item-or-image-long'}
          src={isSmall ? OrMiddle : OrLong}
        />
        {getButtonItems(ButtonConfig.general)}
      </div>
      <Image
        src={isSmall ? BackgroundMiddle : BackgroundLong}
        className="slide-background-image"
      />
    </div>
  );
}

export default OnboardingSlideThree;

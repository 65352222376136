import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';


function StampRecordDetail({ stampRecord }) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'stampRecord/getOneDetail',
      payload: {
        id: params.id,
      },
    });
  }, [dispatch, params.id]);

  const getStampDetailSectionFieldsWithType = (recordType) => {
    let extraFields = [];
    if (recordType === 'STAMP_EXPIRED') {
      extraFields = extraFields.concat([
        [{ title: 'Expiry date', field: 'createDate' }],
      ]);
    }
    return [
      [{ title: 'Quantity', field: 'quantity' }],
      [{ title: 'Remarks (optional)', field: 'remark' }],
      ...extraFields,
    ];
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={'STAMP RECORD DETAILS'}
                data={stampRecord}
                fields={[
                    [{title:'Customer', field:'name'}],
                    [{title:'Event Type', field:'displayEventType'}],
                    [{title:'Record Type', field:'displayRecordType'}],
                ]}
              />,
              <MembershipInfoCard
                title={stampRecord.displayEventType}
                data={stampRecord}
                fields={getStampDetailSectionFieldsWithType(
                  stampRecord.recordType,
                )}
              />,
            ]}
            hidePreview={true}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <CustomListComponent 
      caution={{
        detail:'',
        title:'Stamp record details'
      }}
      breadcrumb={<CustomBreadcrumb />} 
      hideTab={true}
      tabs={tabs}
      buttons={[]}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/stamp_records',
          });
        }}
      />
    </>
  );
}
const mapPropsToState = (state) => ({
  stampRecord: state.stampRecord.detail,
});

export default connect(mapPropsToState)(StampRecordDetail);

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import './CreateStoreCategory.scss';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseForm from '../../../components/base/v2/BaseForm';
import {
  APIStatus,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import {
  anchorElementWithId,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import { STORE_CATEGORY_SESSION_KEY } from '../../../models/CreateStoreCategoryModel';
import {
  getStoreCreateSuccessStepBar,
  CREATE_STORE_CATEGORY,
} from '../../../components/base/prompt/NextStepConfig';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CreateStoreCategoryStepOne from './CreateStoreCategoryStepOne';


const CreateStoreCategory = ({
  errorFields,
  category,
  createStatus,
  hasUpdatedDefaultValues,
  languages,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Need fill in the category name and display order
        title: category.name,
        model: 'createStoreCategory',
      };
    } else {
      return {
        detail: '', //Need fill in the category name and display order
        title: 'Create store category',
        model: 'createStoreCategory',
      };
    }
  };
  const [showDeletePrompt, setShowDeletePrompt] = React.useState(false);

  React.useEffect(() => {
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true }
    });
  }, [dispatch]);

  React.useEffect(() => {
    const categoryID = params.id;
    if (categoryID) {
      dispatch({
        type: 'createStoreCategory/getCategory',
        payload: { id: categoryID }
      });
    } else {
      dispatch({
        type: 'createStoreCategory/loadStoreCategoryFromSessionStorage',
      });
    }
    return () => {
      dispatch({ type: 'createStoreCategory/reset' });
    };
  }, [dispatch, params.id]);
  React.useEffect(() => {
    if (
      createStatus === APIStatus.success &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/store_category');
      return;
    }
  }, [createStatus, category, history]);
  React.useEffect(() => {
    if (errorFields && errorFields.length > 0) {
      setTimeout(() => {
        anchorElementWithId(errorFields[0].field);
      }, 100);
    }
  }, [errorFields]);

  if (category.subCategories && category.subCategories.length > 0) {
  } else {
    let translations = {};
    (languages || []).forEach((item) => {
      translations[item.code] = {
        name: null
      };
    });
    category.subCategories = [
      {
        id: null,
        name: null,
        order: null,
        translations: translations
      },
    ];
  }
  console.log('kevin@167', category);

  const stepContent = [
    <CreateStoreCategoryStepOne
      setShowDeletePrompt={setShowDeletePrompt}
    />
  ];

  return (
    <div className="campaign-category">
      <BaseForm
        defaultValues={category}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={createStatus === APIStatus.success}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(STORE_CATEGORY_SESSION_KEY, {
              ...getValues()
            });
          } else {
            removeFromSessionStorage(STORE_CATEGORY_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description:
            'Store category is successfully created. Let’s create a store to apply this store category.',
          steps: {
            stepNames: getStoreCreateSuccessStepBar(true),
            current: CREATE_STORE_CATEGORY,
          },
          onHide:()=>{},
          buttons: [
            {
              text: 'Create store',
              action: () => {
                history.push({
                  pathname: '/stores/create',
                  search: `category=${category?.pk}`,
                });
              },
              requires: PermissionCodes.addStore,
            },
            {
              text: 'Maybe Later',
              action: () => history.push('/store_category'),
              requires: PermissionCodes.changeStorecategory,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/store_category'),
          onClose: () => history.push('/store_category'),
        }}
        content={stepContent}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        caution={caution()}
      />

      <DeletePrompt
        message={{
          title: 'Delete this store category',
          content: 'Do you confirm to delete this store category?',
        }}
        title={'Store category'}
        data={category}
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'createStoreCategory/deleteCategories',
            payload: {
              ids: [params.id],
            }
          });
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  errorFields: state.createStoreCategory.errorFields,
  category: state.createStoreCategory.category,
  createStatus: state.createStoreCategory.createStatus,
  hasUpdatedDefaultValues: state.createStoreCategory.hasUpdatedDefaultValues,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateStoreCategory);

import React from 'react';
import { Image } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { listMultiItem } from 'components/banners/DisplayItems';
import { getAllCampaignTypeTitle } from 'components/campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { WEEKDAY } from 'config/CustomEnums';
import { GENERAL_TYPE } from 'models/CreateCampaignModel';

import { getReadablePeriod } from 'utils/TimeFormatUtil';

import CheckOnIcon from 'assets/images/check_on.svg';

import '../../campaign/campaignCreation/ProfileSection.scss';

function ProfileSection({ data }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};
  const getListStrings = (list) => {
    if (list?.length === 0) {
      return null;
    }
    return (
      <ul>
        {list?.map((item) => (
          <li>{`[ID: ${item.pk}] ${item.name}`}</li>
        ))}
      </ul>
    );
  };

  const profileField = (field, value, showShortDescription = false) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <label className="create-section-field-input profile-section-label-bottom">
          {value || '-'}
        </label>
        {showShortDescription ? (
          <div className="display-over-all-limit-container">
            <Image src={CheckOnIcon} className="display-over-all-limit-icon" />
            <label className="display-over-all-limit">
              Display the overall limit in the campaign.
            </label>
          </div>
        ) : null}
      </>
    );
  };

  const getLinkedCouponSets = (rewardList) => {
    const result = [];
    if (!rewardList) {
      return result;
    }
    // eslint-disable-next-line array-callback-return
    rewardList.map((reward) => {
      if (reward.linkedCoupon) {
        result.push(reward.linkedCoupon);
      }
    });
    return result;
  };

  return (
    <>
      <label className="create-section-title">PROPERTIES</label>
      {profileField(
        'Link to Earning rules',
        getListStrings(campaign.linkedEarningRules),
      )}
      {profileField(
        'Link to coupon',
        getListStrings(getLinkedCouponSets(campaign.stampRewards)),
      )}
      {console.log('total stamp', campaign.totalStampQuantity)}
      {profileField('Total stamp quantity', campaign.totalStampQuantity)}
      {profileField(
        'Campaign type',
        getAllCampaignTypeTitle(campaign.campaignType),
      )}
      {profileField('Campaign category', getListStrings(campaign.categories))}
      {profileField(
        'Target customer groups',
        getListStrings(
          campaign.generalType === GENERAL_TYPE.customerGroup
            ? campaign.customerGroup
            : [],
        ),
      )}
      {profileField(
        'Segments',
        getListStrings(
          campaign.generalType === GENERAL_TYPE.segment
            ? campaign.segments
            : [],
        ),
      )}
      {profileField(
        'Active period',
        getReadablePeriod(
          campaign.activeStartDate,
          campaign.activeEndDate,
          campaign.isAlwaysActivePeriod,
        ),
      )}
      {listMultiItem(
        'Blackout period (by period)',
        campaign.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false),
        ),
      )}
      {listMultiItem(
        'Blackout period (by weekday)',
        campaign.activeWeekday?.map((item) => WEEKDAY[item]),
      )}
      {profileField(
        'Visible period',
        getReadablePeriod(
          campaign.visibleStartDate,
          campaign.visibleEndDate,
          campaign.isAlwaysVisiblePeriod,
        ),
      )}
      {profileField('Related brand', campaign.linkedBrand?.name)}
    </>
  );
}

export default ProfileSection;

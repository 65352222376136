import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './LanguageTab.scss';
import { LanguageConfig } from '../../config/CustomEnums';
function LanguageTabs(props) {
  const enContainer = props.enContainer;
  const hantContainer = props.hantContainer;
  const hansContainer = props.hansContainer;
  const containers = props.containers || [
    { container: enContainer, key: LanguageConfig.english, title: 'English' },
    {
      container: hantContainer,
      key: LanguageConfig.traditionalChinese,
      title: '繁體',
    },
    {
      container: hansContainer,
      key: LanguageConfig.simplifiedChinese,
      title: '简体',
    },
  ];
  const languageTabsStyle = props.languageTabsStyle || '';
  const languageTabStyle = props.languageTabStyle || '';
  const error = props.fieldsError;
  const errorLanguage = props.errorLanguage || LanguageConfig.english;
  const [activeTab, setActiveTab] = useState(LanguageConfig.english);
  useEffect(() => {
    if (error) {
      setActiveTab(errorLanguage);
    }
  }, [error]);
  return (
    <Tabs
      className={`language-tab-container ${languageTabsStyle}`}
      activeKey={activeTab}
      onSelect={(key) => setActiveTab(key)}
    >
      {containers.map((item, index) => (
        <Tab 
          tabClassName={index === 0 ? languageTabStyle+index : languageTabStyle}
          eventKey={item.key}
          title={item.title} 
          key={item.key}
        >
          {item.container}
        </Tab>
      ))}
    </Tabs>
  );
}

export default LanguageTabs;

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import CreatePointRecordGeneral from './CreatePointRecordGeneral';
import CreatePointRecordDetail from './CreatePointRecordDetail';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import { validate } from '../../containers/record/pointRecords/CreatePorintRecordValidate';


function CreatePointRecordStepTwo() {
  const dispatch = useDispatch();

  const { getValues, clearErrors, setError } = useFormContext();


  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    // dispatch({
    //   type: 'createPointRecord/stepChange',
    //   payload: {
    //     isBack: isBack,
    //     step: 1,
    //     isValid: isValid,
    //   },
    // });
    if (!isBack && isValid) {
      dispatch({
        type: 'createPointRecord/createPointRecord',
        payload: {
          values: getValues(),
        }
      });
    }
  };

  const firstSection = [
    <CreatePointRecordGeneral />,
    <CreatePointRecordDetail />,
  ];

  return (
    <>
      <ContentSections
        sections={firstSection}
        hidePreview={true}
      />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          dispatch({
            type: 'createPointRecord/stepChange',
            payload: {
              isBack: true,
              step: 1,
              isValid: true,
            },
          });
        }}
        saveText={'Save'}
        saveAction={() => {
          stepChangeAction(false);
        }}
      />
    </>
  );
}

const mapPropsToState = () => ({
});

export default connect(mapPropsToState)(CreatePointRecordStepTwo);

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  BackAndMultipleButtons,
  SaveButton,
  SaveTempButton,
} from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import Loading from 'components/base/Loading';
import ApprovalCheckList from 'components/campaign/campaignCreation/ApprovalCheckList';
import PublishCampaignPrompt from 'components/campaign/campaignCreation/PublishCampaignPrompt';
import RejectPrompt from 'components/campaign/campaignCreation/RejectPrompt';
import ContentSection from 'components/stampCampaign/stampCampaignCreation/ContentSection';
import ProfileSection from 'components/stampCampaign/stampCampaignCreation/ProfileSection';
import { APIStatus, ActionType, ApprovalStatus, CampaignType } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { validate } from 'containers/engagement/campaign/CreateCampaignValidate';

import { createAction } from 'utils';

import '../../campaign/campaignCreation/CreateCampaignStepFour.scss';

function CreateStampCampaignStepThree({
  createStatus,
  isSuperuser,
  userPermissions,
  formHasSubmitted,
  campaignId,
}) {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();
  const location = useLocation();
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [showRejectPrompt, setShowRejectPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const approvalStatus = getValues('approvalStatus');
  let saveActionType = null;
  let tempActionType = null;

  const dispatch = useDispatch();
  const history = useHistory();
  const sections = [<ProfileSection />, <ApprovalCheckList />];
  const hasPermission = (requires) => {
    if (isSuperuser) return true;
    if (userPermissions.includes(requires) || !requires) return true;

    return false;
  };

  useEffect(() => {
    if (createStatus === APIStatus.success && formHasSubmitted) {
      setShowPublishPrompt(
        actionType !== ActionType.approval ||
          history.location.pathname.includes('edit'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus, formHasSubmitted]);

  const stepChangeAction = (checkCheckList, isBack, buttonAction = null) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
      checkCheckList,
    });
    if (!isValid || isBack) {
      dispatch({
        type: 'createCampaign/stepChange',
        payload: { isBack: isBack, step: 2, isValid, isStamp: true },
      });
    } else {
      setActionType(buttonAction);
      if (
        buttonAction === ActionType.withdraw ||
        buttonAction === ActionType.unpublish
      ) {
        dispatch(
          createAction('createCampaign/updateApprovalStatus')({
            data: getValues(),
            actionType: buttonAction,
          }),
        );
      } else {
        dispatch(
          createAction('createCampaign/createOrUpdateCampaign')({
            data: getValues(),
            actionType: buttonAction,
            resume: location.state?.resume,
          }),
        );
      }
    }
  };

  const getTempText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        tempActionType = ActionType.update;
        return 'Update';
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          tempActionType = ActionType.update;
          return 'Update';
        }
        tempActionType = ActionType.withdraw;
        return 'Withdraw';
      case ApprovalStatus.published:
        tempActionType = ActionType.tempForPulished;
        return 'Update';
      default:
        tempActionType = ActionType.save;
        return 'Save as a draft';
    }
  };

  const tempRequires = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return PermissionCodes.editDraftCampaign;
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          return PermissionCodes.publishPendingCampaign;
        }
        return PermissionCodes.withdrawPendingCampaignToDraft;
      case ApprovalStatus.published:
        return PermissionCodes.tempForPublishCampaign;
      default:
        return PermissionCodes.createDraftCampaign;
    }
  };

  const getSaveText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.published:
        saveActionType = ActionType.unpublish;
        return 'Unpublish';
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          saveActionType = ActionType.approval;
          return 'Approve';
        }
        saveActionType = ActionType.pendingForApproval;
        return 'Pending for approval';
      default:
        saveActionType = ActionType.submitForApproval;
        return 'Submit for approval';
    }
  };

  const saveRequires = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return PermissionCodes.pendingCampaign;
      case ApprovalStatus.pending:
        if (hasPermission(PermissionCodes.publishPendingCampaign)) {
          return PermissionCodes.publishPendingCampaign;
        }
        return PermissionCodes.withdrawPendingCampaignToDraft;
      case ApprovalStatus.published:
        if (hasPermission(PermissionCodes.withdrawPublishCampaignToPending)) {
          return PermissionCodes.withdrawPublishCampaignToPending;
        }
        return PermissionCodes.withdrawPublishCampaignToDraft;
      default:
        return PermissionCodes.pendingCampaign;
    }
  };

  const getExtraPopContent = () => {
    const extraPopContent = [
      {
        content: getTempText(),
        action: () => stepChangeAction(false, false, tempActionType),
      },
    ];

    if (approvalStatus === ApprovalStatus.pending) {
      extraPopContent.push({
        content: 'Reject',
        action: () => {
          setShowRejectPrompt(true);
        },
        requires: PermissionCodes.publishPendingCampaign,
      });
    }
    return extraPopContent;
  };

  const primaryText = getSaveText();

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <ContentSection />
          <ContentSections sections={sections} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <BackAndMultipleButtons
        backAction={() => stepChangeAction(false, true)}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              hasPermission(tempRequires()) ? (
                <SaveTempButton
                  text={getTempText()}
                  action={() => {
                    stepChangeAction(false, false, tempActionType);
                  }}
                />
              ) : null,
              approvalStatus === ApprovalStatus.pending &&
              hasPermission(PermissionCodes.publishPendingMessage) ? (
                <SaveTempButton
                  text="Reject"
                  action={() => {
                    setShowRejectPrompt(true);
                  }}
                />
              ) : null,
            ]}
            extraPopContent={getExtraPopContent()}
            primaryButton={
              hasPermission(saveRequires()) ? (
                <SaveButton
                  text={primaryText}
                  action={() =>
                    stepChangeAction(
                      approvalStatus === ApprovalStatus.published
                        ? false
                        : true,
                      false,
                      saveActionType,
                    )
                  }
                  disabled={
                    saveActionType === ActionType.pendingForApproval
                      ? true
                      : false
                  }
                />
              ) : null
            }
            primaryPopContent={{
              requires: saveRequires(),
              action: () =>
                stepChangeAction(
                  approvalStatus === ApprovalStatus.published ? false : true,
                  false,
                  saveActionType,
                ),
              content: primaryText,
            }}
          />,
        ]}
      />

      <RejectPrompt
        show={showRejectPrompt}
        onHide={() => setShowRejectPrompt(false)}
        onConfirm={(comment) => {
          setShowRejectPrompt(false);
          dispatch(
            createAction('createCampaign/rejectCampaign')({
              id: params.id,
              message: comment,
              afterAction: () => {
                const pathname = location.pathname.split('/')[1];
                history.push({
                  pathname: `/${pathname}`,
                });
              },
            }),
          );
        }}
      />

      <PublishCampaignPrompt
        campaignId={campaignId}
        campaignType={CampaignType.stampCampaign}
        actionType={actionType}
        show={showPublishPrompt}
        path={'/stamp_campaigns'}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  createStatus: state.createCampaign.createStatus,
  formHasSubmitted: state.createCampaign.formHasSubmitted,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
  campaignId: state.createCampaign.campaign?.pk,
});

export default connect(mapPropsToState)(CreateStampCampaignStepThree);

import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ToastType } from 'models/NavBarModel';

import './SaveDiscardToast.scss';

function SaveDiscardToast() {
  const { saveDiscardToastShowing } = useSelector((state) => ({
    saveDiscardToastShowing: state.navBars.saveDiscardToastShowing,
  }));
  const [show, setShow] = useState(saveDiscardToastShowing.value);
  const dispatch = useDispatch();
  useEffect(() => {
    setShow(saveDiscardToastShowing.value);
  }, [saveDiscardToastShowing.value]);

  const onToastHide = () => {
    setShow(false);
    dispatch({
      type: 'navBars/updateState',
      payload: {
        saveDiscardToastShowing: { value: false, type: ToastType.none },
      },
    });
  };

  return (
    <div className="save-success-toast-container">
      <Toast
        className="save-toast-main-content"
        onClose={onToastHide}
        show={show}
        delay={1200}
        autohide
      >
        <Toast.Body className="save-toast-body">
          <label className="save-toast-text">
            {saveDiscardToastShowing.type}
          </label>
          <label onClick={onToastHide} className="save-toast-button">
            Close
          </label>
        </Toast.Body>
      </Toast>
    </div>
  );
}

export default SaveDiscardToast;

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ActionsDropdownForItem } from 'components/base/ActionsDropdown';
import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import CustomRadios from 'components/base/CustomRadios';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import { ImportResource } from 'models/DownloadImportModel';

import Filter from './Filter';

import { createAction, getHashKeyString } from 'utils';

const TAB_RECORDS = 'Records';
const TAB_REPORTS = 'Reports';

export const POINT_TRANSACTION_TYPE = {
  CUSTOMER_EARN: 'Customer earn',
  CUSTOMER_REDEEM_GIFT_CARD: 'Gift card point',
  CUSTOMER_USED: 'Customer use',
  CUSTOMER_USED_CASH_DOLLAR: 'Customer use(for cash dollar)',
  ADMIN_EDITED: 'Admin edit',
  ADMIN_VOIDED: 'Admin void',
  EXPIRED: 'Point expired',
};

function PointTransactionList({
  displayFields,
  pointTransactionList,
  totalCount,
  pageInfo,
  totalPage,
  reportListDisplayFields,
  pointTransactionReportList,
  checkedList,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showDownloadReportPrompt, setShowDownloadReportPrompt] =
    useState(false);
  const [isExportAllReports, setIsExportAllReports] = useState(true);

  const activeTab = getHashKeyString(location.hash) || TAB_RECORDS;

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const pointType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const reportType = searchDict['report_type'];
    const timeRange = searchDict['time_range'];
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    if (activeTab === TAB_RECORDS) {
      dispatch({
        type: 'pointTransactionList/getCurrentPagePointRecords',
        payload: {
          ...searchDict,
          pointType,
          searchKey,
          rank,
          page,
        },
      });
    }
    if (activeTab === TAB_REPORTS) {
      dispatch({
        type: 'pointTransactionList/getCurrentPagePointReports',
        payload: {
          ...searchDict,
          reportType,
          timeRange,
          searchKey,
          rank,
          page,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, queryString]);

  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const createDatePeriod = parsedSearch?.create_date?.split(',');
    const pointTransactionListIn = (checkedList || []).map((item) => item.pk);

    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.pointRecord,
        related: {
          pointTypeIn: parsedSearch?.type?.split(','),
          transactionTypeIn: parsedSearch?.record_type?.split(','),
          creationDateGte: createDatePeriod?.[0],
          creationDateLte: createDatePeriod?.[1],
          valueGte: parsedSearch?.value?.split(',')?.[0],
          valueLte: parsedSearch?.value?.split(',')?.[1],
          customerNameIcontains: parsedSearch?.search,
          pointTransactionListIn: pointTransactionListIn,
        },
      },
    });
  };
  const updatePointAction = () => {
    history.push('/point_records/create');
  };

  const exportReportAction = () => {
    const searchDict = queryString.parse(location.search);
    const reportType = searchDict['report_type'];
    const timeRange = searchDict['time_range']?.split(',');
    const nameIcontains = searchDict['search'];
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.pointReport,
        ...(isExportAllReports || activeTab !== TAB_REPORTS
          ? null
          : {
              related: {
                nameIcontains,
                reportType,
                timeRangeGte: timeRange?.[0],
                timeRangeLte: timeRange?.[1],
              },
            }),
      }),
    );
    setShowDownloadReportPrompt(false);
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Export report"
          customClass="btn-back-button-common btn-download"
          action={() => {
            setShowDownloadReportPrompt(true);
          }}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title="Export .csv"
          customClass="btn-back-button-common btn-download"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => {
            setShowDownloadReportPrompt(true);
          },
          content: 'Export report',
        },
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: 'Export .csv',
        },
      ]}
      primaryButton={
        <AuthButton
          title="Update Point Records"
          action={updatePointAction}
          requires={[
            PermissionCodes.addCustomerPoints,
            PermissionCodes.removeCustomerPoints,
          ]}
        />
      }
      primaryPopContent={{
        requires: [
          PermissionCodes.addCustomerPoints,
          PermissionCodes.removeCustomerPoints,
        ],
        action: updatePointAction,
        content: 'Update Point Records',
      }}
    />,
  ];

  const typeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'TYPE_POINT_ADD', name: 'Add Points' },
    { key: 'TYPE_POINT_REMOVE', name: 'Remove Points' },
  ];

  const reportTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'WEEKLY', name: 'Weekly' },
    { key: 'MONTHLY', name: 'Monthly' },
  ];

  const tabs = [
    {
      name: TAB_RECORDS,
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={300}
          tabs={typeTabs}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              dataList={pointTransactionList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              model={'pointTransactionList'}
              permissionGroup={PermissionCodes.pointtransaction}
              actions={['Detail']}
              hideActions={false}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
    {
      name: TAB_REPORTS,
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={300}
          tabs={reportTypeTabs}
          tabSearchKey={'report_type'}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          searchPlaceholder={'Search by report name'}
          listContent={
            <BaseListContainer
              fields={reportListDisplayFields}
              dataList={pointTransactionReportList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              model={'pointTransactionList'}
              permissionGroup={PermissionCodes.pointtransaction}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Download',
                    requires: PermissionCodes.addExportjob,
                    href: item?.reportFileUrl,
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  const otherBody = () => {
    return (
      <div className="d-flex">
        <CustomRadios
          onChange={(value) => {
            setIsExportAllReports(value);
          }}
          default={isExportAllReports}
          options={[
            {
              label: 'All items',
              value: true,
            },
            {
              label: 'All filtered items from the list',
              value: false,
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All point records are listed here, you can view the point transactions of each customer.',
          title: 'Point records',
        }}
        defaultActiveKey={activeTab}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={false}
        tabs={tabs}
        onTabChange={(key) => {
          history.push({ pathname: location.pathname, hash: key });
        }}
      />
      <BasePrompt
        customClass={'export-report-modal'}
        show={showDownloadReportPrompt}
        title={'Export report'}
        description={'Select the export range'}
        closeAction={() => {
          setShowDownloadReportPrompt(false);
        }}
        otherBody={otherBody}
        rightButton={{
          text: 'Export',
          action: exportReportAction,
          requires: PermissionCodes.addExportjob,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  displayFields: state.pointTransactionList.listDisplayFields,
  pointTransactionList:
    state.pointTransactionList.currentPagePointTransactionList,
  totalCount: state.pointTransactionList.totalCount,
  pageInfo: state.pointTransactionList.pageInfo,
  totalPage: state.pointTransactionList.totalPage,
  reportListDisplayFields: state.pointTransactionList.reportListDisplayFields,
  pointTransactionReportList:
    state.pointTransactionList.currentPagePointTransactionReportList,
  checkedList: state.pointTransactionList.checkedList,
});

export default connect(mapPropsToState)(PointTransactionList);

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import { validate } from 'containers/record/records/CreateTransactionValidate';

import TransactionAmountSection from './TransactionAmountSection';
import TransactionGeneralSection from './TransactionGeneralSection';
import TransactionAdditionalData from './TransactionAdditionalData';
import { TRANSACTION_EVENT_TYPE } from 'containers/record/records/TransactionList';
import TransactionRefundSection from './TransactionRefundSection';

function CreateTransactionStepTwo() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { getValues, clearErrors, setError, watch } = useFormContext();

  const displayOfflineEventType = watch('displayOfflineEventType');

  const showRefundSection = displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_REFUNDED ||
    displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED

  useEffect(() => {
    dispatch({
      type: 'admin/getAllAdmins',
      payload: {},
    });
  }, [dispatch]);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack: isBack,
    });
    dispatch({
      type: 'createTransaction/stepChange',
      payload: {
        isBack: isBack,
        step: 1,
        isValid,
      },
    });
  };

  const sections = [
    <TransactionGeneralSection />,
    <TransactionAdditionalData title="ADDITIONAL DATA (optional)"/>,
    <TransactionAmountSection />,
    showRefundSection ? <TransactionRefundSection /> : null
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      {location.pathname.includes('edit') ? (
        <OnlyContinueButton
          disabledContinue={false}
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backAction={() => {
            stepChangeAction(true);
          }}
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
      )}
    </>
  );
}

export default CreateTransactionStepTwo;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';
import {
  ApprovalStatus,
  MessageBackendChannel,
  MessageChannel,
} from 'config/CustomEnums';

import { ArrayStringData } from 'utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchMessageChannel = parsedSearch['message_channel'] || '';
  const searchDeliveryTime = parsedSearch['delivery_time'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchMessageChannel),
  );
  const [deliveryTime, setDeliveryTime] = useState(searchDeliveryTime);
  const [status, setStatus] = useState(ArrayStringData(searchStatus));

  const content = [
    {
      title: 'Message channel',
      data: [
        {
          name: MessageChannel.all,
          pk: MessageBackendChannel.all,
        },
        {
          name: MessageChannel.allWhatsapp,
          pk: MessageBackendChannel.allWhatsapp,
        },
        {
          name: MessageChannel.push,
          pk: MessageBackendChannel.push,
        },
        {
          name: MessageChannel.email,
          pk: MessageBackendChannel.email,
        },
        {
          name: MessageChannel.sms,
          pk: MessageBackendChannel.sms,
        },
        {
          name: MessageChannel.whatsapp,
          pk: MessageBackendChannel.whatsapp,
        },
        {
          name: MessageChannel.inbox,
          pk: MessageBackendChannel.inbox,
        },
      ],
      value: messageChannel,
      setValue: setMessageChannel,
      component: FilterTypeEnum.choice,
    },
    {
      title: 'Status',
      data: [
        { name: 'Draft', pk: ApprovalStatus.draft },
        { name: 'Pending for approval', pk: ApprovalStatus.pendingForApproval },
        { name: 'Schedule', pk: ApprovalStatus.published },
        { name: 'Sending', pk: 'STATE_SENDING' },
        { name: 'Sent', pk: 'STATE_COMPLETED' },
        { name: 'Fail', pk: 'STATE_ERROR' },
      ],
      value: status,
      setValue: setStatus,
      component: FilterTypeEnum.choice,
    },
    {
      title: 'Delivery time',
      value: deliveryTime,
      setValue: setDeliveryTime,
      component: FilterTypeEnum.datetime,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SaveAndBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import DeletePrompt from 'components/base/DeletePrompt';
import Loading from 'components/base/Loading';
import WebviewSection from 'components/webview/WebviewSection';
import { APIStatus } from 'config/CustomEnums';

function CreateWebviewStepOne() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { getValues, trigger } = useFormContext();

  const { webview, createStatus, languages } = useSelector((state) => ({
    webview: state.webview.detail,
    createStatus: state.webview.createStatus,
    languages: state.language.allList,
  }));

  useEffect(() => {
    dispatch({ type: 'language/getList', payload: { isSelectorLoad: true } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      window.onbeforeunload = null;
      goToWebviewList();
    }
  });

  const goToWebviewList = () => {
    dispatch({
      type: 'navBars/updateState',
      payload: {
        saveDiscardToastShowing: {
          value: false,
        },
      },
    });
    history.push('/webview');
  };

  return (
    <>
      {createStatus === APIStatus.calling ? (
        <Loading />
      ) : (
        <ContentSections
          languageTabContent={{
            containers: languages?.map((item) => {
              return {
                container: [<WebviewSection language={item.code} />],
                key: item.code,
                title: item.sourceName,
              };
            }),
          }}
          hidePreview
        />
      )}
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          let isValid = await trigger(['name', 'translations.en.description'], {
            shouldFocus: true,
          });
          if (isValid) {
            let uploadData = {
              values: getValues(),
            };
            if (params.id) {
              uploadData = {
                values: getValues(),
                webviewId: params.id,
              };
            }
            dispatch({
              type: 'webview/createOrUpdateWebview',
              payload: uploadData,
            });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
      <DeletePrompt
        message={{
          title: 'Delete this webview content.',
          content: 'Do you confirm to delete this webview content?',
        }}
        title={'Webview Content'}
        data={webview}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'webview/updateState',
            payload: { checkedList: [webview] },
          });
          dispatch({
            type: 'webview/delete',
            payload: { afterAction: goToWebviewList },
          });
        }}
      />
    </>
  );
}

export default CreateWebviewStepOne;

import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getDashboardData = (startDate, endDate) => {
  console.log(startDate, endDate);
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCustomers {
        value
        change
      }
      totalNumberOfNewCustomers {
        value
        change
      }
      totalNumberOfNewCustomersChart {
        periods
        points
      }
      totalNumberOfActiveCustomers {
        value
        change
      }
      totalNumberOfCouponActivities {
        value
        change
      }
      totalNumberOfCouponsRedeemedByPoints {
        value
        change
      }
      totalNumberOfCouponsAcquiredByCampaigns {
        value
        change
      }
      totalNumberOfCouponsUsed {
        value
        change
      }
      totalNumberOfPublishedCampaigns {
        value
        change
      }
      totalNumberOfCampaignConversions {
        value
        change
      }
      totalNumberOfCampaignViews {
        value
        change
      }
      campaignShareRate {
        value
        change
      }
      totalNumberOfPublishedStampCampaign{
        value
        change
      }
      totalNumberOfStampCampaignConversion{
        value
        change
      }
      stampCampaignConversionRate{
        value
        change
      }
      stampCampaignTotalNumberOfView{
        value
        change
      }
      stampCampaignShareRate{
        value
        change
      }
      stampEarnedInStampCampaign{
        value
        change
      }
      totalNumberOfTimesOfRewardsEarnedInStampCampaign{
        value
        change
      }
      pointsEarnedInStampCampaign{
        value
        change
      }
      couponAcquiredInStampCampaign{
        value
        change
      }
      totalNumberOfTemporaryEarningCustomers {
        value
        change
      }
      totalNumberOfTemporaryCustomers {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignsOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfPublishedCampaigns {
        value
        change
      }
      totalNumberOfCampaignConversions {
        value
        change
      }
      totalNumberOfCampaignViews {
        value
        change
      }
      couponCampaignConversionRate {
        value
        change
      }
      earningCampaignConversionRate {
        value
        change
      }
      campaignShareRate {
        value
        change
      }
      totalPointsEarned {
        value
        change
      }
      totalPointsUsed {
        value
        change
      }
      totalNumberOfCouponActivities {
        value
        change
      }
      totalNumberOfCouponsRedeemedByPoints {
        value
        change
      }
      totalNumberOfCouponsUsed {
        value
        change
      }
      totalNumberOfCouponsAcquiredByCampaigns {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomersOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCustomers {
        value
        change
      }
      totalNumberOfNewCustomers {
        value
        change
      }
      totalNumberOfNewCustomersChart {
        periods
        points
      }
      totalNumberOfActiveCustomers {
        value
        change
      }
      totalNumberOfGuestCustomers {
        value
        change
      }
      totalNumberOfTemporaryEarningCustomers {
        value
        change
      }
      totalNumberOfTemporaryCustomers {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponsOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate: "${startDate}", toDate: "${endDate}") {
      totalNumberOfCouponActivities {
        value
        change
      }
      totalNumberOfCouponsRedeemedByPoints {
        value
        change
      }
      totalNumberOfCouponsUsed {
        value
        change
      }
      totalNumberOfCouponsAcquiredByCampaigns {
        value
        change
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStampCampaignsOverviewData = (startDate, endDate) => {
  const query = `{
    analyticsData(fromDate:"${startDate}", toDate:"${endDate}"){
      totalNumberOfPublishedStampCampaign {
        value
        change
      }
      totalNumberOfStampCampaignConversion{
        value
        change
      }
      stampCampaignConversionRate{
        value
        change
      }
      stampCampaignTotalNumberOfView{
        value
        change
      }
      stampCampaignShareRate{
        value
        change
      }
      stampEarnedInStampCampaign{
        value
        change
      }
      totalNumberOfTimesOfRewardsEarnedInStampCampaign{
        value
        change
      }
      pointsEarnedInStampCampaign{
        value
        change
      }
      couponAcquiredInStampCampaign{
        value
        change
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSuperSetToken = (embedId) => {
  const query = `mutation SuperSetToken($input: SuperSetTokenInput!) {
    superSetToken(input: $input) {
      success
      token
      errorMessage
    }
  }`;
  const variables = {
    input: {
      embedId,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

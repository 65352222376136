import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import VirtualGiftCardConfigContentSection from 'components/giftcard/VirtualGiftCardConfigContentSection';
import { APIStatus, SavedStatus } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { errorMessagesList } from 'models/RecencyFrequencyMonetaryScoreSettingModel';

import CloseIcon from 'assets/images/prompt_close_icon.svg';

const mapPropsToState = (state) => ({
  formHasSubmitted: state.virtualGiftCardConfig?.formHasSubmitted,
  saved: state.virtualGiftCardConfig?.formHasSubmitted,
  createStatus: state.virtualGiftCardConfig?.createStatus,
  apiStatus: state.virtualGiftCardConfig?.apiStatus,
});

const VirtualGiftCardConfigSetting = ({
  formHasSubmitted,
  saved,
  createStatus,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      dispatch({
        type: 'virtualGiftCardConfig/getList',
        payload: {},
      });
    }
  }, [show]);

  const onClose = () => {
    setShow(false);
    dispatch({
      type: 'virtualGiftCardConfig/clearData',
      payload: {},
    });
  };

  useEffect(() => {
    // TODO: refactor
    if (createStatus === APIStatus.success && formHasSubmitted) {
      onClose();
    }
  }, [saved]);

  const saveAction = () => {
    dispatch({
      type: 'virtualGiftCardConfig/updateVirtualGiftCardVariants',
    });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body className={`base-prompt-container`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={onClose}
        />
        <VirtualGiftCardConfigContentSection />

        <div className="base-prompt-buttons">
          <PromptButton
            title="Cancel"
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title="Save"
            action={saveAction}
            requires={PermissionCodes.changeSegment}
            type={BUTTON_TYPES.main}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapPropsToState)(VirtualGiftCardConfigSetting);

import React, { useState, useRef, useEffect } from 'react';


const SinglePersonalInfo = ({
  title,
  content,
}) => {
  return (
    <div className='customer-detail-info-container'>
      <label className='customer-detail-info-title'>{title}</label>
      <label className='customer-detail-info-content'>{content || '-'}</label>
    </div>
  )
}


const PersonalInformationFields = ({
  customer,
  fields,
}) => {
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(800);
  const tableOffset = tableRef.current?.offsetWidth;
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableOffset]);


  return (
    <table
      style={{ tableLayout: "fixed", width: "100%" }}
      ref={tableRef}
    >
      {fields.map((row) => {
        return (
          <tr>
            {row.map((column) => {
              return (
                <td
                  style={{
                    display: 'inline-grid',
                    width: `${tableWidth / row.length}px`,
                    wordBreak: `break-word`,
                  }}
                >
                  <SinglePersonalInfo
                    title={column.title}
                    content={customer[column.field]}
                  />
                </td>
              );
            })}
          </tr>
        );
      })}
    </table>
  )
}

export default PersonalInformationFields;

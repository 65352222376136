import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import queryString from 'query-string';

import { DELETE_RELATED_SECTIONS, CUSTOMER_DETAIL_COUPON_TYPE } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';

import { getHashKeyString } from 'utils';
import CustomerRewardListFilter from './CustomerRewardListFilter';
import { ActionsDropdownForItem } from 'components/base/ActionsDropdown';
import SelectStoreOrUseCouponModal from './SelectStoreOrUseCouponModal';

function CustomerRewardList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = getHashKeyString(location.hash);
  const params = useParams();
  const customerId = params.id;

  const [showModal, setShowModal] = useState(false);
  const [selectCoupon, setSelectCoupon] = useState(null);

  useEffect(() => {
    if (hash !== "Coupons") {
      return;
    }

    setSelectCoupon(null);

    const parsedSearch = queryString.parse(location.search);
    const type = parsedSearch['type'] || '';
    const searchKey = parsedSearch['search'] || '';
    const stringPage = parsedSearch['page'] || 1;
    const page = parseInt(stringPage);

    dispatch({
      type: 'singleCoupon/getCustomerDetailCouponList',
      payload: {
        ...parsedSearch,
        customerId: customerId,
        page,
        search: searchKey,
        type: type,
      },
    });
  }, [dispatch, location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={false}
        maxTabWidth={670}
        tabs={[
          { key: CUSTOMER_DETAIL_COUPON_TYPE.all, name: 'All Type' },
          { key: CUSTOMER_DETAIL_COUPON_TYPE.available, name: 'Available' },
          { key: CUSTOMER_DETAIL_COUPON_TYPE.unavailable, name: 'Unavailable' },
          { key: CUSTOMER_DETAIL_COUPON_TYPE.used_or_expired, name: 'Used or expired' },
        ]}
        groupActions={[]}
        pageInfo={pageInfo}
        totalCount={totalCount}
        filter={{ hasFilter: true, component: CustomerRewardListFilter }}
        listContent={
          <BaseListContainer
            fields={listFields}
            dataList={dataList}
            totalPage={totalPage}
            model={'singleCoupon'}
            permissionGroup={PermissionCodes.coupon}
            deleteInfo={{
              data: [],
              title: 'coupon',
              relatedName: 'coupon transaction',
              onComfirm: {},
              relatedSections: DELETE_RELATED_SECTIONS.COUPON,
            }}
            useCustomCommonActions={true}
            customCommonActions={(item) => {
              const actions = [];
              if (item.isActive && item.isEffective) {
                actions.push({
                  name: 'Use coupon',
                  action: (item) => {
                    setSelectCoupon(item);
                    setShowModal(true);
                  },
                  requires: `change_${PermissionCodes.coupon}`,
                });

                if (item.couldSelectStore) {
                  actions.push({
                    name: 'Select store only',
                    action: (item) => {
                      setSelectCoupon(item);
                      setShowModal(true);
                    },
                    requires: `change_${PermissionCodes.coupon}`,
                  });
                };
              };
              return (
                <ActionsDropdownForItem
                  object={item}
                  actions={actions}
                  otherActions={[]}
                />
              )
            }}
            openNewTab
            customPathname="/coupons"
            customPathId="templateId"
          />
        }
      />
      <SelectStoreOrUseCouponModal
        customerId={customerId}
        coupon={selectCoupon}
        show={showModal}
        setShow={setShowModal}
      />

    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.singleCoupon.customerDetailCouponListDisplayFields,
  dataList: state.singleCoupon.customerDetailCouponList,
  pageInfo: state.singleCoupon.pageInfo,
  totalPage: state.singleCoupon.totalPage,
  totalCount: state.singleCoupon.totalCount,
});

export default connect(mapPropsToState)(CustomerRewardList);

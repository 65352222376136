import React from 'react';
import { LanguageConfig } from '../../config/CustomEnums';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import { isShowError } from '../../utils';
import './DisplayOrder.scss';

const error = (language, field, error) => {
  return (
    <ErrorFieldMessage
      id={`${field.name}-${language}`}
      error={error}
      message={field.message}
    />
  );
};

const DisplayOrder = (props) => {
  const language = props.language || LanguageConfig.english;
  const order = props.order;
  const onChange = props.onChange || (() => {});
  const errors = props.errors || [];
  const field = props.field || {};
  return (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        Display order
      </label>
      {language !== LanguageConfig.english ? (
        <label>{order || '-'}</label>
      ) : (
        <input
          type="number"
          min="1"
          value={order}
          className="create-category-prompt-input-text display-order-padding-left"
          onChange={({ target }) => onChange(target.value)}
        />
      )}

      <label className="grey-caution-of-priority">
        Minimum value is 1. The smaller value, the higher priority.
      </label>
      {error(language, field, isShowError(field, errors, language))}
    </>
  );
};

export default DisplayOrder;

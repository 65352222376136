import { CreatePointRecordError } from '../../../models/CreatePointRecordModel';

function firstStepValidate({ getValues, setError }) {
  const pointTypeField = 'pointType';
  const [
    pointType,
  ] = getValues([
    pointTypeField,
  ]);
  const errorList = [];
  if (!pointType) {
    errorList.push({
      name: pointTypeField,
      message: 'Please provide a point type.',
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const customerField = 'customer';
  const pointsField = 'points';
  const [
    selectedCustomer,
    points,
  ] = getValues([
    customerField,
    pointsField,
  ]);
  const errorList = [];
  if (!selectedCustomer || !selectedCustomer.pk) {
    errorList.push({
      name: customerField,
      message: CreatePointRecordError.selectCustomer.message,
    });
  }
  if (!points) {
    errorList.push({
      name: pointsField,
      message: CreatePointRecordError.operatedPoints.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}


export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      return validOne && validTwo;
  }
}

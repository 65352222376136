import React from 'react';
function SpecificCustomSwitchButton(props) {
  const onChange = props.onChange || (() => {});
  const checked = props.checked;
  const disabled = props.disabled;
  return (
    <label className="custom-switch-button">
      <input
        type="checkbox"
        checked={checked}
        onClick={() => {
          onChange(!checked);
        }}
        disabled={disabled}
      />
      <span className="custom-switch-button-slider"></span>
    </label>
  );
}

export default SpecificCustomSwitchButton;

import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import './TargetCustomerSection.scss';
import BaseMultipleSelectorDropDown from '../base/BaseMultipleSelectorDropDown';

function TargetCustomerSection({ customerList }) {
  const { watch, setValue } =
    useFormContext();

  const customersField = 'customers';
  const selectedCustomerList = watch(customersField);

  return (
    <div className="create-customer-group-target">
      <BaseMultipleSelectorDropDown
        title={'TARGET CUSTOMER'}
        filterTitle={'Customer'}
        placeholder='Search by name'
        source={customerList}
        value={selectedCustomerList}
        onChange={(value) => {
          setValue(customersField, value, { shouldDirty: true, });
        }}
        loadMoreAction={'customerList/getPagedCustomers'}
        filterAction={'customerList/getPagedCustomers'}
        defaultFilter={{
          isDropdownNode: true,
        }}
        customItem={(item) => `[${item?.membershipId}] ${item?.owner}`}
      />

      {/* <BaseMultipleSelectorV2
          title={"TARGET CUSTOMER"}
          namespace={"customerList"}
          data={{
            sourceData: optionList(customerList),
            targetData: optionList(selectedCustomerList),
            targetChange: (value) => {
              setValue(customersField, options, { shouldDirty: true, });
            },
          }}
          addButton={config.addButton}
          error={{
            error: hasError(errors, config.errorIdKey),
            id: config.errorIdKey,
            message: errorMessage(errors, config.errorIdKey),
          }}
          requires={config.requires}
          groupKey={config.groupKey}
          custom={config.custom}
          searchPlaceholder={config.searchPlaceholder}
        /> */}
    </div>
  );
}

const mapPropsToState = (state) => ({
  customerList: state.customerList.customerList,
});

export default connect(mapPropsToState)(TargetCustomerSection);

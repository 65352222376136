import moment from 'moment';

import { SESSION_KEYS } from 'config/CustomEnums';
import { createModel } from 'models/BaseModel';
import {
  createPhysicalGiftCard,
  getGiftCard,
  getGiftCardList,
  parseGiftCardStatus,
  parseGiftCardType,
  updateGiftCardRecipient,
} from 'services/GiftCardAPIHelper';

import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';
import { createImportJob } from 'services/DownloadAndImportAPIHelper';

const parseListItem = (data) => {
  console.log(data);
  const canEdit = !data.isBuyForSelf && data.status !== 'USED';
  console.log('canEdit', canEdit, data.isBuyForSelf, data.status);
  //let expiryAt = '-';
  //console.log('virtualConfigVariant', virtualConfigVariant);
  //if (data.usedAt && virtualConfigVariant.activePeriodType !== 'ALL_TIME') {
  //  if (virtualConfigVariant.activePeriodType === 'IN_PERIOD') {
  //    const expiryDate = moment(virtualConfigVariant.endDate);
  //    expiryAt = formatDate(
  //      expiryDate,
  //      TimeFormater.dayMonthYear,
  //    );
  //  }
  //  if (virtualConfigVariant.activePeriodType === 'DAYS_SINCE_ACQUIRE') {
  //    const usedDatetime = moment(data.usedAt);
  //    console.log('usedDatetime', usedDatetime);
  //    const expiryDate = usedDatetime.add(parseInt(virtualConfigVariant.expireDays), 'days')
  //    expiryAt = formatDate(
  //      expiryDate,
  //      TimeFormater.dayMonthYear,
  //    );
  //  }
  //}
  return {
    ...data,
    name: data.code,
    sourceName: data.code,
    displayType: parseGiftCardType(data.type),
    cashValue: `$ ${data.cashValue}`,
    price: data.price,
    status: data.status,
    displayStatus: parseGiftCardStatus(data.status, data.type),
    usedAt: formatDate(data.usedAt, TimeFormater.dayMonthYear),
    expiryAt: formatDate(data.expireAt, TimeFormater.dayMonthYear),
    creationAt: formatDate(data.creationDate, TimeFormater.dayMonthWeek),
    canEdit: canEdit,
  };
};

const parseDetail = (data) => {
  const simpleParseData = parseListItem(data);
  const recipient = data.recipient;
  const recipientContact =
    data.sendMethod === 'email'
      ? data?.recipientContact
      : data?.recipientMobile;
  console.log('recipient', recipient);
  return {
    ...simpleParseData,
    recipientContact,
    recipientMembershipId: recipient?.membershipId,
  };
};

export default createModel({
  namespace: 'giftCard',
  states: {
    listDisplayFields: [
      { displayName: 'Code', fieldName: 'name' },
      { displayName: 'Type', fieldName: 'displayType' },
      {
        displayName: 'Cash value',
        fieldName: 'cashValue',
        orderField: 'cash_value',
      },
      {
        displayName: 'Points expiry date',
        fieldName: 'expiryAt',
        orderField: 'expire_at',
      },
      {
        displayName: 'Creation at',
        fieldName: 'creationAt',
        orderField: 'creation_date',
      },
      { displayName: 'status', fieldName: 'displayStatus' },
    ],
    detail: {
      sourceName: '',
      parameter: '',
    },
  },
  reducers: {},
  params: {
    sessionKey: SESSION_KEYS.GIFTCARD_SESSION_KEY,
    dataKey: SESSION_KEYS.GIFTCARD_DATA_SESSION_KEY,
    listAPI: getGiftCardList,
    parse: (data) =>
      data?.giftCards.edges.map((item) => parseListItem(item.node)),
    pkNode: 'GiftCardNode',
    detailAPI: getGiftCard,
    parseDetail: (data) => parseDetail(data.giftCard),
    objectKey: 'giftCards',
  },
  effects: {
    updateVirtualGiftCard: [
      function* ({ payload }, { call, select, put }) {
        console.log('@56', payload);
        const data = {
          code: payload.code,
          sendMethod: payload.sendMethod,
          contact: payload.recipientContact,
        };
        console.log('@116', data);
        const serviceArgs = [updateGiftCardRecipient, data];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: false },
        });
      },
      { type: 'takeLatest' },
    ],
    createPhysicalGiftCard: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [createImportJob, payload];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true },
        });
        yield put({ type: 'downloadAndImport/getSystemTasks' });

      },
      { type: 'takeLatest' },
    ],
  },
});

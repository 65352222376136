import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { enLocaleNumberFormatter } from '../../utils'
import './RFMPieDash.scss'

const RFMSegmentTemplates = [
    { templateId: 1, rfmName: "Champions", totalCustomers: 0, color: "#F56C49", type: "CHAMPIONS" },
    { templateId: 2, rfmName: "Loyal Customer", totalCustomers: 0, color: "#FF8B2E", type: "LOYAL_CUSTOMER" },
    { templateId: 3, rfmName: "Potential Loyalist", totalCustomers: 0, color: "#FFC400", type: "POTENTIAL_LOYALIST" },
    { templateId: 4, rfmName: "New Customers", totalCustomers: 0, color: "#74E62A", type: "NEW_CUSTOMERS" },
    { templateId: 5, rfmName: "Promising", totalCustomers: 0, color: "#FF7ABA", type: "PROMISING" },
    { templateId: 6, rfmName: "Needs Attention", totalCustomers: 0, color: "#22D66D", type: "NEEDS_ATTENTION" },
    { templateId: 7, rfmName: "About to Sleep", totalCustomers: 0, color: "#1EECB9", type: "ABOUT_TO_SLEEP" },
    { templateId: 8, rfmName: "Can't Lose Them", totalCustomers: 0, color: "#A9B0F7", type: "CANT_LOSE_THEM" },
    { templateId: 9, rfmName: "At Risk", totalCustomers: 0, color: "#E84DE1", type: "AT_RISK" },
    { templateId: 10, rfmName: "Hibernating", totalCustomers: 0, color: "#9A76FF", type: "HIBERNATING" },
];

const addPercentage = (data) => {
    if (!data) {
        return []
    }
    let total = 0
    data.forEach(item => {
        total += parseInt(item.totalCustomers)
    })
    const modifiedData = data.map(item => {
        let percent = 0
        const itemTotalCustomer = parseInt(item.totalCustomers)
        if (itemTotalCustomer === 0) {
            return item
        }
        percent = itemTotalCustomer / total
        const displayPercent = ((percent * 10000).toFixed(0) / 100)
        return { ...item, displayPercent }
    })
    return modifiedData
}

function PieLabel({ data }) {
    const {
        cx,
        cy,
        x,
        y,
        totalCustomers,
        rfmName,
        displayPercent,
        percent
    } = data

    console.log("displayPercent:", displayPercent, percent)
    const anchorY = y > cy ? y + 20 : y - 20
    return (
        <>
            <text
                x={x}
                y={anchorY}
                fill="black"
                textAnchor={x > cx ? "start" : "end"}
                className='rfm-pie-item-name'
            >
                {rfmName}
            </text>
            <text
                x={x}
                y={anchorY + 20}
                fill="black"
                textAnchor={x > cx ? "start" : "end"}
                className='rfm-pie-percentage'
            >
                {enLocaleNumberFormatter(totalCustomers)} ({displayPercent}%)
            </text>
        </>
    )
}

function RFMPie({ data=[] }) {
    const pieData = data.filter(item=> parseInt(item.totalCustomers) > 0).sort((pre, next) => next.templateId - pre.templateId)
    return (
        <PieChart width={1000} height={800} className='rfm-dashboard-chart' >
            <Pie
                dataKey="totalCustomers"
                isAnimationActive={false}
                data={pieData}
                outerRadius={300}
                label={(labelData) => <PieLabel data={labelData} />}
                nameKey={"rfmName"}
                minAngle={10}
            >
                {pieData.map((entry) => (
                    <Cell key={`cell-${entry.rfmName}`} fill={entry.color} />
                ))}
            </Pie>
            <Tooltip />
        </PieChart>
    );
}

function RFMMemeberList({ data }) {
    return (
        <div className='rfm-pie-memberlist-container-item'>
            <div className='rfm-pie-memberlist-container-item-color-label' style={{ background: data.color, }} />
            <label className='rfm-pie-memberlist-container-item-label'>{data.rfmName} [{enLocaleNumberFormatter(data.totalCustomers)} ({data.displayPercent}%)]</label>
        </div>
    )
}

const RFMPieDash = () => {
    const dispatch = useDispatch();
    const segments = useSelector(state => state.segments.segmentList)
    const [modifiedData, setModifiedData] = useState([])
    useEffect(() => {
        dispatch({ type: 'segments/clearData' })
        dispatch({
            type: 'segments/getSegmentsList',
            payload: {
                moreSearch: { defaultRecencyfrequencySegment: true },
            }
        });
        return () => dispatch({ type: 'segments/clearData' });
    }, [])

    useEffect(() => {
        const mappingSegment = RFMSegmentTemplates.map(template => {
            const segment = (segments || []).find(item => item.scoreDefinitionType === template.type)
            return { ...template, ...segment }
        })
        const changedData = addPercentage(mappingSegment)
        setModifiedData(changedData)
    }, [segments])

    console.log("RFMPieDash:", segments)
    return (
        <>
            <div className="rfm-dashboard-section-container">
                <label className='rfm-dashboard-card-title'>No. of members by RFM</label>
                <div className='rfm-pie-memberlist-container'>{modifiedData.map(item => <RFMMemeberList data={item} />)}</div>
                <RFMPie data={modifiedData} />
            </div>
        </>
    );
};

export default RFMPieDash;

import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import BaseFilter, {
  FilterTypeEnum,
} from 'components/base/BaseFilter';
import {
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
  COUPON_SET_MEDIUM,
} from 'config/CustomEnums';
import { ArrayStringData } from 'utils';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchCouponMedium = parsedSearch['coupon_medium'] || '';
  const searchOutOfStock = parsedSearch['out_of_stock'] || '';
  const searchStatus = parsedSearch['status'] || '';
  const searchExpired = parsedSearch['expired'] || '';

  const searchExpiredDateType = parsedSearch['expired_date'] || '';
  const searchExpiredDate = parsedSearch['absolute'] || '';
  const searchRelativeDay = parsedSearch['relativeDay'] || '';

  const searchCouponType = parsedSearch['coupon_type'] || '';
  const searchRedemptionMethod = parsedSearch['redemption_method'] || '';

  const [couponMedium, setCouponMedium] = useState(ArrayStringData(searchCouponMedium));
  const [outOfStock, setOutOfStock] = useState(searchOutOfStock);
  const [expiredDateType, setExpiredDateType] = useState(searchExpiredDateType);
  const [expiredDate, setExpiredDate] = useState(searchExpiredDate);
  const [status, setStatus] = useState(searchStatus);
  const [expired, setExpired] = useState(searchExpired);
  const [relativeDay, setRelativeDay] = useState(searchRelativeDay);
  const [couponType, setCouponType] = useState(
    ArrayStringData(searchCouponType),
  );
  const [offlineRedemptionMethod, setOfflineRedemptionMethod] = useState(
    ArrayStringData(searchRedemptionMethod),
  );

  const content = [
    {
      title: 'Coupon medium',
      data: [
        { name: 'Online only', pk: COUPON_SET_MEDIUM.ONLINE },
        { name: 'Offline only', pk: COUPON_SET_MEDIUM.OFFLINE },
        { name: 'Online or offline', pk: COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE },
      ],
      value: couponMedium,
      setValue: setCouponMedium,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Out of stock',
      data: [
        { name: 'Out of stock', pk: 'true' },
        { name: 'Have stock', pk: 'false' },
      ],
      value: outOfStock,
      setValue: setOutOfStock,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Expired',
      data: [
        { name: 'Expired', pk: 'true' },
        { name: 'Unexpired', pk: 'false' },
      ],
      value: expired,
      setValue: setExpired,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Active', pk: 'true' },
        { name: 'Inactive', pk: 'false' },
      ],
      value: status,
      setValue: setStatus,
      component: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Expired date',
      data: [
        { name: 'All time', pk: 'allTime', subComponment: null },
        {
          name: 'Time Range',
          pk: 'absolute',
          subComponment: {
            value: expiredDate,
            setValue: setExpiredDate,
            component: FilterTypeEnum.dateRange,
          },
        },
        {
          name: 'Since acquiring',
          pk: 'relativeDay',
          subComponment: {
            value: relativeDay,
            setValue: setRelativeDay,
            component: FilterTypeEnum.input,
          },
        },
      ],
      value: expiredDateType,
      setValue: setExpiredDateType,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Coupon type',
      data: [
        { name: 'System generated', pk: COUPON_SET_TYPES.SYSTEM_GENERATED },
        {
          name: 'Import existing coupon',
          pk: COUPON_SET_TYPES.IMPORT_EXISTING_COUPON,
        },
      ],
      value: couponType,
      setValue: setCouponType,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Redemption method',
      data: [
        {
          name: 'Merchant app scan Coupon QR code',
          pk: COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE,
        },
        {
          name: 'Users scan Store QR code',
          pk: COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE,
        },
        {
          name: 'Merchant staff input store code only',
          pk: COUPON_REDEMPTION_METHOD.INPUT_STORE_STAFF_CODE,
        },
        {
          name: 'Sales system update',
          pk: COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE,
        },
        // {
        //   name: 'Users copy the discount code',    // TODO: add it aillia next phase
        //   pk: COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE,
        // },
      ],
      value: offlineRedemptionMethod,
      setValue: setOfflineRedemptionMethod,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
  ];

  // const confirmAction = () => {
  //   backAction();

  //   let search = {};
  //   if (expiredDateType) {
  //     search['expired_date_type'] = expiredDateType;
  //     switch (expiredDateType) {
  //       case 'absolute':
  //         search['expired_date'] = expiredDate;
  //         break;
  //       case 'relativeDay':
  //         search['relative_day'] = relativeDay;
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  //   return search;
  // };

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

import React from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { Row } from 'react-bootstrap';
import CustomRadios from '../base/CustomRadios';

function TargetCustomerRadios(props) {
  const radiosChanged = props.radiosChanged || (() => {});
  const defaultTarget = props.defaultTarget || 'targetedCustomerGroups';
  const targetCustomerOptions = [
    { label: 'Customer group', value: 'targetedCustomerGroups' },
    { label: 'Segment', value: 'targetedSegments' },
    { label: 'Level', value: 'targetedLevels' },
  ];
  return (
    <>
      <CustomTitleLabel title="Target customer" />
      <Row className="featured-exclusive-row">
        <CustomRadios
          disable={false}
          options={targetCustomerOptions}
          default={defaultTarget}
          onChange={(value) => {
            console.log('@23', value);
            radiosChanged(value);
          }}
        />
      </Row>
    </>
  );
}

export default TargetCustomerRadios;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';

function PurchaseReceiptSection(props) {
  const { watch } = useFormContext();

  const receiptImageField = 'receiptImage';
  const creditCardSlipImageField = 'creditCardSlipImage';
  const receiptImage = watch(receiptImageField);
  const creditCardSlipImage= watch(creditCardSlipImageField);

  return (
    <>
      <div className="first-section-title">PURCHASE RECEIPT</div>
      <div className="second-section">
        <ReactHookFormImageUploader
          title={'Receipt image'}
          uploadSuggest={'Suggested Image Size: 1125 px * 2001 px At least : 375 px * 667px(Less than 2MB, support JPG, PNG and GIF only)'}
          name={receiptImageField}
          minWidth={375}
          minHeight={667}
          value={receiptImage}
          aspect={375 / 667}
          maxImageNum={1}
          language={LanguageConfig.english}
        />
      </div>
      <div className="second-section">
        <ReactHookFormImageUploader
          title={'Credit card slip image'}
          uploadSuggest={'Suggested Image Size: 1125 px * 2001 px At least : 375 px * 667px(Less than 2MB, support JPG, PNG and GIF only)'}
          name={creditCardSlipImageField}
          minWidth={375}
          minHeight={667}
          value={creditCardSlipImage}
          aspect={375 / 667}
          maxImageNum={1}
          language={LanguageConfig.english}
        />
      </div>
    </>
  );
}

export default PurchaseReceiptSection;

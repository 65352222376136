export const AdminErrorHandleFields = {
  required: {
    username: 'Please provide name',
    password: 'Please provide password',
    brand: 'Please provide brand',
    email: 'Please provide email',
    store: 'Please provide store',
    adminType: 'Please provide Administrator type',
    passwordConfirmation: 'Please provide confirmation password ',
  },
  others: {
    password: {
      length: 'Password at least 8 characters',
      numeric: 'Password can’t be entirely numeric',
    },
    email: {
      valid: 'please input the valid email',
    },
    periodEndDate: {
      valid:
        'The end date & time of a campaign cannot be on or before the start date and time.',
    },
    passwordConfirmation: {
      valid: 'The two password fields didn’t match.',
    },
  },
};

import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import BaseForm from 'components/base/v2/BaseForm';
import { APIStatus } from 'config/CustomEnums';

import CreatePhysicalGiftCardContent from './CreatePhysicalGiftCardContent';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  formHasSubmitted: state.giftCard?.formHasSubmitted,
  giftCardDetail: state.giftCard?.detail,
  hasUpdatedDefaultValues: state.giftCard?.hasUpdatedDefaultValues,
  createStatus: state.giftCard?.createStatus,
  apiStatus: state.giftCard?.apiStatus,
});

function CreatePhysicalGiftCard({
  formHasSubmitted,
  giftCardDetail,
  hasUpdatedDefaultValues,
  createStatus,
  apiStatus,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const stepSet = [<CreatePhysicalGiftCardContent />];

  useEffect(() => {
    dispatch(createAction('giftCard/loadFromCookie')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'giftCard/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiStatus === APIStatus.success) {
      history.push('/gift_cards');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus]);

  return (
    <>
      <BaseForm
        defaultValues={giftCardDetail}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            dispatch({
              type: 'giftCard/removeFromCookie',
            });
          } else {
            dispatch({
              type: 'giftCard/saveToCookie',
              payload: getValues(),
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Physical gift card is successfully created.',
          steps: null,
          onHide: () => {},
          buttons: [
            {
              text: 'Back to gift card list',
              action: () => {
                history.push({
                  pathname: '/gift_cards',
                });
              },
            },
          ],
        }}
        showFinishPop={createStatus === APIStatus.success}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? giftCardDetail.code : 'Create physical gift card'}
          />
        }
        caution={{
          detail: 'coming soon',
          title: params.id ? giftCardDetail.code : 'Create physical gift card',
        }}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreatePhysicalGiftCard);

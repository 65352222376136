import React from 'react';
import {
    Image,
} from 'react-bootstrap';
import rfmDefinition from '../../assets/images/rfm_definition.svg';
import './RFMDefinition.scss'

const RFMDefinition = () => {
    return (
        <div className="rfm-dashboard-section-container">
            <label className='rfm-dashboard-card-title'>RFM Segments Definition</label>
            <label className='rfm-definitions-tips'>You could edit the scoring setting in Segment section and select "Edit RFM Scoring Setting</label>
            <label className='rfm-definitions-tips'>If the score of F+M is a decimal number, discard the digits after the decimal point. e.g R=3, F+M=3.5, then segment=About to Sleep.</label>
            <Image src={rfmDefinition} alt="rfm definition" className="rfm-definitions-image" />
        </div>
    )
};

export default RFMDefinition;

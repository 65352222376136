import React, { useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { CustomTitleLabel } from "components/earning/CustomBaseComponments";
import deleteButton from '../../assets/images/drop_down_delete_selected.png'
import { ReactHookFormErrorMessage } from "components/base/ErrorFieldMessage";

function TransactionAdditionalData({
  title = "",
  additionalDataField = 'additionalData'
}) {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;
  const additionalData = watch(additionalDataField);

  useEffect(() => {
    if (!additionalData || additionalData?.length < 1) {
      setValue(additionalDataField, [
        {
          title: null,
          value: null
        }
      ], { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const renderSingleAdditionalData = (index, listLength) => {
    const dataTitleField = `${additionalDataField}.${index}.title`;
    const dataValueField = `${additionalDataField}.${index}.value`;

    const dataTitle = watch(dataTitleField);
    const dataValue = watch(dataValueField);

    return (
      <div className="single-additional-data-section">
        <div className="title-and-delete">
          <CustomTitleLabel title={`Fields #${index + 1}`} />
          {listLength > 1 &&
            <Image
              src={deleteButton}
              className="delete-img"
              onClick={(e) => {
                let itemList = additionalData;
                itemList.splice(index, 1);
                setValue(additionalDataField, itemList, { shouldDirty: true });
              }}
            />}
        </div>
        <div>
          <div className="d-flex">
            <label>{`Title`}</label>
            <input
              type="text"
              className={`text-input-field`}
              value={dataTitle || ""}
              onChange={(e) => {
                setValue(dataTitleField, e.target.value, { shouldDirty: true });
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id={dataTitleField} />
        </div>

        <div className="d-flex">
          <label>{`Value`}</label>
          <input
            type="text"
            className={`text-input-field`}
            value={dataValue || ""}
            onChange={(e) => {
              setValue(dataValueField, e.target.value, { shouldDirty: true });
            }}
          />
        </div>
      </div>
    )
  }



  return (
    <>
      {
        additionalData?.length > 0 ?
          <>
            {title &&
              <div className="first-section-title">
                {"ADDITIONAL DATA (optional)"}
              </div>
            }
            {additionalData.map((value, idnex, itemList) => renderSingleAdditionalData(idnex, itemList?.length || 0))}
            <div className="content-section-table-container">
              <Button
                onClick={() => {
                  const tempArray = [].concat(additionalData);
                  const value = {
                    title: null,
                    value: null
                  };
                  tempArray.push(value);
                  setValue(additionalDataField, tempArray, { shouldDirty: true, });
                }}
                className="btn-add-button-common"
              >
                Add field
              </Button>
            </div>
          </>
          : null
      }
    </>

  )
}

export default TransactionAdditionalData;
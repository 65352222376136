import React from 'react';
import { Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { SectionTopTitle } from 'components/base/CommonComponent';
import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomRadios from 'components/base/CustomRadios';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import InputFieldControl from 'components/base/InputFieldControl';
import PeriodAndWeekdaySection from 'components/base/period/PeriodAndWeekdaySection';
import { VALID_PERIOD_TYPE } from 'models/CreateCouponModel';

import './CouponDateTimeSelector.scss';

const couponDatetimeOptions = [
  { label: 'All the time', value: VALID_PERIOD_TYPE.ALL_TIME },
  { label: 'In a period', value: VALID_PERIOD_TYPE.ABSOLUTE },
  {
    label: 'Numbers of day since acquiring',
    value: VALID_PERIOD_TYPE.RELATIVE,
  },
];
const CouponDateTimeSelector = () => {
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const watchValidPeriodType = watch('validPeriodType');

  const watchNumberOfDays = watch('numberOfDaysToExpireAfterAcquisition');
  const watchAbsoluteEffectiveDate = watch('absoluteEffectiveDate');
  const watchAbsoluteExpiryDate = watch('absoluteExpiryDate');
  const periodValues = watch('validPeriodValues');
  const weekday = watch('validWeekday');

  const daySinceNowView = (
    <div>
      <InputFieldControl
        name={'numberOfDaysToExpireAfterAcquisition'}
        title={'Required days'}
        value={watchNumberOfDays}
        setValue={(value) => {
          setValue('numberOfDaysToExpireAfterAcquisition', value, {
            shouldDirty: true,
          });
        }}
        type="number"
        unit="days"
        className="coupon-campaign-section-input"
      />
    </div>
  );

  const periodView = (
    <div>
      <div className="second-section">
        <label className="create-section-label">Start date</label>
        <CustomDateTimeSelect
          defaultTime={watchAbsoluteEffectiveDate}
          onTimeChange={(datetime) => {
            setValue('absoluteEffectiveDate', datetime, {
              shouldDirty: true,
            });
          }}
        />
      </div>
      <div className="second-section">
        <label className="create-section-label">End date</label>
        <CustomDateTimeSelect
          defaultTime={watchAbsoluteExpiryDate}
          error={hasError(errors, 'absoluteExpiryDate')}
          onTimeChange={(datetime) => {
            setValue('absoluteExpiryDate', datetime, { shouldDirty: true });
          }}
          seconds={59}
          // hideTime={true}
        />
        <ReactHookFormErrorMessage errors={errors} id="absoluteExpiryDate" />
      </div>
    </div>
  );

  return (
    <div>
      <SectionTopTitle title={'Valid period'} />
      <div className="second-section">
        <label className="create-section-label">Valid period</label>
        <Row className="featured-exclusive-row">
          <CustomRadios
            disable={false}
            options={couponDatetimeOptions}
            default={watchValidPeriodType}
            onChange={(value) => {
              setValue('validPeriodType', value, { shouldDirty: true });
            }}
          />
        </Row>
      </div>
      <div
        className="second-section no-margin"
        hidden={
          watchValidPeriodType === VALID_PERIOD_TYPE.RELATIVE ? false : true
        }
      >
        {daySinceNowView}
      </div>
      <div
        hidden={
          watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE ? false : true
        }
      >
        {periodView}
      </div>

      <PeriodAndWeekdaySection
        switchButtonTitle="Blackout period"
        periodValuesField="validPeriodValues"
        periodValues={periodValues}
        periodValuesChange={(data) => {
          setValue('validPeriodValues', data, { shouldDirty: true });
        }}
        weekday={weekday}
        weekdayChange={(data) => {
          setValue('validWeekday', data, { shouldDirty: true });
        }}
        maxDate={watchAbsoluteExpiryDate}
        minDate={watchAbsoluteEffectiveDate}
        disabledDate={watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE}
      />
    </div>
  );
};

export default CouponDateTimeSelector;

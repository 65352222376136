import {
  APIStatus,
  LanguageConfig
} from '../config/CustomEnums';
import {
  createStoreCategory,
  getStoreCategory,
  updateStoreCategory,
  deleteStoreCategories,
  getStoreCategoryList,
} from '../services/StoreCategoryAPIHelper';
import {
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  category: {
    name: null,
    order: null,
    subCategories: [],
    translations: [],
  },
  formChanged: false,
  saved: -1,
  createStatus: APIStatus.none,
  formHasSubmitted: false,
  hasUpdatedDefaultValues: false,
});

export const STORE_CATEGORY_SESSION_KEY = 'STORE_CATEGORY_SESSION_KEY';

export const CREATE_STORE_CATEGORY_SUCCESS = 'CREATE_STORE_CATEGORY_SUCCESS';
export const CREATE_STORE_CATEGORY_FAILD = 'CREATE_STORE_CATEGORY_FAILD';

export const STORE_CATEGORY_ORDER_LAST = 'STORE_CATEGORY_ORDER_LAST';


const getCreateStoreCategory = (originData) => {
  const { order, subCategories, translations } = originData;
  const name = translations?.[LanguageConfig.english]?.name;

  let newTranslations = [];
  for (const [key, value] of Object.entries(translations)) {
    console.log(key, value);
    if (key === LanguageConfig.english) {
      continue;
    }
    if (value?.id == null && value?.name == null) {
      continue;
    }
    const translationData = {
      name: value?.name,
      language: key,
    };
    if (value?.id) {
      translationData.id = value?.id;
    }
    newTranslations.push(translationData);
  }

  let uploadSubCategories = [];
  subCategories.forEach((subCategory, index) => {
    const subCateOrder = subCategory?.order;
    const subCateName = subCategory?.translations?.[LanguageConfig.english]?.name;
    if (subCateName === '' || subCateName === null) {
      return;
    }
    let subData = {
      name: subCateName,
      displayPriority: parseInt(subCateOrder),
      translations: [],
    };
    if (subCategory?.id) {
      subData.id = subCategory?.id;
    }
    let subCategoryTranslations = [];
    for (const [key, value] of Object.entries(subCategory?.translations)) {
      if (key === LanguageConfig.english) {
        continue;
      }
      const translationData = {
        id: value?.id,
        name: value?.name,
        language: key,
      };
      if (translationData?.id == null && translationData?.name == null) {
        continue;
      }
      subCategoryTranslations.push(translationData);
    }
    subData.translations = subCategoryTranslations
    uploadSubCategories.push(subData);
  });

  const input = {
    name,
    displayPriority: parseInt(order),
    translations: newTranslations,
    subcategories: uploadSubCategories,
  };
  return input;
};

const assembleStoreSubCategory = (subCategory) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    name: subCategory.name,
  }
  const apiTranslations = subCategory.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    translations[language] = {
      name: item.node.name,
      id: item.node.pk,
    };
  });
  return {
    id: subCategory.pk,
    name: subCategory.name,
    order: subCategory.displayPriority,
    translations,
  };
};

const fixTranslations = (translations) => {
  const translationsToDelete = [];
  const fixedTranslations = translations.filter((translation) => {
    const { name, id } = translation;
    if (id && (name === '' || name === null || name === undefined)) {
      translationsToDelete.push(id);
      return false;
    }
    return true;
  });
  return [translationsToDelete, fixedTranslations];
};

const assembleStoreCategory = (category) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    name: category.name,
  }
  const apiTranslations = category.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    translations[language] = {
      name: item.node.name,
      id: item.node.pk,
    };
  });
  const subCategories = category.subcategories.edges.map(({ node }) =>
    assembleStoreSubCategory(node),
  );
  return {
    name: category.name,
    order: category.displayPriority,
    subCategories,
    translations,
  };
};

export default {
  namespace: 'createStoreCategory',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    reset(state, { payload }) {
      return getInitialState();
    },
    assembleStoreCategory(state, { payload }) {
      const { category } = payload;
      return {
        ...state,
        category: assembleStoreCategory(category),
      };
    },
    loadStoreCategoryFromSessionStorage(state, { payload }) {
      try {
        const category = getObjectFromSessionStorage(
          STORE_CATEGORY_SESSION_KEY,
        );
        if (category) {
          return {
            ...state,
            category: {
              ...category,
            },
            hasUpdatedDefaultValues: true,
          };
        }
      } catch (error) {
        console.log(error);
      }
      return {
        ...state,
      };
    },
    formHasChanged(state, { payload }) {
      return {
        ...state,
        formChanged: true,
      };
    },
  },
  effects: {
    getCategory: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('StoreCategoryNode', payload.id);
        const serviceArgs = [getStoreCategory, id];
        function* onSuccess(data) {
          console.log('getOneStore onSuccess :', data);
          yield all([
            put({
              type: 'assembleStoreCategory',
              payload: { category: data.storeCategory },
            }),
            put({
              type: 'updateState',
              payload: {
                hasUpdatedDefaultValues: true,
                createStatus: APIStatus.none
              },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneStoreCategory onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneStoreCategory arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    checkAndSave: [
      function* ({ payload }, { call, select, put, take, race }) {
        const { values } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = getCreateStoreCategory(values);
        const serviceArgs = [createStoreCategory, input];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
              formChanged: false,
              createStatus: APIStatus.success,
            },
          });
          removeFromSessionStorage(STORE_CATEGORY_SESSION_KEY);
          console.log('success', data);
        }
        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
            },
          });
          console.log('failed', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    checkAndUpdate: [
      function* ({ payload }, { call, select, put, all }) {
        const { values, categoryID } = payload;
        const id = parseInt(categoryID);
        if (isNaN(id)) {
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const input = getCreateStoreCategory(values);
        const [translationsToDelete, fixedTranslations] = fixTranslations(
          input.translations,
        );

        const uploadData = {
          id,
          name: input.name,
          displayPriority: input.displayPriority,
          translations: fixedTranslations,
          translationsToDelete: translationsToDelete,
          subcategories: input.subcategories.map((subCategory) => {
            const [subTranslationsToDelete, subFixedTranslations] = fixTranslations(
              subCategory.translations,
            );
            const data = {
              name: subCategory.name,
              displayPriority: subCategory.displayPriority,
              translationsToDelete: subTranslationsToDelete,
              translations: subFixedTranslations,
            };
            if (subCategory.id) {
              data.id = subCategory.id;
            }
            return data;
          }),
        };
        console.log(uploadData);
        const serviceArgs = [updateStoreCategory, uploadData];
        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          console.log('success', data);
        }
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
              formChanged: false,
              createStatus: APIStatus.success
            },
          });
          removeFromSessionStorage(STORE_CATEGORY_SESSION_KEY);
          console.log('success', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCategoryOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        if (order === STORE_CATEGORY_ORDER_LAST) {
          const countResponse = yield call(getStoreCategoryList, '', '');
          const countResponseData = countResponse.data;
          if (countResponseData?.data?.error) {
            return;
          }
          input.displayPriority =
            countResponseData.data.storeCategories.totalCount;
        } else {
          input.displayPriority = order;
        }
        const response = yield call(updateStoreCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateStoreCategory.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    deleteCategories: [
      function* ({ payload }, { call, put, select }) {
        const { ids, afterAction } = payload;
        yield call(deleteStoreCategories, ids);
        if (afterAction) {
          afterAction();
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateCategory: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('StoreCategoryNode', pk);
        const response = yield call(getStoreCategory, id);
        const responseData = response.data;
        if (
          responseData === undefined ||
          responseData.data?.storeCategory.pk === null
        ) {
          return;
        }
        const category = assembleStoreCategory(responseData.data.storeCategory);
        const input = getCreateStoreCategory(category);
        const uploadData = {
          name: `copy of ${input.name}`,
          displayPriority: input.displayPriority + 1,
          translations: input.translations.map((translation) => ({
            name: translation.name,
            language: translation.language,
          })),
          subcategories: input.subcategories.map((subCategory) => {
            const data = {
              name: `copy of ${subCategory.name}`,
              displayPriority: subCategory.displayPriority,
              translations: subCategory.translations.map((translation) => ({
                name: translation.name,
                language: translation.language,
              })),
            };
            return data;
          }),
        };
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('success', data);
        }
        const serviceArgs = [createStoreCategory, uploadData];
        function* onSuccess(data) {
          if (afterAction) {
            yield afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      {
        type: 'takeEvery',
      },
    ],
  },
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import './BrandSelectDropdown.scss';
import BasePrompt from '../../base/prompt/BasePrompt';
import { useHistory } from 'react-router-dom';
import { PermissionCodes } from '../../../config/PermissionCodes';

function BrandSelectDropdown(props) {
  const customClass = props.customClass || '';
  const title = props.title;
  const defaultValue = props.defaultValue;
  const onSelectChange = props.onSelectChange || (() => { });
  const addButton = props.addButton;
  const editable = props.editable;
  const onFocus = props.onFocus || (() => {});
  const errors = props.errors
  const errorName = props.errorName
  const enablePrompt = props.enablePrompt || false
  const defaultFilter = props.defaultFilter
  const history = useHistory()
  const [showPrompt, setShowPrompt] = useState(false)
  const dispatch = useDispatch();
  const { brandList } = useSelector((state) => ({
    brandList: state.brand.brandList,
  }));

  const getAddButtonInfo = (enablePrompt, addButton) => {
    if (enablePrompt && addButton && !addButton?.action) {
      return { ...addButton, action: () => setShowPrompt(true) }
    }
    if (enablePrompt && !addButton) {
      return {
        action: () => {
          setShowPrompt(true);
        },
        title: 'Create Now',
        requires: PermissionCodes.addBrand,
        customClass: 'general-section-add-new-brand btn-add-button-common',
      }
    }
    return addButton
  }

  // const onSelectChange = (value) => {
  //   dispatch({
  //     type: 'createCampaign/updateCampaign',
  //     payload: { linkedBrand: value?.value },
  //   });
  // };

  useEffect(() => {
    dispatch(createAction('brand/getCurrentPageBrands')({ ...defaultFilter, isSelectorLoad: true }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (editable)
    return (
      <>
        <CustomTitleWithDropDown
          customClass={customClass}
          title={title}
          source={brandList}
          defaultValue={defaultValue}
          setValue={onSelectChange}
          loadMoreAction={'brand/getCurrentPageBrands'}
          filterAction={'brand/getCurrentPageBrands'}
          addButtonInfo={getAddButtonInfo(enablePrompt, addButton)}
          onFocus={onFocus}
          errors={errors}
          errorName={errorName}
          defaultFilter={defaultFilter}
        />
        <BasePrompt
          show={showPrompt}
          closeAction={() => {
            setShowPrompt(false)
          }}
          rightButton={{
            text: `Go to create brand`,
            action: () => {
              setShowPrompt(false)
              history.push({
                pathname: '/brands/create',
                state: {
                  from: history.location,
                  title: 'Continue to create admin?',
                  content: 'You can continue to create the admin.',
                },
              });
            },
          }}
          leftButton={{
            text: 'Cancel',
            action: () => setShowPrompt(false),
          }}
          title={`Go to create a brand?`}
          description={`You will leave the coupon creation process. After you create a new brand, you can back to this page.`}
        />
      </>
    );
  else
    return (
      <div className="brand-select-dropdown-container">
        <CustomTitleLabel title={title} />
        <label>{defaultValue?.label || '-'}</label>
      </div>
    );
}

export default BrandSelectDropdown;

import React from 'react';

import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ANIMATION_DURATION } from 'config/DashboardConfig';

import './NewCustomersChart.scss';

const renderTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const { color } = payload[0];
    const { startDate, endDate } = payload[0].payload;
    let displayDate = '';
    if (startDate - endDate === 0) {
      displayDate = startDate.format('D MMM, YYYY');
    } else {
      displayDate = `${startDate.format('D MMM, YYYY')} - ${endDate.format(
        'D MMM, YYYY',
      )}`;
    }
    return (
      <div className="new-customer-chart-tool-tips">
        <div
          className="new-customer-chart-dot"
          style={{ backgroundColor: color }}
        />
        <div className="new-customer-chart-info">{`New Customers: ${payload[0].payload.point}`}</div>
        <div className="new-customer-chart-tooltips-date">{displayDate}</div>
      </div>
    );
  }
  return null;
};
const NewCustomersChart = ({ width, data, height, dataGrow }) => {
  if (!data) {
    return <div style={{ width, height }} />;
  }
  return (
    <AreaChart
      width={width}
      height={height}
      data={data}
      // margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
          <stop offset="42%" stopColor="rgba(0, 138, 96, 1)" />
          <stop offset="97.91%" stopColor="rgba(0, 138, 96, 0)" />
        </linearGradient>
        <linearGradient id="red" x1="0" y1="0" x2="0" y2="1">
          <stop offset="42%" stopColor="rgba(206, 0, 69, 1)" />
          <stop offset="97.91%" stopColor="rgba(206, 0, 69, 0)" />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dy={10} dataKey="startDateString" />
      <YAxis dx={-10} axisLine={false} tickLine={false} />
      <Tooltip content={renderTooltip} />
      <Area
        animationDuration={ANIMATION_DURATION}
        type="monotone"
        dataKey="point"
        activeDot={{
          stroke: dataGrow ? '#008A60' : '#CE0045',
          fill: 'white',
        }}
        strokeWidth={3}
        stroke={dataGrow ? '#008A60' : '#CE0045'}
        fill={dataGrow ? 'url(#green)' : 'url(#red)'}
      />
    </AreaChart>
  );
};

export default NewCustomersChart;

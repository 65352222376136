import React from 'react';
import { connect } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import DeleteIcon from '../../assets/images/deleteIcon.png';


export const SingleRemark = ({
  remark,
  setShowDeleteRemark,
  setDeleteRemarkId,
}) => {
  const deleteRemark = () => {
    setDeleteRemarkId(remark?.pk);
    setShowDeleteRemark(true);
  }

  return (
    <div className='customer-detail-single-remark-container'>
      <div className='customer-detail-single-remark-title'>
        <label className='customer-detail-single-remark-admin-name'>
          {remark?.adminName}
        </label>
        <label className='customer-detail-single-remark-creation-date'>
          {remark?.displayCreationDate}
        </label>
        {
          remark?.isCreator
            ? <Image
              src={DeleteIcon}
              className="customer-detail-delete-remark-image"
              onClick={deleteRemark}
            />
            : null
        }
      </div>
      <div className='customer-detail-single-remark-content'>
        <label className='customer-detail-single-remark-remark'>
          {remark?.remark}
        </label>
      </div>
    </div>
  )
}


const AllRemarkModal = ({
  customer,
  show,
  setShow,
  setShowDeleteRemark,
  setDeleteRemarkId,
}) => {
  const onClose = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>All remark</label>

        {
          customer.allDisplayRemarks?.map(item => (
            <SingleRemark
              remark={item}
              setShowDeleteRemark={setShowDeleteRemark}
              setDeleteRemarkId={setDeleteRemarkId}
            />
          ))
        }

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
});

export default connect(mapPropsToState)(AllRemarkModal);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';
import { MonthOptions } from 'config/CustomEnums';
import {
  CustomerAcquisitionChannel,
  CustomerAcquisitionChannelDisplay,
  CustomerStatus,
} from 'models/CustomerListModel';

import { ArrayStringData, createAction, getHashKeyString } from 'utils';

import './Filter.scss';

function Filter({ backAction = () => {} }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const genderSource = [
    { name: 'Male', pk: 'MALE' },
    { name: 'Female', pk: 'FEMALE' },
    { name: 'Not disclosed', pk: 'NOT_DISCLOSED' },
  ];

  const { groupSource, segmentSource, levelSource } = useSelector((state) => ({
    groupSource: state.customerGroup.groupList,
    segmentSource: state.segments.segmentList,
    levelSource: state.levels.levelList,
    filters: state.customerList.filters,
  }));
  const activeTab = getHashKeyString(location.hash);

  const parsedSearch = queryString.parse(location.search);
  const searchLevels = parsedSearch['levels'] || '';
  const searchSegments = parsedSearch['segments'] || '';
  const searchAdditionalData = parsedSearch['additional_data'] || '';
  const searchGroups = parsedSearch['customer_groups'] || '';
  const searchGenders = parsedSearch['gender'] || '';
  const searchAge = parsedSearch['age'] || '';
  const searchBirthStart = parsedSearch['birthday_start_date'] || '';
  const searchBirthEnd = parsedSearch['birthday_end_date'] || '';
  const searchBirthMonth = parsedSearch['birth_month'] || '';
  const searchCreationPeriod = parsedSearch['create_date'] || '';
  const searchTimeRange = parsedSearch['time_range'] || '';
  const searchReportType = parsedSearch['report_type'] || '';
  const searchAcquisitionChannel = parsedSearch['acquisition_channel'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [age, setAge] = useState(() => {
    const ages = ArrayStringData(searchAge);
    if (ages.length > 0) return ages;
    return [0, 0];
  });
  const [genders, setGenders] = useState(ArrayStringData(searchGenders));

  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  const [additionalData, setAdditionalData] = useState(searchAdditionalData);
  const [levels, setLevels] = useState(ArrayStringData(searchLevels));

  const [startDate, setStartDate] = useState(searchBirthStart);
  const [endDate, setEndDate] = useState(searchBirthEnd);
  const [birthMonth, setBirthMonth] = useState(() => {
    return searchBirthMonth
      .split(',')
      .filter(
        (ele, index, array) => ele !== '' && array.indexOf(ele) === index,
      );
  });
  const [creationPeriod, setCreationPeriod] = useState(searchCreationPeriod);
  const [reportsTimeRange, setReportsTimeRange] = useState(searchTimeRange);
  const [reportType, setReportType] = useState(searchReportType);
  const [acquisitionChannel, setAcquisitionChannel] = useState(
    searchAcquisitionChannel,
  );
  const [status, setStatus] = useState(ArrayStringData(searchStatus));

  useEffect(() => {
    if (activeTab !== 'Reports') {
      dispatch(
        createAction('customerGroup/getGroupList')({ isSimpleList: true }),
      );
      dispatch(createAction('levels/getLevelList')({}));
      dispatch(
        createAction('segments/getSegmentsList')({ isSelectorLoad: true }),
      );
    }
  }, [activeTab, dispatch]);

  let content;
  if (activeTab === 'Reports') {
    content = [
      {
        title: 'Report type',
        data: [
          { pk: 'WEEKLY', name: 'Weekly report' },
          { pk: 'MONTHLY', name: 'Monthly report' },
        ],
        value: reportType,
        setValue: setReportType,
        component: FilterTypeEnum.singleChoice,
        className: 'fit-content-height-area',
      },
      {
        title: 'Time range',
        value: reportsTimeRange,
        setValue: setReportsTimeRange,
        component: FilterTypeEnum.dateRange,
      },
    ];
  } else {
    content = [
      {
        title: 'Segments',
        data: segmentSource,
        value: segments,
        setValue: setSegments,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Customer groups',
        data: groupSource,
        value: groups,
        setValue: setGroups,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Additional data',
        placeholder: 'Search by Title and Value',
        value: additionalData,
        setValue: setAdditionalData,
        component: FilterTypeEnum.inputWithTitle,
      },
      {
        title: 'Levels',
        data: levelSource,
        value: levels,
        setValue: setLevels,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Status',
        data: [
          { name: 'Active', pk: CustomerStatus.active },
          // { name: 'Pre-Active', pk: CustomerStatus.preActive },
          { name: 'Temporary', pk: CustomerStatus.temporary },
          { name: 'Temporary Earning', pk: CustomerStatus.temporaryEarning },
          { name: 'Deactive', pk: CustomerStatus.deactive },
          { name: 'Requested to Del', pk: CustomerStatus.deleteRequested },
        ],
        value: status,
        setValue: setStatus,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Acquisition channel',
        data: [
          {
            name: CustomerAcquisitionChannelDisplay.IMPORTED_BY_CSV,
            pk: CustomerAcquisitionChannel.IMPORTED_BY_CSV,
          },
          {
            name: CustomerAcquisitionChannelDisplay.ONLINE_STORE,
            pk: CustomerAcquisitionChannel.ONLINE_STORE,
          },
          {
            name: CustomerAcquisitionChannelDisplay.OFFLINE_STORE,
            pk: CustomerAcquisitionChannel.OFFLINE_STORE,
          },
        ],
        value: acquisitionChannel,
        setValue: setAcquisitionChannel,
        component: FilterTypeEnum.singleChoice,
      },
      {
        title: 'Gender',
        data: genderSource,
        value: genders,
        setValue: setGenders,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Age',
        data: [0, 80],
        value: age,
        setValue: setAge,
        component: FilterTypeEnum.slider,
      },
      // {
      //   title: 'Birthday start date',
      //   value: startDate,
      //   setValue: setStartDate,
      //   component: FilterTypeEnum.date,
      // },
      // {
      //   title: 'Birthday end date',
      //   value: endDate,
      //   setValue: setEndDate,
      //   component: FilterTypeEnum.date,
      // },
      {
        title: 'Birth month',
        data: MonthOptions,
        value: birthMonth,
        setValue: setBirthMonth,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Create date',
        value: creationPeriod,
        setValue: setCreationPeriod,
        component: FilterTypeEnum.dateRange,
      },
    ];
  }

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

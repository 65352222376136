import React, { useState, useEffect } from 'react';
import './CustomSwitchButton.scss';
function CustomSwitchButton({
  onChange = () => { },
  defaultChecked = false,
  disabled = false
}) {
  const [checked, setChecked] = useState();
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);
  return (
    <label className="custom-switch-button">
      <input
        type="checkbox"
        defaultChecked={checked}
        disabled={disabled}
        onClick={() => {
          onChange(!checked);
          setChecked(!checked);
        }}
      />
      <span className="custom-switch-button-slider"></span>
    </label>
  );
}

export default CustomSwitchButton;

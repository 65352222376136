import React from 'react';
import addButton from '../../../assets/images/add2.png';
import deleteImage from '../../../assets/images/deleteIcon.png';
import './RewardSection.scss';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import i18n from '../../../I18n';
import AuthButton from '../../base/AuthButton';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CouponSelectDropdown from '../../campaign/campaignCreation/CouponSelectDropdown';
import {
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import InputFieldControl from '../../base/InputFieldControl';
import { CustomTitleWithRadio } from '../../earning/CustomBaseComponments';

const RewardType = { coupon: 'COUPON', points: 'POINTS' };
const rewardTypeRadioOptions = [
  { label: 'Coupon', value: RewardType.coupon },
  { label: 'Point', value: RewardType.points },
];

function RewardSection({ language, onFocus = (() => {}), languages }) {
  const { watch, setValue, control, errors } = useFormContext();
  const watchRewards = watch('stampRewards') || [{}];
  const watchTotalStampQuantity = watch('totalStampQuantity') || 0;

  const updateTotalStampQuantity = (rewardList = watchRewards) => {
    let total = 0;
    rewardList.forEach((item) => {
      const stamps = Number(item?.requiredStamps);
      total = stamps > total? stamps: total;
    });
    setValue('totalStampQuantity', total);
  };
  console.log("reward section ", errors)
  const rewardItem = (item, index) => {
    const rewardType = watch(`stampRewards[${index}].rewardType`);
    if (rewardType === undefined) {
      setValue(`stampRewards[${index}].rewardType`, RewardType.coupon, {
        shouldDirty: true,
      });
    }
    const watchQuantity = watch(`stampRewards[${index}].quantity`);
    const watchRequiredStamps = watch(`stampRewards[${index}].requiredStamps`);
    return (
      <div className="reward-item-container">
        <CustomTitleWithRadio
          title="Reward type"
          defaultValue={rewardType}
          setValue={(value) => {
            setValue(`stampRewards[${index}].rewardType`, value, {
              shouldDirty: true,
            });
          }}
          options={rewardTypeRadioOptions}
          onFocus={()=>{onFocus('rewards')}}
        />
        {rewardType === RewardType.coupon && (
          <>
            <Controller
              name={`stampRewards[${index}].linkedCoupon`}
              control={control}
              render={() => (
                <>
                  <div className="create-section-label-top-space">
                    <CouponSelectDropdown
                      title={'Link to coupon set'}
                      defaultValue={{
                        value: watchRewards[index].linkedCoupon,
                        label: watchRewards[index].linkedCoupon?.pk
                          ? `[ID: ${watchRewards[index].linkedCoupon?.pk}] ${watchRewards[index].linkedCoupon?.name}`
                          : watchRewards[index].linkedCoupon?.name,
                      }}
                      setValue={(item) => {
                        const coupon = item || {};
                        setValue(
                          `stampRewards[${index}].linkedCoupon`,
                          coupon,
                          {
                            shouldDirty: true,
                          },
                        );
                      }}
                      errors={errors}
                      errorName={`stampRewards[${index}].linkedCoupon`}
                      filterAction={`couponList/getCouponTemplateList`}
                      onFocus={()=>{onFocus('rewards')}}
                    />
                  </div>
                </>
              )}
            />
            <ReactHookFormErrorMessage
              errors={errors}
              id={`stampRewards[${index}].linkedCoupon`}
            />
          </>
        )}
        <InputFieldControl
          title={
            rewardType === RewardType.coupon
              ? 'Coupon quantity'
              : 'Point quantity'
          }
          name={`stampRewards[${index}].quantity`}
          type="number"
          value={watchQuantity}
          setValue={(value) => {
            setValue(`stampRewards[${index}].quantity`, value, {
              shouldDirty: true,
            });
          }}
          onFocus={()=>{onFocus('rewards')}}
        />
        <InputFieldControl
          title={'Required stamps'}
          name={`stampRewards[${index}].requiredStamps`}
          type="number"
          value={watchRequiredStamps}
          setValue={(value) => {
            setValue(`stampRewards[${index}].requiredStamps`, value, {
              shouldDirty: true,
            });
            updateTotalStampQuantity();
          }}
          customClass="margin-botton-section"
          onFocus={()=>{onFocus('rewards')}}
        />
        {watchRewards?.length > 1 ? (
          <div className="custom-delete-btn-container">
            <AuthButton
              children={
                <Image src={deleteImage} className="custom-delete-btn" />
              }
              customClass="custom-delete-btn"
              action={() => {
                // eslint-disable-next-line array-callback-return
                const newRewardList = watchRewards?.filter((item1, index1) => {
                  // eslint-disable-next-line eqeqeq
                  if (index != index1) {
                    return item1;
                  }
                });
                setValue('stampRewards', newRewardList, { shouldDirty: true });
                if(Number(item.requiredStamps) === watchTotalStampQuantity){
                  updateTotalStampQuantity(newRewardList);
                }
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const addButtonInfo = {
    children: <Image src={addButton} className="add-new-reward-img" />,
    action: () => {
      setValue('stampRewards', [...watchRewards, {}]);
    },
    customClass: 'add-new-reward-button',
  };
  return (
    <>
      <label className="create-section-title">
        {i18n.t('reward', { locale: language })}
      </label>
      {watchRewards?.map((item, index) => rewardItem(item, index))}
      <ReactHookFormErrorMessage errors={errors} id={'stampRewards'} />
      <CustomTitleLabel title={'Total stamp quantity'} />
      <label>{watchTotalStampQuantity}</label>
      <AuthButton {...addButtonInfo} />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(RewardSection);

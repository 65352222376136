import React from 'react';
// import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { getImageUrl } from '../../../utils';
import i18n from '../../../I18n';
import '../../campaign/campaignCreation/CreateCampaignStepFour.scss';
import { LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import stampCampaignPreview from '../../../assets/images/campaign/preview_stamp_campaigns.svg';

// const fieldsLanguages = [
//   LanguageConfig.english,
//   LanguageConfig.traditionalChinese,
//   LanguageConfig.simplifiedChinese,
// ];

// const detailPhotos = [
//   'detailPhotoOne',
//   'detailPhotoTwo',
//   'detailPhotoThree',
//   'detailPhotoFour',
// ];

function ContentSection({ languages, data }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};
  const translations = campaign.translations || {};

  // const getDetailPhotosWithOneLanguage = (language) => {
  //   const mapResult = detailPhotos.map((detailPhoto) =>
  //     translations[language][detailPhoto] ? (
  //       <Image
  //         key={`detail-photo-${language}-${translations[language][detailPhoto]}`}
  //         className="content-section-rectangle-image content-section-detail-photo"
  //         src={translations[language][detailPhoto]}
  //       />
  //     ) : null,
  //   );
  //   const deleteNullItem = mapResult.filter((result) => result);
  //   return deleteNullItem.length === 0 ? '-' : deleteNullItem;
  // };

  // const images = () => {
  //   return (
  //     <>
  //       {fieldsLanguages.map((language, index) => (
  //         <td key={`fieldsLanguages-${language}-${index}`}>
  //           <div className="content-section-detail-photo-container">
  //             {getDetailPhotosWithOneLanguage(language)}
  //           </div>
  //         </td>
  //       ))}
  //     </>
  //   );
  // };
  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    // eslint-disable-next-line no-unused-vars
    const datas = valueKeys?.forEach((valueKey) => {
      switch (valueKey) {
        case 'coverPhoto':
        case 'squareCoverPhoto':
        case 'stampCampaignTypeEmptySlotStampImage':
        case 'stampCampaignTypeAcquiredStampImage':
        case 'stampCampaignTypeEmptyGiftSlotImage': 
        case 'stampCampaignTypeCollectedGiftImage': {
          const url = getImageUrl(translations?.[language]?.[valueKey]);
          if (!url || url === '') {
            data[valueKey] = null;
          }
          data[valueKey] = url;
          break;
        }
        case 'detailPhotos': {
          data[valueKey] = translations?.[language]?.[valueKey]?.map((item) => {
            const url = getImageUrl(item);
            if (!url || url === '') {
              return null;
            }
            return url;
          });
          break;
        }
        default: {
          data[valueKey] = translations?.[language]?.[valueKey];
        }
      }
    });
    return data;
  };

  const renderTabForLanguage = (language) => {
    const data = getValueFromTranslations(language, [
      'generalName',
      'shortDescription',
      'coverPhoto',
      'squareCoverPhoto',
      'detailPhotos',
      'instructionSectionTitle',
      'instructionSectionContent',
      'detailSectionTitle',
      'detailSectionContent',
      'stampCampaignTypeEmptySlotStampImage',
      'stampCampaignTypeAcquiredStampImage',
      'stampCampaignTypeEmptyGiftSlotImage',
      'stampCampaignTypeCollectedGiftImage',
    ]);
    let fields = [
      [
        {
          title: i18n.t('name', { locale: language }),
          field: 'generalName',
        },
      ],
      [
        {
          title: i18n.t('short_description_optional', { locale: language }),
          field: 'shortDescription',
          isRichText: true,
        },
      ],
      [
        {
          title: i18n.t('cover_photo', { locale: language }),
          field: 'coverPhoto',
        },
      ],
      [
        {
          title: i18n.t('campaign.square_cover_photo', { locale: language }),
          field: 'squareCoverPhoto',
        },
      ],
      [
        {
          title: i18n.t('other_photos', { locale: language }),
          field: 'detailPhotos',
        },
      ],
    ];
    fields.push(
      [
        {
          title: i18n.t('instruction_section_title', { locale: language }),
          field: 'instructionSectionTitle',
        },
      ],
      [
        {
          title: i18n.t('instruction_section_content', { locale: language }),
          field: 'instructionSectionContent',
        },
      ],
    );
    fields.push(
      [
        {
          title: i18n.t('detail_section_title', { locale: language }),
          field: 'detailSectionTitle',
        },
      ],
      [
        {
          title: i18n.t('detail_section_content', { locale: language }),
          field: 'detailSectionContent',
        },
      ],
    );
    if (language === LanguageConfig.english) {
      if (campaign.stampCampaignTypeEmptySlotStampImage) {
        fields.push(
          [
            {
              title: i18n.t('emptyStampSlot'),
              field: 'stampCampaignTypeEmptySlotStampImage',
            },
          ],
          [
            {
              title: i18n.t('collectedStamp'),
              field: 'stampCampaignTypeAcquiredStampImage',
            },
          ],
          [
            {
              title: i18n.t('emptyGiftStampSlot'),
              field: 'stampCampaignTypeEmptyGiftSlotImage',
            },
          ],
          [
            {
              title: i18n.t('collectedGift'),
              field: 'stampCampaignTypeCollectedGiftImage',
            },
          ],
        );
      }
    }
    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: 'en' })}
        data={data}
        fields={fields}
      />
    );
  };

  return (
    <ContentSections
      hidePreview
      languageTabContent={{
        containers: languages?.map((item) => ({
          container: [renderTabForLanguage(item.code)],
          key: item.code,
          title: item.sourceName || item.name,
        })),
      }}
      activeSection={LanguageConfig.english}
      contentStyle="campaign-detail-language-content-sections"
      inputWithImageConfig={{}}
      defaultImage={stampCampaignPreview}
    />
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(ContentSection);

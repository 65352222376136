import React from 'react';
import AuthButton from '../AuthButton';
import './BaseButtons.scss';

export const BUTTON_TYPES = {
  main: 'main',
  secondary: 'secondary',
  link: 'link',
};
const PromptButton = ({
  title,
  action,
  requires,
  disabled,
  className,
  type,
  ...others
}) => {
  let variant = 'primary';
  let customeClass = `${className} btn-further base-buttons-item base-buttons-main`;
  if (type === BUTTON_TYPES.secondary) {
    customeClass = `${className} btn-back-button-common base-buttons-item base-buttons-secondary`;
  }
  if (type === BUTTON_TYPES.link) {
    variant = 'link';
    customeClass = `${className} base-buttons-link`;
  }
  return (
    <AuthButton
      variant={variant}
      disabled={disabled}
      title={title}
      action={() => (disabled ? () => {} : action())}
      customClass={customeClass}
      requires={requires}
      {...others}
    />
  );
};

export default PromptButton;

import { createModel } from './BaseModel';
import {
  APIStatus,
  SavedStatus,
} from '../config/CustomEnums';
import { apiWithResponseHandle } from './LoadingUtil';
import { getScoreSettingList, updateScoreSetting } from 'services/RecencyFrequencyMonetaryScoreSettingAPIHelper';

const scoreSettingDict = {
  recencyScoreOne: {},
  recencyScoreTwo: {},
  recencyScoreThree: {},
  recencyScoreFour: {},
  recencyScoreFive: {},
  frequencyScoreOne: {},
  frequencyScoreTwo: {},
  frequencyScoreThree: {},
  frequencyScoreFour: {},
  frequencyScoreFive: {},
  monetaryScoreOne: {},
  monetaryScoreTwo: {},
  monetaryScoreThree: {},
  monetaryScoreFour: {},
  monetaryScoreFive: {},
}

const getInitialState = () => {
  return {
    scoreSetting: scoreSettingDict,
    apiStatus: APIStatus.none,
    saved: SavedStatus.init,
    errorFields: [],
    errorMessages: [],
  }
};

const parseScoreSetting = (data) => {
  return {
    pk: data.pk,
    recencyScoreOne: {
      minNumber: data.recencyOneScoreGreaterThanDays,
    },
    recencyScoreTwo: {
      minNumber: data.recencyTwoScoreGreaterThanOrEqualDays,
      maxNumber: data.recencyTwoScoreLessThanOrEqualDays,
    },
    recencyScoreThree: {
      minNumber: data.recencyThreeScoreGreaterThanOrEqualDays,
      maxNumber: data.recencyThreeScoreLessThanOrEqualDays,
    },
    recencyScoreFour: {
      minNumber: data.recencyFourScoreGreaterThanOrEqualDays,
      maxNumber: data.recencyFourScoreLessThanOrEqualDays,
    },
    recencyScoreFive: {
      maxNumber: data.recencyFiveScoreLessThanOrEqualDays,
    },
    frequencyScoreOne: {
      maxNumber: data.frequencyOneScoreLessThanTimes,
    },
    frequencyScoreTwo: {
      minNumber: data.frequencyTwoScoreGreaterThanOrEqualTimes,
      maxNumber: data.frequencyTwoScoreLessThanOrEqualTimes,
      lookup: data.frequencyTwoScoreGreaterThanOrEqualTimes === data.frequencyTwoScoreLessThanOrEqualTimes
        ? 'Equal to'
        : 'Between',
    },
    frequencyScoreThree: {
      minNumber: data.frequencyThreeScoreGreaterThanOrEqualTimes,
      maxNumber: data.frequencyThreeScoreLessThanOrEqualTimes,
      lookup: data.frequencyThreeScoreGreaterThanOrEqualTimes === data.frequencyThreeScoreLessThanOrEqualTimes
        ? 'Equal to'
        : 'Between',
    },
    frequencyScoreFour: {
      minNumber: data.frequencyFourScoreGreaterThanOrEqualTimes,
      maxNumber: data.frequencyFourScoreLessThanOrEqualTimes,
      lookup: data.frequencyFourScoreGreaterThanOrEqualTimes === data.frequencyFourScoreLessThanOrEqualTimes
        ? 'Equal to'
        : 'Between',
    },
    frequencyScoreFive: {
      minNumber: data.frequencyFiveScoreGreaterThanOrEqualTimes,
    },
    monetaryScoreOne: {
      maxNumber: data.monetaryOneScoreLessThanNumber,
    },
    monetaryScoreTwo: {
      minNumber: data.monetaryTwoScoreGreaterThanOrEqualNumber,
      maxNumber: data.monetaryTwoScoreLessThanOrEqualNumber,
    },
    monetaryScoreThree: {
      minNumber: data.monetaryThreeScoreGreaterThanOrEqualNumber,
      maxNumber: data.monetaryThreeScoreLessThanOrEqualNumber,
    },
    monetaryScoreFour: {
      minNumber: data.monetaryFourScoreGreaterThanOrEqualNumber,
      maxNumber: data.monetaryFourScoreLessThanOrEqualNumber,
    },
    monetaryScoreFive: {
      minNumber: data.monetaryFiveScoreGreaterThanOrEqualNumber,
    },
  }
}

const scoreType = [
  "recency",
  "frequency",
  "monetary",
];

const scoreLevel = [
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
];

export const errorMessagesList = {
  "overlapped": {
    name: "overlapped", 
    message: "The scores highlighted are overlapped, please edit again.",
  },
  "notConsecutive": {
    name: "notConsecutive",
    message: "The highlighted scores are not consecutive, please edit again.",
  },
  "required": {
    name: "required",
    message: "Please provide required/valid quantity.",
  }
}

const assembleScoreSetting = (data = {}) => {
  const errorFields = [];
  const errorMessages = [];
  scoreType.forEach(singleScoreType => {
    scoreLevel.forEach((singleScoreLevel, index) => {
      const scoreName = `${singleScoreType}Score${singleScoreLevel}`;
      const lookup = data?.[scoreName]?.lookup;
      const minNumber = parseInt(data?.[scoreName]?.minNumber);
      const maxNumber = lookup === "Equal to" ? minNumber : parseInt(data?.[scoreName]?.maxNumber);

      const perviousScoreName = `${singleScoreType}Score${scoreLevel[Math.max(0, index - 1)]}`;
      const perviousLookup = data?.[perviousScoreName]?.lookup;
      const perviousMinNumber = parseInt(data?.[perviousScoreName]?.minNumber);
      const perviousMaxNumber = perviousLookup === "Equal to" ? perviousMinNumber : parseInt(data?.[perviousScoreName]?.maxNumber);

      if (index === 0) {
        if (
          (singleScoreType === 'recency' && (!minNumber || minNumber < 0))
          || ((singleScoreType === 'frequency' || singleScoreType === 'monetary') && (!maxNumber || maxNumber < 0))
        ) {
          errorFields.push(scoreName);
          errorMessages.push(errorMessagesList.required.name);
        }
      } else if (index === 4) {
        if (
          (singleScoreType === 'recency' && (!maxNumber || maxNumber < 0))
          || ((singleScoreType === 'frequency' || singleScoreType === 'monetary') && (!minNumber || minNumber < 0))
        ) {
          errorFields.push(scoreName);
          errorMessages.push(errorMessagesList.required.name);
        }
        if (singleScoreType === 'recency') {
          if (perviousMinNumber !== (maxNumber + 1)) {
            errorFields.push(scoreName);
            errorFields.push(perviousScoreName);
            errorMessages.push(perviousMinNumber < (maxNumber + 1) ? errorMessagesList.overlapped.name : errorMessagesList.notConsecutive.name);
          }
        } else {
          if (perviousMaxNumber !== (minNumber - 1)) {
            errorFields.push(scoreName);
            errorFields.push(perviousScoreName);
            errorMessages.push(perviousMaxNumber > (minNumber - 1) ? errorMessagesList.overlapped.name : errorMessagesList.notConsecutive.name);
          }
        }
      } else {
        if (!minNumber || minNumber < 0 || !maxNumber || maxNumber < 0) {
          errorFields.push(scoreName);
          errorMessages.push(errorMessagesList.required.name);
        }
        if (singleScoreType === 'recency') {
          if (perviousMinNumber !== (maxNumber + (index === 1 ? 0 : 1))) {
            errorFields.push(scoreName);
            errorFields.push(perviousScoreName);
            errorMessages.push(perviousMinNumber < (maxNumber + (index === 1 ? 0 : 1)) ? errorMessagesList.overlapped.name : errorMessagesList.notConsecutive.name);
          }
        } else  {
          if (perviousMaxNumber !== (minNumber - (index === 1 ? 0 : 1))) {
            errorFields.push(scoreName);
            errorFields.push(perviousScoreName);
            errorMessages.push(perviousMaxNumber > (minNumber - (index === 1 ? 0 : 1)) ? errorMessagesList.overlapped.name : errorMessagesList.notConsecutive.name);
          }
        }
      }
    })
  })

  const input = {
    id: data.pk,
    recencyOneScoreGreaterThanDays: data.recencyScoreOne.minNumber,
    recencyTwoScoreGreaterThanOrEqualDays: data.recencyScoreTwo.minNumber,
    recencyTwoScoreLessThanOrEqualDays: data.recencyScoreTwo.maxNumber,
    recencyThreeScoreGreaterThanOrEqualDays: data.recencyScoreThree.minNumber,
    recencyThreeScoreLessThanOrEqualDays: data.recencyScoreThree.maxNumber,
    recencyFourScoreGreaterThanOrEqualDays: data.recencyScoreFour.minNumber,
    recencyFourScoreLessThanOrEqualDays: data.recencyScoreFour.maxNumber,
    recencyFiveScoreLessThanOrEqualDays: data.recencyScoreFive.maxNumber,

    frequencyOneScoreLessThanTimes: data.frequencyScoreOne.maxNumber,
    frequencyTwoScoreLessThanOrEqualTimes: data.frequencyScoreTwo.lookup === "Equal to" ? data.frequencyScoreTwo.minNumber: data.frequencyScoreTwo.maxNumber,
    frequencyTwoScoreGreaterThanOrEqualTimes: data.frequencyScoreTwo.minNumber,
    frequencyThreeScoreLessThanOrEqualTimes: data.frequencyScoreThree.lookup === "Equal to" ? data.frequencyScoreThree.minNumber: data.frequencyScoreThree.maxNumber,
    frequencyThreeScoreGreaterThanOrEqualTimes: data.frequencyScoreThree.minNumber,
    frequencyFourScoreLessThanOrEqualTimes: data.frequencyScoreFour.lookup === "Equal to" ? data.frequencyScoreFour.minNumber: data.frequencyScoreFour.maxNumber,
    frequencyFourScoreGreaterThanOrEqualTimes: data.frequencyScoreFour.minNumber,
    frequencyFiveScoreGreaterThanOrEqualTimes: data.frequencyScoreFive.minNumber,

    monetaryOneScoreLessThanNumber: data.monetaryScoreOne.maxNumber,
    monetaryTwoScoreLessThanOrEqualNumber: data.monetaryScoreTwo.maxNumber,
    monetaryTwoScoreGreaterThanOrEqualNumber: data.monetaryScoreTwo.minNumber,
    monetaryThreeScoreLessThanOrEqualNumber: data.monetaryScoreThree.maxNumber,
    monetaryThreeScoreGreaterThanOrEqualNumber: data.monetaryScoreThree.minNumber,
    monetaryFourScoreLessThanOrEqualNumber: data.monetaryScoreFour.maxNumber,
    monetaryFourScoreGreaterThanOrEqualNumber: data.monetaryScoreFour.minNumber,
    monetaryFiveScoreGreaterThanOrEqualNumber: data.monetaryScoreFive.minNumber,
  };

  console.log("@@202", errorFields, errorMessages, input)

  return {
    input,
    errorFields,
    errorMessages,
  }
}


export default createModel({
  namespace: 'recencyFrequencyMonetaryScoreSetting',
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateScoreSettingField(state, { payload }) {
      const { fieldName, subName, value } = payload;
      return {
        ...state,
        scoreSetting: {
          ...state.scoreSetting,
          [fieldName]: {
            ...state.scoreSetting[fieldName],
            [subName]: value,
          }
        }
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getList: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getScoreSettingList];

        function* onSuccess(data) {
          console.log('@@115: ', data);
          const scoreSettings = data?.recencyFrequencyMonetaryScoreSettings?.edges.map((item, index) => {
            return parseScoreSetting(item.node)
          });
          let scoreSetting = scoreSettingDict;
          if (scoreSettings && scoreSettings.length > 0) {
            scoreSetting = scoreSettings?.[0]
          }
          yield put({
            type: 'updateState',
            payload: {
              scoreSetting,
              apiStatus: APIStatus.success,
            },
          });
        }

        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: { apiStatus: APIStatus.failed },
          });
        }

        yield put({
          type: 'updateState',
          payload: { apiStatus: APIStatus.calling },
        });

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    update: [
      function* ({ payload }, { call, select, put }) {
        const { scoreSetting } = yield select((state) => ({
          scoreSetting: state.recencyFrequencyMonetaryScoreSetting.scoreSetting,
        }));
        const { input, errorFields, errorMessages } = assembleScoreSetting(scoreSetting);

        yield put({
          type: 'updateState',
          payload: {
            errorFields,
            errorMessages,
          }
        })

        if (errorFields.length > 0) {
          return;
        }

        const serviceArgs = [updateScoreSetting, input];
        function* onSuccess(data) {
          console.log('@@score setting update success:', data);
          yield put({
            type: 'updateState',
            payload: { saved: SavedStatus.savedWithSuccess },
          });
        }
        function* onFailed(data) {
          console.log('@@score setting update failed:', data);
          yield put({
            type: 'updateState',
            payload: { saved: SavedStatus.savedWithFail },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
});

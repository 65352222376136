import { APIStatus, LanguageConfig } from '../config/CustomEnums';
import {
  createWebview,
  updateWebview,
  deleteWebviews,
  getOneWebview,
  getWebviews,
} from '../services/WebviewApiHelper';
import { createModel } from './BaseModel';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';

const getInitState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Name', fieldName: 'name' },
    {
      displayName: 'Create date',
      fieldName: 'creationDate',
    },
  ],
});

// const getNameByLanguage =(data,language)=>{
//     const translationsEdges = data?.translations?.edges || []
//     let result = '';
//     translationsEdges.forEach((item)=>{
//         if(language === item.node.language){
//             result = item.node.name
//         }
//     })
//     return result || data?.name
// }

const getPostData = (originData) => {
  const { name, translations } = originData;
  let newTranslations = [];
  for (const [key, value] of Object.entries(translations)) {
    if (key === LanguageConfig.english) {
      continue;
    }
    if (value?.description == null) {
      continue;
    }
    const translationData = {
      content: value?.description,
      language: key,
    };
    if (value?.id) {
      translationData.id = value.id;
    }
    newTranslations.push(translationData);
  }
  return {
    name,
    content:translations[LanguageConfig.english]?.description,
    translations: newTranslations,
  }
};

const parseWebview = (data) => {
    const translationsEdges = data.translations?.edges || [];
    const translations = {};
  translations[LanguageConfig.english] = {
    content: data.content,
  };
  if (translationsEdges.length) {
    translationsEdges.forEach((item) => {
      let language = item.node.language;
      translations[language] = {
        id: item.node.pk,
        language: item.node.language,
        content: item.node.content,
      };
    });
  }
  return {
    ...data,
    creationDate: formatDate(data.creationDate, TimeFormater.dayMonthWeek),
    translations,
  };
};

const fixTranslations = (translations) => {
  const translationsToDelete = [];
  const fixedTranslations = translations.filter((translation) => {
    const { content, id } = translation;
    if (id && (content === '' || content === null || content === undefined)) {
      translationsToDelete.push(id);
      return false;
    }
    return true;
  });
  return [translationsToDelete, fixedTranslations];
};

export default createModel({
  namespace: 'webview',
  states: getInitState(),
  params: {
    listAPI: getWebviews,
    parse: (data) =>
      data?.webviews.edges.map((item) => parseWebview(item.node)),
    pkNode: 'WebviewNode',
    detailAPI: getOneWebview,
    deleteAPI: deleteWebviews,
    parseDetail: (data) => parseWebview(data.webview),
    objectKey: 'webviews',
    initState: getInitState(),
  },
  reducers: {},
  effects: {
    createOrUpdateWebview: [
      function* ({ payload }, { put }) {
        const { values, webviewId } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = getPostData(values);
        let serviceArgs = [createWebview, input];
        if (webviewId) {
          const id = parseInt(webviewId);
          if (isNaN(id)) {
            return;
          }
          const [translationsToDelete, fixedTranslations] = fixTranslations(
            input.translations,
          );
          const updateData = {
            id:id,
            name: input.name,
            content: input.content,
            translations: fixedTranslations,
            translationsToDelete: translationsToDelete,
          };
          serviceArgs = [updateWebview, updateData];
        }
        yield put({
          type: 'responseCreateOrUpdate',
          payload: {
            serviceArgs,
            isCreate: true,
          },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});

import React from 'react';
import _ from 'lodash';
import { get } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import './ErrorFieldMessage.scss';

function ErrorFieldMessage({
  id,
  error = false,
  extra = '',
  message = '',
}) {
  return error ? (
    <label id={id} className={`error-field-message-style ${extra}`}>
      {message}
    </label>
  ) : null;
}

export const hasError = (errors, id) => {
  const error = _.get(errors, id);
  return error && error.message;
};

export const errorMessage = (errors, id) => {
  const error = _.get(errors, id);
  return error?.message;
};

export const getFirstError = (errors, errorKeyOrder = []) => {
  let error = null;
  let key = null;
  errorKeyOrder.some((errorKey) => {
    const errorForKey = _.get(errors, errorKey);
    console.log(errorForKey, errorKey);
    if (errorForKey) {
      error = errorForKey;
      key = errorKey;
      return true;
    }
    return false;
  });
  return {
    error,
    key,
  };
};

export const ReactHookFormErrorMessage = ({ errors, id, extra }) => {
  const error = _.get(errors, id);
  return error && error.message ? (
    <label id={id} className={`error-field-message-style ${extra}`}>
      {error.message}
    </label>
  ) : null;
};

export const CustomErrorMessage = ({ name, extra }) => (
  <ErrorMessage
    name={name}
    render={({ message }) => (
      <label className={`error-field-message-style ${extra}`}>
        {message}
      </label>
    )}
  />
)
export const hasReactHookFormError = (errors, name) => {
  const error = get(errors, name);
  if (!error) {
    return false;
  }
  return true;
}

export const hasErrorFromList = (errors = [], id) => {
  return errors.find(item => item.name === id)
}

export default ErrorFieldMessage;

import React from 'react';
import { useDispatch } from 'react-redux';
import './Dashboard.scss';
import '../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomTips from '../../components/base/CustomTips';
import CustomersCard from '../../components/dashboard/CustomersCard';
import CampaignsCard from '../../components/dashboard/CampaignsCard';
import CouponsCard from '../../components/dashboard/CouponsCard';
import DateRangeSelection from '../../components/dashboard/DateRangeSelection';
import { createAction } from '../../utils';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';
import Onboarding from '../onboarding/Onboarding';

const Dashboard = () => {
  const dispatch = useDispatch();
  const tip =
    'The dashboard shows an overview of the customers, campaigns, and coupons. The percentage change is compared to the previous period.';
  return (
    <>
      <div className="list-section-container-header list-section-container-header-bottom dashboard-header">
        <CustomBreadcrumb />
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left dashboard-header-left">
            <div className="dashboard-title">
              <CustomTips detail={tip}>
                <label className="list-section-container-header-title">
                  {NavBarNames.dashboard}
                </label>
              </CustomTips>
            </div>
            <DateRangeCompare />
          </div>
          <DateRangeSelection
            applyDateRangeEvent={(startDate, endDate) => {
              dispatch(
                createAction('dashboard/getDashboardData')({
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                }),
              );
            }}
            clearDataEvent={() => {
              dispatch(createAction('dashboard/clearState')());
            }}
          />
        </div>
      </div>
      <CustomersCard />
      <div className="dashboard-campaigns-coupons">
        <CouponsCard />
        <CampaignsCard />
      </div>

      <Onboarding />
    </>
  );
};

export default Dashboard;

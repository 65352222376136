import { Viewer } from '@toast-ui/react-editor'
import React, { useEffect } from 'react'
import './CustomRichTextViewer.scss'

const CustomRichTextViewer = ({
  initialValue = {}
}) => {
  const viewerRef = React.useRef(null);
  useEffect(()=>{
    const ref = viewerRef.current;
    if (ref) {
      ref.getInstance().setMarkdown(initialValue);
    }
  }, [initialValue])
  console.log("RICH TEXT",initialValue, viewerRef);
  return (
    <div id='custom-richtext-viewer'>
      <Viewer
        ref={viewerRef}
        initialValue={initialValue}
      />
    </div>

  )
}

export default CustomRichTextViewer
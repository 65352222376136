import { LanguageConfig } from '../../../config/CustomEnums';

function firstStepValidate({ getValues, setError }) {
  const nameField = `translations.${LanguageConfig.english}.name`;
  const orderField = 'order';
  const subCategoriesField = 'subCategories';
  const [
    name,
    order,
    subCategories,
  ] = getValues([
    nameField,
    orderField,
    subCategoriesField,
  ]);
  const errorList = [];
  if (!name || name === '') {
    errorList.push({
      name: nameField,
      message: 'Please provide a name',
    });
  }
  const displayPriority = parseInt(order);
  if (isNaN(displayPriority) || displayPriority < 1) {
    errorList.push({
      name: orderField,
      message: 'Minimum value is 1',
    });
  }

  subCategories.forEach((subCategory, index) => {
    const subCateOrder = subCategory?.order;
    const subCateName = subCategory?.translations?.[LanguageConfig.english]?.name;
    const hasName = !(subCateName === '' || subCateName === null || subCateName === undefined);
    const displayPriority = parseInt(subCateOrder);
    if (!isNaN(displayPriority) || hasName) {
      if (!hasName) {
        errorList.push({
          name: `subCategories.${index}.translations.${LanguageConfig.english}.name`,
          message: 'Please provide a subcategory name',
        });
      }
      if (isNaN(displayPriority) || displayPriority < 1) {
        errorList.push({
          name: `subCategories.${index}.order`,
          message: 'Minimum value is 1',
        });
      }
    }
  });
  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      }
    );
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step }) {
  console.log('@71 CreateStoreCategoryValidate:', getValues());
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
  }
}

import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';

function MessageCreditRecordFilter({ backAction = () => { } }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchCreditConsumed = parsedSearch['credit_consumed'] || "";
  const searchCreationPeriod = parsedSearch['create_at'] || '';

  const [creditConsumed, setCreditConsumed] = useState(searchCreditConsumed?.split(","));
  const [creationPeriod, setCreationPeriod] = useState(searchCreationPeriod);

  const content = [
    {
      title: 'Credit consumed',
      value: creditConsumed,
      setValue: setCreditConsumed,
      component: FilterTypeEnum.inputNumberRange,
    },
    {
      title: 'Create at',
      value: creationPeriod,
      setValue: setCreationPeriod,
      component: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default MessageCreditRecordFilter;

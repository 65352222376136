import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { TitleWithButton } from './utils';
import queryString from 'query-string';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import RecordTableCard from './RecordTableCard';
import { PermissionCodes } from 'config/PermissionCodes';

const fields = [
  [
    { title: "Average order value", field: 'averageOrder' },
    { title: "Total number of orders", field: 'totalOrders' },
    { title: "Days from last order", field: 'lastOrderDays' },
  ],
];

const PurchaseItems = ({
  customer,
  transactions,
}) => {
  const history = useHistory();

  return (
    <>
      <TitleWithButton
        containerExtraClassName="customer-detail-section-title-container"
        title="PURCHASES"
        titleExtraClassName="customer-detail-section-title"
        firstButtonContent="See all"
        firstButtonOnClick={() => {
          history.push({
            pathname: '/transactions',
            search: queryString.stringify({
              search: customer.membershipId
            }),
          });
        }}
        firstButtonRequires={PermissionCodes.viewTransaction}
      />

      <MembershipInfoCard
        title=""
        data={customer}
        fields={fields}
      />

      <RecordTableCard
        data={transactions?.slice(0, 5)}
        fields={[
          { displayName: 'ID', name: 'pk' },
          { displayName: 'Store', name: 'displayStoreName' },
          { displayName: 'Total Value', name: 'transactionValue' },
          { displayName: 'Event Type', name: 'eventType' },
          { displayName: 'Transaction Date', name: 'transactionDetailDisplayDate' },
        ]}
      />

    </>
  )
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  transactions: state.transactions.currentPageTransactionList || [],
});

export default connect(mapPropsToState)(PurchaseItems);
import { 
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
  COUPON_SET_MEDIUM,
 } from '../../../config/CustomEnums';
import {
  VALID_PERIOD_TYPE,
} from '../../../models/CreateCouponModel';
import { CouponErrorFields } from '../../../components/coupon/couponCreation/CouponHandleError'


function firstStepValidate({ getValues, setError }) {
  return true;
}

function secondStepValidate({ getValues, setError }) {
  const [
    name,
    coverPhoto,
    selectedBrand,
    shouldUseInStore,
    selectedStores,
  ] = getValues([
    'translations.en.name',
    'translations.en.coverPhoto',
    'selectedBrand',
    'shouldBeUsedInStores',
    'selectedStores',
  ])
  const errorList = [];
  if (!name || name.length > 200) {
    errorList.push({
      name: 'translations.en.name',
      message: CouponErrorFields.generalName.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: CouponErrorFields.coverPhotoEmpty.message,
    });
  }
  if (!selectedBrand) {
    errorList.push({
      name: 'selectedBrand',
      message: CouponErrorFields.brand.message
    });
  }
  console.log("@59", selectedStores, selectedBrand, shouldUseInStore)
  if (selectedBrand && shouldUseInStore && selectedStores.length === 0) {
    console.log("@61")
    errorList.push({
      name: 'selectedStores',
      message: CouponErrorFields.applicationStore.message
    });
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const [
    validPeriodType,
    numberOfDaysToExpireAfterAcquisition,
    absoluteEffectiveDate,
    absoluteExpiryDate,
    mediumType,
    offlineCouponSetType,
    offlineFormats,
    offlineCouponCodeType,
    offlineGenericCode,
    offlineRedemptionMethod,
    onlineCouponSetType,
    onlineFormats,
    onlineCouponCodeType,
    onlineGenericCode,
    isDifferentCodeForOnlineOffline,
    onlineAndOfflineCouponCodeType,
    onlineAndOfflineFormats,
    onlineAndOfflineGenericCode
  ] = getValues([
    'validPeriodType',
    'numberOfDaysToExpireAfterAcquisition',
    'absoluteEffectiveDate',
    'absoluteExpiryDate',
    'mediumType',
    'offlineCouponSetType',
    'offlineFormats',
    'offlineCouponCodeType',
    'offlineGenericCode',
    'offlineRedemptionMethod',
    'onlineCouponSetType',
    'onlineFormats',
    'onlineCouponCodeType',
    'onlineGenericCode',
    'isDifferentCodeForOnlineOffline',
    'onlineAndOfflineCouponCodeType',
    'onlineAndOfflineFormats',
    'onlineAndOfflineGenericCode'
  ]);

  const offlineCouponValidate = () => {
    if (offlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      if (
        offlineRedemptionMethod !==
        COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
      ) {
        if (offlineFormats === null || offlineFormats === undefined) {
          errorList.push({
            name: 'offlineFormats',
            message: "Please select code formart.",
          });
        }
      }

      if (offlineCouponCodeType === null || offlineCouponCodeType === undefined) {
        errorList.push({
          name: 'offlineCouponCodeType',
          message: "Please select coupon code type.",
        });
      }

      if (
        offlineCouponCodeType === COUPON_CODE_TYPE.GENERIC &&
        (offlineGenericCode === null || offlineGenericCode === undefined || !offlineGenericCode)
      ) {
        errorList.push({
          name: 'offlineGenericCode',
          message: "Please input Generic code.",
        });
      }
    }
  }

  const onlineCouponValidate = () => {
    if (onlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      if (onlineFormats === null || onlineFormats === undefined) {
        errorList.push({
          name: 'onlineFormats',
          message: "Please select code formart.",
        });
      }

      if (onlineCouponCodeType === null || onlineCouponCodeType === undefined) {
        errorList.push({
          name: 'onlineCouponCodeType',
          message: "Please select coupon code type.",
        });
      }

      if (
        onlineCouponCodeType === COUPON_CODE_TYPE.GENERIC &&
        (onlineGenericCode === null || onlineGenericCode === undefined || !onlineGenericCode)
      ) {
        errorList.push({
          name: 'onlineGenericCode',
          message: "Please input Generic code.",
        });
      }
    }
  }

  const onlineAndOfflineValidate = () => {
    if (offlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
      && onlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      if (onlineAndOfflineFormats === null || onlineAndOfflineFormats === undefined) {
        errorList.push({
          name: 'onlineAndOfflineFormats',
          message: "Please select code formart.",
        });
      }

      if (onlineAndOfflineCouponCodeType === null || onlineAndOfflineCouponCodeType === undefined) {
        errorList.push({
          name: 'onlineAndOfflineCouponCodeType',
          message: "Please select coupon code type.",
        });
      }

      if (
        onlineAndOfflineCouponCodeType === COUPON_CODE_TYPE.GENERIC &&
        (onlineAndOfflineGenericCode === null || onlineAndOfflineGenericCode === undefined || !onlineAndOfflineGenericCode)
      ) {
        errorList.push({
          name: 'onlineAndOfflineGenericCode',
          message: "Please input Generic code.",
        });
      }
    } else if (onlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      onlineCouponValidate()
    } else {
      offlineCouponValidate()
    }
  }

  const errorList = [];

  if (validPeriodType === VALID_PERIOD_TYPE.ABSOLUTE &&
    absoluteExpiryDate <= absoluteEffectiveDate) {
    errorList.push({
      name: 'absoluteExpiryDate',
      message: CouponErrorFields.endDatetime.message,
    });
  }
  if (validPeriodType === VALID_PERIOD_TYPE.RELATIVE &&
    !numberOfDaysToExpireAfterAcquisition) {
    errorList.push({
      name: 'numberOfDaysToExpireAfterAcquisition',
      message: CouponErrorFields.relativeDay.message,
    });
  }
  if (mediumType === COUPON_SET_MEDIUM.ONLINE) {
    onlineCouponValidate()
  } else if (mediumType === COUPON_SET_MEDIUM.OFFLINE) {
    offlineCouponValidate()
  } else if (mediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
    if (isDifferentCodeForOnlineOffline) {
      onlineCouponValidate()
      offlineCouponValidate()
    } else {
      onlineAndOfflineValidate()
    }
  }


  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  console.log('@200 couponValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}

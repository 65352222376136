import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CustomRadios from '../../base/CustomRadios';
import './CouponDateTimeSelector.scss';
import {
  COUPON_SET_TYPES,
  COUPON_FORMATS,
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_MEDIUM
} from '../../../config/CustomEnums';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import CouponBarcodeSelectDropdown from './CouponBarcodeSelectDropdown';
import CustomTitleWithSwitch from '../../base/CustomTitleWithSwitch';
import { SectionTopTitle } from 'components/base/CommonComponent';

const CouponCodeSettingSection = () => {
  const {
    watch,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  const watchOfflineCouponSetType = watch('offlineCouponSetType');
  const watchOfflineFormats = watch('offlineFormats');
  const watchOfflineCouponBarcode = watch('offlineBarcodeFormat');
  const watchOfflineCouponCodeType = watch('offlineCouponCodeType');
  const watchOfflineRedemptionMethod = watch('offlineRedemptionMethod');
  const watchOfflineShowCopiableString = watch('offlineShowCopiableString')

  const watchOnlineCouponSetType = watch('onlineCouponSetType');
  const watchOnlineFormats = watch('onlineFormats');
  const watchOnlineCouponCodeType = watch('onlineCouponCodeType');

  const watchOnlineAndOfflineCouponCodeType = watch('onlineAndOfflineCouponCodeType');
  const watchOnlineAndOfflineFormats = watch('onlineAndOfflineFormats');
  const watchOnlineAndOfflineGenericCode = watch('onlineAndOfflineGenericCode')

  const watchMediumType = watch('mediumType');
  const watchIsDifferentCodeForOnlineOffline = watch('isDifferentCodeForOnlineOffline');


  useEffect(() => {
    if ((watchMediumType === COUPON_SET_MEDIUM.ONLINE &&
      watchOnlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON)
      || (watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE)) {
      setValue('onlineFormats', COUPON_FORMATS.TEXT, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMediumType, watchOnlineCouponSetType])

  const getOnlineSettingView = () => {
    return (
      <>
        <div>
          <SectionTopTitle title={'online Coupon code setting'} />
        </div>
        <div className="second-section">
          <label className="create-section-label">Coupon code setting</label>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="onlineFormats"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('onlineFormats', value, { shouldDirty: true });
                  }}
                  default={watchOnlineFormats}
                  options={[
                    {
                      label: 'TEXT',
                      value: COUPON_FORMATS.TEXT,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="onlineFormats" />
        </div>

        <div className="second-section">
          <label className="create-section-label">
            {'Generic code or Unique code'}
          </label>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="onlineCouponCodeType"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('onlineCouponCodeType', value, { shouldDirty: true });
                  }}
                  default={watchOnlineCouponCodeType}
                  options={[
                    {
                      label: 'Generic',
                      value: COUPON_CODE_TYPE.GENERIC,
                    },
                    {
                      label: 'Unique',
                      value: COUPON_CODE_TYPE.UNIQUE,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="onlineCouponCodeType" />
        </div>

        {watchOnlineCouponCodeType === COUPON_CODE_TYPE.GENERIC ? (
          <Controller
            control={control}
            name="onlineGenericCode"
            render={() => (
              <>
                <CustomTitleWithInput
                  title={'Generic code'}
                  defaultValue={watch('onlineGenericCode')}
                  error={{ error: hasError(errors, 'onlineGenericCode') }}
                  setValue={(value) => {
                    setValue('onlineGenericCode', value);
                  }}
                  multipleLines={false}
                  customClass={''}
                />
                <ReactHookFormErrorMessage errors={errors} id="onlineGenericCode" />
              </>
            )}
          />
        ) : null}
      </>
    )
  }

  const getOfflineSettingView = (haveTopMargin = false) => {
    return (
      <>
        <div className={haveTopMargin ? 'second-section' : ''}>
          <SectionTopTitle title={'offline Coupon code setting'} />
        </div>
        <div className="second-section">
          <label className="create-section-label">Coupon code setting</label>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="offlineFormats"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('offlineFormats', value, { shouldDirty: true });
                  }}
                  default={watchOfflineFormats}
                  options={[
                    {
                      label: 'QR code',
                      value: COUPON_FORMATS.COUPON_QR_CODE,
                    },
                    {
                      label: 'Barcode',
                      value: COUPON_FORMATS.COUPON_BAR_CODE,
                    },
                    {
                      label: 'TEXT',
                      value: COUPON_FORMATS.TEXT,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="offlineFormats" />
        </div>

        {watchOfflineFormats === COUPON_FORMATS.COUPON_BAR_CODE ? (
          <CouponBarcodeSelectDropdown
            title={'Barcode format'}
            defaultValue={{
              label: watchOfflineCouponBarcode?.name,
              value: watchOfflineCouponBarcode,
            }}
            onSelectChange={(value) => {
              setValue('offlineBarcodeFormat', value, { shouldDirty: true });
            }}
          />
        ) : null}

        <div className="second-section">
          <label className="create-section-label">
            {'Generic code or Unique code'}
          </label>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="offlineCouponCodeType"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('offlineCouponCodeType', value, { shouldDirty: true });
                  }}
                  default={watchOfflineCouponCodeType}
                  options={[
                    {
                      label: 'Generic',
                      value: COUPON_CODE_TYPE.GENERIC,
                    },
                    {
                      label: 'Unique',
                      value: COUPON_CODE_TYPE.UNIQUE,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="offlineCouponCodeType" />
        </div>

        {watchOfflineCouponCodeType === COUPON_CODE_TYPE.GENERIC ? (
          <Controller
            control={control}
            name="offlineGenericCode"
            render={() => (
              <>
                <CustomTitleWithInput
                  title={'Generic code'
                  }
                  defaultValue={watch('offlineGenericCode')}
                  error={{ error: hasError(errors, 'offlineGenericCode') }}
                  setValue={(value) => {
                    setValue('offlineGenericCode', value);
                  }}
                  multipleLines={false}
                  customClass={''}
                />
                <ReactHookFormErrorMessage errors={errors} id="offlineGenericCode" />
              </>
            )}
          />
        ) : null}
        {watchOfflineRedemptionMethod ===
          COUPON_REDEMPTION_METHOD.POS_UPDATED_CODE && (
            <div className="second-section no-margin">
              <div>
                <Controller
                  control={control}
                  name="offlineShowCopiableString"
                  render={() => (
                    <>
                      <CustomTitleWithSwitch
                        title={'Show copy code button'}
                        defaultValue={watchOfflineShowCopiableString}
                        shouldDirty={true}
                        setValue={(value) => {
                          setValue('offlineShowCopiableString', value)
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          )}
      </>)
  }

  const getOnlineAndOfflineSettingView = () => {
    return (
      <>
        <div>
          <SectionTopTitle title={'Both online and Offline Coupon code setting'} />
        </div>
        <div className="second-section">
          <label className="create-section-label">Coupon code setting</label>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="onlineAndOfflineFormats"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('onlineAndOfflineFormats', value, { shouldDirty: true });
                  }}
                  default={watchOnlineAndOfflineFormats}
                  options={[
                    {
                      label: 'TEXT',
                      value: COUPON_FORMATS.TEXT,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="onlineAndOfflineFormats" />
        </div>

        <div className="second-section">
          <label className="create-section-label">
            {'Generic code or Unique code'}
          </label>
          <div style={{ display: 'flex' }}>
            <Controller
              control={control}
              name="onlineAndOfflineCouponCodeType"
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    setValue('onlineAndOfflineCouponCodeType', value, { shouldDirty: true });
                  }}
                  default={watchOnlineAndOfflineCouponCodeType}
                  options={[
                    {
                      label: 'Generic',
                      value: COUPON_CODE_TYPE.GENERIC,
                    },
                    {
                      label: 'Unique',
                      value: COUPON_CODE_TYPE.UNIQUE,
                    },
                  ]}
                />
              )}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="onlineAndOfflineCouponCodeType" />
        </div>

        {watchOnlineAndOfflineCouponCodeType === COUPON_CODE_TYPE.GENERIC ? (
          <Controller
            control={control}
            name="onlineAndOfflineGenericCode"
            render={() => (
              <>
                <CustomTitleWithInput
                  title={'Generic code'}
                  defaultValue={watchOnlineAndOfflineGenericCode}
                  error={{ error: hasError(errors, 'onlineAndOfflineGenericCode') }}
                  setValue={(value) => {
                    setValue('onlineAndOfflineGenericCode', value);
                  }}
                  multipleLines={false}
                  customClass={''}
                />
                <ReactHookFormErrorMessage errors={errors} id="onlineAndOfflineGenericCode" />
              </>
            )}
          />
        ) : null}
      </>
    )
  }

  const getCouponCodeSettingView = () => {
    if (watchMediumType === COUPON_SET_MEDIUM.OFFLINE && watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      return getOfflineSettingView()
    }
    if (watchMediumType === COUPON_SET_MEDIUM.ONLINE && watchOnlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
      return getOnlineSettingView()
    }
    if (watchMediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
      if (watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        watchOnlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
        return (
          <>
            {watchIsDifferentCodeForOnlineOffline ? (
              <>
                {getOnlineSettingView()}
                <div className='create-section-split-line dashed-border' />
                {getOfflineSettingView(true)}
              </>) : (
              <>{getOnlineAndOfflineSettingView()}</>
            )}
          </>)
      }
      if (watchOfflineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
        return getOfflineSettingView()
      }
      if (watchOnlineCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON) {
        return getOnlineSettingView()
      }
    }
    return;
  }

  return (
    <div>
      {getCouponCodeSettingView()}
    </div>)
}
export default CouponCodeSettingSection;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  COUPON_REDEMPTION_METHOD,
  COUPON_SET_TYPES,
  COUPON_SET_MEDIUM,
} from 'config/CustomEnums';
import BaseFilter, {
  FilterTypeEnum,
} from 'components/base/BaseFilter';
import { ArrayStringData, getHashKeyString } from 'utils';

function Filter({ campaignList, backAction = () => { } }) {
  const location = useLocation();
  const activeTab = getHashKeyString(location.hash) || 'Records';

  const parsedSearch = queryString.parse(location.search);
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchCouponMedium = parsedSearch['coupon_medium'] || '';
  const searchExpiredDateType = parsedSearch['expired_date'] || '';
  const searchAbsoluteDate = parsedSearch['absolute'] || '';
  const searchRelativeDay = parsedSearch['relativeDay'] || '';
  const searchCreationPeriod = parsedSearch['create_date'] || '';
  const searchCampaigns = ArrayStringData(parsedSearch['campaigns'] || "");
  const searchTimeRange = parsedSearch['time_range'] || '';
  const searchReportType = parsedSearch['report_type'] || '';

  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [couponMedium, setCouponMedium] = useState(ArrayStringData(searchCouponMedium));
  const [expiredDateType, setExpiredDateType] = useState(searchExpiredDateType);
  const [absoluteDate, setAbsoluteDate] = useState(searchAbsoluteDate);
  const [relativeDay, setRelativeDay] = useState(searchRelativeDay);
  const [creationPeriod, setCreationPeriod] = useState(searchCreationPeriod);
  const [targetCampaigns, setTargetCampaigns] = useState([]);
  const [reportsTimeRange, setReportsTimeRange] = useState(searchTimeRange);
  const [reportType, setReportType] = useState(searchReportType);

  useEffect(() => {
    if (activeTab === "Records" && searchCampaigns && campaignList) {
      let checkedCampaigns = []
      searchCampaigns.forEach((item, index) => {
        const checkedSingeCampaign = campaignList.find(value => value?.pk === item)
        if (checkedSingeCampaign) {
          checkedCampaigns.push({
            label: `[ID: ${checkedSingeCampaign?.pk}] ${checkedSingeCampaign?.name}`,
            value: checkedSingeCampaign
          })
        }
      })
      setTargetCampaigns(checkedCampaigns)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let content;
  if (activeTab === "Reports") {
    content = [
      {
        title: 'Report type',
        data: [
          { pk: 'WEEKLY', name: 'Weekly report' },
          { pk: 'MONTHLY', name: 'Monthly report' },
        ],
        value: reportType,
        setValue: setReportType,
        component: FilterTypeEnum.singleChoice,
        className: 'fit-content-height-area',
      },
      {
        title: 'Time range',
        value: reportsTimeRange,
        setValue: setReportsTimeRange,
        component: FilterTypeEnum.dateRange,
      },
    ]
  } else {
    content = [
      {
        title: 'Record type',
        data: [
          { name: 'Customer earned', pk: 'EARN' },
          { name: 'Customer acquired', pk: 'BUY' },
          { name: 'Admin added', pk: 'GRANT' },
          { name: 'Admin removed', pk: 'RECLAIM' },
          { name: 'Customer use', pk: 'USE' },
          { name: 'Coupon expired', pk: 'EXPIRED' },
          { name: 'Admin deactivated', pk: 'DEACTIVATE' },
          { name: 'Admin reactivated', pk: 'REACTIVATE' },
        ],
        value: recordType,
        setValue: setRecordType,
        component: FilterTypeEnum.choice,
      },
      {
        title: 'Coupon medium',
        data: [
          { name: 'Online only', pk: COUPON_SET_MEDIUM.ONLINE },
          { name: 'Offline only', pk: COUPON_SET_MEDIUM.OFFLINE },
          { name: 'Online or offline', pk: COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE },
        ],
        value: couponMedium,
        setValue: setCouponMedium,
        component: FilterTypeEnum.choice,
        className: 'fit-content-height-area',
      },
      {
        title: 'Expired date',
        data: [
          { name: 'All time', pk: 'allTime', subComponment: null },
          {
            name: 'Time Range',
            pk: 'absolute',
            subComponment: {
              value: absoluteDate,
              setValue: setAbsoluteDate,
              component: FilterTypeEnum.dateRange,
            },
          },
          {
            name: 'Since acquiring',
            pk: 'relativeDay',
            subComponment: {
              value: relativeDay,
              setValue: setRelativeDay,
              component: FilterTypeEnum.input,
            },
          },
        ],
        value: expiredDateType,
        setValue: setExpiredDateType,
        component: FilterTypeEnum.singleChoice,
      },
      {
        title: 'Create date',
        value: creationPeriod,
        setValue: setCreationPeriod,
        component: FilterTypeEnum.dateRange,
      },
      { 
        filterTitle: 'Campaigns',
        data: campaignList,
        value: targetCampaigns,
        setValue: setTargetCampaigns,
        placeholder: 'Search by campaign name',
        loadMoreAction: 'campaignList/getCampaignList',
        filterAction: 'campaignList/getCampaignList',
        customItem: (item) => `[ID: ${item?.pk}] ${item?.name}`,
        defaultFilter: {
          isSimpleList: true,
          isAvailable: false,
          noLoading: true,
        },
        component: FilterTypeEnum.multipleSelectorDropdown,
      },
    ]; 
  }


  return <BaseFilter content={content} backAction={backAction} />;
}
const mapPropsToState = (state) => ({
  campaignList: state.campaignList.allList,
})
export default connect(mapPropsToState)(Filter);

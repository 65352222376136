import { useRef, useEffect } from 'react';
import { ImageDomain, LanguageConfig } from 'config/CustomEnums';
import { IMAGE_TYPES } from 'models/UploadFilesModel';
import i18n from 'I18n';

export const deleteMessage = (items, related) =>
  `${
    items.length > 1
      ? 'These ' +
        items.slice(0, items.length - 1).join(', ') +
        ' and ' +
        items.slice(items.length - 1) +
        ' are'
      : 'This ' + items.join(', ') + ' is'
  } associated with some ${
    related.length > 1
      ? related.slice(0, related.length - 1).join(', ') +
        ' and ' +
        related.slice(related.length - 1)
      : related.join(', ')
  }, once deleted, it will affect other content. Are you sure to delete?`;

export const delay = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));
export const createNumberArray = (numberSize) =>
  Array.from(Array(numberSize).keys());

export const anchorElementWithId = (id) => {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  window.scrollTo(0, element.offsetTop - 124);
};

export const getFileNameFromUrl = (url) => {
  const mediaPath = 'media/';
  if (!url || url.lastIndexOf(mediaPath) === -1) {
    return url;
  }
  const start = url.lastIndexOf(mediaPath) + mediaPath.length;
  if (url.lastIndexOf('?') === -1) {
    return url.substring(start);
  }

  const end = url.indexOf('?');
  return url.substring(start, end);
};

export const detectIsSafari = () => {
  return window.navigator.userAgent?.indexOf('Safari') !== -1;
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const getImageUrl = (data) => {
  if (!data) {
    return '';
  }
  if (typeof data === 'string') {
    return data;
  }
  if (data.type !== IMAGE_TYPES.TYPE_URL) {
    return '';
  }
  return data.value;
};

export const getImageImage = (data) => {
  if (!data) {
    return null;
  }
  const url = getImageUrl(data);
  const imageName = getFileNameFromUrl(url);
  return imageName;
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};

export const addDomainToImage = (imageName) => {
  if (!imageName) {
    return imageName;
  }
  if (imageName.indexOf('http') > -1) {
    return imageName;
  }
  return `${ImageDomain}${imageName}`;
};

export const gotoOutWeb = (link, callback) => {
  if (typeof callback === 'function') {
    callback();
  }
  window.open(link, '_blank');
};

export const getHashKeyString = (hash) => {
  if (!hash) {
    return '';
  }
  const hashKey = hash.slice(1, hash.length);
  return decodeURI(hashKey);
};

export const convertNumberToCursor = (index) => {
  const numberString = `arrayconnection:${index}`;
  return btoa(numberString);
};
export const convertPKToId = (nodeName, PK) => {
  if (!PK) {
    return PK;
  }
  const idString = `${nodeName}:${PK}`;
  return btoa(idString);
};

export const convertCursorToNumber = (base64) => {
  const decodedString = atob(base64);
  const numberString = decodedString.substring(
    decodedString.lastIndexOf(':') + 1,
  );
  return parseInt(numberString);
};

export function saveToSessionStorage(key, object) {
  window.sessionStorage.setItem(key, JSON.stringify(object));
};

export const removeFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export function getObjectFromSessionStorage(key) {
  const allcookies = sessionStorage.getItem(key);
  if (allcookies) {
    return JSON.parse(allcookies);
  }
  return allcookies;
};

export const addToSessionStorage = (key, object) => {
  console.log('save cookies:', object);

  const originalCookie = getObjectFromSessionStorage(key);
  const newCookie = { ...originalCookie, ...object };
  window.sessionStorage.setItem(key, JSON.stringify(newCookie));
};

// const tokenRequiredAction = [
//   'brand/getBrand',
//   'brand/getCurrentPageBrands',
//   'brand/updateBrand',
//   'brand/delete',
//   'brand/duplicate',
//   'brand/createBrand',
//   'couponList/getCouponTemplateList',
//   'couponList/getCurrentPageTemplateList',
//   'singleCoupon/getCurrentPageSingleCouponList',
//   'singleCoupon/getAllSingleCoupons',
//   'singleCoupon/delete',
//   'singleCoupon/deactiveSingleCoupon',
//   'singleCoupon/activeSingleCoupon',
//   'singleCoupon/deactiveCoupons',
//   'singleCoupon/activeCoupons',
//   'singleCoupon/grantSingleCoupon',
//   'couponList/deleteCouponSet',
//   'couponList/delete',
//   'couponList/duplicate',
//   'couponList/updateCouponSetActiveStatus',
//   'couponList/getCouponSet',
//   'createCoupon/createCouponTemplate',
//   'createCoupon/createCouponTemplateTranslation',
//   'createCoupon/generateCoupon',
//   'campaignList/getCampaignList',
//   'campaignList/getCamapigncategories',
//   'customerList/getCustomerGroups',
//   'customerList/getAllCustomers',
//   'customerList/getPagedCustomers',
//   'customerList/getOneCustomer',
//   'customerList/updateCustomer',
//   'customerList/getCustomerActivityLog',
//   'earningRuleList/getEarningRuleListWithTypes',
//   'earningRuleList/delete',
//   'uploadFiles/uploadCroppedImage',
//   'uploadFiles/uploadFile',
//   'createCampaign/publishOrUnpublishCamapaign',
//   'createCampaign/publishCampaign',
//   'createCampaign/unPublishCampaign',
//   'createCampaign/createOrUpdateCampaign',
//   'createCampaign/createCamapigncategory',
//   'createCampaign/getOneCampaign',
//   'createCampaign/deleteCampaigns',
//   'createCampaign/duplicateCampaign',
//   'createCampaign/getAndLinkCouponDetail',
//   'createEarningRules/createOrUpdateEarningRule',
//   'createEarningRules/getOneEarningRule',
//   'storeModel/getAllStores',
//   'createCampaign/getAndLinkEarningRuleDetail',
//   'segments/deleteSegments',
//   'segments/getSegmentsList',
//   'levels/getLevelList',
//   'segments/createOrUpdateSegment',
//   'segments/getOneSegment',
//   'downloadAndImport/getSystemTasks',
//   'downloadAndImport/importFile',
//   'downloadAndImport/createDownloadTask',
//   'downloadAndImport/downloadFile',
//   'downloadAndImport/cancelTask',
//   'couponTransactions/getCurrentPageCouponTransactions',
//   'couponTransactions/getSingleCouponTransactionRecord',
//   'dashboard/getDashboardData',
//   'transactions/getCurrentPageTransactions',
//   'transactions/getTransaction',
//   'transactions/approveTransaction',
//   'transactions/rejectTransaction',
//   'transactions/recallTransaction',
//   'transactions/deleteTransaction',
//   'createTransaction/createTransaction',
//   'pointTransactionList/getCurrentPagePointRecords',
//   'pointTransactionList/getSinglePointRecord',
//   'admin/getPagedAdmins',
//   'admin/delete',
//   'admin/updateAdminStatus',
//   'admin/duplicate',
//   'admin/getOneAdmin',
//   'admin/updateOrCreate',
//   'adminGroup/getPagedAdminGroups',
//   'adminGroup/delete',
//   'adminGroup/duplicate',
//   'adminGroup/getOneAdminGroup',
//   'adminGroup/updateOrCreate',
//   'admin/getAllPermissions',
//   'admin/getAllAdmins',
//   'customerGroup/getGroupList',
//   'customerGroup/getCurrentPageList',
//   'customerGroup/getCustomerGroup',
//   'customerGroup/delete',
//   'customerGroup/duplicate',
//   'customerGroup/createCustomerGroup',
//   'customerGroup/updateCustomerGroup',
//   'overview/getCustomersData',
//   'overview/getCouponsData',
//   'overview/getCampaignsData',
//   'messageList/getCurrentPageMessages',
//   'messageList/getMessage',
//   'messageList/delete',
//   'messageList/duplicate',
//   'createMessage/createAndUpdateMessageTranslation',
//   'createMessage/testMessageSend',
//   'campaignCategoryList/getCampaignCategoryList',
//   'createCampaignCategory/getCategory',
//   'createCampaignCategory/createCampaignCategory',
//   'createCampaignCategory/updateCategoryActiveStatus',
//   'createCampaignCategory/updateCategoryOrder',
//   'createCampaignCategory/deleteCategories',
//   'createCampaignCategory/duplicateCategory',
//   'storeModel/getPagedStoreList',
//   'storeModel/getOneStore',
//   'storeModel/delete',
//   'storeModel/createOrUpdate',
//   'storeModel/getDistrictList',
//   'storeCategoryList/getStoreCategoryList',
//   'createStoreCategory/getCategory',
//   'storeCategoryList/getAllStoreSubcategoryList',
//   'bannerList/getPageBannerList',
//   'bannerList/duplicate',
//   'bannerList/createOrUpdateBanner',
//   'bannerList/delete',
//   'bannerList/getOneBanner',
//   'language/getList',
//   'language/createOrUpdate',
//   'language/delete',
// ];

export const createAction = (type) => (payload) => {
  // if (
  //   tokenRequiredAction.includes(type) &&
  //   !((payload || {}).fromRefresh || false)
  // ) {
  //   const newPayload = payload || {};
  //   newPayload.action = type;
  //   return { type: 'users/refreshAccessToken', payload: newPayload };
  // }
  return { type, payload: payload || {} };
};

export const removeElementFromArray = (array, value) => {
  return array.filter(function (ele) {
    return ele !== value;
  });
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useCompare = (val) => {
  const prevVal = usePrevious(val);
  // console.log('@@174: ', prevVal, val);
  return prevVal && JSON.stringify(prevVal) !== JSON.stringify(val);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const getError = (field, errorFields, errorHandle) => {
  return {
    id: field,
    error: errorFields?.fields?.includes(field),
    message: errorFields?.messages?.map((item) => {
      if (item.field === field) {
        if (item.errorType === 'required')
          return errorHandle[item.errorType][item.field];
        else return errorHandle['others'][item.field][item.errorType];
      }
      return undefined
    }),
  };
};

export const enLocaleNumberFormatter = (num) => {
  if (!num) {
    return 0;
  }
  const stringNumber = num.toString();
  const commaIndex = stringNumber.indexOf('.');
  if (commaIndex === -1) {
    return i18n.toNumber(stringNumber, { precision: 0 });
  }
  const stringNeedFormatting = stringNumber.substring(0, commaIndex);
  const stringEnd = stringNumber.substring(commaIndex);
  const stringFormated = i18n.toNumber(stringNeedFormatting, { precision: 0 });
  return `${stringFormated}${stringEnd}`;
};

export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeUndefinedFieldFromDict = (data) => {
  let validData = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null) {
        validData[key] = data[key];
      }
    });
  }

  return validData;
};

export const rewritePermission = (requires) => {
  return requires?.indexOf('delete') === 0 || requires?.indexOf('add') === 0
    ? `change_${requires.split('_')[1]}`
    : requires;
};

export const checkHasPermission = (user, requires) => {
  if (user?.isSuperuser) return true;

  const newRequires = rewritePermission(requires);
  if (user?.userPermissions.includes(newRequires) || !requires) return true;

  return false;
};

export const ArrayStringData = (toSplitIntData) => {
  return toSplitIntData
    .split(',')
    .filter(function (ele) {
      return ele !== '';
    })
    .map((el) => {
      let number = Number(el);
      return number === 0 ? number : number || el;
    });
};

export const insertKeyToTranslations = ({
  language,
  key,
  value,
  setValue,
  getValues,
}) => {
  const [translations] = getValues(['translations']) || {};
  const newTransaltion = {
    ...translations,
    [language]: {
      ...(translations?.[language] || {}),
      [key]: value,
    },
  };
  setValue('translations', newTransaltion, { shouldDirty: true });
};

export const insertKeyToAllLanguages = ({
  languages = [],
  key,
  value,
  setValue,
  getValues,
}) => {
  languages.forEach((item) => {
    insertKeyToTranslations({
      key,
      value,
      setValue,
      getValues,
      language: item.code || item,
    });
  });
};

export const checkUrlIsValid = (value) => {
  const validUrlReg =
    // eslint-disable-next-line no-useless-escape
    /\bhttps?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const result = validUrlReg.test(value);
  return result;
};

export const getListInFilterValue = (value) => {
  return value.toString().split(',');
};

export const getInFilterValue = (value) => {
  return JSON.stringify(value.toString().split(','));
}

export const isJsonFile = (value)=>{
  return value?.toLowerCase().endsWith('.json') || false
}

export function debounce(fn, delay = 500) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function isOpenInOtherSite() {
  return !(window.top === window.self)
}

export function getNumberWithOrdinal(num) {
  let j = num % 10,
    k = num % 100;
  if (j === 1 && k !== 11) {
    return num + "st";
  }
  if (j === 2 && k !== 12) {
    return num + "nd";
  }
  if (j === 3 && k !== 13) {
    return num + "rd";
  }
  return num + "th";
}

import { APIStatus, LanguageConfig, SESSION_KEYS} from '../config/CustomEnums';
import { createCampaignCategory } from '../services/CampaignAPIHelper';
import {
  updateCampaignCategory,
  deleteCampaignCategories,
  getCampaignCategoryList,
} from '../services/CampaignCategoryAPIHelper';
import {
  createAction,
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { getCampaignCategory } from '../services/CampaignCategoryAPIHelper';
import { apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  category: {
    name: null,
    order: null,
    isForcedInactive: false,
    translations: [],
  },
  errorFields: [],
  formChanged: false,
  saved: -1,
  createStatus: APIStatus.none,
  hasUpdatedDefaultValues: false,
});

export const CAMPAIGN_CATEGORY_SESSION_KEY = SESSION_KEYS.CAMPAIGN_CATEGORY_SESSION_KEY;

export const CREATE_CAMPAIGN_CATEGORY_SUCCESS = SESSION_KEYS.CREATE_CAMPAIGN_CATEGORY_SUCCESS;
export const CREATE_CAMPAIGN_CATEGORY_FAILD = SESSION_KEYS.CREATE_CAMPAIGN_CATEGORY_FAILD;

export const CAMPAIGN_CATEGORY_ORDER_LAST = SESSION_KEYS.CAMPAIGN_CATEGORY_ORDER_LAST;

const assembleCampaignCategory = (category) => {
  let translations = {};
  const apiTranslations = category.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    }
    if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    console.log("@68", item)
    translations = {
      ...translations,
      [language]: {
        name: item.node.name,
        id: item.node.pk,
        language,
      },
    };
  });
  console.log("@83", translations)
  return {
    categoryID: category.pk,
    name: category.name,
    order: category.displayPriority,
    isForcedInactive: category.isForcedInactive,
    translations,
  };
};

const cleanTranslations = (data, originalData) => {
  return Object.keys(data || {})?.map(language => {
    if (originalData?.[language]?.id) {
      return {
        language: language, 
        name: data?.[language]?.name,
        id: originalData?.[language]?.id
      }
    }
    return {
      language: language, 
      name: data?.[language]?.name,
    }
  })
}

export default {
  namespace: 'createCampaignCategory',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    reset(state, { payload }) {
      return getInitialState();
    },
    assembleCampaignCategory(state, { payload }) {
      const { category } = payload;
      console.log("@122", category)
      return {
        ...state,
        category: assembleCampaignCategory(category),
      };
    },
    loadCampaignCategoryFromSessionStorage(state, { payload }) {
      try {
        const category = getObjectFromSessionStorage(
          CAMPAIGN_CATEGORY_SESSION_KEY,
        );
        if (category) {
          return {
            ...state,
            category: {
              ...category,
            },
            hasUpdatedDefaultValues: true,
          };
        }
      } catch (error) {
        console.log(error);
      }
      return {
        ...state,
      };
    },
    formHasChanged(state, { payload }) {
      return {
        ...state,
        formChanged: true,
      };
    },
  },
  effects: {
    getCategory: [
      function* ({ payload }, { call, select, put, all }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('CampaignCategoryNode', payload.id);
        const serviceArgs = [getCampaignCategory, id];
        function* onSuccess(data) {
          console.log('getOneCampaign onSuccess :', data);
          yield all([
            put({
              type: 'assembleCampaignCategory',
              payload: { category: data.campaignCategory },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.none, hasUpdatedDefaultValues: true, },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneCampaignCategory onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneCampaignCategory arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    checkAndSave: [
      function* ({ payload }, { call, select, put, take, race }) {
        const { name, translations, order, isForcedInactive } = payload;
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        yield put(
          createAction('createCampaignCategory')({
            enCategory: name,
            translations,
            priority: order,
            isForcedInactive,
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put({
            type: 'updateState',
            payload: { formChanged: false, createStatus: APIStatus.success },
          });
          removeFromSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY);
          console.log(success);
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          console.log(failed);
        }
      },
      { type: 'takeLatest' },
    ],
    checkAndUpdate: [
      function* ({ payload }, { call, select, put, all }) {
        const {
          name,
          translations,
          order,
          isForcedInactive,
          categoryID,
        } = payload;
        if (categoryID === null || categoryID === undefined) {
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });

        const category = yield select(
          (state) => state.createCampaignCategory.category,
        );
        console.log('@@250: ', category);

        const input = {
          name,
          displayPriority: parseInt(order),
          isForcedInactive,
          id: categoryID,
          translations: cleanTranslations(translations, category?.translations)
        };
        console.log('@@274: ', input);
        const response = yield call(updateCampaignCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateCampaignCategory.errors
        ) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
          return;
        }
        yield put({
          type: 'updateState',
          payload: { formChanged: false, createStatus: APIStatus.success },
        });
        removeFromSessionStorage(CAMPAIGN_CATEGORY_SESSION_KEY);
      },
      { type: 'takeLatest' },
    ],
    createCampaignCategory: [
      function* ({ payload }, { call, put, all }) {
        const {
          enCategory,
          translations,
          priority,
          isForcedInactive,
          successAction,
        } = payload;
        const input = {
          name: enCategory,
          displayPriority: parseInt(priority),
          isForcedInactive,
          translations: cleanTranslations(translations),
        };
        const response = yield call(createCampaignCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.createCampaignCategory.errors
        ) {
          yield put({ type: CREATE_CAMPAIGN_CATEGORY_FAILD });
          return;
        }
        yield put({ type: CREATE_CAMPAIGN_CATEGORY_SUCCESS });
        const categoryId = responseData.data.createCampaignCategory.node?.pk;
        const categoryNode =
          responseData.data.createCampaignCategory.node || {};
        if (!categoryId) {
          return;
        }
        successAction &&
          successAction({
            name: categoryNode.name,
            id: categoryNode.id,
            pk: categoryNode.pk,
          });
      },
      { type: 'takeLatest' },
    ],
    updateCategoryActiveStatus: [
      function* ({ payload }, { call, put, all }) {
        const { id, isForcedInactive, afterAction } = payload;
        const input = {
          id,
          isForcedInactive,
        };
        const response = yield call(updateCampaignCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateCampaignCategory.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    updateCategoryOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        if (order === CAMPAIGN_CATEGORY_ORDER_LAST) {
          const countResponse = yield call(getCampaignCategoryList, '', {});
          const countResponseData = countResponse.data;
          if (countResponseData?.data?.error) {
            return;
          }
          input.displayPriority =
            countResponseData.data.campaignCategories.totalCount;
        } else {
          input.displayPriority = order;
        }
        const response = yield call(updateCampaignCategory, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateCampaignCategory.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    deleteCategories: [
      function* ({ payload }, { call, put, select }) {
        const { ids, afterAction } = payload;
        const input = {
          ids,
        };
        yield call(deleteCampaignCategories, input);
        if (afterAction) {
          afterAction();
        } else {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateCategory: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('CampaignCategoryNode', pk);
        const response = yield call(getCampaignCategory, id);
        const responseData = response.data;
        if (
          responseData === undefined ||
          responseData.data?.campaignCategory.pk === null
        ) {
          return;
        }
        const category = assembleCampaignCategory(
          responseData.data.campaignCategory,
        );

        yield put(
          createAction('createCampaignCategory')({
            enCategory: `copy of ${category.name}`,
            translations: category.translations,
            priority: category.order + 1,
            isForcedInactive: category.isForcedInactive,
          }),
        );
        // eslint-disable-next-line no-unused-vars
        const [success, failed] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success && afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
  },
};

import React, { useEffect, useRef } from "react";

import { CustomTitleLabel } from "components/earning/CustomBaseComponments";
import CustomRadios from "components/base/CustomRadios";
import { useFormContext } from "react-hook-form";
import { REFUND_TYPE } from "config/CustomEnums";
import { TRANSACTION_EVENT_TYPE } from "containers/record/records/TransactionList";
import { ReactHookFormErrorMessage } from "components/base/ErrorFieldMessage";

function TransactionRefundSection() {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;
  const refundTypeField = "refundType";
  const refundType = watch(refundTypeField);

  const refundAmountField = "refundAmount";
  const refundAmount = watch(refundAmountField);

  // const offlineEventTypeRef = useRef();

  const displayOfflineEventType = watch('displayOfflineEventType');

  const showRefundAmountSection = displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED
    || (displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_REFUNDED && refundType === REFUND_TYPE.CUSTOM_QUANTITY)


  // useEffect(() => {
  //   if (!offlineEventTypeRef.current) {
  //     offlineEventTypeRef.current = displayOfflineEventType
  //     // setValue(refundTypeField, REFUND_TYPE.TOTAL_AMOUNT_OF_ORDER, { shouldDirty: true });
  //     // setValue(refundAmountField, null, { shouldDirty: true });
  //     return ;
  //   }
  //   if (offlineEventTypeRef.current !== TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED
  //     && displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED) {
  //     setValue(refundTypeField, null, { shouldDirty: true });
  //     setValue(refundAmountField, null, { shouldDirty: true });
  //   }

  //   if (offlineEventTypeRef.current !== TRANSACTION_EVENT_TYPE.TYPE_REFUNDED
  //     && displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_REFUNDED) {
  //     setValue(refundTypeField, REFUND_TYPE.TOTAL_AMOUNT_OF_ORDER, { shouldDirty: true });
  //     setValue(refundAmountField, null, { shouldDirty: true });
  //   }

  //   offlineEventTypeRef.current = displayOfflineEventType;

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [displayOfflineEventType])

  return (
    <>
      <div className="first-section-title">REFUND</div>
      <CustomTitleLabel title="Refund amount" />
      {displayOfflineEventType === TRANSACTION_EVENT_TYPE.TYPE_REFUNDED &&
        <div className="d-flex">
          <CustomRadios
            onChange={(value) => {
              setValue(refundTypeField, value, { shouldDirty: true });
              // setValue(refundAmountField, null, { shouldDirty: true });
            }}
            default={refundType}
            options={[
              {
                label: 'The total amount of the order',
                value: REFUND_TYPE.TOTAL_AMOUNT_OF_ORDER
              },
              {
                label: 'Custom quantity',
                value: REFUND_TYPE.CUSTOM_QUANTITY
              },
            ]}
          />
        </div>
      }

      {showRefundAmountSection &&
        <>
          <div className="d-flex">
            <label className="transaction-amount-total-value-label">HK$</label>
            <input
              type="text"
              className={`day-input-fields`}
              value={refundAmount || ''}
              onInput={(e) => {
                const value = e.target.value;
                const data = value.replace(/[^0-9.]/g, '');
                setValue(refundAmountField, data, { shouldDirty: true });
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id={refundAmountField} />
        </>
      }
    </>
  )
}

export default TransactionRefundSection;
import React from 'react';
import { useSelector } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';

import NewCustomersChart from 'components/dashboard/NewCustomersChart';

import './NewCustomerCard.scss';

const NewCustomerCard = ({ width }) => {
  const customerOverviewData = useSelector((state) => state.overview.customers);
  const newCustomersGrow =
    (customerOverviewData?.newCustomers?.change || 0) >= 0;
  return (
    <div className="overview-customer-card">
      <div className="overview-customer-card-title">New Customers</div>
      <NewCustomersChart
        data={customerOverviewData?.chartData}
        width={width ? width - 75 : null}
        height={250}
        dataGrow={newCustomersGrow}
      />
    </div>
  );
};

export default withResizeDetector(NewCustomerCard);

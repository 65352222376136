import { useState, useEffect } from 'react';

export const MENU_BAR_WIDTH = 240;

const getMainContentWidth = () => {
  const { innerWidth: width } = window;
  if (width <= 750) {
    return width;
  }
  return width - MENU_BAR_WIDTH;
};
export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
export const useMainContentWidth = () => {
  const [mainContentWidth, setMainContentWidth] = useState(
    getMainContentWidth(),
  );

  useEffect(() => {
    function handleResize() {
      setMainContentWidth(getMainContentWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return mainContentWidth;
};

const windowWidth = window.screen.width;

export const isLongScreen = windowWidth > 1280;

import { StepStatus } from '../config/CustomEnums';
export const defaultStep = (nameList) =>
  nameList.map((name, index) => {
    return {
      name: name,
      status: index === 0 ? StepStatus.processing : StepStatus.waiting,
      active: index === 0,
      finished: false,
    };
  });

export function getNewStepConfig(
  currentStep,
  oldConfig,
  error,
  isBack = false,
) {
  if (error) {
    oldConfig[currentStep].status = StepStatus.error;
    return oldConfig;
  }
  oldConfig[currentStep].status = StepStatus.finished;
  oldConfig[currentStep].active = true;
  if (isBack) {
    oldConfig[currentStep - 1].status = StepStatus.processing;
    if (!oldConfig[currentStep].finished) {
      oldConfig[currentStep].status = StepStatus.waiting;
      oldConfig[currentStep].active = false;
    }
  } else if (currentStep === oldConfig.length - 1) {
    oldConfig[currentStep].finished = true;
    oldConfig[currentStep].active = true;
  } else {
    oldConfig[currentStep].finished = true;
    oldConfig[currentStep + 1].status = StepStatus.processing;
    oldConfig[currentStep + 1].active = true;
  }
  return oldConfig;
}

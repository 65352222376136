import React, { useEffect } from 'react';

import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import ErrorFieldMessage from 'components/base/ErrorFieldMessage';

function CustomTitleWithInput({
  title,
  defaultValue,
  setValue,
  paramText,
  onParamSelected,
  onCampaignSelected,
  buttonNewLine,
  error = {},
  type = 'text',
  tips,
  focusChange = () => {},
  extra,
  customTitleClass,
  customClass,
  disabled,
  blurAction = (target) => {},
  useDefaultValue = true,
  unit,
  per,
  shortDescription,
}) {
  useEffect(() => {
    console.log('@@15: ', defaultValue);
  }, [defaultValue]);

  // console.log('@@15: ', error);

  return (
    <div className="d-flex flex-column">
      <CustomTitleLabelWithEditorBar
        className={customTitleClass}
        title={title}
        suggest={tips}
        buttonNewLine={buttonNewLine}
        paramText={paramText}
        onParamSelected={onParamSelected}
        onCampaignSelected={onCampaignSelected}
      />
      <div
        style={
          unit ? null : per ? { display: 'flex' } : { display: 'contents' }
        }
      >
        {per ? (
          <label className="help-message margin-right-10-percent">{per}</label>
        ) : null}
        <input
          type={type}
          onChange={({ target }) => setValue(target.value)}
          {...(useDefaultValue
            ? { defaultValue: defaultValue || '' }
            : { value: defaultValue || '' })}
          className={`custom-markdown-area-title ${
            error.error ? 'error-field' : ''
          } custom-markdown-area-title-short ${
            type === 'number' ? 'custom-number-input-short' : ''
          } ${customClass || ''}`}
          onFocus={focusChange}
          {...extra}
          disabled={disabled}
          onBlur={({ target }) => blurAction(target)}
        />
        {unit ? (
          <label className="section-short-description">{unit}</label>
        ) : null}
      </div>
      {shortDescription ? (
        <label className="section-caution-description">
          {shortDescription}
        </label>
      ) : null}

      {error.error ? (
        <ErrorFieldMessage
          id={error.id}
          error={error}
          message={error.message}
        />
      ) : null}
    </div>
  );
}

export default CustomTitleWithInput;

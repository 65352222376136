import React, { Component } from 'react';
import { connect } from 'react-redux';
import './DeactiveSingleCouponView.scss';
import BaseActionModal from './BaseActionModalView';

@connect()
class DeactiveSingleCoupon extends Component {
  getSingleCouponDisplayContent = () => {
    return (
      <div>
        <div className="deactive-content-row">
          <label className="deactive-itme-name">Coupon Set</label>
          <label className="deactive-item-value deactive-single-coupon-set-name">
            {this.props.couponSet?.name}
          </label>
        </div>
        <div className="deactive-content-row">
          <label className="deactive-itme-name">Single Coupon</label>
          <label className="deactive-item-value">
            {this.props.singleCoupon?.serialNumber}
          </label>
        </div>
      </div>
    );
  };

  render() {
    return (
      <BaseActionModal
        customClass={'deactive-single-coupon-modal'}
        showModal={this.props.showDeactiveView}
        onHide={this.props.onHide}
        modalTitle={`Deactive a Single Coupon`}
        modalGeneralContent={`Deactive the single coupon below?`}
        modalDetailContent={this.getSingleCouponDisplayContent()}
        action={() => {
          this.props.activeBtnClicked();
        }}
        actionName={`Deactive`}
      />
    );
  }
}

export default DeactiveSingleCoupon;

import React, { useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import config from 'config';

import { getSuperSetToken } from '../../services/AnalyticsApiHelper';

import './SuperSetDashboard.scss';

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

const SuperSetDashboard = ({
  languages,
  embedID,
}) => {
  const supersetContainer = createRef();

  const fetchGuestTokenFromBackend = async () => {
    try {
      const response = await getSuperSetToken(embedID);
      if (response.status === 200) {
        const { success, token, errorMessage } = response?.data?.data?.superSetToken;
        console.log('kevin@3', success, token, errorMessage);
        console.log('languages', languages);
        return token;
      }
    } catch (err) {
      console.error(err)
    }
    return '';
  };

  useEffect(() => {
    if (!supersetContainer.current) {
      console.log('kevin@1');
      return;
    }
    embedDashboard({
      id: embedID,
      supersetDomain: config.dashboardSupersetDomain,
      mountPoint: supersetContainer.current,
      fetchGuestToken: fetchGuestTokenFromBackend,
      dashboardUiConfig: {
        hideTitle: true,
        filters: {
          expanded: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supersetContainer]);

  return (
    <>
      <div ref={supersetContainer} id="my-superset-container"></div>
    </>
  );
};

export default connect(mapPropsToState)(SuperSetDashboard);

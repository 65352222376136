import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import ContentSections from 'components/base/ContentSections';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import AuthButton from 'components/base/AuthButton';
import { PermissionCodes } from 'config/PermissionCodes';
import { createAction } from 'utils';
import { ImportResource } from 'models/DownloadImportModel';

const mapPropsToState = (state) => ({
  segment: state.segments.segment,
});

function SegmentDetail({
  segment,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={segment}
                fields={[
                  [{ title: 'Name', field: 'name' }],
                  [{ title: 'Description', field: 'description' }],
                  [
                    {
                      title: 'Number of customers',
                      field: 'numberOfCustomers',
                    },
                  ],
                  [
                    {
                      title: 'Last update time:',
                      field: 'displayLastUpdateCustomersDate',
                    }
                  ]
                ]}
              />,
            ]}
            hidePreview={true}
          // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title={'Export customer'}
      className="btn-back-button-common btn-download"
      action={() => {
        dispatch({
          type: 'downloadAndImport/createDownloadTask',
          payload: {
            from: ImportResource.customer,
            related: {
              singleSegmentIn: [id],
            }
          }
        });
      }}
    />,
    segment?.defaultSegment
      ? <></>
      : <AuthButton
        className="btn-further"
        onClick={() => {
          // dispatch({ type: 'customerList/clearData' });
          history.push({
            pathname: 'edit/',
          });
        }}
        requires={PermissionCodes.changeSegment}
      >
        Edit
      </AuthButton>,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'segments/clearData' });

    dispatch(createAction('segments/getOneSegment')({ id, view: true }));
  }, [dispatch, id]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: segment.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={segment.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

export default connect(mapPropsToState)(SegmentDetail);

import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './DateRangeCompare.scss';
import { getStartEndDate } from './DateRangeSelection';

const getDateRangeString = (startDate, endDate) => {
  if (endDate - startDate < 3600 * 24 * 1000) {
    return endDate.format('D MMM, YYYY');
  }
  let startDateString = startDate.format('D MMM, YYYY');
  if (startDate.year() === endDate.year()) {
    startDateString = startDate.format('D MMM');
  }
  return `${startDateString} - ${endDate.format('D MMM, YYYY')}`;
};

const getCompareDateString = (startDate, endDate) => {
  const duration = endDate - startDate;
  const compareStartDate = startDate.clone();
  compareStartDate.add(-duration).add(-1, 'days');
  const compareEndDate = endDate.clone();
  compareEndDate.add(-duration).add(-1, 'days');
  return getDateRangeString(compareStartDate, compareEndDate);
};

const DateRangeCompare = () => {
  const location = useLocation();
  const { range, start, end } = queryString.parse(location.search);
  const { startDate, endDate } = getStartEndDate(range, start, end);
  return (
    <label className="dashboard-compare-date">
      {`Compare with ${getCompareDateString(startDate, endDate)}`}
    </label>
  );
};
export default DateRangeCompare;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SaveAndBackWithOutTempButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import { validate } from 'containers/record/records/CreateTransactionValidate';
import { TRANSACTION_RECORD_TYPE } from 'containers/record/records/TransactionList';

import PurchaseItemSection from './PurchaseItemSection';
import PurchaseReceiptSection from './PurchaseReceiptSection';

function CreateTransactionStepThree() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { watch, getValues, clearErrors, setError } = useFormContext();

  const purchasedItemsField = 'purchasedItems';
  const transactionType = watch('transactionDisplayType');
  const purchasedItems = watch(purchasedItemsField);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack: isBack,
    });
    // dispatch({
    //   type: 'createTransaction/stepChange',
    //   payload: {
    //     isBack: isBack,
    //     step: 2,
    //     isValid
    //   },
    // });
    if (!isBack && isValid) {
      if (location.pathname.includes('edit')) {
        dispatch({
          type: 'createTransaction/updateTransaction',
          payload: {
            values: getValues(),
          },
        });
      } else {
        dispatch({
          type: 'createTransaction/createTransaction',
          payload: {
            values: getValues(),
          },
        });
      }
    }
  };

  const offlineRewardSection = [<PurchaseReceiptSection />];

  const offlinePOSsections = purchasedItems.map((item, index) => {
    return <PurchaseItemSection index={index} />;
  });

  const getSections = () => {
    if (transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
      return offlinePOSsections;
    }
    return offlineRewardSection;
  };

  return (
    <>
      <ContentSections sections={getSections()} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          dispatch({
            type: 'createTransaction/stepChange',
            payload: {
              isBack: true,
              step: 2,
              isValid: true,
            },
          });
        }}
        saveText={'Save'}
        saveAction={() => {
          stepChangeAction(false);
          // dispatch(
          //   createAction('createTransaction/checkStepThree')({
          //     isBack: false,
          //     afterActions: () => {
          //     },
          //   }),
          // );
        }}
      />
    </>
  );
}

export default CreateTransactionStepThree;

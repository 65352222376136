import PreviewStore from '../../assets/images/store/storePreviewDefault.svg';
import PreviewStoreOne from '../../assets/images/store/storepreviewStoreOne.svg';
import PreviewStoreTwo from '../../assets/images/store/storepreviewStoreTwo.svg';
import PreviewStoreThree from '../../assets/images/store/storepreviewStoreThree.svg';
import PreviewStoreFour from '../../assets/images/store/storepreviewStoreFour.svg';
import PreviewStoreFive from '../../assets/images/store/storepreviewStoreFive.svg';
import PreviewStoreSix from '../../assets/images/store/storepreviewStoreSix.svg';
import PreviewStoreSeven from '../../assets/images/store/storepreviewStoreSeven.svg';

const storePreview = {
  coverPhoto: PreviewStoreOne,
  name: PreviewStoreTwo,
  brand: PreviewStoreThree,
  subCategory: PreviewStoreFour,
  district: PreviewStoreFive,
  address: PreviewStoreFive,
  mobileNumber: PreviewStoreSix,
  email: PreviewStoreSix,
  description: PreviewStoreSeven,
};

export const getPreviewLayoutImages = () => {
  return storePreview;
};

export const defaultPreviewImage = () => {
  return PreviewStore;
};

import React from 'react';
import './CustomSteps.scss';
import { Image } from 'react-bootstrap';
import { withResizeDetector } from 'react-resize-detector';
import Error from '../../assets/images/step_error_icon.svg';
import Right from '../../assets/images/step_right_icon.svg';
import { StepStatus } from '../../config/CustomEnums';

function CustomSteps(props) {
  const steps = props.stepsConfig || [];
  const stepLength = steps.length;
  const { width } = props;
  const maxWidth = 40 * stepLength + (stepLength - 1) * 236;
  const reachMaxWidth = width >= maxWidth;
  const errorIcon = () => (
    <Image src={Error} className="finished-and-error-step-icon" />
  );

  const finishIcon = () => (
    <Image src={Right} className="finished-and-error-step-icon" />
  );

  const currentStepIcon = (index) => (
    <label className="starting-step-icon">{index + 1}</label>
  );

  const waitingStepIcon = (index) => (
    <label className="waiting-step-icon">{index + 1}</label>
  );

  const Icon = ({ item, index }) => {
    let iconStatusStyle = 'edit-step-background';
    let icon = errorIcon();
    if (item.status === StepStatus.finished) {
      iconStatusStyle = 'current-and-finished-background';
      icon = finishIcon();
    }
    if (item.status === StepStatus.processing) {
      iconStatusStyle = 'current-and-finished-background';
      icon = currentStepIcon(index);
    }
    if (item.status === StepStatus.error) {
      iconStatusStyle = 'error-background';
    }
    if (item.status === StepStatus.waiting) {
      iconStatusStyle = 'un-edited-background';
      icon = waitingStepIcon(index);
    }
    return (
      <>
        <div className={`edit-step-background ${iconStatusStyle}`}>{icon}</div>
      </>
    );
  };

  return (
    <div
      className={`custom-step-content ${
        stepLength === 0 ? 'no-step-content' : ''
      }`}
    >
      {steps.map((item, index) => {
        const isLastStep = index === steps.length - 1;
        return (
          <div
            key={`${index}-${item.name}`}
            className={`custom-one-step-content ${
              isLastStep ? 'custom-last-step-content' : ''
            }`}
            style={
              reachMaxWidth ? { maxWidth: 276 } : { flex: isLastStep ? 0 : 1 }
            }
          >
            <div
              className={`step-icon-container 
              ${isLastStep ? 'step-icon-container-last' : ''}`}
            >
              <Icon item={item} index={index} />
              <label
                className={`step-description-custom ${
                  item.active
                    ? 'step-description-custom-actived'
                    : 'step-description-custom-inactive'
                }`}
              >
                {item.name}
              </label>
            </div>
            {isLastStep ? null : <div className="step-line" />}
          </div>
        );
      })}
    </div>
  );
}

export default withResizeDetector(CustomSteps);

import React from 'react';

import CustomRadios from 'components/base/CustomRadios';
import ErrorFieldMessage from 'components/base/ErrorFieldMessage';
import 'components/campaign/campaignCreation/PropertiesGeneralSection.scss';

import SearchIcon from 'assets/images/drop_down_filter.svg';

import './RewardSection.scss';

export function CustomTitleLabel(props) {
  return (
    <label
      className={`create-section-label create-section-label-bottom-space ${
        props.className || ''
      }`}
    >
      {props.title}
    </label>
  );
}

export function CustomTitleWithRadio({
  title,
  customTitleClass,
  options,
  tips,
  defaultValue,
  setValue,
  onFocus = () => {},
}) {
  return (
    <div className="d-flex flex-column">
      {title ? (
        <CustomTitleLabel title={title} className={customTitleClass} />
      ) : null}
      {tips ? <label className="tips-message">{tips}</label> : null}
      <div
        style={{
          display: 'flex',
        }}
      >
        <CustomRadios
          onChange={setValue}
          default={defaultValue}
          options={options}
          onFocus={onFocus}
        />
      </div>
    </div>
  );
}

export function CustomStringInput(props) {
  const {
    title,
    placeholder,
    setValue,
    defaultValue,
    errorId,
    error,
    errorMessage,
  } = props;
  return (
    <div className="d-flex flex-column">
      {title ? <CustomTitleLabel title={title} /> : null}
      <div className="filterable-toogle-area custom-input-long">
        <img className="filterable-search-bar-icon" src={SearchIcon} />
        <input
          onChange={({ target }) => setValue(target.value)}
          className="filterable-search-bar-input"
          value={defaultValue}
          placeholder={placeholder}
          // defaultValue={defaultValue}
          onFocus={() => {}}
        />
      </div>
      <ErrorFieldMessage id={errorId} error={error} message={errorMessage} />
    </div>
  );
}

export function CustomNumberInput(props) {
  const { title, setValue, defaultValue, errorId, error, errorMessage } = props;

  // useEffect(() => {
  //   console.log('@@20: ', defaultValue);
  //   setValue(defaultValue);
  // }, [defaultValue]);
  return (
    <div className="d-flex flex-column">
      {title ? <CustomTitleLabel title={title} /> : null}
      <input
        type="number"
        min="0"
        onChange={({ target }) => setValue(target.value)}
        className="custom-number-input-long"
        value={defaultValue}
        // defaultValue={defaultValue}
        onFocus={() => {}}
      />
      <ErrorFieldMessage id={errorId} error={error} message={errorMessage} />
    </div>
  );
}

export function CustomNumberInputWithUnit(props) {
  const {
    title,
    setValue,
    onChangeFunc = () => {},
    defaultValue,
    unit,
    errorId,
    error,
    errorMessage,
    numberTitle,
    disabled,
    others,
  } = props;
  return (
    <div className="d-flex flex-column">
      {title ? <CustomTitleLabel title={title} /> : null}
      <div style={{ display: 'flex' }}>
        {numberTitle ? (
          <label className="help-message margin-right-10-percent">
            {numberTitle}
          </label>
        ) : null}
        <input
          type="number"
          min={'0'}
          disabled={disabled}
          onKeyDown={(event) => {
            console.log('@@91: ', event.keyCode);
            if (event.keyCode === 189) {
              event.preventDefault();
              return false;
            }
          }}
          onChange={({ target }) => {
            setValue(target.value);
            onChangeFunc(target.value);
          }}
          className={`custom-number-input-short ${error ? 'error-field' : ''}`}
          defaultValue={defaultValue}
          onFocus={() => {}}
          {...others}
        />
        <label className="help-message margin-left-10-percent margin-top-8-percent">
          {unit}
        </label>
      </div>
      <ErrorFieldMessage id={errorId} error={error} message={errorMessage} />
    </div>
  );
}

export function CustomNumberMutipleInputWithUnit(props) {
  const {
    setFirstValue,
    setSecondValue,
    setFirstFocus,
    setSecondFocus,
    firstDefaultValue,
    secondDefaultValue,
    numberTitle,
  } = props;
  return (
    <div style={{ display: 'flex' }}>
      {numberTitle ? (
        <label className="help-message margin-right-10-percent">
          {numberTitle}
        </label>
      ) : null}
      <input
        type="number"
        min="0"
        onChange={({ target }) => {
          if (target.value === '') {
            setFirstValue(null);
          } else {
            setFirstValue(target.value);
          }
        }}
        className="custom-number-input-short"
        defaultValue={firstDefaultValue}
        onFocus={setFirstFocus}
      />
      <label className="help-message margin-left-10-percent margin-right-10-percent">
        {props.per}
      </label>
      <input
        type="number"
        min="0"
        onChange={({ target }) => {
          if (target.value === '') {
            setSecondValue(null);
          } else {
            setSecondValue(target.value);
          }
        }}
        className="custom-number-input-short"
        defaultValue={secondDefaultValue}
        onFocus={setSecondFocus}
      />
      <label className="help-message margin-left-10-percent">
        {props.unit}
      </label>
    </div>
  );
}

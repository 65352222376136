import React from 'react';
import { Button } from 'react-bootstrap';

import { OnlyDropdown } from 'components/base/ActionsDropdown';
import CampaignDeeplinkDropdown from 'components/message/CampaignDeeplinkDropdown';
import { InsertParameterOptions } from 'config/MessageEmail';

import { isLongScreen } from 'utils/ScreenUtil';

import './CustomTitleLabelWithEditorBar.scss';

function CustomTitleLabelWithEditorBar({
  className = '',
  title,
  suggest,
  onCampaignSelected,
  paramText,
  onParamSelected,
  buttonNewLine = false,
}) {
  return (
    <div
      className={`create-section-with-editor-bar ${
        buttonNewLine ? 'multi-line' : ''
      }`}
    >
      <div className="title-suggest">
        {title && (
          <label
            className={`create-section-label create-section-label-bottom-space ${className}`}
          >
            {title}
          </label>
        )}
        {suggest && <label className="create-section-suggest">{suggest}</label>}
      </div>
      <div className="editor-bar">
        <div
          className={`customer-editor-bar-container ${
            isLongScreen ? '' : 'short-screen'
          }`}
        >
          {onCampaignSelected && (
            <CampaignDeeplinkDropdown
              customStyles={{ display: 'grid', width: 'auto' }}
              setValue={(value, forApp) => {
                console.log('CampaignDeeplinkDropdown', value);
                onCampaignSelected(value, forApp);
              }}
            />
          )}
          {paramText && (
            <OnlyDropdown
              toggleText={paramText}
              options={InsertParameterOptions}
              setValue={(value) => onParamSelected(value)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomTitleLabelWithEditorBar;

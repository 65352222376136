import React from 'react';
import i18n from '../../I18n';
import { LanguageConfig } from '../../config/CustomEnums';
import { useFormContext } from 'react-hook-form';
import { SectionTopTitle } from '../base/CommonComponent';
import InputFieldControl from '../base/InputFieldControl';

function OverviewSection({
  language,
  onFocus = (() => {}),
  areaTitle,
  tips,
  requireAllLanguage = false
}) {
  const { watch, setValue } = useFormContext();
  const watchName = requireAllLanguage
    ? watch(`translations.${language}.generalName`)
    : watch(`translations.${LanguageConfig.english}.generalName`);
  return (
    <>
      {areaTitle ? 
        <SectionTopTitle title={i18n.t(areaTitle, { locale: language })} />
       : null}
      {language === LanguageConfig.english || requireAllLanguage ? <InputFieldControl 
        name={'generalName'}
        title={i18n.t('name', { locale: language })}
        value={watchName}
        setValue={(value) => {
          setValue(`translations.${language}.generalName`, value, { shouldDirty: true })
        }}
        onFocus={() => {
          onFocus('name');
        }}
        tips={tips}
      /> : (
        <>
          <label
            className={
              areaTitle
                ? 'create-section-label create-section-label-bottom-space'
              : 'create-section-label'
          }
          >
            {i18n.t('name', { locale: language })}
          </label>
          <label className="custom-record-normal-font custom-timeline-time-color">
            {watchName}
          </label>
        </>
      )}
  </>)
}

export default OverviewSection;

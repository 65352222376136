import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import {
  CheckStatus,
  SavedStatus,
} from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import CustomerEditFormStepOne from './CustomerEditFormStepOne';


function CustomerEditForm({
  customer,
  checked,
  formHasSubmitted,
  hasUpdatedDefaultValues,
  saved
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;


  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch({
        type: 'customerList/updateCustomer',
        payload: {}
      });
    }
  }, [dispatch, checked]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithFail ||
      saved === SavedStatus.savedWithSuccess
    ) {
      history.push('/customers#List%20of%20Customers');
    }
  }, [saved, history]);

  useEffect(() => {
    dispatch({
      type: 'customerList/getOneCustomer',
      payload: { id }
    });
  }, [dispatch, id]);

  const stepSet = [
    <CustomerEditFormStepOne />
  ];

  return (
    <div className="customer">
      <BaseForm
        defaultValues={customer}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (!save) {
            // dispatch({
            //   type: 'createEarningRules/removeEarningRuleFromCookie',
            // });
          }else{
            dispatch({
              type: 'customerList/updateCustomer',
              payload: {
                data: getValues(),
                customer: customer,
              },
            });
          }
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        caution={{
          detail: '', //You can modify some customer information, also can delete this customer.
          title: customer.name,
          model: 'customerList',
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer || {},
  checked: state.customerList.checked,
  formHasSubmitted: state.customerList.formHasSubmitted,
  hasUpdatedDefaultValues: state.customerList.hasUpdatedDefaultValues,
  saved: state.customerList.saved,
});

export default connect(mapPropsToState)(CustomerEditForm);

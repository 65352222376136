import React from 'react';
import './Loading.scss';
import { Image } from 'react-bootstrap';
import NoDataIcon from '../../assets/images/nodata.svg';

function NoDataPage() {
  return (
    <div className="loading-area">
      <Image src={NoDataIcon} className=""></Image>
    </div>
  );
}

export default NoDataPage;

import { CreateTransactionError } from '../../../models/CreateTransactionModel';
import { TRANSACTION_EVENT_TYPE, TRANSACTION_RECORD_TYPE } from '../../../containers/record/records/TransactionList';
import { REFUND_TYPE } from 'config/CustomEnums';

function firstStepValidate({ getValues, setError }) {
  const transactionDisplayTypeField = 'transactionDisplayType';
  const [
    transactionDisplayType,
  ] =  getValues([
    transactionDisplayTypeField,
  ]);
  const errorList = [];
  if (!transactionDisplayType) {
    errorList.push({
      name: transactionDisplayTypeField,
      message: 'Please select a type',
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const customerField = 'customer';
  const transactionDateField = 'transactionDate';
  const storeField = 'store';
  const posInvoiceIdField = 'posInvoiceId';
  const invoiceIdField = 'invoiceId';
  const offlineEventTypeField = 'offlineEventType';
  const staffNameField = 'staffName';
  const paidAmountField = 'paidAmount';
  const totalValueField = 'totalValue';
  const refundTypeField = 'refundType';
  const refundAmountField = 'refundAmount';
  const additionalDataField = 'additionalData';
  const discountsField = 'discounts';

  const [
    displayOfflineEventType,
    transactionDisplayType,
    customer,
    transactionDate,
    store,
    posInvoiceId,
    invoiceId,
    offlineEventType,
    staffName,
    paidAmount,
    totalValue,
    refundType,
    refundAmount,
    additionalData,
    discounts,
  ] = getValues([
    'displayOfflineEventType',
    'transactionDisplayType',
    customerField,
    transactionDateField,
    storeField,
    posInvoiceIdField,
    invoiceIdField,
    offlineEventTypeField,
    staffNameField,
    paidAmountField,
    totalValueField,
    refundTypeField,
    refundAmountField,
    additionalDataField,
    discountsField,
  ]);
  const errorList = [];

  const validateAdditionData = ()=> {
    if (additionalData && additionalData?.length > 0) {
      additionalData.forEach((item, index) => {
        if (!item?.title && item?.value) {
          errorList.push({
            name: `${additionalDataField}.${index}.title`,
            message: CreateTransactionError.additionalDataTitle.message,
          });
        }
      })
    }
  }
  const validateDiscounts = () => {
    if (discounts && discounts?.length > 0) {
      discounts.forEach((item, index) => {
        if (!item?.value && item?.code) {
          errorList.push({
            name: `${discountsField}.${index}.value`,
            message: CreateTransactionError.discountValue.message,
          });
        }
        if (!item?.type) {
          errorList.push({
            name: `${discountsField}.${index}.type`,
            message: CreateTransactionError.discountType.message,
          });
        }
      })
    }
  }
  // if (!customer?.pk) {
  //   errorList.push({
  //     name: customerField,
  //     message: CreateTransactionError.customer.message,
  //   });
  // }
  if (!transactionDate) {
    errorList.push({
      name: transactionDateField,
      message: CreateTransactionError.transactionDate.message,
    });
  }
  if (!store?.storePK) {
    errorList.push({
      name: storeField,
      message: CreateTransactionError.store.message,
    });
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
    && !posInvoiceId
  ) {
    errorList.push({
      name: posInvoiceIdField,
      message: CreateTransactionError.posInvoiceId.message,
    });
  }

  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS ) {
    if (!offlineEventType) {
      errorList.push({
        name: offlineEventTypeField,
        message: CreateTransactionError.offlineEventType.message,
      });
    }

    switch (displayOfflineEventType) {
      case TRANSACTION_EVENT_TYPE.TYPE_REFUNDED:
        if (refundType === REFUND_TYPE.CUSTOM_QUANTITY && !refundAmount) {
          errorList.push({
            name: refundAmountField,
            message: CreateTransactionError.refundAmount.message,
          });
        }
        break;
      case TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED:
        if (!refundAmount) {
          errorList.push({
            name: refundAmountField,
            message: CreateTransactionError.refundAmount.message,
          });
        }
        break;
      default:
        break;
    }
    validateAdditionData()
    validateDiscounts()
  }
  if (
    transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
  ) {
    if (!invoiceId) {
      errorList.push({
        name: invoiceIdField,
        message: CreateTransactionError.invoiceId.message,
      });
    }
    validateAdditionData()
    validateDiscounts()
  }
  if (!staffName) {
    errorList.push({
      name: staffNameField,
      message: CreateTransactionError.staffName.message,
    });
  }
  if (!totalValue || totalValue === '') {
    errorList.push({
      name: totalValueField,
      message: CreateTransactionError.totalValue.message,
    });
  } else if (isNaN(parseFloat(totalValue))) {
    errorList.push({
      name: totalValueField,
      message: CreateTransactionError.totalValueFormat.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const [
    transactionDisplayType,
  ] = getValues([
    'transactionDisplayType',
  ]);
  const errorList = [];

  const  validatePurchasedItem = ({ item, index }) => {
    const currentPurchasedItemField = `purchasedItems.${index}`
      if (!item.productName) {
        errorList.push({
          name: `${currentPurchasedItemField}.productName`,
          message: CreateTransactionError.purchasedItemName.message,
        });
      }
      if (!item.quantity) {
        errorList.push({
          name: `${currentPurchasedItemField}.quantity`,
          message: CreateTransactionError.purchasedItemQuantity.message,
        });
      } else if (isNaN(parseInt(item.quantity))) {
        errorList.push({
          name: `${currentPurchasedItemField}.quantity`,
          message: CreateTransactionError.purchasedItemQuantityFormat.message,
        });
      }
      if (!item.value) {
        errorList.push({
          name: `${currentPurchasedItemField}.value`,
          message: CreateTransactionError.purchasedItemValue.message,
        });
      } else if (isNaN(parseFloat(item.value))) {
        errorList.push({
          name: `${currentPurchasedItemField}.value`,
          message: CreateTransactionError.purchasedItemValueFormat.message,
        });
      }
    if (item?.additionalData?.length > 0) {
      item.additionalData.forEach((data, index) => {
        if (!data?.title && data?.value) {
          errorList.push({
            name: `${currentPurchasedItemField}.additionalData.${index}.title`,
            message: CreateTransactionError.additionalDataTitle.message,
          });
        }
      })
    }
    if (item?.discounts?.length > 0) {
      item.discounts.forEach((data, index) => {
        if (!data?.value && data?.code) {
          errorList.push({
            name: `${currentPurchasedItemField}.discounts.${index}.value`,
            message: CreateTransactionError.discountValue.message,
          });
        }
      })
    }
  }

  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    const purchasedItemsField = 'purchasedItems';
    const [
      purchasedItems,
    ] = getValues([
      purchasedItemsField,
    ]);
    purchasedItems.forEach((item, index) => {
      validatePurchasedItem({ item, index })
    });
  } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
    const receiptImageField = 'receiptImage';
    const creditCardSlipImageField = 'creditCardSlipImage';
    const [
      receiptImage,
      creditCardSlipImage,
    ] = getValues([
      receiptImageField,
      creditCardSlipImageField,
    ]);
    if (!receiptImage) {
      errorList.push({
        name: receiptImageField,
        message: CreateTransactionError.receiptImage.message,
      });
    }
    if (!creditCardSlipImage) {
      errorList.push({
        name: creditCardSlipImageField,
        message: CreateTransactionError.creditCardSlipImage.message,
      });
    }
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}

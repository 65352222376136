import React from 'react';
import { useFormContext } from 'react-hook-form';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomRadioWithTitleAndRelated from './CustomRadioWithTitleAndRelated';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

function CustomTitleWithInput({ title, defaultValue, setValue, error = {} }) {
  return (
    <div style={{ display: 'grid' }}>
      <CustomTitleLabel title={title} />
      <input
        type="text"
        onChange={({ target }) => setValue(target.value)}
        value={defaultValue || ''}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short`}
        onFocus={() => {}}
      />
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

function PersonalInfoEditCard({ title }) {

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchNickName = watch('nickname');
  const watchGender = watch('gender');
  const watchIsAssignedAsTestingCustomer = watch('isAssignedAsTestingCustomer');

  return (
    <div>
      <label className="create-section-title">{ title }</label>

      <CustomTitleWithInput
        title={'First name'}
        defaultValue={watchFirstName}
        setValue={(value) => {
          setValue('firstName', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='firstName' />

      <CustomTitleWithInput
        title={'Last name'}
        defaultValue={watchLastName}
        setValue={(value) => {
          setValue('lastName', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='lastName' />

      {/* <CustomTitleWithInput
        title={'Chinese First name(Optional)'}
        defaultValue={''}
        setValue={setChineseFirstName}
        error={{}}
      />

      <CustomTitleWithInput
        title={'Chinese Last name(Optional)'}
        defaultValue={''}
        setValue={setChineseLastName}
        error={{}}
      /> */}

      <CustomTitleWithInput
        title={'Preferred name (optional)'}
        defaultValue={watchNickName}
        setValue={(value) => {
          setValue('nickname', value, { shouldDirty: true });
        }}
        error={{}}
      />
      <ReactHookFormErrorMessage errors={errors} id='nickname' />

      <CustomRadioWithTitleAndRelated
        title="Gender (optional)"
        options={[
          { label: 'Male', value: 'MALE' },
          { label: 'Female', value: 'FEMALE' },
          { label: 'Not disclosed', value: 'NOT_DISCLOSED' },
        ]}
        defaultValue={watchGender}
        setValue={(value) => {
          setValue('gender', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='gender' />

      {/* <CustomTitleWithDropDown
        title={'Preferred message language'}
        source={[
          { name: 'English', value: 'en' },
          { name: '简体', value: 'zh-Hant' },
          { name: '繁体', value: 'zh-Hans' },
        ]}
        defaultValue={preferredMessageLanguage}
        needFilter={false}
        setValue={setPreferredMessageLanguage}
        errors={errorFields?.fields}
      /> */}

      <CustomTitleWithSwitch
        title={'Assign as test customer'}
        defaultValue={watchIsAssignedAsTestingCustomer}
        setValue={(value) => {
          setValue('isAssignedAsTestingCustomer', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='isAssignedAsTestingCustomer' />
    </div>
  );
}

export default PersonalInfoEditCard;


import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import AuthButton from 'components/base/AuthButton';
import './SelectStoreOrUseCouponModal.scss';


const SelectStoreOrUseCouponModal = ({
  customerId,
  coupon,
  show,
  setShow,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const couldSelectStore = coupon?.couldSelectStore;

  const [selectStore, setSelectStore] = useState(null);
  const [confirm, setConfirm] = useState(false);

  const getTitle = () => {
    if (confirm) {
      return "Confirm to use coupon?";
    }
    return `Use coupon${couldSelectStore ? " (or select store only)" : ""}`;
  }

  const onClose = () => {
    if (confirm) {
      setConfirm(false);
      return;
    };
    setShow(false);
  };

  const buttonAction = (type) => {
    dispatch({
      type: `singleCoupon/${type}`,
      payload: {
        id: coupon?.pk,
        customerId: customerId,
        storeId: selectStore?.value?.pk,
        afterAction: () => {
          setConfirm(false);
          setShow(false);
          history.push(location);
        }
      }
    });
  };

  const primaryButtonAction = () => {
    if (confirm) {
      buttonAction("useCoupon");
    }
    setConfirm(true);
  };

  useEffect(() => {
    if (show) {
      setConfirm(false);
      setSelectStore(
        coupon?.store
          ? { label: coupon?.store?.name, value: coupon?.store }
          : null
      );
    };
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>
          {getTitle()}
        </label>

        {
          confirm
            ? null
            : <CustomTitleWithDropDown
              title="Use in store"
              defaultValue={selectStore}
              setValue={setSelectStore}
              source={coupon?.selectedStores || []}
              loadMoreAction=''
              needFilter={false}
              filterAction=''
            />
        }

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          {
            !confirm && couldSelectStore
              ? <AuthButton
                title='Select store only'
                customClass={'btn-back-button-common select-store-or-use-coupon-button'}
                action={() => buttonAction("selectStore")}
                requires={PermissionCodes.changeCoupon}
              />
              : null
          }
          <PromptButton
            title={confirm ? 'Confirm' : 'Use Coupon'}
            action={primaryButtonAction}
            requires={PermissionCodes.changeCoupon}
            type={BUTTON_TYPES.main}
            disabled={!selectStore?.value?.pk}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
});

export default connect(mapPropsToState)(SelectStoreOrUseCouponModal);

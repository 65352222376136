import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import DeletePrompt from 'components/base/DeletePrompt';
import { DELETE_RELATED_SECTIONS, EarningRuleType } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';

import { getHashKeyString } from 'utils';
import CustomerEarningRuleListFilter from './CustomerEarningRuleListFilter';

function CustomerEarningRuleList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = getHashKeyString(location.hash);

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    if (hash !== "Earning rules") {
      return;
    }
    dispatch({ type: 'customerGroup/getGroupList', payload: { isSimpleList: true }});
    dispatch({ type: 'segments/getSegmentsList', payload: {
        type: 'all',
        isSelectorLoad: true,
      }
    });
  }, [dispatch, hash])

  useEffect(() => {
    if (hash !== "Earning rules") {
      return;
    }
    const parsedSearch = queryString.parse(location.search);
    const type = parsedSearch['type'] || '';
    const searchKey = parsedSearch['search'] || '';
    const stringRank = parsedSearch['rank'] || 'true';
    const stringPage = parsedSearch['page'] || 1;
    const groupsIn = parsedSearch['customer_groups'] || '';
    const segmentsIn = parsedSearch['segments'] || '';
    const rewardType = parsedSearch['reward_type'] || '';
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch({
      type: 'earningRuleList/getEarningRuleListWithTypes',
      payload: {
        ...parsedSearch,
        page,
        reverse: rank,
        search: searchKey,
        otherSearch: {
          rewardType,
          groupsInWithSegmentsIn: `${groupsIn};${segmentsIn}`,
          customerDetailFilter: true,
        },
        type: type, //filters.levels.join(','),
      },
    });
  }, [dispatch, location]);

  return (
    <>
      <BaseTabListContainer
        hideTab={false}
        maxTabWidth={670}
        tabs={[
          { key: EarningRuleType.allTypes, name: 'All Type' },
          { key: EarningRuleType.qrCodeScanning, name: 'QR Code' },
          { key: EarningRuleType.gpsCheckIn, name: 'GPS' },
          { key: EarningRuleType.memberReferral, name: 'Referral' },
          { key: EarningRuleType.generalPurchase, name: 'Purchase' },
          { key: EarningRuleType.newMember, name: 'New Member' },
          { key: EarningRuleType.birthday, name: 'Birthday' },
          { key: EarningRuleType.fillingForm, name: 'Webhook' },
          { key: EarningRuleType.levelUpgrade, name: 'Level Upgrade' },
        ]}
        groupActions={[]}
        // defaultTabKey={levels}
        pageInfo={pageInfo}
        totalCount={totalCount}
        filter={{ hasFilter: true, component: CustomerEarningRuleListFilter }}
        listContent={
          <BaseListContainer
            fields={listFields}
            dataList={dataList}
            totalPage={totalPage}
            model={'earningRuleList'}
            permissionGroup={PermissionCodes.earningrule}
            deleteInfo={{
              data: [],
              title: 'earning rules',
              relatedName: 'campaigns',
              onComfirm: {},
              relatedSections: DELETE_RELATED_SECTIONS.EARNING_RULE,
            }}
            actions={['Edit', 'Detail', 'Delete']}
            customPathname="/earns"
          />
        }
      />
      <DeletePrompt
        data={checkedList}
        title={'earning rules'}
        relatedName={'campaigns'}
        relatedSections={DELETE_RELATED_SECTIONS.EARNING_RULE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'earningRuleList/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            },
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.earningRuleList.listDisplayFields,
  dataList: state.earningRuleList.earningRuleTypeList,
  pageInfo: state.earningRuleList.pageInfo,
  totalPage: state.earningRuleList.totalPage,
  totalCount: state.earningRuleList.totalCount,
  checkedList: state.earningRuleList.checkedList,
});

export default connect(mapPropsToState)(CustomerEarningRuleList);

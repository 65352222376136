import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';

  const [type, setType] = useState(searchType);

  const content = [
    {
      title: 'Type',
      data: [
        { pk: 'ADD_POINTS', name: 'Add Points' },
        { pk: 'REMOVE_POINTS', name: 'Remove Points' },
        { pk: 'ADD_TPES', name: 'Add Progress Points' },
        { pk: 'REMOVE_TPES', name: 'Remove Progress Points' },
        { pk: 'ADD_COUPONS', name: 'Add Coupons' },
        { pk: 'REMOVE_COUPONS', name: 'Remove Coupons' },
      ],
      value: type,
      setValue: setType,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
  ];
  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import AuthButton from 'components/base/AuthButton';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import Loading from 'components/base/Loading';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import { APIStatus } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  giftCardDetail: state.giftCard.detail,
  status: state.loading.status,
});

function GiftCardDetail({ giftCardDetail, status }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const fieldsA = [[{ title: 'Gift card code (String)', field: 'code' }]];
  if (giftCardDetail.type === 'PHYSICAL') {
    fieldsA.push([{ title: 'QR code', field: 'shortLink', isQRCode: true }]);
  }
  const fieldsB = [
    [{ title: 'Type', field: 'displayType' }],
    [{ title: 'Cash value', field: 'cashValue' }],
    [{ title: 'Points expiry date', field: 'expiryAt' }],
    [{ title: 'status', field: 'displayStatus' }],
    [{ title: 'Sender contact', field: 'senderContact' }],
    [{ title: 'Recipient contact', field: 'recipientContact' }],
    [{ title: "Recipient's Member ID", field: 'recipientMembershipId' }],
  ];
  const fields = fieldsA.concat(fieldsB);

  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={
            status === APIStatus.calling
              ? [<Loading />]
              : [<MembershipInfoCard data={giftCardDetail} fields={fields} />]
          }
          hidePreview
          // sectionStyle="detail-info-section-item"
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'giftCard/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeLanguage}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('giftCard/getOneDetail')({
          id: params.id,
          view: true,
        }),
      );
    }
    return () => {
      dispatch({ type: 'giftCard/clearData' });
    };
  }, [dispatch, params.id]);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: 'Gift card',
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={giftCardDetail.sourceName} />}
        buttons={giftCardDetail.canEdit ? buttons : []}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

export default connect(mapPropsToState)(GiftCardDetail);

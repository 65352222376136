import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import HtmlEditor from '../base/HtmlEditor';

export default function WebviewSection({ language }) {
  const isEnglish = language === LanguageConfig.english;
  const { watch, setValue, control, formState, getValues } = useFormContext();
  const { errors } = formState;
  const watchName = watch('name');
  const initHtmlContent = getValues(`translations.${language}.content`);
  return (
    <>
      <div className="create-section-title">
        {i18n.t('general', { locale: language })}
      </div>
      {isEnglish ? (
        <>
          <Controller
            control={control}
            name={'name'}
            rules={{ required: 'Please provide a name' }}
            render={() => (
              <CustomTitleWithInput
                title={i18n.t('name', { locale: 'en' })}
                defaultValue={watchName}
                error={{
                  error: hasError(errors, 'name'),
                }}
                setValue={(value) => {
                  setValue('name', value, { shouldDirty: true });
                }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'name'} />
        </>
      ) : null}
      <Controller
        control={control}
        name={`translations.${language}.description`}
        rules={isEnglish ? { required: 'Please provide description' } : {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <CustomTitleLabel
              title={i18n.t(isEnglish ? 'description' : 'short_description', {
                locale: language,
              })}
            />
            <HtmlEditor
              initialValue={initHtmlContent}
              init={{
                height: 1000,
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:10pt }',
              }}
              onEditorChange={(value, editor) => {
                field.onChange(value);
              }}
              className={error ? 'error-field' : ''}
            />
          </>
        )}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={`translations.${language}.description`}
      />
    </>
  );
}

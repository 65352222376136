import React from 'react';

import { Editor } from '@toast-ui/react-editor';
import DOMPurify from 'dompurify';

import { isLongScreen } from 'utils/ScreenUtil';

import './CustomEditor.scss';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'codemirror/lib/codemirror.css';

const toolBars = [
  'heading',
  'bold',
  'italic',
  'quote',
  'divider',
  'ul',
  'ol',
  'link',
  'htmlInline',
  'redo',
  'undo',
];

class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.initialValue = props.initialValue;
    this.onValueChange = props.onValueChange || (() => {});
    this.errorMessage = props.errorMessage;
    this.onFocus = props.onFocus || (() => {});

    this.state = { editorValue: props.initialValue };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialValue && this.props.initialValue) {
      this.editorRef.current.getInstance().setMarkdown(this.props.initialValue);
    }
  }

  render() {
    return (
      <>
        <div
          className={`customer-editor-container ${
            this.props.error ? 'editor-container-error' : ''
          } ${isLongScreen ? '' : 'customer-editor-container-short '}`}
          onFocus={() => this.onFocus()}
        >
          <Editor
            events={{
              change: () => {
                const markdown = this.editorRef.current
                  .getInstance()
                  .getMarkdown();
                // console.log('@@122-56: ', this.state.editorValue, markdown);
                if (
                  markdown !== this.state.editorValue &&
                  (markdown || this.state.editorValue)
                ) {
                  this.onValueChange(markdown);
                  this.setState({ editorValue: markdown });
                }
              },
            }}
            initialValue={this.initialValue}
            previewStyle="vertical"
            height="270px"
            useCommandShortcut={true}
            initialEditType="markdown"
            hideModeSwitch={true}
            toolbarItems={toolBars}
            ref={this.editorRef}
            // useDefaultHTMLSanitizer={true}
            customHTMLSanitizer={(html) => {
              // console.log('@@74: ', html);
              // console.log('@@74: ', html.replace(/<span[^>]*>?/gm, ''));
              let newHtml = `${html
                .replace(/<\/?span[^>]*>/gm, '')
                .replace(/<\/?label[^>]*>/gm, '')}`;
              // .replace(/<\/?u[^>]*>/gm, '')}`;
              // return `<b>${html}</b>`;
              newHtml = DOMPurify.sanitize(newHtml);
              return newHtml;
            }}
          />
        </div>
        {this.props.error ? (
          <label className="editor-container-error-message">
            {this.errorMessage}
          </label>
        ) : null}
      </>
    );
  }
}

export default CustomEditor;
